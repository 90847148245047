import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import Col from "react-bootstrap/Col";

import Row from "react-bootstrap/Row";

import { useContext } from "react";
import { Context } from "../../../../utils/context";
function Step1({ handleNext }) {
  const {
    postData,
    jobSeekerData,
    setJobSeekerData,

    getJobSeekerData,
  } = useContext(Context);

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    step: 2,
    status: true,
  });

  const [first_name, setFirstName] = useState("");

  const [last_name, setLastName] = useState("");

  const [nameErr, setNameErr] = useState("");
  const [lastNameErr, setlastNameErr] = useState("");

  const handleValidation = async () => {
    // console.log(jobSeekerData, "jobSeekerData");

    // Initialize an array to store error messages
    var errors = [];

    if (!formData?.first_name) {
      setNameErr("First Name is required");
      errors.push("First Name is required");
    }

    if (!formData?.last_name) {
      setlastNameErr("Last Name is required");
      errors.push("Last Name is required");
    }

    if (errors.length === 0) {
      // console.log(jobSeekerData?.id);
      const data = {
        first_name: first_name,
        last_name: last_name,
        step: 2,
        status: true,
      };
      const res = await postData(`/jobseeker/signup/name`, formData);
      if (res?.success) {
        handleNext();
      } else {
        // console.log(res, "fails");
      }
    }
  };

  const seekerData = async () => {
    const res = await getJobSeekerData();
    if (res?.first_name != null && res?.last_name != null) {
      await setFormData({
        first_name: res?.first_name,
        last_name: res?.last_name,
        step: 2,
        status: "none",
      });
    }
  };

  const handleChange = async (e) => {
    // console.log(e.target.value);

    setFormData({ ...formData, [e?.target?.name]: e?.target?.value });
  };
  useEffect(() => {
    seekerData();
  }, []);
  return (
    <>
      <fieldset>
        <div className="form-card StepForm">
          <div className="row step1_nameField mt-4">
            <div className="d-flex  steps-heading">
              <div className="border-left" />
              <h5>Step 1</h5>
            </div>
            <Form>
              <Row>
                <div className="col-md-6 col-sm-12 mb-3">
                  <Form.Label>
                    First Name <span className="text-danger">*</span>{" "}
                  </Form.Label>
                  <Form.Control
                    name="first_name"
                    type="text"
                    placeholder="Enter First Name"
                    defaultValue={formData?.first_name}
                    onKeyDown={(event) => {
                      const allowedCharacters = /[A-Za-z ]/;
                      if (!allowedCharacters.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onKeyUp={(event) => {
                      handleChange(event);
                      setNameErr("");
                    }}
                  />
                  <span
                    className="text text-danger "
                    style={{
                      textAlign: "center",
                    }}
                  >
                    {nameErr && nameErr}
                  </span>
                </div>

                <div className="col-md-6 col-sm-12 mb-3">
                  <Form.Label>
                    Last Name <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    name="last_name"
                    type="text"
                    placeholder="Enter Last Name"
                    defaultValue={formData?.last_name}
                    onKeyDown={(event) => {
                      if (!/[A-Z-a-z ]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onKeyUp={(event) => {
                      handleChange(event);
                      setlastNameErr("");
                    }}
                  />
                  <span
                    className="text text-danger "
                    style={{
                      textAlign: "center",
                    }}
                  >
                    {lastNameErr && lastNameErr}
                  </span>
                </div>
              </Row>
            </Form>
            <div className="mobile-view-button">
              <button
                type="button"
                name="next"
                className="next action-button contin-btn"
                onClick={handleValidation}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </fieldset>

      <div className="row mt-5  pt-4 footer-sec">
        <div className="col-md-10 col-12 desktop-view-button">
          {/* <div className="check-row text-start">
            <span>
              <Form.Check inline name="group1" className="me-2" />
            </span>
            <span className=" by-text ">By signing up, I agree to the</span>
            <span className="px-1 ">
              <Link to="/terms" className="term-btn">
                Term and Conditions
              </Link>
            </span>
            <br />
          </div>
          <div className="login-row text-start">
            <span className="">Already an User?</span>
            <span className="px-1 ">
              <Link to="/other-page" className="login-btn">
                log In
              </Link>
            </span>
            &nbsp; &nbsp; &nbsp;
            <span className="">Not a Jobseeker?</span>
            <span className="px-1 ">
              <Link to="/signup-recruiter" className="signup-btn">
                Signup as Recruiter
              </Link>
            </span>
          </div> */}
        </div>
        {/* <div className="col-md-8 col-12 mb-5  mobile-view-button">
          <div className="check-row text-start">
            <span>
              <Form.Check inline name="group1" className="me-2" />
            </span>
            <span className=" by-text ">By signing up, I agree to the</span>
            <span className="px-1 ">
              <Link to="/terms" className="term-btn">
                Term and Conditions
              </Link>
            </span>
            <br />
          </div>
          <div className="login-row text-start">
            <span className="">Already an User?</span>
            <span className="px-1 ">
              <Link to="/other-page" className="login-btn">
                log In
              </Link>
            </span>
            <br />
            <span className="">Not a Jobseeker?</span>
            <span className="px-1 ">
              <Link to="/signup-recruiter" className="signup-btn">
                Signup as Recruiter
              </Link>
            </span>
          </div>
        </div> */}

        <div className="col-2">
          <div className="desktop-view-button">
            <button
              type="button"
              name="next"
              className="next action-button contin-btn"
              onClick={handleValidation}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Step1;
