import React, { useEffect, useRef } from "react";
import './PortfolioJobExperience.css';
import { Link } from 'react-router-dom';

const ViewDetailsProject = () => {
    const vidRef = useRef();
    useEffect(() => { vidRef.current.play(); }, []);
    return (
        <>
            <section className='portfolio-experience'>
                <div className='container'>
                    <div className='black-header'>
                        <div className='row'>
                            <div className='col-md-4 col-6'>
                                <Link to='/my-portfolio'>
                                    <div className='text-holder pt-2 ms-3'>
                                        <p>Back</p>
                                    </div>
                                </Link>
                            </div>
                            <div className='col-md-4 col-6'>
                                <div className='text-holder text-center pt-2'>
                                    <h5>Projects</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='form-bg'>
                        <div className='company-name'>
                            <h4>MAEER'S MIT College of Arts, Commerce & Science Erandwane Pune, 38</h4>
                        </div>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <div className='row'>
                                    <div className='col-5'>
                                        <div className='position-holder'>
                                            <p>Project Title:</p>
                                        </div>
                                    </div>
                                    <div className='col-7'>
                                        <div className='role-holder '>
                                            <p>E.R.P.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-5'>
                                        <div className='position-holder'>
                                            <p>Role:</p>
                                        </div>
                                    </div>
                                    <div className='col-7'>
                                        <div className='role-holder '>
                                            <p>Project Assistant</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-5'>
                                        <div className='position-holder'>
                                            <p>Skills Used :</p>
                                        </div>
                                    </div>
                                    <div className='col-7'>
                                        <div className='role-holder '>
                                            <p>JAVA, Python, HTML, React</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-5'>
                                        <div className='position-holder'>
                                            <p>Project Nature :</p>
                                        </div>
                                    </div>
                                    <div className='col-7'>
                                        <div className='role-holder '>
                                            <p>College Project</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-5'>
                                        <div className='position-holder'>
                                            <p>Time Period :</p>
                                        </div>
                                    </div>
                                    <div className='col-7'>
                                        <div className='role-holder '>
                                            <p>2022-2023</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-5'>
                                        <div className='position-holder'>
                                            <p>Outcomes Application Sector:</p>
                                        </div>
                                    </div>
                                    <div className='col-7'>
                                        <div className='role-holder '>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='video-img text-lg-end text-md-center mb-3'>
                                    <video src={process.env.PUBLIC_URL + "assets/Images/my-portfolio/video.mp4"} ref={vidRef} muted loop className=" video-class-grid" />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className='row'>
                                    <div className='col-md-2 col-4'>
                                        <div className='position-holder'>
                                            <p>Approved Authority:</p>
                                        </div>
                                    </div>
                                    <div className='col-md-10 col-8'>
                                        <div className='role-holder '>
                                            <p>MAEER'S MIT College of Arts, Commerce & Science Erandwane Pune, 38</p>
                                        </div>
                                    </div>
                                    <div className='position-holder'>
                                        <p>Contribution(On Scale 1 to 10):</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-2 col-5'>
                                        <div className='position-holder'>
                                            <p>Team Work :</p>
                                        </div>
                                    </div>
                                    <div className='col-md-10 col-7'>
                                        <div className='role-holder '>
                                            <p>8</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-2 col-5'>
                                        <div className='position-holder'>
                                            <p>Individual Work :</p>
                                        </div>
                                    </div>
                                    <div className='col-md-10 col-7'>
                                        <div className='role-holder '>
                                            <p>8</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-2 col-5'>
                                        <div className='position-holder'>
                                            <p>Scale :</p>
                                        </div>
                                    </div>
                                    <div className='col-md-10 col-7'>
                                        <div className='role-holder '>
                                            <p>8</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-2 col-5'>
                                        <div className='position-holder'>
                                            <p>Reporting Person :</p>
                                        </div>
                                    </div>
                                    <div className='col-md-10 col-7'>
                                        <div className='role-holder '>
                                            <p>Prasad Kulkarni</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-2 col-5'>
                                        <div className='position-holder'>
                                            <p>Mentor/Guide Name:</p>
                                        </div>
                                    </div>
                                    <div className='col-md-10 col-7'>
                                        <div className='role-holder '>
                                            <p>Prasad Kulkarni</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-6">s
                                <div className='position-holder mt-3'>
                                    <p>Files Attached</p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <button className='btn btn-status1 me-1 mt-3'><img src={process.env.PUBLIC_URL + "assets/Images/my-portfolio/document.png"} className="document-img me-2" />Experience Letter</button>
                            </div>
                            <div className=" col-lg-3 col-md-6">
                                <button className='btn btn-status1 me-1 mt-3'> <img src={process.env.PUBLIC_URL + "assets/Images/my-portfolio/document.png"} className="document-img me-2" /> Document 1</button>
                            </div>
                            <div className=" col-lg-3 col-md-6">
                                <button className='btn btn-status1 me-1 mt-3'> <img src={process.env.PUBLIC_URL + "assets/Images/my-portfolio/document.png"} className="document-img me-2" /> Document 2</button>
                            </div>
                            <div className='button-holder text-center mt-5 mb-md-0 mb-5'>
                                <button className='btn btn-save' >OK</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ViewDetailsProject