import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookmark } from "@fortawesome/free-regular-svg-icons";
import { Link } from "react-router-dom";
import "../AppliedJobs/AppliedJobs.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import ApplyModal from "../../ApplyModel/ApplyModal";
import parse from "html-react-parser";
import { Context } from "../../../../utils/context";
import { getData, postData } from "../../../../utils/api";
import Cookies from "js-cookie";

const JobDescription = (props) => {
  const { jobSeekerData, fetchSeekerData, postData, getData } =
    useContext(Context);
  const [modalShow, setModalShow] = React.useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contact: "",
    job_id: props?.data?.id,
  });

  useEffect(() => {
    fetchSeekerData();
  }, []);

  const SetVal = async () => {
    await setFormData({
      ...formData,
      name: jobSeekerData?.first_name + " " + jobSeekerData?.last_name,
      email: jobSeekerData?.email,
      contact: jobSeekerData?.contact_no,
    });
  };

  const saveJob = async (id) => {
    const res = await postData(`/jobseeker/saved`, { job_id: id });
    if (res?.success) {
      getJD();
      if (props?.getSavedJobs) {
        props?.getSavedJobs();
      }
    }
  };
  const [jobData, setJobData] = useState();
  const getJD = async () => {
    const res = await getData(`/common/job-description/${props?.data?.id}`);
    if (res?.success) {
      // console.log(res);
      setJobData(res.data);
    }
  };
  var HtmlToReactParser = require("html-to-react").Parser;
  var htmlToReactParser = new HtmlToReactParser();

  const [authToken, setAuthToken] = useState(null);
  const [authType, setAuthType] = useState(null);
  useEffect(() => {
    getJD();
    if (Cookies.get("hiclousia_website") != null) {
      setAuthToken(Cookies.get("hiclousia_website"));
      setAuthType(Cookies.get("jwtType"));
    }
  }, [props]);
  useEffect(() => {
    SetVal();
  }, [jobSeekerData?.id]);
  return (
    <>
      <section className="Sixth-job-tab">
        <div className="sec-title mt-md-5 mt-3 mobile-view">
          <h5 className="title-talent">Job Description</h5>
        </div>
        <h5 className="desktop-view text-center mt-md-5 mt-3">{props.title}</h5>
        <div className="container pb-5">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="desktop-view">
                {/* <Breadcrumb>
                  <Breadcrumb.Item
                    href="/dashboard-job"
                    className="breadcrum_home_txt"
                  >
                    Go to Home
                  </Breadcrumb.Item>
                  <Breadcrumb.Item className="breadcrum_home_txt">
                    Saved Jobs
                  </Breadcrumb.Item>
                  <Breadcrumb.Item className="breadcrum_home_txt" active>
                    Job Description
                  </Breadcrumb.Item>
                </Breadcrumb> */}
              </div>
            </div>
            <div className="col-lg-12 col-md-6">
              <div className="brown-line desktop-view"></div>
            </div>
            <div className="col-md-12">
              <div className="sixthjob-card mt-md-0 mt-3">
                <div className="row ms-md-5 ms-0">
                  <div className="col-xxl-8 col-lg-7 col-md-6 col-sm-8 col-6">
                    <h5>{jobData?.data?.role?.name}</h5>
                  </div>
                  {authType === "Job Seeker" && (
                    <div className="col-xxl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                      {jobData?.saved === null ? (
                        <div
                          className="bookmark_icon_description text-center"
                          style={{ cursor: "pointer" }}
                          onClick={() => saveJob(jobData?.data?.id)}
                        >
                          <FontAwesomeIcon icon={faBookmark} className="book" />
                        </div>
                      ) : (
                        <div
                          className="bookmark_icon_description text-center"
                          style={{ cursor: "pointer" }}
                          onClick={() => saveJob(jobData?.data?.id)}
                        >
                          <FontAwesomeIcon
                            icon="fa-solid fa-bookmark"
                            className="book"
                          />
                        </div>
                      )}
                    </div>
                  )}

                  <div className="col-xxl-2 col-lg-1 col-md-1 col-sm-1 col-4">
                    <div className="description_apply_btn text-center">
                      {/* <Link to='/apply-job'> */}
                      {authType === "Job Seeker" && (
                        <>
                          {jobData?.apply === null ? (
                            jobData?.apply_status && (
                              <button
                                className="btn-apply"
                                onClick={() => setModalShow(true)}
                              >
                                Apply
                              </button>
                            )
                          ) : (
                            <button className="btn-apply">Applied</button>
                          )}
                        </>
                      )}

                      {/* </Link> */}
                    </div>

                    <ApplyModal
                      show={modalShow}
                      formData={formData}
                      setFormData={setFormData}
                      onHide={() => setModalShow(false)}
                      onJDHide={props.onJDHide}
                    />
                  </div>
                  <div className="col-xxl-2 col-lg-2 col-md-3">
                    {/* <div className="desktop-view">
                                            <div className="on-off-btn d-flex">
                                                <p className="job-alert me-2">Job Alert</p>
                                                <button className="on-btn">on</button>
                                                <button className="off-btn">off</button>
                                            </div>
                                        </div> */}
                  </div>
                </div>
                <div className="row ms-md-5 ms-0">
                  <div className="col-lg-10 col-md-8 col-sm-7 col-7 ">
                    <div>
                      <a className="company-name">
                        {jobData?.data?.company?.name}
                      </a>
                    </div>
                    <div className="d-md-flex">
                      {/* <p className="text-holder-sixthjob">
                        <FontAwesomeIcon icon="fa-solid fa-star" />
                        <FontAwesomeIcon icon="fa-solid fa-star" />
                        <FontAwesomeIcon icon="fa-solid fa-star" />
                        <FontAwesomeIcon icon="fa-solid fa-star" />
                        <FontAwesomeIcon icon="fa-solid fa-star" />
                        9,408 reviews
                      </p> */}
                      <p className="text-holder-sixthjob">
                        <FontAwesomeIcon
                          icon="fa-solid fa-location-dot"
                          className="me-2 ms-md-2"
                        />{" "}
                        {jobData?.data?.city?.name}
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-4 col-sm-5 col-5">
                    {/* mobile btn */}
                    {/* <div className="mobile-view">
                      <div className=" d-flex mt-2">
                        <p className="job-alert me-2">Alert</p>
                        <button className="on-btn">on</button>
                        <button className="off-btn">off</button>
                      </div>
                    </div> */}
                    <div className=" activeStatus_txt">
                      {/* <p className="text-holder-sixthjob">Status : Active</p> */}
                      {/* <p className="text-holder-sixthjob">Day Remaining: 10</p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="applied-job">
              <div className="col-md-12">
                <div className="applied-card mt-3">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-1 col-1 ">
                          <div className="text-holder-applied">
                            <FontAwesomeIcon
                              icon="fa-solid fa-briefcase"
                              className="me-4"
                            />
                          </div>
                        </div>
                        <div className="col-md-11 col-11">
                          <div className="text-holder-applied">
                            <h5>Job description: </h5>
                            {htmlToReactParser.parse(
                              jobData?.data?.description
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <div className="text-holder-applied">
                        <div className="row">
                          <div className="col-md-1 col-1">
                            <div className="text-holder-applied">
                              <FontAwesomeIcon icon="fa-solid fa-briefcase" />
                            </div>
                          </div>
                          <div className="col-md-11 col-11">
                            <div className="text-holder-applied">
                              <h6>Primary Skills </h6>
                              <p>
                                {jobData?.data?.job_primary_skills?.map(
                                  (val, index) =>
                                    val?.skill?.name +
                                    " - " +
                                    val?.skill?.skill_type?.name +
                                    ", "
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="text-holder-applied">
                        <div className="row">
                          <div className="col-md-1 col-1">
                            <div className="text-holder-applied">
                              <FontAwesomeIcon icon="fa-solid fa-graduation-cap" />
                            </div>
                          </div>
                          <div className="col-md-11 col-11">
                            <div className="text-holder-applied">
                              <h6> Qualification </h6>
                              <p>
                                {jobData?.data?.job_qualifications?.map(
                                  (val, index) => val?.degree?.name + ", "
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="text-holder-applied">
                        <div className="row">
                          <div className="col-md-1 col-1">
                            <div className="text-holder-applied">
                              <FontAwesomeIcon icon="fa-solid fa-comments" />
                            </div>
                          </div>
                          <div className="col-md-11 col-11">
                            <div className="text-holder-applied">
                              <h6> Languages </h6>
                              <p>
                                {" "}
                                {jobData?.data?.job_languages?.map(
                                  (val, index) => val?.language?.name + ", "
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="applied-card">
                  <div className="jobDetails_head_txt">Job details:</div>
                  <div className="row mt-3">
                    <div className="col-md-6 col-12">
                      <div>
                        <div className="text-holder-applied">
                          <div className="row ">
                            <div className="col-md-1 col-1">
                              <div className="text-holder-applied">
                                <FontAwesomeIcon icon="fa-solid fa-money-bill-1" />
                              </div>
                            </div>
                            <div className="col-md-11 col-11">
                              <div className="text-holder-applied">
                                <h6> Pay </h6>
                                <p>
                                  ₹ {jobData?.data?.start_salary} - ₹{" "}
                                  {jobData?.data?.end_salary} LPA
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="row">
                            <div className="col-md-1 col-1">
                              <div className="text-holder-applied">
                                <FontAwesomeIcon icon="fa-solid fa-clock" />
                              </div>
                            </div>
                            <div className="col-md-11 col-11">
                              <div className="text-holder-applied">
                                <h6>Shift and Schedule</h6>
                                <p>
                                  {" "}
                                  {jobData?.data?.job_create_shifts?.map(
                                    (val, index) => val?.shift?.name + ", "
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-1 col-1">
                              <div className="text-holder-applied">
                                <FontAwesomeIcon icon="fa-solid fa-briefcase" />
                              </div>
                            </div>
                            <div className="col-md-11 col-11">
                              <div className="text-holder-applied">
                                <h5>
                                  Experience required:{" "}
                                  {jobData?.data?.experience_in_year?.name} year
                                </h5>
                              </div>
                            </div>
                          </div>
                          {/* <h6>Shift and Schedule</h6> */}
                          <div className="row mt-4">
                            <div className="col-md-1 col-1">
                              <div className="text-holder-applied">
                                <FontAwesomeIcon icon="fa-solid fa-location-dot" />
                              </div>
                            </div>
                            <div className="col-md-11 col-11">
                              <div className="text-holder-applied">
                                <h6>
                                  Application Deadline Date :{" "}
                                  {jobData?.data?.job_contact?.deadline}
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <div className="row">
                        <div className="col-md-1 col-1">
                          <div className="text-holder-applied">
                            <FontAwesomeIcon
                              icon="fa-solid fa-briefcase"
                              className="me-4"
                            />
                          </div>
                        </div>
                        <div className="col-md-11 col-11">
                          <div className="text-holder-applied">
                            <h6> Job type </h6>
                            <p>
                              {jobData?.data?.job_create_types?.map(
                                (val, index) => val?.job_type?.name + ", "
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-1 col-1">
                          <div className="text-holder-applied">
                            <FontAwesomeIcon
                              icon="fa-solid fa-briefcase"
                              className="me-4"
                            />
                          </div>
                        </div>
                        <div className="col-md-11 col-11">
                          <div className="text-holder-applied">
                            <h6> Career Level </h6>
                            <p>{jobData?.data?.career_level?.name}</p>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-1 col-1">
                          <div className="text-holder-applied">
                            <FontAwesomeIcon
                              icon="fa-solid fa-briefcase"
                              className="me-4"
                            />
                          </div>
                        </div>
                        <div className="col-md-11 col-11">
                          <div className="text-holder-applied">
                            <h6>Number of Vacancy</h6>
                            <p> {jobData?.data?.no_of_vacancy} </p>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-1 col-1">
                          <div className="text-holder-applied">
                            <FontAwesomeIcon
                              icon="fa-solid fa-briefcase"
                              className="me-4"
                            />
                          </div>
                        </div>
                        <div className="col-md-11 col-11">
                          <div className="text-holder-applied">
                            <h6>
                              Secondary Skills:{" "}
                              {jobData?.data?.job_secondary_skills?.map(
                                (val, index) => val?.skill?.name + ", "
                              )}
                            </h6>
                          </div>
                        </div>
                      </div>
                      {/* <div className="row application_row_txt">
                        <div className="col-md-1 col-1">
                          <div className="text-holder-applied">
                            <FontAwesomeIcon
                              icon="fa-solid fa-briefcase"
                              className="me-4"
                            />
                          </div>
                        </div>
                        <div className="col-md-11 col-11">
                          <div className="text-holder-applied">
                            <h6> Application Deadline Date : 20/10/2023</h6>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="applied-card">
                  <div>
                    <h6>
                      {" "}
                      <FontAwesomeIcon
                        icon="fa-solid fa-briefcase"
                        className="me-4"
                      />{" "}
                      Roles and responsibilities:{" "}
                    </h6>
                    {htmlToReactParser.parse(
                      jobData?.data?.role_and_permission
                    )}
                    {/* <ul className="mt-3">
                      <li>
                        <FontAwesomeIcon
                          icon="fa-solid fa-circle-check"
                          className="watch-icon me-2"
                        />
                        Expertise in designing websites using Photoshop and
                        Illustrator.
                      </li>
                      <li>
                        <FontAwesomeIcon
                          icon="fa-solid fa-circle-check"
                          className="watch-icon me-2"
                        />
                        Visualize and design user interface, wireframes,
                        mock-ups, task flows for web applications.
                      </li>
                      <li>
                        <FontAwesomeIcon
                          icon="fa-solid fa-circle-check"
                          className="watch-icon me-2"
                        />
                        Create graphic assets like icons, buttons, widgets,
                        landing pages for use in user interfaces.
                      </li>
                      <li>
                        <FontAwesomeIcon
                          icon="fa-solid fa-circle-check"
                          className="watch-icon me-2"
                        />
                        Designing Web Layouts, Logos, Banners, Infographics.
                      </li>
                      <li>
                        <FontAwesomeIcon
                          icon="fa-solid fa-circle-check"
                          className="watch-icon me-2"
                        />
                        Conceptualize ideas that bring simplicity and delight to
                        the customer.
                      </li>
                      <li>
                        <FontAwesomeIcon
                          icon="fa-solid fa-circle-check"
                          className="watch-icon me-2"
                        />
                        Maintain standards and processes for producing
                        deliverables.
                      </li>
                      <li>
                        <FontAwesomeIcon
                          icon="fa-solid fa-circle-check"
                          className="watch-icon me-2"
                        />
                        Gather feedback from teams and follow an iterative
                        process.
                      </li>
                    </ul> */}
                  </div>
                  <div>
                    <h6>
                      {" "}
                      <FontAwesomeIcon
                        icon="fa-solid fa-briefcase"
                        className="me-4"
                      />{" "}
                      Skill Required{" "}
                    </h6>
                    {htmlToReactParser.parse(jobData?.data?.skills_required)}
                    {/* <ul className="mt-3">
                      <li>
                        <FontAwesomeIcon
                          icon="fa-solid fa-circle-check"
                          className="watch-icon me-2"
                        />
                        Expertise in designing websites using Photoshop and
                        Illustrator.
                      </li>
                      <li>
                        <FontAwesomeIcon
                          icon="fa-solid fa-circle-check"
                          className="watch-icon me-2"
                        />{" "}
                        Visualize and design user interface, wireframes,
                        mock-ups, task flows for web applications.
                      </li>
                      <li>
                        <FontAwesomeIcon
                          icon="fa-solid fa-circle-check"
                          className="watch-icon me-2"
                        />{" "}
                        Create graphic assets like icons, buttons, widgets,
                        landing pages for use in user interfaces.
                      </li>
                      <li>
                        <FontAwesomeIcon
                          icon="fa-solid fa-circle-check"
                          className="watch-icon me-2"
                        />{" "}
                        Designing Web Layouts, Logos, Banners, Infographics.
                      </li>
                      <li>
                        <FontAwesomeIcon
                          icon="fa-solid fa-circle-check"
                          className="watch-icon me-2"
                        />{" "}
                        Conceptualize ideas that bring simplicity and delight to
                        the customer.
                      </li>
                      <li>
                        <FontAwesomeIcon
                          icon="fa-solid fa-circle-check"
                          className="watch-icon me-2"
                        />{" "}
                        Maintain standards and processes for producing
                        deliverables.
                      </li>
                      <li>
                        <FontAwesomeIcon
                          icon="fa-solid fa-circle-check"
                          className="watch-icon me-2"
                        />{" "}
                        Gather feedback from teams and follow an iterative
                        process.
                      </li>
                    </ul> */}
                  </div>
                </div>
              </div>

              {jobData?.apply?.job_apply_status_id === 4 && (
                <div className="col-md-12">
                  <div className="applied-card">
                    <div className="contact">
                      <h6>Contact:</h6>
                      <div className="ps-4">
                        <p className="name-holder">
                          {jobData?.data?.job_contact?.recruiter_name} (HR
                          Manager)
                          <br />
                          {jobData?.data?.company?.name}
                        </p>
                        <p>
                          Recruiter Contact Number:{" "}
                          <span className="num-holder ms-2">
                            +91 {jobData?.data?.job_contact?.contact_no}
                          </span>{" "}
                        </p>
                        <p>
                          Recruiter Email:{" "}
                          <span className="num-holder ms-2">
                            {jobData?.data?.job_contact?.email}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default JobDescription;
