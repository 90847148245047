import React, { useEffect, useState } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import "./Blog_Details.css";

const BlogDetails = () => {
  const { id } = useParams();
  const location = useLocation();
  // console.log(location);
  const queryParams = new URLSearchParams(location.search);

  const imageSource = queryParams.get("imageSource");
  // console.log(imageSource);

  const [blogPost, setBlogPost] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      const blogPostsData = [
        { id: 1 },
        { id: 2 },
        { id: 3 },
        { id: 4 },
        { id: 5 },
        { id: 6 },
        { id: 7 },
        { id: 8 },
        { id: 9 },
        { id: 10 },
        { id: 11 },
        { id: 12 },
      ];

      const selectedPost = blogPostsData.find((post) => post.id === Number(id));

      if (selectedPost) {
        setBlogPost(selectedPost);
      }
    };
    fetchData();
  }, [id]);

  return (
    <>
      <section className="BlogDetails">
        {blogPost ? (
          <>
            <div className="container-fluid">
              <div className="row BlogDetails_main">
                <div className="col-lg-12 my-auto">
                  <div className="row">
                    <div className="col-lg-10 mx-auto">
                      <Link to={"/blogs"} className="mb-md-3 mb-0">
                        <p className="goToBlog_txt">Go to Blog</p>
                      </Link>
                    </div>
                  </div>
                  <div className="row blog_details p-0">
                    <div className="col-lg-10 mx-auto">
                      <div className="innerBlog_content">
                        <div className="row  ">
                          <div className="col-xl-8 col-lg-6 col-md-6">
                            <div className=" BlogonBanner_content pt-4 pb-md-4 pb-0">
                              <p className="Blogdetails_heading">
                                ADVICE FROM HR AND <br /> BUSINESS LEADERS
                              </p>
                              <p className="Blogdetails_info">
                                The ROI of Veteran Hiring: How Investing in
                                Veterans Pays Off
                              </p>
                              <p className="Blogdetails_date">
                                <FontAwesomeIcon
                                  icon={faCalendarDays}
                                  style={{ color: "white" }}
                                  className="me-1"
                                />
                                AUG 13,2023
                              </p>
                            </div>
                          </div>
                          {/* Mobile viw image */}
                          <div className="col-xl-4 col-lg-6 col-md-6">
                            <div className="blogdetails_imgsection text-xl-end text-lg-end">
                              {imageSource && (
                                <img
                                  src={"http://localhost:3000/" + imageSource}
                                  className="blogDetails_Backimg"
                                  alt="Blog Image"
                                />
                              )}
                            </div>
                          </div>
                          <div className="col-xl-4 col-lg-6 ">
                            <div className="blogdetails_imgsection-mobile text-xl-end text-lg-end">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/Images/banner/BlogDetails_img.png"
                                }
                                className="banner-img-mobile"
                                alt="..."
                              />{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mt-5 mb-5">
                <div className="col-lg-8 ">
                  <div className=" blogDetails_below_info">
                    <p className="blogDetails_content_section">
                      Hiring our nation’s heroes can yield positive returns for
                      employers that invest in veteran hiring. With their
                      unmatched technical and leadership skills, strong work
                      ethic, and unwavering commitment to safety and integrity
                      in the workplace, veterans can generate positive outcomes
                      that can impact a business’s bottom line. In fact, one
                      analysis showed that a company of 1,000 employees, 25% of
                      whom are veterans, can experience an annual cost savings
                      of $325,000.
                    </p>
                    <p className="blogDetails_content_section">
                      Hiring our nation’s heroes can yield positive returns for
                      employers that invest in veteran hiring. With their
                      unmatched technical and leadership skills, strong work
                      ethic, and unwavering commitment to safety and integrity
                      in the workplace, veterans can generate positive outcomes
                      that can impact a business’s bottom line. In fact, one
                      analysis showed that a company of 1,000 employees, 25% of
                      whom are veterans, can experience an annual cost savings
                      of $325,000.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <p>Loading...</p>
        )}
      </section>
    </>
  );
};

export default BlogDetails;
