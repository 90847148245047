import React, { useState } from "react";
import Step1 from "../SignUpForm/Step1";
import Step2 from "../SignUpForm/Step2";
import Step3 from "../SignUpForm/Step3";
import Step4 from "../SignUpForm/Step4";
import Step5 from "../SignUpForm/Step5";
import Step6 from "../SignUpForm/Step6";
import Stepform from "./Image/Stepform.png";

import "./prog-track.css";
import "./SignUpForm.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faEnvelope,
  faCalendarAlt,
  faLessThan,
  faPhone,
  faShield,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";

import { useContext } from "react";
import { Context } from "../../../../utils/context";

function SignupForm() {
  const { recruiterData, setRecruiterData } = useContext(Context);

  const [current, setCurrent] = useState(1);

  const handleNext = () => {
    if (current < 7) {
      setCurrent(current + 1);
    }
  };

  const handlePrevious = () => {
    if (current > 1) {
      setCurrent(current - 1);
    }
  };

  const isStepCompleted = (step) => {
    return step < current;
  };

  const getIconForStep = (step) => {
    switch (step) {
      case 1:
        return faUser;
      case 2:
        return faEnvelope;

      // case 3:
      //   return faShield;
      case 3:
        return faCalendarAlt;

      case 4:
        return faPhone;
      case 5:
        return faLocationDot;
      default:
        return null;
    }
  };

  return (
    <>
      <section className="StepForm-recruiter">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12  text-center p-0 mt-3 mb-2">
              <div className="header-content text-center">
                <p className="signup-text mb-0">Recruiter's Sign up </p>
                <p className="info-text">Personal Info Page</p>
              </div>
              <div className="card stepForm_card px-0  pb-0 mt-3 mb-3">
                <div className="card-body p-md-0 p-none">
                  <div className="row">
                    <div className="col-md-9">
                      <form id="msform">
                        <div
                          className="text-start my-2"
                          onClick={handlePrevious}
                          style={{ cursor: "pointer" }}
                        >
                          {current > 1 && (
                            <span className="back-btn">
                              <FontAwesomeIcon
                                icon={faLessThan}
                                className="px-2"
                              />
                              Back
                            </span>
                          )}
                        </div>
                        <div className="signup-form">
                          <div className="progress-bar-container">
                            <div className="step-row">
                              {[1, 2, 3, 4, 5].map((step) => (
                                <div key={step} className="step-container">
                                  {step >= 1 && (
                                    <div
                                      className={`line-left ${
                                        isStepCompleted(step - 1)
                                          ? "completed"
                                          : ""
                                      }`}
                                    ></div>
                                  )}
                                  <div
                                    className={`circle ${
                                      isStepCompleted(step) ? "completed" : ""
                                    }`}
                                  >
                                    <FontAwesomeIcon
                                      icon={getIconForStep(step)}
                                    />
                                  </div>
                                  {step <= 6 && (
                                    <div
                                      className={`line-right ${
                                        isStepCompleted(step) ? "completed" : ""
                                      }`}
                                    ></div>
                                  )}
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                        <div className="wizard-form mt-3">
                          {current === 1 && <Step1 handleNext={handleNext} />}
                          {current === 2 && (
                            <Step2
                              handleNext={handleNext}
                              handlePrevious={handlePrevious}
                            />
                          )}
                          {/* {current === 3 && (
                            <Step3
                              handleNext={handleNext}
                              handlePrevious={handlePrevious}
                            />
                          )} */}
                          {current === 3 && (
                            <Step4
                              handleNext={handleNext}
                              handlePrevious={handlePrevious}
                            />
                          )}
                          {current === 4 && (
                            <Step5
                              handleNext={handleNext}
                              handlePrevious={handlePrevious}
                            />
                          )}
                          {current === 5 && (
                            <Step6
                              handleNext={handleNext}
                              handlePrevious={handlePrevious}
                            />
                          )}
                        </div>
                      </form>
                    </div>
                    <div className=" col-md-3">
                      <div className="image-holder">
                        <img src={Stepform} className="signup-image" />
                        <div className="overlay-holder">
                          <div className="middle-content">
                            <h1>Don't let </h1>
                            <p>
                              the best career
                              <br />
                              opportunity <br />
                              slip off!
                            </p>
                            <p className="create">
                              Create <br />
                              Your Account Today!
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SignupForm;
