import React, { useState } from "react";
import "../OpenJobs/OpenJobs.css";
// import '../../DashboardMain/SecondTab/SecondTab.css';
import "../DraftedJobs/DraftedJobs.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ShortBanner from "../../../../RecruiterPage/NavBar/ShortListedPortfolio/ShortBanner/ShortBanner";

const DraftedJobsMain = () => {
  const [selectedOption, setSelectedOption] = useState("");

  const handleDropdownChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <>
      <section className="manage-job">
        <div className="col-md-12">
          <div className="banner-none">
            <ShortBanner />
          </div>
        </div>
        <div className="container">
          <div className="row mt-md-5 mt-3">
            <div className="col-md-12">
              <div className="section-title mt-md-3 mt-0">
                <div className="row">
                  <div className=" col-xxl-4 col-xl-5 col-lg-6 col-md-7 ">
                    <a className="title-open-jobs">
                      <Link to="/postedjob">
                        Go to List of Till Date Posted Jobs{" "}
                      </Link>
                      <FontAwesomeIcon
                        icon="fa-solid fa-angle-right"
                        className="mx-1"
                      />{" "}
                      <Link to="/openjobs">Open Jobs</Link>{" "}
                      <FontAwesomeIcon
                        icon="fa-solid fa-angle-right"
                        className="mx-1"
                      />{" "}
                      <span> Drafted Jobs </span>
                    </a>
                  </div>
                  <div className=" col-xxl-8 col-xl-7 col-lg-6 col-md-5">
                    <div className="left-black-line"></div>
                  </div>
                  <div className="col-md-12">
                    {/* <div className="subdrafted-jobs"> */}
                    <div className="heading-holder1 text-center mt-md-3 mt-0">
                      <h5 className=" hiring-name">Drafted Jobs</h5>
                    </div>
                    <div className="text-center">
                      {/* <button className="btn ">Edit Job Draft</button> */}
                      {/* <button onClick={openModal} className='btn post-job-btn'>Edit Job Draft</button>
                                                <DraftedJobsMain isOpen={isModalOpen} onClose={closeModal} /> */}
                    </div>
                    {/* </div> */}
                  </div>
                  {/*  */}
                  <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                    <div className="first-company">
                      <div className="row">
                        <div className="col-md-3 col-3 ">
                          <div className="companyimg-desktop">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "assets/Images/logo/Profcyma.png"
                              }
                              className="company-img"
                              alt="..."
                            />
                          </div>
                          <div className="companyimg-mobile">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "assets/Images/logo/Profcyma.png"
                              }
                              className="company-img"
                              alt="..."
                            />
                          </div>
                        </div>
                        <div className="col-md-6 col-6">
                          <div className="text-center company-name">
                            <p>Profcyma Solution Pvt. Ltd</p>
                          </div>
                        </div>
                        <div className="col-md-3 col-3 text-end ">
                          <div className="btn-group secondTab_dropdown">
                            <Link
                              className="nav-link"
                              exact="true"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              id="actionButton"
                              activeclassname="active"
                            >
                              <FontAwesomeIcon icon="fa-solid fa-ellipsis-vertical" />
                            </Link>
                            <ul
                              className="dropdown-menu dropdown-menu-end p-0"
                              aria-labelledby="actionButton"
                            >
                              <li>
                                <Link className="dropdown-item" to={"/draft"}>
                                  View JD Draft
                                </Link>
                              </li>
                              <li>
                                <Link className="dropdown-item" to={"/draft"}>
                                  Edit Draft
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  to={"/editjobs"}
                                >
                                  Delete Draft
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="text-holder-employer">
                            <h5 className="position mt-3">
                              Graphic & UI Designer
                            </h5>
                            <p className="job-post mt-3">
                              Job Posted on 15/09/23
                            </p>
                            <p className="pool-silver">
                              {" "}
                              <FontAwesomeIcon
                                icon="fa-solid fa-star"
                                className="me-2"
                              />{" "}
                              Pool: Silver (S1)
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                    <div className="first-company">
                      <div className="row">
                        <div className="col-md-3 col-3">
                          <div className="companyimg-desktop">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "assets/Images/logo/Profcyma.png"
                              }
                              className="company-img"
                              alt="..."
                            />
                          </div>
                          <div className="companyimg-mobile">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "assets/Images/logo/Profcyma.png"
                              }
                              className="company-img"
                              alt="..."
                            />
                          </div>
                        </div>
                        <div className="col-md-6 col-6">
                          <div className="text-center company-name">
                            <p>Profcyma Solution Pvt. Ltd</p>
                          </div>
                        </div>
                        <div className="col-md-3 text-end col-3">
                          <div className="btn-group secondTab_dropdown">
                            <Link
                              className="nav-link"
                              exact="true"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              id="actionButton"
                              activeclassname="active"
                            >
                              <FontAwesomeIcon icon="fa-solid fa-ellipsis-vertical" />
                            </Link>
                            <ul
                              className="dropdown-menu dropdown-menu-end p-0"
                              aria-labelledby="actionButton"
                            >
                              <li>
                                <Link className="dropdown-item" to={"/draft"}>
                                  View JD Draft
                                </Link>
                              </li>
                              <li>
                                <Link className="dropdown-item" to={"/draft"}>
                                  Edit Draft
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  to={"/editjobs"}
                                >
                                  Delete Draft
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="text-holder-employer">
                            <h5 className="position mt-3">
                              Graphic & UI Designer
                            </h5>
                            <p className="job-post mt-3">
                              Job Posted on 15/09/23
                            </p>
                            <p className="pool-silver">
                              {" "}
                              <FontAwesomeIcon
                                icon="fa-solid fa-star"
                                className="me-2"
                              />{" "}
                              Pool: Silver (S1)
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                    <div className="first-company">
                      <div className="row">
                        <div className="col-md-3 col-3">
                          <div className="companyimg-desktop">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "assets/Images/logo/Profcyma.png"
                              }
                              className="company-img"
                              alt="..."
                            />
                          </div>
                          <div className="companyimg-mobile">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "assets/Images/logo/Profcyma.png"
                              }
                              className="company-img"
                              alt="..."
                            />
                          </div>
                        </div>
                        <div className="col-md-6 col-6">
                          <div className="text-center company-name">
                            <p>Profcyma Solution Pvt. Ltd</p>
                          </div>
                        </div>
                        <div className="col-md-3 col-3 text-end ">
                          <div className="btn-group secondTab_dropdown">
                            <Link
                              className="nav-link"
                              exact="true"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              id="actionButton"
                              activeclassname="active"
                            >
                              <FontAwesomeIcon icon="fa-solid fa-ellipsis-vertical" />
                            </Link>
                            <ul
                              className="dropdown-menu dropdown-menu-end p-0"
                              aria-labelledby="actionButton"
                            >
                              <li>
                                <Link className="dropdown-item" to={"/draft"}>
                                  View JD Draft
                                </Link>
                              </li>
                              <li>
                                <Link className="dropdown-item" to={"/draft"}>
                                  Edit Draft
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  to={"/editjobs"}
                                >
                                  Delete Draft
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="text-holder-employer">
                            <h5 className="position mt-3">
                              Full Stack Developer
                            </h5>
                            <p className="job-post mt-3">
                              Job Posted on 15/09/23
                            </p>
                            <p className="pool-silver">
                              {" "}
                              <FontAwesomeIcon
                                icon="fa-solid fa-star"
                                className="me-2"
                              />
                              Pool: Silver (S3)
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DraftedJobsMain;
