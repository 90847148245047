import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ScheduleModal from "../NavBar/ShortListedPortfolio/Schedulinterview/Schedulemodel.js";
import ShortBanner from "../NavBar/ShortListedPortfolio/ShortBanner/ShortBanner.js";
import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import "../ScheduleInterviewModal/ScheduleInterviewModal.css";
import "../NavBar/ShortListedPortfolio/Schedulinterview/SchedulInterview.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { Form, Row, Col, Nav } from "react-bootstrap";
import { useContext } from "react";
import { Context } from "../../../utils/context.js";

// Select to

const options = [
  { value: "Interview Type", label: "HR Round" },
  { value: "Interview Type", label: "TR Round" },
];

const options2 = [
  { value: "Location", label: "Nashik" },
  { value: "Location", label: "Pune" },
];

function ScheduleInterViewModal(props) {
  const navigate = useNavigate();
  const { getData, postData, Select2Data } = useContext(Context);

  // console.log(props);
  const values = [true];
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);

  function handleShow(breakpoint) {
    setFullscreen(breakpoint);
    setShow(true);
  }

  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const [modalShow, setModalShow] = useState(false);
  const handleClose = () => setShow(false);

  const openModal = () => {
    setModalShow(true);

    setTimeout(() => {
      navigate("/dashboardmain");
      setModalShow(false);
    }, 3000);
  };

  // time-picker

  const [selectedTime, setSelectedTime] = useState("");

  const handleTimeChange = (time) => {
    setSelectedTime(time);
  };

  //DatePicker

  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  // select 1

  const [selectedOption, setSelectedOption] = useState(null);

  // select 2

  const [selectedOptionLocation, setSelectedOptionLocation] = useState("");

  const [formData, setFormData] = useState({
    job_applyed_id: props?.data?.id,
    city_id: "",
    round_id: "",
    type: "",
    date: "",
    start_time: "",
    end_time: "",
    message: "",
    link: "",
    jobseeker_id: props?.data?.user?.id,
  });

  // console.log(formData);
  useEffect(() => {
    setFormData({
      ...formData,
      job_applyed_id: props?.data?.id,
      jobseeker_id: props?.data?.user?.id,
    });
  }, [props]);
  // console.log(formData, 'props1');

  const [rounds, setRounds] = useState();
  const [cities, setCities] = useState();
  const getMasters = async () => {
    {
      const res = await getData("/without-login/master/all-round");
      setRounds(await Select2Data(res.data, "round_id"));
    }
    {
      const res = await getData("/without-login/master/all-cities");
      setCities(await Select2Data(res.data, "city_id"));
    }
  };

  const [linkShow, setLinkShow] = useState(false);
  const handleChange = async (e) => {
    if (e?.name === "round_id" || e?.name === "city_id") {
      setFormData({ ...formData, [e?.name]: e });
    } else {
      setFormData({ ...formData, [e?.target?.name]: e?.target?.value });
      if (e?.target?.name === "type") {
        if (e?.target?.value === "Video") {
          setLinkShow(true);
        } else {
          setLinkShow(false);
        }
      }
    }
  };

  const errorStyle = {
    color: "red",
    marginLeft: "5px",
    // fontSize: "11px",
  };
  const [errors, setErrors] = useState();
  const validateForm = () => {
    let errors = {};
    const regexAlfaNumric = /^[A-Za-z0-9]+(\s[A-Za-z0-9]+)*$/;
    const regexAlfa = /^[A-Za-z]+(\s[A-Za-z]+)*$/;

    if (!formData.date) {
      errors.date = "Date is required";
    } else {
      const currentDate = new Date();
      const enteredDate = new Date(formData?.date);

      if (enteredDate <= currentDate) {
        errors.date = "Date cannot be in the past";
      }
    }
    if (!formData.start_time) {
      errors.start_time = "Start Time is required";
    }
    if (!formData.end_time) {
      errors.end_time = "End Time is required";
    } else if (new Date(formData.end_time) < new Date(formData.start_time)) {
      errors.end_time = "End Time cannot be a past time of Start Time";
    }
    if (!formData.city_id) {
      errors.city_id = "City is required";
    }
    if (!formData.round_id) {
      errors.round_id = "Round is required";
    }
    if (!formData.type) {
      errors.type = "Type is required";
    } else if (formData.type === "Video") {
      if (!formData.link) {
        errors.link = "Link is required";
      }
    }
    return errors;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();

    // console.log(validationErrors);

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        const finalData = new FormData();
        finalData.append(`city_id`, formData.city_id?.value);
        finalData.append(`round_id`, formData.round_id?.value);
        finalData.append(`date`, formData.date);
        finalData.append(`start_time`, formData.start_time);
        finalData.append(`end_time`, formData.end_time);
        finalData.append(`message`, formData.message);
        finalData.append(`link`, formData.link);
        finalData.append(`jobseeker_id`, formData.jobseeker_id);
        finalData.append(`job_applyed_id`, formData.job_applyed_id);
        finalData.append(`type`, formData.type);
        // console.log(formData);

        const result = await postData(
          "/recruiters/job/schedule-interview",
          finalData
        );

        if (result?.success) {
          await setFormData({
            job_applyed_id: props?.data?.id,
            city_id: "",
            round_id: "",
            type: "",
            date: "",
            start_time: "",
            end_time: "",
            message: "",
            link: "",
            jobseeker_id: props?.data?.user?.id,
          });

          await setErrors();
          openModal();
          props.onHide();
          props.setShortListedDisplay();
        }
      } catch (error) {
        // console.log(error);
      }
    }
  };
  useEffect(() => {
    getMasters();
  }, []);

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        fullscreen={true}
      >
        <Modal.Body>
          <section className="manage-job">
            <ShortBanner />
            <div className="container">
              <div className="row mt-5">
                <div className="col-md-12">
                  <div className="section-title mt-3">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="title-creat-job text-center">
                          <h5>Schedule Interview</h5>
                        </div>
                      </div>
                      {/*  */}
                      <div className="col-lg-12 col-md-12">
                        <div className="interview-details">
                          <div className="position">
                            <h2> Position: Data Analyst</h2>
                          </div>
                          <div className="row mt-3">
                            <div className="col-lg-2 col-md-4">
                              <p>
                                <FontAwesomeIcon
                                  icon="fa-solid fa-message"
                                  className="shorlisted-icon me-2"
                                />
                                Interview Type
                              </p>
                            </div>
                            <div className="col-lg-3 col-md-4">
                              <div className="">
                                <label className="me-2">
                                  <input
                                    type="radio"
                                    value="Walk In"
                                    checked={formData?.type === "Walk In"}
                                    onChange={handleChange}
                                    className="me-2"
                                    name="type"
                                  />
                                  Walk In
                                </label>

                                <label className="me-2">
                                  <input
                                    type="radio"
                                    value="Telephonic"
                                    checked={formData?.type === "Telephonic"}
                                    onChange={handleChange}
                                    className="me-2"
                                    name="type"
                                  />
                                  Telephonic
                                </label>
                                <label className="me-2">
                                  <input
                                    type="radio"
                                    value="Video"
                                    checked={formData?.type === "Video"}
                                    onChange={handleChange}
                                    className="me-2"
                                    name="type"
                                  />
                                  Video
                                </label>
                              </div>
                              {errors?.type && (
                                <span style={errorStyle}>{errors?.type}</span>
                              )}
                            </div>
                            {linkShow && (
                              <div className="col-lg-3 col-md-4 text-center">
                                <div className="d-flex justify-content-center">
                                  <div className="social-icon me-2 d-flex">
                                    <FontAwesomeIcon
                                      icon="fa-solid fa-link"
                                      className="shorlisted-icon me-2 mt-2"
                                    />
                                    <Form.Control
                                      type="text"
                                      placeholder="Create Video Link"
                                      value={formData?.link}
                                      name="link"
                                      onChange={handleChange}
                                    />
                                    {errors?.link && (
                                      <span style={errorStyle}>
                                        {errors?.link}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="row mt-3">
                            <div className="col-lg-2 col-md-4 mt-3 mt-md-0">
                              <div className="d-flex">
                                <div className="social-icon me-2">
                                  <FontAwesomeIcon
                                    icon="fa-solid fa-calendar-days"
                                    className="shorlisted-icon"
                                  />
                                </div>
                                <div className="date">
                                  <DatePicker
                                    selected={
                                      formData?.date
                                        ? new Date(formData?.date)
                                        : null
                                    }
                                    onChange={async (date) => {
                                      const formattedDate =
                                        date &&
                                        date.toLocaleDateString("en-US", {
                                          day: "2-digit",
                                          month: "2-digit",
                                          year: "numeric",
                                        });

                                      await setFormData({
                                        ...formData,
                                        date: formattedDate,
                                      });
                                    }}
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="Enter Date"
                                  />
                                  {errors?.date && (
                                    <span style={errorStyle}>
                                      {errors?.date}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-2 col-md-3 mt-3 mt-md-0">
                              {/* <DatePicker
                                selected={formData.start_time || null}  // Ensure formData.start_time is initially a Date object or null
                                onChange={async (time) => {
                                  // Format the time to HH:mm
                                  const formattedTime = time && time.toLocaleTimeString('en-US', { hour12: true });

                                  // Update the state with a Date object
                                  await setFormData({
                                    ...formData,
                                    start_time: formattedTime,
                                  });
                                }}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                dateFormat="h:mm aa"
                                placeholderText="Select Start Time"
                              /> */}
                              <TimePicker
                                onChange={async (time) => {
                                  // Convert the time to AM/PM format
                                  const amPmTime = new Date(
                                    `2022-01-01T${time}:00`
                                  ).toLocaleTimeString("en-US", {
                                    hour: "numeric",
                                    minute: "numeric",
                                    hour12: true,
                                  });

                                  // Update the state with the time in AM/PM format
                                  await setFormData({
                                    ...formData,
                                    start_time: amPmTime,
                                  });
                                }}
                                value={formData?.start_time}
                                format="h:mm a"
                                disableClock={true} // Set to true if you want to disable the clock view
                              />
                              {errors?.start_time && (
                                <span style={errorStyle}>
                                  {errors?.start_time}
                                </span>
                              )}
                            </div>
                            <div className="col-lg-2 col-md-3 mt-3 mt-md-0">
                              {/* <DatePicker
                                selected={formData.end_time || null}  // Ensure formData.end_time is initially a Date object or null
                                onChange={async (time) => {
                                  // Format the time to HH:mm
                                  const formattedTime = time && time.toLocaleTimeString('en-US', { hour12: true });

                                  // Update the state with a Date object
                                  await setFormData({
                                    ...formData,
                                    end_time: time || null,
                                  });
                                }}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                dateFormat="h:mm aa"
                                placeholderText="Select Start Time"
                              /> */}
                              <TimePicker
                                onChange={async (time) => {
                                  // Convert the time to AM/PM format
                                  const amPmTime = new Date(
                                    `2022-01-01T${time}:00`
                                  ).toLocaleTimeString("en-US", {
                                    hour: "numeric",
                                    minute: "numeric",
                                    hour12: true,
                                  });

                                  // Update the state with the time in AM/PM format
                                  await setFormData({
                                    ...formData,
                                    end_time: amPmTime,
                                  });
                                }}
                                value={formData?.end_time}
                                format="h:mm a"
                                disableClock={true} // Set to true if you want to disable the clock view
                              />
                              {errors?.end_time && (
                                <span style={errorStyle}>
                                  {errors?.end_time}
                                </span>
                              )}
                            </div>
                            <div className="col-lg-3 col-md-4 mt-3 mt-md-0">
                              <div className="d-flex justify-content-center">
                                <div className="social-icon d-flex">
                                  <FontAwesomeIcon
                                    icon="fa-solid fa-location-dot"
                                    className="shorlisted-icon me-2 mt-2"
                                  />
                                  <Select
                                    value={formData?.city_id}
                                    onChange={handleChange}
                                    classNamePrefix="schedulecities"
                                    options={cities}
                                    isSearchable={true}
                                  />
                                  {errors?.city_id && (
                                    <span style={errorStyle}>
                                      {errors?.city_id}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-4 mt-3 mt-md-0">
                              <div className="d-flex justify-content-center">
                                <div className="social-icon me-2 d-flex">
                                  <FontAwesomeIcon
                                    icon="fa-solid fa-user-clock"
                                    className="shorlisted-icon me-2 mt-2"
                                  />
                                  <Select
                                    value={formData?.round_id}
                                    onChange={handleChange}
                                    options={rounds}
                                    isSearchable={true}
                                  />
                                  {errors?.round_id && (
                                    <span style={errorStyle}>
                                      {errors?.round_id}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12 mt-3 ">
                              <textarea
                                className="form-control input-set"
                                placeholder="Type a Message if required"
                                type="text"
                                id=""
                                cols="30"
                                rows="3"
                                name="message"
                                value={formData?.message}
                                onChange={handleChange}
                              ></textarea>
                              {errors?.message && (
                                <span style={errorStyle}>
                                  {errors?.message}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className=" text-center mt-3 ">
                            <Button
                              variant="secondary"
                              className="send-notification me-3"
                              onClick={props.onHide}
                            >
                              Close
                            </Button>
                            <button
                              onClick={handleSubmit}
                              className="btn send-notification "
                            >
                              <FontAwesomeIcon icon="fa-solid fa-paper-plane" />{" "}
                              Send Notification to Candidate
                            </button>
                            <ScheduleModal
                              show={modalShow}
                              onHide={() => setModalShow(false)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ScheduleInterViewModal;
