import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import '../Apply/Apply.css'

const Apply2 = () => {
    const [selectedOption, setSelectedOption] = useState('');

    const handleDropdownChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleRadioChange = (event) => {
        setSelectedOption(event.target.value);
    };
    return (
        <>
            <section className="personal-details">
                <div className='container'>
                    <div className='peronal-information'>
                        <div className='row'>
                            <div className='col-md-12 '>
                                <div className='title-personal d-flex '>
                                    <p className='mx-auto' >Peronal Details</p>
                                    <p><a className='save-close-text me-5'>Save & Close</a></p>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-7 p-5 ms-5'>
                                <form>
                                    <div className='row mt-4'>
                                        <div className='col-xl-2 col-xl-2 col-lg-2 col-md-3'>
                                            <label htmlFor="name">Full Name:</label>
                                        </div>
                                        <div className='col-xl-10 col-xl-10 col-lg-10 col-md-9'>
                                            <input
                                                type="text"
                                                id="name"
                                                name="name"
                                            />
                                        </div>
                                    </div>
                                    <div className='row mt-4'>
                                        <div className='col-md-2'>
                                            <label htmlFor="Number">Contact No:</label>
                                        </div>
                                        <div className='col-md-1'>
                                            <select id="dropdown" >
                                                <option value="">+91</option>
                                                <option value="option1">+91</option>
                                                <option value="option2">+91</option>
                                                <option value="option3">+91</option>
                                            </select>
                                        </div>
                                        <div className='col-md-9'>
                                            <input
                                                type="email"
                                                id="Website"
                                                name="Website"
                                            />
                                        </div>
                                    </div>
                                    <div className='row mt-4'>
                                        <div className='col-md-2'>
                                            <label htmlFor="Website">Email ID:</label>
                                        </div>
                                        <div className='col-md-10'>
                                            <input
                                                type="email"
                                                id="Website"
                                                name="Website"
                                            />
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className='col-md-4 '>
                                <div className='user-img-class mt-5'>
                                    <img src={process.env.PUBLIC_URL + "assets/Images/banner/apply-job/Mask Group 168.png"}  className='img-class' alt="..." />
                                    <p>Upload Photo</p>
                                </div>
                            </div>
                            <div className='col-md-12'>
                                <div className='text-center'>
                                    <button className='upload-portfolio'>Upload Portfolio</button>
                                </div>
                                <div className='text-center mt-4 mb-5'>
                                    <button className='save-and-proceed'>Save and Proceed</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Apply2;