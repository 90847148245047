import React, { useContext, useEffect, useState } from "react";
// import "../ThirdTab/ThirdTab.css";
import { Link } from "react-router-dom";

import Breadcrumb from "react-bootstrap/Breadcrumb";
import ThirdTab from "../../../RecruiterPage/DashboardMain/ThirdTab/ThirdTab";
import AppliedThirdTab from "../../../RecruiterPage/DashboardMain/AppliedThirdTab/AppliedThirdTab";
import { Context } from "../../../../utils/context";

const AppliedPortfolio = () => {
  const { getData, imageUrl, postData, NoDataAnimations } = useContext(Context);
  const [display, setDisplay] = useState(false);
  const [data, setData] = useState([]);
  const [applyed, setApplyed] = useState([]);

  const getAllData = async () => {
    const res = await getData("/recruiters/job/all-appyled");
    if (res?.success) {
      setData(res.data);
    }
  };

  useEffect(() => {
    getAllData();
  }, [display]);

  const handleClick = async (val) => {
    await setApplyed(val);
    await setDisplay(!display);
  };
  return (
    <>
      <section
        className="manage-job"
        style={{ display: display ? "none" : "block" }}
      >
        <div className="container">
          <div className="row justify-content-center my-5 ">
            {data?.map((job, index) => (
              <div key={index} className="col-xl-2 col-lg-4 col-md-4 col-md-3">
                <div className="text-holder-manage-job text-md-center ">
                  <div className="matched-portfolio">
                    <p className="mb-0">Company</p>
                    <h5>{job?.company?.name}</h5>
                    <p className="mb-0">Job Role</p>
                    <h5>{job?.role?.name}</h5>

                    <button
                      className="btn view-all"
                      onClick={() => handleClick(job)}
                    >
                      View Candidate's Match
                    </button>
                  </div>
                </div>
              </div>
            ))}
            {data?.length === 0 && <NoDataAnimations />}
          </div>
        </div>
      </section>

      {display && (
        <>
          <div className="row ms-0 me-0">
            <div className="col-xl-8 col-lg-10 col-md-10  col-10 mx-auto">
              <Breadcrumb className="matchedPortfolio_breadcrum_main">
                <Breadcrumb.Item className="matchedPortfolio_breadcrum">
                  Home
                </Breadcrumb.Item>
                <Breadcrumb.Item
                  className="matchedPortfolio_breadcrum"
                  href="/dashboardmain"
                >
                  DashBoard
                </Breadcrumb.Item>
                <Breadcrumb.Item
                  className="matchedPortfolio_breadcrum"
                  onClick={() => setDisplay(false)}
                >
                  Applied PortFolios
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>

          <AppliedThirdTab
            job={applyed}
            getAllData={getAllData}
            setDisplay={() => setDisplay(false)}
          />
        </>
      )}
    </>
  );
};

export default AppliedPortfolio;
