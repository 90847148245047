import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Notification from "./Notification/Notification";


const NotificationModal = (props) => {
  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      modal-dialog-scrollable
      centered
      fullscreen={false}
      className="nottmodsa"
    >
      <Modal.Body className="p-0" style={{ maxHeight: '60vh', overflowY: 'auto' }}>
        {/* <NotificationBanner /> */}
        <section className="privacy-banner">
          <div className="container-fluid ">
            <div className="row ">
              <div className="col-md-12">
                <div className="text-holder-privacy-banner">
                  <h2>Notifications</h2>
                </div>
                <div className="go-to-text desktop-view-text">
                  <a onClick={props.onHide} style={{ cursor: "pointer" }}>
                    Close
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Notification show={props.show} />
      </Modal.Body>
      <Modal.Footer>
        <div className="mx-auto">
          <Button onClick={props.onHide} className="blue-btn-class">Close</Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default NotificationModal;
