import React, { useEffect, useState } from "react";
import "./Contactus.css";
import Select from "react-select";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import DatePicker from "react-datepicker";
import ContactModal from "./ContactModal/ContactModal";

// import "react-datepicker/dist/react-datepicker.css";
import InputGroup from "react-bootstrap/InputGroup";
import { Link, useNavigate } from "react-router-dom";

import { useContext } from "react";
import { Context } from "../../../utils/context";
const Contactus = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const options = [];

  const navigate = useNavigate();
  const { getData, postData, imageUrl } = useContext(Context);

  const [cities, setCities] = useState();

  const getCities = async () => {
    const res = await getData("/without-login/master/all-cities");
    if (res?.success) {
      // console.log(res.data, 'cities');
      const mergedOptions = [
        ...options,
        ...res.data.map((city) => ({ value: city?.id, label: city?.name })),
      ];
      // console.log(mergedOptions, 'options');
      setCities(mergedOptions);
    }
  };

  const [modalShow, setModalShow] = useState(false);

  const [nameErr, setNameErr] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [mobileErr, setMobileErr] = useState("");
  const [livingErr, setLivingErr] = useState("");
  const [messageErr, setMessageErr] = useState("");

  const handleValidation = async () => {
    var username = document.getElementById("username").value;
    var email = document.getElementById("email").value;
    var mobile = document.getElementById("mobile").value;
    var message = document.getElementById("message").value;

    // Initialize an array to store error messages
    var errors = [];

    if (username.trim() === "") {
      setNameErr("Username is required");
      errors.push("Username is required");
    } else if (!/^[a-zA-Z ]+$/.test(username)) {
      setNameErr("Username should contain only letters and spaces");
      errors.push("Username should contain only letters and spaces");
    }

    if (email.trim() === "") {
      setEmailErr("Email is required");
      errors.push("Email is required");
    } else if (!/^\S+@\S+\.\S+$/.test(email)) {
      setEmailErr("Email is not valid");
      errors.push("Email is not valid");
    }

    if (mobile.trim() === "") {
      setMobileErr("Mobile is required");
      errors.push("Mobile is required");
    } else if (!/^\d{10}$/.test(mobile)) {
      setMobileErr("Mobile should be a 10-digit number");
      errors.push("Mobile should be a 10-digit number");
    }

    if (selectedOption === null) {
      setLivingErr("Living City is required");
      errors.push("Living City is required");
    }

    if (message.trim() === "") {
      setMessageErr("Message is required");
      errors.push("Message is required");
    }

    if (errors.length === 0) {
      const data = {
        name: username,
        email: email,
        mobile: "91" + mobile,
        remark: message,
        city_id: selectedOption?.value,
      };

      const res = await postData("/without-login/contact-us", data);

      if (res?.success) {
        // console.log(res, "contact");
        setModalShow(true);

        setTimeout(() => {
          navigate("/");
          setModalShow(false);
        }, 4000);
      } else {
        // console.log(res, "fail");
      }
    }
  };

  useEffect(() => {
    getCities();
  }, []);

  return (
    <>
      <section className="requiter-signup-form">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 mx-auto">
              <div className="  heading-holder mt-3 mb-4">
                <h4>Contact Us for any Support</h4>
              </div>
            </div>
            <div className="col-xxl-9 col-xl-9 col-lg-11 col-md-12 mx-auto ">
              <div className="form-contact-main1 ">
                <div className="row contact_form_card">
                  <div className="col-xxl-6 col-xl-7 col-lg-7 ">
                    <Form className="form_contact mt-3">
                      <Row className="mb-3">
                        <Form.Group as={Col} controlId="formGridfirstname">
                          <Form.Label>
                            Full Name<span className="red-star">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Full Name"
                            id="username"
                            onKeyDown={(event) => {
                              if (!/[A-Z-a-z ]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            onChange={() => setNameErr("")}
                          />
                        </Form.Group>
                        <span
                          className="text text-danger "
                          style={{
                            textAlign: "center",
                          }}
                        >
                          {nameErr && nameErr}
                        </span>
                      </Row>

                      <Form.Group className="mb-3" controlId="formGridemail">
                        <Form.Label>
                          Email<span className="red-star">*</span>
                        </Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Enter Email"
                          id="email"
                          onChange={() => setEmailErr("")}
                        />
                        <span
                          className="text text-danger"
                          style={{
                            textAlign: "center",
                          }}
                        >
                          {emailErr && emailErr}
                        </span>
                      </Form.Group>

                      <Row className="mb-3">
                        <Form.Group as={Col} controlId="formGridpassword">
                          <Form.Label>
                            Contact Number<span className="red-star">*</span>
                          </Form.Label>
                          <div className="d-flex">
                            <Form.Select
                              defaultValue="+91"
                              className="code-hoder me-1"
                            >
                              <option>+91</option>
                            </Form.Select>
                            <Form.Control
                              type="text"
                              minlength="10"
                              maxlength="10"
                              placeholder="Enter Contact No"
                              className="mobile-holder"
                              id="mobile"
                              onKeyDown={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  if (event.key != "Backspace")
                                    event.preventDefault();
                                }
                              }}
                              onChange={() => setMobileErr("")}
                            ></Form.Control>
                          </div>
                        </Form.Group>
                        <span
                          className="text text-danger"
                          style={{
                            textAlign: "center",
                          }}
                        >
                          {mobileErr && mobileErr}
                        </span>

                        <Form.Group as={Col} controlId="formGridcity">
                          <Form.Label>
                            Living City<span className="red-star">*</span>
                          </Form.Label>
                          <Select
                            value={selectedOption}
                            onChange={(selectedOption) => {
                              setSelectedOption(selectedOption);
                              setLivingErr("");
                            }}
                            options={cities}
                            isSearchable={true}
                            id="living"
                          />
                        </Form.Group>
                        <span
                          className="text text-danger"
                          style={{
                            textAlign: "center",
                          }}
                        >
                          {livingErr && livingErr}
                        </span>
                      </Row>

                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <Form.Label>Your Message or Query</Form.Label>
                        <Form.Control
                          placeholder="Type here"
                          as="textarea"
                          style={{ height: "100px" }}
                          id="message"
                          onChange={() => setMessageErr("")}
                        />
                        <span
                          className="text text-danger"
                          style={{
                            textAlign: "center",
                          }}
                        >
                          {messageErr && messageErr}
                        </span>
                      </Form.Group>

                      <div className="mb-lg-3 mb-3 text-center">
                        {/* <Link to='/dashboardmain'> */}

                        {/* </Link> */}
                        <div>
                          <button
                            type="button"
                            onClick={handleValidation}
                            className="btn  accept-btn "
                          >
                            Submit
                          </button>
                          <ContactModal
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                          />
                        </div>
                      </div>
                    </Form>
                  </div>
                  <div className="col-xxl-6 col-xl-5 col-lg-5 p-xxl-0 p-xl-0 p-lg-0 p-md-none">
                    <div className="image-holder">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "assets/Images/banner/girlimg.png"
                        }
                        className="background mt-2"
                        alt="..."
                      />
                      <div className="img_overlay"></div>
                      <div className="overlay-holder">
                        <h5>We are</h5>
                        <h6>listening to you! </h6>
                        <p>
                          No Matter <br /> what query you have
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contactus;
