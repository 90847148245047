import React, { useState, useEffect } from "react";
import "./Header.css";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-regular-svg-icons";
import Nav from "react-bootstrap/Nav";
import Sms from "../CommonPage/Home/Animation/chatbot/Sms";
import { PacmanLoader } from "react-spinners";
import { ClipLoader } from "react-spinners";

import Cookies from "js-cookie";
import { useContext } from "react";
import { Context } from "../../utils/context";
import NotificationModal from "../CommonPage/NotificationPage/NotificationModal";
import { getData } from "../../utils/api";
const SabKaBaap = () => {
  const [modalShow, setModalShow] = useState(false);
  const navigate = useNavigate();
  const {
    ReactLoader,
    setReactLoader,
    getData,
    imageUrl,
    setSignin,
    signin,
    setUsertype,
    userType,
    jobSeekerData,
  } = useContext(Context);

  const [authToken, setAuthToken] = useState(null);
  const [authType, setAuthType] = useState(null);
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
  };

  const logoStyle = {
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
  };

  const logout = async () => {
    // Clear token cookies
    await setSignin(false);
    await setUsertype("");
    await Cookies.remove("hiclousia_website");
    // Clear type cookies
    await Cookies.remove("jwtType");
    await setAuthToken(null);
    await setAuthType(null);

    await setSignin(false);
    navigate("/");
  };
  const [notifications, setNotification] = useState();
  const getNotifications = async () => {
    const res = await getData(`/common/notifications`);
    if (res?.success) {
      setNotification(res.data);
    }
  };

  useEffect(() => {
    // const intervalId = setInterval(() => {
    getNotifications();
    // }, 10000);
    // return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    // console.log(signin, "UserAccess");
    if (signin) {
      setAuthToken(Cookies.get("hiclousia_website"));
      setAuthType(Cookies.get("jwtType"));
    } else {
      setAuthToken(null);
      setAuthType(null);
    }
    setReactLoader(false);
  }, [signin, userType]);

  return (
    <>
      <NotificationModal show={modalShow} onHide={() => setModalShow(false)} />
      {ReactLoader ? (
        <ClipLoader color="#36d7b7" />
      ) : (
        <>
          {/* Recruiter header */}
          {authType === "Recruiter" && (
            <section className="Header desktopview-recruiter-header">
              <div className="container-lg p-0 ">
                <nav className="navbar navbar-expand-md">
                  <div className="container-fluid">
                    <div className="logo">
                      {/* <FontAwesomeIcon icon={["custom", "search-plus"]} className="custom-icon" />*/}
                      <Link className="navbar-brand hiclousia_logo" href="/">
                        <img
                          className="hiclousia_logo"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/Images/logo/hiclo-logo.png"
                          }
                        />
                      </Link>
                    </div>
                    <button
                      className="navbar-toggler"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#navbarSupportedContent"
                      aria-controls="navbarSupportedContent"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                      <span className="navbar-toggler-icon"></span>
                    </button>
                    <div
                      className="collapse navbar-collapse justify-content-end"
                      id="navbarSupportedContent"
                    >
                      <ul className="navbar-nav ">
                        <li className="nav-item ">
                          <NavLink
                            className="nav-link "
                            aria-current="page"
                            activeclassname="active"
                            to={"/"}
                          >
                            Home
                          </NavLink>
                        </li>
                        <li className="nav-item ">
                          <NavLink
                            className="nav-link header-menu"
                            activeclassname="active"
                            to="/dashboardmain"
                          >
                            Dashboard
                          </NavLink>
                        </li>

                        <li className="nav-item ">
                          <NavLink
                            className="nav-link header-menu"
                            activeclassname="active"
                            to="/talentPool"
                          >
                            Talent Pool
                          </NavLink>
                        </li>
                        <li className="nav-item dropdown aboutUs_section">
                          <Link to={"/aboutUs"}>About Us</Link>
                          {/* <ul className="dropdown-menu">
                            <li>
                              <Link className="dropdown-item drop-color">
                                Initiative of IIT Madras Alumni
                              </Link>
                            </li>
                            <li>
                              <Link className="dropdown-item">
                                About Hiclousia
                              </Link>
                            </li>
                          </ul> */}
                        </li>
                        <li className="nav-item ">
                          <NavLink className="nav-link header-menu" to="/blogs">
                            Blogs
                          </NavLink>
                        </li>
                        <li className="nav-item ">
                          <NavLink
                            className="nav-link header-menu"
                            to="/contact"
                          >
                            Contact Us
                          </NavLink>
                        </li>
                        {/* <li className="nav-item search_main-section">
                          <div className="btn-group">
                            <button
                              type="button"
                              className="search_icon_menu"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              id="actionButton"
                              onClick={toggleSearch}
                            >
                              <FontAwesomeIcon
                                icon={isSearchOpen ? faTimes : faSearch}
                                className="search_header_icon"
                              />
                            </button>
                            <ul
                              className="dropdown-menu"
                              aria-labelledby="actionButton"
                              style={{
                                maxHeight: isSearchOpen ? "200px" : "0",
                                overflow: "hidden",
                                transition: "max-height 0.3s ease-in-out",
                              }}
                            >
                              {isSearchOpen && (
                                <input
                                  type="text"
                                  className="form-control search_input_field"
                                  placeholder="Search Here"
                                />
                              )}
                            </ul>
                          </div>
                        </li> */}
                        {/* <li className="nav-item bellIcon">
                          <Link to="/notification">
                            {" "}
                            <FontAwesomeIcon
                              icon={faBell}
                              className="search_header_icon"
                            />
                          </Link>
                        </li> */}

                        <li className="nav-item bellIcon">
                          <FontAwesomeIcon
                            icon={faBell}
                            className="search_header_icon"
                            onClick={() => setModalShow(true)}
                          />
                        </li>

                        <li className="nav-item dropdown Login_section">
                          <Link
                            className="nav-link dropdown-toggle user_btn  nav-menu show"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="true"
                          >
                            {jobSeekerData?.first_name &&
                              jobSeekerData?.first_name[0]}{" "}
                            {jobSeekerData?.last_name &&
                              jobSeekerData?.last_name[0]}
                          </Link>
                          <ul
                            className="dropdown-menu profile_section"
                            aria-labelledby="actionButton"
                          >
                            <li>
                              <Link
                                className="dropdown-item"
                                to={"/user-profile"}
                              >
                                My Profile
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                to={"/dashboardmain"}
                              >
                                Dashboard
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={() => setModalShow(true)}
                              >
                                Notification
                              </Link>
                            </li>
                            {/* <li>
                              <Link className="dropdown-item" to={"/profile"}>
                                Company Profile
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                to={"/talentPool"}
                              >
                                My Talent Pools (Plans)
                              </Link>
                            </li>
                            <li>
                              <Link className="dropdown-item" to={"/purchased"}>
                                My Purchased pools
                              </Link>
                            </li> */}
                            <li>
                              <Link className="dropdown-item" to={"/account"}>
                                Setting
                              </Link>
                            </li>
                            <li>
                              <Link className="dropdown-item" to={"/help"}>
                                Help Center
                              </Link>
                            </li>
                            <li>
                              <Link className="dropdown-item" to={"/privacy"}>
                                Privacy Policy
                              </Link>
                            </li>
                            <li onClick={logout}>
                              <Link className="dropdown-item">Logout</Link>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                </nav>
              </div>
            </section>
          )}

          {/* JobSeeker header */}
          {authType === "Job Seeker" && (
            <section className="Header desktopview-recruiter-header">
              <div className="container p-0 ">
                <div className="row ms-0 me-0">
                  <div className="col-lg-12 mx-auto p-0">
                    <nav className="navbar navbar-expand-lg navbar-light">
                      <Link className="navbar-brand hiclousia_logo " href="/">
                        <img
                          className="hiclousia_logo"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/Images/logo/hiclo-logo.png"
                          }
                        />
                      </Link>

                      <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarNav"
                        aria-controls="navbarNav"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                      >
                        <span className="navbar-toggler-icon"></span>
                      </button>
                      <div
                        className="collapse navbar-collapse justify-content-end"
                        id="navbarNav"
                      >
                        <ul className="navbar-nav ">
                          <li className="nav-item">
                            <NavLink
                              className="nav-link"
                              to="/"
                              exact="true"
                              activeclassname="active"
                            >
                              Home
                            </NavLink>
                          </li>

                          <li className="nav-item">
                            <NavLink
                              className="nav-link"
                              exact="true"
                              to="/dashboard-job"
                              activeclassname="active"
                            >
                              Dashboard
                            </NavLink>
                          </li>
                          <li className="nav-item">
                            <NavLink
                              className="nav-link"
                              exact="true"
                              to="/my-portfolio"
                              activeclassname="active"
                            >
                              My Portfolio
                            </NavLink>
                          </li>
                          {/* <li className="nav-item">
                            <NavLink
                              className="nav-link"
                             exact="true"
                              to="/job-search"
                              activeclassname="active"
                            >
                              Job Search
                            </NavLink>
                          </li> */}
                          {/* <li className="nav-item">
                            <NavLink
                              className="nav-link"
                             exact="true"
                              to="/matched-job"
                              activeclassname="active"
                            >
                              Matched Jobs
                            </NavLink>
                          </li> */}
                          <li className="nav-item">
                            <NavLink
                              className="nav-link"
                              exact="true"
                              to="/aboutUs"
                              activeclassname="active"
                            >
                              About Us
                            </NavLink>
                          </li>
                          <li className="nav-item">
                            <NavLink
                              className="nav-link"
                              exact="true"
                              to="/contact"
                              activeclassname="active"
                            >
                              Contact Us
                            </NavLink>
                          </li>

                          {/* <div className="btn-group">
                <button type="button" className="search_icon_menu" data-bs-toggle="dropdown" aria-expanded="false" id="actionButton" onClick={toggleSearch}><FontAwesomeIcon icon={isSearchOpen ? faTimes : faSearch} className='search_header_icon' /></button>
                <ul className="dropdown-menu" aria-labelledby="actionButton" style={{
                  maxHeight: isSearchOpen ? '200px' : '0',
                  overflow: 'hidden',
                  transition: 'max-height 0.3s ease-in-out',
                }}>
                  {isSearchOpen && (
                    <input
                      type="text"
                      className="form-control search_input_field"
                      placeholder="Search Here"
                    />
                  )}
                </ul>
              </div> */}

                          {/* <li className="nav-item dropdown searchh-headd">
                            <a
                              className="nav-link dropdown-toggle"
                              href="#"
                              role="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <button
                                type="button"
                                className="search_head_icon"
                              >
                                <FontAwesomeIcon
                                  icon={isSearchOpen ? faTimes : faSearch}
                                  className="search_header_icon"
                                  onClick={toggleSearch}
                                />
                              </button>
                            </a>
                            <ul className="dropdown-menu">
                              <input
                                className="form-control"
                                placeholder="Search Here..."
                                type=""
                              />
                            </ul>
                          </li> */}

                          <li className="nav-item bellIcon">
                            <FontAwesomeIcon
                              icon={faBell}
                              className="search_header_icon"
                              onClick={() => setModalShow(!modalShow)}
                            />
                          </li>

                          <NotificationModal
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                          />
                          <li className="nav-item Login_section">
                            <div className="btn-group">
                              <button
                                type="button"
                                className="user_btn"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                id="actionButton"
                              >
                                {jobSeekerData?.first_name &&
                                  jobSeekerData?.first_name[0]}{" "}
                                {jobSeekerData?.last_name &&
                                  jobSeekerData?.last_name[0]}
                              </button>
                              <ul
                                className="dropdown-menu"
                                aria-labelledby="actionButton"
                              >
                                <li>
                                  <Link
                                    className="dropdown-item"
                                    to={"/dashboard-job"}
                                  >
                                    Dashboard
                                  </Link>
                                </li>
                                <li>
                                  <NavLink
                                    className="dropdown-item"
                                    to={"/my-portfolio"}
                                  >
                                    My Portfolio
                                  </NavLink>
                                </li>
                                <li>
                                  <NavLink
                                    className="dropdown-item"
                                    onClick={() => setModalShow(true)}
                                  >
                                    Notification(3)
                                  </NavLink>
                                </li>
                                <li>
                                  <NavLink
                                    className="dropdown-item"
                                    to={"/account"}
                                  >
                                    Setting
                                  </NavLink>
                                </li>
                                <li>
                                  <NavLink
                                    className="dropdown-item"
                                    to={"/help"}
                                  >
                                    Help Center
                                  </NavLink>
                                </li>
                                <li>
                                  <NavLink
                                    className="dropdown-item"
                                    to={"/privacy"}
                                  >
                                    Privacy Policy
                                  </NavLink>
                                </li>
                                <li onClick={logout}>
                                  <NavLink className="dropdown-item">
                                    Logout
                                  </NavLink>
                                </li>
                              </ul>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </nav>
                  </div>
                </div>
              </div>
            </section>
          )}

          {/* Main header */}
          {authToken === null && (
            <section className="Header desktop-view-header">
              <div className="container p-0 ">
                <div className="row ms-0 me-0">
                  <div className="col-lg-12 mx-auto p-0">
                    <nav className="navbar navbar-expand-md navbar-light">
                      <Link className="navbar-brand hiclousia_logo " href="/">
                        <img
                          className="hiclousia_logo"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/Images/logo/hiclo-logo.png"
                          }
                        />
                      </Link>

                      <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarNav"
                        aria-controls="navbarNav"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                      >
                        <span className="navbar-toggler-icon"></span>
                      </button>
                      <div
                        className="collapse navbar-collapse justify-content-end"
                        id="navbarNav"
                      >
                        <ul className="navbar-nav ">
                          <li className="nav-item">
                            <NavLink
                              className="nav-link"
                              to="/"
                              exact="true"
                              activeclassname="active"
                            >
                              Home
                            </NavLink>
                          </li>
                          {/* <li className="nav-item">
                            <NavLink
                              className="nav-link"
                             exact="true"
                              to="/talentPool"
                              activeclassname="active"
                            >
                              Talent Pool
                            </NavLink>
                          </li> */}
                          <li className="nav-item aboutUs_section">
                            <div className="btn-group">
                              <Link to={"/aboutUs"}>About us</Link>
                              {/* <ul
                                className="dropdown-menu"
                                aria-labelledby="actionButton"
                              >
                                <li>
                                  <Link
                                    className="dropdown-item"
                                    to={"/aboutUs"}
                                  >
                                    Initiative of IIT Madras Alumni
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    className="dropdown-item"
                                    to={"/aboutUs"}
                                  >
                                    About Hiclousia
                                  </Link>
                                </li>
                              </ul> */}
                            </div>
                          </li>
                          <li className="nav-item">
                            <NavLink
                              className="nav-link"
                              exact="true"
                              to="/blogs"
                            >
                              Blogs
                            </NavLink>
                          </li>
                          <li className="nav-item">
                            <NavLink
                              className="nav-link"
                              exact="true"
                              to="/contact"
                            >
                              Contact us
                            </NavLink>
                          </li>

                          {/* <li className="nav-item dropdown searchh-headd">
                            <a
                              className="nav-link dropdown-toggle"
                              href="#"
                              role="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <button
                                type="button"
                                className="search_head_icon"
                              >
                                <FontAwesomeIcon
                                  icon={isSearchOpen ? faTimes : faSearch}
                                  className="search_header_icon"
                                  onClick={toggleSearch}
                                />
                              </button>
                            </a>
                            <ul className="dropdown-menu">
                              <input
                                className="form-control"
                                placeholder="Search Here..."
                                type=""
                              />
                            </ul>
                          </li> */}

                          {/* <li className="nav-item bellIcon">
                            <Link to="/notification">
                              {" "}
                              <FontAwesomeIcon
                                icon={faBell}
                                className="search_header_icon"
                              />
                            </Link>
                          </li> */}

                          {/* <li className='nav-item Login_section my-auto'>
              <div className="btn-group">
                <button type="button" className="logIn_btn" data-bs-toggle="dropdown" aria-expanded="false" id="actionButton">Login</button>
                <ul className="dropdown-menu" aria-labelledby="actionButton">
                  <li><NavLink className="dropdown-item" to={"/getotp-jobseeker"}>Job Seekers</NavLink></li>
                  <li><NavLink className="dropdown-item" to={"/getotp"}>Recruiters</NavLink></li>
                </ul>
              </div>
            </li> */}

                          <li className="nav-item  dropdown Login_section">
                            <a
                              className="nav-link dropdown-toggle"
                              href="#"
                              role="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <button type="button" className="logIn_btn">
                                Login
                              </button>
                            </a>
                            <ul className="dropdown-menu">
                              <li>
                                <NavLink
                                  className="dropdown-item"
                                  to={"/getotp-jobseeker"}
                                >
                                  Job Seekers
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  className="dropdown-item"
                                  to={"/getotp"}
                                >
                                  Recruiters
                                </NavLink>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </nav>
                  </div>
                </div>
              </div>
            </section>
          )}

          {/* Chat Bot */}
          <section className="sms-lottie">
            <div className="lottie">
              <Sms />
            </div>
          </section>

          {/* mobile-view-start */}

          {/* Recruiter header Mobile*/}
          {authType === "Recruiter" && (
            <section className="Header mobileview-recruiter-header">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-6 ps-0">
                    <div className=" my-2">
                      <div className="d-flex">
                        <button
                          className="btn menuBtn pt-1"
                          type="button"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvasmobileapp"
                          aria-controls="offcanvasExample"
                        >
                          <FontAwesomeIcon
                            icon="fa-solid fa-bars"
                            className="me-1"
                          />
                        </button>

                        <Link className="mt-1" to="/">
                          <span>Back to Dashboard</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-6 ">
                    <nav className="d-flex justify-content-end ">
                      <div className="nav-item search_main-section my-2">
                        {/* <li className="nav-item dropdown searchh-headd">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <button type="button" className="search_head_icon">
                              <FontAwesomeIcon
                                icon={isSearchOpen ? faTimes : faSearch}
                                className="search_header_icon"
                                onClick={toggleSearch}
                              />
                            </button>
                          </a>
                          <ul className="dropdown-menu">
                            <input
                              className="form-control"
                              placeholder="Search Here..."
                              type=""
                            />
                          </ul>
                        </li> */}
                      </div>
                      {/* <div className="nav-item bellIcon my-2">
                        <Link to="/notification">
                          {" "}
                          <FontAwesomeIcon
                            icon={faBell}
                            className="search_header_icon"
                          />
                        </Link>
                      </div> */}
                      <div className="nav-item bellIcon my-2">
                        {" "}
                        <FontAwesomeIcon
                          icon={faBell}
                          className="search_header_icon"
                          onClick={() => setModalShow(true)}
                        />
                      </div>
                      <div className="nav-item mt-2">
                        <div className="nav-link  user_btn  nav-menu show  pt-1">
                          {jobSeekerData?.first_name &&
                            jobSeekerData?.first_name[0]}{" "}
                          {jobSeekerData?.last_name &&
                            jobSeekerData?.last_name[0]}{" "}
                        </div>
                      </div>
                    </nav>
                  </div>
                </div>
              </div>
            </section>
          )}

          {/* JobSeeker header Mobile */}
          {authType === "Job Seeker" && (
            <section className="Header mobileview-recruiter-header">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-6 ps-0">
                    <div className=" my-2">
                      <div className="d-flex">
                        <button
                          className="btn menuBtn pt-1"
                          type="button"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvasmobileapp"
                          aria-controls="offcanvasExample"
                        >
                          <FontAwesomeIcon
                            icon="fa-solid fa-bars"
                            className="me-1"
                          />
                        </button>

                        <Link className="mt-1" to="/">
                          <span>Back to Dashboard</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-6 ">
                    <nav className="d-flex justify-content-end ">
                      <div className="nav-item search_main-section my-2">
                        {/* <div className="btn-group">
            <button type="button" className="search_icon_menu" data-bs-toggle="dropdown" aria-expanded="false" id="actionButton" onClick={toggleSearch}><FontAwesomeIcon icon={isSearchOpen ? faTimes : faSearch} className='search_header_icon' /></button>
            <ul className="dropdown-menu" aria-labelledby="actionButton" style={{
              maxHeight: isSearchOpen ? '200px' : '0',
              overflow: 'hidden',
              transition: 'max-height 0.3s ease-in-out',
            }}>
              <div className=''>
                {isSearchOpen && (

                  <input
                    type="text"
                    className="form-control search_input_field"
                    placeholder="Search Here"
                  />
                )}
              </div>


            </ul>
          </div> */}

                        {/* <li className="nav-item dropdown searchh-headd">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <button type="button" className="search_head_icon">
                              <FontAwesomeIcon
                                icon={isSearchOpen ? faTimes : faSearch}
                                className="search_header_icon"
                                onClick={toggleSearch}
                              />
                            </button>
                          </a>
                          <ul className="dropdown-menu">
                            <input
                              className="form-control"
                              placeholder="Search Here..."
                              type=""
                            />
                          </ul>
                        </li> */}
                      </div>

                      <div className="nav-item bellIcon my-2">
                        {" "}
                        <FontAwesomeIcon
                          icon={faBell}
                          className="search_header_icon"
                          onClick={() => setModalShow(true)}
                        />
                      </div>
                      <div className="nav-item mt-2">
                        <div className="nav-link  user_btn  nav-menu show  pt-1">
                          {" "}
                          {jobSeekerData?.first_name &&
                            jobSeekerData?.first_name[0]}{" "}
                          {jobSeekerData?.last_name &&
                            jobSeekerData?.last_name[0]}{" "}
                        </div>
                      </div>
                    </nav>
                  </div>
                </div>
              </div>
            </section>
          )}

          {/* Main header Mobile */}
          {authToken === null && (
            <section className="Header mobile-Header ">
              <div className="container p-0 ">
                <div className="row ms-0 me-0">
                  <div className="col-lg-12 mx-auto p-0">
                    <nav className="d-flex justify-content-end my-2">
                      <div className="nav-item search_main-section">
                        <li className="nav-item dropdown searchh-headd">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <button type="button" className="search_head_icon">
                              <FontAwesomeIcon
                                icon={isSearchOpen ? faTimes : faSearch}
                                className="search_header_icon"
                                onClick={toggleSearch}
                              />
                            </button>
                          </a>
                          <ul className="dropdown-menu">
                            <input
                              className="form-control"
                              placeholder="Search Here..."
                              type=""
                            />
                          </ul>
                        </li>
                      </div>

                      <div className="nav-item Login_section">
                        <div className="btn-group">
                          <button
                            type="button"
                            className="logIn_btn me-4"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            id="actionButton"
                          >
                            Login
                          </button>
                          <ul
                            className="dropdown-menu mt-3"
                            aria-labelledby="actionButton"
                          >
                            <li>
                              <Link
                                className="dropdown-item"
                                to={"/getotp-jobseeker"}
                              >
                                Job Seekers
                              </Link>
                            </li>
                            <li>
                              <Link className="dropdown-item" to={"/getotp"}>
                                Recruiters
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </nav>
                  </div>
                </div>
              </div>
            </section>
          )}

          {/* mobile app view offcanvas start */}

          {/* Recruiter header Canvas */}
          {authType === "Recruiter" && (
            <div className="mobile-app-offcanvas">
              <div
                className="offcanvas offcanvas-start"
                tabIndex="-1"
                id="offcanvasmobileapp"
                aria-labelledby="offcanvasExampleLabel"
              >
                <div className="offcanvas-header">
                  {/* <h5 className="offcanvas-title" id="offcanvasExampleLabel">Offcanvas</h5> */}
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="offcanvas-body pt-0">
                  <div className="row">
                    <div className="all-pages">
                      <Link to={"/user-profile"}>
                        <div
                          className=""
                          data-bs-dismiss="offcanvas"
                          aria-label="Close"
                        >
                          <div className="page-holder ">
                            <p>My Profile</p>
                          </div>
                        </div>
                      </Link>

                      <Link to={"/dashboardmain"}>
                        <div
                          className=""
                          data-bs-dismiss="offcanvas"
                          aria-label="Close"
                        >
                          <div className="page-holder pt-3">
                            <p>Dashboard</p>
                          </div>
                        </div>
                      </Link>

                      <Link onClick={() => setModalShow(true)}>
                        <div
                          className=""
                          data-bs-dismiss="offcanvas"
                          aria-label="Close"
                        >
                          <div className="page-holder pt-3">
                            <p>Notifications ({notifications?.length})</p>
                          </div>
                        </div>
                      </Link>
                      {/* 
                      <Link to={"/profile"}>
                        <div
                          className=""
                          data-bs-dismiss="offcanvas"
                          aria-label="Close"
                        >
                          <div className="page-holder pt-3">
                            <p>Company Profile</p>
                          </div>
                        </div>
                      </Link> */}
                      {/* 
                      <Link to={"/talentPool"}>
                        <div
                          className=""
                          data-bs-dismiss="offcanvas"
                          aria-label="Close"
                        >
                          <div className="page-holder pt-3">
                            <p>My Talent Pool (Plans)</p>
                          </div>
                        </div>
                      </Link>

                      <Link to={"/purchased"}>
                        <div
                          className=""
                          data-bs-dismiss="offcanvas"
                          aria-label="Close"
                        >
                          <div className="page-holder pt-3">
                            <p>My Purchased Pools</p>
                          </div>
                        </div>
                      </Link> */}

                      <Link to={"/account"}>
                        <div
                          className=""
                          data-bs-dismiss="offcanvas"
                          aria-label="Close"
                        >
                          <div className="page-holder pt-3">
                            <p>Settings</p>
                          </div>
                        </div>
                      </Link>

                      <Link to={"/help"}>
                        <div
                          className=""
                          data-bs-dismiss="offcanvas"
                          aria-label="Close"
                        >
                          <div className="page-holder pt-3">
                            <p>Help Center</p>
                          </div>
                        </div>
                      </Link>

                      <Link to={"/privacy"}>
                        <div
                          className=""
                          data-bs-dismiss="offcanvas"
                          aria-label="Close"
                        >
                          <div className="page-holder pt-3">
                            <p>Privacy Policy</p>
                          </div>
                        </div>
                      </Link>

                      <Link onClick={logout}>
                        <div
                          className=""
                          data-bs-dismiss="offcanvas"
                          aria-label="Close"
                        >
                          <div className="page-holder pt-3">
                            <p>Logout</p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Job Seeker header Canvas */}
          {authType === "Job Seeker" && (
            <div className="mobile-app-offcanvas">
              <div
                className="offcanvas offcanvas-start"
                tabIndex="-1"
                id="offcanvasmobileapp"
                aria-labelledby="offcanvasExampleLabel"
              >
                <div className="offcanvas-header">
                  {/* <h5 className="offcanvas-title" id="offcanvasExampleLabel">Offcanvas</h5> */}
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="offcanvas-body pt-0">
                  <div className="row">
                    <div className="all-pages">
                      <Link to={"/dashboard-job"}>
                        <div
                          className=""
                          data-bs-dismiss="offcanvas"
                          aria-label="Close"
                        >
                          <div className="page-holder ">
                            <p>Dashboard</p>
                          </div>
                        </div>
                      </Link>
                      <Link to={"/my-portfolio"}>
                        <div
                          className=""
                          data-bs-dismiss="offcanvas"
                          aria-label="Close"
                        >
                          <div className="page-holder ">
                            <p>My Portfolio</p>
                          </div>
                        </div>
                      </Link>
                      {/* <Link to={"/job-search"}>
                        <div
                          className=""
                          data-bs-dismiss="offcanvas"
                          aria-label="Close"
                        >
                          <div className="page-holder pt-3">
                            <p>Job Search</p>
                          </div>
                        </div>
                      </Link> */}
                      {/* <Link to={"/matched-job"}>
                        <div
                          className=""
                          data-bs-dismiss="offcanvas"
                          aria-label="Close"
                        >
                          <div className="page-holder pt-3">
                            <p>Matched Jobs</p>
                          </div>
                        </div>
                      </Link> */}
                      <Link onClick={() => setModalShow(true)}>
                        <div
                          className=""
                          data-bs-dismiss="offcanvas"
                          aria-label="Close"
                        >
                          <div className="page-holder pt-3">
                            <p>Notification({notifications?.length})</p>
                          </div>
                        </div>
                      </Link>

                      <Link to={"/account"}>
                        <div
                          className=""
                          data-bs-dismiss="offcanvas"
                          aria-label="Close"
                        >
                          <div className="page-holder pt-3">
                            <p>Setting</p>
                          </div>
                        </div>
                      </Link>

                      <Link to={"/help"}>
                        <div
                          className=""
                          data-bs-dismiss="offcanvas"
                          aria-label="Close"
                        >
                          <div className="page-holder pt-3">
                            <p>Help Center</p>
                          </div>
                        </div>
                      </Link>

                      <Link to={"/privacy"}>
                        <div
                          className=""
                          data-bs-dismiss="offcanvas"
                          aria-label="Close"
                        >
                          <div className="page-holder pt-3">
                            <p>Privacy Policy</p>
                          </div>
                        </div>
                      </Link>

                      <Link onClick={logout}>
                        <div
                          className=""
                          data-bs-dismiss="offcanvas"
                          aria-label="Close"
                        >
                          <div className="page-holder pt-3">
                            <p>Logout</p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default SabKaBaap;
