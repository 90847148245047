import React from 'react';
import '../../PrivacyPolicyPage/PrivacyBanner/PrivacyBanner.css'
import { Link } from "react-router-dom";

const HelpDeskBanner = () => {
    return (
        <>
            <section className="privacy-banner">
                <div className='container-fluid p-0 '>
                    <div className="row ms-0 me-0">
                        <div className="col-md-12 p-0">
                            {/* <div className='banner-img-short'>
                                <img src={banner} className='banner-img' />
                            </div> */}
                            <div className='text-holder-privacy-banner'>
                                <h2>Help Desk</h2>
                            </div>
                            <div className='go-to-text desktop-view-text'>
                                {/* <Link to='/dashboardmain'><a>Go To Dashboard</a></Link> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HelpDeskBanner;