import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import '../DraftedJobs/DraftedJobs.css'
import DraftedModal from './DraftedModel.js'
import { Link } from 'react-router-dom';
const DraftedMain = () => {
    const [isModalOpen, setModalOpen] = useState(false);

    const openModal = () => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    return (
        <>
            <section className='drafted-main'>
                <div className='container'>
                    <div className="row mt-md-5 mt-0">
                        <div className="col-md-12">
                            <div className="section-title mt-md-3 mt-3">


                                <div className="subdrafted-jobs">

                                    <div className="heading-holder1 text-center my-0">
                                        <h5 className="hiring-name">Drafted Jobs</h5>
                                    </div>
                                    <div className="text-center">
                                        {/* <button className="btn ">Edit Job Draft</button> */}
                                        {/* <button onClick={openModal} className='btn post-job-btn'>Edit Job Draft</button>
                                                <DraftedJobsMain isOpen={isModalOpen} onClose={closeModal} /> */}
                                    </div>
                                </div>

                                <div className=" col-xxl-5 col-xl-5 col-lg-6 col-md-6 ">
                                    <a className="title-open-jobs"><Link to='/postedjob'>Go to List of Till Date Posted Jobs </Link><FontAwesomeIcon icon="fa-solid fa-angle-right" /> <Link to='/openjobs'>Open Jobs</Link> <FontAwesomeIcon icon="fa-solid fa-angle-right" /> <span> Drafted Jobs </span></a>
                                </div>
                                <div className=" col-xxl-7 col-xl-7 col-lg-6 col-md-6">
                                    <div className="left-black-line"></div>
                                </div>

                                {/*  */}
                                <div className="col-md-12 col-12 Posted-job">
                                    <div className="first-company mt-md-2 mb-md-5">
                                        <div className="row ms-lg-5 ms-md-0">
                                            <div className="col-md-10 col-8">
                                                <div className="company-name">
                                                    <h5 className="position mt-md-3 mt-1">Graphic & UI Designer</h5>
                                                </div>
                                            </div>
                                            <div className='col-md-2 col-4 mt-3 pool-silver'><Link to='/editjobs'><FontAwesomeIcon icon="fa-solid fa-pen-to-square" /> Edit Details</Link></div>
                                            <div className="col-md-12">
                                                <div className="text-holder-employer">
                                                    <p className="job-post mt-md-3 mt-0"><p>Profcyma Solution Pvt. Ltd</p></p>
                                                </div>
                                            </div>
                                            <div className='col-md-10 col-8 d-flex'>
                                                <p className="pool-silver me-2"> <FontAwesomeIcon icon="fa-solid fa-star" /><FontAwesomeIcon icon="fa-solid fa-star" /><FontAwesomeIcon icon="fa-solid fa-star" /><FontAwesomeIcon icon="fa-solid fa-star" /><FontAwesomeIcon icon="fa-solid fa-star" className='me-2' />9,408 reviews  </p>
                                                <p className="pool-silver me-2" ><FontAwesomeIcon icon="fa-solid fa-location-dot" /> Bavdhan, Pune</p>
                                            </div>
                                            <div className='col-md-2 col-4'>
                                                <p className="pool-silver text-md-start text-end">Silver Pool (S1) </p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                {/*  */}
                                <div className='row'>
                                    <div className='col-md-1 text-end'>
                                        <img src={process.env.PUBLIC_URL + "public/assets/Images/icons/skill.png"} className='' alt='...' />
                                    </div>
                                    <div className='col-md-11'>
                                        <div className='text-holder-draft'>
                                            <h5>Skills</h5>
                                            <p>Photoshop, Illlustrator, Corel Draw, Adobe XD, Figma</p>
                                        </div>

                                    </div>
                                    <div className='col-md-1 text-end'>
                                        <FontAwesomeIcon icon="fa-solid fa-graduation-cap" className='social-icon' />
                                    </div>
                                    <div className='col-md-11'>
                                        <div className='text-holder-draft'>
                                            <h5>Qualification</h5>
                                            <p>10+2 + Any Graduate with Degree or Diploma Certification in Graphic and UI Designing </p>
                                        </div>
                                    </div>
                                    <div className='col-md-1 text-end'>
                                        <FontAwesomeIcon icon="fa-solid fa-comments-dollar" className='social-icon' />
                                    </div>
                                    <div className='col-md-11'>
                                        <div className='text-holder-draft'>
                                            <h5>Languages</h5>
                                            <p>English, Hindi, Marathi</p>
                                        </div>

                                    </div>
                                </div>
                                {/* Job Details */}
                                <div className='job-details'>
                                    <div className='row'>
                                        <div className='col-md-12 ms-5 mt-5' >
                                            <h6>Job details:</h6>
                                        </div>
                                        <div className='col-md-1 text-end'>
                                            <FontAwesomeIcon icon="fa-solid fa-money-bill-1" className='social-icon' />
                                        </div>
                                        <div className='col-md-11'>
                                            <div className='text-holder-draft'>
                                                <h5>Pay</h5>
                                                <p>₹ 18,000 - ₹ 36,000 a month</p>
                                            </div>

                                        </div>
                                        <div className='col-md-1 text-end'>
                                            <FontAwesomeIcon icon="fa-solid fa-briefcase" className='social-icon' />
                                        </div>
                                        <div className='col-md-11'>
                                            <div className='text-holder-draft'>
                                                <h5>Job type</h5>
                                                <p>Full Time</p>
                                            </div>

                                        </div>
                                        <div className='col-md-1 text-end'>
                                            <FontAwesomeIcon icon="fa-solid fa-clock" className='social-icon' />
                                        </div>
                                        <div className='col-md-11'>
                                            <div className='text-holder-draft'>
                                                <h5>Shift and Schedule</h5>
                                                <p>Day Shift</p>
                                            </div>
                                        </div>
                                        <div className='col-md-1 text-end'>
                                            <img src={process.env.PUBLIC_URL + "public/assets/Images/icons/vacancy.png"} />
                                        </div>
                                        <div className='col-md-11'>
                                            <div className='text-holder-draft' >
                                                <h5>Number of Vacancy</h5>
                                                <p>1</p>
                                            </div>
                                        </div>
                                        <div className='col-md-1 text-end'>
                                            <FontAwesomeIcon icon="fa-solid fa-clipboard" className='social-icon' />
                                        </div>
                                        <div className='col-md-11'>
                                            <div className='text-holder-draft'>
                                                <h5>Job description:</h5>
                                                <p>Urgently hiring full time in-house Graphic Designer & UI Designer.
                                                    We are looking for a Graphic Designer who will be responsible for the User interface (UI) design of various websites and design of banners
                                                    and logos. The candidate will ensure the online user experience is optimized for improved usability, usefulness, and exceptional visual design.</p>
                                            </div>
                                        </div>
                                        <div className='col-md-1 text-end'>
                                            <FontAwesomeIcon icon="fa-solid fa-clipboard" className='social-icon' />
                                        </div>
                                        <div className='col-md-11'>
                                            <div className='text-holder-draft'>
                                                <h5>Experience required: 1-2 yrs</h5>
                                            </div>
                                        </div>
                                        <div className='col-md-1 text-end'>
                                            <FontAwesomeIcon icon="fa-solid fa-location-dot" />                                            </div>
                                        <div className='col-md-11'>
                                            <div className='text-holder-draft'>
                                                <h5>Job Location: Bavdan, Pune</h5>
                                            </div>
                                        </div>
                                        <div className='col-md-1 text-end'>
                                            <FontAwesomeIcon icon="fa-solid fa-user-gear" />                                           </div>
                                        <div className='col-md-11'>
                                            <div className='text-holder-draft'>
                                                <h5>Roles & reasponsibilities</h5>
                                            </div>
                                        </div>
                                        <div className='col-md-1 text-end'>
                                            <FontAwesomeIcon icon="fa-solid fa-gear" />                                        </div>
                                        <div className='col-md-11'>
                                            <div className='text-holder-draft'>
                                                <h5>Skill Required:</h5>
                                                <ul>
                                                    <li></li>
                                                    <li></li>
                                                    <li></li>
                                                    <li></li>
                                                    <li></li>
                                                    <li></li>
                                                </ul>

                                            </div>
                                        </div>
                                        <div className='col-md-1 text-end'>
                                            <FontAwesomeIcon icon="fa-solid fa-gear" />                                          </div>
                                        <div className='col-md-11'>
                                            <div className='text-holder-draft'>
                                                <h5>Secondary Skill: Photoshop and illustrator(MUSI)</h5>
                                            </div>
                                        </div>
                                        {/* <div className='col-md-12 text-center'>
                                                <button className='btn primary-btn post-job'>Post Job</button>
                                            </div> */}
                                        <div className='col-md-12 text-center'>
                                            <button onClick={openModal} className='btn post-job-btn '>Post a Job</button>
                                            <DraftedModal isOpen={isModalOpen} onClose={closeModal} />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default DraftedMain;