import AboutBanner from "./AboutBanner/AboutBanner";
import AboutHiclousia from "./AboutHiclousia/AboutHiclousia";
import AboutText from "./AboutText/AboutText";
import Help from "./Help/Help"
import OurPartner from "./OurPartner/OurPartner";

function AboutUs() {
  return (
    <>
      <AboutBanner/>
      <AboutText/>
      <AboutHiclousia/>
      <OurPartner/>
      <Help/>
    </>   
  );
}
export default AboutUs;