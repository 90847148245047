import React, { useContext, useEffect, useState } from "react";
import "../SecondJobTab/SecondJobTab.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { Context } from "../../../../utils/context";
import JobDescriptionModal from "../../JobDescriptionModel/JobDescriptionModal";
const SecondJobTab = () => {
  const { getData, imageUrl, NoDataAnimations } = useContext(Context);

  const [jobs, setJobs] = useState([]);
  const [data, setData] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const getAllData = async () => {
    const res = await getData(`/jobseeker/shortlisted/company`);
    setJobs(res.data);
  };

  useEffect(() => {
    getAllData();
  }, []);

  const [showAll, setShowAll] = useState(false);
  const visibleJobs = showAll ? jobs : jobs?.slice(0, 6);

  return (
    <>
      <section className="Job-alert">
        <div className="desktop-view">
          <div className="sec-title mt-md-5 mt-3">
            <h5 className="title-talent">You Shortlisted by Company</h5>
          </div>
        </div>
        <div className="container">
          <div className="mobile-view-class">
            <div className="row mt-3">
              <div className="col-lg-4 col-md-3 col-3  mt-md-5 mt-0">
                <div className="black-title-line mt-3"></div>
              </div>
              <div className="col-lg-4  col-md-6 col-6 text-center mt-md-5 mt-2">
                <h5 className="title-talent">You Shortlisted by Company</h5>
              </div>
              <div className="col-lg-4 col-md-3 col-3 mt-md-5 mt-0">
                <div className="black-title-line mt-3"></div>
              </div>
            </div>
          </div>
          <div className="row mt-3 mt-md-5">
            {visibleJobs?.map((job, index) => (
              <div
                key={index}
                className="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-6 "
              >
                <div className="jobcard">
                  <div className="row ">
                    <div className="col-md-12">
                      <div className="delete-btn-mobile text-end">
                        {/* <FontAwesomeIcon icon="fa-solid fa-trash-can" /> */}
                      </div>
                    </div>
                    <div className="col-md-11">
                      <div className="text-center">
                        <img
                          // src={imageUrl + job?.job?.company?.image}
                          src={imageUrl + job?.job?.job_contact?.logo}
                          className="companyimg-short"
                          alt="..."
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-md-4 justify-content-center">
                    <div className="col-xxl-8 col-xl-8 col-lg-9 col-md-10">
                      <div className="row">
                        {job?.job?.job_create_shifts?.map((d) => (
                          <div className="col-md-4 col-sm-4 col-12 g-0">
                            <div className=" text-center">
                              <button className="full-time-btn ">
                                {d?.shift?.name}
                              </button>
                            </div>
                          </div>
                        ))}

                        {/* <div className="col-md-4 col-sm-4 col-12 g-0">
                          <div className=" text-center ">
                            <button className="btn part-time-btn">
                              Part Time
                            </button>
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-4 col-12 g-0">
                          <div className=" text-center ">
                            <button className="btn freeLance-btn">
                              FreeLance
                            </button>
                          </div>
                        </div> */}
                      </div>
                    </div>
                    <div className="col-md-12 text-center">
                      <p className="mt-4 position">{job?.job?.role?.name}</p>
                      <div className="text-holder-job">
                        <p className="mt-md-4">
                          <FontAwesomeIcon icon="fa-solid fa-location-dot" />{" "}
                          {job?.job?.job_work_locations
                            ?.slice(0, 2)
                            .map((type, i) => type?.city?.name)
                            .join(", ")}
                        </p>
                        <p className="mt-md-4">
                          Status:{job?.job_apply_status?.name}
                        </p>
                      </div>
                      <div className="explore-btn">
                        {/* <Link to="/job-description"> */}
                        <button
                          className="btn"
                          type="button"
                          onClick={() => {
                            setData(job?.job);
                            setModalShow(true);
                          }}
                        >
                          Explore
                        </button>
                        {/* </Link> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {jobs?.length == 0 && <NoDataAnimations />}
          </div>
        </div>

        <JobDescriptionModal
          title={"Job Description"}
          show={modalShow}
          data={data}
          onHide={() => setModalShow(false)}
        />
        <div className="row mt-4 mb-4">
          <div className="col-lg-5 col-md-4 ">
            <div className="black-title-line1 "></div>
          </div>
          <div className="col-lg-2  col-md-4 text-center ">
            {jobs?.length > 6 && (
              <button
                className="show-more2"
                onClick={() => setShowAll(!showAll)}
              >
                {showAll ? "Show Less" : "Show More"}{" "}
                <FontAwesomeIcon icon="chevron-down" />
              </button>
            )}
            {jobs?.length === 0 && (
              <span className="text tex-danger">Not Shortlisted Yet...!</span>
            )}
          </div>

          <div className="col-lg-5 col-md-4 ">
            <div className="black-title-line1 "></div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SecondJobTab;
