import React, { useState } from 'react'
import './PortfolioJobExperience.css';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';
const PersonalDetailForm = () => {

    return (
        <>
            <section className='portfolio-experience'>
                <div className='container'>
                    <div className='row'>
                        <div className='heading-holder mb-3 text-center'>
                            <h4>My Portfolio</h4>
                        </div>
                    </div>
                    <div className='black-header'>
                        <div className='row'>
                            <div className='col-md-4 col-4'>
                                <Link to='/dashboard-job'>
                                    <div className='text-holder pt-2 ms-3'>
                                        <p>Back</p>
                                    </div>
                                </Link>
                            </div>
                            <div className='col-md-4 col-4'>
                                <div className='text-holder text-center pt-2'>
                                    <h5>Personal Details</h5>
                                </div>
                            </div>
                            <div className='col-md-4 col-4'>
                                <Link to='/my-portfolio'>
                                    <div className='text-holder text-end pt-2 me-3'>
                                        <p>Save & Close</p>
                                    </div></Link>
                            </div>
                        </div>
                    </div>
                    <div className='form-bg'>
                        <Form>
                            <Form.Group as={Row} className="mb-4" controlId="formPlaintext Project">
                                <Form.Label column lg="2">
                                    Full Name:
                                </Form.Label>
                                <Col lg="10">
                                    <Form.Control type="text" placeholder="Surajkumar Rao" />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-4" controlId="formPlaintextRole">
                                <Form.Label column lg="2">
                                    Project Nature:
                                </Form.Label>
                                <Col lg="4">
                                    <div className='d-flex'>
                                        <Form.Select aria-label="Default select example" className='code-holder'>
                                            <option>+91</option>
                                            <option value="1"></option>
                                            <option value="2"></option>
                                            <option value="3"></option>
                                        </Form.Select>
                                        <Form.Control type="text" placeholder="9856324745" />
                                    </div>
                                </Col>
                                <Form.Label column lg="2">
                                    Email ID.:
                                </Form.Label>
                                <Col lg="4">
                                    <Form.Control type="email" placeholder="surajkumarrao@dummymail.com" />
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-4" controlId="formPlaintextSkills">
                                <Form.Label column lg="2">
                                    Address:
                                </Form.Label>
                                <Col lg="10">
                                    <Form.Control type="text" placeholder="" />
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-4" controlId="formPlaintextCompany">
                                <div className='d-flex'>
                                    <Form.Label column lg="2">
                                        Social Media Links:
                                    </Form.Label>
                                    <Col lg="4">
                                        <span className='social-link'>https://facebook.com/Surajkumar Rao</span>
                                        <span className='social-link'> https://linkedin.com/in/Surajkumar Rao</span>
                                    </Col>
                                    <div className='ms-auto'>
                                        <p className='add-links'>Add Link/s</p>
                                    </div>
                                </div>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-4" controlId="formPlaintextReporting">
                                <Form.Label column lg="2">
                                    Resume Headline:
                                </Form.Label>
                                <Col lg="10">
                                    <Form.Control as="textarea" rows={1} />
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-4" controlId="formPlaintextPeriod">
                                <Form.Label column lg="2">
                                    Uploads:
                                </Form.Label>
                                <Col lg="4">
                                    <button className='btn btn-photo'>
                                        <img src={process.env.PUBLIC_URL + "assets/Images/my-portfolio/material-photo.png"} className='logo-img me-2' />Photo</button>

                                </Col>
                                <Form.Label column lg="2">
                                    Birth Date.:
                                </Form.Label>
                                <Col lg="4">
                                    <div className='d-flex'>
                                        <Form.Select aria-label="Default select example" className='me-2'>
                                            <option>17</option>
                                            <option value="1"></option>
                                            <option value="2"></option>
                                            <option value="3"></option>
                                        </Form.Select>
                                        <Form.Select aria-label="Default select example" className='me-2'>
                                            <option>04</option>
                                            <option value="1"></option>
                                            <option value="2"></option>
                                            <option value="3"></option>
                                        </Form.Select>
                                        <Form.Select aria-label="Default select example">
                                            <option>2000</option>
                                            <option value="1"></option>
                                            <option value="2"></option>
                                            <option value="3"></option>
                                        </Form.Select>
                                    </div>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-4" controlId="formPlaintextReporting">
                                <Form.Label column lg="2">
                                    Personal Status:
                                </Form.Label>
                                <Col lg="10">
                                    <div className=''>
                                        <button className='btn btn-status me-1'> Single / Unmarried</button>
                                        <button className='btn btn-status me-1'>Married</button>
                                        <button className='btn btn-status me-1'> Divorced</button>
                                        <button className='btn btn-status me-1'> Widowed</button>
                                        <button className='btn btn-status me-1'> Seprated</button>
                                        <button className='btn btn-status'> Other</button>
                                    </div>

                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-4" controlId="formPlaintextReporting">
                                <Form.Label column lg="2">
                                    More Information:
                                </Form.Label>
                                <Col lg="10">
                                    <div className=''>
                                        <button className='btn btn-status me-1'>Single Parent</button>
                                        <button className='btn btn-status me-1'>Working Mother</button>
                                        <button className='btn btn-status me-1'>Served in Defence / Security</button>
                                        <button className='btn btn-status'>Retired</button>

                                    </div>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-4" controlId="formPlaintextReporting">
                                <Form.Label column lg="2" md="6" col="6">
                                    Differently abled?
                                </Form.Label>
                                <Col lg="4" md="6" col="6">

                                    {['radio'].map((type) => (
                                        <div key={`default-${type}`} className="mb-3 d-flex">

                                            <Form.Check // prettier-ignore
                                                type={type}
                                                id={`default-${type}`}
                                                label={`Yes `}
                                                className='me-2'
                                            />

                                            <Form.Check

                                                type={type}
                                                label={`No `}
                                                id={`disabled-default-${type}`}
                                            />
                                        </div>

                                    ))}

                                </Col>
                                <Form.Label column="6" lg="4" md="6" className='text-lg-end'>
                                    Taken Career Break?
                                </Form.Label>
                                <Col lg="2" md="6" >

                                    {['radio'].map((type) => (
                                        <div key={`default-${type}`} className="mb-3 d-flex ">
                                            <Form.Check // prettier-ignore
                                                type={type}
                                                id={`default-${type}`}
                                                label={`Yes `}
                                                className='me-2'
                                            />
                                            <Form.Check

                                                type={type}
                                                label={`No `}
                                                id={`disabled-default-${type}`}
                                            />
                                        </div>
                                    ))}
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-4 desktop-view-lang" controlId="formPlaintextReporting">
                                <Form.Label column lg="2">
                                    Languages:
                                </Form.Label>
                                <Col lg="10">
                                    <div className='row'>
                                        <div className='col-md-4 col-6'>
                                            <Form.Select aria-label="Default select example" className='me-2'>
                                                <option>Hindi</option>
                                                <option value="1"></option>
                                                <option value="2"></option>
                                                <option value="3"></option>
                                            </Form.Select>
                                            <Form.Select aria-label="Default select example" className='me-2'>
                                                <option>Englisih</option>
                                                <option value="1"></option>
                                                <option value="2"></option>
                                                <option value="3"></option>
                                            </Form.Select>
                                            <Form.Select aria-label="Default select example" className='me-2'>
                                                <option>Marathi</option>
                                                <option value="1"></option>
                                                <option value="2"></option>
                                                <option value="3"></option>
                                            </Form.Select>
                                        </div>
                                        <div className='col-md-4 col-6'>
                                            <Form.Select aria-label="Default select example" className='me-2'>
                                                <option>Expert</option>
                                                <option value="1"></option>
                                                <option value="2"></option>
                                                <option value="3"></option>

                                            </Form.Select>
                                            <Form.Select aria-label="Default select example" className='me-2'>
                                                <option>Proficient</option>
                                                <option value="1"></option>
                                                <option value="2"></option>
                                                <option value="3"></option>

                                            </Form.Select>
                                            <Form.Select aria-label="Default select example" className='me-2'>
                                                <option>Proficient</option>
                                                <option value="1"></option>
                                                <option value="2"></option>
                                                <option value="3"></option>

                                            </Form.Select>
                                        </div>
                                        <div className='col-md-4 col-12'>
                                            <div className='d-flex'>
                                                <Form.Group className="mb-3 me-2" id="formGridCheckbox">
                                                    <Form.Check type="checkbox" label="Read" />
                                                </Form.Group>

                                                <Form.Group className="mb-3 me-2" id="formGridCheckbox">
                                                    <Form.Check type="checkbox" label="Write" />
                                                </Form.Group>

                                                <Form.Group className="mb-3" id="formGridCheckbox">
                                                    <Form.Check type="checkbox" label="Speak" />
                                                </Form.Group>
                                            </div>
                                            <div className='d-flex'>
                                                <Form.Group className="mb-3 me-2" id="formGridCheckbox">
                                                    <Form.Check type="checkbox" label="Read" />
                                                </Form.Group>

                                                <Form.Group className="mb-3 me-2" id="formGridCheckbox">
                                                    <Form.Check type="checkbox" label="Write" />
                                                </Form.Group>

                                                <Form.Group className="mb-3" id="formGridCheckbox">
                                                    <Form.Check type="checkbox" label="Speak" />
                                                </Form.Group>
                                            </div>
                                            <div className='d-flex'>
                                                <Form.Group className="mb-3 me-2" id="formGridCheckbox">
                                                    <Form.Check type="checkbox" label="Read" />
                                                </Form.Group>

                                                <Form.Group className="mb-3 me-2" id="formGridCheckbox">
                                                    <Form.Check type="checkbox" label="Write" />
                                                </Form.Group>

                                                <Form.Group className="mb-3" id="formGridCheckbox">
                                                    <Form.Check type="checkbox" label="Speak" />
                                                </Form.Group>
                                            </div>
                                        </div>
                                        <p className='more-lang'>Add More Language/s</p>
                                    </div>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-4 mobile-view-language" controlId="formPlaintextReporting">
                                <div className='d-flex'>
                                    <Form.Label   >
                                        Languages:
                                    </Form.Label>
                                    <Form.Label className='ms-auto'>
                                        <p className='more-lang '>Add More Language/s</p>
                                    </Form.Label>
                                </div>
                                <Col lg="12">
                                    <div className='row'>
                                        <div className='col-md-4 col-6'>
                                            <Form.Select aria-label="Default select example" className='me-2'>
                                                <option>Hindi</option>
                                                <option value="1"></option>
                                                <option value="2"></option>
                                                <option value="3"></option>

                                            </Form.Select>
                                            <div className='d-flex'>
                                                <Form.Group className="mb-3 me-2" id="formGridCheckbox">
                                                    <Form.Check type="checkbox" label="Read" />
                                                </Form.Group>
                                                <Form.Group className="mb-3 me-2 ms-auto" id="formGridCheckbox">
                                                    <Form.Check type="checkbox" label="Write" />
                                                </Form.Group>
                                            </div>
                                            <Form.Select aria-label="Default select example" className='me-2'>
                                                <option>Englisih</option>
                                                <option value="1"></option>
                                                <option value="2"></option>
                                                <option value="3"></option>
                                            </Form.Select>
                                            <div className='d-flex'>
                                                <Form.Group className="mb-3 me-2" id="formGridCheckbox">
                                                    <Form.Check type="checkbox" label="Read" />
                                                </Form.Group>
                                                <Form.Group className="mb-3 me-2 ms-auto" id="formGridCheckbox">
                                                    <Form.Check type="checkbox" label="Write" />
                                                </Form.Group>
                                            </div>
                                            <Form.Select aria-label="Default select example" className='me-2'>
                                                <option>Marathi</option>
                                                <option value="1"></option>
                                                <option value="2"></option>
                                                <option value="3"></option>
                                            </Form.Select>
                                            <div className='d-flex'>
                                                <Form.Group className="mb-3 me-2" id="formGridCheckbox">
                                                    <Form.Check type="checkbox" label="Read" />
                                                </Form.Group>
                                                <Form.Group className="mb-3 me-2 ms-auto" id="formGridCheckbox">
                                                    <Form.Check type="checkbox" label="Write" />
                                                </Form.Group>
                                            </div>
                                        </div>
                                        <div className='col-md-4 col-6'>
                                            <Form.Select aria-label="Default select example" className='me-2'>
                                                <option>Expert</option>
                                                <option value="1"></option>
                                                <option value="2"></option>
                                                <option value="3"></option>

                                            </Form.Select>

                                            <Form.Group className="mb-3" id="formGridCheckbox">
                                                <Form.Check type="checkbox" label="Speak" />
                                            </Form.Group>
                                            <Form.Select aria-label="Default select example" className='me-2'>
                                                <option>Proficient</option>
                                                <option value="1"></option>
                                                <option value="2"></option>
                                                <option value="3"></option>

                                            </Form.Select>

                                            <Form.Group className="mb-3" id="formGridCheckbox">
                                                <Form.Check type="checkbox" label="Speak" />
                                            </Form.Group>
                                            <Form.Select aria-label="Default select example" className='me-2'>
                                                <option>Proficient</option>
                                                <option value="1"></option>
                                                <option value="2"></option>
                                                <option value="3"></option>

                                            </Form.Select>

                                            <Form.Group className="mb-3" id="formGridCheckbox">
                                                <Form.Check type="checkbox" label="Speak" />
                                            </Form.Group>
                                        </div>
                                        {/* <div className='col-md-4 col-12'>
                                            <div className='d-flex'>
                                                <Form.Group className="mb-3 me-2" id="formGridCheckbox">
                                                    <Form.Check type="checkbox" label="Read" />
                                                </Form.Group>

                                                <Form.Group className="mb-3 me-2" id="formGridCheckbox">
                                                    <Form.Check type="checkbox" label="Write" />
                                                </Form.Group>

                                                <Form.Group className="mb-3" id="formGridCheckbox">
                                                    <Form.Check type="checkbox" label="Speak" />
                                                </Form.Group>
                                            </div>
                                            <div className='d-flex'>
                                                <Form.Group className="mb-3 me-2" id="formGridCheckbox">
                                                    <Form.Check type="checkbox" label="Read" />
                                                </Form.Group>

                                                <Form.Group className="mb-3 me-2" id="formGridCheckbox">
                                                    <Form.Check type="checkbox" label="Write" />
                                                </Form.Group>

                                                <Form.Group className="mb-3" id="formGridCheckbox">
                                                    <Form.Check type="checkbox" label="Speak" />
                                                </Form.Group>
                                            </div>
                                            <div className='d-flex'>
                                                <Form.Group className="mb-3 me-2" id="formGridCheckbox">
                                                    <Form.Check type="checkbox" label="Read" />
                                                </Form.Group>

                                                <Form.Group className="mb-3 me-2" id="formGridCheckbox">
                                                    <Form.Check type="checkbox" label="Write" />
                                                </Form.Group>

                                                <Form.Group className="mb-3" id="formGridCheckbox">
                                                    <Form.Check type="checkbox" label="Speak" />
                                                </Form.Group>
                                            </div>
                                        </div> */}

                                    </div>
                                </Col>
                            </Form.Group>
                            <div className='button-holder text-center mb-md-0 mb-3'>
                                <Link to='/my-portfolio'>  <button className='btn btn-cancle me-2' >Cancel</button></Link>
                                <Link to='/my-portfolio'>  <button className='btn btn-save' >Save</button></Link>
                            </div>
                        </Form>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PersonalDetailForm