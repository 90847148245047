import React from "react";
import '../PurchasedPool/PurchasedPool.css'
import { Link } from "react-router-dom";

const PurchasedPool = () => {
    return (
        <>
            <section className="manage-job">
                <div className='container'>
                    <div className="row mt-4">
                        <div className="col-md-12">
                            <div className="section-title">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h2 className="title-purchaed-pool">My Purchased Pools</h2>
                                    </div>
                                    <div className=" col-xxl-2 col-xl-2 col-lg-2 col-md-3 mt-2 ">
                                        <Link to='/DashBoardMain'><a className="title-open-jobs">Go to Dashboard</a></Link>
                                    </div>
                                    <div className=" col-xxl-10 col-xl-10 col-lg-10 col-md-9">
                                        <div className="left-black-line"></div>
                                    </div>
                                </div>
                                <div className="first-pool-plan"> 
                                    <div className="row text-center ">
                                        <div className="col-md-3 col-3">
                                            <p>My Plans</p>
                                        </div>
                                        <div className="col-md-3 col-3">
                                            <p>Company Name</p>
                                        </div>
                                        <div className="col-md-3 col-3">
                                            <p>Status </p>
                                        </div>
                                        <div className="col-md-3 col-3">
                                            <p>Talent Pool Access</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="second-pool-plan">
                                    <div className="row text-center">
                                        <div className="col-md-3 col-3">
                                            <p>Platinum (P3)</p>
                                        </div>
                                        <div className="col-md-3 col-3">
                                            <p>Capgemini</p>
                                        </div>
                                        <div className="col-md-3 col-3">
                                            <p>Inactive</p>
                                        </div>
                                        <div className="col-md-3 col-3">
                                            <Link to='/dashboardmain'>Click Here</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="second-pool-plan">
                                    <div className="row text-center">
                                        <div className="col-md-3 col-3">
                                            <p>Silver (S1)</p>
                                        </div>
                                        <div className="col-md-3 col-3">
                                            <p>Graphic & UI Designer</p>
                                        </div>
                                        <div className="col-md-3 col-3">
                                            <p>Active</p>
                                        </div>
                                        <div className="col-md-3 col-3">
                                            <Link to='/dashboardmain'>Click Here</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PurchasedPool;