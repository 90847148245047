import React, { useState, useEffect } from "react";
import "../PostedJobs/PostedJobs.css";
import companyimg from "../Image/img1.png";
import companyimg1 from "../Image/img2.png";
import starimg from "../Image/star.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import PostedModaledit from "./Modal/PostedModaledit";
import JobDescriptionModal from "../../../JobseekerPage/JobDescriptionModel/JobDescriptionModal";

import { useContext } from "react";
import { Context } from "../../../../utils/context";
const PostedJobs = () => {
  const {
    getData,
    imageUrl,
    NoDataAnimations,
    createdStatus,
    setCreatedStatus,
  } = useContext(Context);

  const [show, setShow] = useState(false);
  const [data, setData] = useState({});
  const [modalShow, setModalShow] = React.useState(0);

  const [showAll, setShowAll] = useState(false);
  // const [jobId, setJobId] = useState("");

  const [postedJobs, setPostedJob] = useState();

  const getPostedJobs = async () => {
    const res = await getData("/recruiters/job/job-create");
    if (res?.success) {
      setPostedJob(res?.data?.data);
    }
  };

  const visibleJobs = showAll ? postedJobs : postedJobs?.slice(0, 8);

  useEffect(() => {
    getPostedJobs();
  }, []);
  useEffect(() => {
    getPostedJobs();
  }, [createdStatus]);
  return (
    <>
      <section className="Posted-job">
        <div className="sec-title mt-md-5 mt-5">
          <h5 className="title-talent">My Posted Jobs</h5>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 mx-auto">
              <div className="row ">
                {visibleJobs?.map((job, index) => (
                  <div
                    key={job?.id}
                    className="col-xl-3 col-lg-4 col-md-6 col-12"
                  >
                    <div className="first-company postedJobs_Main">
                      <div className="row">
                        <div className="col-lg-3 col-md-3 col-3 p-0">
                          <div className="company_img_sec">
                            <img
                              src={imageUrl + job?.job_contact?.logo}
                              className="company-img"
                              alt="Logo"
                            />
                          </div>
                        </div>
                        <div className="col-lg-7 col-md-8 col-8 text-center">
                          <div className="text-start company-name">
                            <p>{job?.pool_subscriber?.company_name}</p>
                          </div>
                        </div>

                        <div className="col-lg-2 col-md-1 col-1 text-end ">
                          <div className="btn-group secondTab_dropdown">
                            <Link
                              className="nav-link"
                              exact="true"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              id="actionButton"
                              activeclassname="active"
                            >
                              <FontAwesomeIcon icon="fa-solid fa-ellipsis-vertical" />
                            </Link>
                            <ul
                              className="dropdown-menu dropdown-menu-end p-0"
                              aria-labelledby="actionButton"
                            >
                              <li
                                variant="primary"
                                onClick={() => {
                                  setModalShow(job?.id);
                                }}
                              >
                                <Link className="dropdown-item">
                                  Edit Job details
                                </Link>
                              </li>
                              {/* <li>
                                <Link className="dropdown-item">
                                  View Job details
                                </Link>
                              </li> */}
                            </ul>
                          </div>
                        </div>

                        <div className="col-md-12">
                          {/* <Link to="/postedjobmain"> */}
                          <div
                            className="text-holder-employer"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setData(job);
                              setShow(true);
                            }}
                          >
                            <h5 className="position mt-3">{job?.role?.name}</h5>
                            <p className="job-post mt-md-3">
                              Job Posted on :{" "}
                              {new Date(job?.createdAt).toLocaleDateString(
                                "en-US",
                                {
                                  day: "2-digit",
                                  month: "short",
                                  year: "numeric",
                                }
                              )}
                            </p>
                            <p className="job-post mt-md-3">
                              Company : {job?.company?.name}
                            </p>
                            <p className="pool-silver">
                              {" "}
                              <img
                                src={starimg}
                                className="star-img me-2"
                                alt="Star"
                              />
                              Pool:{" "}
                              {
                                job?.talent_pool_plan?.talent_pool_subcategory
                                  ?.name
                              }
                            </p>
                          </div>
                          {/* </Link> */}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                {postedJobs?.length === 0 && <NoDataAnimations />}
                <PostedModaledit
                  show={modalShow}
                  setModalShow={setModalShow}
                  getPostedJobs={getPostedJobs}
                />
                <div className="text-center mt-md-5 mb-md-5 mt-3 mb-5">
                  {/* <button className='show-more2 '>Show More <FontAwesomeIcon icon="fa-solid fa-chevron-down 2" /> </button> */}
                  {postedJobs?.length > 8 && (
                    <button
                      className="show-more2"
                      onClick={() => setShowAll(!showAll)}
                    >
                      {showAll ? "Show Less" : "Show More"}{" "}
                      <FontAwesomeIcon icon="chevron-down" />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <JobDescriptionModal
        show={show}
        data={data}
        title={"Job Description"}
        onHide={() => setShow(false)}
      />
    </>
  );
};

export default PostedJobs;
