import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";

// Common Pages
import MainHome from "../CommonPage/Home/MainHome/MainHome.js";
import JobExp from "../CommonPage/JobExplore/JobExp.js";
import TalentPool from "../RecruiterPage/NavBar/Talent_pool/TalentPool.js";
import AboutUs from "../CommonPage/AboutUs/AboutUs.js";
import Blogs from "../CommonPage/Blogs/Blogs.js";
import BlogDetails from "../CommonPage/Blogs/BlogDetails/BlogDetails.js";
import Contactus from "../CommonPage/Contact/Contactus.js";
import CompanyProfile from "../RecruiterPage/NavBar/Company-Profile/CompanyProfile.js";
import UserProfile from "../RecruiterPage/NavBar/UserProfile/UserProfile.js";
import TermsCondition from "../CommonPage/TermsCondition/TermsCondition.js";
import PrivacyPolicyPage from "../CommonPage/PrivacyPolicyPage/PrivacyPolicyPage.js";
import HelpDeskPage from "../CommonPage/HelpDeskPage/HelpDeskPage.js";
import NotificationPage from "../CommonPage/NotificationPage/NotificationPage.js";
import PageNotFound from "../CommonPage/PageNotFound/PageNotFound.js";
import AccountManagement from "../CommonPage/AccountManagement/AccountManagement.js";
import AccountText2 from "../CommonPage/AccountManagement1/AccountText-2/AccountText2.js";
import AccountText3 from "../CommonPage/AccountManagement1/AccountText-3/AccountText3.js";
import AccountText4 from "../CommonPage/AccountManagement1/AccountText-4/AccountText4.js";
import AccountDelete from "../CommonPage/AccountManagement1/AccountDelete/AccountDelete.js";

//Recruiter
import RequirterSignUp1 from "../RecruiterPage/LoginSignUp/Reguirter-signup1/RequirterSignUp1.js"; //old signup
import LoginPage from "../RecruiterPage/LoginSignUp/login-page/LoginPage.js";
import Resetp from "../RecruiterPage/LoginSignUp/ResetP/ResetP.js";
import Getotp from "../RecruiterPage/LoginSignUp/login-page/GetOtp/Getotp.js";
import DashBoardMain from "../RecruiterPage/DashboardMain/DashboardMain.js";
import ManangeJobs from "../RecruiterPage/DashboardMain/ManageJobs/ManageJobs.js";
import CreateJobs from "../RecruiterPage/DashboardMain/ManageJobs/CreateJobs/CreateJobs.js";
import OpenJobs from "../RecruiterPage/DashboardMain/ManageJobs/OpenJobs/OpenJobs.js";
import SearchPool from "../RecruiterPage/DashboardMain/ManageJobs/SearchPool/SearchPool.js";
import HiringFunnel from "../RecruiterPage/DashboardMain/ManageJobs/HiringFunnel/HiringFunnel.js";
import DraftedJobs from "../RecruiterPage/DashboardMain/ManageJobs/DraftedJobs/DraftedJobs.js";
import ShortListedPortFolio from "../RecruiterPage/NavBar/ShortListedPortfolio/ShortListedPortfolio.js";
import InnerPage from "../CommonPage/Home/JobCategory/InnerPage/InnerPage.js";
import PortFolio from "../CommonPage/PortFolio/PortFolio.js";
import HiringFunnel2 from "../RecruiterPage/DashboardMain/ManageJobs/HiringFunnel2/HiringFunnel2.js";
import Buynow from "../RecruiterPage/NavBar/Talent_pool/BuyNow/Buynow.js";
import PurchasedPool from "../RecruiterPage/NavBar/PurchasedPool/PurchasedPool.js";
import UserTalentPool from "../RecruiterPage/NavBar/UserProfile/UserTalentPool.js";
import ViewJobs from "../RecruiterPage/DashboardMain/ManageJobs/ViewJobs/ViewJobs.js";
import EditJobs from "../RecruiterPage/DashboardMain/ManageJobs/EditJobs/EditJobs.js";
import PostedJobMain from "../RecruiterPage/DashboardMain/PostedJobMain/PostedJobMain.js";
import PostedJobs from "../RecruiterPage/DashboardMain/PostedJobs/PostedJobs.js";
import EditJobs2 from "../RecruiterPage/DashboardMain/ManageJobs/EditJobs/EditJobs2.js";
import DraftedJobsMain from "../RecruiterPage/DashboardMain/ManageJobs/DraftedJobs/DraftedJobsMain.js";
import DraftedMain from "../RecruiterPage/DashboardMain/ManageJobs/DraftedJobs/DraftedMain.js";
import ThirdTab from "../RecruiterPage/DashboardMain/ThirdTab/ThirdTab.js";
import ShortlistedInterview from "../RecruiterPage/DashboardMain/FourthTab/ShortlistedInterview.js";
import ScrollbleTab from "../JobseekerPage/ScrollableTab/ScrollableTab.js";
// Jobseeker
import DashBoardJob from "../JobseekerPage/DashBoardJob/DashBoardJob.js";
import SignupForm from "../RecruiterPage/LoginSignUp/SignUpForm/SignUpForm.js";
import AppliedJobs from "../JobseekerPage/DashBoardJob/AppliedJobs/AppliedJobs.js";
import ApplyJobs from "../JobseekerPage/ApplyJobs/ApplyJobs.js";
import MatchedJob from "../JobseekerPage/MatchedJob/MatchedJob.js";
import NotiFicationJob from "../JobseekerPage/NotificationJob/NotificationJob.js";
import JobDescription from "../JobseekerPage/DashBoardJob/JobDescription/JobDescription.js";
import JobSearch from "../JobseekerPage/DashBoardJob/JobSearch/JobSearch.js";
import ExploreApportunity from "../JobseekerPage/DashBoardJob/JobTab/ExploreApportunity/ExploreApportunity.js";
import DesiredJobSearch from "../JobseekerPage/DashBoardJob/DesiredJobSearch/DesiredJobSearch.js";
import Apply2 from "../JobseekerPage/ApplyJobs/Apply/Apply2.js";
import Apply3 from "../JobseekerPage/ApplyJobs/Apply/Apply3.js";
import MyPortfolio from "../JobseekerPage/my-portfolio/MyPortfolio.js";
import ProjectTabPage from "../JobseekerPage/my-portfolio/ProjectTabPage.js";
import PortFolioView from "../JobseekerPage/my-portfolio/view-portfolio-page/PortFolioView.js";
import PortfolioJobExperience from "../JobseekerPage/my-portfolio/Job-Experience/PortfolioJobExperience.js";
import PortFolioProjectform from "../JobseekerPage/my-portfolio/Job-Experience/PortFolioProjectform.js";
import PortFolioInternshipForm from "../JobseekerPage/my-portfolio/Job-Experience/PortFolioInternshipForm.js";
import PortfolioFreelancingForm from "../JobseekerPage/my-portfolio/Job-Experience/PortfolioFreelancingForm.js";
import PersonalDetailForm from "../JobseekerPage/my-portfolio/Job-Experience/PersonalDetailForm.js";
import PortfolioViewDetail from "../JobseekerPage/my-portfolio/Job-Experience/PortfolioViewDetail.js";
import ViewDetailsProject from "../JobseekerPage/my-portfolio/Job-Experience/ViewDetailsProject.js";
import ViewDetailsinternship from "../JobseekerPage/my-portfolio/Job-Experience/ViewDetailsinternship.js";
import PortfolioEducationForm from "../JobseekerPage/my-portfolio/Job-Experience/portfolio-Education-form/PortfolioEducationForm.js";
import PortfolioCertificateForm from "../JobseekerPage/my-portfolio/Job-Experience/portfolio-Education-form/PortfolioCertificateForm.js";
import SkillQuestions from "../JobseekerPage/my-portfolio/SkillQuestions/SkillQuestions.js";
import EditPrimarySkill from "../JobseekerPage/my-portfolio/Primary-skill/EditPrimarySkill.js";
import UpdatedForm from "../JobseekerPage/LoginSignup/UpdatedSingUp/UpdatedForm.js";
import LoginJob from "../JobseekerPage/LoginSignup/LoginJob/LoginJob.js";
import GetotpJobseeker from "../JobseekerPage/LoginSignup/GetOtp-jobseeker/GetotpJobseeker.js";
import DoneModal from "../JobseekerPage/my-portfolio/SkillQuestions/done-modal/DoneModal.js";
import FilterJobMobile from "../JobseekerPage/DashBoardJob/DesiredJobSearch/DesiredJob/FilterJobMobile.js";

import Middleware from "../../Middleware";
import Cookies from "js-cookie";
import { useContext } from "react";
import { Context } from "../../utils/context.js";

const All_Routes = () => {
  const { signin, usertype, token } = useContext(Context);

  return (
    <>
      <Routes>
        {/* Wihout Login */}
        {/* {signin ? (

          <Route
            path="/"
            element={<Middleware component={usertype === "Recruiter" ? DashBoardMain : DashBoardJob} />}
          ></Route>
        ) : (
          <Route
            path="/"
            element={<Middleware component={MainHome} />}
          ></Route>
        )} */}

        <Route path="/" element={<MainHome />}></Route>
        <Route path="/jobexp/:id" element={<JobExp />}></Route>
        <Route path="/jobexp" element={<JobExp />}></Route>
        <Route path="/aboutUs" element={<AboutUs />}></Route>
        <Route path="/blogs" element={<Blogs />}></Route>
        <Route path="/blogs/:id" element={<BlogDetails />}></Route>
        <Route path="/contact" element={<Contactus />}></Route>
        <Route path="/getotp" element={<Getotp />}></Route>
        <Route path="/getotp-jobseeker" element={<GetotpJobseeker />}></Route>
        <Route path="/terms" element={<TermsCondition />}></Route>
        <Route path="/signup-recruiter" element={<SignupForm />}></Route>
        <Route path="/privacy" element={<PrivacyPolicyPage />}></Route>
        <Route path="/help" element={<HelpDeskPage />}></Route>
        <Route path="*" element={<PageNotFound />}></Route>

        {usertype == "Recruiter" || usertype == "Job Seeker" ? (
          <>
            <Route path="/profile" element={<CompanyProfile />}></Route>
            <Route path="/user-profile" element={<UserProfile />}></Route>
            <Route path="/account" element={<AccountManagement />}></Route>
          </>
        ) : (
          <></>
        )}
        {/* Common Page  */}

        {/* <Route path="/notification-job" element={<NotiFicationJob />}></Route> */}
        {/* <Route path="/notification" element={<NotificationPage />}></Route> */}
        {/* <Route path="/account-2" element={<AccountText2 />}></Route> */}
        {/* <Route path="/account-3" element={<AccountText3 />}></Route> */}
        {/* <Route path="/account-4" element={<AccountText4 />}></Route> */}
        {/* <Route path="/account-6" element={<AccountDelete />}></Route> */}
        {/* <Route path="/requirter-signup1" element={<RequirterSignUp1 />}></Route> */}
        {/* <Route path="/login-recruiter" element={<LoginPage />}></Route> */}
        {/* <Route path="/reset" element={<Resetp />}></Route> */}

        {usertype == "Recruiter" && (
          <>
            {/* Recruiter */}
            <Route
              path="/dashboardmain"
              element={<Middleware component={DashBoardMain} />}
            ></Route>
            <Route
              path="/searchpool"
              element={<Middleware component={SearchPool} />}
            ></Route>
            <Route
              path="/schedule"
              element={<Middleware component={ShortListedPortFolio} />}
            ></Route>
            <Route
              path="/talentPool"
              element={<Middleware component={TalentPool} />}
            ></Route>
            <Route
              path="/hiring"
              element={<Middleware component={HiringFunnel} />}
            ></Route>
            <Route
              path="/hiring-2"
              element={<Middleware component={HiringFunnel2} />}
            ></Route>
            <Route
              path="/draft"
              element={<Middleware component={DraftedJobs} />}
            ></Route>
            <Route
              path="/innerPage"
              element={<Middleware component={InnerPage} />}
            ></Route>
            <Route
              path="/portfolio"
              element={<Middleware component={PortFolio} />}
            ></Route>
            <Route
              path="/buynow/:cat/:sub/:plan"
              element={<Middleware component={Buynow} />}
            ></Route>
            <Route
              path="/purchased"
              element={<Middleware component={PurchasedPool} />}
            ></Route>
            <Route
              path="/user-talent"
              element={<Middleware component={UserTalentPool} />}
            ></Route>
            <Route
              path="/viewjobs"
              element={<Middleware component={ViewJobs} />}
            ></Route>
            <Route
              path="/editjobs"
              element={<Middleware component={EditJobs} />}
            ></Route>
            <Route
              path="/postedjobmain"
              element={<Middleware component={PostedJobMain} />}
            ></Route>
            <Route
              path="/postedjob"
              element={<Middleware component={PostedJobs} />}
            ></Route>
            <Route
              path="/editjobs-2"
              element={<Middleware component={EditJobs2} />}
            ></Route>
            <Route
              path="/manangejobs"
              element={<Middleware component={ManangeJobs} />}
            ></Route>
            <Route
              path="/createjobs/:id"
              element={<Middleware component={CreateJobs} />}
            ></Route>
            <Route
              path="/openjobs"
              element={<Middleware component={OpenJobs} />}
            ></Route>
            <Route
              path="/draft-2"
              element={<Middleware component={DraftedJobsMain} />}
            ></Route>
            <Route
              path="/draft-3"
              element={<Middleware component={DraftedMain} />}
            ></Route>
            <Route
              path="/shortlisted-interview-portfolio"
              element={<Middleware component={ShortlistedInterview} />}
            ></Route>
            <Route
              path="/scroll-tab"
              element={<Middleware component={ScrollbleTab} />}
            ></Route>
          </>
        )}

        {usertype == "Job Seeker" && (
          <>
            {/*JobSeeker  */}
            <Route
              path="/signup-job/:id"
              element={<Middleware component={UpdatedForm} />}
            ></Route>
            <Route
              path="/login-job"
              element={<Middleware component={LoginJob} />}
            ></Route>
            <Route
              path="/dashboard-job"
              element={<Middleware component={DashBoardJob} />}
            ></Route>
            <Route
              path="/appliedJob"
              element={<Middleware component={AppliedJobs} />}
            ></Route>
            <Route
              path="/apply-job"
              element={<Middleware component={ApplyJobs} />}
            ></Route>
            <Route
              path="/matched-job"
              element={<Middleware component={MatchedJob} />}
            ></Route>
            <Route
              path="/job-description"
              element={<Middleware component={JobDescription} />}
            ></Route>
            <Route
              path="/exploreApportunity"
              element={<Middleware component={ExploreApportunity} />}
            ></Route>
            <Route
              path="/desired"
              element={<Middleware component={DesiredJobSearch} />}
            ></Route>
            <Route
              path="/thirdtab"
              element={<Middleware component={ThirdTab} />}
            ></Route>
            <Route
              path="/job-search"
              element={<Middleware component={JobSearch} />}
            ></Route>
            <Route
              path="/apply-job-2"
              element={<Middleware component={Apply2} />}
            ></Route>
            <Route
              path="/apply-job-3"
              element={<Middleware component={Apply3} />}
            ></Route>
            <Route
              path="/Portfolio-project-tab"
              element={<Middleware component={ProjectTabPage} />}
            ></Route>
            <Route
              path="/my-portfolio"
              element={<Middleware component={MyPortfolio} />}
            ></Route>
            <Route
              path="/view-portfolio"
              element={<Middleware component={PortFolioView} />}
            ></Route>
            <Route
              path="/portfolio-job-experience"
              element={<Middleware component={PortfolioJobExperience} />}
            ></Route>
            <Route
              path="/portfolio-project-form"
              element={<Middleware component={PortFolioProjectform} />}
            ></Route>
            <Route
              path="/portfolio-internship-form"
              element={<Middleware component={PortFolioInternshipForm} />}
            ></Route>
            <Route
              path="/portfolio-freelancing-form"
              element={<Middleware component={PortfolioFreelancingForm} />}
            ></Route>
            <Route
              path="/personal-detail-form"
              element={<Middleware component={PersonalDetailForm} />}
            ></Route>
            <Route
              path="/portfolio-education-form"
              element={<Middleware component={PortfolioEducationForm} />}
            ></Route>
            <Route
              path="/portfolio-certificate-form"
              element={<Middleware component={PortfolioCertificateForm} />}
            ></Route>
            <Route
              path="/portfolio-viewdetails-experience"
              element={<Middleware component={PortfolioViewDetail} />}
            ></Route>
            <Route
              path="/portfolio-viewdetails-project"
              element={<Middleware component={ViewDetailsProject} />}
            ></Route>
            <Route
              path="/portfolio-viewdetails-internship"
              element={<Middleware component={ViewDetailsinternship} />}
            ></Route>
            <Route
              path="/portfolio-skillquestion"
              element={<Middleware component={SkillQuestions} />}
            ></Route>
            <Route
              path="/Portfolio-edit-primaryskill"
              element={<Middleware component={EditPrimarySkill} />}
            ></Route>
            <Route
              path="/done-skill"
              element={<Middleware component={DoneModal} />}
            ></Route>
            <Route
              path="/filter"
              element={<Middleware component={FilterJobMobile} />}
            ></Route>
          </>
        )}
      </Routes>
    </>
  );
};

export default All_Routes;
