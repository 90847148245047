import React, { useEffect, useState } from "react";
import "../Account/Account.css";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import DeleteModal from "../../AccountManagement1/AccountModel/DeleteAccount";
import AccountManagement from "../AccountManagement";
import profileImg from "../../AccountManagement1/AccountDelete/Images/accountManagament.png";
import AccountTextModal2 from "../../AccountManagement1/AccountText-2/AccountTextModal-2";
import AccountTextModal3 from "../../AccountManagement1/AccountText-3/AccountTextModal-3";
import AccountTextModal4 from "../../AccountManagement1/AccountText-4/AccountTextModal4";
import AccountTextModal5 from "../../AccountManagement1/AccountText-5/AccountTextModal-5";

import Cookies from "js-cookie";
import { useContext } from "react";
import { Context } from "../../../../utils/context";
const Account = () => {
  const { getData, imageUrl, deleteData, setSignin, setUsertype } =
    useContext(Context);
  const [isHidden, setIsHidden] = useState(true);

  const toggleHidden = () => {
    setIsHidden(!isHidden);
  };

  const [isHidden2, setIsHidden2] = useState(false);

  const toggleHidden2 = () => {
    setIsHidden2(!isHidden2);
  };

  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionClick = (option) => {
    if (selectedOption === option) {
      setSelectedOption(null);
    } else {
      setSelectedOption(option);
    }
  };

  // account-modal-2
  const [modalShow, setModalShow] = useState(false);

  //account-modal-3
  const [modalShow3, setModalShow3] = useState(false);

  //account-modal-4
  const [modalShow4, setModalShow4] = useState(false);

  //account-modal-5
  const [modalShow5, setModalShow5] = useState(false);

  const [userData, setUserData] = useState();
  const getUserData = async () => {
    var res;
    if (Cookies.get("jwtType") === "Job Seeker") {
      res = await getData(`/jobseeker/profile`);
      if (res?.success) {
        setUserData(res.data);
      }
    }
    if (Cookies.get("jwtType") === "Recruiter") {
      res = await getData(`/recruiters/profile`);
      if (res?.success) {
        setUserData(res.data.user);
      }
    }
  };

  const [isChecked, setIsChecked] = useState(false);
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  const navigate = useNavigate();
  const [modalDeleteShow, setModalDeleteShow] = React.useState(false);
  const openModal = async () => {
    const res = await deleteData(`/common/close-account`);

    if (res?.success) {
      await Cookies.remove("hiclousia_website");
      // Clear type cookies
      await Cookies.remove("jwtType");
      setModalDeleteShow(true);

      setTimeout(async () => {
        navigate("/");
        await setSignin(false);
        await setUsertype("");
        setModalDeleteShow(false);
      }, 3000);
    }
  };
  useEffect(() => {
    getUserData();
  }, []);
  return (
    <>
      <section className="Account-management">
        <div className="container-fluid">
          <div className="row justify-content-center ">
            <div className="accountManagement_main"></div>
            <div className="col-xl-6  col-lg-10 col-md-10 col-lg-12">
              <div className="row ">
                <div className="col-lg-12">
                  <div className="company-profile-title text-center">
                    <div>
                      {userData?.image ? (
                        <img
                          src={imageUrl + userData?.image}
                          className="accManprofile_img"
                        />
                      ) : (
                        <img src={profileImg} className="accManprofile_img" />
                      )}
                      {/* <img src={profileImg} className="accManprofile_img" /> */}
                    </div>
                    <h2>
                      {userData?.first_name} {userData?.last_name}
                    </h2>
                  </div>
                  <div className="text-holder-privacy account-below_sec">
                    <div className="title-privacy">
                      <h5>Account Management</h5>
                    </div>
                    <div className="first-jobseeker">
                      <div className="row">
                        <div className="col-xl-8 col-lg-9 col-md-7 col-sm-7 col-7">
                          <h2 className="title-account">Email</h2>
                          <p>{userData?.email}</p>
                        </div>
                        <div className="col-xl-4 col-lg-3 col-md-5 col-sm-5 col-5 my-auto">
                          {/* <Link to='/Account-2'><button className='switch-jobseeker' >Email Settings</button></Link> */}
                          <Button
                            variant="primary"
                            className="switch-jobseeker"
                            onClick={() => setModalShow(true)}
                          >
                            Email Settings
                          </Button>
                          <AccountTextModal2
                            show={modalShow}
                            getUserData={getUserData}
                            onHide={() => setModalShow(false)}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="first-jobseeker">
                      <div className="row">
                        <div className="col-xl-8 col-lg-9 col-md-7 col-sm-7 col-7">
                          <h2 className="title-account">Phone Number</h2>
                          <p className="second-text">{userData?.contact_no}</p>
                        </div>
                        <div className="col-xl-4 col-lg-3 col-md-5 col-sm-5 col-5 my-auto">
                          {/* <Link to='/account-4'><button className='switch-jobseeker' >Change Number</button></Link> */}
                          <Button
                            variant="primary"
                            className="switch-jobseeker"
                            onClick={() => setModalShow4(true)}
                          >
                            Change Number
                          </Button>
                          <AccountTextModal4
                            show={modalShow4}
                            getUserData={getUserData}
                            userData={userData}
                            onHide={() => setModalShow4(false)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="first-jobseeker">
                      <div className="row">
                        <div className="col-xl-8 col-lg-9 col-md-7 col-sm-7 col-7">
                          <h2 className="title-account">Close Account </h2>
                          <p className="second-text">(De-Activate Acc.)</p>
                        </div>
                        <div className="col-xl-4 col-lg-3 col-md-5 col-sm-5 col-5 my-auto">
                          <button
                            className="switch-jobseeker"
                            onClick={toggleHidden2}
                          >
                            Delete Account {isHidden2 ? "" : ""}
                          </button>
                          {/*  */}
                          <div>
                            {isHidden2 && (
                              <div>
                                <div className="text-center">
                                  <p className="contitnue-text">
                                    Do You Want to Delete Your Account?
                                  </p>
                                  {/* <Link to='/account-6'><button className="yes-button">Yes</button></Link> */}
                                  <Button
                                    variant="primary"
                                    className="yes-button"
                                    onClick={openModal}
                                  >
                                    Yes
                                  </Button>

                                  <button
                                    className="no-button"
                                    onClick={toggleHidden2}
                                  >
                                    No
                                  </button>
                                </div>
                              </div>
                            )}
                            {/* <AccountTextModal5
                                    show={modalShow5}
                                    onHide={() => setModalShow5(false)}
                                  /> */}
                            <DeleteModal
                              show={modalDeleteShow}
                              onHide={() => setModalDeleteShow(false)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="text-center ">
                        <button
                          className="decline me-2"
                          onClick={() => {
                            navigate("/");
                          }}
                        >
                          Cancel
                        </button>
                        <button className="accept">Save</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Account;
