import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import Col from "react-bootstrap/Col";

import Row from "react-bootstrap/Row";

import SignUpModal from "./SignUpModal/ContactModal";
import ContactModal from "./ContactModal/ContactModal";
import Cookies from "js-cookie";
import { useContext } from "react";
import { Context } from "../../../../utils/context";
const Step6 = () => {
  const navigate = useNavigate();

  const { getData, postData, recruiterData, setSignin, setRecruiterData } =
    useContext(Context);

  const [modalShow, setModalShow] = useState(false);

  // Cities API
  const [selectedOption, setSelectedOption] = useState(null);
  const [livingErr, setLivingErr] = useState("");
  const options = [];
  const [cities, setCities] = useState();

  const getCities = async () => {
    const res = await getData("/without-login/master/all-cities");
    if (res?.success) {
      // console.log(res.data, "cities");
      const mergedOptions = [
        ...options,
        ...res.data.map((city) => ({ value: city?.id, label: city?.name })),
      ];
      // console.log(mergedOptions, "options");
      setCities(mergedOptions);
    }
  };

  // Nationality API
  const [selectedNationOption, setSelectedNationOption] = useState(null);
  const [nationErr, setNationErr] = useState("");
  const nationOptions = [];
  const [nations, setNations] = useState();

  const getNations = async () => {
    const res = await getData("/without-login/master/all-nationality");
    if (res?.success) {
      // console.log(res.data, "nations");
      const mergedOptions = [
        ...nationOptions,
        ...res.data.map((nation) => ({
          value: nation?.id,
          label: nation?.name,
        })),
      ];
      // console.log(mergedOptions, "nationOptions");
      setNations(mergedOptions);
    }
  };

  const [addressErr, setAddressErr] = useState("");
  const handleValidation = async () => {
    var address = document.getElementById("address").value;

    // Initialize an array to store error messages
    var errors = [];

    if (selectedOption === null) {
      setLivingErr("Living City is required");
      errors.push("Living City is required");
    }

    if (selectedNationOption === null) {
      setNationErr("Nationality is required");
      errors.push("Nationality is required");
    }

    if (address.trim() === "") {
      setAddressErr("Address is required");
      errors.push("Address is required");
    }

    if (errors.length === 0) {
      recruiterData.current_address = address;
      recruiterData.living_city_id = selectedOption?.value;
      recruiterData.nationality_id = selectedNationOption?.value;
      signUp();
    }
  };

  const [policyErr, setPolicyErr] = useState("");
  const signUp = async () => {
    if (recruiterData.policy == false) {
      setPolicyErr("Accept our Policies for SignUp...!");
    } else {
      // console.log(recruiterData, "signUpData");
      const res = await postData(
        `/without-login/recruiters/sign-up`,
        recruiterData
      );
      if (res?.success) {
        // console.log(res, "success");
        Cookies.set("hiclousia_website", res.data.token, { expires: 1 });
        Cookies.set("jwtType", res.data.type, { expires: 1 });

        setModalShow(true);

        setSignin(true);
        setTimeout(() => {
          setModalShow(false);
          navigate("/talentPool");
        }, 4000);
      } else {
        // console.log(res, "fail");
      }
    }
  };

  const handleTerms = (element) => {
    setPolicyErr("");
    if (element.target.checked) {
      recruiterData.policy = true;
    } else {
      recruiterData.policy = false;
    }
  };

  useEffect(() => {
    // console.log(recruiterData, "recruiterData");
    getCities();
    getNations();
  }, []);

  return (
    <>
      <fieldset>
        <div className="form-card StepForm-recruiter">
          <div className="row">
            <div className="col-7">
              <h2>
                <span className="blue-line"></span>
                <span className="fs-title">Step6</span>
              </h2>
            </div>
          </div>
          <Form>
            <Row className="mb-3">
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>
                  Current Address <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={1}
                  id="address"
                  onChange={() => setAddressErr("")}
                />
              </Form.Group>
              <span
                className="text text-danger"
                style={{
                  textAlign: "center",
                }}
              >
                {addressErr && addressErr}
              </span>
            </Row>
            <Row className="mb-3">
              <div className="col-md-6 col-12">
                <Form.Group className="mb-3" controlId="formGridCity">
                  <Form.Label>
                    {" "}
                    Living City <span className="text-danger">*</span>
                  </Form.Label>
                  {/* <Form.Select className="mt-0">
                    <option>Pune</option>
                    <option>Mumbai</option>
                    <option>Hydrabad</option>
                  </Form.Select> */}
                  <Select
                    value={selectedOption}
                    onChange={(selectedOption) => {
                      setSelectedOption(selectedOption);
                      setLivingErr("");
                    }}
                    options={cities}
                    isSearchable={true}
                    id="living"
                  />
                </Form.Group>
                <span
                  className="text text-danger"
                  style={{
                    textAlign: "center",
                  }}
                >
                  {livingErr && livingErr}
                </span>
              </div>
              <div className="col-md-6 col-12">
                <Form.Group className="mb-3" controlId="formGridState">
                  <Form.Label>
                    {" "}
                    Nationality <span className="text-danger">*</span>
                  </Form.Label>
                  {/* <Form.Select className="mt-0">
                    <option>Indian</option>
                  </Form.Select> */}
                  <Select
                    value={selectedNationOption}
                    onChange={(selectedNationOption) => {
                      setSelectedNationOption(selectedNationOption);
                      setNationErr("");
                    }}
                    options={nations}
                    isSearchable={true}
                    id="living"
                  />
                </Form.Group>
                <span
                  className="text text-danger"
                  style={{
                    textAlign: "center",
                  }}
                >
                  {nationErr && nationErr}
                </span>
              </div>
            </Row>

            <div className="mobile-view-button">
              {/* <Link to='/dashboardmain'> */}
              <button
                type="button"
                name="next"
                className="next action-button"
                onClick={handleValidation}
              >
                Signup
              </button>
              {/* </Link> */}
            </div>
            <div>
              <SignUpModal
                show={modalShow}
                onHide={() => setModalShow(false)}
              />
            </div>
          </Form>
        </div>
      </fieldset>

      <div className="row  mb-4 footer-sec">
        <div className="col-md-10 col-12">
          <span
            className="text text-danger"
            style={{
              textAlign: "center",
            }}
          >
            {policyErr && policyErr}
          </span>
          <div className="check-row text-start">
            <span>
              <input
                type="checkbox"
                name="group1"
                className="me-2"
                onChange={handleTerms}
                checked={recruiterData?.policy}
              />
            </span>
            <span className=" by-text ">By signing up, I agree to the</span>
            <span className="px-1 ">
              <Link to="/terms" className="term-btn">
                Term and Conditions
              </Link>
            </span>
            <br />
          </div>
          <div className="login-row text-start">
            <span className="">Already an User?</span>
            <span className="px-1 ">
              <Link to="" className="login-btn">
                log In
              </Link>
            </span>
            <br />
            <span className="">Not a Recruiter ?</span>
            <span className="px-1 ">
              <Link to="/getotp-jobseeker" className="signup-btn">
                Signup as Jobseeker
              </Link>
            </span>
          </div>
        </div>

        <div className="col-2">
          <div className="desktop-view-button">
            {/* <Link to='/dashboardmain'> */}
            <button
              type="button"
              name="next"
              className="next action-button"
              onClick={handleValidation}
            >
              Signup
            </button>
            {/* </Link> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Step6;
