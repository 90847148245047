import React, { useState } from 'react';
import "../SkillQuestions/AllQuestion.css";
import Form from 'react-bootstrap/Form';

const Question3 = () => {
  return (
    <>
      <search className='Questions-sec'>
        <div className='container p-0'>
          <h6>3</h6>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
              <Form.Label>How would you overcome communication challenges on a remote team?</Form.Label>
              <Form.Control as="textarea" rows={1} />
            </Form.Group>
          </Form>
        </div>
      </search>
    </>
  );

};

export default Question3;
