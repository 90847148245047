import React, { useEffect, useState, useRef } from "react";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "@fortawesome/fontawesome-svg-core/styles.css";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Select, { components } from "react-select";
import Modal from "react-bootstrap/Modal";
import Lottie from "react-lottie";
import * as animationData from "./Completedlotty.json";
import * as animationDataTwo from "./Hundredper.json";

import { useContext } from "react";
import { Context } from "../../../../utils/context";
import {
  faUpload,
  faFile,
  faXmark,
  faPlus,
  faMinus,
} from "@fortawesome/free-solid-svg-icons";

const options = [
  { value: "HTML", label: "HTML" },
  { value: "CSS", label: "CSS" },
  { value: "Bootstrap", label: "Bootstrap" },
];

const MultiValueRemove = (props) => (
  <components.MultiValueRemove
    {...props}
    className="custom-multi-value-remove"
  />
);
const Step10 = ({ handleNext }) => {
  const [lgShow, setLgShow] = useState(false);
  const handleClose1 = () => setLgShow(false);
  const handleShow1 = () => setLgShow(true);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    const files = e.target.files;
    if (files.length > 0) {
      const file = files[0];
      setFile(file);
    } else {
      setFile(null);
    }
  };

  const handleButtonClick = () => {
    inputRef.current?.click();
  };

  const [buttonStatus, setButtonStatus] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [currentAnimation, setCurrentAnimation] = useState(1);
  const defaultOptionsTwo = {
    loop: false, // Disable loop for the first animation
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const defaultOptionsThree = {
    loop: true, // Enable loop for the second animation
    autoplay: true,
    animationData: animationDataTwo,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const handleFirstAnimationFinish = () => {
    // Triggered when the first animation completes
    setCurrentAnimation(2); // Switch to the second animation
  };
  const handleCloseSecondAnimation = () => {
    // Add any logic you need when the second animation is closed
    // For example, you might want to proceed to the next step
    handleNext();
  };

  const handleUploadButtonClick = (id) => {
    // Trigger a click on the file input
    // inputRef.current.click();
    document.getElementById(id).click();
  };

  const [currentEvidenceLoader, setCurrentEvidenceLoader] = useState(0.0);

  const years = Array.from({ length: 100 }, (_, index) => ({
    label: `${new Date().getFullYear() - index}`,
    value: `${new Date().getFullYear() - index}`,
  }));

  const {
    getData,
    postData,
    recruiterData,
    jobSeekerData,
    editStatusData,
    getJobSeekerData,
    Select2Data,
    Select2Skills,
    Select2Roles,
    fetchSeekerData,
  } = useContext(Context);

  const [projectTypes, setProjectTypes] = useState();
  const [roles, setRole] = useState();
  const [roleCurrentErr, setRoleCurrentErr] = useState("");

  const [skills, setSkill] = useState();
  const [skillCurrentErr, setSkillCurrentErr] = useState("");

  const [achievements, setAchievement] = useState();
  const [companyCurrentErr, setCompanyCurrentErr] = useState("");

  const [companySectors, setCompanySectors] = useState();

  const getMasters = async () => {
    {
      const res = await getData(`/without-login/master/all-type`);
      if (res?.success) {
        // console.log(res.data, "roles");

        const data = await Select2Data(res.data, "type_id", false);
        setProjectTypes(data);
      }
    }
    {
      const res = await getData(`/without-login/master/all-roles`);
      if (res?.success) {
        // console.log(res.data, "roles");

        const data = await Select2Roles(res.data, "role_id", false);
        setRole(data);
      }
    }
    {
      const res = await getData(`/without-login/master/all-skills`);
      if (res?.success) {
        // console.log(res.data, "skills");

        const data = await Select2Skills(res.data, "skill_id", false);
        setSkill(data);
      }
    }
    {
      const res = await getData("/without-login/master/all-achievemente");
      if (res?.success) {
        const data = await Select2Data(res.data, "achievement_id", false);
        setAchievement(data);
      }
    }
    {
      const res = await getData("/without-login/master/all-category");
      if (res?.success) {
        const data = await Select2Data(res.data, "category_id", false);
        setCompanySectors(data);
      }
    }
  };

  const [formNumber, setFormNumber] = useState({
    step: 10,
    type_id: "",
    organization: "",
    title: "",
    project_nature: "",
    role: "",
    url: "",
    skills: [],
    publication: "",
    description: "",
    start_date: "",
    end_date: "",
    start_date_value: "",
    end_date_value: "",
    category_id: "",
    achievement_id: "",

    mentor_email: "",
    mentor: "",
    type_id_value: "",
    category_id_value: "",
    achievement_id_value: "",
    skills_value: [],
    media: undefined,
  });

  const [formMedia, setFormMedia] = useState([]);
  const [formEvidence, setFormEvidence] = useState([]);

  const handleMediaChange = (event, index) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      // Update the state with the selected file data
      setFormMedia((prevFormMedia) => {
        const updatedFormMedia = [...prevFormMedia];
        updatedFormMedia[index] = selectedFile;
        return updatedFormMedia;
      });
    }
  };

  const [selectedskills, setSelectedSkills] = useState([]);
  useEffect(() => {
    setFormNumber({
      ...formNumber,
      ["skills"]: selectedskills?.map((item) => item.value),
    });
  }, [selectedskills]);

  const handleSelectChange = (e) => {
    setFormNumber({ ...formNumber, [e.name]: e });
  };

  const handleInputChange = (e) => {
    setFormNumber({ ...formNumber, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    let errors = {};
    const regexAlfa = /^[A-Za-z]+(\s[A-Za-z]+)*$/;
    const regexEmail =
      /^([A-Za-z0-9_\-\.])+\@(?!(?:[A-Za-z0-9_\-\.]+\.)?([A-Za-z]{2,4})\.\2)([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

    if (!formNumber.type_id) {
      errors[`type_id`] = "Project Type is required";
    }

    if (!formNumber.organization) {
      errors.organization = "Project Organization is required";
    } else if (!regexAlfa.test(formNumber.organization?.trim())) {
      errors.organization = "Enter a valid data";
    }

    if (!formNumber.title) {
      errors.title = "Project Title is required";
    } else if (!regexAlfa.test(formNumber.title?.trim())) {
      errors.title = "Enter a valid data";
    }
    if (!formNumber.role) {
      errors.role = "Project Role is required";
    } else if (!regexAlfa.test(formNumber.role?.trim())) {
      errors.role = "Enter a valid data";
    }

    if (!formNumber.mentor) {
      errors.mentor = "Mentor is required";
    } else if (!regexAlfa.test(formNumber.mentor?.trim())) {
      errors.mentor = "Enter a valid data";
    }

    if (formNumber.mentor_email) {
      if (!regexEmail.test(formNumber.mentor_email)) {
        errors.mentor_email = "Enter a valid Email";
      }
    }

    if (formNumber?.skills.length === 0) {
      errors.skills = "Skills is required";
    }

    if (!formNumber.start_date) {
      errors.start_date = "Start Date is required";
    }

    if (!formNumber.end_date) {
      errors.end_date = "End Date is required";
    } else if (
      new Date(formNumber.end_date) < new Date(formNumber.start_date)
    ) {
      errors.end_date = "End Date cannot be a past date of Start Date";
    } else if (new Date(formNumber.end_date) > new Date()) {
      errors.end_date = "End Date cannot be a future date";
    }

    return errors;
  };

  const [errors, setErrors] = useState({});

  const [status, setStatus] = useState("none");
  const handleProjectSave = async (action) => {
    await setButtonStatus(true);
    const validationErrors = validateForm();
    // console.log(validationErrors);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      if (action === "continue") {
        if (projectData?.length > 0) {
          setShow(true);
          setErrors("");
          setTimeout(() => {
            navigate("/dashboard-job");
          }, 10000);
        }
      }
      await setButtonStatus(false);
    } else {
      try {
        const finalData = new FormData();
        finalData.append(`media`, formNumber.media);
        finalData.append(`type_id`, formNumber.type_id?.value);
        finalData.append(`organization`, formNumber.organization);
        finalData.append(`step`, formNumber.step);
        finalData.append(`status`, "none");
        if (formNumber.category_id) {
          finalData.append(`category_id`, formNumber.category_id?.value);
        }
        if (formNumber.achievement_id) {
          finalData.append(`achievement_id`, formNumber.achievement_id?.value);
        }
        if (formNumber.university_type_id) {
          finalData.append(
            `university_type_id`,
            formNumber.university_type_id?.value
          );
        }
        finalData.append(`skills`, JSON.stringify(formNumber.skills));
        finalData.append(`title`, formNumber.title);
        finalData.append(`role`, formNumber.role);
        finalData.append(`url`, formNumber.url);
        finalData.append(`publication`, formNumber.publication);
        finalData.append(`description`, formNumber.description);
        finalData.append(`mentor_email`, formNumber.mentor_email);
        finalData.append(`mentor`, formNumber.mentor);
        finalData.append(`start_date`, formNumber.start_date);
        finalData.append(`end_date`, formNumber.end_date);
        finalData.append(`status`, status);

        const result = await postData("/jobseeker/signup/project", finalData);

        if (result?.success) {
          await setFormNumber({
            step: 10,
            type_id: "",
            organization: "",
            title: "",
            project_nature: "",
            role: "",
            url: "",
            skills: [],
            publication: "",
            description: "",
            start_date: "",
            end_date: "",
            start_date_value: "",
            end_date_value: "",
            category_id: "",
            achievement_id: "",
            mentor_email: "",
            mentor: "",
            type_id_value: "",
            category_id_value: "",
            achievement_id_value: "",
            skills_value: [],
            media: undefined,
          });
          setErrors("");
          getProjects();
          await fetchSeekerData();
          if (action === "continue") {
            setShow(true);
            setTimeout(() => {
              navigate("/dashboard-job");
            }, 10000);
          }
          await setButtonStatus(false);
        }
      } catch (error) {
        await setButtonStatus(false);
      }
    }
  };

  const [projectData, setProjectData] = useState();

  const getProjects = async () => {
    const res = await getData(`/jobseeker/signup/project`);
    if (res?.success) {
      // console.log(res.data, "project");
      setProjectData(res.data);
      if (res?.data.length !== 0) {
        setStatus("none");
      } else {
        setStatus(true);
      }
    }
  };
  const handleDelete = async (id) => {
    var data = "";
    if (projectData?.length === 1) {
      var data = {
        jobseeker_id: jobSeekerData?.id,
        step: 9,
        status: false,
      };
    } else {
      var data = {
        jobseeker_id: jobSeekerData?.id,
        step: 10,
        status: "none",
      };
    }

    const res = await editStatusData(`/jobseeker/signup/project/${id}`, data);
    await getProjects();
    await fetchSeekerData();
  };

  useEffect(() => {
    getMasters();
    getProjects();
  }, []);

  const handleSkip = async () => {
    setShow(true);
    setErrors("");
    setTimeout(() => {
      navigate("/dashboard-job");
    }, 10000);
  };
  const errorStyle = {
    color: "red",
    marginLeft: "5px",
    fontSize: "11px",
  };

  return (
    <>
      <section className="eightstep StepForm text-start">
        <div className="container">
          <div className="d-flex  steps-heading">
            <div className="border-left" />
            <h5>Step 9</h5>
          </div>
          <p className="title">Add Project</p>

          {projectData?.map((value, index) => (
            <Col className="col-md-5 col-lg-3 col-xl-3 col-xxl-3 col-12">
              <div className="msc-box">
                <div className="tabs-btn p-2">
                  <div className="d-flex justify-content-end">
                    <p>
                      <FontAwesomeIcon
                        icon={faXmark}
                        className="close-icon"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleDelete(value?.id)}
                      />
                    </p>
                  </div>
                  <div className="info">
                    <p>Title : {value?.title}</p>
                    <p>Organization: {value?.organization}</p>
                    <p>Mentor: {value?.mentor}</p>
                    <p>Start Date: {value?.start_date}</p>
                    <p>End Date: {value?.end_date}</p>
                    <p>Type : {value?.type?.name}</p>
                  </div>
                </div>
              </div>
            </Col>
          ))}
          <Form>
            {/* {formNumber.r_id} */}
            <Row className="mt-4">
              <div className="col-md-6 col-sm-12 mb-md-3 mb-0">
                <Form.Label>
                  Project Type <span className="text-danger">*</span>
                </Form.Label>
                <Select
                  placeholder="Enter Project Type"
                  value={formNumber.type_id}
                  classNamePrefix="projectType"
                  options={projectTypes}
                  name="type_id"
                  onChange={handleSelectChange}
                  isSearchable={true}
                />
                {errors[`type_id`] && (
                  <div style={errorStyle}>{errors[`type_id`]}</div>
                )}
              </div>
              <div className="col-md-6 col-sm-12 mb-md-3 mb-0">
                <Form.Label>
                  Project Organization <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="organization"
                  placeholder="Enter Institute / Company Name"
                  value={formNumber.organization}
                  onChange={handleInputChange}
                />{" "}
                {errors[`organization`] && (
                  <div style={errorStyle}>{errors[`organization`]}</div>
                )}
              </div>
            </Row>

            <Row>
              <div className="col-md-6 col-sm-12 mb-md-3 mb-0">
                <Form.Label>
                  Project Title <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="title"
                  placeholder="Enter Project Title"
                  value={formNumber.title}
                  onChange={handleInputChange}
                />
                {errors[`title`] && (
                  <div style={errorStyle}>{errors[`title`]}</div>
                )}
              </div>
              <div className="col-md-6 col-sm-12 mb-md-3 mb-0">
                <Form.Label>Role</Form.Label>
                <span className="text-danger">*</span>
                <Form.Control
                  type="text"
                  name="role"
                  placeholder="Your Role in Project"
                  value={formNumber.role}
                  onChange={handleInputChange}
                />
                {errors[`role`] && (
                  <div style={errorStyle}>{errors[`role`]}</div>
                )}
              </div>
              {/* <div className="col-md-6 col-sm-12 mb-md-3 mb-0">
                  <Form.Label>My Position</Form.Label>
                  <Form.Control type="text" placeholder="Enter Position"  value={formNumber.my} onChange={(event) => handleInputChange(event,  'my')} />
                </div> */}
            </Row>
            <Row>
              <div className="col-md-6 col-sm-12 mb-md-3 mb-0">
                <Form.Label>
                  Skill Used: <span className="text-danger">*</span>
                </Form.Label>
                <Select
                  placeholder="Select Skills"
                  className="select-line"
                  classNamePrefix="skills"
                  isMulti
                  name="skills"
                  value={selectedskills}
                  onChange={(e) => {
                    setSelectedSkills(e);
                  }}
                  options={skills}
                />{" "}
                {errors[`skills`] && (
                  <div style={errorStyle}>{errors[`skills`]}</div>
                )}
              </div>

              <div className="col-md-6 col-sm-12 mb-md-3 mb-0">
                <Form.Label>Project Url</Form.Label>
                <Form.Control
                  type="text"
                  name="url"
                  placeholder="Project Url"
                  value={formNumber.url}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-md-6 col-sm-12 mb-md-3 mb-0">
                <Form.Label>Publications</Form.Label>
                <Form.Control
                  type="text"
                  name="publication"
                  placeholder="Publications"
                  value={formNumber.publication}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-md-6 col-sm-12 mb-md-3 mb-0">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  type="text"
                  name="description"
                  placeholder="Description"
                  value={formNumber.description}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-md-6 col-sm-6 mb-md-3 mb-0">
                <Row>
                  <Col>
                    <Form.Label>
                      Time Period
                      <sup className="bracket-font px-md-1 px-5">
                        (from)
                      </sup>{" "}
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="date"
                      placeholder="End Date"
                      name="start_date"
                      value={formNumber?.start_date}
                      onChange={handleInputChange}
                    />{" "}
                    {errors[`start_date`] && (
                      <div style={errorStyle}>{errors[`start_date`]}</div>
                    )}
                  </Col>
                  <Col>
                    <Form.Label>
                      Time Period
                      <sup className="bracket-font px-md-1 px-5">(to)</sup>{" "}
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="date"
                      placeholder="End Date"
                      name="end_date"
                      value={formNumber?.end_date}
                      onChange={handleInputChange}
                    />{" "}
                    {errors[`end_date`] && (
                      <div style={errorStyle}>{errors[`end_date`]}</div>
                    )}
                  </Col>
                </Row>
              </div>
              <div className="col-md-6 col-sm-12 mb-md-3 mb-0">
                <Form.Label>Mentor Email</Form.Label>
                <Form.Control
                  type="text"
                  name="mentor_email"
                  placeholder="Mentor Email"
                  value={formNumber.mentor_email}
                  onChange={handleInputChange}
                />
                {errors[`mentor_email`] && (
                  <div style={errorStyle}>{errors[`mentor_email`]}</div>
                )}
              </div>
              <div className="col-md-6 col-sm-12 mb-md-3 mb-0">
                <Form.Label>
                  Mentor/Guide Name <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="mentor"
                  placeholder="Mentor/Guide Name"
                  value={formNumber.mentor}
                  onChange={handleInputChange}
                />{" "}
                {errors[`mentor`] && (
                  <div style={errorStyle}>{errors[`mentor`]}</div>
                )}
              </div>
              {/* <div className="col-md-6 col-sm-12 mb-md-3 mb-0">
                  <Form.Label>Company Name</Form.Label>
                  <Form.Control type="text" placeholder="Company Name"  value={formNumber.com} onChange={(event) => handleInputChange(event,  'com')}/>
                </div> */}
              <div className="col-md-6 col-sm-12 mb-md-3 mb-0">
                <Form.Label>Category</Form.Label>
                <Select
                  placeholder="Enter Category"
                  classNamePrefix="skills"
                  name="category_id"
                  value={formNumber.category_id}
                  onChange={handleSelectChange}
                  options={companySectors}
                  isSearchable={true}
                />
              </div>

              <div className="col-md-6 col-sm-12 mb-md-3 mb-0">
                <Form.Label>Achievements</Form.Label>
                <Select
                  placeholder="Enter Achievements"
                  classNamePrefix="skills"
                  name="achievement_id"
                  value={formNumber.achievement_id}
                  onChange={handleSelectChange}
                  options={achievements}
                  isSearchable={true}
                />
              </div>

              <div className="col-md-6 col-sm-12 mb-md-3 mb-0">
                <div>
                  <p className="upload-text mb-2">Upload Media</p>
                  <input
                    className="uplode-file"
                    type="file"
                    accept="application/pdf"
                    name="media"
                    id={"currentMedia"}
                    onChange={async (e) => {
                      await setFormNumber({
                        ...formNumber,
                        [e.target.name]: e.target.files[0],
                      });
                    }}
                  />
                  <button
                    type="button"
                    className=" upload-experience"
                    onClick={() => handleUploadButtonClick("currentMedia")}
                  >
                    Upload <FontAwesomeIcon icon={faUpload} className="px-3" />
                  </button>
                  {/* <span>{formNumber?.name}</span> */}
                  {/* <p className="bracket-font mb-0">
                            ( Max file size 1 MB )
                          </p> */}
                </div>
              </div>
            </Row>
            <hr />
          </Form>
        </div>
        <div>
          {/* Modal */}
          <Modal
            size="md"
            show={show}
            onHide={handleClose}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="completed-modal"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <div className="click text-center">
                {currentAnimation === 1 && (
                  <div className="lotty-holder">
                    <Lottie
                      className="me-auto"
                      options={defaultOptionsTwo}
                      height={200}
                      width={200}
                      eventListeners={[
                        {
                          eventName: "complete",
                          callback: handleFirstAnimationFinish,
                        },
                      ]}
                    />
                    <div className="text-lottey">
                      <span>{jobSeekerData?.percentage}% Completed</span>
                    </div>
                  </div>
                )}
                {currentAnimation === 2 && (
                  <div>
                    <Lottie
                      className="me-auto"
                      options={defaultOptionsThree}
                      height={200}
                      width={200}
                      eventListeners={[
                        {
                          eventName: "complete",
                          callback: handleCloseSecondAnimation,
                        },
                      ]}
                    />
                    <p style={{ color: "#000" }}>
                      Your Portfolio created <br></br> successfully
                    </p>
                  </div>
                )}
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </section>

      <div className=" mobile-view-button mt-5">
        <div className="text-end mb-4">
          <Button
            className="btn education-btn"
            onClick={() => handleProjectSave("add")}
          >
            Add Project{" "}
          </Button>
        </div>
        <div className="d-flex">
          <div className="me-1">
            <Button
              className="next action-button contin-btn"
              onClick={handleSkip}
            >
              Skip
            </Button>
          </div>
          <div className="ms-auto">
            <button
              disabled={buttonStatus}
              type="button"
              name="next"
              className="next action-button contin-btn"
              onClick={() => handleProjectSave("continue")}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
      <div className="row mt-5 pt-4 footer-sec">
        <div className="col-md-8 col-12 desktop-view-button"></div>

        <div className="col-4 desktop-view-button">
          <div className="text-end mb-4">
            <Button
              className="btn education-btn"
              onClick={() => handleProjectSave("add")}
            >
              Add Project{" "}
            </Button>
          </div>
          <div className="d-flex justify-content-end">
            <div className="">
              <Button className="btn education-btn1" onClick={handleSkip}>
                Skips
              </Button>
            </div>
            <div className="">
              <button
                disabled={buttonStatus}
                type="button"
                name="next"
                className="next action-button contin-btn"
                onClick={() => handleProjectSave("continue")}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Step10;
