import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../Schedulinterview/SchedulInterview.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ScheduleModal from "../Schedulinterview/Schedulemodel.js";

const SchedulInterview = () => {
  const [selectedOption, setSelectedOption] = useState("");

  const handleDropdownChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const [modalShow, setModalShow] = useState(false);

  const openModal = () => {
    setModalShow(true);

    setTimeout(() => {
      window.location.href = "/account";
      setModalShow(false);
    }, 3000);
  };

  return (
    <>
      <section className="manage-job">
        <div className="container">
          <div className="row mt-5">
            <div className="col-md-12">
              <div className="section-title mt-3">
                <div className="row">
                  <div className="col-md-12 ">
                    <div className="title-creat-job mt-md-4 text-center">
                      <h5>Schedule Interview</h5>
                    </div>
                  </div>

                  <div className=" col-xxl-5 col-xl-5 col-lg-6 col-md-9 mb-3">
                    <a className="title-open-jobs">
                      <Link to="/dashboardmain">
                        Go to Dashboard{" "}
                        <FontAwesomeIcon icon="fa-solid fa-angle-right" />
                      </Link>
                      <Link to="/openjobs">
                        Open Jobs
                        <FontAwesomeIcon icon="fa-solid fa-angle-right" />
                      </Link>
                      <Link to="/portfolio">
                        Shortlisted Portfolios{" "}
                        <FontAwesomeIcon icon="fa-solid fa-angle-right" />
                      </Link>{" "}
                      <span> Schedule Interview</span>
                    </a>
                  </div>

                  <div className="col-lg-6 col-md-3">
                    <div className="left-blue-line mt-3"></div>
                  </div>
                  <div className="col-lg-3 col-md-12 col-12 mt-3  order-md-1 order-2">
                    <div className="edit-section">
                      <div className="text-end">
                        <button className="edit-btn">
                          <FontAwesomeIcon icon="fa-solid fa-pen" /> edit
                        </button>
                      </div>
                      <div className="row">
                        <div className="col-xxl-1 col-xl-2 col-lg-1 col-md-1 col-1">
                          <div className="social-icon">
                            <FontAwesomeIcon
                              icon="fa-solid fa-calendar-days"
                              className="shorlisted-icon"
                            />
                          </div>
                        </div>
                        <div className="col-xxl-11 col-xl-10 col-lg-10 col-md-11 col-11">
                          <div className="date">
                            {/* <input type='date' /> */}
                            <p className="edit-shhe-box-p">10.OCT.23</p>
                          </div>
                        </div>
                        <div className="col-xxl-1 col-xl-2 col-lg-1 col-md-1 col-1">
                          <div className="social-icon mt-md-3">
                            <FontAwesomeIcon
                              icon="fa-solid fa-clock"
                              className="shorlisted-icon"
                            />
                          </div>
                        </div>
                        <div className="col-xxl-11 col-xl-10 col-lg-10 col-md-11 col-11">
                          <div className="time mt-md-3   ">
                            <p className="edit-shhe-box-p">
                              10.00 am - 12.00 pm
                            </p>
                          </div>
                        </div>
                        <div className="col-xxl-1 col-xl-2 col-lg-1 col-md-1 col-1">
                          <div className="social-icon">
                            <FontAwesomeIcon
                              icon="fa-solid fa-location-dot"
                              className="shorlisted-icon"
                            />
                          </div>
                        </div>
                        <div className="col-xxl-11 col-xl-10 col-lg-10 col-md-11 col-11">
                          <div className="date">
                            <p className="edit-shhe-box-p">Kharadi, Pune</p>
                          </div>
                        </div>
                        <div className="col-xxl-1 col-xl-2 col-lg-1 col-md-1 col-1">
                          <div className="social-icon">
                            <FontAwesomeIcon
                              icon="fa-solid fa-user-clock"
                              className="shorlisted-icon"
                            />
                          </div>
                        </div>
                        <div className="col-xxl-11 col-xl-10 col-lg-10 col-md-11 col-11">
                          <div className="date">
                            <p className="edit-shhe-box-p">HR Round</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*  */}
                  <div className="col-lg-9 col-md-12 mt-3  order-md-2 order-1">
                    <div className="interview-details">
                      <div className="position">
                        <h2> Position: Data Analyst</h2>
                      </div>
                      <div className="row mt-3">
                        <div className="col-md-3 col-3">
                          <p className="intt-tpp">
                            <FontAwesomeIcon
                              icon="fa-solid fa-message"
                              className="shorlisted-icon me-2"
                            />
                            Interview Type
                          </p>
                        </div>

                        <div className="col-md-4 col-9">
                          <div className="intt-tttt-div">
                            <label className="me-2">
                              <input
                                type="radio"
                                value="option1"
                                checked={selectedOption === "option1"}
                                onChange={handleRadioChange}
                                className="me-2"
                              />
                              Walk In
                            </label>

                            <label className="me-2">
                              <input
                                type="radio"
                                value="option2"
                                checked={selectedOption === "option2"}
                                onChange={handleRadioChange}
                                className="me-2"
                              />
                              Telephonic
                            </label>
                            <label className="me-2">
                              <input
                                type="radio"
                                value="option3"
                                checked={selectedOption === "option3"}
                                onChange={handleRadioChange}
                                className="me-2"
                              />
                              Video
                            </label>
                          </div>
                        </div>
                        <div className="col-md-3 12">
                          <a>Create Video Link</a>
                        </div>
                      </div>
                      <div className="col-md-12 ">
                        <textarea
                          className="form-control input-set texttt-ar"
                          placeholder="Type a Message if required"
                          type="text"
                          id=""
                          cols="30"
                          rows="3"
                        ></textarea>
                      </div>
                      <div className=" text-center mt-3 ">
                        <button
                          onClick={openModal}
                          className="btn send-notification "
                        >
                          <FontAwesomeIcon icon="fa-solid fa-paper-plane" />{" "}
                          Send Notification to Candidate
                        </button>
                        <ScheduleModal
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SchedulInterview;
