import React, { useEffect } from "react";
import "../OnlinePortfolio/OnlinePortfolio.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useContext } from "react";
import { Context } from "../../../../utils/context";
const OnlinePortfolio = () => {
  const { signin } = useContext(Context);

  return (
    <>
      <section className=" OnlinePortfolio">
        <div className="container-fluid">
          <div className="row">
            <div className="Banner_OnlinePortfolio">
              <img
                src={
                  process.env.PUBLIC_URL +
                  "assets/Images/banner/back_img_portfoilio.png"
                }
                className="back_img"
                alt="..."
              />
              <div className="upper_content_portfolio row ">
                <div className="col-lg-7 col-md-7 col-12">
                  <div className="info_section">
                    <span className="makeDifference_txt">
                      Make a Difference
                    </span>
                    <br />
                    <span className="onlinePort_txt">
                      with Your Online Portfolio!
                    </span>
                    <p className="info">
                      Make a difference with a platform where you can search
                      <br />
                      millions of jobs online to find the next step in your
                      career.
                    </p>

                    {!signin && (
                      <div className="button-container">
                        <Link to={"/getotp-jobseeker"}>
                          <button className="btn btn-primary createaccbtn">
                            Create Account
                          </button>
                        </Link>
                        <div className="arrow-icon">
                          <FontAwesomeIcon icon={faArrowRight} />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-lg-5 col-md-5 col-12">
                  <div className="mx-auto text-center">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "assets/Images/banner/man_img_portfolio.png"
                      }
                      className="portfolio_man_img"
                      alt="..."
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="OnlinePortfolio ">
                        <div className="container">
                            <div className="row mt-5">
                                <div className="col-md-7">
                                    <div className="text-holder-job-alert">
                                    <div className='info_section'>
                                        <span className='makeDifference_txt'>Make a Difference </span><br/>
                                        <span className='onlinePort_txt'>with Your Online Portfolio!</span>
                                    <p className='info'>Make a difference with a platform where you can search <br/>millions of jobs online to find the next step in your career.</p>
                                    <button className='btn createAcc_btn'>Create Account</button>
                                    </div>

                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div className="mx-auto text-center">
                                        <img src={process.env.PUBLIC_URL + "assets/Images/banner/girlimg.png"} className="userimg" alt="..." />
                                    </div>
                                    <div className="OpportunityverticalLine"></div>
                                </div>
                            </div>
                        </div>
                    </section> */}
    </>
  );
};

export default OnlinePortfolio;
