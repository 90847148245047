import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Success from '../../SignUpForm/Animation/Success';


function ContactModal(props) {
    
    return (  
      <>     
        <div >
          <Modal
            {...props} className='main-modal'
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
  
            <Modal.Body>
              <Success />
              <p className='modal_txt text-center'> Signed In Successfully!</p>
              {/* <p className='text-center' style={{ color: "#FFFF" }}>  </p> */}
            </Modal.Body>
  
          </Modal>
  
        </div>
      </>
      
    );
  }
  
  export default ContactModal;
  