import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ThirdJobTabModal from "../ThirdJobTab/ThirdJobTabModal.js";
import ReasonModal from "./ReasonModal/ReasonModal.js";
import "../SecondJobTab/SecondJobTab.css";
import "./ThirdJobTabModal.css";
import "./ThirdJobTab.css";
import { Context } from "../../../../utils/context.js";

const ThirdJobTab = () => {
  const { getData, postData, imageUrl, NoDataAnimations } = useContext(Context);

  const [data, setData] = useState([]);

  const getAllData = async () => {
    const res = await getData(`/jobseeker/jobs/about-interview`);
    setData(res.data);
  };

  const [isModalOpen, setModalOpen] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const openModal = async (id) => {
    const res = await postData(`/jobseeker/jobs/accept-reject`, {
      id: id,
      accept_or_reject: 1,
    });
    // console.log(res);
    if (res.success) {
      setModalShow(true);
      setTimeout(() => {
        setModalShow(false);
        getAllData();
      }, 3000);
    }
  };

  const [modalShow1, setModalShow1] = useState(false);

  const openModal1 = async () => {
    setModalShow1(true);

    // setTimeout(() => {
    //   setModalShow1(false);
    // }, 3000);
  };

  useEffect(() => {
    getAllData();
  }, []);

  return (
    <>
      <section className="manage-job ">
        <div className="container">
          <div className="sec-title mt-md-5 mt-3">
            <h5 className="title-talent">About Interview</h5>
          </div>

          <div className="row mt-md-5 mt-0 mb-5">
            {data?.map((interview, index) => (
              <div key={index} className="col-md-12">
                <div className="Third-job mt-4">
                  <div className="row">
                    <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-3 col-3">
                      <div className="desktop-view mt-md-3 mx-auto">
                        <img
                          src={imageUrl + interview?.job?.job_contact?.logo}
                          className="companyimg"
                          alt="..."
                        />
                      </div>
                      {/* Mobile view */}
                      <div className="backgorund-desird">
                        <img
                          src={imageUrl + interview?.job?.job_contact?.logo}
                          className="company-img-interview"
                          alt="..."
                        />
                      </div>
                    </div>
                    <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-3 col-9">
                      <div className="text-holder-manage-job mt-3 mt-md-0">
                        <p className="company-name">Company Name:</p>
                        <h5>{interview?.job?.company?.name}</h5>
                      </div>
                    </div>
                    <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-3">
                      <div className="text-holder-manage-job">
                        <p className="me-2 ms-3 ms-md-0 mt-2 mt-md-0">
                          Position:
                        </p>
                        <h5 className="mt-1 ms-3 ms-md-0 mt-2 mt-md-0">
                          {interview?.job?.role?.name}
                        </h5>
                      </div>
                    </div>
                    <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-3">
                      <div className="text-holder-manage-job">
                        <p className="ms-3 ms-md-0">
                          <FontAwesomeIcon
                            icon="fa-solid fa-calendar-days"
                            className="me-2 social-icon"
                          />
                          {interview?.job_schedule_interview?.date}
                        </p>
                      </div>
                      <div className="text-holder-manage-job">
                        <p className="ms-3 ms-md-0">
                          <FontAwesomeIcon
                            icon="fa-solid fa-clock"
                            className="social-icon me-2"
                          />
                          Start Time{" "}
                          {interview?.job_schedule_interview?.start_time}
                          {/* and
                          End Time {interview?.job_schedule_interview?.end_time} */}
                        </p>
                        <p className="ms-3 ms-md-0">
                          <FontAwesomeIcon
                            icon="fa-solid fa-clock"
                            className="social-icon me-2"
                          />
                          End Time {interview?.job_schedule_interview?.end_time}
                        </p>
                      </div>
                    </div>
                    <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-3 col-12">
                      <div className="text-holder-manage-job">
                        <p className="ms-3 ms-md-0">
                          <FontAwesomeIcon
                            icon="fa-solid fa-location-dot"
                            className="me-2 social-icon"
                          />
                          {interview?.job_schedule_interview?.city?.name}
                        </p>
                        <p className="desktop-view ms-3 ms-md-0">
                          <FontAwesomeIcon
                            icon="fa-solid fa-user-gear"
                            className="me-2 social-icon"
                          />
                          {interview?.job_schedule_interview?.round?.name}
                        </p>
                      </div>
                    </div>
                    {/* mobile-view */}
                    <div className="hr-round">
                      <div className="text-holder-manage-job">
                        <p className="ms-3 ms-md-0">
                          <FontAwesomeIcon
                            icon="fa-solid fa-user-gear"
                            className="me-2 social-icon"
                          />
                          {interview?.job_schedule_interview?.round?.name}
                        </p>
                      </div>
                    </div>
                    <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-3 col-12">
                      <div className="text-holder-manage-job">
                        <div className="row mb-2">
                          {/* <div className="col-12"> */}
                          {/* <p className="ms-3 "> */}
                          {/* Interview: {interview?.status} */}
                          {/* </p> */}
                          {/* </div> */}
                          <div className="col-12">
                            <div className="d-flex accept_reject_btn ms-2">
                              <div>
                                <button
                                  type="button"
                                  onClick={() =>
                                    openModal(
                                      interview?.job_schedule_interview?.id
                                    )
                                  }
                                  className="btn accept-btn me-2"
                                >
                                  Accept
                                </button>
                                <ThirdJobTabModal
                                  show={modalShow}
                                  onHide={() => setModalShow(false)}
                                />
                              </div>
                              <div>
                                <button
                                  type="button"
                                  onClick={openModal1}
                                  className="btn reject-btn"
                                >
                                  Reject
                                </button>
                                <ReasonModal
                                  show={modalShow1}
                                  id={interview?.job_schedule_interview?.id}
                                  getAllData={getAllData}
                                  onHide={() => setModalShow1(false)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {data?.length == 0 && <NoDataAnimations />}
          </div>
        </div>
      </section>
    </>
  );
};

export default ThirdJobTab;
