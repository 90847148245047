import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../../../utils/context";
import "../FifithJobTab/FifthJobTab.css";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "@fortawesome/fontawesome-svg-core/styles.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import ApplyModal from "../../ApplyModel/ApplyModal";
import {
  faEllipsisVertical,
  faClock,
  faGraduationCap,
  faBriefcase,
  faDesktop,
  faMoneyBill,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import JobDescriptionModal from "../../JobDescriptionModel/JobDescriptionModal";

const FifthJobTab = (props) => {
  const [modalShow1, setModalShow1] = useState(false);

  const [data, setData] = useState({});
  const {
    jobSeekerData,
    fetchSeekerData,
    getData,
    imageUrl,
    NoDataAnimations,
  } = useContext(Context);
  const [modalShow, setModalShow] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contact: "",
    job_id: "",
  });

  const [savedData, setSavedData] = useState();
  const getSavedJobs = async () => {
    const res = await getData(`/jobseeker/saved`);
    if (res?.success) {
      setSavedData(res.data);
    }
  };

  const handleApply = async (data) => {
    // console.log(jobSeekerData);
    await setFormData({
      name: jobSeekerData?.first_name + jobSeekerData?.last_name,
      email: jobSeekerData?.email,
      contact: jobSeekerData?.contact_no,
      job_id: data?.id,
    });
    setModalShow1(true);
  };

  useEffect(() => {
    getSavedJobs();
    fetchSeekerData();
  }, []);

  return (
    <>
      <section className="SaveJob">
        <div className="container">
          <div className="sec-title mt-md-5 mt-3">
            <h5 className="title-talent">Saved Jobs</h5>
          </div>

          <div className="fiftheJobTab_breadcrum">
            <div className="desktop-view">
              {/* <Breadcrumb>
                <Breadcrumb.Item href="#">Go to Home</Breadcrumb.Item>

                <Breadcrumb.Item active>Saved Jobs</Breadcrumb.Item>
                <span className="under-line"></span>
              </Breadcrumb> */}
            </div>
          </div>
          <div className="cards">
            <div className="row mt-4">
              {savedData?.map((value, index) => (
                <div className="col-xl-4 col-lg-6 col-md-6 col-12">
                  <Card className="cardapplied">
                    <div className="logo-div">
                      <Card.Img
                        variant="top"
                        src={imageUrl + value?.job?.job_contact?.logo}
                        className=" company-logo"
                      />
                      <div className="ellips-icon">
                        <FontAwesomeIcon
                          icon={faEllipsisVertical}
                          className="dots-ellips"
                          onClick={() => setModalShow(true)}
                        />
                        <JobDescriptionModal
                          show={modalShow}
                          data={data}
                          getSavedJobs={getSavedJobs}
                          title={"Job Description"}
                          onHide={() => setModalShow(false)}
                        />
                        {/* mobile-view btn */}
                        <div className="mobile-view-class">
                          <FontAwesomeIcon icon="fa-solid fa-trash-can" />
                        </div>
                        <ul
                          className="dropdown-menu dropdown-menu-end p-0"
                          aria-labelledby="actionButton"
                        >
                          <li className="fifthTab_edit_view">
                            <Link className="dropdown-item">Remove</Link>
                          </li>
                        </ul>
                      </div>
                      <div>
                        <p>{value?.job?.company?.name}</p>
                      </div>
                    </div>

                    <Card.Body>
                      <div className="search-data-card">
                        <Card.Title>{value?.job?.role?.name}</Card.Title>
                        <Card.Text>
                          <div className="row">
                            <div className="col-1">
                              <span className="">
                                <FontAwesomeIcon icon={faClock} />
                              </span>
                            </div>
                            <div className="col-9">
                              <span className="text">
                                {value?.job?.job_create_shifts?.map(
                                  (shift, shiftIndex) => (
                                    <React.Fragment key={shiftIndex}>
                                      {shift?.shift?.name}
                                      {shiftIndex <
                                        value.job.job_create_shifts.length -
                                          1 && " , "}
                                    </React.Fragment>
                                  )
                                )}
                              </span>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-1">
                              <span className="">
                                <FontAwesomeIcon icon={faGraduationCap} />
                              </span>
                            </div>
                            <div className="col-9">
                              <span className=" text">
                                {value?.job?.job_qualifications?.map(
                                  (shift, shiftIndex) => (
                                    <React.Fragment key={shiftIndex}>
                                      {shift?.qualification?.name}
                                      {shiftIndex <
                                        value.job.job_qualifications.length -
                                          1 && " , "}
                                    </React.Fragment>
                                  )
                                )}
                              </span>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-1">
                              <span className="">
                                <FontAwesomeIcon icon={faBriefcase} />{" "}
                              </span>
                            </div>
                            <div className="col-9">
                              <span className=" text">
                                Experienced min.{" "}
                                {value?.job?.experience_in_year?.name} Yr.
                              </span>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-1">
                              <span className="">
                                <FontAwesomeIcon icon={faDesktop} />
                              </span>
                            </div>
                            <div className="col-9">
                              <div className=" text">
                                {value?.job?.job_primary_skills?.map(
                                  (shift, shiftIndex) => (
                                    <React.Fragment key={shiftIndex}>
                                      {shift?.skill?.name}
                                      {shiftIndex <
                                        value.job.job_primary_skills.length -
                                          1 && " , "}
                                    </React.Fragment>
                                  )
                                )}
                              </div>
                            </div>
                          </div>
                        </Card.Text>
                      </div>
                    </Card.Body>
                    <ListGroup className="list-group-flush card-text-holder">
                      <div>
                        <span>
                          <FontAwesomeIcon icon="fa-solid fa-sack-dollar" />
                        </span>
                        <span className="px-3 text">
                          {value?.job?.end_salary}
                          {/* {(value?.job?.end_salary / 100000)
                            .toFixed(1)
                            .replace(/\.0$/, "")} */}
                          LPA
                        </span>
                      </div>
                      {/* <div className=" foot">
                        <div className="">
                          <div className="">
                            <p className="m-0 p-0 text">Review</p>
                            <span>
                              <FontAwesomeIcon
                                icon={faStar}
                                className="icon-color"
                              />
                            </span>
                            <span>
                              <FontAwesomeIcon
                                icon={faStar}
                                className="icon-color"
                              />
                            </span>
                            <span>
                              <FontAwesomeIcon
                                icon={faStar}
                                className="icon-color"
                              />
                            </span>
                            <span>
                              <FontAwesomeIcon
                                icon={faStar}
                                className="icon-color"
                              />
                            </span>
                            <span>
                              <FontAwesomeIcon icon={faStar} />
                            </span>
                          </div>
                        </div>
                        <div className="posted-date-text mt-2">
                          <p>Job Posted on : {new Date(value?.job?.createdAt).toLocaleDateString(
                                "en-US",
                                {
                                  day: "2-digit",
                                  month: "short",
                                  year: "numeric",
                                }
                              )}</p>
                        </div>
                      </div> */}
                      <div className="apply-butn ">
                        <div className="mobile-view-class">
                          {/* <Link to="/job-description"> */}
                          {/* <button className="btn create-job-btn me-2 " onClick={() => {
                            setModalShow(true);
                            setData(value?.job);
                          }}>
                            <span className="under-line">View JD</span>
                          </button> */}
                          {/* </Link> */}
                          {/* <Link to="/apply-job"> */}
                          <button
                            className="btn create-job-btn "
                            onClick={() => {
                              setModalShow(true);
                              setData(value?.job);
                            }}
                          >
                            <span className="under-line">Apply</span>
                          </button>
                          {/* </Link> */}
                        </div>
                        <div className="desktop-view">
                          {/* <Link to="/apply-job"> */}
                          <button
                            className="btn create-job-btn "
                            onClick={() => {
                              setModalShow(true);
                              setData(value?.job);
                            }}
                          >
                            <span className="under-line">Apply</span>
                          </button>

                          <ApplyModal
                            show={modalShow1}
                            formData={formData}
                            setFormData={setFormData}
                            onHide={() => setModalShow1(false)}
                            onJDHide={props.onJDHide}
                          />
                          {/* </Link> */}
                        </div>
                      </div>
                    </ListGroup>
                  </Card>
                </div>
              ))}
              {savedData?.length == 0 && <NoDataAnimations />}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FifthJobTab;
