import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { useNavigate, useLocation } from "react-router-dom";
import ContactModal from "./ContactModal/ContactModal";

import { useContext } from "react";
import { Context } from "../../../../utils/context";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
function Step2({ handleNext }) {
  const { postData, recruiterData, setRecruiterData } = useContext(Context);

  const [emailVerify, setEmailVerify] = useState(false);

  const [modalShow, setModalShow] = useState(false);
  const [timerShow, setTimerShow] = useState(false);

  const [seconds, setSeconds] = useState(0); // Initial value is 60 seconds
  const [isTimerExpired, setIsTimerExpired] = useState(false);
  const [otpERR, setOtpErr] = useState("");

  const [otpsend, setOtpSend] = useState(null);

  const [error, seterror] = useState(false);
  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);
  const inputRef3 = useRef(null);
  const inputRef4 = useRef(null);
  const navigate = useNavigate();
  const { state } = useLocation();

  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [emailDisable, setEmailDis] = useState(false);

  const handleValidation = async (type) => {
    // console.log(recruiterData, "recruiterData");

    // Initialize an array to store error messages
    var errors = [];

    if (email.trim() === "") {
      setEmailErr("Email is required");
      errors.push("Email is required");
    } else if (
      !/^([A-Za-z0-9_\-\.])+\@(?!(?:[A-Za-z0-9_\-\.]+\.)?([A-Za-z]{2,4})\.\2)([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(
        email
      )
    ) {
      setEmailErr("Email is not valid");
      errors.push("Email is not valid");
    }

    if (errors.length === 0) {
      if (type === "next") {
        if (emailVerify == false) {
          setEmailErr("Verify Your Email");
          errors.push("Verify Your Email");
        } else {
          // console.log('next');
          recruiterData.email = email;
          recruiterData.emailVerify = true;
          handleNext();
        }
      }
      if (type === "otp") {
        // console.log('otp');
        setEmailErr("");
        const randOtp = Math.floor(1000 + Math.random() * 9000).toString();
        // console.log(randOtp, 'random');
        const res = await postData("/without-login/verify-otp/email-verify", {
          email: email,
          otp: randOtp,
        });

        if (res?.success) {
          // console.log(res, 'emailotp');
          setOtpSend(randOtp);
          setModalShow(true);
          setTimerShow(true);
          setIsTimerExpired(false);
          setOtpErr("");
          setOtpValues(["", "", "", ""]);
          setSeconds(60);
          setEmailDis(true);
          setTimeout(() => {
            setModalShow(false);
          }, 4000);
        } else {
          // console.log(res, 'fail');
          setEmailErr(res?.message);
        }
      }
    }
  };

  const [otpValues, setOtpValues] = useState(["", "", "", ""]);
  const inputRefs = [useRef(), useRef(), useRef(), useRef()];

  const handleOtpChange = (index, event) => {
    setOtpErr("");
    const inputValue = event.target.value;

    if (/^[0-9]*$/.test(inputValue) || inputValue === "") {
      otpValues[index] = inputValue;
      setOtpValues([...otpValues]);

      if (otpsend === otpValues.join("")) {
        // Call your OTP verification function here
        setEmailVerify(true);
        setTimerShow(false);
        setOtpValues(["", "", "", ""]);
        setOtpSend(null);
      }

      if (inputValue !== "" && index < 3) {
        inputRefs[index + 1].current.focus();
      } else {
        setOtpErr("OTP Invalid!");
      }
    }
  };

  const handleKeyDown = (index, event) => {
    setOtpErr("");
    if (event.key === "Backspace" && index > 0 && otpValues[index] === "") {
      otpValues[index - 1] = "";
      setOtpValues([...otpValues]);
      inputRefs[index - 1].current.focus();
    }
  };
  const [executeCount, setExecuteCount] = useState(0);

  const handleTerms = (element) => {
    if (element.target.checked) {
      recruiterData.policy = true;
    } else {
      recruiterData.policy = false;
    }
  };

  useEffect(() => {
    if (executeCount == 0) {
      if (recruiterData?.emailVerify) {
        setEmail(recruiterData?.email);
        setEmailVerify(true);
      }
      setExecuteCount(1);
    }

    const decrementTimer = () => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else {
        setIsTimerExpired(true);
        setOtpSend(null);
        setEmailDis(false);
      }
    };

    const timerInterval = setInterval(decrementTimer, 1000);

    return () => {
      clearInterval(timerInterval);
    };
  }, [seconds]);

  return (
    <>
      <fieldset>
        <div className="form-card StepForm-recruiter">
          <div className="row">
            <div className="col-7">
              <h2>
                <span className="blue-line"></span>{" "}
                <span className="fs-title">Step2</span>
              </h2>
            </div>
          </div>

          <Form>
            <div className="enter-email mt-0">
              <Form.Group as={Row} controlId="formPlaintextEmail">
                <Form.Label className="email" column lg="2" md="3" sm="12">
                  Email<span className="star">*</span>
                </Form.Label>
                <Col lg="10" md="9" sm="12">
                  <div className="input-group mb-3">
                    <input
                      className="form-control mt-0 p-2"
                      placeholder="Enter your email id"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      disabled={emailVerify || emailDisable}
                      defaultValue={email}
                      type="email"
                      id="email"
                      onChange={(event) => {
                        setEmail(event.target.value);
                        setEmailErr("");
                        setEmailVerify(false);
                        setTimerShow(false);
                      }}
                    />

                    {emailVerify === false && timerShow !== true && (
                      <span
                        className="input-group-text"
                        style={{ cursor: "pointer" }}
                        id="basic-addon2"
                        onClick={() => {
                          handleValidation("otp");
                        }}
                      >
                        Get OTP
                      </span>
                    )}
                    {emailVerify === false &&
                      timerShow === true &&
                      isTimerExpired === true && (
                        <span
                          className="input-group-text"
                          style={{ cursor: "pointer" }}
                          id="basic-addon2"
                          onClick={() => {
                            handleValidation("otp");
                          }}
                        >
                          Resend OTP
                        </span>
                      )}
                  </div>

                  <div>
                    <ContactModal
                      show={modalShow}
                      onHide={() => setModalShow(false)}
                    />
                  </div>
                  <span
                    className="text text-danger"
                    style={{
                      textAlign: "center",
                    }}
                  >
                    {emailErr && emailErr}
                  </span>

                  {timerShow && (
                    <div className="row text-center">
                      <div className="otpContainer">
                        {otpValues.map((value, index) => (
                          <input
                            className="otp"
                            type="text"
                            maxLength="1"
                            value={value}
                            onChange={(e) => handleOtpChange(index, e)}
                            onKeyDown={(e) => handleKeyDown(index, e)}
                            ref={inputRefs[index]}
                          />
                        ))}
                      </div>

                      <p className="otp">
                        Enter OTP within{" "}
                        {`${Math.floor(seconds / 60)}:${(seconds % 60)
                          .toString()
                          .padStart(2, "0")}`}{" "}
                        seconds
                      </p>
                      <span
                        className="text text-danger"
                        style={{
                          textAlign: "center",
                        }}
                      >
                        {otpERR && otpERR}
                      </span>
                    </div>
                  )}
                </Col>
              </Form.Group>

              {emailVerify && (
                <div className="row text-center">
                  <p className="otp text text-success">Email Verified...!</p>
                </div>
              )}
            </div>
            <div className="mobile-view-button">
              <button
                type="button"
                name="next"
                className="next action-button contin-btn"
                onClick={() => handleValidation("next")}
              >
                Continue
              </button>
            </div>
          </Form>
        </div>
      </fieldset>
      <div className="row  mb-4 footer-sec">
        <div className="col-md-10 col-12">
          <div className="check-row text-start">
            <span>
              <input
                type="checkbox"
                name="group1"
                className="me-2"
                onChange={handleTerms}
                checked={recruiterData?.policy}
              />
            </span>
            <span className=" by-text ">By signing up, I agree to the</span>
            <span className="px-1 ">
              <Link to="/terms" className="term-btn">
                Term and Conditions
              </Link>
            </span>
            <br />
          </div>
          <div className="login-row text-start">
            <span className="">Already an User?</span>
            <span className="px-1 ">
              <Link to="/other-page" className="login-btn">
                log In
              </Link>
            </span>
            <br />
            <span className="">Not a Recruiter ?</span>
            <span className="px-1 ">
              <Link to="/getotp-jobseeker" className="signup-btn">
                Signup as Jobseeker
              </Link>
            </span>
          </div>
        </div>

        <div className="col-2">
          <button
            type="button"
            name="next"
            className="next action-button desktop-view-button"
            onClick={() => handleValidation("next")}
          >
            Continue
          </button>
        </div>
      </div>
    </>
  );
}

export default Step2;
