import React, { useState } from "react";
import { Link } from "react-router-dom";
import '../OpenJobs/OpenJobs.css'
import '../DraftedJobs/DraftedJobs.css';
import MyModal from '../MyModal/MyModel.js';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Select from 'react-select';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
const DraftedJobs = () => {
    const [selectedOption, setSelectedOption] = useState('');

    const options = [
        { value: 'developer', label: 'developer' },
        { value: 'accountent', label: 'accountent' },
        { value: 'web developer', label: 'web developer' },
    ];

    const [isModalOpen, setModalOpen] = useState(false);

    const openModal = () => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };
    return (
        <>
            <section className="manage-job">
                <div className='container'>
                    <div className="row mt-3">
                        <div className="col-md-12">
                            <div className="section-title">
                                <div className="row">
                                    <div className="col-md-12">
                                        {/* <div className="title-drafted-job text-center">
                                            <p className="mt-5">Drafted Jobs</p>
                                        </div> */}
                                        <div className="heading-holder1 text-center my-md-5 mt-3">
                                            <h5 className="mt-md-5 mt-0 hiring-name">Drafted Jobs</h5>
                                        </div>
                                    </div>
                                    <div className=" col-xxl-4 col-xl-4 col-lg-4 col-md-6 ">
                                        <a className="title-open-jobs"><Link to='/postedjob'>Go to List of Till Date Posted Jobs </Link><FontAwesomeIcon icon="fa-solid fa-angle-right" className="mx-2" /> <Link to='/openjobs'>Open Jobs</Link> <FontAwesomeIcon icon="fa-solid fa-angle-right" className="mx-2" /> <span> Drafted Jobs </span></a>
                                    </div>

                                    <div className=" col-xxl-8 col-xl-8 col-lg-8 col-md-6">
                                        <div className="left-black-line"></div>
                                    </div>

                                    {/*  */}
                                    <div className="col-md-12 col-12 Posted-job">
                                        <div className="first-company ">
                                            <div className="row ms-lg-5 ms-md-0">
                                                <div className="col-md-10 col-8">
                                                    <div className="company-name">
                                                        <h5 className="position mt-md-3 mt-1">Graphic & UI Designer</h5>
                                                    </div>
                                                </div>
                                                <div className='col-md-2 col-4 mt-3 pool-silver text-md-start text-end'><Link to='/editjobs'><FontAwesomeIcon icon="fa-solid fa-pen-to-square" /> Edit Details</Link></div>
                                                <div className="col-md-12">
                                                    <div className="text-holder-employer">
                                                        <p className="job-post mt-md-3 mt-0"><p>Profcyma Solution Pvt. Ltd</p></p>
                                                    </div>
                                                </div>
                                                <div className='col-md-10 col-8 d-flex'>
                                                    <p className="pool-silver me-2"> <FontAwesomeIcon icon="fa-solid fa-star" /><FontAwesomeIcon icon="fa-solid fa-star" /><FontAwesomeIcon icon="fa-solid fa-star" /><FontAwesomeIcon icon="fa-solid fa-star" /><FontAwesomeIcon icon="fa-solid fa-star" className='me-2' />9,408 reviews  </p>
                                                    <p className="pool-silver me-2" ><FontAwesomeIcon icon="fa-solid fa-location-dot" /> Bavdhan, Pune</p>
                                                </div>
                                                <div className='col-md-2 col-4'>
                                                    <p className="pool-silver text-md-start text-end">Silver Pool (S1) </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" col-xxl-8 col-xl-8 col-lg-8 col-md-6">
                                        <div className="left-black-line"></div>
                                    </div>
                                    <div className="col-md-12">
                                    <Form className='create-job-form mt-md-0 mt-3'>
                                            <Row className=" mt-md-5 mt-3">
                                                <div className="col-xxl-9 col-xl-9 col-lg-8 col-md-6 ">
                                                    <Form.Group className="mb-3" controlId="formGroupEmail">
                                                        <Form.Label>Job Title :</Form.Label>
                                                        <Form.Control type="text" placeholder="Recipient's username" />
                                                    </Form.Group>
                                                </div>
                                                <div className=" col-xxl-3 col-xl-3 col-lg-4 col-md-6 ">
                                                    <Form.Group className="mb-3" controlId="formGroupPassword">
                                                        <Form.Label>Job Type :</Form.Label>
                                                        <Select className='select-line'
                                                            defaultValue={selectedOption}
                                                            onChange={setSelectedOption}
                                                            options={options}
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-xxl-9 col-xl-9 col-lg-8 col-md-6 col-12">
                                                    <Form.Group className="mb-3" controlId="formGroupPassword">
                                                        <Form.Label>Industry :</Form.Label>
                                                        <Form.Control type="text" placeholder="Username" />
                                                    </Form.Group>
                                                </div>

                                                <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-12">
                                                    <Form.Group className="mb-3" controlId="formGroupPassword">
                                                        <Form.Label>Work Location :</Form.Label>
                                                        <Select className='select-line'
                                                            defaultValue={selectedOption}
                                                            onChange={setSelectedOption}
                                                            options={options}
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-xxl-9 col-xl-9 col-lg-8 col-md-6 col-12">
                                                    <Form.Group className="mb-3" controlId="formGroupPassword">
                                                        <Form.Label>Sector :</Form.Label>
                                                        <Form.Control type="text" placeholder="Username" />
                                                    </Form.Group>

                                                </div>
                                                <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-12 ">
                                                    <Form.Group className="mb-3" controlId="formGroupPassword">
                                                        <Form.Label>Career Level :</Form.Label>
                                                        <Select className='select-line'
                                                            defaultValue={selectedOption}
                                                            onChange={setSelectedOption}
                                                            options={options}
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-xxl-9 col-xl-9 col-lg-8 col-md-6 col-12">
                                                    <Form.Group className="mb-3" controlId="formGroupPassword">
                                                        <Form.Label>Qualification :</Form.Label>
                                                        <Form.Control type="text" placeholder="Username" />
                                                    </Form.Group>

                                                </div>

                                                <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-12">
                                                    <Form.Group className="mb-3" controlId="formGroupPassword">
                                                        <Form.Label>Select Prof. Experience in Years :</Form.Label>
                                                        <Select className='select-line'
                                                            defaultValue={selectedOption}
                                                            onChange={setSelectedOption}
                                                            options={options}
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-xxl-9 col-xl-9 col-lg-8 col-md-6">
                                                    <Form.Group className="mb-3" controlId="formGroupPassword">
                                                        <Form.Label>Primary Skills :</Form.Label>
                                                        <Form.Control as="textarea" rows={1} />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6">
                                                    <Form.Group className="mb-3" controlId="formGroupPassword">
                                                        <Form.Label>Number of Vacancy :</Form.Label>
                                                        <Select className='select-line'
                                                            defaultValue={selectedOption}
                                                            onChange={setSelectedOption}
                                                            options={options}
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-xxl-9 col-xl-9 col-lg-8 col-md-6">
                                                    <Form.Group className="mb-3" controlId="formGroupPassword">
                                                        <Form.Label>Secondary Skills</Form.Label>
                                                        <Form.Control as="textarea" rows={1} />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mt-4">
                                                    <Form.Group className="mb-3" controlId="formGroupPassword">
                                                        <Form.Control type="text" placeholder="Username" />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-12 col-12">
                                                    <Form.Group className="mb-3" controlId="formGroupPassword">
                                                        <Form.Label>Job description:</Form.Label>
                                                        <Form.Control as="textarea" rows={1} />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <Form.Group className="mb-3" controlId="formGroupPassword">
                                                        <Form.Label>Languages:</Form.Label>
                                                        <Form.Control type="text" placeholder="Username" />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <Form.Group className="mb-3" controlId="formGroupPassword">
                                                        <Form.Label>Shift and Schedule:</Form.Label>
                                                        <Form.Control type="text" placeholder="Username" />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <Form.Group className="mb-3" controlId="formGroupPassword">
                                                        <Form.Label>Roles and responsibilities:</Form.Label>
                                                        <Form.Control as="textarea" rows={1} />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <Form.Group className="mb-3" controlId="formGroupPassword">
                                                        <Form.Label>Skills required:</Form.Label>
                                                        <Form.Control as="textarea" rows={1} />
                                                    </Form.Group>
                                                </div>
                                                <div className=" col-12">
                                                    <Form.Group className="mb-3" controlId="formGroupPassword">
                                                        <p>Contact:</p>
                                                        <Form.Label>H.R. Person / Recruiter</Form.Label>
                                                        <Form.Control type="text" placeholder="Username" />
                                                    </Form.Group>
                                                </div>
                                                {/* <div className="col-xxl-2 col-xl-2 col-lg-4 col-md-4 col-12">
                                                    <Form.Group className="mb-3" controlId="formGroupPassword">
                                                        <Form.Label className='mt-5'>Upload Company Logo</Form.Label>
                                                        <Form.Control type="text" placeholder="Username" />
                                                    </Form.Group>

                                                </div> */}
                                                <div className="col-md-4 col-12">
                                                    <Form.Group className="mb-3" controlId="formGroupPassword">
                                                        <Form.Label>Recruiter Contact Number:</Form.Label>
                                                        <Form.Control type="text" placeholder="Username" />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-4 col-12">
                                                    <Form.Group className="mb-3" controlId="formGroupPassword">
                                                        <Form.Label>Recruiter Email:</Form.Label>
                                                        <Form.Control type="text" placeholder="Username" />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-4 col-12">
                                                    <Form.Group className="mb-3" controlId="formGroupPassword">
                                                        <Form.Label>Application Deadline:</Form.Label>
                                                        <Form.Control type="text" placeholder="Username" />
                                                    </Form.Group>
                                                </div>
                                                <div className='justify-content-center d-flex mt-5 mb-5'>
                                                    <Link to='/draft-3'><button className='btn btn-primary me-3'>Ok</button></Link>
                                                </div>
                                            </Row>
                                        </Form>
                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default DraftedJobs;