import React, { useContext } from "react";
import { Context } from "./utils/context.js";
import { useNavigate } from "react-router-dom";
import MainHome from "../src/Components/CommonPage/Home/MainHome/MainHome.js";

const Middleware = ({ component: Component }) => {
  // Destructure `component` prop
  const { signin, usertype } = useContext(Context);
  const navigate = useNavigate();
  if (signin) {
    if (usertype === "Recruiter") {
      return <Component />;
    } else {
      return <Component />;
    }
  } else {
    return <MainHome />;
  }
};

export default Middleware;
