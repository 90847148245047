import React, { useState, useEffect } from 'react';
import "./AboutText.css";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


const AboutText = () => {

  const [counter, setCounter] = useState(0);

  useEffect(() => {
    const incrementCounter = () => {
      setCounter((prevCounter) => {
        const newCounter = prevCounter + 1;

        return newCounter <= 100 ? newCounter : prevCounter;
      });
    };

    const intervalId = setInterval(incrementCounter, 50);

    return () => clearInterval(intervalId);
  }, []);
  return (
    <section className="about-text-section">
      <div className="container">
        <div className="text">
          <p>
            Hiclousia strives to put job seekers first, giving them free access
            to search for jobs, post resumes, and research companies.
          </p>
          <p>Every day, we connect millions of people to new opportunities.</p>
        </div>

        <div className="card-box">
            
        <Row>
        <Col className="col-4">
          <div className="first-col">
              <h2>{counter}M+</h2>
              <p>Unique Monthly Visitors</p>
          </div>
        
        </Col>
        <Col className="col-4">
        <div className="sec-col">
              <h2>{counter}M</h2>
              <p>Portfolios on Hiclousia</p>
          </div>
        
        </Col>
        <Col className="col-4">
        <div className="third-col">
              <h2>{counter}M+</h2>
              <p>Total ratings and reviews</p>
          </div>
        
        </Col>
        
      </Row>

        </div>
      </div>
    </section>
  );
};

export default AboutText;
