import React from 'react';
import '../ShortBanner/ShortBanner.css'
import banner from '../image/banner.png'

const ShortBanner = () => {
    return (
        <>
            <section className="short-banner">
                <div className='container-fluid '>
                    <div className="row ">
                        <div className="col-md-12">
                            <div className='banner-img-short'>
                                <img src={banner} className='banner-img' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ShortBanner;