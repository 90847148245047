import React, { useState, useEffect } from "react";
import "./SecondTab.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import PostedModaledit from "../PostedJobs/Modal/PostedModaledit";

import { useContext } from "react";
import { Context } from "../../../../utils/context";
const SecondTab = (props) => {
  const buttonTypes = ["open", "paused", "closed"];
  const [activeButton, setActiveButton] = useState(null);

  const handleButtonClick = async (buttonType, jobId) => {
    const data = {
      job_status_id: buttonType,
    };
    const res = await editStatusData(
      `/recruiters/job/job-create/${jobId}`,
      data
    );
    if (res?.success) {
      // console.log(res, 'statusChange');
      getPostedJobs();
    }
  };

  const { getData, imageUrl, editStatusData, NoDataAnimations } =
    useContext(Context);

  const [show, setShow] = useState(false);

  const [modalShow, setModalShow] = React.useState(0);
  // console.log(modalShow, 'testing');
  const [showAll, setShowAll] = useState(false);
  // const [jobId, setJobId] = useState("");

  const [postedJobs, setPostedJob] = useState();
  const [jobStatus, setJobStatus] = useState();
  const [statusID, setStatusId] = useState("");
  const [poolID, setPoolID] = useState("");

  const getPostedJobs = async () => {
    const res = await getData(
      `/recruiters/job/job-create?status=${statusID}&subcategory=${poolID}`
    );
    if (res?.success) {
      // console.log(res.data, 'postedJobs');
      setPostedJob(res.data.data);
      setJobStatus(res.data.count);

      res?.data?.count?.map((data) => {
        // console.log(data, 'statuses');
        // Add any further processing or code related to each element in the map
        if (data.job_status.name === "Open") {
        } else if (data.job_status === "Paused") {
        } else if (data.job_status === "Closed") {
        }
      });
    }
  };

  const visibleJobs = showAll ? postedJobs : postedJobs?.slice(0, 6);

  const statusColor = [
    {
      color: "open-btn2",
      name: "Open",
      id: 4,
      color2: "open-btn",
    },

    {
      color: "paused-btn2",
      name: "Paused",
      id: 5,
      color2: "paused-btn",
    },

    {
      color: "closed-btn2",
      name: "Closed",
      id: 6,
      color2: "closed-btn",
    },
  ];

  const [pools, setPools] = useState();

  const getSubCategory = async () => {
    const res = await getData(
      `/without-login/master/all-talent-pool-subcategory`
    );
    if (res?.success) {
      // console.log(res.data, 'subCategory');
      setPools(res.data);
    }
  };

  useEffect(() => {
    getPostedJobs();
    getSubCategory();
  }, [statusID, poolID]);

  return (
    <>
      <section className="manage-job recruiter_manageJob">
        <div className="container">
          <div className="text-center managedJob_uppertxt">
            <h6 className="posted-tttt">List of Till Date Posted Jobs</h6>
          </div>
          <div className="row mt-md-5 mt-2">
            <div className="col-lg-6 col-md-6 order-md-1 order-1">
              <button
                className="btn open-btn2 me-2 mb-2"
                style={{
                  backgroundColor: "#00dcff",
                }}
                onClick={() => setStatusId("")}
              >
                All Jobs
              </button>

              {jobStatus ? (
                <>
                  {statusColor?.map((value, index) =>
                    jobStatus[index]?.job_status_id ? (
                      <button
                        className={`btn ${value?.color} me-2 mb-2`}
                        onClick={() =>
                          setStatusId(jobStatus[index]?.job_status_id)
                        }
                      >
                        {jobStatus[index]?.job_status?.name} (
                        {jobStatus[index]?.count})
                      </button>
                    ) : (
                      <button
                        className={`btn ${value?.color} me-2 mb-2`}
                        onClick={() => setStatusId(value?.id)}
                      >
                        {value?.name} (0)
                      </button>
                    )
                  )}
                </>
              ) : (
                <></>
              )}

              {/* {jobStatus?.map((value,index) => (
                                    value?.job_status_id ?  <button 
                                    className={`btn ${statusColor[index].color} me-2`}
                                    onClick={() => setStatusId(value?.job_status_id)}
                                    >{value?.job_status?.name} ({value?.count})</button> :<button 
                                    className={`btn ${statusColor[index].color} me-2`}
                                    onClick={() => setStatusId()}
                                    ></button>
                            ))} */}

              {/* <button className="btn open-btn2 me-2" onClick={() => setStatusId(4)}>Open (1)</button>
                            <button className="btn paused-btn2 me-2" onClick={() => setStatusId(5)}>Paused (1)</button>
                            <button className="btn closed-btn2" onClick={() => setStatusId(6)}>Closed (2)</button> */}
            </div>
            {/* <div className="col-lg-3 col-md-0"></div> */}
            <div className="col-lg-6 col-md-6 order-md-2 order-0 text-end btn-revvvv mt-md-0 mb-md-0 mt-3 mb-3">
              {/* <div className="btn search-pool-btn me-2 ">Search by Pool<img src={process.env.PUBLIC_URL + "assets/Images/icons/filter.png"} className="ms-2" alt="..." /></div> */}
              <div className="row">
                <div className="col-lg-4 col-6">
                  {/* <Link to='/draft-2'><button className="btn Draft-btn me-4">Draft Jobs (3)</button></Link> */}
                </div>
                <div className="col-lg-8 col-6">
                  <div className="thirdTab_select d-flex justify-content-end">
                    <select
                      className="form-select text-center"
                      aria-label="Default select example"
                      onChange={(event) => setPoolID(event.target.value)}
                    >
                      <option value="" selected>
                        Search by pool
                      </option>
                      {pools?.map((value) => (
                        <option value={value?.id}>{value?.name}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 order-2">
              <div className="section-title mt-4">
                <Tabs className="row">
                  <div className=" col-md-4">
                    <h5 className="title-talent">
                      List of Till Date Posted Jobs
                    </h5>
                  </div>
                  <div className=" col-md-6">
                    <div className="left-blue-line"></div>
                  </div>
                  <div className="col-md-2 text-end">
                    <div className="secondTab_tabPane">
                      <TabList>
                        <Tab>
                          <FontAwesomeIcon
                            icon="fa-solid fa-border-all"
                            className="me-5"
                          />
                        </Tab>
                        <Tab>
                          <FontAwesomeIcon icon="fa-solid fa-list-ul" />
                        </Tab>
                      </TabList>
                    </div>
                  </div>
                  <div className="list-grid-view">
                    {/* Grid view */}
                    <TabPanel>
                      <div className="grid-view">
                        <div className="row mb-5">
                          {visibleJobs?.map((job, index) => (
                            <div key={index} className="col-lg-4 col-md-6 ">
                              <div className="first-company">
                                <div className="row">
                                  <div className="col-lg-8 col-md-8 col-8">
                                    <div className="text-holder-employer">
                                      <h5 className="position">
                                        {job?.role?.name}
                                      </h5>
                                      <sup>{job?.company?.name}</sup>
                                    </div>
                                  </div>
                                  <div className="col-lg-4 col-md-4 col-4 text-end">
                                    <div className="btn-group secondTab_dropdown">
                                      <Link
                                        className="nav-link"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <FontAwesomeIcon icon="ellipsis-vertical" />
                                      </Link>
                                      <ul
                                        className="dropdown-menu dropdown-menu-end p-0"
                                        aria-labelledby="actionButton"
                                      >
                                        <li
                                          variant="primary"
                                          onClick={() => {
                                            setModalShow(job?.id);
                                          }}
                                        >
                                          <Link className="dropdown-item">
                                            Edit Job details
                                          </Link>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <div className="text-holder-employer">
                                  <p className="job-post">
                                    <FontAwesomeIcon
                                      icon="calendar-days"
                                      className="social-icon me-2"
                                    />
                                    <b>Job Posted on :</b>{" "}
                                    {new Date(
                                      job?.createdAt
                                    ).toLocaleDateString("en-US", {
                                      day: "2-digit",
                                      month: "short",
                                      year: "numeric",
                                    })}
                                  </p>
                                  <p className="job-post">
                                    <FontAwesomeIcon
                                      icon="location-dot"
                                      className="social-icon me-2"
                                    />
                                    <b>Location: </b>
                                    {job?.job_work_locations?.map(
                                      (location, index) => (
                                        <span key={index}>
                                          {location?.city?.name}
                                          {index <
                                          job?.job_work_locations.length - 1
                                            ? ", "
                                            : ""}
                                        </span>
                                      )
                                    )}
                                  </p>
                                  <p className="job-post">
                                    <FontAwesomeIcon
                                      icon="star"
                                      className="social-icon me-2"
                                    />
                                    <b>Pool:</b>{" "}
                                    {
                                      job?.talent_pool_plan
                                        ?.talent_pool_subcategory?.name
                                    }
                                  </p>
                                </div>

                                <div className="shortlisting text-center">
                                  {statusColor.map((buttonType) => (
                                    <button
                                      key={buttonType}
                                      className={`btn ${
                                        buttonType?.color2
                                      } me-2 ${
                                        buttonType?.id === job?.job_status_id
                                          ? "active"
                                          : ""
                                      }`}
                                      onClick={() =>
                                        handleButtonClick(
                                          buttonType?.id,
                                          job?.id
                                        )
                                      }
                                    >
                                      {buttonType?.name}
                                    </button>
                                  ))}
                                </div>
                              </div>
                            </div>
                          ))}
                          {postedJobs?.length === 0 && <NoDataAnimations />}
                        </div>
                        {postedJobs?.length > 6 && (
                          <div className="text-center mt-5">
                            <button
                              className="show-more2 showsecondbtn"
                              onClick={() => setShowAll(!showAll)}
                            >
                              {showAll ? "Show Less" : "Show More"}{" "}
                              <FontAwesomeIcon icon="fa-solid fa-chevron-down" />
                            </button>
                          </div>
                        )}
                      </div>
                    </TabPanel>
                    <TabPanel>
                      {/* List View */}
                      {visibleJobs?.map((job, index) => (
                        <div
                          className="manage-job-text mt-4 shadow-lg p-3 mb-4 bg-body rounded"
                          key={index}
                        >
                          <div className="row">
                            <div className="col-lg-10 col-md-9">
                              <div className="text-holder-manage-job">
                                <p>{job?.job_title?.name}</p>
                                <sup>{job?.company?.name}</sup>
                              </div>
                            </div>
                            <div className="col-md-2 text-end">
                              <div className="btn-group secondTab_dropdown">
                                <Link
                                  className="nav-link"
                                  exact="true"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                  id="actionButton"
                                  activeclassname="active"
                                >
                                  <FontAwesomeIcon icon="fa-solid fa-ellipsis-vertical" />
                                </Link>
                                <ul
                                  className="dropdown-menu dropdown-menu-end p-0"
                                  aria-labelledby="actionButton"
                                >
                                  <li
                                    variant="primary"
                                    onClick={() => {
                                      setModalShow(job?.id);
                                    }}
                                  >
                                    <Link className="dropdown-item">
                                      Edit Job details
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-4">
                              <div className="text-holder-manage-job">
                                <h5>
                                  <FontAwesomeIcon icon="fa-solid fa-calendar-days" />{" "}
                                  Job Posted on :{" "}
                                  {new Date(job?.createdAt).toLocaleDateString(
                                    "en-US",
                                    {
                                      day: "2-digit",
                                      month: "short",
                                      year: "numeric",
                                    }
                                  )}
                                </h5>
                              </div>
                            </div>
                            <div className="col-lg-2 col-md-4">
                              <div className="text-holder-manage-job">
                                <h5>
                                  <FontAwesomeIcon icon="fa-solid fa-location-dot" />{" "}
                                  Location:{" "}
                                  {job?.job_work_locations?.map(
                                    (location, index) => (
                                      <span key={index}>
                                        {location?.city?.name}
                                        {index <
                                        job?.job_work_locations.length - 1
                                          ? ", "
                                          : ""}
                                      </span>
                                    )
                                  )}
                                </h5>
                              </div>
                            </div>
                            <div className="col-lg-2 col-md-4">
                              <div className="text-holder-manage-job">
                                <h5>
                                  <FontAwesomeIcon icon="fa-solid fa-star" />{" "}
                                  Pool:{" "}
                                  {
                                    job?.talent_pool_plan
                                      ?.talent_pool_subcategory?.name
                                  }
                                </h5>
                              </div>
                            </div>
                            <div className="col-lg-5 col-md-12">
                              <div className="shortlisting text-lg-center text-md-start">
                                {statusColor.map((buttonType) => (
                                  <button
                                    className={`btn ${
                                      buttonType?.color2
                                    } me-2 ${
                                      buttonType?.id === job?.job_status_id
                                        ? "active"
                                        : ""
                                    }`}
                                    onClick={() =>
                                      handleButtonClick(buttonType?.id, job?.id)
                                    }
                                  >
                                    {buttonType?.name}
                                  </button>
                                ))}

                                {/* <button className="btn paused-btn me-2" >Paused</button>
                                                                <button className="btn closed-btn me-2" > Closed</button> */}
                                {/* <Link to='/hiring'><a className="btn Hiring">Hiring Funnel</a></Link> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                      {postedJobs?.length === 0 && <NoDataAnimations />}
                      {postedJobs?.length > 6 && (
                        <div className="text-center mt-5">
                          <button
                            className="show-more2"
                            onClick={() => setShowAll(!showAll)}
                          >
                            {showAll ? "Show Less" : "Show More"}{" "}
                            <FontAwesomeIcon icon="fa-solid fa-chevron-down" />
                          </button>
                        </div>
                      )}
                    </TabPanel>
                  </div>
                </Tabs>
                <PostedModaledit
                  show={modalShow}
                  setModalShow={setModalShow}
                  getPostedJobs={getPostedJobs}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SecondTab;
