import React, { useEffect, useState, useRef } from "react";
import "./Talent_pool.css";
import Button from "react-bootstrap/Button";
import { Link, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { Navigation, Autoplay } from "swiper/modules";
import { PacmanLoader } from "react-spinners";

import { useContext } from "react";
import { Context } from "../../../../utils/context";
const TalentPool = () => {
  const navigateToDashboard = useNavigate();
  const navigateClick = () => {
    navigateToDashboard("/dashboardmain");
  };

  const textClassMap = {
    0: "card_silver_txt",
    1: "card_golden_txt",
    2: "card_platinum_txt",
  };

  const textClassMap1 = {
    0: "card_silver_txt1",
    1: "card_golden_txt1",
    2: "card_platinum_txt1",
  };

  const { getData, imageUrl } = useContext(Context);

  const [loader, setLoader] = useState(true);
  const [talentPools, setTalentPool] = useState();
  const [freePool, setFreePool] = useState();

  const getTalentPool = async () => {
    const res = await getData("/recruiters/talent-pool/all-talent-pool");
    if (res?.success) {
      // console.log(res.data, "talentPools");
      setTalentPool(res.data.data);
      setFreePool(res.data.freeused);
      setLoader(false);
    }
  };

  useEffect(() => {
    getTalentPool();
  }, []);
  return (
    <>
      <section className="Talent_pool mt-4">
        <div className="container">
          <h3 className="main_heading text-center mb-4">Buy a Talent Pool</h3>
          <div className="text_horizantal_line mb-4">
            <span className="talent_txt" onClick={navigateClick}>
              Go to Dashboard
            </span>
          </div>
          <div className="row desktop-view-pool">
            {loader ? (
              <div className="container" style={{ paddingLeft: "50%" }}>
                <PacmanLoader color="#36d7b7" />
              </div>
            ) : (
              <>
                {talentPools?.map((category, index) => (
                  <div key={category?.id} className="col-lg-4 col-md-6  ">
                    <div className={`titleClass ${textClassMap1[index]}`}>
                      {category?.name}
                    </div>

                    <div className="premiumCards">
                      {category?.talent_pool_subcategories?.map(
                        (subcategory, planIndex) => (
                          <>
                            {subcategory?.talent_pool_plans?.map(
                              (planValue, planValueIndex) => (
                                <div
                                  key={planValueIndex}
                                  className="card card_talent_pool mb-4 mt-lg-4 mt-md-4 mt-2"
                                >
                                  <div className="card-body">
                                    <div className="row ">
                                      <div className="col-lg-8 col-md-6">
                                        <div
                                          className={`textClass ${textClassMap[index]}`}
                                        >
                                          {subcategory?.name}
                                        </div>
                                      </div>
                                      <div className="col-lg-4 col-md-6">
                                        <div className="buyNow_section">
                                          {freePool?.includes(planValue?.id) ? (
                                            <>
                                              <span className="text-end buyNow_txt text-danger">
                                                Purchased Pool
                                              </span>
                                            </>
                                          ) : (
                                            <Link
                                              to={`/buynow/${index}/${planIndex}/${planValueIndex}`}
                                            >
                                              <span className="text-end buyNow_txt">
                                                Buy Now
                                              </span>
                                            </Link>
                                          )}
                                        </div>
                                      </div>
                                    </div>

                                    <ul className="mt-4 ">
                                      <li className="text-right">
                                        Job Post
                                        <span className="ms-2">
                                          : {planValue?.job_posts}
                                        </span>{" "}
                                      </li>
                                      <li className="text-right">
                                        Duration
                                        <span className="ms-2">
                                          : {planValue?.days_id} Days
                                        </span>{" "}
                                      </li>
                                      <li className="text-right">
                                        Price
                                        <span className="ms-3">
                                          : ₹ {planValue?.price}
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              )
                            )}
                          </>
                        )
                      )}
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>

          <div className="row mobileview-talentpool">
            {loader ? (
              <div className="container" style={{ paddingLeft: "50%" }}>
                <PacmanLoader color="#36d7b7" />
              </div>
            ) : (
              <>
                {talentPools?.map((category, index) => (
                  <div key={category?.id}>
                    <div className={`titleClass ${textClassMap1[index]}`}>
                      {category?.name}
                    </div>
                    <div className="premiumCards">
                      <Swiper
                        spaceBetween={10}
                        // // slidesPerView={7}
                        modules={[Navigation, Autoplay]}
                        breakpoints={{
                          0: {
                            slidesPerView: 2,
                            // spaceBetween: 10,
                          },
                          360: {
                            slidesPerView: 2,
                            // spaceBetween: 10,
                          },
                          567: {
                            slidesPerView: 2,
                            // spaceBetween: 10,
                          },
                          767: {
                            slidesPerView: 3,
                            // spaceBetween: 10,
                          },
                        }}
                      >
                        {category?.talent_pool_subcategories?.map(
                          (subcategory, planIndex) => (
                            <>
                              {subcategory?.talent_pool_plans?.map(
                                (planValue, planValueIndex) => (
                                  <SwiperSlide>
                                    <div
                                      key={index}
                                      className="card card_talent_pool mb-4 mt-lg-4  mt-2"
                                    >
                                      <div className="card-body">
                                        <div className="row ">
                                          <div className="col-lg-8 col-md-6">
                                            <div
                                              className={`textClass ${textClassMap[index]}`}
                                            >
                                              {subcategory?.name}
                                            </div>
                                          </div>
                                        </div>

                                        <ul className="mt-4 ">
                                          <li className="text-right">
                                            Job Post
                                            <span className="ms-2">
                                              : {planValue?.job_posts}
                                            </span>{" "}
                                          </li>
                                          <li className="text-right">
                                            Duration
                                            <span className="ms-2">
                                              : {planValue?.days_id} Days
                                            </span>{" "}
                                          </li>
                                          <li className="text-right">
                                            Price
                                            <span className="ms-3">
                                              : ₹ {planValue?.price}
                                            </span>
                                          </li>
                                        </ul>

                                        <Link
                                          to={`/buynow/${category?.id}/${subcategory?.id}/${planValue?.id}`}
                                        >
                                          <div className="text-center">
                                            <button className="text-center buyNow_txt">
                                              Buy Now
                                            </button>
                                          </div>
                                        </Link>
                                      </div>
                                    </div>
                                  </SwiperSlide>
                                )
                              )}
                            </>
                          )
                        )}
                      </Swiper>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
          <div className="text-center mb-5 ">
            {/* <Button variant="primary" className='skip-btn'><Link to={"/dashboardmain"}>Skip</Link></Button> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default TalentPool;
