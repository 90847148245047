import React from 'react'
import Modal from 'react-bootstrap/Modal';
import Success from '../../../Model/Animation/Success';
import "./UpdateModal.css"
const UpdateModal = (props) => {
    return (
        <>
            <div className="update-modal" id="update-modal-one">
                <Modal
                    {...props}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className='updateModal'
                >
                    <Modal.Body>
                        <Success />
                        <p className="update text-center"> Updated Successfully!</p>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    )
}

export default UpdateModal