import React from 'react'
import Lottie from 'react-lottie';
import * as animationData from './barAnimation.json';
import "./BarAnimation.css"
const BarAnimation = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    return (
        <>
            <div className='bar_lottie'>
                <div className='mb-3  barAnimation'>
                    <Lottie
                        options={defaultOptions}
                        height={250}
                        width={250}
                    />
                </div>
                <p className='text-center lottieBelowtxt'>No data Available</p>
            </div>
        </>
    )
}

export default BarAnimation