import React from 'react'
import DesiredJobs from './DesiredJob/DesiredJob';
import Title from './Title/Title';

const DesiredJobSearch = () => {
    return (
        <>
        <Title/>
        <DesiredJobs/>
        </>
    )
}

export default DesiredJobSearch;