import React from 'react';
import Success from '../../../../JobseekerPage/DashBoardJob/ThirdJobTab/Animation/success.json';
import Modal from 'react-bootstrap/Modal';

function ScheduleModal(props) {
  return (
    <>
      <section className='PopupModal'>
        <Modal
          {...props}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered>

          <Modal.Body>
            <Success />
            <p className='modal_txt'> Interview Schedule has been Sent Successfully!</p>
            <p className='text-center' style={{ color: "#FFFF" }}> Have a Great Time! </p>
          </Modal.Body>
        </Modal>

      </section>
      
    </>

  );
}

export default ScheduleModal;