import React from "react";
import Breadcrumb from 'react-bootstrap/Breadcrumb'
const ApplyJobsTitle = () => {
    return (
        <>
            <section className="Sixth-job-tab">
                <div className='container'>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="tittle-sixth-job-tab ">
                                <div className="desktop-view">
                                    <h5 className="text-center mt-5">Apply for Job</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-5 col-xl-6 col-lg-7 col-md-9">
                            <div className="desktop-view">
                                <Breadcrumb>
                                    <Breadcrumb.Item href="/">Go to Home</Breadcrumb.Item>
                                    <Breadcrumb.Item href="/job-search">Saved Jobs</Breadcrumb.Item>
                                    <Breadcrumb.Item href="/job-description">Job Description</Breadcrumb.Item>
                                    <Breadcrumb.Item active>Apply for Job</Breadcrumb.Item>
                                    <span className="under-line"></span>
                                </Breadcrumb>
                            </div>
                        </div>
                        <div className="col-xxl-7 col-xl-6 col-lg-5 col-md-3">
                            <div className="desktop-view">
                                <div className="blue-line">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ApplyJobsTitle;