import React, { useState } from "react";
import "../JobBanner/JobBanner.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faMapMarker,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

const JobBanner = () => {
  return (
    <>
      <section className="Job-Tab-banner">
        <div className="container-fluid p-0">
          <div className="JobTabbanner_imgSection">
            <img
              src={
                process.env.PUBLIC_URL + "assets/Images/banner/dashbanner.png"
              }
              className="banner-img"
              alt="..."
            />
            <div className="container JobTabbanner_upperPart">
              <div className="row justify-content-center ">
                <div className="col-xl-5 col-lg-6 col-md-12 col-12 p-0">
                  <div className="title-1">
                    <div className="text-holder-jobbanner">
                      <h5>Grab a Best Opportunity</h5>
                      <h6>Towards Better Future </h6>
                    </div>
                  </div>
                </div>
                <div className="col-xl-7 col-lg-6 col-md-12 col-12 p-0">
                  {/* <div className="title-2">
                                        <div className="row search_section">
                                            <div className="col-lg-12 mx-auto">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5">
                                                                <div className="btn-group searchJobs_section pe-0">
                                                                    <FontAwesomeIcon icon={faSearch} className="search_icon_banner mt-2 ms-1" />
                                                                    <input type="text" className="form-control search_icon_menu" data-bs-toggle="dropdown" aria-expanded="false" id="actionButton" placeholder="Search Jobs"></input>
                                                                    <ul className="dropdown-menu dropdown-menu-start" aria-labelledby="actionButton">
                                                                        <div className="mysearch_section mb-2 p-2">
                                                                            <div className="text_horizantal_line">
                                                                                <p className="talent_txt mb-0">My Search</p>
                                                                            </div>
                                                                            <div>
                                                                                <span className="content_dropdown_menu me-2">Development   <FontAwesomeIcon icon={faTimes} className="ms-3" /></span>
                                                                                <span className="content_dropdown_menu me-2">Banking <FontAwesomeIcon icon={faTimes} className="ms-3" /> </span>
                                                                                <span className="content_dropdown_menu me-2">Deign/Architecture <FontAwesomeIcon icon={faTimes} className="ms-3" /> </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="mysearch_section p-2">
                                                                            <div className="text_horizantal_line">
                                                                                <p className="talent_txt mb-0">Sectors</p>

                                                                            </div>
                                                                            <div className="mt-2">
                                                                                <div className="d-flex">
                                                                                    <FontAwesomeIcon icon={["fas", "user-gear"]} className="sector_icons" />
                                                                                    <p className="ms-2 sector_dropdown">Admin/Back Office</p>
                                                                                </div>

                                                                                <div className="d-flex">
                                                                                    <FontAwesomeIcon icon={["fas", "bullhorn"]} className="sector_icons" />
                                                                                    <p className="ms-2 sector_dropdown">Advertising/Communication</p>
                                                                                </div>

                                                                                <div className="d-flex">
                                                                                    <FontAwesomeIcon icon={["fas", "building-columns"]} className="sector_icons" />
                                                                                    <p className="ms-2 sector_dropdown">Banking</p>
                                                                                </div>

                                                                                <div className="d-flex">
                                                                                    <FontAwesomeIcon icon={["fas", "laptop-code"]} className="sector_icons" />
                                                                                    <p className="ms-2 sector_dropdown">Development</p>
                                                                                </div>

                                                                                <div className="d-flex">
                                                                                    <FontAwesomeIcon icon={["fas", "laptop-code"]} className="sector_icons" />
                                                                                    <p className="ms-2 sector_dropdown">Development</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5 ps-0">
                                                                <div className="input-group citiesDropdownBanner mb-3">
                                                                    <FontAwesomeIcon icon="fa-solid fa-location-dot" className="location_icon_banner" />
                                                                    <select className="form-select form-control" id="inputGroupSelect01">
                                                                        <option selected>All Cities</option>
                                                                        <option value="1">Mumbai</option>
                                                                        <option value="2">Pune</option>
                                                                        <option value="3">Bangalore</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                                                                <button className="btn btn-primary search_btn">Search</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default JobBanner;
