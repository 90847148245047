import React, { useState } from 'react';
import Select from 'react-select';
import '../OpenJobs/OpenJobs.css';
import '../SearchPool/SearchPool.css';
import '../CreateJobs/CreateJobs.css'
import { Link } from 'react-router-dom';
import CreateModal from './CreateModal.js';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
const CreateJobs = (props) => {
    const [selectedOption, setSelectedOption] = useState('');
    const options = [
        { value: 'developer', label: 'developer' },
        { value: 'accountent', label: 'accountent' },
        { value: 'web developer', label: 'web developer' },
    ];


    return (
        <>
            <section className="manage-job">
                <div className='container'>
                    <div className="row mt-md-2 mt-4">
                        <div className="col-md-12">
                            <div className="section-title mt-md-3 mt-0">
                                <div className="row">
                                    <div className="col-md-12 ">
                                        <div className="title-creat-job text-center">
                                            <h5>Create Job (Silver S1)</h5>
                                        </div>
                                    </div>
                                    <div className=" col-xxl-5 col-xl-5 col-lg-5 col-md-7">
                                        <Link to='/dashboardmain'><a className="title-open-jobs">Go to Dashboard </a></Link>
                                    </div>
                                    <div className="col-md-12 mt-4">
                                        <div className="create-job-form">
                                            <div className="row jobTitle_form_sec">
                                                <Form>
                                                    <Row>
                                                        <div className="col-xxl-9 col-xl-9 col-lg-8 col-md-6 ">
                                                            <Form.Group className="mb-3" controlId="formGroupEmail">
                                                                <Form.Label>Job Title :</Form.Label>
                                                                <Form.Control type="text" placeholder="Username" />
                                                            </Form.Group>
                                                        </div>
                                                        <div className=" col-xxl-3 col-xl-3 col-lg-4 col-md-6 ">
                                                            <Form.Group className="mb-3" controlId="formGroupPassword">
                                                                <Form.Label>Job Type :</Form.Label>
                                                                <Select className='select-line'
                                                                    defaultValue={selectedOption}
                                                                    onChange={setSelectedOption}
                                                                    options={options}
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                        <div className="col-xxl-9 col-xl-9 col-lg-8 col-md-6 col-12">
                                                            <Form.Group className="mb-3" controlId="formGroupPassword">
                                                                <Form.Label>Industry :</Form.Label>
                                                                <Form.Control type="text" placeholder="Username" />
                                                            </Form.Group>
                                                        </div>

                                                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-12">
                                                            <Form.Group className="mb-3" controlId="formGroupPassword">
                                                                <Form.Label>Work Location :</Form.Label>
                                                                <Select className='select-line'
                                                                    defaultValue={selectedOption}
                                                                    onChange={setSelectedOption}
                                                                    options={options}
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                        <div className="col-xxl-9 col-xl-9 col-lg-8 col-md-6 col-12">
                                                            <Form.Group className="mb-3" controlId="formGroupPassword">
                                                                <Form.Label>Sector :</Form.Label>
                                                                <Form.Control type="text" placeholder="Username" />
                                                            </Form.Group>

                                                        </div>
                                                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-12 ">
                                                            <Form.Group className="mb-3" controlId="formGroupPassword">
                                                                <Form.Label>Career Level :</Form.Label>
                                                                <Select className='select-line'
                                                                    defaultValue={selectedOption}
                                                                    onChange={setSelectedOption}
                                                                    options={options}
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                        <div className="col-xxl-9 col-xl-9 col-lg-8 col-md-6 col-12">
                                                            <Form.Group className="mb-3" controlId="formGroupPassword">
                                                                <Form.Label>Qualification :</Form.Label>
                                                                <Form.Control type="text" placeholder="Username" />
                                                            </Form.Group>

                                                        </div>

                                                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-12">
                                                            <Form.Group className="mb-3" controlId="formGroupPassword">
                                                                <Form.Label>Select Prof. Experience:</Form.Label>
                                                                <Select className='select-line'
                                                                    defaultValue={selectedOption}
                                                                    onChange={setSelectedOption}
                                                                    options={options}
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                        <div className="col-xxl-9 col-xl-9 col-lg-8 col-md-6">
                                                            <Form.Group className="mb-3" controlId="formGroupPassword">
                                                                <Form.Label>Primary Skills :</Form.Label>
                                                                <Form.Control as="textarea" rows={1} />
                                                            </Form.Group>
                                                        </div>
                                                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6">
                                                            <Form.Group className="mb-3" controlId="formGroupPassword">
                                                                <Form.Label>Number of Vacancy :</Form.Label>
                                                                <Select className='select-line'
                                                                    defaultValue={selectedOption}
                                                                    onChange={setSelectedOption}
                                                                    options={options}
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                        <div className="col-xxl-9 col-xl-9 col-lg-8 col-md-6">
                                                            <Form.Group className="mb-3" controlId="formGroupPassword">
                                                                <Form.Label>Secondary Skills</Form.Label>
                                                                <Form.Control as="textarea" rows={1} />
                                                            </Form.Group>
                                                        </div>
                                                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mt-4">
                                                            <Form.Group className="mb-3" controlId="formGroupPassword">
                                                                <Form.Control type="text" placeholder="Username" />
                                                            </Form.Group>
                                                        </div>
                                                        <div className="col-md-12 col-12">
                                                            <Form.Group className="mb-3" controlId="formGroupPassword">
                                                                <Form.Label>Job description:</Form.Label>
                                                                <Form.Control as="textarea" rows={1} />
                                                            </Form.Group>
                                                        </div>
                                                        <div className="col-md-6 col-12">
                                                            <Form.Group className="mb-3" controlId="formGroupPassword">
                                                                <Form.Label>Languages:</Form.Label>
                                                                <Form.Control type="text" placeholder="Username" />
                                                            </Form.Group>
                                                        </div>
                                                        <div className="col-md-6 col-12">
                                                            <Form.Group className="mb-3" controlId="formGroupPassword">
                                                                <Form.Label>Shift and Schedule:</Form.Label>
                                                                <Form.Control type="text" placeholder="Username" />
                                                            </Form.Group>
                                                        </div>
                                                        <div className="col-md-6 col-12">
                                                            <Form.Group className="mb-3" controlId="formGroupPassword">
                                                                <Form.Label>Roles and responsibilities:</Form.Label>
                                                                <Form.Control as="textarea" rows={1} />
                                                            </Form.Group>
                                                        </div>
                                                        <div className="col-md-6 col-12">
                                                            <Form.Group className="mb-3" controlId="formGroupPassword">
                                                                <Form.Label>Skills required:</Form.Label>
                                                                <Form.Control as="textarea" rows={1} />
                                                            </Form.Group>
                                                        </div>
                                                        <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-12">
                                                            <Form.Group className="mb-3" controlId="formGroupPassword">
                                                                <p>Contact:</p>
                                                                <Form.Label>H.R. Person / Recruiter</Form.Label>
                                                                <Form.Control type="text" placeholder="Username" />
                                                            </Form.Group>
                                                        </div>
                                                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12">
                                                            <Form.Group className="mb-3" controlId="formGroupPassword">
                                                                <Form.Label className='mt-5'>Upload Company Logo</Form.Label>
                                                                <Form.Control type="file" placeholder="Username" />
                                                            </Form.Group>

                                                        </div>
                                                        <div className="col-md-4 col-12">
                                                            <Form.Group className="mb-3" controlId="formGroupPassword">
                                                                <Form.Label>Recruiter Contact Number:</Form.Label>
                                                                <Form.Control type="text" placeholder="Username" />
                                                            </Form.Group>
                                                        </div>
                                                        <div className="col-md-4 col-12">
                                                            <Form.Group className="mb-3" controlId="formGroupPassword">
                                                                <Form.Label>Recruiter Email:</Form.Label>
                                                                <Form.Control type="text" placeholder="Username" />
                                                            </Form.Group>
                                                        </div>
                                                        <div className="col-md-4 col-12">
                                                            <Form.Group className="mb-3" controlId="formGroupPassword">
                                                                <Form.Label>Application Deadline:</Form.Label>
                                                                <Form.Control type="text" placeholder="Username" />
                                                            </Form.Group>
                                                        </div>
                                                        <div className='text-center mt-5 mb-5'>
                                                            <Button type='button' className='btn show-more mb-2'>Post a Job</Button>
                                                            <Button onClick={() => { props.setModalShow(false) }}>Close</Button>
                                                            {/* <CreateModal show={props.modalShow}
                                                            onHide={() => setModalShow(false)} /> */}
                                                        </div>
                                                    </Row>
                                                </Form>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CreateJobs;