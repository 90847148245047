import React from 'react'
import "./PageNotFound.css"
import { Link } from 'react-router-dom'
const PageNotFound = () => {
  return (
    <>
    <div className="PageNotFound justify-content-center">
        <h1 className="error">404</h1>
        <div className="page">Ooops!!! The page you are looking for is not found</div>
        <Link className="back-home" to={"/"}>Back to home</Link>
    </div>
    </>
  )
}

export default PageNotFound