import React, { useContext, useEffect, useState } from "react";
import "./PortfolioJobExperience.css";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom";
import { Context } from "../../../../utils/context";
import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import Modal from "react-bootstrap/Modal";
const PortfolioFreelancingForm = () => {
  // const [lgShow, setLgShow] = useState(false);

  // const handleClose = () => setLgShow(false);
  // const handleShow = () => setLgShow(true);
  const {
    getData,
    postData,
    recruiterData,
    jobSeekerData,
    editStatusData,
    getJobSeekerData,
    Select2Data,
    deleteData,
    fetchSeekerData,
    Select2Skills,
  } = useContext(Context);

  const [skills, setSkills] = useState([]);
  const [achievemente, setAchievemente] = useState([]);
  const [certificateFor, setCertificateFor] = useState([]);
  const [selectedskills, setSelectedSkills] = useState([]);
  const [errors, setErrors] = useState();

  const [cards, setCards] = useState([]);

  const [formData, setFormData] = useState({
    step: 9,
    media: undefined,
    client_name: "",
    project_title: "",
    description: "",
    start_date: "",
    end_date: "",
    your_role: "",
    project_outcome: "",
    project_link: "",
  });

  const validateForm = () => {
    let errors = {};
    const regexAlfaNumric = /^[A-Za-z0-9]+(\s[A-Za-z0-9]+)*$/;
    const regexAlfa = /^[A-Za-z]+(\s[A-Za-z]+)*$/;

    if (!formData.project_title) {
      errors.project_title = "project title is required";
    } else if (!regexAlfaNumric.test(formData?.project_title?.trim())) {
      errors.project_title = "Enter a valid data";
    }

    // if (!formData.description) {
    //   errors.description = "description is required";
    // } else if (!regexAlfaNumric.test(formData?.description?.trim())) {
    //   errors.description = "Enter a valid data";
    // }
    if (!formData.your_role) {
      errors.your_role = "your role is required";
    } else if (!regexAlfaNumric.test(formData.your_role?.trim())) {
      errors.your_role = "Enter a valid data";
    }
    // if (!formData.project_outcome) {
    //   errors.project_outcome = "project outcome is required";
    // } else if (!regexAlfaNumric.test(formData.project_outcome?.trim())) {
    //   errors.project_outcome = "Enter a valid data";
    // }
    // if (!formData.project_link) {
    //   errors.project_link = "project link is required";
    // } else if (!regexAlfaNumric.test(formData.project_link?.trim())) {
    //   errors.project_link = "Enter a valid data";
    // }
    if (!formData.client_name) {
      errors.client_name = "client name is required";
    } else if (!regexAlfaNumric.test(formData.client_name?.trim())) {
      errors.client_name = "Enter a valid data";
    }

    // if (formData?.media === undefined) {
    //   errors.media = "media is required";
    // }

    if (!formData.start_date) {
      errors.start_date = "Start Date is required";
    }

    if (!formData.end_date) {
      errors.end_date = "End Date is required";
    } else if (new Date(formData.end_date) < new Date(formData.start_date)) {
      errors.end_date = "End Date cannot be a past date of Start Date";
    } else if (new Date(formData.end_date) > new Date()) {
      errors.end_date = "End Date cannot be a future date";
    }

    return errors;
  };

  const handleFreeDelete = async (id) => {
    const data = {
      jobseeker_id: jobSeekerData?.id,
      step: 9,
    };
    const res = await editStatusData(
      `/jobseeker/signup/experiance-freelancer/${id}`,
      data
    );
    await getAllCards();
  };

  const handleChange = (e) => {
    if (e.target.name === "client_name") {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
    if (e.target.name === "project_title") {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
    if (e.target.name === "description") {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
    if (e.target.name === "your_role") {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
    if (e.target.name === "project_outcome") {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
    if (e.target.name === "project_link") {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const getAllData = async () => {
    {
      const res = await getData("/without-login/master/all-achievemente");
      setAchievemente(await Select2Data(res.data, "achievement_id"));
    }
    {
      const res = await getData("/without-login/master/all-skills");
      setSkills(await Select2Skills(res.data, "skills"));
    }
    {
      const res = await getData("/without-login/master/all-certificate-for");
      setCertificateFor(await Select2Data(res.data, "certificate_for"));
    }
  };

  const [status, setStatus] = useState("none");
  const getAllCards = async () => {
    if (jobSeekerData?.id) {
      const res = await getData(`/jobseeker/signup/experiance-freelancer`);
      await setCards(res?.data);
      if (res?.data.length !== 0) {
        setStatus("none");
      } else {
        setStatus("none");
      }
    }
  };

  const [lgShow, setLgShow] = useState(false);
  const [ftype, setFType] = useState(true);

  const handleClose = () => setLgShow(false);
  const handleShow = async (type = "ADD") => {
    if (type === "ADD") {
      await setFType(true);
      await setFormData({
        step: 9,
        media: undefined,
        client_name: "",
        project_title: "",
        description: "",
        start_date: "",
        end_date: "",
        your_role: "",
        project_outcome: "",
        project_link: "",
      });
      // await setSelectedArea_of_studay([]);
      // await setErrors();
    } else {
      await setFType(false);
    }
    await setLgShow(true);
    await setErrors({});
  };

  const getEditData = async (id) => {
    setFType(false);
    const res = await getData(`/jobseeker/signup/experiance-freelancer/${id}`);

    const data = res?.data;

    setFormData({
      ...formData,
      id: data?.id,
      step: 9,
      media: "edit",
      client_name: data?.client_name,
      project_title: data?.project_title,
      description: data?.description,
      start_date: data?.start_date,
      end_date: data?.end_date,
      your_role: data?.your_role,
      project_outcome: data?.project_outcome,
      project_link: data?.project_link,
    });
    handleShow("EDIT");
  };

  useEffect(() => {
    getAllData();
    getAllCards();
    fetchSeekerData();
    setFormData({ ...formData, id: jobSeekerData?.id });
  }, [jobSeekerData?.id]);

  useEffect(() => {
    setFormData({
      ...formData,
      ["skills"]: selectedskills?.map((item) => item.value),
    });
  }, [selectedskills]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        const finalData = new FormData();
        if (formData.media != "edit") {
          finalData.append(`media`, formData.media);
        }
        finalData.append(`jobseeker_id`, formData.jobseeker_id);
        finalData.append(`step`, formData.step);
        finalData.append(`status`, "none");
        finalData.append(`client_name`, formData.client_name);
        finalData.append(`project_title`, formData.project_title);
        finalData.append(`description`, formData.description);
        finalData.append(`start_date`, formData.start_date);
        finalData.append(`end_date`, formData.end_date);
        finalData.append(`your_role`, formData.your_role);
        finalData.append(`project_outcome`, formData.project_outcome);
        finalData.append(`project_link`, formData.project_link);
        if (formData?.id) {
          finalData.append(`id`, formData.id);
          await postData(
            `/jobseeker/signup/experiance-freelancer/${formData?.id}`,
            finalData
          );
        } else {
          await postData("/jobseeker/signup/experiance-freelancer", finalData);
        }

        await setFormData({
          id: jobSeekerData?.id,
          step: 9,
          media: undefined,
          client_name: "",
          project_title: "",
          description: "",
          start_date: "",
          end_date: "",
          your_role: "",
          project_outcome: "",
          project_link: "",
        });

        await setErrors();
        await getAllCards();
        await handleClose();
      } catch (error) {
        // console.log(error);
      }
    }
  };

  const handleDelete = async (id) => {
    var data = "";
    if (cards?.length === 1) {
      var data = {
        step: 9,
        status: "none",
      };
    } else {
      var data = {
        step: 9,
        status: "none",
      };
    }
    const res = await editStatusData(
      `/jobseeker/signup/experiance-freelancer/${id}`,
      data
    );

    await getAllCards();
  };

  const errorStyle = {
    color: "red",
    marginLeft: "5px",
    // fontSize: "11px",
  };
  return (
    <>
      <section className="portfolio-experience">
        <div className="container">
          <div className="row">
            <div className="heading-holder mb-3 text-center">
              <h4>My Portfolio</h4>
            </div>
          </div>
          <div className="black-header">
            <div className="row">
              <div className="col-md-4 col-4">
                <Link to="/dashboard-job">
                  <div className="text-holder pt-2 ms-3">
                    <p>Back</p>
                  </div>
                </Link>
              </div>
              <div className="col-md-4 col-4">
                <div className="text-holder text-center pt-2">
                  <h5>Freelancing Projects</h5>
                </div>
              </div>
              <div className="col-md-4 col-4">
                <Link onClick={() => handleShow()}>
                  <div className="text-holder text-end pt-2 me-3">
                    <p>Add</p>
                  </div>
                </Link>
              </div>
            </div>
          </div>

          <div className="form-bg">
            <Form>
              <Row className="">
                {cards?.map((value, index) => (
                  <Col
                    className="col-md-5 col-lg-3 col-xl-3 col-xxl-3 col-12 mt-2"
                    key={index}
                  >
                    <div className="msc-box">
                      <div className="tabs-btn p-2">
                        <div className="d-flex justify-content-end">
                          <FontAwesomeIcon
                            icon="fa-solid fa-pen-to-square"
                            className="me-3"
                            style={{ cursor: "pointer" }}
                            onClick={() => getEditData(value?.id)}
                          />
                          <p>
                            <FontAwesomeIcon
                              icon={faXmark}
                              className="close-icon"
                              style={{ cursor: "pointer" }}
                              onClick={() => handleDelete(value?.id)}
                            />
                          </p>
                        </div>
                        <div className="info">
                          <p>Title : {value?.project_title}</p>
                          <p>Client Name: {value?.client_name}</p>
                          <p>Your Role: {value?.your_role}</p>
                          <p>
                            Start Date:{" "}
                            {value?.start_date
                              ? new Date(value.start_date).toLocaleDateString(
                                  "en-US",
                                  {
                                    year: "numeric",
                                    month: "2-digit",
                                    day: "2-digit",
                                  }
                                )
                              : "N/A"}
                          </p>
                          <p>
                            End Date:{" "}
                            {value?.end_date
                              ? new Date(value.end_date).toLocaleDateString(
                                  "en-US",
                                  {
                                    year: "numeric",
                                    month: "2-digit",
                                    day: "2-digit",
                                  }
                                )
                              : "N/A"}
                          </p>

                          {/* <p>Achievement :{value?.achievement?.name}</p> */}
                        </div>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
              {/* <Row>
                <div className="col-md-6 col-sm-12 mb-3">
                  <Form.Label>
                    Project Title <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Project Title"
                    name="project_title"
                    value={formData?.project_title}
                    onChange={handleChange}
                  />
                  {errors?.project_title && (
                    <span style={errorStyle}>{errors?.project_title}</span>
                  )}
                </div>

                <div className="col-md-6 col-sm-12 mb-3">
                  <Form.Label>
                    Client Name <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Client Name"
                    name="client_name"
                    value={formData?.client_name}
                    onChange={handleChange}
                  />
                  {errors?.client_name && (
                    <span style={errorStyle}>{errors?.client_name}</span>
                  )}
                </div>

                <div className="col-md-12 col-sm-12 mb-3">
                  <Row>
                    <Col>
                      <Form.Label>
                        {" "}
                        Time Period
                        <sup className="bracket-font px-md-1 px-5">(from)</sup>
                      </Form.Label>
                      <DatePicker
                        selected={formData?.start_date}
                        onChange={async (date) => {
                          await setFormData({
                            ...formData,
                            start_date: date,
                          });
                        }}
                        placeholderText="Start Date"
                        name="start_date"
                        dateFormat="MM/dd/yyyy" // Adjust the date format as needed
                      />
                      {errors?.start_date && (
                        <span style={errorStyle}>{errors?.start_date}</span>
                      )}
                    </Col>
                    <Col>
                      <Form.Label>
                        {" "}
                        Time Period
                        <sup className="bracket-font px-md-1 px-5">(to)</sup>
                      </Form.Label>
                      <DatePicker
                        selected={formData?.end_date}
                        onChange={async (date) => {
                          await setFormData({
                            ...formData,
                            end_date: date,
                          });
                        }}
                        placeholderText="End Date"
                        name="end_date"
                        dateFormat="MM/dd/yyyy" // Adjust the date format as needed
                      />
                      {errors?.end_date && (
                        <span style={errorStyle}>{errors?.end_date}</span>
                      )}
                    </Col>
                  </Row>
                </div>

                <div className="col-md-12 col-sm-12 mb-3">
                  <Row>
                    <Form.Label>
                      Your Role <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Your Role"
                      name="your_role"
                      value={formData?.your_role}
                      onChange={handleChange}
                    />
                    {errors?.your_role && (
                      <span style={errorStyle}>{errors?.your_role}</span>
                    )}
                  </Row>
                </div>

                <div className="col-md-6 col-sm-12 mb-3">
                  <Form.Label>
                    Description <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Description"
                    name="description"
                    value={formData?.description}
                    onChange={handleChange}
                  />
                  {errors?.description && (
                    <span style={errorStyle}>{errors?.description}</span>
                  )}
                </div>

                <div className="col-md-6 col-sm-12 mb-3">
                  <Form.Label>
                    Project Out Come <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Project Out Come"
                    name="project_outcome"
                    value={formData?.project_outcome}
                    onChange={handleChange}
                  />
                  {errors?.project_outcome && (
                    <span style={errorStyle}>{errors?.project_outcome}</span>
                  )}
                </div>

                <div className="col-md-6 col-sm-12 mb-3">
                  <Form.Label>
                    Project Link <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Project Link"
                    name="project_link"
                    value={formData?.project_link}
                    onChange={handleChange}
                  />
                  {errors?.project_link && (
                    <span style={errorStyle}>{errors?.project_link}</span>
                  )}
                </div>

                <div className="col-md-6 col-sm-12 mb-3">
                  <Form.Label>
                    Upload Media<span className="text-danger">*</span>{" "}
                  </Form.Label>

                  <Form.Control
                    type="file"
                    placeholder="Enter Media"
                    name="media"
                    onChange={async (e) => {
                      await setFormData({
                        ...formData,
                        media: e.target.files[0],
                      });
                    }}
                  />
                  {errors?.media && (
                    <span style={errorStyle}>{errors?.media}</span>
                  )}
                </div>
              </Row> */}
              {/* <Form> */}
              <Modal
                size="lg"
                show={lgShow}
                onHide={() => setLgShow(false)}
                className="experience-modal"
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title>
                    {ftype ? "Add Freelancing" : "Edit Freelancing"}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Row>
                    <div className="col-md-6 col-sm-12 mb-3">
                      <Form.Label>
                        Project Title <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Project Title"
                        name="project_title"
                        value={formData?.project_title}
                        onChange={handleChange}
                      />
                      {errors?.project_title && (
                        <span style={errorStyle}>{errors?.project_title}</span>
                      )}
                    </div>

                    <div className="col-md-6 col-sm-12 mb-3">
                      <Form.Label>
                        Client Name <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Client Name"
                        name="client_name"
                        value={formData?.client_name}
                        onChange={handleChange}
                        onKeyDown={(event) => {
                          const allowedCharacters = /[A-Za-z ]/;
                          if (!allowedCharacters.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                      {errors?.client_name && (
                        <span style={errorStyle}>{errors?.client_name}</span>
                      )}
                    </div>

                    <div className="col-md-12 col-sm-12 mb-3">
                      <Row>
                        <Col>
                          <Form.Label>
                            {" "}
                            Time Period
                            <sup className="bracket-font px-md-1 px-5">
                              (from)
                            </sup>
                          </Form.Label>
                          <Form.Control
                            type="date"
                            placeholder="Start Date"
                            name="start_date"
                            value={formData?.start_date}
                            onChange={handleChange}
                          />
                          {errors?.start_date && (
                            <span style={errorStyle}>{errors?.start_date}</span>
                          )}
                        </Col>
                        <Col>
                          <Form.Label>
                            {" "}
                            Time Period
                            <sup className="bracket-font px-md-1 px-5">
                              (to)
                            </sup>
                          </Form.Label>
                          <Form.Control
                            type="date"
                            placeholder="End Date"
                            name="end_date"
                            value={formData?.end_date}
                            onChange={handleChange}
                          />
                          {errors?.end_date && (
                            <span style={errorStyle}>{errors?.end_date}</span>
                          )}
                        </Col>
                      </Row>
                    </div>

                    <div className="col-md-12 col-sm-12 mb-3">
                      <Row className="ms-0 me-0">
                        <Form.Label>
                          Your Role <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Your Role"
                          name="your_role"
                          value={formData?.your_role}
                          onChange={handleChange}
                        />
                        {errors?.your_role && (
                          <span style={errorStyle}>{errors?.your_role}</span>
                        )}
                      </Row>
                    </div>

                    <div className="col-md-6 col-sm-12 mb-3">
                      <Form.Label>
                        Description
                        {/* <span className="text-danger">*</span> */}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Description"
                        name="description"
                        value={formData?.description}
                        onChange={handleChange}
                      />
                      {errors?.description && (
                        <span style={errorStyle}>{errors?.description}</span>
                      )}
                    </div>

                    <div className="col-md-6 col-sm-12 mb-3">
                      <Form.Label>
                        Project Out Come
                        {/* <span className="text-danger">*</span> */}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Project Out Come"
                        name="project_outcome"
                        value={formData?.project_outcome}
                        onChange={handleChange}
                      />
                      {errors?.project_outcome && (
                        <span style={errorStyle}>
                          {errors?.project_outcome}
                        </span>
                      )}
                    </div>

                    <div className="col-md-6 col-sm-12 mb-3">
                      <Form.Label>
                        Project Link
                        {/* <span className="text-danger">*</span> */}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Project Link"
                        name="project_link"
                        value={formData?.project_link}
                        onChange={handleChange}
                      />
                      {errors?.project_link && (
                        <span style={errorStyle}>{errors?.project_link}</span>
                      )}
                    </div>

                    <div className="col-md-6 col-sm-12 mb-3">
                      <Form.Label>
                        Upload Media
                        {/* <span className="text-danger">*</span>{" "} */}
                      </Form.Label>

                      <Form.Control
                        type="file"
                        placeholder="Enter Media"
                        name="media"
                        accept="application/pdf"
                        onChange={async (e) => {
                          await setFormData({
                            ...formData,
                            media: e.target.files[0],
                          });
                        }}
                      />
                      {errors?.media && (
                        <span style={errorStyle}>{errors?.media}</span>
                      )}
                    </div>
                  </Row>
                </Modal.Body>
                <Modal.Footer className="mx-auto">
                  <Button
                    variant="secondary"
                    className="blue-btn-class "
                    onClick={handleClose}
                  >
                    Close
                  </Button>
                  <Button
                    className="education-btn blue-btn-class "
                    onClick={handleSubmit}
                  >
                    {ftype ? "Add Freelancing" : "Edit Freelancing"}
                  </Button>
                </Modal.Footer>
              </Modal>

              {/* </Form> */}

              {/* <div className="text-end">
                <Button className="education-btn" onClick={handleSubmit}>
                  Add Freelancing
                </Button>
              </div> */}
            </Form>
            {/* <Form>
              <Form.Group
                as={Row}
                className="mb-4"
                controlId="formPlaintextTitle"
              >
                <Form.Label column lg="2">
                  Project Title:
                </Form.Label>
                <Col lg="10">
                  <Form.Control type="text" placeholder="E.R.P." />
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                className="mb-4"
                controlId="formPlaintextRole"
              >
                <Form.Label column lg="2">
                  Project Nature:
                </Form.Label>
                <Col lg="4">
                  <Form.Select aria-label="Default select example">
                    <option>Select Project Nature</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Select>
                </Col>
                <Form.Label column lg="2">
                  URL :
                </Form.Label>
                <Col lg="4">
                  <Form.Control type="text" placeholder="" />
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                className="mb-4"
                controlId="formPlaintextSkills"
              >
                <Form.Label column lg="2">
                  Skills Used:
                </Form.Label>
                <Col lg="10">
                  <Form.Control
                    type="text"
                    placeholder="JAVA, Python, HTML, React"
                  />
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                className="mb-4"
                controlId="formPlaintextCompany"
              >
                <Form.Label column lg="2">
                  Outcomes Application Sector:
                </Form.Label>
                <Col lg="4">
                  <Form.Control
                    type="text"
                    placeholder="Profcyma Solutions Pvt. Ltd."
                  />
                </Col>
                <Form.Label column lg="2">
                  Scale:
                </Form.Label>
                <Col lg="4">
                  <Form.Control type="text" placeholder="7.5" />
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                className="mb-4"
                controlId="formPlaintextScale"
              >
                <Form.Label column lg="2">
                  Contribution (On Scale 1 to 10):
                  <br />
                  Team Work:
                </Form.Label>
                <Col lg="4">
                  <Form.Control type="text" placeholder="8" />
                </Col>
                <Form.Label column lg="2">
                  Individual Work:
                </Form.Label>
                <Col lg="4">
                  <Form.Control type="text" placeholder="8" />
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                className="mb-4"
                controlId="formPlaintextReporting"
              >
                <Form.Label column lg="2">
                  Approved Athourity:
                </Form.Label>
                <Col lg="4">
                  <Form.Control type="text" placeholder="" />
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                className="mb-4"
                controlId="formPlaintextPeriod"
              >
                <Form.Label column lg="2">
                  Time Period:
                </Form.Label>
                <Col lg="4">
                  <Form.Select aria-label="Default select example">
                    <option>2022</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Select>
                </Col>
                <Form.Label column lg="2"></Form.Label>
                <Col lg="4">
                  <Form.Select aria-label="Default select example">
                    <option>2023</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Select>
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                className="mb-4"
                controlId="formPlaintextJob"
              >
                <Form.Label column lg="2">
                  Reporting Person:
                </Form.Label>
                <Col lg="4">
                  <Form.Control type="text" placeholder="Prasad Kulkarni" />
                </Col>
                <Form.Label column lg="2">
                  Upload Evidence:
                </Form.Label>
                <Col lg="4">
                  <div className="show-data">
                    <span className="effect p-1">
                      Experience Letter + 2 Files Attached
                    </span>
                    <button type="file" className="btn upload-btn">
                      Upload Your File here
                    </button>
                  </div>
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                className="mb-4"
                controlId="formPlaintextReporting"
              >
                <Form.Label column lg="2">
                  Mentor/Guide Name:
                </Form.Label>
                <Col lg="4">
                  <Form.Control type="text" placeholder="Prasad Kulkarni" />
               </Col>
                <Form.Label column lg="2">
                  Uploads:
                </Form.Label>
                <Col lg="4">
                  <button type="file" className="btn btn-media">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "assets/Images/my-portfolio/material-photo.png"
                      }
                      className="logo-img me-2"
                    />
                    /
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "assets/Images/my-portfolio/awesome-video.png"
                      }
                      className="logo-img mx-2"
                    />{" "}
                    Media
                  </button>
                </Col>
              </Form.Group>
              <div className="button-holder text-center my-3">
                <button className="btn btn-add">Add More Project</button>
              </div>
              <div className="button-holder text-center mb-md-0  mb-4">
                <Link to="/my-portfolio">
                  {" "}
                  <button className="btn btn-save">Save</button>
                </Link>
              </div>
            </Form> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default PortfolioFreelancingForm;
