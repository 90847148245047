import React, { useContext, useEffect, useState } from "react";
import "./FirstTab1.css";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import JobCategory from "../../../../CommonPage/Home/JobCategory/JobCategory";
import ExploreOpportunity from "../../ExploreOpportunity/ExploreOpportunity";

import JobAlert from "../../JobAlert/JobAlert";
import { Context } from "../../../../../utils/context";

const FirstTab1 = () => {
  const { getData, imageUrl, postData, 
    BarAnimations,
    NoDataAnimations, } = useContext(Context);

  const [suggestion, setSuggestion] = useState([]);
  const [company, setCompany] = useState([]);
  const [jobAlerts, setJobAlerts] = useState([]);

  const getAllData = async () => {
    const res = await getData("/jobseeker/dashboard/suggestions");
    setSuggestion(res.data);
    {
      const res = await getData("/jobseeker/dashboard/follow");
      setCompany(res.data);
    }
    {
      const res = await getData("/jobseeker/dashboard/job-alerts");
      setJobAlerts(res.data);
    }
  };

  const Follow = async (company_id) => {
    const res = await postData("/jobseeker/dashboard/follow", {
      company_id: company_id,
    });

    getAllData();
  };
  const unFollow = async (company_id) => {
    const res = await postData("/jobseeker/dashboard/unfollow", {
      company_id: company_id,
    });
    getAllData();
  };

  useEffect(() => {
    getAllData();
  }, []);

  

  return (
    <>
      <section className="FirstTabafterSignup">
        <div className="container">
          <div className="row mb-5 mt-5">
            <div className="col-lg-8 col-md-7">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <Tabs className="row ">
                    <div className="row mb-4">
                      <div className="col-xl-9 col-lg-8 col-md-8">
                        <div className="col-xxl-11 col-xl-11 col-lg-11 col-md-10">
                          <div className="Jobtabheading">
                            Companies You Followed
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-4 col-md-4">
                        <TabList className=" Job_grid-tab text-end">
                          <Tab>
                            <FontAwesomeIcon icon="fa-solid fa-list-ul" />
                          </Tab>
                          <Tab>
                            <FontAwesomeIcon icon="fa-solid fa-border-all" />
                          </Tab>
                        </TabList>
                      </div>
                    </div>

                    {/* List view */}
                    <TabPanel>
                      <div className="FirstTabafterSignup_card_main ">
                        {company?.map((val, index) => (
                          <div
                            className="card FirstTabafterSignup_card  mb-3"
                            key={index}
                          >
                            <div className="card-body">
                              <div className="row">
                                <div className="col-xxl-2 col-xl-3 col-lg-2 col-sm-3">
                                  <div className="logoCard text-center ">
                                    <img
                                      src={imageUrl + val?.company?.image}
                                      className="company_logo"
                                    />
                                  </div>
                                </div>
                                <div className="col-xxl-7 col-xl-6 col-lg-7 col-sm-6">
                                  <div className="FirstTabafterSignup_heading ">
                                    {val?.company?.name}
                                  </div>
                                  <div className="mt-4 ">
                                    <span className="full_txt time_txt">
                                      Full Time
                                    </span>
                                    <span className="Part_txt time_txt">
                                      Part Time
                                    </span>
                                    <span className="Freelance_txt time_txt">
                                      Freelance
                                    </span>
                                  </div>
                                </div>
                                <div className="col-xxl-3 col-xl-3 col-lg-3 col-sm-3">
                                  <div className="city_txt text-end">
                                    <FontAwesomeIcon icon="fa-solid fa-location-dot" />{" "}
                                    Bavdhan, Pune
                                  </div>
                                  <div className="mt-4">
                                    <div className="vacancy_btn_sec ">
                                      <button
                                        type="btn"
                                        className="vacancy_btn"
                                      >
                                        {" "}
                                        {val?.company?.pool_subscribers
                                          ?.map((data, index) =>
                                            Number(data?.no_of_jobs)
                                          )
                                          ?.reduce(
                                            (acc, currentValue) =>
                                              acc + (currentValue || 0),
                                            0
                                          )}{" "}
                                        Vacancies
                                      </button>
                                    </div>
                                  </div>
                                  <div
                                    className="unfollow_txt text-end"
                                    onClick={() => {
                                      unFollow(val?.company_id);
                                    }}
                                  >
                                    Unfollow Company
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                        {company?.length === 0 &&(
                          <NoDataAnimations />
                        )}

                      </div>
                    </TabPanel>

                    <TabPanel>
                      {/* Gridview */}
                      <div className="FirstTabafterSignup_card_main ">
                        <div className="row">
                          {company?.map((val, index) => (
                            <div
                              key={index}
                              className="col-xxl-4 col-xl-4 col-lg-6 col-md-6"
                            >
                              <div className="card FirstTabafterSignup_card mb-3">
                                <div className="card-body pt-0">
                                  <div className="row mt-1">
                                    <div className="col-xxl-4 col-xl-4 col-lg-3 col-md-4 p-0">
                                      <div className="dash_company_logo_sec">
                                        <img
                                          src={imageUrl + val?.company?.image}
                                          className="company_logo-grid"
                                          alt={`${val?.company?.name} Logo`}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-xxl-8 col-xl-8 col-lg-9 col-md-8 p-0">
                                      <div className="FirstTabafterSignup_heading-grid ">
                                        {val?.company?.name}
                                      </div>
                                    </div>
                                    {/* <div className="col-md-12">
                                      <div className="mt-3 text-md-center">
                                        <span className="full_txt time_txt">
                                          Full Time
                                        </span>
                                        <span className="Part_txt time_txt">
                                          Part Time
                                        </span>
                                        <span className="Freelance_txt time_txt">
                                          Freelance
                                        </span>
                                      </div>
                                    </div> */}
                                    <div className="col-lg-12 col-md-12">
                                      <div className="d-flex justify-content-between location_vacation_txt">
                                        {/* <div className="city_txt-grid">
                                          <FontAwesomeIcon icon="fa-solid fa-location-dot" />
                                          {company.location}
                                        </div> */}
                                        <div className="">
                                          <div className="vacancy_btn_sec">
                                            <button
                                              type="btn"
                                              className="vacancy_btn-grid"
                                            >
                                              No of Vacancies{" "}
                                              {val?.company?.pool_subscribers
                                                ?.map((data, index) =>
                                                  Number(data?.no_of_jobs)
                                                )
                                                ?.reduce(
                                                  (acc, currentValue) =>
                                                    acc + (currentValue || 0),
                                                  0
                                                )}
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="text-center mt-3"
                                        onClick={() => {
                                          unFollow(val?.company_id);
                                        }}
                                      >
                                        <a className="unfollow_txt-grid mt-5">
                                          Unfollow Company
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                          {company?.length === 0 &&(
                          <NoDataAnimations />
                        )}
                        </div>
                      </div>
                    </TabPanel>
                  </Tabs>
                </div>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-5  mt-lg-0">
              <div className="Suggestions_heading">Suggestions for You</div>
              <div className="card  suggestion_card">
                <div className="card-body suggestion_card_body">
                  {suggestion?.map((data, index) => (
                    <div
                      className="row suggestion_inner_content mb-4"
                      key={index}
                    >
                      <div className="col-xl-9 col-lg-8 col-8">
                        <div className="Company_img_sec">
                          <img
                            src={imageUrl + data?.image}
                            className="company_logo"
                          />
                        </div>
                        <p className="company_info mt-3">{data?.name}</p>
                        {/* <p className="company_info"> Bavdhan, Pune</p> */}
                        <div className="city_txt ">
                                    <FontAwesomeIcon icon="fa-solid fa-location-dot" />{" "}
                                    Bavdhan, Pune
                                  </div>
                      </div>
                      <div className="col-xl-3 col-lg-4 col-4">
                        <div className="text-end">
                          <button
                            className="follow-btn-grid"
                            onClick={() => Follow(data?.id)}
                          >
                            Follow
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                  {suggestion?.length === 0 && (
                    <BarAnimations />
                  )}

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* MObile View */}

      <section className="mobile_FirstTabafterSignup m-2">
        <Tabs>
          <TabList className="tablist_dashboard_tab_mobile">
            <Tab className="mobile_first_tab_companies">
              Companies You Followed
            </Tab>

            <Tab className="mobile_first_tab_companies">
              Suggestions for You
            </Tab>
          </TabList>

          <TabPanel>
            <div className="FirstTabafterSignup_card_main ">
              {company?.map((val, index) => (
                <div
                  className="card FirstTabafterSignup_card  mb-3"
                  key={index}
                >
                  <div className="card-body pt-0">
                    <div className="row">
                      <div className="col-sm-4 col-3 ps-0">
                        <div className="logoCard text-center ">
                          <img
                            src={imageUrl + val?.company?.image}
                            className="company_logo"
                          />
                        </div>
                      </div>
                      <div className="col-sm-8 col-9">
                        <div className="FirstTabafterSignup_heading">
                          {val?.company?.name}
                        </div>
                      </div>
                    </div>
                    <div className="row full_part_lance_txt mt-3">
                      <div className="col-sm-3 col-3 pe-0 ps-0">
                        <span className="full_txt time_txt">Full Time</span>
                      </div>
                      <div className="col-sm-3 col-3 pe-0 ps-0">
                        <span className="Part_txt time_txt">Part Time</span>
                      </div>
                      <div className="col-sm-3 col-3 pe-0 ps-0">
                        <span className="Freelance_txt full_txt time_txt">
                          Freelance
                        </span>
                      </div>
                      <div className="col-sm-3 col-3 p-0 ps-0">
                        <div className="vacancy_btn_sec">
                          <button type="btn" className="vacancy_btn">
                            {val?.company?.pool_subscribers
                              ?.map((data, index) => Number(data?.no_of_jobs))
                              ?.reduce(
                                (acc, currentValue) =>
                                  acc + (currentValue || 0),
                                0
                              )}{" "}
                            Vacancies
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="row mt-3">
                      <div className="col-6">
                        <div className="vacancy_btn_sec">
                          <div className="city_txt">
                            <FontAwesomeIcon icon="fa-solid fa-location-dot" />{" "}
                            Bavdhan, Pune
                          </div>
                        </div>
                      </div>
                      <div
                        className="col-6"
                        onClick={() => {
                          unFollow(val?.company_id);
                        }}
                      >
                        <div className="unfollow_txt ">Unfollow Company</div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              {company?.length === 0 &&(
                          <NoDataAnimations />
                        )}
              

              {/* <div className="text-center">
                <button className="btn btn-primary mobile_showMore_btn">
                  Show More
                </button>
              </div> */}
            </div>
          </TabPanel>

          <TabPanel>
            <div className="card  suggestion_card">
              <div className="card-body suggestion_card_body">
                {suggestion?.map((data, index) => (
                  <div
                    className="row suggestion_inner_content mb-4"
                    key={index}
                  >
                    <div className="col-xl-9 col-lg-8 col-sm-6 col-6">
                      <div className="Company_img_sec">
                        <img
                          src={imageUrl + data?.image}
                          className="mobile_company_logo"
                        />
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-sm-6 col-6">
                      <div className="text-end">
                        <button
                          className="follow-btn-grid"
                          onClick={() => Follow(data?.id)}
                        >
                          Follow
                        </button>
                      </div>
                    </div>
                    <p className="company_info">{data?.name}</p>
                    {/* <p className="company_loc">
                      <FontAwesomeIcon icon="fa-solid fa-location-dot" />{" "}
                      Bavdhan, Pune
                    </p> */}
                  </div>
                ))}
                {suggestion?.length === 0 && (
                      <BarAnimations />
                )}

               

                {/* <div className="showwmorr-alignnn">
                  <button className="btn showbtn">
                    Show More{" "}
                    <FontAwesomeIcon icon="fa-solid fa-chevron-down" />
                  </button>
                </div> */}
              </div>
            </div>
          </TabPanel>
        </Tabs>

        <JobCategory />
        <JobAlert />
        <ExploreOpportunity />
      </section>
    </>
  );
};

export default FirstTab1;
