import React from 'react'
import './PortfolioJobExperience.css';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import { Link } from 'react-router-dom';
const PortfolioJobExperience = () => {
    return (
        <>
            <section className='portfolio-experience'>
                <div className='container'>
                    <div className='row'>
                        <div className='heading-holder mb-3 text-center'>
                            <h4>My Portfolio</h4>
                        </div>
                    </div>

                    <div className='black-header'>
                        <div className='row'>
                            <div className='col-md-4 col-4'>
                                <Link to='/dashboard-job'>
                                    <div className='text-holder pt-2 ms-3'>
                                        <p>Back</p>
                                    </div></Link>
                            </div>
                            <div className='col-md-4 col-4'>
                                <div className='text-holder text-center pt-2'>
                                    <h5>Job Experience</h5>
                                </div>
                            </div>
                            <div className='col-md-4 col-4'>
                                <Link to='/my-portfolio'>
                                    <div className='text-holder text-end pt-2 me-3'>
                                        <p>Save & Close</p>
                                    </div></Link>
                            </div>
                        </div>
                    </div>
                    <div className='form-bg'>
                        <Form>
                            <Form.Group as={Row} className="mb-4" controlId="formPlaintextRole">
                                <Form.Label column lg="2">
                                    My Role:
                                </Form.Label>
                                <Col lg="4">
                                    <Form.Control type="text" placeholder="Frontend Intern" />
                                </Col>
                                <Form.Label column lg="2">
                                    My Position:
                                </Form.Label>
                                <Col lg="4">
                                    <Form.Control type="text" placeholder="Junior Frontend Intern" />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-4" controlId="formPlaintextSkills">
                                <Form.Label column lg="2">
                                    Skills Used:
                                </Form.Label>
                                <Col lg="10">
                                    <Form.Control type="text" placeholder="JAVA, Python, HTML, React" />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-4" controlId="formPlaintextCompany">
                                <Form.Label column lg="2">
                                    Company Name:
                                </Form.Label>
                                <Col lg="10">
                                    <Form.Control type="text" placeholder="Profcyma Solutions Pvt. Ltd." />
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-4" controlId="formPlaintextScale">
                                <Form.Label column lg="2">
                                    Scale:
                                </Form.Label>
                                <Col lg="4">
                                    <Form.Control type="text" placeholder="7.5" />
                                </Col>
                                <Form.Label column lg="2">
                                    Company Sector:
                                </Form.Label>
                                <Col lg="4">
                                    <Form.Select aria-label="Default select example">
                                        <option>Open this select menu</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </Form.Select>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-4" controlId="formPlaintextReporting">
                                <Form.Label column lg="2">
                                    Reporting Person:
                                </Form.Label>
                                <Col lg="10">
                                    <Form.Control type="text" placeholder="Prasad Kulkarni" />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-4" controlId="formPlaintextPeriod">
                                <Form.Label column lg="2">
                                    Time Period:
                                </Form.Label>
                                <Col lg="4">
                                    <Form.Select aria-label="Default select example">
                                        <option>Open this select menu</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </Form.Select>
                                </Col>
                                <Form.Label column lg="2">

                                </Form.Label>
                                <Col lg="4">
                                    <Form.Select aria-label="Default select example">
                                        <option>Open this select menu</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </Form.Select>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-4" controlId="formPlaintextJob">
                                <Form.Label column lg="2">
                                    Job Nature:
                                </Form.Label>
                                <Col lg="4">
                                    <Form.Control type="text" placeholder="Developer" />
                                </Col>
                                <Form.Label column lg="2">
                                    Upload Evidence:
                                </Form.Label>
                                <Col lg="4">
                                    <div className='show-data'>
                                        <span className='effect p-1'>Experience Letter + 2 Files Attached</span>
                                        {/* <Form.Control type="file" placeholder="Developer" className='upload-btn' /> */}
                                        <button type='file' className='btn upload-btn'>Upload Your File here</button>
                                    </div>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-4" controlId="formPlaintextReporting">
                                <Form.Label column lg="2">
                                    Reporting Person:
                                </Form.Label>
                                <Col lg="4">
                                    <button type='file' className='btn btn-media' ><img src={process.env.PUBLIC_URL + "assets/Images/my-portfolio/material-photo.png"} className='logo-img me-2' />/<img src={process.env.PUBLIC_URL + "assets/Images/my-portfolio/awesome-video.png"} className='logo-img mx-2' /> Media</button>
                                </Col>
                            </Form.Group>
                            <div className='button-holder text-center my-3'>
                                <button className='btn btn-add'>Add More Job</button>
                            </div>
                            <div className='button-holder text-center mb-md-0 mb-4'>
                                <Link to='/my-portfolio'>  <button className='btn btn-save'>Save</button></Link>
                            </div>
                        </Form>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PortfolioJobExperience