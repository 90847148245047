import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import gender from "../UpdatedSingUp/Image/gender.png";

import { useContext } from "react";
import { Context } from "../../../../utils/context";
function Step3({ handleNext }) {
  const { postData, jobSeekerData, getJobSeekerData } = useContext(Context);

  const [gender, setGender] = useState(true);
  const [status, setStatus] = useState("none");
  const [execute, setExecute] = useState(0);

  const handleGender = () => {
    setGender(!gender);
  };

  const handleValidation = async () => {
    // console.log(jobSeekerData, "jobSeekerData");
    var finGender = "";
    if (gender) {
      finGender = "Male";
    } else {
      finGender = "Female";
    }

    const data = {
      gender: finGender,
      step: 3,
      status: status,
    };
    const res = await postData(`/jobseeker/signup/gender`, data);
    if (res?.success) {
      handleNext();
    } else {
      // console.log(res, "fails");
    }
  };

  const seekerData = async () => {
    const res = await getJobSeekerData();
    if (res?.users_detail?.gender != null) {
      if (res?.users_detail?.gender === "Male") {
        setGender(true);
      } else {
        setGender(false);
      }
      await setStatus("none");
    } else {
      await setStatus(true);
    }
  };

  useEffect(() => {
    seekerData();
  }, []);
  return (
    <>
      <fieldset>
        <div className="form-card StepForm ">
          <Row>
            <div className="d-flex  steps-heading ms-3">
              <div className="border-left" />
              <h5>Step 2</h5>
            </div>
            <Col className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
              <p className="select-gender-text mt-5 ms-3">
                Select Your Gender <span>*</span>
              </p>
            </Col>
            <Col className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12 mt-3">
              <div className="gender-img text-center">
                {gender ? (
                  <>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/Images/icons/Path-23.png"
                      }
                      className="inner-img mb-2"
                      alt="img"
                    />
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/Images/icons/Path-22.png"
                      }
                      className="inner-img mb-2"
                      alt="img"
                      onClick={handleGender}
                    />
                  </>
                ) : (
                  <>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/Images/icons/Path 23.png"
                      }
                      className="inner-img mb-2"
                      alt="img"
                      onClick={handleGender}
                    />
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/Images/icons/Path 22.png"
                      }
                      className="inner-img mb-2"
                      alt="img"
                    />
                  </>
                )}
              </div>
            </Col>
          </Row>
          <div className="mobile-view-button">
            <button
              type="button"
              name="next"
              className="next action-button contin-btn"
              onClick={handleValidation}
            >
              Continue
            </button>
          </div>
        </div>
      </fieldset>

      <div className="row mt-5 pt-4 footer-sec">
        <div className="col-md-10 col-12 desktop-view-button">
          {/* <div className="check-row text-start">
            <span>
              <Form.Check inline name="group1" className="me-2" />
            </span>
            <span className=" by-text ">By signing up, I agree to the</span>
            <span className="px-1 ">
              <Link to="/terms" className="term-btn">
                Term and Conditions
              </Link>
            </span>
            <br />
          </div>
          <div className="login-row text-start">
            <span className="">Already an User?</span>
            <span className="px-1 ">
              <Link to="/other-page" className="login-btn">
                log In
              </Link>
            </span>
            &nbsp; &nbsp; &nbsp;
            <span className="">Not a Jobseeker?</span>
            <span className="px-1 ">
              <Link to="/signup-recruiter" className="signup-btn">
                Signup as Recruiter
              </Link>
            </span>
          </div> */}
        </div>
        {/* <div className="col-md-8 col-12 mb-5  mobile-view-button">
          <div className="check-row text-start">
            <span>
              <Form.Check inline name="group1" className="me-2" />
            </span>
            <span className=" by-text ">By signing up, I agree to the</span>
            <span className="px-1 ">
              <Link to="/terms" className="term-btn">
                Term and Conditions
              </Link>
            </span>
            <br />
          </div>
          <div className="login-row text-start">
            <span className="">Already an User?</span>
            <span className="px-1 ">
              <Link to="/other-page" className="login-btn">
                log In
              </Link>
            </span>
            <br />
            <span className="">Not a Jobseeker?</span>
            <span className="px-1 ">
              <Link to="/signup-recruiter" className="signup-btn">
                Signup as Recruiter
              </Link>
            </span>
          </div>
        </div> */}

        <div className="col-2">
          <div className="desktop-view-button">
            <button
              type="button"
              name="next"
              className="next action-button contin-btn"
              onClick={handleValidation}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Step3;
