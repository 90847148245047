import React from 'react';
import Modal from 'react-bootstrap/Modal';
import '../../DashBoardJob/ThirdJobTab/Animation/success.json';
import Success from '../../../RecruiterPage/Model/Animation/Success';



function ApplyJobModal(props) {
    
    return (  
      <>     
      <section className='PopupModal'>
          <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
  
            <Modal.Body>
              <Success />
              <p className='modal_txt'> Your Application has been Submitted Successfully!</p>
              <p className='text-center' style={{ color: "#FFFF" }}> Have a Great Time! </p>
            </Modal.Body>
  
          </Modal>
  
        </section>
      </>
      
    );
  }
  
  export default ApplyJobModal;
  