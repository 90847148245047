import React, { useContext, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../ReasonModal/ReasonModal.css";
import ThirdJobTabModal from "../ThirdJobTabModal.js";
import "../ThirdJobTabModal.css";
import { Link } from "react-router-dom";
import { Context } from "../../../../../utils/context.js";
const ReasonModal = (props) => {
  const { postData } = useContext(Context);
  const [isModalOpen, setModalOpen] = useState(false);

  const [modalShow, setModalShow] = useState(false);
  const [message, setMessage] = useState("");

  const openModal2 = async () => {
    const res = await postData(`/jobseeker/jobs/accept-reject`, {
      id: props.id,
      accept_or_reject: 0,
      rejecte_message: message,
    });
    if (res.success) {
      setModalShow(true);
      setTimeout(() => {
        props.onHide();
        setModalShow(false);
        props.getAllData();
      }, 3000);
    }
  };

  return (
    <>
      <div>
        <Modal
          {...props}
          size="lg"
          className="main-modal"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          animation={false}
        >
          <div className="reject-modal">
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <p className="reject-text"> Reason for Rejecting Interview</p>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="pt-0">
              <Form>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Control
                    as="textarea"
                    rows={5}
                    onChange={(e) => {
                      setMessage(e.target.value);
                    }}
                    placeholder="Type a Reason for Rejecting Interview"
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <div className="footer-modal">
              <Row>
                <Col className="text-end">
                  <button
                    type="button"
                    onClick={openModal2}
                    className="btn submit-btn1"
                  >
                    Submit
                  </button>
                  <ThirdJobTabModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                  />
                </Col>
                <Col>
                  <Link className="">
                    {" "}
                    <Button
                      type="button"
                      className="cancel-btn"
                      onClick={() => props.onHide()}
                    >
                      Cancel
                    </Button>
                  </Link>
                </Col>
              </Row>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default ReasonModal;
