import React, { Fragment, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Tabs, TabList, TabPanel } from "react-tabs";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import "react-tabs/style/react-tabs.css";
import Form from "react-bootstrap/Form";
import "swiper/swiper-bundle.css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Col, Row } from "react-bootstrap";
import FirstTab from "../Job-Experience/portfolio-Education-form/FirstTab";
import SecondTab from "../Job-Experience/portfolio-Education-form/SecondTab";
import ThirdTab from "../Job-Experience/portfolio-Education-form/ThirdTab";
import ForthTab from "../Job-Experience/portfolio-Education-form/ForthTab";
import SixthTab from "../Job-Experience/portfolio-Education-form/SixthTab";
import FifthTab from "../Job-Experience/portfolio-Education-form/FifthTab";
import PortfolioEducationForm from "../Job-Experience/portfolio-Education-form/PortfolioEducationForm";
import Lottie from "react-lottie";
import alertAnimation from "../chart-section/alert-lottie.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

import { useContext } from "react";
import { Context } from "../../../../utils/context";
const ExperienceSection = () => {
  const openPdfInNewTab = (data) => {
    window.open(imageUrl + data, "_blank");
  };
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: alertAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const [display, setDisplay] = useState(true);
  const [project, setProject] = useState(false);
  const [project1, setProject1] = useState(false);

  const vidRef = useRef();

  const [educationPopup, setEducationPopup] = useState(true);
  const [certificatePopup, setCertificatePopup] = useState(true);
  const [employmentPopup, setEmploymentPopup] = useState(true);
  const [freeLancerPopup, setFreeLancerPopup] = useState(true);
  const [projectPopup, setProjectPopup] = useState(true);
  const [chartPopup, setchartPopup] = useState(true);
  const [portfolio, setPortfolio] = useState();

  const {
    postData,
    jobSeekerData,
    setJobSeekerData,
    imageUrl,
    getJobSeekerData,
  } = useContext(Context);

  const seekerData = async () => {
    const res = await getJobSeekerData();
    if (res?.id) {
      if (res.jobseeker_educations.length > 0) {
        setEducationPopup(false);
      }
      if (res.jobseeker_certificates.length > 0) {
        setCertificatePopup(false);
      }
      if (res.jobseeker_employements.length > 0) {
        setEmploymentPopup(false);
      }
      if (res.jobseeker_freelancers.length > 0) {
        setFreeLancerPopup(false);
      }
      if (res.jobseeker_projects.length > 0) {
        setProjectPopup(false);
      }
      if (res.steps === "10") {
        setchartPopup(false);
      }
      setPortfolio(res);
    } else {
      window.location.href = "/getotp-jobseeker";
    }
  };

  useEffect(() => {
    // vidRef.current.play();
    seekerData();
  }, []);
  return (
    <>
      <div className=" desktop-view-experience-card">
        <div className="row">
          <div className="col-lg-12 p-0">
            <Tab.Container id="left-tabs-example" defaultActiveKey="fourth">
              <Nav
                variant="pills"
                className="experience-pills  mt-4 mb-3"
                role="tablist"
              >
                <Nav.Item className="nav-item me-3">
                  <Nav.Link eventKey="fourth" className="nav-link ">
                    Education
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item className="nav-item me-3">
                  <Nav.Link eventKey="certificate" className="nav-link ">
                    Certificate
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item className="nav-item   me-2" role="presentation">
                  <Nav.Link
                    eventKey="first"
                    className="nav-link "
                    onClick={() => {
                      setProject(false);
                      setProject1(false);
                      setDisplay(true);
                    }}
                  >
                    Experience
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="fourth">
                  <div className="card">
                    <Tab.Container
                      id="right-tabs-exampleeee"
                      defaultActiveKey="edutenth"
                    >
                      <Tab.Content>
                        <Tab.Pane eventKey="edutenth">
                          <div
                            className=""
                            style={{ height: educationPopup && "322px" }}
                          >
                            <div className="text-end">
                              <Link to="/portfolio-education-form?activeTab=0">
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "assets/Images/my-portfolio/edit-img.png"
                                  }
                                  className="edit-tool"
                                />
                              </Link>
                            </div>
                            {portfolio?.jobseeker_educations?.map(
                              (value, index) => (
                                <div className="row" key={index}>
                                  <div className="col-lg-3 col-md-3">
                                    <div className="company-name mb-3">
                                      <b className="text-start">
                                        {" "}
                                        University :
                                      </b>
                                      <p>
                                        {value?.university?.name},
                                        {value?.university?.city?.name}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-3">
                                    <div className="role-holder text-start">
                                      <b> Degree Earnd :</b>
                                      <p>
                                        {value?.degree?.name}
                                        {/* <br />({value?.university?.name}) */}
                                      </p>
                                    </div>
                                  </div>
                                  {value?.degree_name && (
                                    <div className="col-lg-3 col-md-2">
                                      <div className="role-holder ">
                                        <b>Degree Name :</b>
                                        <p>{value?.degree_name}</p>
                                      </div>
                                    </div>
                                  )}
                                  {value?.grades && (
                                    <div className="col-lg-3 col-md-2">
                                      <div className="role-holder ">
                                        <b>GPA or Grades :</b>
                                        <p>{value?.grades}</p>
                                      </div>
                                    </div>
                                  )}

                                  <div className="col-lg-3 col-md-2">
                                    <div className="role-holder ">
                                      <b> Year :</b>
                                      <p>
                                        {value?.start_date
                                          ? new Date(
                                              value.start_date
                                            ).getFullYear()
                                          : "N/A"}
                                        -
                                        {value?.end_date
                                          ? String(
                                              new Date(
                                                value.end_date
                                              ).getFullYear()
                                            ).slice(-2)
                                          : "N/A"}
                                      </p>
                                    </div>
                                  </div>
                                  {value?.jobseeker_education_areaof_stuadies && (
                                    <div className="col-lg-3 col-md-3  mb-2">
                                      <div className="role-holder ">
                                        <b> Area Of Study :</b>
                                        {value?.jobseeker_education_areaof_stuadies?.map(
                                          (d, index) => (
                                            <p className="m-0" key={index}>
                                              - {d?.descipline?.name}
                                            </p>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  )}
                                  {value?.certificate_name && (
                                    <div className="col-lg-3 col-md-3">
                                      <div className="role-holder ">
                                        <b> Certificate :</b>
                                        <p>{value?.certificate_name}</p>
                                      </div>
                                    </div>
                                  )}
                                  {value.media && (
                                    <div className="col-lg-3 col-md-3">
                                      <button
                                        type="button"
                                        className="btn viewBtn btn-info mt-2 ms-lg-2 ms-md-2 ms-0"
                                        title="View PDF"
                                        onClick={() =>
                                          openPdfInNewTab(value?.media)
                                        }
                                      >
                                        <FontAwesomeIcon
                                          style={{
                                            display: "flex",
                                            height: "100%",
                                          }}
                                          icon={faEye}
                                          className="viewEye"
                                        />
                                      </button>

                                      <div
                                        className="role-holder"
                                        onClick={() =>
                                          openPdfInNewTab(value?.media)
                                        }
                                      >
                                        <object
                                          data={imageUrl + value?.media}
                                          type="application/pdf"
                                          width="100"
                                          height="100"
                                        >
                                          <p>
                                            Browser Not Supported{" "}
                                            <a
                                              href={imageUrl + value?.media}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              Download PDF
                                            </a>
                                          </p>
                                        </object>
                                      </div>
                                    </div>
                                  )}

                                  <hr />
                                </div>
                              )
                            )}
                            {educationPopup && (
                              <div className="middlePopup">
                                <div className="popupContent">
                                  <div
                                    className="alert-popup"
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Lottie
                                      options={defaultOptions}
                                      height={150}
                                      width={150}
                                    />
                                    <p className="message">
                                      Add Educations to your Portfolio ...!
                                    </p>
                                    <Link to="/portfolio-education-form?activeTab=0">
                                      <button className="btn btn-danger">
                                        Go to form
                                      </button>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </div>
                </Tab.Pane>

                <Tab.Pane eventKey="certificate">
                  <div className="card">
                    <Tab.Container
                      id="right-tabs-exampleeee"
                      defaultActiveKey="edutenth"
                    >
                      <Tab.Content>
                        <Tab.Pane eventKey="edutenth">
                          <div
                            className=""
                            style={{ height: certificatePopup && "322px" }}
                          >
                            <div className="text-end">
                              <Link to="/portfolio-certificate-form?activeTab=0">
                                {" "}
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "assets/Images/my-portfolio/edit-img.png"
                                  }
                                  className="edit-tool"
                                />
                              </Link>
                            </div>
                            {portfolio?.jobseeker_certificates?.map(
                              (value, index) => (
                                <div className="row" key={index}>
                                  <div className="col-lg-3 col-md-3">
                                    <div className="company-name mb-3">
                                      <b> Title :</b>
                                      <p>{value?.title}</p>
                                    </div>
                                  </div>
                                  {value?.issuing_org && (
                                    <div className="col-lg-3 col-md-3">
                                      <div className="company-name mb-3">
                                        <b> Issuing Organization :</b>
                                        <p>{value?.issuing_org}</p>
                                      </div>
                                    </div>
                                  )}

                                  {value?.certificate_for && (
                                    <div className="col-lg-3 col-md-3">
                                      <div className="company-name mb-3">
                                        <b> Certificate For :</b>
                                        <p>{value?.certificate_for?.name}</p>
                                      </div>
                                    </div>
                                  )}

                                  <div className="col-lg-2 col-md-2">
                                    <div className="role-holder ">
                                      <b>Duration :</b>
                                      <p>
                                        {value?.start_date
                                          ? new Date(
                                              value.start_date
                                            ).getFullYear()
                                          : "N/A"}
                                        -
                                        {value?.end_date
                                          ? String(
                                              new Date(
                                                value.end_date
                                              ).getFullYear()
                                            ).slice(-2)
                                          : "N/A"}
                                      </p>
                                    </div>
                                  </div>
                                  {value?.main_topics && (
                                    <div className="col-lg-3">
                                      <div className="role-holder mb-2">
                                        <b>Main Topics : </b>
                                        <p>{value?.main_topics}</p>
                                      </div>
                                    </div>
                                  )}

                                  <div className="col-lg-3">
                                    <div className="role-holder mb-2">
                                      <b>Skills : </b>
                                      {value?.jobseeker_certificate_skills?.map(
                                        (data, index) => (
                                          <p className="m-0" key={index}>
                                            - {data?.skill?.name + ", "}
                                          </p>
                                        )
                                      )}
                                    </div>
                                  </div>
                                  {value?.achievement && (
                                    <div className="col-lg-3">
                                      <div className="role-holder mb-2">
                                        <b>Achievement : </b>
                                        <p>{value?.achievement?.name}</p>
                                      </div>
                                    </div>
                                  )}
                                  <div className="col-lg-3 col-md-3">
                                    {value.evidance && (
                                      <button
                                        type="button"
                                        className="btn viewBtn btn-info mt-2 ms-lg-2 ms-md-2 ms-0"
                                        title="View PDF"
                                        onClick={() =>
                                          openPdfInNewTab(value?.evidance)
                                        }
                                      >
                                        <FontAwesomeIcon
                                          style={{
                                            display: "flex",
                                            height: "100%",
                                          }}
                                          icon={faEye}
                                          className="viewEye"
                                        />
                                      </button>
                                    )}
                                    <div
                                      className="role-holder"
                                      onClick={() =>
                                        openPdfInNewTab(value?.evidance)
                                      }
                                    >
                                      <object
                                        data={imageUrl + value?.evidance}
                                        type="application/pdf"
                                        width="100"
                                        height="100"
                                      >
                                        <p>
                                          Browser Not Supported{" "}
                                          <a
                                            href={imageUrl + value?.evidance}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            Download PDF
                                          </a>
                                        </p>
                                      </object>
                                    </div>
                                  </div>

                                  <hr />
                                </div>
                              )
                            )}
                            {certificatePopup && (
                              <div className="middlePopup">
                                <div className="popupContent">
                                  <div
                                    className="alert-popup"
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Lottie
                                      options={defaultOptions}
                                      height={150}
                                      width={150}
                                    />
                                    <p className="message">
                                      Add Certificates to your Portfolio...!
                                    </p>
                                    <Link to="/portfolio-certificate-form?activeTab=0">
                                      <button className="btn btn-danger">
                                        Go to form
                                      </button>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </div>
                </Tab.Pane>

                <Tab.Pane eventKey="first">
                  <div className="card">
                    <div className="text-end">
                      <Link to="/portfolio-job-experience">
                        {/* <img
                          src={
                            process.env.PUBLIC_URL +
                            "assets/Images/my-portfolio/edit-img.png"
                          }
                          className="edit-tool"
                        /> */}
                      </Link>
                    </div>

                    <Tab.Container
                      id="right-tabs-example"
                      defaultActiveKey="seventh"
                    >
                      <Nav
                        variant="pills"
                        className="experience-pills mb-3"
                        role="tablist"
                      >
                        <Nav.Item className="nav-item me-3">
                          <Nav.Link
                            eventKey="seventh"
                            className="nav-link "
                            // onClick={() => {
                            //   setDisplay(false);
                            //   setProject(false);
                            //   setProject1(true);
                            // }}
                          >
                            Job
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="nav-item me-3">
                          <Nav.Link
                            eventKey="eight"
                            className="nav-link "
                            // onClick={() => {
                            //   setDisplay(false);
                            //   setProject(false);
                            //   setProject1(true);
                            // }}
                          >
                            Freelancer
                          </Nav.Link>
                        </Nav.Item>

                        <Nav.Item className="nav-item  me-3">
                          <Nav.Link
                            eventKey="second"
                            className="nav-link "
                            // onClick={() => {
                            //   setDisplay(false);
                            //   setProject(true);
                            //   setProject1(false);
                            // }}
                          >
                            Projects
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                      <Tab.Content>
                        <Tab.Pane eventKey="seventh">
                          {/* {project1 && ( */}
                          <div
                            className=""
                            style={{ height: employmentPopup && "322px" }}
                          >
                            <div className="text-end">
                              <Link to="/portfolio-viewdetails-experience">
                                {" "}
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "assets/Images/my-portfolio/edit-img.png"
                                  }
                                  className="edit-tool"
                                />
                              </Link>
                            </div>
                            {portfolio?.jobseeker_employements?.map(
                              (value, index) => (
                                <div className="row" key={index}>
                                  <div className="col-lg-4">
                                    <div className="company-name">
                                      {/* <h4>&nbsp;</h4> */}
                                    </div>
                                    <div className="row">
                                      <div className="col-5">
                                        <div className="position-holder">
                                          <b>Company Name :</b>
                                        </div>
                                      </div>
                                      <div className="col-7">
                                        <div className="role-holder ">
                                          <p>{value?.company?.name}</p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-5">
                                        <div className="position-holder">
                                          <b>Job Role :</b>
                                        </div>
                                      </div>
                                      <div className="col-7">
                                        <div className="role-holder ">
                                          <p>{value?.role?.name}</p>
                                        </div>
                                      </div>
                                    </div>
                                    {value?.responsibilities && (
                                      <div className="row">
                                        <div className="col-5">
                                          <div className="position-holder">
                                            <b>Responsibilities :</b>
                                          </div>
                                        </div>
                                        <div className="col-7">
                                          <div className="role-holder ">
                                            <p>{value?.responsibilities}</p>
                                          </div>
                                        </div>
                                      </div>
                                    )}

                                    {value?.city && (
                                      <div className="row">
                                        <div className="col-5">
                                          <div className="position-holder">
                                            <b>Location :</b>
                                          </div>
                                        </div>
                                        <div className="col-7">
                                          <div className="role-holder ">
                                            <p>{value?.city?.name}</p>
                                          </div>
                                        </div>
                                      </div>
                                    )}

                                    <div className="row">
                                      <div className="col-5">
                                        <div className="position-holder">
                                          <b>Experience :</b>
                                        </div>
                                      </div>
                                      <div className="col-7">
                                        <div className="role-holder ">
                                          <p>
                                            {value?.start_date
                                              ? new Date(
                                                  value.start_date
                                                ).getFullYear()
                                              : "N/A"}
                                            -
                                            {value?.end_date
                                              ? new Date(
                                                  value.end_date
                                                ).getFullYear()
                                              : "N/A"}
                                          </p>
                                        </div>
                                      </div>
                                    </div>

                                    {value?.contact_person && (
                                      <div className="row">
                                        <div className="col-5">
                                          <div className="position-holder">
                                            <b> Contact Person :</b>
                                          </div>
                                        </div>
                                        <div className="col-7">
                                          <div className="role-holder ">
                                            <p>{value?.contact_person}</p>
                                          </div>
                                        </div>
                                      </div>
                                    )}

                                    {value?.contact_person_email && (
                                      <div className="row">
                                        <div className="col-5">
                                          <div className="position-holder">
                                            <b> C. P. Email :</b>
                                          </div>
                                        </div>
                                        <div className="col-7">
                                          <div className="role-holder ">
                                            <p>{value?.contact_person_email}</p>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                  <div className="col-lg-4">
                                    <div className="company-name"></div>
                                    <div className="row">
                                      <div className="col-5">
                                        <div className="position-holder">
                                          <b>Skills :</b>
                                        </div>
                                      </div>
                                      <div className="col-7">
                                        <div className="role-holder ">
                                          {value?.jobseeker_employement_skills?.map(
                                            (d, index) => (
                                              <p className="m-0" key={index}>
                                                {d?.skill?.name + ", "}
                                              </p>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    {value?.jobseeker_employement_job_natures && (
                                      <div className="row">
                                        <div className="col-5">
                                          <div className="position-holder">
                                            <b>Job Natures :</b>
                                          </div>
                                        </div>
                                        <div className="col-7">
                                          <div className="role-holder ">
                                            {value?.jobseeker_employement_job_natures?.map(
                                              (d, index) => (
                                                <p className="m-0" key={index}>
                                                  {d?.job_nature?.name + ", "}
                                                </p>
                                              )
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    )}

                                    {value?.achievement && (
                                      <div className="row">
                                        <div className="col-5">
                                          <div className="position-holder">
                                            <b>Achievement :</b>
                                          </div>
                                        </div>
                                        <div className="col-7">
                                          <div className="role-holder ">
                                            <p>{value?.achievement?.name}</p>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                    {/* <div className="row">
                                      <div className="col-5">
                                        <div className="position-holder">
                                          <p>Achievements :</p>
                                        </div>
                                      </div>
                                      <div className="col-7">
                                        <div className="role-holder ">
                                          <p>{value?.achievement?.name}</p>
                                        </div>
                                      </div>
                                    </div> */}
                                  </div>
                                  <div className="col-lg-4">
                                    {value.media && (
                                      <button
                                        type="button"
                                        className="btn viewBtn btn-info mt-2 ms-lg-2 ms-md-2 ms-0"
                                        title="View PDF"
                                        onClick={() =>
                                          openPdfInNewTab(value?.media)
                                        }
                                      >
                                        <FontAwesomeIcon
                                          style={{
                                            display: "flex",
                                            height: "100%",
                                          }}
                                          icon={faEye}
                                          className="viewEye"
                                        />
                                      </button>
                                    )}

                                    <div
                                      className="role-holder"
                                      onClick={() =>
                                        openPdfInNewTab(value?.media)
                                      }
                                    >
                                      <object
                                        data={imageUrl + value?.media}
                                        type="application/pdf"
                                        width="100"
                                        height="100"
                                      >
                                        <p>
                                          Browser Not Supported{" "}
                                          <a
                                            href={imageUrl + value?.media}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            Download PDF
                                          </a>
                                        </p>
                                      </object>
                                    </div>
                                  </div>
                                  <hr />
                                </div>
                              )
                            )}
                            {employmentPopup && (
                              <div className="middlePopup">
                                <div className="popupContent">
                                  <div
                                    className="alert-popup"
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Lottie
                                      options={defaultOptions}
                                      height={150}
                                      width={150}
                                    />
                                    <p className="message">
                                      Add Job Experience...!
                                    </p>
                                    <Link to="/portfolio-viewdetails-experience">
                                      <button className="btn btn-danger">
                                        Go to form
                                      </button>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                          {/* )} */}
                        </Tab.Pane>

                        <Tab.Pane eventKey="eight">
                          {/* {project1 && ( */}
                          <div
                            className=""
                            style={{ height: freeLancerPopup && "322px" }}
                          >
                            <div className="text-end">
                              <Link to="/portfolio-freelancing-form">
                                {" "}
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "assets/Images/my-portfolio/edit-img.png"
                                  }
                                  className="edit-tool"
                                />
                              </Link>
                            </div>
                            {portfolio?.jobseeker_freelancers?.map(
                              (value, index) => (
                                <div className="row" key={index}>
                                  <div className="col-lg-4">
                                    <div className="company-name"></div>
                                    {value?.project_title && (
                                      <div className="row">
                                        <div className="col-5">
                                          <div className="position-holder">
                                            <b>Project Title :</b>
                                          </div>
                                        </div>
                                        <div className="col-7">
                                          <div className="role-holder ">
                                            <p>{value?.project_title}</p>
                                          </div>
                                        </div>
                                      </div>
                                    )}

                                    {value?.client_name && (
                                      <div className="row">
                                        <div className="col-5">
                                          <div className="position-holder">
                                            <b>Client Name :</b>
                                          </div>
                                        </div>
                                        <div className="col-7">
                                          <div className="role-holder ">
                                            <p>{value?.client_name}</p>
                                          </div>
                                        </div>
                                      </div>
                                    )}

                                    <div className="row">
                                      {value?.your_role && (
                                        <>
                                          <div className="col-5">
                                            <div className="position-holder">
                                              <b>Your Role :</b>
                                            </div>
                                          </div>
                                          <div className="col-7">
                                            <div className="role-holder ">
                                              <p>{value?.your_role}</p>
                                            </div>
                                          </div>
                                        </>
                                      )}

                                      <div className="row">
                                        <div className="col-5">
                                          <div className="position-holder">
                                            <b>Year :</b>
                                          </div>
                                        </div>
                                        <div className="col-7">
                                          <div className="role-holder ">
                                            <p>
                                              {" "}
                                              {value?.start_date
                                                ? new Date(
                                                    value.start_date
                                                  ).getFullYear()
                                                : "N/A"}
                                              -
                                              {value?.end_date
                                                ? new Date(
                                                    value.end_date
                                                  ).getFullYear()
                                                : "N/A"}
                                            </p>
                                          </div>
                                        </div>
                                      </div>

                                      {value?.description && (
                                        <div className="row">
                                          <div className="col-5">
                                            <div className="position-holder">
                                              <b>Description :</b>
                                            </div>
                                          </div>
                                          <div className="col-7">
                                            <div className="role-holder ">
                                              <p>{value?.description}</p>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-lg-4">
                                    <div className="company-name"></div>
                                    <div className="row">
                                      {value?.project_outcome && (
                                        <div className="row">
                                          <div className="col-5">
                                            <div className="position-holder">
                                              <b>Project Outcome :</b>
                                            </div>
                                          </div>
                                          <div className="col-7">
                                            <div className="role-holder ">
                                              <p>{value?.project_outcome}</p>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                      {value?.project_link && (
                                        <div className="row">
                                          <div className="col-5">
                                            <div className="position-holder">
                                              <b>Project Link :</b>
                                            </div>
                                          </div>
                                          <div className="col-7">
                                            <div className="role-holder ">
                                              <p>{value?.project_link}</p>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                    {/* <div className="row">
                                      <div className="col-5">
                                        <div className="position-holder">
                                          <p>Project Out Come :</p>
                                        </div>
                                      </div>
                                      <div className="col-7">
                                        <div className="role-holder ">
                                          <p>{value?.project_outcome}</p>
                                        </div>
                                      </div>
                                    </div> */}
                                    {/* <div className="row">
                                        <div className="col-5">
                                          <div className="position-holder">
                                            <p>Achievements :</p>
                                          </div>
                                        </div>
                                        <div className="col-7">
                                          <div className="role-holder ">
                                            <p>{value?.achievement?.name}</p>
                                          </div>
                                        </div>
                                      </div> */}
                                  </div>
                                  <div className="col-lg-4">
                                    {value.media && (
                                      <button
                                        type="button"
                                        className="btn viewBtn btn-info mt-2 ms-lg-2 ms-md-2 ms-0"
                                        title="View PDF"
                                        onClick={() =>
                                          openPdfInNewTab(value?.media)
                                        }
                                      >
                                        <FontAwesomeIcon
                                          style={{
                                            display: "flex",
                                            height: "100%",
                                          }}
                                          icon={faEye}
                                          className="viewEye"
                                        />
                                      </button>
                                    )}

                                    <div
                                      className="role-holder"
                                      onClick={() =>
                                        openPdfInNewTab(value?.media)
                                      }
                                    >
                                      <object
                                        data={imageUrl + value?.media}
                                        type="application/pdf"
                                        width="100"
                                        height="100"
                                      >
                                        <p>
                                          Browser Not Supported{" "}
                                          <a
                                            href={imageUrl + value?.media}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            Download PDF
                                          </a>
                                        </p>
                                      </object>
                                    </div>
                                  </div>
                                  <hr />
                                </div>
                              )
                            )}
                            {freeLancerPopup && (
                              <div className="middlePopup">
                                <div className="popupContent">
                                  <div
                                    className="alert-popup"
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Lottie
                                      options={defaultOptions}
                                      height={150}
                                      width={150}
                                    />
                                    <p className="message">
                                      Add FreeLancing Experience...!
                                    </p>
                                    <Link to="/portfolio-freelancing-form">
                                      <button className="btn btn-danger">
                                        Go to form
                                      </button>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                          {/* )} */}
                        </Tab.Pane>

                        <Tab.Pane eventKey="second">
                          {/* {project && ( */}
                          <div
                            className=""
                            style={{ height: projectPopup && "322px" }}
                          >
                            <div className="text-end">
                              <Link to="/portfolio-project-form">
                                {" "}
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "assets/Images/my-portfolio/edit-img.png"
                                  }
                                  className="edit-tool"
                                />
                              </Link>
                            </div>
                            {portfolio?.jobseeker_projects?.map(
                              (value, index) => (
                                <div className="row" key={index}>
                                  <div className="col-lg-4">
                                    <div className="company-name">
                                      {/* <h4>&nbsp;</h4> */}
                                    </div>
                                    <div className="row">
                                      <div className="col-5">
                                        <div className="position-holder">
                                          <b>Project Type :</b>
                                        </div>
                                      </div>
                                      <div className="col-7">
                                        <div className="role-holder ">
                                          <p>{value?.type?.name}</p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-5">
                                        <div className="position-holder">
                                          <b>Project Organization :</b>
                                        </div>
                                      </div>
                                      <div className="col-7">
                                        <div className="role-holder ">
                                          <p>{value?.organization}</p>
                                        </div>
                                      </div>
                                    </div>
                                    {value?.role && (
                                      <div className="row">
                                        <div className="col-5">
                                          <div className="position-holder">
                                            <b>Role :</b>
                                          </div>
                                        </div>
                                        <div className="col-7">
                                          <div className="role-holder ">
                                            <p>{value?.role}</p>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                    {value?.publication && (
                                      <div className="row">
                                        <div className="col-5">
                                          <div className="position-holder">
                                            <b>Publication :</b>
                                          </div>
                                        </div>
                                        <div className="col-7">
                                          <div className="role-holder ">
                                            <p>{value?.publication}</p>
                                          </div>
                                        </div>
                                      </div>
                                    )}

                                    <div className="row">
                                      <div className="col-5">
                                        <div className="position-holder">
                                          <b>Year :</b>
                                        </div>
                                      </div>
                                      <div className="col-7">
                                        <div className="role-holder ">
                                          <p>
                                            {" "}
                                            {value?.start_date
                                              ? new Date(
                                                  value.start_date
                                                ).getFullYear()
                                              : "N/A"}
                                            -
                                            {value?.end_date
                                              ? new Date(
                                                  value.end_date
                                                ).getFullYear()
                                              : "N/A"}
                                          </p>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="col-5">
                                        <div className="position-holder">
                                          <b>Mentor/Guide Name :</b>
                                        </div>
                                      </div>
                                      <div className="col-7">
                                        <div className="role-holder ">
                                          <p>{value?.mentor}</p>
                                        </div>
                                      </div>
                                    </div>
                                    {value?.achievement && (
                                      <div className="row">
                                        <div className="col-5">
                                          <div className="position-holder">
                                            <b>Achievement :</b>
                                          </div>
                                        </div>
                                        <div className="col-7">
                                          <div className="role-holder ">
                                            <p>{value?.achievement?.name}</p>
                                          </div>
                                        </div>
                                      </div>
                                    )}

                                    {/* <div className="row">
                                      <div className="col-5">
                                        <div className="position-holder">
                                          <p>Publications :</p>
                                        </div>
                                      </div>
                                      <div className="col-7">
                                        <div className="role-holder ">
                                          <p>{value?.publication}</p>
                                        </div>
                                      </div>
                                    </div> */}
                                  </div>
                                  <div className="col-lg-4">
                                    <div className="company-name">
                                      {/* <h4>&nbsp;</h4> */}
                                    </div>

                                    <div className="row">
                                      <div className="col-5">
                                        <div className="position-holder">
                                          <b>Project Title :</b>
                                        </div>
                                      </div>
                                      <div className="col-7">
                                        <div className="role-holder ">
                                          <p>{value?.title}</p>
                                        </div>
                                      </div>
                                    </div>

                                    {value?.url && (
                                      <div className="row">
                                        <div className="col-5">
                                          <div className="position-holder">
                                            <b>Project Url :</b>
                                          </div>
                                        </div>
                                        <div className="col-7">
                                          <div className="role-holder ">
                                            <p>{value?.url}</p>
                                          </div>
                                        </div>
                                      </div>
                                    )}

                                    <div className="row">
                                      <div className="col-5">
                                        <div className="position-holder">
                                          <b>Skill Used :</b>
                                        </div>
                                      </div>
                                      <div className="col-7">
                                        <div className="role-holder ">
                                          <p>
                                            {value?.jobseeker_projects_skills?.map(
                                              (data, index) =>
                                                data?.skill?.name + ", "
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    {value?.description && (
                                      <div className="row">
                                        <div className="col-5">
                                          <div className="position-holder">
                                            <b>Description :</b>
                                          </div>
                                        </div>
                                        <div className="col-7">
                                          <div className="role-holder ">
                                            <p>{value?.description}</p>
                                          </div>
                                        </div>
                                      </div>
                                    )}

                                    {value?.mentor_email && (
                                      <div className="row">
                                        <div className="col-5">
                                          <div className="position-holder">
                                            <b>Mentor Email :</b>
                                          </div>
                                        </div>
                                        <div className="col-7">
                                          <div className="role-holder ">
                                            <p>{value?.mentor_email}</p>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                    {value?.category && (
                                      <div className="row">
                                        <div className="col-5">
                                          <div className="position-holder">
                                            <b>Category :</b>
                                          </div>
                                        </div>
                                        <div className="col-7">
                                          <div className="role-holder ">
                                            <p>{value?.category?.name}</p>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                  <div className="col-lg-4">
                                    {value.media && (
                                      <button
                                        type="button"
                                        className="btn viewBtn btn-info mt-2 ms-lg-2 ms-md-2 ms-0"
                                        title="View PDF"
                                        onClick={() =>
                                          openPdfInNewTab(value?.media)
                                        }
                                      >
                                        <FontAwesomeIcon
                                          style={{
                                            display: "flex",
                                            height: "100%",
                                          }}
                                          icon={faEye}
                                          className="viewEye"
                                        />
                                      </button>
                                    )}

                                    <div
                                      className="role-holder"
                                      onClick={() =>
                                        openPdfInNewTab(value?.media)
                                      }
                                    >
                                      <object
                                        data={imageUrl + value?.media}
                                        type="application/pdf"
                                        width="100"
                                        height="100"
                                      >
                                        <p>
                                          Browser Not Supported{" "}
                                          <a
                                            href={imageUrl + value?.media}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            Download PDF
                                          </a>
                                        </p>
                                      </object>
                                    </div>
                                  </div>
                                  <hr />
                                </div>
                              )
                            )}
                            {projectPopup && (
                              <div className="middlePopup">
                                <div className="popupContent">
                                  <div
                                    className="alert-popup"
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Lottie
                                      options={defaultOptions}
                                      height={150}
                                      width={150}
                                    />
                                    <p className="message">
                                      Add Projects To your Portfolio...!
                                    </p>
                                    <Link to="/portfolio-project-form">
                                      <button className="btn btn-danger">
                                        Go to form
                                      </button>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                          {/* )} */}
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                    {/* {display && (
                      <div className="row ">
                        <div className="col-lg-6">
                          <div className="company-name">
                            <h4>Profcyma Solutions Pvt. Ltd.</h4>
                          </div>
                          <div className="row">
                            <div className="col-5">
                              <div className="position-holder">
                                <p>Role :</p>
                              </div>
                            </div>
                            <div className="col-7">
                              <div className="role-holder ">
                                <p>Frontend Intern</p>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-5">
                              <div className="position-holder">
                                <p>My Position :</p>
                              </div>
                            </div>
                            <div className="col-7">
                              <div className="role-holder ">
                                <p>Junior Frontend Intern</p>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-5">
                              <div className="position-holder">
                                <p>Skills Used :</p>
                              </div>
                            </div>
                            <div className="col-7">
                              <div className="role-holder ">
                                <p>JAVA, Python, HTML, React</p>
                              </div>
                            </div>
                          </div>
                          <div className="details-button desk- mt-5">
                            <Link to="/portfolio-viewdetails-experience">
                              {" "}
                              <div className="btn btn-details">
                                View Details
                              </div>
                            </Link>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="video-img text-lg-end text-md-center mb-3">
                            <video
                              src={
                                process.env.PUBLIC_URL +
                                "assets/Images/my-portfolio/video.mp4"
                              }
                              ref={vidRef}
                              muted
                              loop
                              className=" video-class-grid"
                            />
                          </div>
                        </div>
                      </div>
                    )} */}
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>

          <div className="col-lg-5"></div>
        </div>
      </div>

      <div className="row mobile-view-experience-card">
        <div className="col-lg-12">
          <div className="row">
            <Tab.Container id="left-tabs-example" defaultActiveKey="fourth">
              <Nav
                variant="pills"
                className="experience-pills  mt-4 mb-3"
                role="tablist"
              >
                <Nav.Item className="nav-item">
                  <Nav.Link eventKey="fourth" className="nav-link ">
                    Education
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item className="nav-item">
                  <Nav.Link eventKey="Certificate" className="nav-link ">
                    Certificate
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item className="nav-item " role="presentation">
                  <Nav.Link
                    eventKey="first"
                    className="nav-link "
                    // onClick={() => {
                    //   setProject(false);
                    //   setProject1(false);
                    //   setDisplay(true);
                    // }}
                  >
                    Experience
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="fourth">
                  <div className="card">
                    <div className="">
                      <Tab.Container
                        id="right-tabs-exampleeee"
                        defaultActiveKey="edumobiletenth"
                      >
                        <Tab.Content>
                          <Tab.Pane eventKey="edumobiletenth">
                            <div className="">
                              <div className="text-end">
                                <Link to="/portfolio-education-form?activeTab=0">
                                  {" "}
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "assets/Images/my-portfolio/edit-img.png"
                                    }
                                    className="edit-tool"
                                  />
                                </Link>
                              </div>

                              {portfolio?.jobseeker_educations?.map(
                                (value, index) => (
                                  <div className="row" key={index}>
                                    <div className="col-lg-3 col-md-3">
                                      <div className="company-name mb-3">
                                        <b> University :</b>
                                        <p>
                                          {value?.university?.name},
                                          {value?.university?.city?.name}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="col-lg-2">
                                      <div className="role-holder ">
                                        <b> Degree Earnd :</b>
                                        <p>
                                          {value?.degree?.name}
                                          {/* <br />({value?.university?.name}) */}
                                        </p>
                                      </div>
                                    </div>
                                    {value?.degree_name && (
                                      <div className="col-lg-2 col-md-2">
                                        <div className="role-holder ">
                                          <b>Degree Name :</b>
                                          <p>{value?.degree_name}</p>
                                        </div>
                                      </div>
                                    )}
                                    {value?.grades && (
                                      <div className="col-lg-2 col-md-2">
                                        <div className="role-holder ">
                                          <b>GPA or Grades :</b>
                                          <p>{value?.grades}</p>
                                        </div>
                                      </div>
                                    )}

                                    <div className="col-lg-2 col-md-2">
                                      <div className="role-holder ">
                                        <b> Year :</b>
                                        <p>
                                          {value?.start_date
                                            ? new Date(
                                                value.start_date
                                              ).getFullYear()
                                            : "N/A"}
                                          -
                                          {value?.end_date
                                            ? String(
                                                new Date(
                                                  value.end_date
                                                ).getFullYear()
                                              ).slice(-2)
                                            : "N/A"}
                                        </p>
                                      </div>
                                    </div>
                                    {value?.jobseeker_education_areaof_stuadies && (
                                      <div className="col-lg-2 col-md-2  mb-2">
                                        <div className="role-holder ">
                                          <b> Area Of Study :</b>
                                          {value?.jobseeker_education_areaof_stuadies?.map(
                                            (d, index) => (
                                              <p className="m-0" key={index}>
                                                - {d?.descipline?.name}
                                              </p>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    )}
                                    {value?.certificate_name && (
                                      <div className="col-lg-2 col-md-2">
                                        <div className="role-holder ">
                                          <b> Certificate :</b>
                                          <p>{value?.certificate_name}</p>
                                        </div>
                                      </div>
                                    )}
                                    {value.media && (
                                      <div className="col-lg-3 col-md-3">
                                        <button
                                          type="button"
                                          className="btn viewBtn btn-info mt-2 ms-lg-2 ms-md-2 ms-0"
                                          title="View PDF"
                                          onClick={() =>
                                            openPdfInNewTab(value?.media)
                                          }
                                        >
                                          <FontAwesomeIcon
                                            style={{
                                              display: "flex",
                                              height: "100%",
                                            }}
                                            icon={faEye}
                                            className="viewEye"
                                          />
                                        </button>

                                        <div
                                          className="role-holder"
                                          onClick={() =>
                                            openPdfInNewTab(value?.media)
                                          }
                                        >
                                          <object
                                            data={imageUrl + value?.media}
                                            type="application/pdf"
                                            width="100"
                                            height="100"
                                          >
                                            <p>
                                              Browser Not Supported{" "}
                                              <a
                                                href={imageUrl + value?.media}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                              >
                                                Download PDF
                                              </a>
                                            </p>
                                          </object>
                                        </div>
                                      </div>
                                    )}

                                    <hr />
                                  </div>
                                )
                              )}
                            </div>
                          </Tab.Pane>
                        </Tab.Content>
                      </Tab.Container>
                    </div>
                  </div>
                </Tab.Pane>

                <Tab.Pane eventKey="Certificate">
                  <div className="card">
                    <div className="">
                      <Tab.Container
                        id="right-tabs-exampleeee"
                        defaultActiveKey="edumobiletenth"
                      >
                        <Tab.Content>
                          <Tab.Pane eventKey="edumobiletenth">
                            <div className="">
                              <div className="text-end">
                                <Link to="/portfolio-certificate-form?activeTab=0">
                                  {" "}
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "assets/Images/my-portfolio/edit-img.png"
                                    }
                                    className="edit-tool"
                                  />
                                </Link>
                              </div>
                              {portfolio?.jobseeker_certificates?.map(
                                (value, index) => (
                                  <div className="row" key={index}>
                                    <div className="col-lg-3 ">
                                      <div className="role-holder ">
                                        <b> Title :</b>
                                        <p>{value?.title}</p>
                                      </div>
                                    </div>
                                    {value?.issuing_org && (
                                      <div className="col-lg-3 ">
                                        <div className="role-holder ">
                                          <b> Issuing Organization :</b>
                                          <p>{value?.issuing_org}</p>
                                        </div>
                                      </div>
                                    )}

                                    {value?.certificate_for && (
                                      <div className="col-lg-3 ">
                                        <div className="role-holder ">
                                          <b> Certificate For :</b>
                                          <p>{value?.certificate_for?.name}</p>
                                        </div>
                                      </div>
                                    )}

                                    <div className="col-lg-2 ">
                                      <div className="role-holder ">
                                        <b>Duration :</b>
                                        <p>
                                          {value?.start_date
                                            ? new Date(
                                                value.start_date
                                              ).getFullYear()
                                            : "N/A"}
                                          -
                                          {value?.end_date
                                            ? String(
                                                new Date(
                                                  value.end_date
                                                ).getFullYear()
                                              ).slice(-2)
                                            : "N/A"}
                                        </p>
                                      </div>
                                    </div>
                                    {value?.main_topics && (
                                      <div className="col-lg-3">
                                        <div className="role-holder">
                                          <b>Main Topics : </b>
                                          <p>{value?.main_topics}</p>
                                        </div>
                                      </div>
                                    )}

                                    <div className="col-lg-3">
                                      <div className="role-holder">
                                        <b>Skills : </b>
                                        {value?.jobseeker_certificate_skills?.map(
                                          (data, index) => (
                                            <p className="m-0" key={index}>
                                              - {data?.skill?.name + ", "}
                                            </p>
                                          )
                                        )}
                                      </div>
                                    </div>
                                    {value?.achievement && (
                                      <div className="col-lg-3">
                                        <div className="role-holder">
                                          <b>Achievement : </b>
                                          <p>{value?.achievement?.name}</p>
                                        </div>
                                      </div>
                                    )}
                                    <div className="col-lg-3 col-md-3">
                                      {value.evidance && (
                                        <button
                                          type="button"
                                          className="btn viewBtn btn-info mt-2 ms-lg-2 ms-md-2 ms-0"
                                          title="View PDF"
                                          onClick={() =>
                                            openPdfInNewTab(value?.evidance)
                                          }
                                        >
                                          <FontAwesomeIcon
                                            style={{
                                              display: "flex",
                                              height: "100%",
                                            }}
                                            icon={faEye}
                                            className="viewEye"
                                          />
                                        </button>
                                      )}
                                      <div
                                        className="role-holder"
                                        onClick={() =>
                                          openPdfInNewTab(value?.evidance)
                                        }
                                      >
                                        <object
                                          data={imageUrl + value?.evidance}
                                          type="application/pdf"
                                          width="100"
                                          height="100"
                                        >
                                          <p>
                                            Browser Not Supported{" "}
                                            <a
                                              href={imageUrl + value?.evidance}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              Download PDF
                                            </a>
                                          </p>
                                        </object>
                                      </div>
                                    </div>

                                    <hr />
                                  </div>
                                )
                              )}
                            </div>
                          </Tab.Pane>
                        </Tab.Content>
                      </Tab.Container>
                    </div>
                  </div>
                </Tab.Pane>

                <Tab.Pane eventKey="first">
                  <div className="card">
                    <div className="text-end">
                      {/* <Link to="/portfolio-job-experience">
                        {" "}
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "assets/Images/my-portfolio/edit-img.png"
                          }
                          className="edit-tool"
                        />
                      </Link> */}
                    </div>

                    <Tab.Container
                      id="right-tabs-example"
                      defaultActiveKey="seventh"
                    >
                      <Nav
                        variant="pills"
                        className="experience-pills mb-2"
                        role="tablist"
                      >
                        <Swiper
                          className="mobileExpSwiper"
                          breakpoints={{
                            0: {
                              slidesPerView: 4,
                            },
                            360: {
                              slidesPerView: 4,
                            },
                            640: {
                              slidesPerView: 4,
                            },
                            767: {
                              slidesPerView: 3,
                            },
                          }}
                        >
                          <SwiperSlide>
                            <Nav.Item className="nav-item">
                              <Nav.Link
                                eventKey="seventh"
                                className="nav-link "
                                // onClick={() => {
                                //   setDisplay(false);
                                //   setProject(false);
                                //   setProject1(true);
                                // }}
                              >
                                Job
                              </Nav.Link>
                            </Nav.Item>
                          </SwiperSlide>
                          <SwiperSlide>
                            <Nav.Item className="nav-item ">
                              <Nav.Link
                                eventKey="eight"
                                className="nav-link Expfreelancer_tab"
                                // onClick={() => {
                                //   setDisplay(false);
                                //   setProject(false);
                                //   setProject1(true);
                                // }}
                              >
                                Freelancer
                              </Nav.Link>
                            </Nav.Item>
                          </SwiperSlide>

                          <SwiperSlide>
                            <Nav.Item className="nav-item ">
                              <Nav.Link
                                eventKey="second"
                                className="nav-link "
                                // onClick={() => {
                                //   setDisplay(false);
                                //   setProject(true);
                                //   setProject1(false);
                                // }}
                              >
                                Projects
                              </Nav.Link>
                            </Nav.Item>
                          </SwiperSlide>
                        </Swiper>
                      </Nav>
                      <Tab.Content>
                        <Tab.Pane eventKey="seventh">
                          {/* {project1 && ( */}
                          <div className="">
                            <div className="text-end">
                              <Link to="/portfolio-viewdetails-experience">
                                {" "}
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "assets/Images/my-portfolio/edit-img.png"
                                  }
                                  className="edit-tool"
                                />
                              </Link>
                            </div>
                            {portfolio?.jobseeker_employements?.map(
                              (value, index) => (
                                <Fragment key={index}>
                                  <div className="row">
                                    <div className="col-lg-4">
                                      <div className="company-name">
                                        {/* <h4>&nbsp;</h4> */}
                                      </div>
                                      <div className="row">
                                        <div className="col-5">
                                          <div className="position-holder">
                                            <b>Company Name :</b>
                                          </div>
                                        </div>
                                        <div className="col-7">
                                          <div className="role-holder ">
                                            <p>{value?.company?.name}</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-5">
                                          <div className="position-holder">
                                            <b>Job Role :</b>
                                          </div>
                                        </div>
                                        <div className="col-7">
                                          <div className="role-holder ">
                                            <p>{value?.role?.name}</p>
                                          </div>
                                        </div>
                                      </div>
                                      {value?.responsibilities && (
                                        <div className="row">
                                          <div className="col-5">
                                            <div className="position-holder">
                                              <b>Responsibilities :</b>
                                            </div>
                                          </div>
                                          <div className="col-7">
                                            <div className="role-holder ">
                                              <p>{value?.responsibilities}</p>
                                            </div>
                                          </div>
                                        </div>
                                      )}

                                      {value?.city && (
                                        <div className="row">
                                          <div className="col-5">
                                            <div className="position-holder">
                                              <b>Location :</b>
                                            </div>
                                          </div>
                                          <div className="col-7">
                                            <div className="role-holder ">
                                              <p>{value?.city?.name}</p>
                                            </div>
                                          </div>
                                        </div>
                                      )}

                                      <div className="row">
                                        <div className="col-5">
                                          <div className="position-holder">
                                            <b>Experience :</b>
                                          </div>
                                        </div>
                                        <div className="col-7">
                                          <div className="role-holder ">
                                            <p>
                                              {value?.start_date
                                                ? new Date(
                                                    value.start_date
                                                  ).getFullYear()
                                                : "N/A"}
                                              -
                                              {value?.end_date
                                                ? new Date(
                                                    value.end_date
                                                  ).getFullYear()
                                                : "N/A"}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      {value?.contact_person && (
                                        <div className="row">
                                          <div className="col-5">
                                            <div className="position-holder">
                                              <b> Contact Person :</b>
                                            </div>
                                          </div>
                                          <div className="col-7">
                                            <div className="role-holder ">
                                              <p>{value?.contact_person}</p>
                                            </div>
                                          </div>
                                        </div>
                                      )}

                                      {value?.contact_person_email && (
                                        <div className="row">
                                          <div className="col-5">
                                            <div className="position-holder">
                                              <b> C. P. Email :</b>
                                            </div>
                                          </div>
                                          <div className="col-7">
                                            <div className="role-holder ">
                                              <p>
                                                {value?.contact_person_email}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                    <div className="col-lg-4">
                                      <div className="company-name"></div>
                                      <div className="row">
                                        <div className="col-5">
                                          <div className="position-holder">
                                            <b>Skills :</b>
                                          </div>
                                        </div>
                                        <div className="col-7">
                                          <div className="role-holder ">
                                            {value?.jobseeker_employement_skills?.map(
                                              (d, index) => (
                                                <p className="m-0" key={index}>
                                                  {d?.skill?.name + ", "}
                                                </p>
                                              )
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                      {value?.jobseeker_employement_job_natures && (
                                        <div className="row">
                                          <div className="col-5">
                                            <div className="position-holder">
                                              <b>Job Natures :</b>
                                            </div>
                                          </div>
                                          <div className="col-7">
                                            <div className="role-holder ">
                                              {value?.jobseeker_employement_job_natures?.map(
                                                (d, index) => (
                                                  <p
                                                    className="m-0"
                                                    key={index}
                                                  >
                                                    {d?.job_nature?.name + ", "}
                                                  </p>
                                                )
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      )}

                                      {value?.achievement && (
                                        <div className="row">
                                          <div className="col-5">
                                            <div className="position-holder">
                                              <b>Achievement :</b>
                                            </div>
                                          </div>
                                          <div className="col-7">
                                            <div className="role-holder ">
                                              <p>{value?.achievement?.name}</p>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                      {/* <div className="row">
                                      <div className="col-5">
                                        <div className="position-holder">
                                          <p>Achievements :</p>
                                        </div>
                                      </div>
                                      <div className="col-7">
                                        <div className="role-holder ">
                                          <p>{value?.achievement?.name}</p>
                                        </div>
                                      </div>
                                    </div> */}
                                    </div>
                                    <div className="col-lg-4">
                                      {value.media && (
                                        <button
                                          type="button"
                                          className="btn viewBtn btn-info mt-2 ms-lg-2 ms-md-2 ms-0"
                                          title="View PDF"
                                          onClick={() =>
                                            openPdfInNewTab(value?.media)
                                          }
                                        >
                                          <FontAwesomeIcon
                                            style={{
                                              display: "flex",
                                              height: "100%",
                                            }}
                                            icon={faEye}
                                            className="viewEye"
                                          />
                                        </button>
                                      )}

                                      <div
                                        className="role-holder"
                                        onClick={() =>
                                          openPdfInNewTab(value?.media)
                                        }
                                      >
                                        <object
                                          data={imageUrl + value?.media}
                                          type="application/pdf"
                                          width="100"
                                          height="100"
                                        >
                                          <p>
                                            Browser Not Supported{" "}
                                            <a
                                              href={imageUrl + value?.media}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              Download PDF
                                            </a>
                                          </p>
                                        </object>
                                      </div>
                                    </div>
                                    <hr />
                                  </div>

                                  {/* <div className="row" key={index}>
                                  <div className="col-lg-4">
                                    <div className="company-name">
                                  
                                    </div>
                                    <div className="row">
                                      <div className="col-5">
                                        <div className="position-holder">
                                          <p>Job Role :</p>
                                        </div>
                                      </div>
                                      <div className="col-7">
                                        <div className="role-holder ">
                                          <p>{value?.role?.name}</p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-5">
                                        <div className="position-holder">
                                          <p>Company Name :</p>
                                        </div>
                                      </div>
                                      <div className="col-7">
                                        <div className="role-holder ">
                                          <p>{value?.company?.name}</p>
                                        </div>
                                      </div>
                                    </div>
                                   
                                  </div>
                                  <div className="col-lg-4">
                                    <div className="company-name"></div>
                                    <div className="row">
                                      <div className="col-5">
                                        <div className="position-holder">
                                          <p>Experience :</p>
                                        </div>
                                      </div>
                                      <div className="col-7">
                                        <div className="role-holder ">
                                          <p>
                                            {value?.start_date
                                              ? new Date(
                                                  value.start_date
                                                ).getFullYear()
                                              : "N/A"}
                                            -
                                            {value?.end_date
                                              ? new Date(
                                                  value.end_date
                                                ).getFullYear()
                                              : "N/A"}
                                          </p>
                                        </div>
                                      </div>
                                    </div>

                                  
                                  </div>
                                  <div className="col-lg-4">
                                    {value.media && (
                                      <button
                                        type="button"
                                        className="btn viewBtn btn-info mt-2 ms-2"
                                        title="View PDF"
                                        onClick={() =>
                                          openPdfInNewTab(value?.media)
                                        }
                                      >
                                        <FontAwesomeIcon
                                          style={{
                                            display: "flex",
                                            height: "100%",
                                          }}
                                          icon={faEye}
                                          className="viewEye"
                                        />
                                      </button>
                                    )}

                                    <div
                                      className="role-holder"
                                      onClick={() =>
                                        openPdfInNewTab(value?.media)
                                      }
                                    >
                                      <object
                                        data={imageUrl + value?.media}
                                        type="application/pdf"
                                        width="200"
                                        height="200"
                                      >
                                        <p>
                                          Browser Not Supported{" "}
                                          <a
                                            href={imageUrl + value?.media}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            Download PDF
                                          </a>
                                        </p>
                                      </object>
                                    </div>
                                  </div>
                                  <hr />
                                </div> */}
                                </Fragment>
                              )
                            )}
                          </div>
                          {/* )} */}
                        </Tab.Pane>

                        <Tab.Pane eventKey="eight">
                          {/* {project1 && ( */}
                          <div className="">
                            <div className="text-end">
                              <Link to="/portfolio-freelancing-form">
                                {" "}
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "assets/Images/my-portfolio/edit-img.png"
                                  }
                                  className="edit-tool"
                                />
                              </Link>
                            </div>
                            {portfolio?.jobseeker_freelancers?.map(
                              (value, index) => (
                                <Fragment key={index}>
                                  <div className="row">
                                    <div className="col-lg-4">
                                      <div className="company-name"></div>
                                      <div className="row">
                                        <div className="col-5">
                                          <div className="position-holder">
                                            <b>Project Title :</b>
                                          </div>
                                        </div>
                                        <div className="col-7">
                                          <div className="role-holder ">
                                            <p>{value?.project_title}</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-5">
                                          <div className="position-holder">
                                            <b>Client Name :</b>
                                          </div>
                                        </div>
                                        <div className="col-7">
                                          <div className="role-holder ">
                                            <p>{value?.client_name}</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-5">
                                          <div className="position-holder">
                                            <b>Your Role :</b>
                                          </div>
                                        </div>
                                        <div className="col-7">
                                          <div className="role-holder ">
                                            <p>{value?.your_role}</p>
                                          </div>
                                        </div>

                                        <div className="row">
                                          <div className="col-5">
                                            <div className="position-holder">
                                              <b>Year :</b>
                                            </div>
                                          </div>
                                          <div className="col-7">
                                            <div className="role-holder ">
                                              <p>
                                                {" "}
                                                {value?.start_date
                                                  ? new Date(
                                                      value.start_date
                                                    ).getFullYear()
                                                  : "N/A"}
                                                -
                                                {value?.end_date
                                                  ? new Date(
                                                      value.end_date
                                                    ).getFullYear()
                                                  : "N/A"}
                                              </p>
                                            </div>
                                          </div>
                                        </div>

                                        {value?.description && (
                                          <div className="row">
                                            <div className="col-5">
                                              <div className="position-holder">
                                                <b>Description :</b>
                                              </div>
                                            </div>
                                            <div className="col-7">
                                              <div className="role-holder ">
                                                <p>{value?.description}</p>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                    <div className="col-lg-4">
                                      <div className="company-name"></div>
                                      <div className="row">
                                        {value?.project_outcome && (
                                          <div className="row">
                                            <div className="col-5">
                                              <div className="position-holder">
                                                <b>Project Outcome :</b>
                                              </div>
                                            </div>
                                            <div className="col-7">
                                              <div className="role-holder ">
                                                <p>{value?.project_outcome}</p>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                        {value?.project_link && (
                                          <div className="row">
                                            <div className="col-5">
                                              <div className="position-holder">
                                                <b>Project Link :</b>
                                              </div>
                                            </div>
                                            <div className="col-7">
                                              <div className="role-holder ">
                                                <p>{value?.project_link}</p>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                      {/* <div className="row">
                                      <div className="col-5">
                                        <div className="position-holder">
                                          <p>Project Out Come :</p>
                                        </div>
                                      </div>
                                      <div className="col-7">
                                        <div className="role-holder ">
                                          <p>{value?.project_outcome}</p>
                                        </div>
                                      </div>
                                    </div> */}
                                      {/* <div className="row">
                                        <div className="col-5">
                                          <div className="position-holder">
                                            <p>Achievements :</p>
                                          </div>
                                        </div>
                                        <div className="col-7">
                                          <div className="role-holder ">
                                            <p>{value?.achievement?.name}</p>
                                          </div>
                                        </div>
                                      </div> */}
                                    </div>
                                    <div className="col-lg-4">
                                      {value.media && (
                                        <button
                                          type="button"
                                          className="btn viewBtn btn-info mt-2 ms-lg-2 ms-md-2 ms-0"
                                          title="View PDF"
                                          onClick={() =>
                                            openPdfInNewTab(value?.media)
                                          }
                                        >
                                          <FontAwesomeIcon
                                            style={{
                                              display: "flex",
                                              height: "100%",
                                            }}
                                            icon={faEye}
                                            className="viewEye"
                                          />
                                        </button>
                                      )}

                                      <div
                                        className="role-holder"
                                        onClick={() =>
                                          openPdfInNewTab(value?.media)
                                        }
                                      >
                                        <object
                                          data={imageUrl + value?.media}
                                          type="application/pdf"
                                          width="100"
                                          height="100"
                                        >
                                          <p>
                                            Browser Not Supported{" "}
                                            <a
                                              href={imageUrl + value?.media}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              Download PDF
                                            </a>
                                          </p>
                                        </object>
                                      </div>
                                    </div>
                                    <hr />
                                  </div>
                                </Fragment>
                              )
                            )}
                          </div>
                          {/* )} */}
                        </Tab.Pane>

                        <Tab.Pane eventKey="second">
                          {/* {project && ( */}
                          <div className="">
                            <div className="text-end">
                              <Link to="/portfolio-project-form">
                                {" "}
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "assets/Images/my-portfolio/edit-img.png"
                                  }
                                  className="edit-tool"
                                />
                              </Link>
                            </div>
                            {portfolio?.jobseeker_projects?.map(
                              (value, index) => (
                                <div className="row" key={index}>
                                  <div className="col-lg-4">
                                    <div className="company-name">
                                      {/* <h4>&nbsp;</h4> */}
                                    </div>
                                    <div className="row">
                                      <div className="col-5">
                                        <div className="position-holder">
                                          <b>Project Type :</b>
                                        </div>
                                      </div>
                                      <div className="col-7">
                                        <div className="role-holder ">
                                          <p>{value?.type?.name}</p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-5">
                                        <div className="position-holder">
                                          <b>Project Organization :</b>
                                        </div>
                                      </div>
                                      <div className="col-7">
                                        <div className="role-holder ">
                                          <p>{value?.organization}</p>
                                        </div>
                                      </div>
                                    </div>
                                    {value?.role && (
                                      <div className="row">
                                        <div className="col-5">
                                          <div className="position-holder">
                                            <b>Role :</b>
                                          </div>
                                        </div>
                                        <div className="col-7">
                                          <div className="role-holder ">
                                            <p>{value?.role}</p>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                    {value?.publication && (
                                      <div className="row">
                                        <div className="col-5">
                                          <div className="position-holder">
                                            <b>Publication :</b>
                                          </div>
                                        </div>
                                        <div className="col-7">
                                          <div className="role-holder ">
                                            <p>{value?.publication}</p>
                                          </div>
                                        </div>
                                      </div>
                                    )}

                                    <div className="row">
                                      <div className="col-5">
                                        <div className="position-holder">
                                          <b>Year :</b>
                                        </div>
                                      </div>
                                      <div className="col-7">
                                        <div className="role-holder ">
                                          <p>
                                            {" "}
                                            {value?.start_date
                                              ? new Date(
                                                  value.start_date
                                                ).getFullYear()
                                              : "N/A"}
                                            -
                                            {value?.end_date
                                              ? new Date(
                                                  value.end_date
                                                ).getFullYear()
                                              : "N/A"}
                                          </p>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="col-5">
                                        <div className="position-holder">
                                          <b>Mentor/Guide Name :</b>
                                        </div>
                                      </div>
                                      <div className="col-7">
                                        <div className="role-holder ">
                                          <p>{value?.mentor}</p>
                                        </div>
                                      </div>
                                    </div>
                                    {value?.achievement && (
                                      <div className="row">
                                        <div className="col-5">
                                          <div className="position-holder">
                                            <b>Achievement :</b>
                                          </div>
                                        </div>
                                        <div className="col-7">
                                          <div className="role-holder ">
                                            <p>{value?.achievement?.name}</p>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                  <div className="col-lg-4">
                                    <div className="company-name">
                                      {/* <h4>&nbsp;</h4> */}
                                    </div>

                                    <div className="row">
                                      <div className="col-5">
                                        <div className="position-holder">
                                          <b>Project Title :</b>
                                        </div>
                                      </div>
                                      <div className="col-7">
                                        <div className="role-holder ">
                                          <p>{value?.title}</p>
                                        </div>
                                      </div>
                                    </div>

                                    {value?.url && (
                                      <div className="row">
                                        <div className="col-5">
                                          <div className="position-holder">
                                            <b>Project Url :</b>
                                          </div>
                                        </div>
                                        <div className="col-7">
                                          <div className="role-holder ">
                                            <p>{value?.url}</p>
                                          </div>
                                        </div>
                                      </div>
                                    )}

                                    <div className="row">
                                      <div className="col-5">
                                        <div className="position-holder">
                                          <b>Skill Used :</b>
                                        </div>
                                      </div>
                                      <div className="col-7">
                                        <div className="role-holder ">
                                          <p>
                                            {value?.jobseeker_projects_skills?.map(
                                              (data, index) =>
                                                data?.skill?.name + ", "
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    {value?.description && (
                                      <div className="row">
                                        <div className="col-5">
                                          <div className="position-holder">
                                            <b>Description :</b>
                                          </div>
                                        </div>
                                        <div className="col-7">
                                          <div className="role-holder ">
                                            <p>{value?.description}</p>
                                          </div>
                                        </div>
                                      </div>
                                    )}

                                    {value?.mentor_email && (
                                      <div className="row">
                                        <div className="col-5">
                                          <div className="position-holder">
                                            <b>Mentor Email :</b>
                                          </div>
                                        </div>
                                        <div className="col-7">
                                          <div className="role-holder ">
                                            <p>{value?.mentor_email}</p>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                    {value?.category && (
                                      <div className="row">
                                        <div className="col-5">
                                          <div className="position-holder">
                                            <b>Category :</b>
                                          </div>
                                        </div>
                                        <div className="col-7">
                                          <div className="role-holder ">
                                            <p>{value?.category?.name}</p>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                  <div className="col-lg-4">
                                    {value.media && (
                                      <button
                                        type="button"
                                        className="btn viewBtn btn-info mt-2 ms-lg-2 ms-md-2 ms-0"
                                        title="View PDF"
                                        onClick={() =>
                                          openPdfInNewTab(value?.media)
                                        }
                                      >
                                        <FontAwesomeIcon
                                          style={{
                                            display: "flex",
                                            height: "100%",
                                          }}
                                          icon={faEye}
                                          className="viewEye"
                                        />
                                      </button>
                                    )}

                                    <div
                                      className="role-holder"
                                      onClick={() =>
                                        openPdfInNewTab(value?.media)
                                      }
                                    >
                                      <object
                                        data={imageUrl + value?.media}
                                        type="application/pdf"
                                        width="100"
                                        height="100"
                                      >
                                        <p>
                                          Browser Not Supported{" "}
                                          <a
                                            href={imageUrl + value?.media}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            Download PDF
                                          </a>
                                        </p>
                                      </object>
                                    </div>
                                  </div>
                                  <hr />
                                </div>
                              )
                            )}
                          </div>
                          {/* )} */}
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExperienceSection;
