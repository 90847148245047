import React from 'react';
import Modal from 'react-bootstrap/Modal';
import '../CreateJobs/CreateJobs.css'
import Success from '../../../../JobseekerPage/DashBoardJob/ThirdJobTab/Animation/Success';


const CreateModal = (props)=>  {
  return (
    <>
       <section >
        <Modal className='PopupModal'
          {...props}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered>

          <Modal.Body>
            <Success />
            <p className='modal_txt'> Your Job has been Posted Successfully!</p>
            <p className='text-center' style={{ color: "#FFFF" }}> Have a Great Time! </p>
          </Modal.Body>
        </Modal>
      </section> 



    </>

  );
}

export default CreateModal;