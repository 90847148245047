import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Title = () => {
    return (
        <>
            <section className="Sixth-job-tab">
                <div className='container'>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="tittle-sixth-job-tab ">
                                <div className="desktop-view">
                                    <h5 className="text-center mt-md-5 mt-3">Your Desired Jobs Search</h5>
                                    <Link to='/'><a>Home <FontAwesomeIcon icon="fa-solid fa-angle-right" /><span>Your Desired Jobs Search</span></a></Link>
                                </div>
                            </div>
                            <div className="black-line">
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Title;