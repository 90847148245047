import React from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import "./BuyNow.css";
import { useState, useEffect } from "react";
import Select from "react-select";
import BuyNowModal from "./Modal/BuyNowModal";
import { PacmanLoader } from "react-spinners";

import useRazorpay from "react-razorpay";
import { useContext } from "react";
import { Context } from "../../../../../utils/context";
import Autosuggest from "react-autosuggest";

const Buynow = () => {
  const { postData, getData, imageUrl, Select2Data } = useContext(Context);

  const navigate = useNavigate();
  const [modalShow1, setModalShow1] = useState(false);
  const { cat, sub, plan } = useParams();
  const location = useLocation();
  // console.log(location);
  const queryParams = new URLSearchParams(location.search);
  const title = queryParams.get("title");
  const cardHeading = queryParams.get("card_heading");

  const [name, setName] = useState("");
  const [sector_id, setSector] = useState("");
  const [city_id, setCity] = useState(null);
  const [email, setEmail] = useState("");
  const [contact_no, setContact] = useState("");
  const [contact_person, setContactPerson] = useState("");
  const [relation_to_company, setRelation] = useState("");

  const [nameErr, setNameErr] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [sectorErr, setSectorErr] = useState("");
  const [livingErr, setLivingErr] = useState("");
  const [contactErr, setContactErr] = useState("");
  const [conPersonErr, setConPersonErr] = useState("");
  const [relationErr, setRelationErr] = useState("");

  const openModal1 = () => {
    // console.log(sector_id, "forms");
    // console.log(
    //   name +
    //     " " +
    //     sector_id +
    //     " " +
    //     city_id +
    //     " " +
    //     email +
    //     " " +
    //     contact_no +
    //     " " +
    //     contact_person +
    //     " " +
    //     relation_to_company,
    //   "forms"
    // );

    // Initialize an array to store error messages
    var errors = [];

    // if (email.trim() === "") {
    //   setEmailErr("Email is required");
    //   errors.push("Email is required");
    // } else if (!/^\S+@\S+\.\S+$/.test(email)) {
    //   setEmailErr("Email is not valid");
    //   errors.push("Email is not valid");
    // }

    if (city_id === null) {
      setLivingErr("Company City is required");
      errors.push("Company City is required");
    }

    if (!name) {
      setNameErr("Company Name is required");
      errors.push("Company Name is required");
    }

    // if (!sector_id) {
    //   setSectorErr("Job Sector is required");
    //   errors.push("Job Sector is required");
    // }

    // if (contact_no.trim() === "") {
    //   setContactErr("Contact Number is required");
    //   errors.push("Contact Number is required");
    // } else if (!/^\d{10}$/.test(contact_no)) {
    //   setContactErr("Contact Number should be a 10-digit number");
    //   errors.push("Contact Number should be a 10-digit number");
    // }

    // if (contact_person.trim() === "") {
    //   setConPersonErr("Contact Person is required");
    //   errors.push("Contact Person is required");
    // }

    // if (relation_to_company.trim() === "") {
    //   setRelationErr("Relation is required");
    //   errors.push("Relation is required");
    // }

    if (errors.length === 0) {
      handleRazorpay(amount);
    }
  };

  const finalFunction = async (razor) => {
    const paydata = {
      razorpay_id: razor,
      amount: amount,
    };
    // console.log(paydata);
    const payres = await postData(
      "/recruiters/pool_subscriber_payment",
      paydata
    );

    if (payres?.success) {
      const data = {
        payment_id: payres?.data?.id,
        company_id: name,
        sector_id: sector_id,
        city_id: city_id?.value,
        email: email,
        contact_no: contact_no,
        contact_person: contact_person,
        relation_to_company: relation_to_company,
        category_id: talentPoolCategory?.id,
        subcategory_id: talentPoolSubCategory?.id,
        plan_id: talentPoolPlan?.id,
      };
      const res = await postData("/recruiters/pool_subscriber", data);
      if (res?.success) {
        setModalShow1(true);

        setTimeout(() => {
          setModalShow1(false);
          setName("");
          setEmail("");
          setContact("");
          setContactPerson("");
          setRelation("");
          setOtherSector("");
          setOtherCompany("");
          setSector("");
          setCity("");
          navigate("/dashboardmain");
        }, 5000);
      } else {
      }
    } else {
    }
  };

  //Payment Gateway

  const [Razorpay, openRazorpay] = useRazorpay();

  const [razorid, setRazorId] = useState();
  const [amount, setAmount] = useState();
  const handleRazorpay = async (storedValue) => {
    // console.log(storedValue, "ghjgfjh");
    const options: RazorpayOptions = {
      key: "rzp_test_I05KCFb1v5JJRc",
      amount: storedValue * 100,
      currency: "INR",
      name: "Hiclousia",
      description: "Test Transaction",
      image:
        "http://localhost:3000/static/media/hippicart-logo.427718645d3015dbf003.png",

      handler: async (res) => {
        await setRazorId(res.razorpay_payment_id);
        finalFunction(res.razorpay_payment_id);
        // console.log(res, "ttttttttttttttttttttttttttttt");
      },
      prefill: {
        name: "Piyush Garg",
        email: "youremail@example.com",
        contact: "9999999999",
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#052c65",
      },
    };

    const rzpay = new Razorpay(options);
    rzpay.open();
  };

  //GET APIS Start

  const textClassMap = {
    0: "card_silver_txt",
    1: "card_golden_txt",
    2: "card_platinum_txt",
  };

  const textClass = textClassMap[cat] || "default_txt";

  //talent Pool API
  const [talentPoolCategory, setTalentPoolCategory] = useState();
  const [talentPoolSubCategory, setTalentPoolSubcategory] = useState();
  const [talentPoolPlan, setTalentPoolPlan] = useState();
  const [loader, setLoader] = useState(true);

  const getTalentPool = async () => {
    const res = await getData("/recruiters/talent-pool/all-talent-pool");
    if (res?.success) {
      // console.log(res.data, "talentPools");
      setTalentPoolCategory(res.data.data[cat]);
      setTalentPoolSubcategory(
        res.data.data[cat].talent_pool_subcategories[sub]
      );
      setTalentPoolPlan(
        res.data.data[cat].talent_pool_subcategories[sub].talent_pool_plans[
          plan
        ]
      );
      setAmount(
        res.data.data[cat].talent_pool_subcategories[sub].talent_pool_plans[
          plan
        ].price
      );
      setLoader(false);
    } else {
      getTalentPool();
    }
  };

  //Sector API
  const sectoroptions = [];
  const [companySectors, setCompanySectors] = useState();
  const [companies, setCompamies] = useState();

  const [otherCompany, setOtherCompany] = useState(false);
  const [otherSector, setOtherSector] = useState(false);
  const [other_sector, setOtherJobSector] = useState("");
  const [other_company, setOtherJobCompany] = useState("");
  const getMaster = async () => {
    {
      const res = await getData("/without-login/master/all-cities");
      if (res?.success) {
        const data = await Select2Data(res.data, "cities", false);
        setCities(data);
      }
    }
  };

  const handleSectorChange = (selectedValues) => {
    setSectorErr("");
    setSector(selectedValues);
    if (selectedValues?.value === "0") {
      setOtherSector(true);
    } else {
      setOtherJobSector("");
      setOtherSector(false);
    }
  };

  const handleCompanyChange = (selectedValues) => {
    setNameErr("");
    setName(selectedValues);
    if (selectedValues?.value === "0") {
      setOtherCompany(true);
    } else {
      setOtherJobCompany("");
      setOtherCompany(false);
    }
  };

  // Cities API
  const options = [];
  const [cities, setCities] = useState();

  const [suggestionsList, setSuggestionsList] = useState([]);

  const [company_id, setSelectedCompanyOption] = useState("");
  const getSuggestions = async (inputValue, list) => {
    const inputValueLowerCase = inputValue?.trim().toLowerCase();

    if (list === "company_id") {
      const res = await getData(
        `/without-login/master/all-company?name=${inputValueLowerCase}`
      );
      if (res?.success) {
        return res.data?.filter((suggestion) =>
          suggestion?.name.toLowerCase().includes(inputValueLowerCase)
        );
      }
    }
    if (list === "sector_id") {
      const res = await getData(
        `/without-login/master/all-sector?name=${inputValueLowerCase}`
      );
      if (res?.success) {
        return res.data?.filter((suggestion) =>
          suggestion?.name.toLowerCase().includes(inputValueLowerCase)
        );
      }
    }
  };

  const onSuggestionsFetchRequested = async ({ value }, list) => {
    const suggestions = await getSuggestions(value, list);
    setSuggestionsList(suggestions);
  };

  const onSuggestionsClearRequested = () => {
    setSuggestionsList([]);
  };

  const onChange = (event, { newValue }) => {
    if (event.target.id === "company_id") {
      setName(newValue);
    }
    if (event.target.id === "sector_id") {
      setSector(newValue);
    }
  };

  const getSuggestionValue = (suggestion) => suggestion?.name;

  const renderSuggestion = (suggestion, id) => (
    <div
      id={id}
      style={{
        border: "1px solid #ccc",
        padding: "10px",
        borderRadius: "5px",
        margin: "5px",
        backgroundColor: "#f9f9f9",
      }}
    >
      {suggestion?.name}
    </div>
  );

  const inputProps = {
    onChange,
    style: {
      width: "300px", // Adjust the width as needed
      padding: "8px",
      borderRadius: "5px",
      border: "1px solid #ccc",
      // Add other styles as needed
    },
  };

  useEffect(() => {
    getTalentPool();
    getMaster();
  }, []);
  return (
    <>
      <section className="requiter-signup-form">
        <div className="container">
          {loader ? (
            <div className="container" style={{ paddingLeft: "50%" }}>
              <PacmanLoader color="#36d7b7" />
            </div>
          ) : (
            <>
              <div className="row buyNowContainer">
                <div className="border-heading"></div>
                <div className={`upperheading_buyNow`}>
                  <span className={` ${textClass}`}>
                    {talentPoolCategory?.name}
                  </span>
                  <span className=" silverPooltxt_2 ms-2">
                    {talentPoolSubCategory?.name}
                  </span>
                </div>
                <div className="buynow_belowTitleTxt">
                  Bridge the Gap between Talent and Demand
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 mx-auto p-lg-0 BuynowMain ">
                  <div className="form-contact-main">
                    <div className="row">
                      <div className="col-lg-7">
                        <Form className="form_contact">
                          <Row className="mb-3">
                            <Form.Group as={Col} controlId="formGridfirstname">
                              <Form.Label>
                                Company Name{" "}
                                <span className="text-danger">*</span>
                              </Form.Label>

                              <Autosuggest
                                id="company_id"
                                suggestions={suggestionsList}
                                onSuggestionsFetchRequested={(event) =>
                                  onSuggestionsFetchRequested(
                                    event,
                                    "company_id"
                                  )
                                }
                                onSuggestionsClearRequested={
                                  onSuggestionsClearRequested
                                }
                                getSuggestionValue={getSuggestionValue}
                                renderSuggestion={(suggestion) =>
                                  renderSuggestion(suggestion, "company_id")
                                }
                                inputProps={{
                                  ...inputProps,
                                  id: "company_id",
                                  value: name,
                                  placeholder: "Enter Company",
                                }}
                              />
                            </Form.Group>
                            <span
                              className="text text-danger "
                              style={{
                                textAlign: "center",
                              }}
                            >
                              {nameErr && nameErr}
                            </span>
                          </Row>

                          {/* <Form.Group
                            className="mb-3"
                            controlId="formGridemail"
                          >
                            <Form.Label>
                              Company Sector{" "}
                              <span className="text-danger">*</span>
                            </Form.Label>

                            <Autosuggest
                              id="sector_id"
                              suggestions={suggestionsList}
                              onSuggestionsFetchRequested={(event) =>
                                onSuggestionsFetchRequested(event, "sector_id")
                              }
                              onSuggestionsClearRequested={
                                onSuggestionsClearRequested
                              }
                              getSuggestionValue={getSuggestionValue}
                              renderSuggestion={(suggestion) =>
                                renderSuggestion(suggestion, "sector_id")
                              }
                              inputProps={{
                                ...inputProps,
                                id: "sector_id",
                                value: sector_id,
                                placeholder: "Enter Sector",
                              }}
                            />
                          </Form.Group>
                          <span
                            className="text text-danger "
                            style={{
                              textAlign: "center",
                            }}
                          >
                            {sectorErr && sectorErr}
                          </span> */}

                          <Form.Group
                            className="mb-3"
                            controlId="formGridemail"
                          >
                            <Form.Label>
                              Office City <span className="text-danger">*</span>
                            </Form.Label>
                            {/* <Form.Select defaultValue="Enter Your City">
                                                    <option>Enter Your City</option>
                                                    <option>Pune</option>
                                                    <option>Mumbai</option>
                                                </Form.Select> */}
                            <Select
                              placeholder="Select Office City"
                              value={city_id}
                              onChange={(city_id) => {
                                setCity(city_id);
                                setLivingErr("");
                              }}
                              options={cities}
                              isSearchable={true}
                              id="living"
                            />
                          </Form.Group>
                          <span
                            className="text text-danger "
                            style={{
                              textAlign: "center",
                            }}
                          >
                            {livingErr && livingErr}
                          </span>

                          {/* <Row className="">
                            <div className="col-md-6 col-12 mb-3">
                              <Form.Group controlId="formGridpassword">
                                <Form.Label>
                                  Contact Person{" "}
                                  <span className="text-danger">*</span>
                                </Form.Label>
                                <div>
                                  <Form.Control
                                    type="text"
                                    placeholder="Contact Person"
                                    value={contact_person}
                                    onChange={(event) => {
                                      setContactPerson(event.target.value);
                                      setConPersonErr("");
                                    }}
                                    onKeyDown={(event) => {
                                      if (!/[A-Z-a-z ]/.test(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                  />
                                </div>
                              </Form.Group>
                              <span
                                className="text text-danger "
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                {conPersonErr && conPersonErr}
                              </span>
                            </div>
                            <div className="col-md-6 col-12 mb-3">
                              <Form.Group controlId="formGridcity">
                                <Form.Label>
                                  Relation to the Company
                                   <span className="text-danger">*</span> 
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Enter Relation"
                                  value={relation_to_company}
                                  onChange={(event) => {
                                    setRelation(event.target.value);
                                    setRelationErr("");
                                  }}
                                />
                              </Form.Group>
                              <span
                                className="text text-danger "
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                {relationErr && relationErr}
                              </span>
                            </div>
                          </Row> */}
                          {/* <Row className="">
                            <div className="col-md-6 col-12 mb-3">
                              <Form.Group controlId="formGridpassword">
                                <Form.Label>
                                  Email
                                  <span className="text-danger">*</span>
                                </Form.Label>
                                <div>
                                  <Form.Control
                                    type="email"
                                    placeholder="Enter Email"
                                    value={email}
                                    onChange={(event) => {
                                      setEmail(event.target.value);
                                      setEmailErr("");
                                    }}
                                  />
                                </div>
                              </Form.Group>
                              <span
                                className="text text-danger"
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                {emailErr && emailErr}
                              </span>
                            </div>
                            <div className="col-md-6 col-12 mb-3">
                              <Form.Group controlId="formGridcity">
                                <Form.Label>
                                  Contact Number{" "}
                                  <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  minlength="10"
                                  maxlength="10"
                                  placeholder="Enter Contact Number"
                                  value={contact_no}
                                  onChange={(event) => {
                                    setContact(event.target.value);
                                    setContactErr("");
                                  }}
                                  onKeyDown={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      if (event.key != "Backspace")
                                        event.preventDefault();
                                    }
                                  }}
                                />
                              </Form.Group>
                              <span
                                className="text text-danger "
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                {contactErr && contactErr}
                              </span>
                            </div>
                          </Row> */}
                          <Form.Group
                            className="mb-3"
                            id="formGridCheckbox"
                          ></Form.Group>
                          <div className="text-center  buyNowbtn mb-4">
                            <button
                              className="btn btn-primary TalentPool_btn"
                              type="button"
                              onClick={openModal1}
                            >
                              Create a Talent pool
                            </button>
                            <BuyNowModal
                              show={modalShow1}
                              onHide={() => setModalShow1(false)}
                            />
                          </div>
                        </Form>
                      </div>
                      <div className="col-lg-5">
                        <div className="BuyNow_image_holder">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/Images/banner/girlimg.png"
                            }
                            className="signup-image-contact"
                          />
                          <div className="img_overlay"></div>
                          <div className="overlay-holder">
                            <h5>
                              Bridge <br /> the Gap{" "}
                            </h5>
                            <h6>
                              between <br />
                              Talent and <br /> Demand{" "}
                            </h6>
                            <p>
                              Purchase <br /> Your Plan Today
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default Buynow;
