import React from 'react'
// import FirstTabBanner from './FirstTabBanner/FirstTabBanner.js';
import OpenJobs from './OpenJobs/OpenJobs.js';
// import SearchPool from './SearchPool/SearchPool.js';
// import CreateJobs from './CreateJobs/CreateJobs.js';
// import HiringFunnel from './HiringFunnel/HiringFunnel.js'
// import DraftedJobs from './DraftedJobs/DraftedJobs.js';
// import SubDraftJob from './SubDraftJob/SubDraftJob.js'


const ManangeJobs = () => {
    return (
        <div>
            {/* <FirstTabBanner /> */}
            <OpenJobs />
            {/* <SearchPool />
            <CreateJobs />
            <HiringFunnel />
            <DraftedJobs />
            <SubDraftJob /> */}
        </div>
    )
} 

export default ManangeJobs;