import React, { useState } from "react";
import "../CompanyProfileText/CompanyProfileText.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

const CompanyProfileText = () => {
  const [selectedValue, setSelectedValue] = useState("");

  const handleSelectChange = (e) => {
    setSelectedValue(e.target.value);
  };
  const [formData, setFormData] = useState({
    name: "",
    email: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(formData);
  };
  return (
    <>
      <section className="company-profile">
        {/* <div className='container '> */}
        <div className="row  ms-0 me-0 justify-content-center">
          <div className="accountManagement_main">
            <p className="companyProfile_editdetails text-end">
              <FontAwesomeIcon icon="fa-solid fa-pen" /> Edit details
            </p>
          </div>
          <div className="col-xl-7 col-lg-10 col-md-12">
            <div className="row">
              <div className="col-lg-10  mx-auto">
                <div className="company-profile-title text-center ">
                  <div>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "assets/Images/banner/manimg.png"
                      }
                      className="accManprofile_img"
                    />
                  </div>
                  <h2>Company Profile</h2>
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <Form className="company-profile-form">
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formHorizontalEmail"
                >
                  <Form.Label column sm={2}>
                    Company Name :
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      id="name"
                      name="name"
                      value={formData.name}
                      placeholder="Enter Company Name"
                      onChange={handleChange}
                    />
                  </Col>
                </Form.Group>

                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formHorizontalPassword"
                >
                  <Form.Label column sm={2}>
                    Company Website :
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      id="Website"
                      name="Website"
                      placeholder="Enter Company Website"
                      value={formData.Website}
                      onChange={handleChange}
                    />
                  </Col>
                </Form.Group>

                <div className="row">
                  <div className="col-md-6">
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formHorizontalEmail"
                    >
                      <Form.Label column sm={4}>
                        Established since :
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Control
                          type="text"
                          id="name"
                          name="name"
                          value={formData.name}
                          placeholder="Enter Established since"
                          onChange={handleChange}
                        />
                      </Col>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formHorizontalEmail"
                    >
                      <Form.Label column sm={4}>
                        Official Email :
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Control
                          type="text"
                          id="name"
                          name="name"
                          value={formData.name}
                          placeholder="Enter Official Email"
                          onChange={handleChange}
                        />
                      </Col>
                    </Form.Group>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formHorizontalEmail"
                    >
                      <Form.Label column sm={4}>
                        Contact Number :
                      </Form.Label>
                      <Col sm={8}>
                        <div className="d-flex">
                          <div className="">
                            <select
                              id="dropdown"
                              value={selectedValue}
                              onChange={handleSelectChange}
                            >
                              <option value="">
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "assets/Images/logo/flag.png"
                                  }
                                />
                                +91
                              </option>
                              <option value="option1">+91</option>
                              <option value="option2">+91</option>
                              <option value="option3">+91</option>
                            </select>
                          </div>
                          <div className="">
                            <input
                              type="number"
                              id="number"
                              name="number"
                              value={formData.number}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </Col>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formHorizontalEmail"
                    >
                      <Form.Label column sm={4}>
                        G.S.T. Number :
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Control
                          type="text"
                          placeholder="Enter G.S.T. Number"
                          id="team"
                          name="team"
                          value={formData.team}
                          onChange={handleChange}
                        />
                      </Col>
                    </Form.Group>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formHorizontalEmail"
                    >
                      <Form.Label column sm={4}>
                        Team Size :
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Control
                          placeholder="Enter Team Size"
                          type="text"
                          id="team"
                          name="team"
                          value={formData.team}
                          onChange={handleChange}
                        />
                      </Col>
                    </Form.Group>
                  </div>
                </div>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formHorizontalEmail"
                >
                  <Form.Label column sm={2}>
                    Industry (belongs to :)
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      as="textarea"
                      placeholder="Enter Industry (belong to)"
                      rows={1}
                    />
                  </Col>
                </Form.Group>
                {/* <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                    <Form.Label column sm={2}>
                                        About Company
                                    </Form.Label>
                                    <Col sm={10}>
                                        <Form.Control as="textarea" rows={1} />
                                    </Col>
                                </Form.Group> */}
                <div className="row">
                  <div className="col-md-2">
                    <label htmlFor="Video">Upload Video</label>
                  </div>
                  <div className="col-md-10 mt-4">
                    <video
                      src={"assets/Images/video/video.mp4"}
                      muted
                      autoPlay
                      loop
                      className="video-set"
                    />
                  </div>
                </div>
              </Form>
            </div>
            <div className="col-md-12 mt-md-5 mt-3">
              <p className="award-name">Company Awards & Achievements :</p>
            </div>
            <div className="row">
              <div className="col-md-2"></div>
              <div className="col-md-10">
                <div className="awards">
                  <p>
                    Lorem Ipsum Award : Lorem Ipsum Award Lorem Ipsum Award
                    2021-22
                  </p>
                  <p>
                    Lorem Ipsum Award : Lorem Ipsum Award Lorem Ipsum Award
                    2021-22
                  </p>
                  <p>
                    Lorem Ipsum Award : Lorem Ipsum Award Lorem Ipsum Award
                    2021-22
                  </p>
                  <p>
                    Lorem Ipsum Award : Lorem Ipsum Award Lorem Ipsum Award
                    2021-22
                  </p>
                </div>
              </div>
              <div className="col-xl-2  col-xl-2 col-lg-2 col-md-3 col-3">
                <div className="social-icon-company mt-md-5 mb-md-5">
                  <div className="social-name">Social Links :</div>
                </div>
              </div>
              <div className="col-xl-3  col-xl-3 col-lg-10 col-md-9 col-9 mb-5 mb-md-0 ">
                <div className="social-icon-class d-flex">
                  <div>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "assets/Images/icons/google logo.png"
                      }
                      className="social-icon-class-img me-4 "
                    />
                  </div>
                  <div>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "assets/Images/icons/facebook.png"
                      }
                      className="social-icon-class-img me-4"
                    />
                  </div>
                  <div>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "assets/Images/icons/twitter.png"
                      }
                      className="social-icon-class-img me-4 "
                    />
                  </div>
                  <div>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "assets/Images/icons/instagram.png"
                      }
                      className="social-icon-class-img me-4"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
      </section>
    </>
  );
};

export default CompanyProfileText;
