import React, { Fragment, useEffect, useState } from "react";
import "./Blogs.css";
import Col from "react-bootstrap/Col";
// import Tab from 'react-bootstrap/Tab';
import "react-tabs/style/react-tabs.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import BlogDetailsModal from "./BlogDetails/BlogDeatailsModal";

import { useContext } from "react";
import { Context } from "../../../utils/context";
const Blogs = () => {
  var HtmlToReactParser = require("html-to-react").Parser;
  var htmlToReactParser = new HtmlToReactParser();
  const [key, setKey] = useState("home");

  const [modalShow, setModalShow] = React.useState(false);

  const { getData, imageUrl, NoDataAnimations } = useContext(Context);

  const [blogs, setBlog] = useState();
  const [blog, setBlogDetail] = useState();

  const getBlogs = async () => {
    const res = await getData("/without-login/blog/all-blogs");
    if (res?.success) {
      setBlog(res.data);
    }
  };

  const blogDetail = async (data) => {
    await setBlogDetail(data);
    await setModalShow(true);
  };

  useEffect(() => {
    getBlogs();
  }, []);
  return (
    <>
      <section className="Blogs">
        <div className="container-fluid p-0">
          <div className="blog_banner">
            <img
              src={
                process.env.PUBLIC_URL + "assets/Images/banner/Blog_backImg.png"
              }
              className="back_img"
              alt="Blog_background"
            />
            <div className="Blog_banner_txt">
              <p className="blog_banner_para1">Blog</p>
              <p className="blog_banner_para2">to stay updated with trends</p>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="mt-4 ">
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <Row className="desktop-view-blogs-tabs">
                <Nav variant="pills" className="nav-tabs blog_tabs">
                  {/* <Nav.Item >
                                        <Nav.Link className='nav-link' eventKey="first" style={{ borderLeft: "none" }}>ALL POSTS</Nav.Link>
                                    </Nav.Item>
                                    {blogs?.map((value, index) => (
                                        <Nav.Item>
                                            <Nav.Link className='nav-link' eventKey={value?.name + value?.id}>{value?.name}</Nav.Link>
                                        </Nav.Item>
                                    ))} */}
                  <Swiper
                    // spaceBetween={5}

                    breakpoints={{
                      768: {
                        slidesPerView: 2,
                      },
                      1200: {
                        slidesPerView: 3,
                      },
                      1440: {
                        slidesPerView: 4,
                      },
                      1600: {
                        slidesPerView: 5,
                      },
                    }}
                    className="desktop-swiper"
                  >
                    <SwiperSlide className="blog-slider">
                      <Nav.Item>
                        <Nav.Link
                          className="nav-link"
                          eventKey="first"
                          style={{ borderLeft: "none" }}
                        >
                          ALL POSTS
                        </Nav.Link>
                      </Nav.Item>
                    </SwiperSlide>
                    {blogs?.map((value, index) => (
                      <SwiperSlide className="blog-slider" key={index}>
                        <Nav.Item>
                          <Nav.Link
                            className="nav-link"
                            eventKey={value?.name + value?.id}
                          >
                            {value?.name}
                          </Nav.Link>
                        </Nav.Item>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </Nav>
              </Row>
              <Row className="mobile-view-blogs-tabs">
                <Nav variant="pills" className="nav-tabs blog_tabs">
                  <Swiper
                    // spaceBetween={5}

                    breakpoints={{
                      0: {
                        slidesPerView: 2,
                      },
                      360: {
                        slidesPerView: 3,
                      },
                      640: {
                        slidesPerView: 3,
                      },
                      767: {
                        slidesPerView: 3,
                      },
                    }}
                  >
                    <SwiperSlide>
                      <Nav.Item className="blogs_upperTab">
                        <Nav.Link
                          className="nav-link"
                          eventKey="first"
                          style={{ borderLeft: "none" }}
                        >
                          ALL POSTS
                        </Nav.Link>
                      </Nav.Item>
                    </SwiperSlide>
                    {blogs?.map((value, index) => (
                      <SwiperSlide key={index}>
                        <Nav.Item className="blogs_upperTab">
                          <Nav.Link
                            className="nav-link"
                            eventKey={value?.name + value?.id}
                          >
                            {value?.name}
                          </Nav.Link>
                        </Nav.Item>
                      </SwiperSlide>
                    ))}
                    {/* <SwiperSlide >
                                            <Nav.Item className='blogs_upperTab'>
                                                <Nav.Link className='nav-link' eventKey="third">STATISTICS AND JOB </Nav.Link>
                                            </Nav.Item>
                                        </SwiperSlide>
                                        <SwiperSlide >
                                            <Nav.Item className='blogs_upperTab'>
                                                <Nav.Link className='nav-link' eventKey="fourth">STAFFING</Nav.Link>
                                            </Nav.Item>
                                        </SwiperSlide>
                                        <SwiperSlide >
                                            <Nav.Item className='blogs_upperTab'>
                                                <Nav.Link className='nav-link' eventKey="fifth">SMALL BUSINESSES</Nav.Link>
                                            </Nav.Item>
                                        </SwiperSlide> */}
                  </Swiper>
                </Nav>
              </Row>
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <div className="row blog_main">
                    {blogs?.map((value, id) => (
                      <Fragment key={id}>
                        {value?.blogs?.map((item, id) => (
                          <Fragment key={id}>
                            <div
                              className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 mb-3"
                              key={id}
                            >
                              <div
                                className="cardLinksection"
                                variant="primary"
                                onClick={() => blogDetail(item)}
                              >
                                <Link>
                                  <div className="card Blog_card">
                                    <div className="text-center mt-2">
                                      <img
                                        src={imageUrl + item?.image}
                                        className="Blogcard-img "
                                        alt="..."
                                      />
                                    </div>
                                    <div className="card-body blog_body pb-0">
                                      <h5 className="card-title blog_cardHeading">
                                        {item?.title}
                                      </h5>
                                      <p className="card-text Blog_card_txt">
                                        {htmlToReactParser.parse(
                                          item?.description
                                        )}
                                      </p>
                                      <p className="Blog_cardTitle">
                                        <FontAwesomeIcon
                                          icon={faCalendarDays}
                                          style={{ color: "black" }}
                                          className="me-1"
                                        />
                                        {item?.date}
                                      </p>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            </div>
                            <div
                              className="mobile-view-blog"
                              variant="primary"
                              onClick={() => blogDetail(item)}
                            >
                              <Link className="blog-link">
                                <div className="row ">
                                  <div className="col-sm-12 col-12">
                                    <p className="Blog_cardTitle text-end">
                                      <FontAwesomeIcon
                                        icon={faCalendarDays}
                                        style={{ color: "black" }}
                                        className="me-1"
                                      />
                                      {item?.date}
                                    </p>
                                  </div>
                                  <div className="col-sm-4 col-4 g-0">
                                    <div className="text-center ">
                                      <img
                                        src={imageUrl + item?.image}
                                        className="Blogcard-img "
                                        alt="..."
                                      />
                                    </div>
                                  </div>
                                  <div className="col-sm-8 col-8">
                                    <div className="card-body pb-0">
                                      <h5 className="card-title blog_cardHeading">
                                        {item?.title}
                                      </h5>
                                      <p className="card-text Blog_card_txt">
                                        {htmlToReactParser.parse(
                                          item?.description
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          </Fragment>
                        ))}
                      </Fragment>
                    ))}
                    {blogs?.length === 0 && <NoDataAnimations />}
                  </div>
                </Tab.Pane>

                {blogs?.map((value, index) => (
                  <Tab.Pane eventKey={value?.name + value?.id} key={index}>
                    <div className="row blog_main">
                      {value?.blogs?.map((item, id) => (
                        <Fragment key={id}>
                          <div
                            className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 mb-3"
                            key={id}
                          >
                            <div
                              className="cardLinksection"
                              variant="primary"
                              onClick={() => blogDetail(item)}
                            >
                              <Link>
                                <div className="card Blog_card">
                                  <div className="text-center mt-2">
                                    <img
                                      src={imageUrl + item?.image}
                                      className="Blogcard-img "
                                      alt="..."
                                    />
                                  </div>
                                  <div className="card-body blog_body pb-0">
                                    <h5 className="card-title blog_cardHeading">
                                      {item?.title}
                                    </h5>
                                    <p className="card-text Blog_card_txt">
                                      {htmlToReactParser.parse(
                                        item?.description
                                      )}
                                    </p>
                                    <p className="Blog_cardTitle">
                                      <FontAwesomeIcon
                                        icon={faCalendarDays}
                                        style={{ color: "black" }}
                                        className="me-1"
                                      />
                                      {item?.date}
                                    </p>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          </div>
                          <div
                            className="mobile-view-blog"
                            variant="primary"
                            onClick={() => blogDetail(item)}
                          >
                            <Link className="blog-link">
                              <div className="row ">
                                <div className="col-sm-12 col-12">
                                  <p className="Blog_cardTitle text-end">
                                    <FontAwesomeIcon
                                      icon={faCalendarDays}
                                      style={{ color: "black" }}
                                      className="me-1"
                                    />
                                    {item?.date}
                                  </p>
                                </div>
                                <div className="col-sm-4 col-4 g-0">
                                  <div className="text-center ">
                                    <img
                                      src={imageUrl + item?.image}
                                      className="Blogcard-img "
                                      alt="..."
                                    />
                                  </div>
                                </div>
                                <div className="col-sm-8 col-8">
                                  <div className="card-body pb-0">
                                    <h5 className="card-title blog_cardHeading">
                                      {item?.title}
                                    </h5>
                                    <p className="card-text Blog_card_txt">
                                      {htmlToReactParser.parse(
                                        item?.description
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </div>
                          <BlogDetailsModal
                            show={modalShow}
                            data={item}
                            onHide={() => setModalShow(false)}
                          />
                        </Fragment>
                      ))}
                      {value?.blogs?.length === 0 && <NoDataAnimations />}
                    </div>
                  </Tab.Pane>
                ))}
              </Tab.Content>
            </Tab.Container>
            <BlogDetailsModal
              show={modalShow}
              data={blog}
              onHide={() => setModalShow(false)}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default Blogs;
