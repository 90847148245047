import React from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Button from 'react-bootstrap/Button';
import Form from "react-bootstrap/Form";
import Select from "react-select";
import "./JobSearch.css";
const JobSearch = () => {
  const locationOptions = [
    { value: "1", label: "Option 1" },
    { value: "2", label: "Option 2" },
    { value: "3", label: "Option 3" },
    { value: "4", label: "Option 4" },
    { value: "1", label: "Option 1" },
    { value: "2", label: "Option 2" },
    { value: "3", label: "Option 3" },
    { value: "4", label: "Option 4" },
    { value: "1", label: "Option 1" },
    { value: "2", label: "Option 2" },
    { value: "3", label: "Option 3" },
    { value: "4", label: "Option 4" },
    { value: "1", label: "Option 1" },
    { value: "2", label: "Option 2" },
    { value: "3", label: "Option 3" },
    { value: "4", label: "Option 4" },
    { value: "1", label: "Option 1" },
    { value: "2", label: "Option 2" },
    { value: "3", label: "Option 3" },
    { value: "4", label: "Option 4" },

  ];
  return (
    <>
      {/* main section started */}
      <section className="job-search">
        <div className="container">
          <div className="row justify-content-center">
            <h1 className="heading-job text-center">JobSearch</h1>

            {/* breadcrumb section started */}
            <div className="Go-To_home-main mt-3">
              <div className="d-flex">
                <div className="breadcrumb-main me-3">
                  <Breadcrumb>
                    <Breadcrumb.Item href="" className="go-home">
                      Go to Home
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href="https://getbootstrap.com/docs/4.0/components/breadcrumb/">
                      Find a Suitable Job
                    </Breadcrumb.Item>
                  </Breadcrumb>
                </div>
                <div className="border-job-search mt-3"></div>
              </div>
            </div>
            {/* breadcrumb section end */}

            {/* Search section started */}
            <div className="Search-section mt-4 mb-5">
              <div className="job-search-background">
                <p>Job Search</p>
              </div>
              <div className="main-content">
                {/* 1st row of selector */}
                <div className="row">
                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 ">
                    <label htmlFor="inputPassword5" className="form-label">
                      Job Role :
                    </label>
                    <Select
                      id="inputState"
                      options={locationOptions}
                      placeholder="Graphic & UI Designer"
                      className="job"
                      classNamePrefix="job"
                    />
                  </div>
                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                    <label htmlFor="inputPassword5" className="form-label">
                      Job Sector :
                    </label>
                    <Select
                      id="inputState"
                      options={locationOptions}
                      placeholder="Designer"
                      className="job"
                      classNamePrefix="job"
                    />
                  </div>
                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                    <label htmlFor="inputPassword5" className="form-label">
                      Qualification :
                    </label>
                    <Select
                      id="inputState"
                      options={locationOptions}
                      placeholder="Diploma / Degree"
                      className="job"
                      classNamePrefix="job"
                    />
                  </div>
                </div>

                {/* 2nd row of selector */}
                <div className="row  mt-4">
                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                    <label htmlFor="inputPassword5" className="form-label">
                      Primary Skills :
                    </label>
                    <Select
                      id="inputState"
                      options={locationOptions}
                      placeholder="Drawing & Visualization"
                      className="job"
                      classNamePrefix="job"
                    />
                  </div>
                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                    <label htmlFor="inputPassword5" className="form-label">
                      Secondary Skills :
                    </label>
                    <Select
                      id="inputState"
                      options={locationOptions}
                      placeholder="Softwares"
                      className="job"
                      classNamePrefix="job"
                    />
                  </div>
                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                    <label htmlFor="inputPassword5" className="form-label">
                      Select Prof. Experience in Years :
                    </label>
                    <Select
                      id="inputState"
                      options={locationOptions}
                      placeholder="1 Year"
                      className="job"
                      classNamePrefix="job"
                    />
                  </div>
                </div>

                {/* 3rd row of selector */}
                <div className="row  mt-4">
                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                    <label htmlFor="inputPassword5" className="form-label">
                      Professional Projects :
                    </label>
                    <Select
                      id="inputState"
                      options={locationOptions}
                      placeholder="1 Year"
                      className="job"
                      classNamePrefix="job"
                    />
                  </div>
                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                    <label htmlFor="inputPassword5" className="form-label">
                      Expected Salary :
                    </label>
                    <Select
                      id="inputState"
                      options={locationOptions}
                      placeholder="3 - 3.5 Lac Per Annum"
                      className="job"
                      classNamePrefix="job"
                      type="number"
                    />
                  </div>
                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                    <label htmlFor="inputPassword5" className="form-label">
                      Preferred City for work :
                    </label>
                    <Select
                      id="inputState"
                      options={locationOptions}
                      placeholder="Pune"
                      className="job"
                      classNamePrefix="job"
                    />
                  </div>
                </div>
                <p className="ready mt-4">Ready to Move :</p>

                {/* radio button started */}
                <Form>
                  {["radio"].map((type) => (
                    <div key={`inline-${type}`} className="mb-3">
                      <Form.Check
                        inline
                        label="yes"
                        name="group1"
                        type={type}
                        id={`inline-${type}-1`}
                      />
                      <Form.Check
                        inline
                        label="No"
                        name="group1"
                        type={type}
                        id={`inline-${type}-2`}
                      />
                    </div>
                  ))}
                </Form>
                <div className="row justify-content-center">
                  <Button variant="primary">Submit</Button>{' '}
                </div>
              </div>
            </div>
            {/* Search section end*/}
          </div>
        </div>
      </section>
      {/* main section end */}
    </>
  );
};

export default JobSearch;
