import React from "react";
import { Link } from "react-router-dom";
import "../OpenJobs/OpenJobs.css";
import "../HiringFunnel2/HiringFunnel2.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ShortBanner from "../../../../RecruiterPage/NavBar/ShortListedPortfolio/ShortBanner/ShortBanner";

const HiringFunnel = () => {
  return (
    <>
      <section className="manage-job">
        <div className="col-md-12">
          <div className="heading-holder1 text-center my-md-5 mt-3">
            {/* <ShortBanner /> */}
            <h5 className="hiring-name">Hiring Funnel</h5>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-title">
                <div className="row">
                  <div className=" col-xxl-3 col-xl-4 col-lg-5 col-md-6 ">
                    <a className="title-open-jobs">
                      <Link to="/dashboardmain">
                        Go to List of Till Date Posted Jobs
                      </Link>{" "}
                      <FontAwesomeIcon
                        icon="fa-solid fa-angle-right"
                        className="mx-2 "
                      />
                      <Link to="/hiring">
                        {" "}
                        <span> Hiring Funnel</span>
                      </Link>
                    </a>
                  </div>
                  <div className=" col-xxl-9 col-xl-8 col-lg-7 col-md-6">
                    <div className="left-blue-line"></div>
                  </div>
                  <div className="col-md-12">
                    <p className="text-holder-manage-job mt-md-4">
                      Application for Graphic & UI Designer (Pool S1)
                    </p>
                  </div>
                  <div className="col-md-12">
                    <div className="manage-job-text ">
                      <div className="row p-2">
                        <div className="col-md-10 col-10">
                          <div className="text-holder-manage-job">
                            <p>Graphic & UI Designer</p>
                          </div>
                        </div>
                        <div className="col-md-2 col-2 text-end ">
                          <div className="btn-group secondTab_dropdown">
                            <Link
                              className="nav-link"
                              exact="true"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              id="actionButton"
                              activeclassname="active"
                            >
                              <FontAwesomeIcon icon="fa-solid fa-ellipsis-vertical" />
                            </Link>
                            <ul
                              className="dropdown-menu dropdown-menu-end p-0"
                              aria-labelledby="actionButton"
                            >
                              <li>
                                <Link
                                  className="dropdown-item"
                                  to={"/editjobs"}
                                >
                                  Edit Job details
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  to={"/viewjobs"}
                                >
                                  View Job details
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="text-holder-manage-job">
                            <h5>
                              <FontAwesomeIcon icon="fa-solid fa-calendar-days" />{" "}
                              Job Posted on 15/09/23
                            </h5>
                          </div>
                        </div>
                        <div className="col-lg-2 col-md-6">
                          <div className="text-holder-manage-job">
                            <h5>
                              <FontAwesomeIcon icon="fa-solid fa-location-dot" />{" "}
                              Location: Mumbai
                            </h5>
                          </div>
                        </div>
                        <div className="col-lg-2 col-md-6">
                          <div className="text-holder-manage-job">
                            <h5>
                              <FontAwesomeIcon icon="fa-solid fa-star" /> Pool:
                              Silver (S1)
                            </h5>
                          </div>
                        </div>
                        <div className="col-lg-5 col-md-6">
                          <div className="shortlisting text-lg-center text-md-start">
                            <Link to="/openjobs">
                              <a className="btn open-btn me-2">Open</a>
                            </Link>
                            <button className="btn paused-btn-1 me-2">
                              Paused
                            </button>
                            <button className="btn paused-btn-1 me-2">
                              Closed
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="grid-view">
                      <div className="row mb-5">
                        <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 mt-4">
                          <Link to="/hiring-2">
                            <div className="first-company card-effect">
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="text-holder-employer">
                                    <div className="d-flex mt-md-3 mt-0">
                                      <div className="me-2">
                                        <p className="nametag">SS</p>
                                      </div>
                                      <div className="">
                                        <h5 className="name-holder pt-1 ">
                                          Shweta Sharma
                                        </h5>
                                      </div>
                                      <div className="view-details ms-auto">
                                        <p>View</p>
                                      </div>
                                    </div>
                                  </div>
                                  <p className="job-post mt-md-3 mt-0">
                                    <FontAwesomeIcon
                                      icon="fa-solid fa-user"
                                      className="social-icon me-3"
                                    />
                                    Job Posted on 15/09/23
                                  </p>
                                  <p className="job-post mt-md-3 mt-0">
                                    <FontAwesomeIcon
                                      icon="fa-solid fa-location-dot"
                                      className="social-icon me-3"
                                    />
                                    Location: Mumbai
                                  </p>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 mt-4">
                          <Link to="/hiring-2">
                            <div className="first-company card-effect">
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="text-holder-employer">
                                    <div className="d-flex mt-md-3 mt-0 ">
                                      <div className="me-2">
                                        <p className="nametag">SS</p>
                                      </div>
                                      <div className="">
                                        <h5 className="name-holder pt-1 ">
                                          Shweta Sharma
                                        </h5>
                                      </div>
                                      <div className="view-details ms-auto">
                                        <p>View</p>
                                      </div>
                                    </div>
                                  </div>
                                  <p className="job-post mt-md-3 mt-0">
                                    <FontAwesomeIcon
                                      icon="fa-solid fa-user"
                                      className="social-icon me-3"
                                    />
                                    Job Posted on 15/09/23
                                  </p>
                                  <p className="job-post mt-md-3 mt-0">
                                    <FontAwesomeIcon
                                      icon="fa-solid fa-location-dot"
                                      className="social-icon me-3"
                                    />
                                    Location: Mumbai
                                  </p>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 mt-4">
                          <Link to="/hiring-2">
                            <div className="first-company card-effect">
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="text-holder-employer">
                                    <div className="d-flex mt-md-3 mt-0 ">
                                      <div className="me-2">
                                        <p className="nametag">SS</p>
                                      </div>
                                      <div className="">
                                        <h5 className="name-holder pt-1 ">
                                          Shweta Sharma
                                        </h5>
                                      </div>
                                      <div className="view-details ms-auto">
                                        <p>View</p>
                                      </div>
                                    </div>
                                  </div>
                                  <p className="job-post mt-md-3 mt-0">
                                    <FontAwesomeIcon
                                      icon="fa-solid fa-user"
                                      className="social-icon me-3"
                                    />
                                    Job Posted on 15/09/23
                                  </p>
                                  <p className="job-post mt-md-3 mt-0">
                                    <FontAwesomeIcon
                                      icon="fa-solid fa-location-dot"
                                      className="social-icon me-3"
                                    />
                                    Location: Mumbai
                                  </p>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 mt-4">
                          <Link to="/hiring-2">
                            <div className="first-company card-effect">
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="text-holder-employer">
                                    <div className="d-flex mt-md-3 mt-0 ">
                                      <div className="me-2">
                                        <p className="nametag">SS</p>
                                      </div>
                                      <div className="">
                                        <h5 className="name-holder pt-1 ">
                                          Shweta Sharma
                                        </h5>
                                      </div>
                                      <div className="view-details ms-auto">
                                        <p>View</p>
                                      </div>
                                    </div>
                                  </div>
                                  <p className="job-post mt-md-3 mt-0">
                                    <FontAwesomeIcon
                                      icon="fa-solid fa-user"
                                      className="social-icon me-3"
                                    />
                                    Job Posted on 15/09/23
                                  </p>
                                  <p className="job-post mt-md-3 mt-0">
                                    <FontAwesomeIcon
                                      icon="fa-solid fa-location-dot"
                                      className="social-icon me-3"
                                    />
                                    Location: Mumbai
                                  </p>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HiringFunnel;
