
import React from 'react';
import Success from '../../../RecruiterPage/Model/Animation/Success';
import Modal from 'react-bootstrap/Modal';

function EmailChangedModal(props) {
  return (
    <>
      <section >
        <Modal
          {...props}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          className='PopupModal'
          centered>
          <Modal.Body>
            <Success />
            <p className='modal_txt'> Your Email has been Changed Successfully!</p>
            <p className='text-center' style={{ color: "#FFFF" }}> Have a Great Time! </p>
          </Modal.Body>
        </Modal>
      </section>
    </>

  );
}

export default EmailChangedModal;  
