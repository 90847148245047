import { createContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { PacmanLoader } from "react-spinners";
import BarAnimation from "../Components/JobseekerPage/DashBoardJob/JobTab/JobTabAnimation/BarAnimation/BarAnimation";
import NodataAnimation from "../Components/JobseekerPage/DashBoardJob/JobTab/JobTabAnimation/NodataAnimation/NodataAnimation";

import {
  getData,
  postData,
  Select2Data,
  // getEditData,
  // editData,
  editStatusData,
  getJobSeekerData,
  // postFormData,
  PostGoogleLogin,
  deleteData,
  Select2Skills,
  Select2Roles,
} from "./api";
import { Bar } from "react-chartjs-2";

export const Context = createContext();

const AppContext = ({ children }) => {
  const navigate = useNavigate();

  const [signin, setSignin] = useState(false);
  const [language, setLanguage] = useState(1);
  const [usertype, setUsertype] = useState("");
  const [userdata, setUserData] = useState({});
  const [token, setToken] = useState("");

  useEffect(() => {
    isTokenValid();
  }, [signin]);

  const getuserData = async (id) => {
    const response = await getData(`/jobseeker/profile`);
    await setUserData(response?.data);
  };

  const isTokenValid = async () => {
    const token = Cookies.get("hiclousia_website");

    if (token) {
      // Decode the token to get the expiration time
      const decodedToken = JSON.parse(atob(token.split(".")[1]));
      const currentTime = Date.now() / 1000;

      // Check if the token has expired
      if (decodedToken.exp < currentTime) {
        Cookies.remove("hiclousia_website", { path: "/" });
        setSignin(false);
        setUsertype("");
        navigate("/");
      } else {
        getuserData(decodedToken.user);
        setUsertype(decodedToken.type);
        fetchSeekerData();
        setSignin(true);
      }
    } else {
      setSignin(false);
      setUsertype("");
      navigate("/");
    }
  };

  const [ReactLoader, setReactLoader] = useState(true);
  const [recruiterData, setRecruiterData] = useState({
    first_name: null,
    last_name: null,
    email: null,
    emailVerify: false,
    gender: null,
    dob: null,
    contact_no: null,
    mobileVerify: false,
    alternate: null,
    current_address: null,
    living_city_id: null,
    nationality_id: null,
    policy: false,
  });
  const [jobSeekerData, setJobSeekerData] = useState();
  const [seekerGender, setSeekerGender] = useState(null);

  const [jobSearch, setJobSearch] = useState("");
  const [sectorSearch, setSectorSearch] = useState("");
  const [citySearch, setCitySearch] = useState("");
  const [createdStatus, setCreatedStatus] = useState(false);

  const fetchSeekerData = () => {
    getJobSeekerData()
      .then((data) => {
        // console.log(data, "Job Seeker Data:");
        setSeekerGender(data?.users_detail?.gender);
        setJobSeekerData(data);
        return data;
      })
      .catch((error) => {
        console.error("Error fetching job seeker data:", error);
      });
  };

  const NoDataAnimations = BarAnimation;
  const BarAnimations = NodataAnimation;
  const PacmanLoaders = PacmanLoader;
  // const imageUrl = "http://192.168.14.111:1212";
  // const imageUrl = "http://127.0.0.1:1212";
  // const imageUrl = "https://hiclousianode.profcyma.org:4848";
  const imageUrl = "https://hiclousianode.profcymabackend.com:1212";

  return (
    <Context.Provider
      value={{
        Select2Roles,
        Select2Skills,
        PostGoogleLogin,
        getData,
        postData,
        recruiterData,
        setRecruiterData,
        jobSeekerData,
        setJobSeekerData,
        getJobSeekerData,
        fetchSeekerData,
        editStatusData,
        seekerGender,
        setSeekerGender,
        deleteData,
        signin,
        setSignin,
        usertype,
        setUsertype,
        userdata,
        token,
        setToken,
        imageUrl,
        Select2Data,
        jobSearch,
        setJobSearch,
        sectorSearch,
        setSectorSearch,
        citySearch,
        setCitySearch,
        ReactLoader,
        setReactLoader,
        PacmanLoaders,
        BarAnimations,
        NoDataAnimations,
        createdStatus,
        setCreatedStatus,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default AppContext;
