import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import './DesiredMobileView.css'



const DesiredMobileView = () => {
    const desiredData = [
        {
            imageSource: process.env.PUBLIC_URL + "assets/Images/logo/profcyma.png",
            companytitle: 'Profcyma Solution Pvt. Ltd',
            title: "Full Stack Developer",
            location: "Bavdan, Pune",
        },
        {
            imageSource: process.env.PUBLIC_URL + "assets/Images/logo/Tata.png",
            companytitle: 'Tata Consultancy Service',
            title: "Frond End Developer",
            location: "Hinjewadi, Pune",
        },
        {
            imageSource: process.env.PUBLIC_URL + "assets/Images/logo/Infosys.png",
            companytitle: 'Infosys',
            title: "Full Stack Developer",
            location: "Hinjewadi, Pune",
        },
        {
            imageSource: process.env.PUBLIC_URL + "assets/Images/logo/Capjemini.png",
            companytitle: 'Capjemini',
            title: "Full Stack Developer",
            location: "Pune",
        },
        {
            imageSource: process.env.PUBLIC_URL + "assets/Images/logo/Cognizant.png",
            companytitle: 'Cognizant',
            title: "Full Stack Developer",
            location: "Hinjewadi, Pune",
        },
        {
            imageSource: process.env.PUBLIC_URL + "assets/Images/logo/profcyma.png",
            companytitle: 'Profcyma Solution Pvt. Ltd',
            title: "Full Stack Developer",
            location: "Bavdan, Pune",
        },
    ];
    return (
        <>
            <section className="Featured_Category-mobile ">
                <div className='title-desired'>
                    <div className='row'>
                        <div className='col-10'>
                            <p className='text-center mt-3'>Your Desired Jobs Search</p>
                        </div>
                        <div className='col-2'>
                            <div className='filter-button mt-2'>
                               <Link to='/filter'><button className='filter-btn-main'><FontAwesomeIcon icon="fa-solid fa-filter" /></button></Link> 
                            </div>
                        </div>
                    </div>
                </div>
                {desiredData.map((desired, index) => (
                    <div className='mobile-view-desired-class'>
                        <div className='row' key={index}>
                            <div className='col-3'>
                                <div className='backgorund-desird'>
                                    <img src={desired.imageSource} className='company-img-desired' alt='...' />
                                </div>
                            </div>
                            <div className='col-8'>
                                <h2 className='company-title'>{desired.companytitle}</h2>
                            </div>
                            <div className='col-8 '>
                                <h2 className='company-title ms-2'>{desired.title}</h2>
                                <p className='company-location ms-2'><FontAwesomeIcon icon="fa-solid fa-location-dot" className="me-2" />{desired.location}</p>
                            </div>
                            <div className='col-4'>
                                <div className='text-end mt-3 me-2'>
                                    <Link to='/job-description'><button className='apply-btn-mobile'>Apply</button></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}

            </section>
        </>
    )
}

export default DesiredMobileView
