import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import FirstTab from "../FirstTab/FirstTab.js";
import SecondTab from "../SecondTab/SecondTab.js";
import ThirdTab from "../ThirdTab/ThirdTab.js";
import FourthTab from "../FourthTab/FourthTab.js";
import "../Tab/Tab.css";
import PostedJobs from "../PostedJobs/PostedJobs.js";
import ThirdTabMain from "../ThirdTab/ThirdTabMain.js";
import { useState } from "react";
import AppliedPortfolio from "../../../JobseekerPage/DashBoardJob/AppliedPortfolio/AppliedPortfolio.js";

const MainTab = () => {
  const [active, setActive] = useState(0);

  return (
    <>
      <section className="main-tabs">
        <div className="">
          <div className="row ms-0 me-0">
            <div className="col-md-12 p-0">
              <div className="main-design-tab">
                <Tabs defaultIndex={active}>
                  <div className="container p-0">
                    <TabList className="row mx-auto justify-content-center g-0 p-0 box-shhh">
                      <Tab className="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-3 text-center">
                        Dashboard
                      </Tab>
                      <Tab className="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-3 text-center">
                        Manage Jobs
                      </Tab>
                      <Tab className="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-3 text-center">
                        Applied Portfolios
                      </Tab>
                      <Tab className="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-3 text-center">
                        Matched Portfolios
                      </Tab>

                      <Tab className="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-3 text-center">
                        Portfolios
                      </Tab>
                    </TabList>
                  </div>
                  {/* Firsttab */}
                  <TabPanel>
                    <FirstTab />
                    <PostedJobs />
                  </TabPanel>

                  {/* Secondtab */}
                  <TabPanel>
                    <SecondTab />
                  </TabPanel>

                  {/* Thirdtab */}
                  <TabPanel>
                    <AppliedPortfolio />
                  </TabPanel>

                  <TabPanel>
                    <ThirdTabMain />
                  </TabPanel>

                  {/* Fourthtab */}
                  <TabPanel>
                    <FourthTab />
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MainTab;
