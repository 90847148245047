import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import '../AppliedJobs/AppliedJobs.css'
import { Context } from "../../../../utils/context";
import JobDescriptionModal from "../../JobDescriptionModel/JobDescriptionModal";

const SixthJobMobile = (props) => {
    const [modalShow, setModalShow] = useState(false);
    const [data, setData] = useState({});
    const { getData, imageUrl, NoDataAnimations } = useContext(Context);

    const [applied, setApplied] = useState();
    const getAppliedJobs = async () => {
        const res = await getData(`/jobseeker/applyed`);
        if (res?.success) {
            setApplied(res.data);
        }
    }


    useEffect(() => {
        getAppliedJobs();
    }, []);
    return (
        <>
            <section className="Sixth-job-tab-mobile">




                <div className='sixth-job-applied'>
                    <div className="row">
                        <div className="col-md-12 p-0">
                            <div className="tittle-sixth-job-tab">
                                {/* <h5 className="text-center mt-3">Applied Jobs</h5> */}
                                <div className="desktop-view">
                                    <Link to='/dashboard-job'><a>Go to Dashboard</a></Link>
                                </div>
                            </div>
                            <div className="col-md-12 mt-md-4">
                                {applied?.map((value, index) => (
                                    <div className="sixthjob-card mb-5">
                                        <div className="row">
                                            <div className="col-xxl-10 col-xl-10 col-lg-9 col-md-10">
                                                <span className="appliedJObs_title">{value?.job?.job_title?.name}</span>
                                                <span style={{ color: "#1967D3" }}>(Applied)</span>
                                            </div>
                                            <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-2">
                                                <div>
                                                    <button className="view_btn" onClick={() => {
                                                        setData(value?.job);
                                                        setModalShow(true);
                                                    }}>View</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-xxl-10 col-xl-10 col-lg-9 col-md-8">
                                                <div>
                                                    <a className="company-name">{value?.job?.company?.name}</a>
                                                </div>
                                                <div className="d-md-flex">
                                                    {/* <p className="text-holder-sixthjob"><FontAwesomeIcon icon="fa-solid fa-star" /><FontAwesomeIcon icon="fa-solid fa-star" /><FontAwesomeIcon icon="fa-solid fa-star" /><FontAwesomeIcon icon="fa-solid fa-star" /><FontAwesomeIcon icon="fa-solid fa-star" /> 9,408 reviews</p> */}
                                                    <p className="text-holder-sixthjob"><FontAwesomeIcon icon="fa-solid fa-location-dot" className="me-2 ms-2" />
                                                    {value?.job?.job_work_locations?.slice(0, 2).map((type, i) => (
                                                                type?.city?.name
                                                            )).join(', ')}
                                                    </p>
                                                </div>
                                            </div>
                                            {/* <div className="col-md-2">
                                                    <div className="mt-4">
                                                        <p className="text-holder-sixthjob">Status:Active</p>
                                                    </div>
                                                </div> */}
                                            <div className="col-md-12">
                                                <div className="d-flex appplied_date">
                                                    {value?.job?.application_status_histories?.map((status, statusIndex) => (
                                                        <p className="text-holder-sixthjob">{status?.application_status?.name} : {new Date(status?.createdAt).toLocaleDateString(
                                                            "en-US",
                                                            {
                                                                day: "2-digit",
                                                                month: "short",
                                                                year: "numeric",
                                                            }
                                                        )}

                                                            {statusIndex !== value?.job?.application_status_histories.length - 1 && (
                                                                <img src={process.env.PUBLIC_URL + "assets/Images/icons/blue-line.png"} className="blueline" alt="..." />
                                                            )}
                                                        </p>
                                                    ))}
                                                    {/* <p className="text-holder-sixthjob">Applied on: 25/09/2023 <img src={process.env.PUBLIC_URL + "assets/Images/icons/blue-line.png"} className="blueline" alt="..." /></p>

                                                        <p className="text-holder-sixthjob">Applied Viewed on: 25/09/2023  <img src={process.env.PUBLIC_URL + "assets/Images/icons/blue-line.png"} className="blueline" alt="..." /></p>
                                                        <p className="text-holder-sixthjob">Contacted via: Mail  <img src={process.env.PUBLIC_URL + "assets/Images/icons/blue-line.png"} className="blueline" alt="..." /></p>
                                                        <p className="text-holder-sixthjob">Status: Shortlisted</p> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                {applied?.length == 0 && (
                                    <NoDataAnimations />
                                )}
                                <JobDescriptionModal
                                    show={modalShow}
                                    data={data}
                                    title={"Job Description"}
                                    onHide={() => setModalShow(false)}
                                />

                                {/* <div className="sixthjob-card mt-4">
                                            <div className="row">
                                                <div className="col-xxl-10 col-xl-10 col-lg-9 col-md-10">
                                                    <span className="appliedJObs_title">Graphic % UI Designer </span>
                                                    <span style={{ color: "#1967D3" }}>(Applied)</span>
                                                </div>
                                                <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-2">
                                                    <div>

                                                        <Link to='/appliedJob'><button className="view_btn">View</button></Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-xxl-10 col-xl-10 col-lg-9 col-md-8">
                                                    <div>
                                                        <a className="company-name">Profcyma Solutions PVT. Ltd.</a>
                                                    </div>
                                                    <div className="d-flex">
                                                        <p className="text-holder-sixthjob"><FontAwesomeIcon icon="fa-solid fa-star" /><FontAwesomeIcon icon="fa-solid fa-star" /><FontAwesomeIcon icon="fa-solid fa-star" /><FontAwesomeIcon icon="fa-solid fa-star" /><FontAwesomeIcon icon="fa-solid fa-star" /> 9,408 reviews</p>
                                                        <p className="text-holder-sixthjob"><FontAwesomeIcon icon="fa-solid fa-location-dot" className="me-2 ms-2" /> Bavdan,Pune</p>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="mt-4">
                                                        <p className="text-holder-sixthjob">Status:Active</p>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="d-flex appplied_date">
                                                        <p className="text-holder-sixthjob">Applied on: 25/09/2023 <img src={process.env.PUBLIC_URL + "assets/Images/icons/blue-line.png"} className="blueline" alt="..." /></p>
                                                        <p className="text-holder-sixthjob">Applied Viewed on: 25/09/2023  <img src={process.env.PUBLIC_URL + "assets/Images/icons/blue-line.png"} className="blueline" alt="..." /></p>
                                                        <p className="text-holder-sixthjob">Contacted via: Mail  <img src={process.env.PUBLIC_URL + "assets/Images/icons/blue-line.png"} className="blueline" alt="..." /></p>
                                                        <p className="text-holder-sixthjob">Status: Shortlisted</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="sixthjob-card mb-5 mt-4">
                                            <div className="row">
                                                <div className="col-xxl-10 col-xl-10 col-lg-9 col-md-10">
                                                    <span className="appliedJObs_title">Graphic % UI Designer </span>
                                                    <span style={{ color: "#1967D3" }}>(Applied)</span>
                                                </div>
                                                <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-2">
                                                    <div>

                                                        <Link to='/appliedJob'><button className="view_btn">View</button></Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-xxl-10 col-xl-10 col-lg-9 col-md-8">
                                                    <div>
                                                        <a className="company-name">Profcyma Solutions PVT. Ltd.</a>
                                                    </div>
                                                    <div className="d-flex">
                                                        <p className="text-holder-sixthjob"><FontAwesomeIcon icon="fa-solid fa-star" /><FontAwesomeIcon icon="fa-solid fa-star" /><FontAwesomeIcon icon="fa-solid fa-star" /><FontAwesomeIcon icon="fa-solid fa-star" /><FontAwesomeIcon icon="fa-solid fa-star" /> 9,408 reviews</p>
                                                        <p className="text-holder-sixthjob"><FontAwesomeIcon icon="fa-solid fa-location-dot" className="me-2 ms-2" /> Bavdan,Pune</p>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="mt-4">
                                                        <p className="text-holder-sixthjob">Status:Active</p>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="d-flex appplied_date">
                                                        <p className="text-holder-sixthjob">Applied on: 25/09/2023 <img src={process.env.PUBLIC_URL + "assets/Images/icons/blue-line.png"} className="blueline" alt="..." /></p>
                                                        <p className="text-holder-sixthjob">Applied Viewed on: 25/09/2023  <img src={process.env.PUBLIC_URL + "assets/Images/icons/blue-line.png"} className="blueline" alt="..." /></p>
                                                        <p className="text-holder-sixthjob">Contacted via: Mail  <img src={process.env.PUBLIC_URL + "assets/Images/icons/blue-line.png"} className="blueline" alt="..." /></p>
                                                        <p className="text-holder-sixthjob">Status: Shortlisted</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </>
    )
}

export default SixthJobMobile;