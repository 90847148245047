import React, { useState, useEffect } from "react";
import "./Myportfolio.css";
import ChartSection from "./chart-section/ChartSection";
import ExperienceSection from "./Experience-section/ExperienceSection";
import PrimarySkill from "./Primary-skill/PrimarySkill";

const MyPortfolio = () => {
  return (
    <>
      <section className="my-portfolio">
        <div className="container">
          <ChartSection />
          <ExperienceSection />
          <PrimarySkill />
        </div>
      </section>
    </>
  );
};

export default MyPortfolio;
