import React from "react";
import "./UserProfile.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const UserTalentPool = () => {
  return (
    <>
      {/* First section */}
      <section className="user-profile">
        <div className="container">
          <div className="row">
            <div className="col-md-2">
              <img
                src={
                  process.env.PUBLIC_URL +
                  "public/assets/Images/banner/user-profile.png"
                }
                className="user-profile"
              />
            </div>
            <div className="col-md-10">
              <div className="text-holder-company-profile">
                <h3 className="user-name">Shashikant Kumar</h3>
                <p>HR Manager, at Hiclousia</p>
              </div>
              <div className="">
                <p className="text-holder-userprofile">
                  <FontAwesomeIcon icon="fa-solid fa-phone" /> 9876543210
                </p>
                <p className="text-holder-userprofile">
                  <FontAwesomeIcon icon="fa-solid fa-envelope" />{" "}
                  SKrecruiter@hiclousia.com
                </p>
                <p className="text-holder-userprofile">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "public/assets/Images/icons/twitter.png"
                    }
                    className="social-user-profile"
                  />{" "}
                  https://linkedin.com/in/Shashikant Kumar
                </p>
                <p className="text-holder-userprofile">
                  {" "}
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "public/assets/Images/icons/linkedin.png"
                    }
                    className="social-user-profile"
                  />{" "}
                  https://twitter.com/Shashikant Kumar
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Second section */}
      <section className="user-profile-talent">
        <div className="container">
          {/* Add company */}
          <div className="row mt-5 ">
            <div className="col-md-12 text-center">
              <h2 className="title-userprofile">My Talents Pool</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="section-title mt-3">
                <div className="row">
                  <div className=" col-xxl-1 col-xl-1 col-lg-2 col-md-2 mt-3">
                    <Link to="/user-profile">
                      <a className="title-open-jobs ">Go to Profile</a>
                    </Link>
                  </div>
                  <div className=" col-xxl-11 col-xl-11 col-lg-10 col-md-10 mt-lg-2 mt-md-3 ">
                    <div className="left-black-line"></div>
                  </div>
                  <div className="col-md-12 ">
                    <div className="post-new-job mt-4"></div>
                  </div>
                  <div className="col-md-1 col-1">
                    <p className="silver-text-talent-pool">
                      Silver (S1) Talent Pool
                    </p>
                  </div>
                  <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-8 col-7"></div>
                  <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-3 col-4 text-end mt-1">
                    <Link to="/talentPool">
                      <button className="btn btn-primary Draft-btn">
                        Add Pool
                      </button>
                    </Link>
                  </div>
                  <div className="col-md-12">
                    <div className="grid-view">
                      <div className="row mb-5">
                        <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-6">
                          <div className="first-company">
                            <div className="row">
                              <div className="col-md-11">
                                <div className="text-holder-employer">
                                  <h5 className="position">
                                    Profcyma Solutions Pvt. Ltd.{" "}
                                  </h5>
                                  <p className="job-post mt-3">
                                    <FontAwesomeIcon
                                      icon="fa-solid fa-briefcase"
                                      className="me-2"
                                    />
                                    Number Job Post : 1
                                  </p>
                                  <p className="job-post mt-3">
                                    <FontAwesomeIcon
                                      icon="fa-solid fa-calendar-days"
                                      className="me-2"
                                    />
                                    Duration : 15 Days
                                  </p>
                                  <p className="job-post mt-3">
                                    <FontAwesomeIcon
                                      icon="fa-solid fa-clock "
                                      className="me-2"
                                    />
                                    Timer : 10 Days left
                                  </p>
                                </div>
                              </div>
                              <div className="col-md-1 text-end ">
                                <div className="btn-group secondTab_dropdown">
                                  <Link
                                    className="nav-link"
                                    exact="true"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    id="actionButton"
                                    activeclassname="active"
                                  >
                                    <FontAwesomeIcon icon="fa-solid fa-ellipsis-vertical" />
                                  </Link>
                                  <ul
                                    className="dropdown-menu dropdown-menu-end p-0"
                                    aria-labelledby="actionButton"
                                  >
                                    <li>
                                      <Link
                                        className="dropdown-item"
                                        to={"/editjobs"}
                                      >
                                        Edit Job details
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        className="dropdown-item"
                                        to={"/viewjobs"}
                                      >
                                        View Job details
                                      </Link>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default UserTalentPool;
