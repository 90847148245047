import React, { useEffect, useState, useRef } from "react";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import Tab from "react-bootstrap/Tab";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";

import { useContext } from "react";
import { Context } from "../../../../utils/context";
const Step7 = ({ handlePrevious, handleNext }) => {
  const [lgShow, setLgShow] = useState(false);
  const handleClose = () => setLgShow(false);
  const handleShow = () => setLgShow(true);

  const educationTabRef = useRef(null);

  const {
    getData,
    postData,
    recruiterData,
    jobSeekerData,
    editStatusData,
    getJobSeekerData,
    Select2Data,
    deleteData,
    fetchSeekerData,
    Select2Skills,
  } = useContext(Context);

  const [skills, setSkills] = useState([]);
  const [buttonStatus, setButtonStatus] = useState(false);
  const [achievemente, setAchievemente] = useState([]);
  const [certificateFor, setCertificateFor] = useState([]);
  const [selectedskills, setSelectedSkills] = useState([]);
  const [errors, setErrors] = useState();

  const [cards, setCards] = useState([]);
  const [status, setStatus] = useState("none");

  const [formData, setFormData] = useState({
    id: jobSeekerData?.id,
    step: 8,
    evidance: undefined,
    achievement_id: "",
    title: "",
    issuing_org: "",
    start_date: "",
    end_date: "",
    certificate_for_id: "",
    main_topics: "",
    skills: [],
  });

  const validateForm = () => {
    let errors = {};
    const regexAlfaNumric = /^[A-Za-z0-9]+(\s[A-Za-z0-9]+)*$/;
    const regexAlfa = /^[A-Za-z]+(\s[A-Za-z]+)*$/;

    if (!formData.title) {
      errors.title = "Title is required";
    } else if (!regexAlfaNumric.test(formData.title?.trim())) {
      errors.title = "Enter a valid data";
    }

    if (formData?.evidance === undefined) {
      errors.evidance = "Evidance is required";
    }

    // if (!formData.issuing_org) {
    //   errors.issuing_org = "Issuing Organization is required";
    // } else if (!regexAlfaNumric.test(formData.issuing_org?.trim())) {
    //   errors.issuing_org = "Enter a valid data";
    // }

    if (!formData.start_date) {
      errors.start_date = "Start Date is required";
    }

    if (!formData.end_date) {
      errors.end_date = "End Date is required";
    } else if (new Date(formData.end_date) < new Date(formData.start_date)) {
      errors.end_date = "End Date cannot be a past date of Start Date";
    } else if (new Date(formData.end_date) > new Date()) {
      errors.end_date = "End Date cannot be a future date";
    }

    // if (!formData.certificate_for_id) {
    //   errors.certificate_for_id = "Certificate For is required";
    // }

    if (!formData.main_topics) {
      errors.main_topics = "Main Topics is required";
    }

    if (!formData.achievement_id) {
      errors.achievement_id = "Achievement is required";
    }
    if (selectedskills.length === 0) {
      errors.skills = "Skills is required";
    }

    return errors;
  };

  const handleChange = (e) => {
    if (e?.name === "achievement_id" || e?.name === "certificate_for_id") {
      setFormData({ ...formData, [e.name]: e });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const getAllData = async () => {
    {
      const res = await getData("/without-login/master/all-achievemente");
      setAchievemente(await Select2Data(res.data, "achievement_id"));
    }
    {
      const res = await getData("/without-login/master/all-skills");
      setSkills(await Select2Skills(res.data, "skills"));
    }
    {
      const res = await getData("/without-login/master/all-certificate-for");
      setCertificateFor(await Select2Data(res.data, "certificate_for_id"));
    }
  };

  const getAllCards = async () => {
    if (jobSeekerData?.id) {
      const res = await getData(`/jobseeker/signup/certificate`);
      if (res?.success) {
        await setCards(res?.data);
        if (res?.data.length !== 0) {
          setStatus("none");
        } else {
          setStatus(true);
        }
      }
    }
  };

  useEffect(() => {
    getAllData();
    getAllCards();
  }, [jobSeekerData?.id]);

  useEffect(() => {
    setFormData({
      ...formData,
      ["skills"]: selectedskills?.map((item) => item.value),
    });
  }, [selectedskills]);

  const handleSubmit = async (action) => {
    await setButtonStatus(true);
    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      if (action === "continue") {
        if (cards.length > 0) {
          handleNext();
        }
      }
      await setButtonStatus(false);
    } else {
      try {
        const finalData = new FormData();
        finalData.append(`evidance`, formData.evidance);
        finalData.append(`id`, formData.id);
        finalData.append(`step`, formData.step);
        finalData.append(`achievement_id`, formData.achievement_id?.value);
        finalData.append(`title`, formData.title);
        finalData.append(`issuing_org`, formData.issuing_org);
        finalData.append(`start_date`, formData.start_date);
        finalData.append(`end_date`, formData.end_date);
        if (formData.certificate_for_id) {
          finalData.append(
            `certificate_for_id`,
            formData.certificate_for_id?.value
          );
        }
        finalData.append(`main_topics`, formData.main_topics);
        finalData.append(`skills`, JSON.stringify(formData.skills));
        finalData.append(`status`, status);

        const res = await postData("/jobseeker/signup/certificate", finalData);

        if (res?.success) {
          await setFormData({
            id: jobSeekerData?.id,
            step: 8,
            evidance: undefined,
            achievement_id: "",
            title: "",
            issuing_org: "",
            start_date: "",
            end_date: "",
            certificate_for_id: "",
            main_topics: "",
            skills: [],
          });

          await setSelectedSkills([]);

          await setErrors();
          await getAllCards();
          await fetchSeekerData();
          if (action === "continue") {
            handleNext();
          }
          await setButtonStatus(false);
        }
      } catch (error) {
        // console.log(error);
        await setButtonStatus(false);
      }
    }
  };

  const handleDelete = async (id) => {
    var data = "";
    if (cards.length === 1) {
      var data = {
        step: 7,
        status: false,
      };
    } else {
      var data = {
        step: 8,
        status: "none",
      };
    }
    const res = await editStatusData(
      `/jobseeker/signup/certificate/${id}`,
      data
    );

    await getAllCards();
    await fetchSeekerData();
  };

  const errorStyle = {
    color: "red",
    marginLeft: "5px",
    // fontSize: "11px",
  };

  return (
    <>
      <section className="eightstep  step-seven text-start StepForm">
        <div className="container">
          <div className="d-flex  steps-heading">
            <div className="border-left" />
            <h5 className="stepsixtxt">Step 7</h5>
          </div>
          <p className="title">Add Your Certificate</p>
          <div className="desktop-view-navitem">
            <Tab.Container defaultActiveKey="home">
              <Row>
                <Col md={12}>
                  <Nav variant="pills">
                    {/* {qualifications?.map((value) => (
                      <Nav.Item>
                        <Nav.Link
                          className={
                            education_type_id == value?.id ? "active" : ""
                          }
                          onClick={() => typeChange(value?.id)}
                        >
                          {value?.name}
                        </Nav.Link>
                      </Nav.Item>
                    ))} */}
                  </Nav>
                </Col>

                <Tab.Content>
                  <Tab.Pane eventKey="home"></Tab.Pane>
                  <Tab.Pane eventKey="link-2">Second tab content</Tab.Pane>
                </Tab.Content>
                <Form>
                  <Row className="">
                    {cards?.map((value, index) => (
                      <Col
                        className="col-md-5 col-lg-3 col-xl-3 col-xxl-3 col-12 mt-2"
                        key={index}
                      >
                        <div className="msc-box">
                          <div className="tabs-btn p-2">
                            <div className="d-flex justify-content-end">
                              {/* <FontAwesomeIcon
                                icon="fa-solid fa-pen-to-square"
                                className="me-3"
                                style={{ cursor: "pointer" }}
                                onClick={() => setLgShow(true)} /> */}
                              <p className="text-para">
                                {value?.degree?.name}
                                <FontAwesomeIcon
                                  icon={faXmark}
                                  className="close-icon"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => handleDelete(value?.id)}
                                />
                              </p>
                            </div>
                            <div className="info ">
                              <p>Title : {value?.title}</p>
                              <p>Issuing Organization: {value?.issuing_org}</p>
                              <p>Start Date : {value?.start_date}</p>
                              <p>End Date : {value?.end_date}</p>
                              <p>
                                Certificate For : {value?.certificate_for?.name}
                              </p>
                              <p>Main Topics : {value?.main_topics}</p>
                              <p>Achievemente : {value?.achievement?.name}</p>
                              <p>
                                Skills :
                                {value?.jobseeker_certificate_skills?.map(
                                  (skill) => skill?.skill?.name + ", "
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                      </Col>
                    ))}
                  </Row>

                  <Row className="mt-4">
                    <div className="col-md-6 col-sm-12 mb-3">
                      <Form.Label>
                        Title <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Title"
                        name="title"
                        value={formData?.title}
                        onChange={handleChange}
                      />
                      {errors?.title && (
                        <span style={errorStyle}>{errors?.title}</span>
                      )}
                    </div>

                    <div className="col-md-6 col-sm-12 mb-3">
                      <Form.Label>
                        Issuing Organization{" "}
                        <span className="text-danger"></span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Issuing Organization"
                        name="issuing_org"
                        value={formData?.issuing_org}
                        onChange={handleChange}
                      />
                      {errors?.issuing_org && (
                        <span style={errorStyle}>{errors?.issuing_org}</span>
                      )}
                    </div>

                    <div className="col-md-3 col-sm-6 mb-3">
                      <Form.Label>
                        Start Date <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="date"
                        placeholder="Start Date"
                        name="start_date"
                        value={formData?.start_date}
                        onChange={handleChange}
                      />
                      {errors?.start_date && (
                        <span style={errorStyle}>{errors?.start_date}</span>
                      )}
                    </div>

                    <div className="col-md-3 col-sm-6 mb-3">
                      <Form.Label>
                        End Date <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="date"
                        placeholder="End Date"
                        name="end_date"
                        value={formData?.end_date}
                        onChange={handleChange}
                      />
                      {errors?.end_date && (
                        <span style={errorStyle}>{errors?.end_date}</span>
                      )}
                    </div>

                    <div className="col-md-6 col-sm-12 mb-3">
                      <Form.Label>
                        Certificate For <span className="text-danger"></span>
                      </Form.Label>
                      <Select
                        name="certificate_for_id"
                        classNamePrefix="certificate"
                        placeholder="Enter Certificate For"
                        value={formData?.certificate_for_id}
                        onChange={handleChange}
                        options={certificateFor}
                      />
                      {/* <Form.Control
                        type="text"
                        placeholder="Enter Certificate For"
                        name="certificate_for"
                        value={formData?.certificate_for}
                        onChange={handleChange}
                      /> */}
                      {errors?.certificate_for_id && (
                        <span style={errorStyle}>
                          {errors?.certificate_for_id}
                        </span>
                      )}
                    </div>

                    <div className="col-md-6 col-sm-12 mb-3">
                      <Form.Label>
                        Main Topics <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Main Topics"
                        name="main_topics"
                        value={formData?.main_topics}
                        onChange={handleChange}
                      />
                      {errors?.main_topics && (
                        <span style={errorStyle}>{errors?.main_topics}</span>
                      )}
                    </div>

                    <div className="col-md-6 col-sm-12 mb-3">
                      <Form.Label>
                        Achievements<span className="text-danger">*</span>{" "}
                      </Form.Label>

                      <Select
                        name="achievement_id"
                        classNamePrefix="achievements"
                        placeholder="Enter Degree"
                        value={formData?.achievement_id}
                        onChange={handleChange}
                        options={achievemente}
                      />
                      {errors?.achievement_id && (
                        <span style={errorStyle}>{errors?.achievement_id}</span>
                      )}
                    </div>

                    <div className="col-md-6 col-sm-12 mb-3">
                      <Form.Label>
                        Skills<span className="text-danger">*</span>{" "}
                      </Form.Label>

                      <Select
                        isMulti={true}
                        classNamePrefix="skills"
                        name="skills"
                        placeholder="Selecte Skills"
                        value={selectedskills}
                        onChange={(e) => {
                          setSelectedSkills(e);
                        }}
                        options={skills}
                      />
                      {errors?.skills && (
                        <span style={errorStyle}>{errors?.skills}</span>
                      )}
                    </div>

                    <div className="col-md-6 col-sm-12 mb-3">
                      <Form.Label>
                        Upload Evidence<span className="text-danger">*</span>{" "}
                      </Form.Label>

                      <Form.Control
                        type="file"
                        placeholder="Enter Evidance"
                        name="evidance"
                        accept="application/pdf"
                        onChange={async (e) => {
                          await setFormData({
                            ...formData,
                            [e.target.name]: e.target.files[0],
                          });
                        }}
                      />
                      {errors?.evidance && (
                        <span style={errorStyle}>{errors?.evidance}</span>
                      )}
                    </div>
                  </Row>

                  <div className="text-end">
                    <Button
                      className="education-btn"
                      onClick={() => handleSubmit("add")}
                    >
                      Add Certificate
                    </Button>
                  </div>
                </Form>
              </Row>
            </Tab.Container>
          </div>

          {/* Mobile View */}
          <div className="mobile-view-navitem">
            <Tab.Container defaultActiveKey="home" className=" ">
              <Nav variant="pills">
                <Swiper
                  slidesPerView={1}
                  spaceBetween={10}
                  pagination={{
                    clickable: true,
                  }}
                  breakpoints={{
                    0: {
                      slidesPerView: 1,
                    },
                    360: {
                      slidesPerView: 2,
                    },
                    640: {
                      slidesPerView: 2,
                    },
                    767: {
                      slidesPerView: 3,
                    },
                  }}
                  // loop={true}

                  modules={[Pagination]}
                >
                  {/* {qualifications?.map((value) => (
                    <SwiperSlide>
                      <Nav.Item className=" mb-5">
                        <Nav.Link eventKey="home"> {value?.name} </Nav.Link>
                      </Nav.Item>
                    </SwiperSlide>
                  ))} */}
                </Swiper>
              </Nav>
              <Row>
                <Tab.Content>
                  <Tab.Pane eventKey="home">
                    <Form>
                      {cards?.map((value) => (
                        <Col className="col-md-5 col-lg-3 col-xl-3 col-xxl-3 col-12 ">
                          <div className="msc-box">
                            <div className="tabs-btn p-2">
                              <p lassName="text-para">
                                {value?.degree?.name}
                                {/* <FontAwesomeIcon
                                  icon={faXmark}
                                  className="close-icon"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => handleDelete(value?.id)}
                                /> */}
                              </p>
                              <div className="info">
                                <p>Title : {value?.title}</p>
                                <p>
                                  Issuing Organization: {value?.issuing_org}
                                </p>
                                <p>Start Date : {value?.start_date}</p>
                                <p>End Date : {value?.end_date}</p>
                                <p>
                                  Certificate For :{" "}
                                  {value?.certificate_for?.name}
                                </p>
                                <p>Main Topics : {value?.main_topics}</p>
                                <p>Achievemente : {value?.achievement?.name}</p>
                                <p>
                                  Skills :
                                  {value?.jobseeker_certificate_skills?.map(
                                    (skill) => skill?.skill?.name + ", "
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>
                        </Col>
                      ))}
                      <Row>
                        <div className="col-md-6 col-sm-12 mb-3">
                          <Form.Label>
                            Title <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Title"
                            name="title"
                            value={formData?.title}
                            onChange={handleChange}
                          />
                          {errors?.title && (
                            <span style={errorStyle}>{errors?.title}</span>
                          )}
                        </div>

                        <div className="col-md-6 col-sm-12 mb-3">
                          <Form.Label>
                            Issuing Organization{" "}
                            <span className="text-danger"></span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Issuing Organization"
                            name="issuing_org"
                            value={formData?.issuing_org}
                            onChange={handleChange}
                          />
                          {errors?.issuing_org && (
                            <span style={errorStyle}>
                              {errors?.issuing_org}
                            </span>
                          )}
                        </div>

                        <div className="col-md-3 col-sm-6 mb-3">
                          <Form.Label>
                            Start Date <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            type="date"
                            placeholder="Start Date"
                            name="start_date"
                            value={formData?.start_date}
                            onChange={handleChange}
                          />
                          {errors?.start_date && (
                            <span style={errorStyle}>{errors?.start_date}</span>
                          )}
                        </div>

                        <div className="col-md-3 col-sm-6 mb-3">
                          <Form.Label>
                            End Date <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            type="date"
                            placeholder="End Date"
                            name="end_date"
                            value={formData?.end_date}
                            onChange={handleChange}
                          />
                          {errors?.end_date && (
                            <span style={errorStyle}>{errors?.end_date}</span>
                          )}
                        </div>

                        <div className="col-md-6 col-sm-12 mb-3">
                          <Form.Label>
                            Certificate For{" "}
                            <span className="text-danger"></span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Certificate For"
                            name="certificate_for_id"
                            value={formData?.certificate_for_id}
                            onChange={handleChange}
                          />
                          {errors?.certificate_for_id && (
                            <span style={errorStyle}>
                              {errors?.certificate_for_id}
                            </span>
                          )}
                        </div>

                        <div className="col-md-6 col-sm-12 mb-3">
                          <Form.Label>
                            Main Topics <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Main Topics"
                            name="main_topics"
                            value={formData?.main_topics}
                            onChange={handleChange}
                          />
                          {errors?.main_topics && (
                            <span style={errorStyle}>
                              {errors?.main_topics}
                            </span>
                          )}
                        </div>

                        <div className="col-md-6 col-sm-12 mb-3">
                          <Form.Label>
                            Achievemente<span className="text-danger">*</span>{" "}
                          </Form.Label>

                          <Select
                            name="achievement_id"
                            placeholder="Enter Degree"
                            value={formData?.achievement_id}
                            onChange={handleChange}
                            options={achievemente}
                          />
                          {errors?.achievement_id && (
                            <span style={errorStyle}>
                              {errors?.achievement_id}
                            </span>
                          )}
                        </div>

                        <div className="col-md-6 col-sm-12 mb-3">
                          <Form.Label>
                            Skills<span className="text-danger">*</span>{" "}
                          </Form.Label>

                          <Select
                            isMulti={true}
                            name="skills"
                            placeholder="Selecte Skills"
                            value={selectedskills}
                            onChange={(e) => {
                              setSelectedSkills(e);
                            }}
                            options={skills}
                          />
                          {errors?.skills && (
                            <span style={errorStyle}>{errors?.skills}</span>
                          )}
                        </div>

                        <div className="col-md-6 col-sm-12 mb-3">
                          <Form.Label>
                            Upload Evidence
                            <span className="text-danger">*</span>{" "}
                          </Form.Label>

                          <Form.Control
                            type="file"
                            placeholder="Enter Evidance"
                            name="evidance"
                            accept="application/pdf"
                            onChange={async (e) => {
                              await setFormData({
                                ...formData,
                                [e.target.name]: e.target.files[0],
                              });
                            }}
                          />
                          {errors?.evidance && (
                            <span style={errorStyle}>{errors?.evidance}</span>
                          )}
                        </div>
                      </Row>
                    </Form>
                  </Tab.Pane>
                  <Tab.Pane eventKey="link-2">Second tab content</Tab.Pane>
                </Tab.Content>
              </Row>
              <div className="text-end">
                <Button
                  disabled={buttonStatus}
                  className="education-btn"
                  onClick={() => handleSubmit("add")}
                >
                  Add Certificate
                </Button>
              </div>
            </Tab.Container>
          </div>
          <div className=" mobile-view-button mt-5">
            <div className="d-flex">
              {/* <div className="">
                <Button className="btn education-btn1" onClick={handleSubmit}>
                  Add Education
                </Button>
              </div> */}
              <div className=" me-1">
                <button
                  type="button"
                  name="next"
                  // disabled={cards?.length === 0 ? true : false}
                  className="next action-button contin-btn"
                  onClick={handleNext}
                >
                  Skip
                </button>
              </div>
              <div className="ms-auto ">
                <button
                  disabled={buttonStatus}
                  type="button"
                  name="next"
                  // disabled={cards?.length === 0 ? true : false}
                  className="next action-button contin-btn"
                  onClick={() => handleSubmit("continue")}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>

          <div className="row mt-5 pt-4 footer-sec">
            <div className="col-md-8 col-12 desktop-view-button">
              {/* <div className="check-row text-start">
                <span>
                  <Form.Check inline name="group1" className="me-1" />
                </span>
                <span className=" by-text ">By signing up, I agree to the</span>
                <span className="px-1 ">
                  <Link to="/terms" className="term-btn">
                    Term and Conditions
                  </Link>
                </span>
                <br />
              </div>
              <div className="login-row text-start">
                <span className="">Already an User?</span>
                <span className="px-1 ">
                  <Link to="/other-page" className="login-btn">
                    log In
                  </Link>
                </span>
                &nbsp; &nbsp; &nbsp;
                <span className="">Not a Jobseeker?</span>
                <span className="px-1 ">
                  <Link to="/signup-recruiter" className="signup-btn">
                    Signup as Recruiter
                  </Link>
                </span>
              </div> */}
            </div>
            {/* <div className=" col-12 mb-5  mobile-view-button">
              <div className="check-row text-start">
                <span>
                  <Form.Check inline name="group1" className="me-1" />
                </span>
                <span className=" by-text ">By signing up, I agree to the</span>
                <span className=" ">
                  <Link to="/terms" className="term-btn">
                    Term and Conditions
                  </Link>
                </span>
                <br />
              </div>
              <div className="login-row text-start">
                <span className="">Already an User?</span>
                <span className="px-1 ">
                  <Link to="/other-page" className="login-btn">
                    log In
                  </Link>
                </span>
                <br />
                <span className="">Not a Jobseeker?</span>
                <span className="px-1 ">
                  <Link to="/signup-recruiter" className="signup-btn">
                    Signup as Recruiter
                  </Link>
                </span>
              </div>
            </div> */}

            <div className="col-4 desktop-view-button">
              <div className="d-flex justify-content-end">
                <div className="">
                  <button
                    type="button"
                    name="next"
                    // disabled={cards?.length === 0 ? true : false}
                    className="next action-button contin-btn"
                    onClick={handleNext}
                  >
                    Skip
                  </button>
                </div>
                <div className="">
                  <button
                    disabled={buttonStatus}
                    type="button"
                    name="next"
                    // disabled={cards?.length === 0 ? true : false}
                    className="next action-button contin-btn"
                    onClick={() => handleSubmit("continue")}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Modal */}

      {/* <Modal size="lg" show={lgShow} onHide={() => setLgShow(false)} className="experience-modal" centered>
        <Modal.Header closeButton>
          <Modal.Title>Edit Certificate</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        
        </Modal.Body>
        <Modal.Footer className="mx-auto">
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button className="education-btn" onClick={handleSubmit}>
            Add Certificate
          </Button>
        </Modal.Footer>
      </Modal> */}
    </>
  );
};

export default Step7;
