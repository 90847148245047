import React, { useEffect, useState, useRef } from "react";
import { Form, Row, Col, Button, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import Tab from "react-bootstrap/Tab";
import Select from "react-select";
import Autosuggest from "react-autosuggest";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-bootstrap/Modal";
import { useContext } from "react";
import { Context } from "../../../../utils/context";
const Step7 = ({ handlePrevious, handleNext }) => {
  const [lgShow, setLgShow] = useState(false);
  const handleClose = () => setLgShow(false);
  const handleShow = () => setLgShow(true);

  const educationTabRef = useRef(null);

  const {
    getData,
    postData,
    Select2Data,
    jobSeekerData,
    editStatusData,
    fetchSeekerData,
  } = useContext(Context);

  const [errors, setErrors] = useState();
  const [buttonStatus, setButtonStatus] = useState(false);
  const [achievemente, setAchievemente] = useState([]);
  const [degree, setDegree] = useState([]);
  const [university, setUniversity] = useState([]);
  const [universityType, setUniversityType] = useState([]);
  const [degreeType, setDegreeType] = useState([]);
  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [selectedarea_of_studay, setSelectedArea_of_studay] = useState([]);
  const [desciples, setDesciples] = useState([]);
  const [type, setType] = useState([
    {
      value: "Grades",
      label: "Grades",
      name: "type_id",
    },
    {
      value: "GPA",
      label: "GPA",
      name: "type_id",
    },
  ]);

  const [cards, setCards] = useState([]);
  const [status, setStatus] = useState("none");

  const [formData, setFormData] = useState({
    step: 7,
    evidance: undefined,
    achievement_id: "",
    certificate_name: "",
    university_id: "",
    university_type_id: "",
    degree_id: "",
    degree_type_id: "",
    country_id: "",
    state_id: "",
    city_id: "",
    type_id: "",
    grades: "",
    degree_name: "",
    start_date: "",
    end_date: "",
    area_of_study: [],
    college_name: "",
    showCollegeInput: false,
    showDegreeInput: false,
  });

  const validateForm = () => {
    let errors = {};
    const regexAlfaNumric = /^[A-Za-z0-9]+(\s[A-Za-z0-9]+)*$/;
    const regexAlfa = /^[A-Za-z]+(\s[A-Za-z]+)*$/;

    if (!formData.degree_name) {
      errors.degree_name = "Degree Name is required";
    }

    if (!formData.start_date) {
      errors.start_date = "Start Date is required";
    }

    if (!formData.end_date) {
      errors.end_date = "End Date is required";
    } else if (new Date(formData.end_date) < new Date(formData.start_date)) {
      errors.end_date = "End Date cannot be a past date of Start Date";
    } else if (new Date(formData.end_date) > new Date()) {
      errors.end_date = "End Date cannot be a future date";
    }

    if (formData.certificate_name) {
      if (formData?.evidance === undefined) {
        errors.evidance = "Media is required";
      }
    }

    if (!formData.university_id) {
      errors.university_id = "University is required";
    }

    if (!formData.degree_id) {
      errors.degree_id = "Degree is required";
    }

    if (otherDegree) {
      if (!formData?.degree_type_id) {
        errors.degree_type_id = "Degree Type is required";
      }

      if (!formData?.optional_degree) {
        errors.optional_degree = "Degree is required";
      }
    }

    if (otherUniversity) {
      if (!formData.college_name) {
        errors.college_name = "College Name is required";
      }

      if (!formData.country_id) {
        errors.country_id = "Country is required";
      }

      if (!formData.state_id) {
        errors.state_id = "State is required";
      }

      if (!formData.city_id) {
        errors.city_id = "City is required";
      }
    }

    return errors;
  };

  const handleChange = async (e) => {
    // console.log(e?.target?.name);
    if (
      e?.name === "achievement_id" ||
      e?.name === "university_type_id" ||
      e?.name === "degree_type_id" ||
      e?.name === "country_id" ||
      e?.name === "state_id" ||
      e?.name === "city_id" ||
      e?.name === "type_id"
    ) {
      if (e?.name === "country_id") {
        const res = await getData(
          `/without-login/master/all-state/${e?.value}`
        );
        setState(await Select2Data(res.data, "state_id"));
        setFormData({ ...formData, [e.name]: e, state_id: "", city_id: "" });
      } else if (e?.name === "state_id") {
        const res = await getData(`/without-login/master/all-city/${e?.value}`);
        setCity(await Select2Data(res.data, "city_id"));
        setFormData({ ...formData, [e.name]: e, city_id: "" });
      }
      if (e?.name === "university_type_id") {
        setFormData({ ...formData, [e?.name]: e });
      }
      if (e?.name === "degree_type_id") {
        setFormData({ ...formData, [e?.name]: e });
      }
      if (e?.name === "achievement_id") {
        setFormData({ ...formData, [e?.name]: e });
      }
      if (e?.name === "type_id") {
        setFormData({ ...formData, [e?.name]: e });
      } else {
        setFormData({ ...formData, [e.name]: e });
      }
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleInputChange = async (e) => {
    if (
      e?.target?.name === "area_of_study" ||
      e?.target?.name === "degree_name" ||
      e?.target?.name === "college_name" ||
      e?.target?.name === "grades"
    ) {
      if (e?.target?.name === "area_of_study") {
        setFormData({ ...formData, [e?.target?.name]: e.target.value });
      }
      if (e?.target?.name === "degree_name") {
        setFormData({ ...formData, [e?.target?.name]: e.target.value });
      }
      if (e?.target?.name === "college_name") {
        setFormData({ ...formData, [e?.target?.name]: e.target.value });
      }
      if (e?.target?.name === "grades") {
        setFormData({ ...formData, [e?.target?.name]: e.target.value });
      }
    }
  };

  const getAllData = async () => {
    {
      const res = await getData("/without-login/master/all-achievemente");
      setAchievemente(await Select2Data(res.data, "achievement_id"));
    }
    {
      const res = await getData("/without-login/master/all-descipline");
      setDesciples(await Select2Data(res.data, "area_of_studay_id"));
    }
    {
      const res = await getData("/without-login/master/all-degree");
      setDegree(res.data);
    }
    {
      const res = await getData("/without-login/master/all-university");
      setUniversity(res.data);
    }
    {
      const res = await getData("/without-login/master/all-country");
      setCountry(await Select2Data(res.data, "country_id"));
    }
    {
      const res = await getData("/without-login/master/all-university-type");
      setUniversityType(await Select2Data(res.data, "university_type_id"));
    }
    {
      const res = await getData("/without-login/master/all-degree-type");
      setDegreeType(await Select2Data(res.data, "degree_type_id"));
    }
  };

  const [educations, setEducation] = useState();
  const getAllCards = async () => {
    const res = await getData(`/jobseeker/signup/education`);
    if (res?.success) {
      // console.log(res.data.length, "education");
      await setEducation(res?.data);
      if (res?.data.length !== 0) {
        setStatus("none");
      } else {
        setStatus(true);
      }
    }
  };

  useEffect(() => {
    getAllData();
    getAllCards();
  }, [jobSeekerData?.id]);

  const handleSubmit = async (action) => {
    await setButtonStatus(true);
    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      if (action === "continue") {
        if (educations.length > 0) {
          handleNext();
        }
      }

      await setButtonStatus(false);
    } else {
      try {
        const finalData = new FormData();
        finalData.append(`media`, formData.evidance);
        finalData.append(`step`, formData.step);
        if (formData.achievement_id) {
          finalData.append(`achievement_id`, formData.achievement_id?.value);
        }
        finalData.append(`university_id`, formData.university_id);
        if (formData.university_type_id) {
          finalData.append(
            `university_type_id`,
            formData.university_type_id?.value
          );
        }
        finalData.append(`degree_id`, formData.degree_id);
        if (formData.degree_type_id) {
          finalData.append(`degree_type_id`, formData.degree_type_id?.value);
          finalData.append(`optional_degree`, formData.optional_degree);
        }
        if (formData.country_id) {
          finalData.append(`country_id`, formData.country_id?.value);
        }
        if (formData.state_id) {
          finalData.append(`state_id`, formData.state_id?.value);
        }
        if (formData.city_id) {
          finalData.append(`city_id`, formData.city_id?.value);
        }

        if (formData.type_id) {
          finalData.append(`type_id`, formData.type_id?.value);
        }
        finalData.append(`degree_name`, formData.degree_name);
        finalData.append(`college_name`, formData.college_name);

        finalData.append(`certificate_name`, formData.certificate_name);
        finalData.append(`start_date`, formData.start_date);
        finalData.append(`end_date`, formData.end_date);
        finalData.append(
          `area_of_study`,
          JSON.stringify(formData.area_of_study)
        );
        finalData.append(`grades`, formData.grades);
        finalData.append(`status`, status);

        const result = await postData("/jobseeker/signup/education", finalData);

        if (result?.success) {
          await setFormData({
            step: 7,
            evidance: undefined,
            achievement_id: "",
            certificate_name: "",
            university_id: "",
            degree_id: "",
            university_type_id: "",
            degree_type_id: "",
            country_id: "",
            state_id: "",
            city_id: "",
            type_id: "",
            grades: "",
            degree_name: "",
            start_date: "",
            end_date: "",
            area_of_study: [],
            college_name: "",
            showCollegeInput: false,
            showDegreeInput: false,
          });
          await setSelectedArea_of_studay([]);
          await setErrors();
          await getAllCards();
          await fetchSeekerData();
          if (action === "continue") {
            handleNext();
          }

          await setButtonStatus(false);
        }
      } catch (error) {
        // console.log(error);
        await setButtonStatus(false);
      }
    }
  };

  useEffect(() => {
    setFormData({
      ...formData,
      ["area_of_study"]: selectedarea_of_studay?.map((item) => item.value),
    });
  }, [selectedarea_of_studay]);

  const handleDelete = async (id) => {
    var data = "";
    if (educations.length === 1) {
      var data = {
        step: 6,
        status: false,
      };
    } else {
      var data = {
        step: 7,
        status: "none",
      };
    }
    const res = await editStatusData(`/jobseeker/signup/education/${id}`, data);

    await getAllCards();
    await fetchSeekerData();
  };

  const errorStyle = {
    color: "red",
    marginLeft: "5px",
    // fontSize: "11px",
  };

  const [value, setValue] = useState("");
  const [otherUniversity, setOtherUniversity] = useState(false);
  const [otherDegree, setOtherDegree] = useState(false);
  const [suggestionsList, setSuggestionsList] = useState([]);

  const getSuggestions = async (inputValue, list) => {
    const inputValueLowerCase = inputValue.trim().toLowerCase();

    if (list === "university") {
      const res = await getData(
        `/without-login/master/all-university?name=${inputValueLowerCase}`
      );
      if (res?.success) {
        if (res.data.length === 0) {
          setOtherUniversity(true);
        } else {
          setOtherUniversity(false);
        }

        return res.data?.filter((suggestion) =>
          suggestion?.name.toLowerCase().includes(inputValueLowerCase)
        );
      }
    }
    if (list === "degree") {
      const res = await getData(
        `/without-login/master/all-degree?name=${inputValueLowerCase}`
      );
      if (res?.success) {
        if (res.data.length === 0) {
          setOtherDegree(true);
        } else {
          setOtherDegree(false);
        }
        return res.data?.filter((suggestion) =>
          suggestion?.name.toLowerCase().includes(inputValueLowerCase)
        );
      }
    }
  };

  const onSuggestionsFetchRequested = async ({ value }, list) => {
    const suggestions = await getSuggestions(value, list);
    setSuggestionsList(suggestions);
  };

  const onSuggestionsClearRequested = () => {
    setSuggestionsList([]);
  };

  const onChange = (event, { newValue }) => {
    if (event.target.id === "university_id") {
      const isExistingUniversity = suggestionsList.some(
        (suggestion) => suggestion.name === newValue
      );
      if (!isExistingUniversity) {
        setFormData((prevData) => ({
          ...prevData,
          university_id: newValue,
          showCollegeInput: true,
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          university_id: newValue,
          showCollegeInput: false,
        }));
      }
      // setFormData((prevData) => ({ ...prevData, university_id: newValue }));
    }

    if (event.target.id === "degree_id") {
      const isExistingUniversity = suggestionsList.some(
        (suggestion) => suggestion.name === newValue
      );
      if (!isExistingUniversity) {
        setFormData((prevData) => ({
          ...prevData,
          degree_id: newValue,
          showDegreeInput: true,
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          degree_id: newValue,
          showDegreeInput: false,
        }));
      }
      // setFormData((prevData) => ({ ...prevData, degree_id: newValue }));
    }
  };

  const getSuggestionValue = (suggestion) => suggestion?.name;

  const renderSuggestion = (suggestion, id) => (
    <div
      id={id}
      style={{
        border: "1px solid #ccc",
        padding: "10px",
        borderRadius: "5px",
        margin: "5px",
        backgroundColor: "#f9f9f9",
      }}
    >
      {suggestion?.name}
      <br />
      {id === "university_id" && (
        <sup>
          <b id={id}>
            {suggestion?.university_type?.name} : {suggestion?.city?.name},
            {suggestion?.state?.name},{suggestion?.country?.name}
          </b>
        </sup>
      )}
    </div>
  );

  const inputProps = {
    onChange,
    style: {
      width: "100%", // Adjust the width as needed
      padding: "8px",
      borderRadius: "5px",
      border: "1px solid #ccc",
      // Add other styles as needed
    },
  };

  return (
    <>
      <section className="eightstep  step-seven text-start StepForm">
        <div className="container">
          <div className="d-flex  steps-heading">
            <div className="border-left" />
            <h5 className="stepsixtxt">Step 6</h5>
          </div>
          <p className="title">Add Your Education</p>
          <div className="desktop-view-navitem">
            <Tab.Container defaultActiveKey="home">
              <Row>
                <Col md={12}>
                  <Nav variant="pills"></Nav>
                </Col>

                <Tab.Content>
                  <Tab.Pane eventKey="home"></Tab.Pane>
                  <Tab.Pane eventKey="link-2">Second tab content</Tab.Pane>
                </Tab.Content>
                <Form>
                  <Row className="">
                    {educations?.map((value) => (
                      <Col className="col-md-5 col-lg-3 col-xl-3 col-xxl-3 col-12 ">
                        <div className="msc-box">
                          <div className="tabs-btn p-2">
                            <div className="d-flex justify-content-end">
                              {/* <FontAwesomeIcon
                                icon="fa-solid fa-pen-to-square"
                                className="me-3"
                                style={{ cursor: "pointer" }}
                                onClick={() => setLgShow(true)} /> */}
                              <FontAwesomeIcon
                                icon={faXmark}
                                className="close-icon"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleDelete(value?.id)}
                              />
                            </div>
                            <p className="text-para">{value?.degree?.name}</p>
                            <div className="info">
                              <p>Degree : {value?.degree?.name}</p>
                              <p>University: {value?.university?.name}</p>
                              <p>
                                Start Date :{" "}
                                {new Date(value?.start_date).toLocaleDateString(
                                  "en-CA",
                                  {
                                    year: "numeric",
                                    month: "2-digit",
                                    day: "2-digit",
                                  }
                                )}
                              </p>
                              <p>
                                End Date :
                                {new Date(value?.end_date).toLocaleDateString(
                                  "en-CA",
                                  {
                                    year: "numeric",
                                    month: "2-digit",
                                    day: "2-digit",
                                  }
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                      </Col>
                    ))}
                  </Row>

                  <Row className="mt-4">
                    <div className="col-md-6 col-sm-12 mb-3">
                      <Form.Label>
                        University<span className="text-danger">*</span>{" "}
                      </Form.Label>
                      <Autosuggest
                        id="university_id"
                        suggestions={suggestionsList}
                        onSuggestionsFetchRequested={(event) =>
                          onSuggestionsFetchRequested(event, "university")
                        }
                        onSuggestionsClearRequested={
                          onSuggestionsClearRequested
                        }
                        getSuggestionValue={getSuggestionValue}
                        renderSuggestion={(suggestion) =>
                          renderSuggestion(suggestion, "university_id")
                        }
                        inputProps={{
                          ...inputProps,
                          id: "university_id",
                          value: formData?.university_id,
                          placeholder: "Enter University",
                        }}
                      />
                      {/* <Select
                        name="university_id"
                        placeholder="Select University"
                        value={formData?.university_id}
                        onChange={handleChange}
                        options={university}
                      /> */}
                      {errors?.university_id && (
                        <span style={errorStyle}>{errors?.university_id}</span>
                      )}
                    </div>

                    {/* {formData?.showCollegeInput ? (
                      <div className="col-md-6 col-sm-12 mb-3">
                        <Form.Label>
                          College Name <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter College Name"
                          name="college_name"
                          value={formData?.college_name}
                          onChange={handleInputChange}
                        />
                        {errors?.college_name && (
                          <span style={errorStyle}>{errors?.college_name}</span>
                        )}
                      </div>
                    ) : (
                      <></>
                    )} */}

                    {otherUniversity && (
                      <>
                        <div className="col-md-6 col-sm-12 mb-3">
                          <Form.Label>
                            College Name <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter College Name"
                            name="college_name"
                            value={formData?.college_name}
                            onChange={handleInputChange}
                          />
                          {errors?.college_name && (
                            <span style={errorStyle}>
                              {errors?.college_name}
                            </span>
                          )}
                        </div>
                      </>
                    )}

                    <div className="col-md-6 col-sm-12 mb-3">
                      <Form.Label>
                        Degree Earned <span className="text-danger">*</span>
                      </Form.Label>

                      <Autosuggest
                        suggestions={suggestionsList}
                        onSuggestionsFetchRequested={(event) =>
                          onSuggestionsFetchRequested(event, "degree")
                        }
                        onSuggestionsClearRequested={
                          onSuggestionsClearRequested
                        }
                        getSuggestionValue={getSuggestionValue}
                        renderSuggestion={(suggestion) =>
                          renderSuggestion(suggestion, "degree_id")
                        }
                        inputProps={{
                          ...inputProps,
                          id: "degree_id",
                          value: formData?.degree_id,
                          placeholder: "Enter Degree",
                        }}
                      />
                      {/* <Select
                        name="degree_id"
                        placeholder="Select Degree Earned "
                        value={formData?.degree_id}
                        onChange={handleChange}
                        options={degree}
                      /> */}
                      {errors?.degree_id && (
                        <span style={errorStyle}>{errors?.degree_id}</span>
                      )}
                    </div>

                    {/* {formData?.showDegreeInput ? (
                      <div className="col-md-6 col-sm-12 mb-3">
                        <Form.Label>
                          Degree <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Degree"
                          name="optional_degree"
                          value={formData?.optional_degree}
                          onChange={handleInputChange}
                        />
                        {errors?.optional_degree && (
                          <span style={errorStyle}>
                            {errors?.optional_degree}
                          </span>
                        )}
                      </div>
                    ) : (
                      <></>
                    )} */}

                    <div className="col-md-6 col-sm-12 mb-3">
                      <Form.Label>
                        GPA or Grades <span className="text-danger"></span>
                      </Form.Label>

                      <Select
                        name="type_id"
                        classNamePrefix="gpaselect"
                        placeholder="Select GPA or Grades"
                        value={formData?.type_id}
                        onChange={handleChange}
                        options={type}
                      />
                      {errors?.type_id && (
                        <span style={errorStyle}>{errors?.type_id}</span>
                      )}
                    </div>

                    <div className="col-md-6 col-sm-12 mb-3">
                      <Form.Label>
                        GPA or Grades <span className="text-danger"></span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter GPA or Grades"
                        name="grades"
                        value={formData?.grades}
                        onChange={handleInputChange}
                      />
                      {errors?.grades && (
                        <span style={errorStyle}>{errors?.grades}</span>
                      )}
                    </div>
                    {otherDegree && (
                      <>
                        <div className="col-md-6 col-sm-12 mb-3">
                          <Form.Label>
                            Degree Type <span className="text-danger">*</span>{" "}
                          </Form.Label>

                          <Select
                            name="degree_type_id"
                            placeholder="Select Degree Type"
                            value={formData?.degree_type_id}
                            onChange={handleChange}
                            options={degreeType}
                          />
                          {errors?.degree_type_id && (
                            <span style={errorStyle}>
                              {errors?.degree_type_id}
                            </span>
                          )}
                        </div>

                        <div className="col-md-6 col-sm-12 mb-3">
                          <Form.Label>
                            Degree <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Degree"
                            name="optional_degree"
                            value={formData?.optional_degree}
                            onChange={handleChange}
                          />
                          {errors?.optional_degree && (
                            <span style={errorStyle}>
                              {errors?.optional_degree}
                            </span>
                          )}
                        </div>
                      </>
                    )}
                    {otherUniversity && (
                      <>
                        <div className="col-md-6 col-sm-12 mb-3">
                          <Form.Label>
                            University Type{" "}
                            <span className="text-danger">*</span>{" "}
                          </Form.Label>

                          <Select
                            name="university_type_id"
                            placeholder="Select University Type"
                            value={formData?.university_type_id}
                            onChange={handleChange}
                            options={universityType}
                          />
                          {errors?.country_id && (
                            <span style={errorStyle}>{errors?.country_id}</span>
                          )}
                        </div>
                        <div className="col-md-4 col-sm-12 mb-3">
                          <Form.Label>
                            Country <span className="text-danger">*</span>{" "}
                          </Form.Label>

                          <Select
                            name="country_id"
                            placeholder="Select Country"
                            value={formData?.country_id}
                            onChange={handleChange}
                            options={country}
                          />
                          {errors?.country_id && (
                            <span style={errorStyle}>{errors?.country_id}</span>
                          )}
                        </div>

                        <div className="col-md-4 col-sm-12 mb-3">
                          <Form.Label>
                            State <span className="text-danger">*</span>{" "}
                          </Form.Label>

                          <Select
                            name="state_id"
                            placeholder="Select State"
                            value={formData?.state_id}
                            onChange={handleChange}
                            options={state}
                          />
                          {errors?.state_id && (
                            <span style={errorStyle}>{errors?.state_id}</span>
                          )}
                        </div>

                        <div className="col-md-4 col-sm-12 mb-3">
                          <Form.Label>
                            City <span className="text-danger">*</span>
                          </Form.Label>

                          <Select
                            name="city_id"
                            placeholder="Select City"
                            value={formData?.city_id}
                            onChange={handleChange}
                            options={city}
                          />
                          {errors?.city_id && (
                            <span style={errorStyle}>{errors?.city_id}</span>
                          )}
                        </div>
                      </>
                    )}

                    <div className="col-md-6 col-sm-12 mb-3">
                      <Form.Label>
                        Degree Name <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Degree Name"
                        name="degree_name"
                        value={formData?.degree_name}
                        onChange={handleInputChange}
                      />
                      {errors?.degree_name && (
                        <span style={errorStyle}>{errors?.degree_name}</span>
                      )}
                    </div>

                    {/* <div className="col-md-6 col-sm-12 mb-3">
                      <Form.Label>
                        Area of Study <span className="text-danger"></span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Area of Study"
                        name="area_of_study"
                        value={formData?.area_of_study}
                        onChange={handleInputChange}
                      />
                      {errors?.area_of_study && (
                        <span style={errorStyle}>{errors?.area_of_study}</span>
                      )}
                    </div> */}

                    <div className="col-md-6 col-sm-12 mb-3">
                      <Form.Label>Area Of Study</Form.Label>

                      <Select
                        isMulti={true}
                        classNamePrefix="Eduarea-study"
                        name="area_of_studay"
                        placeholder="Selecte Area Of Study"
                        value={selectedarea_of_studay}
                        onChange={(e) => {
                          setSelectedArea_of_studay(e);
                        }}
                        options={desciples}
                      />
                      {errors?.area_of_studay && (
                        <span style={errorStyle}>{errors?.area_of_studay}</span>
                      )}
                    </div>

                    <div className="col-md-6 col-sm-6 mb-3">
                      <Form.Label>
                        Start Date <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="date"
                        placeholderText="yyyy-mm-dd"
                        name="start_date"
                        value={formData?.start_date}
                        onChange={handleChange}
                      />
                      {errors?.start_date && (
                        <span style={errorStyle}>{errors?.start_date}</span>
                      )}
                    </div>

                    <div className="col-md-6 col-sm-6 mb-3">
                      <Form.Label>
                        End Date <span className="text-danger">*</span>
                      </Form.Label>

                      <Form.Control
                        type="date"
                        placeholderText="yyyy-mm-dd"
                        name="end_date"
                        value={formData?.end_date}
                        onChange={handleChange}
                      />

                      {errors?.end_date && (
                        <span style={errorStyle}>{errors?.end_date}</span>
                      )}
                    </div>

                    {/* <div className="col-md-6 col-sm-12 mb-3">
                      <Form.Label>
                        Achievemente<span className="text-danger"></span>{" "}
                      </Form.Label>

                      <Select
                        name="achievement_id"
                        placeholder="Select Achievement"
                        value={formData?.achievement_id}
                        onChange={handleChange}
                        options={achievemente}
                      />
                      {errors?.achievement_id && (
                        <span style={errorStyle}>{errors?.achievement_id}</span>
                      )}
                    </div> */}

                    <div className="col-md-6 col-sm-12 mb-3">
                      <Form.Label>
                        Certificate Name <span className="text-danger"></span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Certificate Name"
                        name="certificate_name"
                        value={formData?.certificate_name}
                        onChange={handleChange}
                      />
                      {errors?.certificate_name && (
                        <span style={errorStyle}>
                          {errors?.certificate_name}
                        </span>
                      )}
                    </div>

                    <div className="col-md-6 col-sm-12 mb-3">
                      <Form.Label>
                        Upload Media
                        <span className="text-danger"></span>{" "}
                      </Form.Label>

                      <Form.Control
                        type="file"
                        placeholder="Upload Media"
                        name="evidance"
                        accept="application/pdf"
                        onChange={async (e) => {
                          await setFormData({
                            ...formData,
                            [e.target.name]: e.target.files[0],
                          });
                        }}
                      />
                      {errors?.evidance && (
                        <span style={errorStyle}>{errors?.evidance}</span>
                      )}
                    </div>
                  </Row>

                  <div className="text-end">
                    <Button
                      disabled={buttonStatus}
                      className="education-btn"
                      onClick={handleSubmit}
                    >
                      Add Education
                    </Button>
                  </div>
                </Form>
              </Row>
            </Tab.Container>
          </div>
          <div className="mobile-view-navitem">
            <Tab.Container defaultActiveKey="home" className=" ">
              <Nav variant="pills">
                <Swiper
                  slidesPerView={1}
                  spaceBetween={10}
                  pagination={{
                    clickable: true,
                  }}
                  breakpoints={{
                    0: {
                      slidesPerView: 1,
                    },
                    360: {
                      slidesPerView: 2,
                    },
                    640: {
                      slidesPerView: 2,
                    },
                    767: {
                      slidesPerView: 3,
                    },
                  }}
                  // loop={true}

                  modules={[Pagination]}
                ></Swiper>
              </Nav>
              <Row>
                <Tab.Content>
                  <Tab.Pane eventKey="home">
                    <Form>
                      {educations?.map((value) => (
                        <Col className="col-md-5 col-lg-3 col-xl-3 col-xxl-3 col-12 mb-2">
                          <div className="msc-box">
                            <div className="tabs-btn p-2">
                              <p className="text-para">
                                {value?.degree?.name}
                                <FontAwesomeIcon
                                  icon={faXmark}
                                  className="close-icon"
                                />
                              </p>
                              <div className="info">
                                <p>Degree : {value?.degree?.name}</p>
                                <p>University: {value?.university?.name}</p>
                                <p>Start Date : {value?.start_date}</p>
                                <p>End Date : {value?.end_date}</p>
                              </div>
                            </div>
                          </div>
                        </Col>
                      ))}
                      <Row className="mt-4">
                        <div className="col-md-6 col-sm-12 mb-3">
                          <Form.Label>
                            University<span className="text-danger">*</span>{" "}
                          </Form.Label>
                          <Autosuggest
                            id="university_id"
                            suggestions={suggestionsList}
                            onSuggestionsFetchRequested={(event) =>
                              onSuggestionsFetchRequested(event, "university")
                            }
                            onSuggestionsClearRequested={
                              onSuggestionsClearRequested
                            }
                            getSuggestionValue={getSuggestionValue}
                            renderSuggestion={(suggestion) =>
                              renderSuggestion(suggestion, "university_id")
                            }
                            inputProps={{
                              ...inputProps,
                              id: "university_id",
                              value: formData?.university_id,
                              placeholder: "Enter University",
                            }}
                          />
                          {/* <Select
                        name="university_id"
                        placeholder="Select University"
                        value={formData?.university_id}
                        onChange={handleChange}
                        options={university}
                      /> */}
                          {errors?.university_id && (
                            <span style={errorStyle}>
                              {errors?.university_id}
                            </span>
                          )}
                        </div>

                        {/* {formData?.showCollegeInput ? (
                      <div className="col-md-6 col-sm-12 mb-3">
                        <Form.Label>
                          College Name <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter College Name"
                          name="college_name"
                          value={formData?.college_name}
                          onChange={handleInputChange}
                        />
                        {errors?.college_name && (
                          <span style={errorStyle}>{errors?.college_name}</span>
                        )}
                      </div>
                    ) : (
                      <></>
                    )} */}

                        {otherUniversity && (
                          <>
                            <div className="col-md-6 col-sm-12 mb-3">
                              <Form.Label>
                                College Name{" "}
                                <span className="text-danger">*</span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter College Name"
                                name="college_name"
                                value={formData?.college_name}
                                onChange={handleInputChange}
                              />
                              {errors?.college_name && (
                                <span style={errorStyle}>
                                  {errors?.college_name}
                                </span>
                              )}
                            </div>
                          </>
                        )}

                        <div className="col-md-6 col-sm-12 mb-3">
                          <Form.Label>
                            Degree Earned <span className="text-danger">*</span>{" "}
                          </Form.Label>

                          <Autosuggest
                            suggestions={suggestionsList}
                            onSuggestionsFetchRequested={(event) =>
                              onSuggestionsFetchRequested(event, "degree")
                            }
                            onSuggestionsClearRequested={
                              onSuggestionsClearRequested
                            }
                            getSuggestionValue={getSuggestionValue}
                            renderSuggestion={(suggestion) =>
                              renderSuggestion(suggestion, "degree_id")
                            }
                            inputProps={{
                              ...inputProps,
                              id: "degree_id",
                              value: formData?.degree_id,
                              placeholder: "Enter Degree",
                            }}
                          />
                          {/* <Select
                        name="degree_id"
                        placeholder="Select Degree Earned "
                        value={formData?.degree_id}
                        onChange={handleChange}
                        options={degree}
                      /> */}
                          {errors?.degree_id && (
                            <span style={errorStyle}>{errors?.degree_id}</span>
                          )}
                        </div>

                        {/* {formData?.showDegreeInput ? (
                      <div className="col-md-6 col-sm-12 mb-3">
                        <Form.Label>
                          Degree <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Degree"
                          name="optional_degree"
                          value={formData?.optional_degree}
                          onChange={handleInputChange}
                        />
                        {errors?.optional_degree && (
                          <span style={errorStyle}>
                            {errors?.optional_degree}
                          </span>
                        )}
                      </div>
                    ) : (
                      <></>
                    )} */}

                        <div className="col-md-6 col-sm-12 mb-3">
                          <Form.Label>
                            GPA or Grades <span className="text-danger"></span>{" "}
                          </Form.Label>

                          <Select
                            name="type_id"
                            placeholder="Select GPA or Grades"
                            value={formData?.type_id}
                            onChange={handleChange}
                            options={type}
                          />
                          {errors?.type_id && (
                            <span style={errorStyle}>{errors?.type_id}</span>
                          )}
                        </div>

                        <div className="col-md-6 col-sm-12 mb-3">
                          <Form.Label>
                            GPA or Grades <span className="text-danger"></span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter GPA or Grades"
                            name="grades"
                            value={formData?.grades}
                            onChange={handleInputChange}
                          />
                          {errors?.grades && (
                            <span style={errorStyle}>{errors?.grades}</span>
                          )}
                        </div>
                        {otherDegree && (
                          <>
                            <div className="col-md-6 col-sm-12 mb-3">
                              <Form.Label>
                                Degree Type{" "}
                                <span className="text-danger">*</span>{" "}
                              </Form.Label>

                              <Select
                                name="degree_type_id"
                                placeholder="Select Degree Type"
                                value={formData?.degree_type_id}
                                onChange={handleChange}
                                options={degreeType}
                              />
                              {errors?.degree_type_id && (
                                <span style={errorStyle}>
                                  {errors?.degree_type_id}
                                </span>
                              )}
                            </div>

                            <div className="col-md-6 col-sm-12 mb-3">
                              <Form.Label>
                                Degree <span className="text-danger">*</span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter Degree"
                                name="optional_degree"
                                value={formData?.optional_degree}
                                onChange={handleChange}
                              />
                              {errors?.optional_degree && (
                                <span style={errorStyle}>
                                  {errors?.optional_degree}
                                </span>
                              )}
                            </div>
                          </>
                        )}
                        {otherUniversity && (
                          <>
                            <div className="col-md-6 col-sm-12 mb-3">
                              <Form.Label>
                                University Type{" "}
                                <span className="text-danger">*</span>{" "}
                              </Form.Label>

                              <Select
                                name="university_type_id"
                                placeholder="Select University Type"
                                value={formData?.university_type_id}
                                onChange={handleChange}
                                options={universityType}
                              />
                              {errors?.country_id && (
                                <span style={errorStyle}>
                                  {errors?.country_id}
                                </span>
                              )}
                            </div>
                            <div className="col-md-4 col-sm-12 mb-3">
                              <Form.Label>
                                Country <span className="text-danger">*</span>{" "}
                              </Form.Label>

                              <Select
                                name="country_id"
                                placeholder="Select Country"
                                value={formData?.country_id}
                                onChange={handleChange}
                                options={country}
                              />
                              {errors?.country_id && (
                                <span style={errorStyle}>
                                  {errors?.country_id}
                                </span>
                              )}
                            </div>

                            <div className="col-md-4 col-sm-12 mb-3">
                              <Form.Label>
                                State <span className="text-danger">*</span>{" "}
                              </Form.Label>

                              <Select
                                name="state_id"
                                placeholder="Select State"
                                value={formData?.state_id}
                                onChange={handleChange}
                                options={state}
                              />
                              {errors?.state_id && (
                                <span style={errorStyle}>
                                  {errors?.state_id}
                                </span>
                              )}
                            </div>

                            <div className="col-md-4 col-sm-12 mb-3">
                              <Form.Label>
                                City <span className="text-danger">*</span>{" "}
                              </Form.Label>

                              <Select
                                name="city_id"
                                placeholder="Select City"
                                value={formData?.city_id}
                                onChange={handleChange}
                                options={city}
                              />
                              {errors?.city_id && (
                                <span style={errorStyle}>
                                  {errors?.city_id}
                                </span>
                              )}
                            </div>
                          </>
                        )}

                        <div className="col-md-6 col-sm-12 mb-3">
                          <Form.Label>
                            Degree Name <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Degree Name"
                            name="degree_name"
                            value={formData?.degree_name}
                            onChange={handleInputChange}
                          />
                          {errors?.degree_name && (
                            <span style={errorStyle}>
                              {errors?.degree_name}
                            </span>
                          )}
                        </div>

                        {/* <div className="col-md-6 col-sm-12 mb-3">
                      <Form.Label>
                        Area of Study <span className="text-danger"></span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Area of Study"
                        name="area_of_study"
                        value={formData?.area_of_study}
                        onChange={handleInputChange}
                      />
                      {errors?.area_of_study && (
                        <span style={errorStyle}>{errors?.area_of_study}</span>
                      )}
                    </div> */}

                        <div className="col-md-6 col-sm-12 mb-3">
                          <Form.Label>Area Of Study</Form.Label>

                          <Select
                            isMulti={true}
                            name="area_of_studay"
                            placeholder="Selecte Area Of Study"
                            value={selectedarea_of_studay}
                            onChange={(e) => {
                              setSelectedArea_of_studay(e);
                            }}
                            options={desciples}
                          />
                          {errors?.area_of_studay && (
                            <span style={errorStyle}>
                              {errors?.area_of_studay}
                            </span>
                          )}
                        </div>

                        <div className="col-md-6 col-sm-6 mb-3">
                          <Form.Label>
                            Start Date <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            type="date"
                            placeholderText="yyyy-mm-dd"
                            name="start_date"
                            value={formData?.start_date}
                            onChange={handleChange}
                          />
                          {errors?.start_date && (
                            <span style={errorStyle}>{errors?.start_date}</span>
                          )}
                        </div>

                        <div className="col-md-6 col-sm-6 mb-3">
                          <Form.Label>
                            End Date <span className="text-danger">*</span>
                          </Form.Label>

                          <Form.Control
                            type="date"
                            placeholderText="yyyy-mm-dd"
                            name="end_date"
                            value={formData?.end_date}
                            onChange={handleChange}
                          />

                          {errors?.end_date && (
                            <span style={errorStyle}>{errors?.end_date}</span>
                          )}
                        </div>

                        {/* <div className="col-md-6 col-sm-12 mb-3">
                      <Form.Label>
                        Achievemente<span className="text-danger"></span>{" "}
                      </Form.Label>

                      <Select
                        name="achievement_id"
                        placeholder="Select Achievement"
                        value={formData?.achievement_id}
                        onChange={handleChange}
                        options={achievemente}
                      />
                      {errors?.achievement_id && (
                        <span style={errorStyle}>{errors?.achievement_id}</span>
                      )}
                    </div> */}

                        <div className="col-md-6 col-sm-12 mb-3">
                          <Form.Label>
                            Certificate Name{" "}
                            <span className="text-danger"></span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Certificate Name"
                            name="certificate_name"
                            value={formData?.certificate_name}
                            onChange={handleChange}
                          />
                          {errors?.certificate_name && (
                            <span style={errorStyle}>
                              {errors?.certificate_name}
                            </span>
                          )}
                        </div>

                        <div className="col-md-6 col-sm-12 mb-3">
                          <Form.Label>
                            Upload Media
                            <span className="text-danger"></span>{" "}
                          </Form.Label>

                          <Form.Control
                            type="file"
                            placeholder="Upload Media"
                            name="evidance"
                            accept="application/pdf"
                            onChange={async (e) => {
                              await setFormData({
                                ...formData,
                                [e.target.name]: e.target.files[0],
                              });
                            }}
                          />
                          {errors?.evidance && (
                            <span style={errorStyle}>{errors?.evidance}</span>
                          )}
                        </div>
                      </Row>
                    </Form>
                  </Tab.Pane>
                  <Tab.Pane eventKey="link-2">Second tab content</Tab.Pane>
                </Tab.Content>
              </Row>
            </Tab.Container>
          </div>
          <div className=" mobile-view-button mt-5">
            <div className="d-flex">
              <div className="">
                <Button
                  disabled={buttonStatus}
                  className="next action-button contin-btn"
                  onClick={handleSubmit}
                >
                  Add
                </Button>
              </div>
              <div className="me-1">
                <button
                  type="button"
                  name="next"
                  // disabled={cards?.length === 0 ? true : false}
                  className="next action-button contin-btn"
                  onClick={handleNext}
                >
                  Skip
                </button>
              </div>
              <div className="ms-auto">
                <button
                  disabled={buttonStatus}
                  type="button"
                  name="next"
                  // disabled={educations?.length == 0 ? true : false}
                  className="next action-button contin-btn"
                  onClick={() => handleSubmit("continue")}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>

          <div className="row mt-5 pt-4 footer-sec">
            <div className="col-md-8 col-12 desktop-view-button"></div>

            <div className="col-4 desktop-view-button">
              <div className="d-flex justify-content-end">
                <div className="">
                  <button
                    type="button"
                    name="next"
                    // disabled={cards?.length === 0 ? true : false}
                    className="next action-button contin-btn"
                    onClick={handleNext}
                  >
                    Skip
                  </button>
                </div>
                <div className="">
                  <button
                    disabled={buttonStatus}
                    type="button"
                    name="next"
                    // disabled={educations?.length == 0 ? true : false}
                    className="next action-button contin-btn"
                    onClick={() => handleSubmit("continue")}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Modal */}
      {/* <Modal size="lg" show={lgShow} onHide={() => setLgShow(false)} className="experience-modal" centered>
        <Modal.Header closeButton>
          <Modal.Title>Edit Your Education</Modal.Title>
        </Modal.Header>
        <Modal.Body>
         
        </Modal.Body>
        <Modal.Footer className="mx-auto">
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button className="education-btn" onClick={handleSubmit}>
            Add Education
          </Button>
        </Modal.Footer>
      </Modal> */}
    </>
  );
};

export default Step7;
