import React, { useContext, useEffect, useState } from "react";
import "./ExploreApportunity.css";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import firstimg from "../ExploreApportunity/Images/Infosys.png";
import profcyma from "../ExploreApportunity/Images/Profcyma.png";
import secimg from "../ExploreApportunity/Images/Tech_Mahindra.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "@fortawesome/fontawesome-svg-core/styles.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";

import JobDescriptionModal from "../../../JobDescriptionModel/JobDescriptionModal";
import { Context } from "../../../../../utils/context";
import { Link } from "react-router-dom";
import {
  faEllipsisVertical,
  faClock,
  faGraduationCap,
  faBriefcase,
  faDesktop,
  faMoneyBill,
  faStar,
  faGreaterThan,
} from "@fortawesome/free-solid-svg-icons";

const ExploreApportunity = () => {
  const { getData, imageUrl, postData, NoDataAnimations } = useContext(Context);
  const [modalShow, setModalShow] = useState(false);
  const [jobAlerts, setJobAlerts] = useState([]);

  const getAllData = async () => {
    const res = await getData("/jobseeker/dashboard/job-alerts");
    if (res?.success) {
      setJobAlerts(res?.data);
    }
  };

  const [data, setData] = useState({});

  useEffect(() => {
    getAllData();
  }, [modalShow]);

  const [showAll, setShowAll] = useState(false);
  const visibleJobs = showAll ? jobAlerts : jobAlerts?.slice(0, 6);

  return (
    <>
      <section className="Explore_apportunity">
        <div className="container">
          <div className="heading text-center">
            <h1>Explore New Apportunities</h1>
          </div>
          <div className="head-bar ">
            {/* <Breadcrumb >
              <Breadcrumb.Item href="#" className="go-home" >Go to Home</Breadcrumb.Item>
              <span className="greater"><FontAwesomeIcon icon={faGreaterThan} /></span>
              <Breadcrumb.Item active className="explore-text">Explore More</Breadcrumb.Item>
            </Breadcrumb> */}
          </div>
          <div className="cards">
            <div className="row">
              {visibleJobs?.map((value, index) => (
                <div className="col-12 col-md-6 col-lg-4">
                  <Card>
                    <div className="logo-div">
                      <div className="row">
                        <div className="col-6">
                          <Card.Img
                            variant="top"
                            // src={profcyma}
                            src={imageUrl + value?.job_contact?.logo}
                            className=" company-logo"
                          />
                        </div>
                        <div className="col-6 text-end">
                          {/* <FontAwesomeIcon icon={faEllipsisVertical} /> */}
                        </div>
                      </div>
                      <div>
                        <p>{value?.company?.name}</p>
                      </div>
                    </div>
                    <Card.Body>
                      <Card.Title>{value?.role?.name}</Card.Title>
                      <Card.Text>
                        <div className="row">
                          <div className="col-1">
                            <span className="">
                              <FontAwesomeIcon icon={faClock} />
                            </span>
                          </div>
                          <div className="col-9">
                            <span className=" text">
                              {value?.job_create_types
                                ?.map(
                                  (quali, qualindex) => quali?.job_type?.name
                                )
                                .join(", ")}
                            </span>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-1">
                            <span className="">
                              <FontAwesomeIcon icon={faGraduationCap} />
                            </span>
                          </div>
                          <div className="col-9">
                            <span className=" text">
                              {value?.job_qualifications
                                ?.map((quali, qualindex) => quali?.degree?.name)
                                .join(", ")}
                            </span>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-1">
                            <span className="">
                              <FontAwesomeIcon icon={faBriefcase} />{" "}
                            </span>
                          </div>
                          <div className="col-9">
                            <span className=" text">
                              Experienced min. {value?.experience_in_year?.name}{" "}
                              Yr.
                            </span>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-1">
                            <span className="">
                              <FontAwesomeIcon icon={faDesktop} />{" "}
                            </span>
                          </div>
                          <div className="col-9">
                            <div className=" text">
                              {value?.job_primary_skills
                                ?.map((quali, qualindex) => quali?.skill?.name)
                                .join(", ")}
                            </div>
                          </div>
                        </div>
                      </Card.Text>
                    </Card.Body>
                    <ListGroup className="list-group-flush card-text-holder">
                      <div>
                        <span>
                          <FontAwesomeIcon icon={faMoneyBill} />
                        </span>
                        <span className="px-3 text">
                          {" "}
                          {value?.start_salary + " - " + value?.end_salary} per
                          month
                        </span>
                      </div>
                      <div className=" foot">
                        <div className="">
                          {/* <div className="">
                            <p className="m-0 p-0 text">Review</p>
                            <span>
                              <FontAwesomeIcon
                                icon={faStar}
                                className="icon-color"
                              />
                            </span>
                            <span>
                              <FontAwesomeIcon
                                icon={faStar}
                                className="icon-color"
                              />
                            </span>
                            <span>
                              <FontAwesomeIcon
                                icon={faStar}
                                className="icon-color"
                              />
                            </span>
                            <span>
                              <FontAwesomeIcon
                                icon={faStar}
                                className="icon-color"
                              />
                            </span>
                            <span>
                              <FontAwesomeIcon icon={faStar} />
                            </span>
                          </div> */}
                        </div>

                        <div className="pt-3  ">
                          <p>
                            Job Posted on :{" "}
                            {value?.createdAt?.replace("T", " ")?.split(" ")[0]}
                          </p>
                        </div>
                      </div>

                      <div className="apply-butn ">
                        {/* <button className="btn create-job-btn vdvdjd"><span className="under-line"><Link onClick={() => {
                          setData(value);
                          setModalShow(true);
                        }}>View JD</Link></span></button> */}
                        <button className="btn create-job-btn ">
                          <span className="under-line">
                            <Link
                              onClick={() => {
                                setData(value);
                                setModalShow(true);
                              }}
                            >
                              Apply
                            </Link>
                          </span>
                        </button>
                      </div>
                    </ListGroup>
                  </Card>
                </div>
              ))}
              {jobAlerts.length === 0 && <NoDataAnimations />}

              <JobDescriptionModal
                show={modalShow}
                data={data}
                title={"Job Description"}
                onHide={() => setModalShow(false)}
              />
              {/* <div className=" col-12 col-md-6 col-lg-4">
                <Card>
                  <div className="logo-div">
                    <div className="row">
                      <div className="col-6">
                        <Card.Img
                          variant="top"
                          src={secimg}
                          className=" company-logo"
                        />
                      </div>
                      <div className="col-6 text-end">
                        <FontAwesomeIcon icon={faEllipsisVertical} />
                      </div>

                    </div>
                    <div>
                      <p>Tech Mahindra</p>
                    </div>
                  </div>
                  <Card.Body>
                    <Card.Title>Graphic Designer</Card.Title>
                    <Card.Text>
                      <div className="row">
                        <div className="col-1">
                          <span>
                            <FontAwesomeIcon icon={faClock} />
                          </span>
                        </div>

                        <div className="col-9">
                          <span className=" text"> Full Time</span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-1">
                          <span>
                            <FontAwesomeIcon icon={faGraduationCap} />
                          </span>
                        </div>
                        <div className="col-9">
                          <div className=" text">
                            10 + 2 + Any Graduate With Diploma Certification in
                            Graphic Designing
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-1">
                          <span className="">
                            <FontAwesomeIcon icon={faBriefcase} />{" "}
                          </span>
                        </div>
                        <div className="col-9">
                          <div className=" text">Experienced min. 2 Yr.</div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-1">
                          {" "}
                          <span className="">
                            <FontAwesomeIcon icon={faDesktop} />{" "}
                          </span>
                        </div>
                        <div className="col-9">
                          <div className=" text">
                            Photoshop , Illustrator, corel Draw
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-1">
                          <span className="">
                            <FontAwesomeIcon icon={faDesktop} />{" "}
                          </span>
                        </div>
                        <div className="col-9">
                          <div className=" text">
                            Adbo Animate will be plus point
                          </div>
                        </div>
                      </div>
                    </Card.Text>
                  </Card.Body>
                  <ListGroup className="list-group-flush card-text-holder">
                    <div>
                      <span>
                        <FontAwesomeIcon icon={faMoneyBill} />
                      </span>
                      <span className="px-3 text"> 3.1 Lac per annum</span>
                    </div>
                    <div className=" foot">
                      <div className="">
                        <div className="">
                          <p className="m-0 p-0 text">Review</p>
                          <span>
                            <FontAwesomeIcon
                              icon={faStar}
                              className="icon-color"
                            />
                          </span>
                          <span>
                            <FontAwesomeIcon
                              icon={faStar}
                              className="icon-color"
                            />
                          </span>
                          <span>
                            <FontAwesomeIcon
                              icon={faStar}
                              className="icon-color"
                            />
                          </span>
                          <span>
                            <FontAwesomeIcon
                              icon={faStar}
                              className="icon-color"
                            />
                          </span>
                          <span>
                            <FontAwesomeIcon icon={faStar} />
                          </span>
                        </div>
                      </div>

                      <div className="pt-3  ">
                        <p>Job Posted on : 5/10/2023</p>
                      </div>
                    </div>
                    <div className="apply-butn ">

                      <button className="btn create-job-btn vdvdjd"><span className="under-line"><Link to="/postedjobmain">View JD</Link></span></button>
                      <button className="btn create-job-btn "><span className="under-line"><Link to="/job-description">Apply</Link></span></button>


                    </div>
                  </ListGroup>
                </Card>
              </div>
              <div className=" col-12 col-md-6 col-lg-4">
                <Card >
                  <div className="logo-div">
                    <div className="row">
                      <div className="col-6">
                        <Card.Img
                          variant="top"
                          src={firstimg}
                          className=" company-logo"
                        />
                      </div>
                      <div className="col-6 text-end">
                        <FontAwesomeIcon icon={faEllipsisVertical} />
                      </div>

                    </div>
                    <div>
                      <p>Infosys</p>
                    </div>
                  </div>
                  <Card.Body>
                    <Card.Title>UI Designer</Card.Title>
                    <Card.Text>
                      <div className="row">
                        <div className="col-1">
                          <span>
                            <FontAwesomeIcon icon={faClock} />
                          </span>
                        </div>

                        <div className="col-9">
                          <span className=" text"> Full Time</span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-1">
                          <span>
                            <FontAwesomeIcon icon={faGraduationCap} />
                          </span>
                        </div>
                        <div className="col-9">
                          <div className="text">
                            10 + 2 + Any Graduate With Diploma Certification in
                            Graphic Designing
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-1">
                          <span className="">
                            <FontAwesomeIcon icon={faBriefcase} />{" "}
                          </span>
                        </div>
                        <div className="col-9">
                          <div className=" text">Experienced min. 2 Yr.</div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-1">
                          {" "}
                          <span className="">
                            <FontAwesomeIcon icon={faDesktop} />{" "}
                          </span>
                        </div>
                        <div className="col-9">
                          <div className=" text">Adobe Xd fligma</div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-1">
                          <span className="">
                            <FontAwesomeIcon icon={faDesktop} />{" "}
                          </span>
                        </div>
                        <div className="col-9">
                          <div className=" text">
                            Photoshop , Illustrator, corel Draw
                          </div>
                        </div>
                      </div>
                    </Card.Text>
                  </Card.Body>
                  <ListGroup className="list-group-flush  card-text-holder">
                    <div >
                      <span>
                        <FontAwesomeIcon icon={faMoneyBill} />
                      </span>
                      <span className="px-3 text"> 3.1 Lac per annum</span>
                    </div>
                    <div className=" foot">
                      <div className="">
                        <div className="">
                          <p className="m-0 p-0 text">Review</p>
                          <span>
                            <FontAwesomeIcon
                              icon={faStar}
                              className="icon-color"
                            />
                          </span>
                          <span>
                            <FontAwesomeIcon
                              icon={faStar}
                              className="icon-color"
                            />
                          </span>
                          <span>
                            <FontAwesomeIcon
                              icon={faStar}
                              className="icon-color"
                            />
                          </span>
                          <span>
                            <FontAwesomeIcon
                              icon={faStar}
                              className="icon-color"
                            />
                          </span>
                          <span>
                            <FontAwesomeIcon icon={faStar} />
                          </span>
                        </div>
                      </div>

                      <div className="pt-3  ">
                        <p>Job Posted on : 5/10/2023</p>
                      </div>
                    </div>
                    <div className="apply-butn ">

                      <button className="btn create-job-btn vdvdjd"><span className="under-line"><Link to="/postedjobmain">View JD</Link></span></button>
                      <button className="btn create-job-btn "><span className="under-line"><Link to="/job-description">Apply</Link></span></button>
                    </div>
                  </ListGroup>
                </Card>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ExploreApportunity;
