import React from "react";
import "./FirstTabBanner.css";
import { Link } from "react-router-dom";
const FirstTabBanner = () => {
  return (
    <>
      <section className="first-Tab-banner">
        <div className="container-fluid p-0">
          <div className="row ms-0 me-0">
            <div className="col-md-12 p-0 ">
              <img
                src={
                  process.env.PUBLIC_URL + "assets/Images/banner/dashbanner.png"
                }
                className="banner-img"
                alt="..."
              />
              <div className="container title">
                <h5>Grab a Right Talent</h5>
                <div className="mt-md-3 mt-lg-4">
                  <Link to="/talentPool">
                    <button className="btn choose-pool">Choose a Pool</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FirstTabBanner;
