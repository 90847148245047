import React, { useState, useEffect } from "react";
import Step1 from "../UpdatedSingUp/Step1";
import Step2 from "../UpdatedSingUp/Step2";
import Step3 from "../UpdatedSingUp/Step3";
import Step4 from "../UpdatedSingUp/Step4";
import Step5 from "../UpdatedSingUp/Step5";
import Step6 from "../UpdatedSingUp/Step6";
import Step7 from "../UpdatedSingUp/Step7";
import Step8 from "../UpdatedSingUp/Step8";

import Stepform from "./Image/Stepform.png";

import "./prog-track.css";
import "./UpdatedForm.css";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import RoundedBar from "./RounderBar";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLessThan } from "@fortawesome/free-solid-svg-icons";
import Step9 from "../UpdatedSingUp/Step9";
import Step10 from "../UpdatedSingUp/Step10";
import Step11 from "../UpdatedSingUp/Step11";
import ProgressBar from "./ProgressBar";
import { Link, useParams } from "react-router-dom";

import { useContext } from "react";
import { Context } from "../../../../utils/context";
function UpdatedForm() {
  const { id } = useParams();
  const [current, setCurrent] = useState(parseInt(id));
  const steps = 10;
  const totalSteps = 10;
  const progressBarColor = "white"; // Progress bar color
  const borderWidth = current === totalSteps ? "5px" : "black"; // Set the border width dynamically
  const percentage = Math.trunc((current / totalSteps) * 100);

  const handleNext = () => {
    if (current < 10) {
      setCurrent(current + 1);
    }
    fetchSeekerData();
  };

  const handlePrevious = () => {
    if (current > 1) {
      setCurrent(current - 1);
    }
  };

  const isStepCompleted = (step) => {
    return step < current;
  };

  const { fetchSeekerData, jobSeekerData } = useContext(Context);
  useEffect(() => {
    fetchSeekerData();
  }, []);
  return (
    <>
      <section className="StepForm ">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12  text-center  mt-3 mb-2">
              <div className="header-content text-center">
                <Link to="/" className="back-btn">
                  <FontAwesomeIcon icon={faLessThan} className="less-icon" />{" "}
                  Back to Homegage
                </Link>
                <p className="signup-text mb-0">Job Seeker's Sign Up </p>
                <p className="info-text">Personal Info Page</p>
              </div>
              <div className="card stepForm_card px-0  pb-0 mt-3 mb-3">
                <div className="card-body p-0">
                  <div className="row ">
                    <div className="col-md-9 col-12">
                      <form id="msform">
                        <Row>
                          <div className="col-xxl-6 col-xl-6 col-lg-7 col-md-7 col-6">
                            <div className="d-flex">
                              <div className="">
                                {current > 1 && (
                                  <p
                                    className="text-start"
                                    onClick={handlePrevious}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <span className="back-btn-icon">
                                      <FontAwesomeIcon
                                        icon={faLessThan}
                                        className="less-icon1 px-2"
                                      />
                                    </span>
                                  </p>
                                )}
                              </div>
                              <div className="">
                                <ProgressBar
                                  currentStep={current}
                                  totalSteps={totalSteps}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-xxl-6 col-xl-6 col-lg-5 col-md-5 col-6">
                            <div className="d-flex justify-content-end">
                              <p className="few-second mt-2 me-2">
                                Just a few steps to go
                              </p>
                              <div className="">
                                <RoundedBar
                                  percentage={jobSeekerData?.percentage}
                                  color={progressBarColor}
                                  borderWidth={borderWidth}
                                />
                              </div>
                            </div>
                          </div>
                        </Row>

                        <div className="wizard-form mt-3">
                          {/* {current === 1 && 

                          <Step5 handleNext={handleNext} />
                          } */}
                          {current === 1 && (
                            //Name 2
                            <Step1
                              handleNext={handleNext}
                              handlePrevious={handlePrevious}
                            />
                          )}
                          {/* {current === 3 && (
                            <Step8
                              handleNext={handleNext}
                              handlePrevious={handlePrevious}
                            />
                          )} */}
                          {current === 2 && (
                            //Gender 3
                            <Step3
                              handleNext={handleNext}
                              handlePrevious={handlePrevious}
                            />
                          )}
                          {current === 3 && (
                            //DOB 4
                            <Step4
                              handleNext={handleNext}
                              handlePrevious={handlePrevious}
                            />
                          )}
                          {current === 4 && (
                            //Email 5
                            <Step2
                              handleNext={handleNext}
                              handlePrevious={handlePrevious}
                            />
                          )}
                          {current === 5 && (
                            //City 6
                            <Step6
                              handleNext={handleNext}
                              handlePrevious={handlePrevious}
                            />
                          )}
                          {current === 6 && (
                            //Education 7
                            <Step7
                              handleNext={handleNext}
                              handlePrevious={handlePrevious}
                            />
                          )}
                          {current === 7 && (
                            //Certificate 8
                            <Step8
                              handleNext={handleNext}
                              handlePrevious={handlePrevious}
                            />
                          )}
                          {current === 8 && (
                            //Experience 8
                            <Step9
                              handleNext={handleNext}
                              handlePrevious={handlePrevious}
                            />
                          )}
                          {current === 9 && (
                            //Project 9
                            <Step10
                              handleNext={handleNext}
                              handlePrevious={handlePrevious}
                            />
                          )}
                          {/* {current === 10 && (
                            <Step11
                              handleNext={handleNext}
                              handlePrevious={handlePrevious}
                            />
                          )} */}
                        </div>
                      </form>
                    </div>
                    <div className=" col-md-3 col-12">
                      <div className="image-holder">
                        <img src={Stepform} className="signup-image" />
                        <div className="overlay-holder">
                          <div className="middle-content">
                            <h1>Don't let </h1>
                            <p>
                              the best career
                              <br />
                              opportunity <br />
                              slip off!
                            </p>
                            <p className="create">
                              Create <br />
                              Your Account Today!
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default UpdatedForm;
