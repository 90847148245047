import { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from "react-bootstrap/Button";
import PortfolioModalComponent from "./PortfolioModalComponent/PortfolioModalComponent";


const MyPortfolioModal = (props) => {
    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                fullscreen={true}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Modal heading
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                {/* <div className="text-center">
                        <span style={{float:'left'}} onClick={props.onHide}>Back</span>
                    </div> */}
                    <PortfolioModalComponent userId={props?.data} onHide={props.onHide}/>
                    <div className="text-center">
                        <Button onClick={props.onHide}>Close</Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};
export default MyPortfolioModal;
