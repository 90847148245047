import React from "react";
import AccountBanner from "./AccountBanner/AccountBanner";
import Account from "./Account/Account";

const AccountManagement = () => {
  return (
    <div>
      <AccountBanner />
      <Account />
    </div>
  );
};

export default AccountManagement;
