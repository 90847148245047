import React from "react";
import FirstTabBanner from "./FirstTabBanner/FirstTabBanner.js";
import MainTab from "./Tab/Tab.js";

const DashBoardMain = () => {
  return (
    <div>
      <FirstTabBanner />
      <MainTab />
    </div>
  );
};

export default DashBoardMain;
