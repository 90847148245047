import React, { useContext, useEffect, useRef, useState } from "react";
import "./ReviewSlider.css";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import "./ReviewSlider.css";

// import required modules
import { Autoplay, EffectCoverflow, Pagination } from "swiper/modules";
import { Context } from "../../../../utils/context";

function ReviewSlider() {
  var HtmlToReactParser = require("html-to-react").Parser;
  var htmlToReactParser = new HtmlToReactParser();
  const { getData, imageUrl } = useContext(Context);

  const [clientReviews, setClientReview] = useState();

  const getClientReview = async () => {
    const res = await getData("/without-login/home/client-review");
    if (res?.success) {
      // console.log(res.data, 'clientReviews');
      setClientReview(res.data);
    }
  };

  useEffect(() => {
    getClientReview();
  }, []);
  return (
    <>
      <section className="ReviewsMainSlider desktop-view-review">
        <div className="container">
          <h3 className="mt-5 reviews_heading">Good Reviews By Clients</h3>
          <Swiper
            effect={"coverflow"}
            grabCursor={true}
            centeredSlides={true}
            slidesPerView={3}
            coverflowEffect={{
              rotate: 50,
              stretch: 0,
              depth: 100,
              modifier: 1,
              slideShadows: true,
            }}
            pagination={true}
            modules={[EffectCoverflow, Pagination]}
            className="ReviewSwiper"
            initialSlide={1}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              360: {
                slidesPerView: 2,
              },
              640: {
                slidesPerView: 2,
              },
              768: {
                slidesPerView: 3,
              },
            }}
          >
            {clientReviews?.map((value, index) => (
              <SwiperSlide>
                <div className="slider-card">
                  <div className="content-holder">
                    <div className="imgSlider_sec ">
                      <img
                        src={imageUrl + value?.image}
                        className="man_img_slider"
                        alt="..."
                      />
                      {/* <div className="blue-overlay"></div> */}
                    </div>

                    <div className="colhide">
                      <div className="text-start">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/Images/banner/Reviews/Inverted_commas.png"
                          }
                          className="inverted_commas me-3"
                          alt="..."
                        />
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/Images/banner/Reviews/Inverted_commas.png"
                          }
                          className="inverted_commas"
                          alt="..."
                        />
                      </div>
                      <p className="Review_slider-txt mt-3">
                        {htmlToReactParser.parse(value?.description)}
                      </p>
                      <div className="slider_below_txt">{value?.name}</div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </section>

      <section section className="Reviews1 mobile-view-review">
        <div className="container">
          <div className="row  ">
            <h3 className="mt-5 reviews_heading">Good Reviews By Clients</h3>
            <div className="swiper_section_reviews mt-3">
              <Swiper
                slidesPerView={1}
                spaceBetween={10}
                pagination={{
                  clickable: true,
                }}
                loop={true}
                initialSlide={1}
                modules={[Pagination, Autoplay]}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
              >
                {clientReviews?.map((job, index) => (
                  <SwiperSlide>
                    <div className="row" key={index}>
                      <div className="col-5">
                        <div className="imgSlider_sec1 ps-1">
                          <img
                            src={imageUrl + job?.image}
                            className="slide-img"
                            alt="..."
                          />
                          {/* <img src={process.env.PUBLIC_URL + "/assets/Images/icons/slide-img.png"} className='slide-img' alt="..." /> */}
                          <div className="blue-overlay1 "></div>
                        </div>
                      </div>
                      <div className="col-7 mb-3">
                        <div className="text-start invertedImage">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "assets/Images/banner/Reviews/Inverted_commas.png"
                            }
                            className="inverted_commas me-1"
                            alt="..."
                          />
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "assets/Images/banner/Reviews/Inverted_commas.png"
                            }
                            className="inverted_commas"
                            alt="..."
                          />
                        </div>
                        {/* <p className='Review_slider-txt1 '>I wanted to thank you so much for your help with my job search. I secured a fabulous role… It'sexact="true"ly what I was looking for and I'm so excited to start! I have worked with a number of recruiters throughout my career, and your firm was easily the best.
                        </p> */}
                        <p className="Review_slider-txt1">
                          {htmlToReactParser.parse(job?.description)}
                        </p>
                        <p className="slider_below_txt1">{job?.name}</p>
                        {/* <div className='slider_below_txt1'>Aditya</div> */}
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ReviewSlider;
