import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "@fortawesome/fontawesome-svg-core/styles.css";
import Tab from "react-bootstrap/Tab";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select, { components } from "react-select";
import Job from "../UpdatedSingUp/Job";
import Freelancing from "../UpdatedSingUp/Freelancing";

import { useContext } from "react";
import { Context } from "../../../../utils/context";
import {
  faUpload,
  faFile,
  faXmark,
  faPlus,
  faMinus,
} from "@fortawesome/free-solid-svg-icons";
import { getData } from "../../../../utils/api";

const options = [
  { value: "Job Experience", label: "Job Experience" },
  { value: "FreeLancing", label: "FreeLancing" },
];

const MultiValueRemove = (props) => (
  <components.MultiValueRemove
    {...props}
    className="custom-multi-value-remove"
  />
);

const Step9 = ({ handlePrevious, handleNext }) => {
  const {
    getData,
    postData,
    recruiterData,
    jobSeekerData,
    editStatusData,
    getJobSeekerData,
    Select2Data,
    fetchSeekerData,
  } = useContext(Context);
  const [expType, setExpType] = useState(true);
  const [valueType, setValueType] = useState({
    value: "Job Experience",
    label: "Job Experience",
  });

  const [status, setStatus] = useState("none");
  const [freeStatus, setFreeStatus] = useState("none");
  const [currentStatus, setCurrentStatus] = useState(true);
  const [jobData, setJobData] = useState();
  const [freeData, setFreeData] = useState();

  const getCardData = async () => {
    const res = await getData(`/jobseeker/signup/experiance-job`);
    if (res?.success) {
      // console.log(res.data, "job");
      await setJobData(res.data);
      res?.data?.forEach((item) => {
        // console.log(item, "ssss");
        if (item?.org_type === 1) {
          setCurrentStatus(false);
        }
      });
    }

    const resFree = await getData(`/jobseeker/signup/experiance-freelancer`);
    if (resFree?.success) {
      // console.log(resFree.data, "free");
      await setFreeData(resFree.data);
    }
    if (res?.data.length === 0) {
      await setCurrentStatus(true);
    }
    if (res?.data.length === 0) {
      await setStatus(true);
    } else {
      await setStatus("none");
    }
    if (resFree?.data.length === 0) {
      await setFreeStatus(true);
    } else {
      await setFreeStatus("none");
    }
    await fetchSeekerData();
  };

  const handleJobDelete = async (id) => {
    var data = "";

    data = {
      step: jobData.length === 1 && freeData.length === 0 ? 8 : 9,
      status: jobData.length === 1 ? false : "none",
    };

    const res = await editStatusData(
      `/jobseeker/signup/experiance-job/${id}`,
      data
    );
    await getCardData();
    await fetchSeekerData();
  };

  const handleFreeDelete = async (id) => {
    var data = "";

    data = {
      step: jobData.length === 0 && freeData.length === 1 ? 8 : 9,
      status: freeData.length === 1 ? false : "none",
    };

    const res = await editStatusData(
      `/jobseeker/signup/experiance-freelancer/${id}`,
      data
    );
    await getCardData();
    await fetchSeekerData();
  };

  useEffect(() => {
    getCardData();
  }, []);

  return (
    <>
      <section className="eightstep StepForm text-start">
        <div className="container">
          <div className="d-flex  steps-heading">
            <div className="border-left" />
            <h5 className="stepsixtxt">Step 8</h5>
          </div>
          <p className="title">Add Job Experience</p>
          {jobData?.length != 0 && <h5>Job Experience</h5>}
          <Row className="">
            {jobData?.map((value, index) => (
              <Col
                className="col-md-5 col-lg-3 col-xl-3 col-xxl-3 col-12 mt-2"
                key={index}
              >
                <div className="msc-box">
                  <div className="tabs-btn p-2">
                    <p lassName="text-para">
                      <FontAwesomeIcon
                        icon={faXmark}
                        className="close-icon"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleJobDelete(value?.id)}
                      />
                    </p>
                    <div className="info">
                      <p>Company : {value?.company?.name}</p>
                      <p>Role: {value?.role?.name}</p>
                      <p>
                        Start Date:
                        {value?.start_date
                          ? new Date(value.start_date).toLocaleDateString(
                              "en-US",
                              {
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                              }
                            )
                          : "N/A"}
                      </p>
                      <p>
                        End Date:
                        {value?.end_date
                          ? new Date(value.end_date).toLocaleDateString(
                              "en-US",
                              {
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                              }
                            )
                          : "N/A"}
                      </p>

                      <p>Location :{value?.city?.name}</p>
                    </div>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
          {freeData?.length != 0 && <h5>Freelancing Experience</h5>}

          <Row className="">
            {freeData?.map((value, index) => (
              <Col
                className="col-md-5 col-lg-3 col-xl-3 col-xxl-3 col-12 mt-2"
                key={index}
              >
                <div className="msc-box">
                  <div className="tabs-btn p-2">
                    <p lassName="text-para">
                      <FontAwesomeIcon
                        icon={faXmark}
                        className="close-icon"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleFreeDelete(value?.id)}
                      />
                    </p>
                    <div className="info">
                      <p>Title : {value?.project_title}</p>
                      <p>Client Name: {value?.client_name}</p>
                      <p>Your Role: {value?.your_role}</p>
                      <p>
                        Start Date:
                        {value?.start_date
                          ? new Date(value.start_date).toLocaleDateString(
                              "en-US",
                              {
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                              }
                            )
                          : "N/A"}
                      </p>
                      <p>
                        End Date:
                        {value?.end_date
                          ? new Date(value.end_date).toLocaleDateString(
                              "en-US",
                              {
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                              }
                            )
                          : "N/A"}
                      </p>

                      <p>Role :{value?.your_role}</p>
                    </div>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
          <Row>
            <div className="col-md-6 col-sm-12 mb-md-3 mb-0">
              <Form.Label>Job Role </Form.Label>
              <Select
                placeholder="Select Experience Type"
                classNamePrefix="jobRole"
                value={valueType}
                options={options}
                isSearchable={true}
                onChange={async (inputValue) => {
                  // await setExpType(!expType);
                  await setValueType(inputValue);
                }}
              />
            </div>
          </Row>

          {valueType?.value === "Job Experience" ? (
            <Job
              getCardData={getCardData}
              status={status}
              currentStatus={currentStatus}
            />
          ) : (
            <Freelancing getCardData={getCardData} status={freeStatus} />
          )}
        </div>
      </section>

      <div className=" mobile-view-button mt-5">
        <div className="d-flex">
          <div className=" me-1">
            <button
              type="button"
              name="next"
              // disabled={cards?.length === 0 ? true : false}
              className="next action-button contin-btn"
              onClick={handleNext}
            >
              Skip
            </button>
          </div>
          <div className="ms-auto ">
            <button
              type="button"
              name="next"
              // disabled={cards?.length === 0 ? true : false}
              className="next action-button contin-btn"
              onClick={handleNext}
            >
              Continue
            </button>
          </div>
        </div>
      </div>

      <div className="row mt-5 pt-4 footer-sec">
        <div className="col-4 desktop-view-button">
          <div className="d-flex justify-content-end">
            <div className="">
              <Button className="btn education-btn1" onClick={handleNext}>
                skip
              </Button>
            </div>
            <div className="">
              <button
                type="button"
                name="next"
                className="next action-button contin-btn"
                onClick={handleNext}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Step9;
