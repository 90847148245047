import React from "react";
import "./AboutBanner.css";

const AboutBanner = () => {
  return (
    <section className="aboutbanner-section">
      <div className="container-fluid p-0 ">
        <div className="complete-banner">
          <div className="banner">
            <img src={process.env.PUBLIC_URL + "assets/Images/banner/aboutBanner.png"} alt="banner" className="banner-img" />
          </div>
          <div className="text-overlay ">
            <div className="row ">
              <div className="col-lg-6 col-xl-6 col-xxl-6 ">
                <div className="left-side">
                  <h5>About Hiclousia</h5>
                  <p className="line-second">Hiclousia is the #1 job site</p>
                  <p className="line-third">
                    in the world with over 350M+ <br></br>
                    unique visitors every month.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-xl-6 col-xxl-6">
                <div className="right-side">
                  <div className="row">
                    <div className="col-3 p-0">
                      <div className="first-img">
                        <img src={process.env.PUBLIC_URL + "assets/Images/banner/BannerImg-1.png"} alt="banner" className="banner-first-img" />
                      </div>
                    </div>
                    <div className="col-3 p-0">
                      <p className="jobs-text">JOBS </p>
                    </div>
                    <div className="col-3 p-0">
                      <div className="second-img">
                        <img src={process.env.PUBLIC_URL + "assets/Images/banner/BannerImg-4.png"} alt="banner" className="banner-first-img" />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-3 p-0">
                      <div className="third-img">
                        <img src={process.env.PUBLIC_URL + "assets/Images/banner/BannerImg-2.png"} alt="banner" className="banner-first-img" />
                      </div>
                    </div>
                    <div className="col-3 p-0">
                      <div className="fifth-img">
                        <img src={process.env.PUBLIC_URL + "assets/Images/banner/BannerImg-3.png"} alt="banner" className="banner-first-img" />
                        <div >
                          <p className=" px-2 mt-lg-3 mt-md-0">more than</p>
                          <p className="bottom-line px-2">one can simply expect!</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-3 p-0">
                      <div className="forth-img">
                        <img src={process.env.PUBLIC_URL + "assets/Images/banner/BannerImg-5.png"} alt="banner" className="horizontal-img" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutBanner;
