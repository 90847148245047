import React, { useEffect, useState } from "react";
import "../Notification/Notification.css";
import { Link } from "react-router-dom";

import { useContext } from "react";
import { Context } from "../../../../utils/context";
const Notification = (props) => {
  const calculateHoursAgo = (updatedAt) => {
    const updatedTime = new Date(updatedAt);
    const currentTime = new Date();

    const timeDifferenceInMilliseconds = currentTime - updatedTime;
    const timeDifferenceInHours =
      timeDifferenceInMilliseconds / (1000 * 60 * 60);

    return Math.round(timeDifferenceInHours); // Round to the nearest whole number
  };

  const { ReactLoader, setReactLoader, getData } = useContext(Context);
  const [notifications, setNotification] = useState();
  const getNotifications = async () => {
    const res = await getData(`/common/notifications`);
    if (res?.success) {
      setNotification(res?.data);
    }
  };

  useEffect(() => {
    
      getNotifications();
   
  }, [props.show]);

  // notifications;
  return (
    <>
      <section className="notification">
        <div className="container">
          {notifications?.map((value, index) => (
            <div className="notiication-text-holder">
              <div className="row ">
                <div className="col-md-8">
                  <div className="first-notification">
                    <p>
                      {value?.comapny_name}{" "}
                      <span>
                        {" "}
                        {value?.message}
                        {value?.count !== 0 && " : " + value?.count}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="first-notification-line">
                    <p>
                      {calculateHoursAgo(value?.updatedAt)}{" "}
                      {calculateHoursAgo(value?.updatedAt) === 1
                        ? "Hour"
                        : "Hours"}{" "}
                      Ago
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default Notification;
