import React, { useEffect, useState, useRef } from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import { Link, useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import Animation from "../../../RecruiterPage/LoginSignUp/login-page/animation/Animation";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";

import Cookies from "js-cookie";
import SignUpModal from "../SignUpModal/ContactModal";
import ContactModal from "../ContactModal/ContactModal";
import { useContext } from "react";
import { Context } from "../../../../utils/context";

const GetotpJobseeker = () => {
  const { postData, PostGoogleLogin, setSignin, setUsertype } =
    useContext(Context);
  const navigate = useNavigate();
  const [phone, setPhone] = useState("");

  const [mobileNo, setMobile] = useState("");

  const [modalShow, setModalShow] = useState(false);
  const [signModalShow, setSignModalShow] = useState(false);
  const [timerShow, setTimerShow] = useState(false);

  const [seconds, setSeconds] = useState(60); // Initial value is 60 seconds
  const [isTimerExpired, setIsTimerExpired] = useState(true);
  const [otpERR, setOtpErr] = useState("");

  const [otpsend, setOtpSend] = useState(null);

  const [authToken, setAuthToken] = useState(null);
  const [authType, setAuthType] = useState(null);
  const [steps, setSteps] = useState(null);

  const [mobileErr, setMobileErr] = useState("");

  const handleValidation = async () => {
    var mobile = phone;

    // Initialize an array to store error messages
    var errors = [];

    if (mobile.trim() === "") {
      setMobileErr("Mobile Number is required");
      errors.push("Mobile Number is required");
    } else if (!/^\d{10}$/.test(mobile)) {
      setMobileErr("Mobile Number should be a 10-digit number");
      errors.push("Mobile Number should be a 10-digit number");
    }

    if (errors.length === 0) {
      // console.log("otp");
      setMobileErr("");
      const randOtp = Math.floor(1000 + Math.random() * 9000).toString();
      // console.log(randOtp, "random");

      const res = await postData("/without-login/jobseeker/sign-in", {
        contact_no: "91" + mobile,
        otp: randOtp,
      });

      if (res?.success) {
        // console.log(res, "mobileotp");
        setAuthToken(res.data.token);
        setAuthType(res.data.type);
        setSteps(res.data.steps);
        setOtpSend(randOtp);
        setModalShow(true);
        setTimerShow(true);
        setIsTimerExpired(false);

        setOtpErr("");
        setOtpValues(["", "", "", ""]);
        setSeconds(60);
        setTimeout(() => {
          setModalShow(false);
        }, 4000);
      } else {
        // console.log(res, "fail");
        setMobileErr(res?.message);
      }
    }
  };

  const [otpValues, setOtpValues] = useState(["", "", "", ""]);
  const inputRefs = [useRef(), useRef(), useRef(), useRef()];

  const handleOtpChange = (index, event) => {
    setOtpErr("");
    const inputValue = event.target.value;

    if (/^[0-9]*$/.test(inputValue) || inputValue === "") {
      otpValues[index] = inputValue;
      setOtpValues([...otpValues]);

      if (otpsend === otpValues.join("")) {
        // Call your OTP verification function here

        setOtpErr("");
        setOtpValues(["", "", "", ""]);
        Cookies.set("hiclousia_website", authToken, { expires: 1 });
        Cookies.set("jwtType", authType, { expires: 1 });
        setSignModalShow(true);
        setModalShow(true);

        setTimeout(() => {
          setSignModalShow(false);
          setOtpSend(null);
          // console.log(steps);
          setSignin(true);
          setUsertype(authType);
          if (steps > 5) {
            navigate("/dashboard-job");
          } else {
            navigate(`/signup-job/${steps}`);
          }
          // window.location.href = '/dashboardmain';
        }, 4000);
      }
      if (otpsend !== otpValues.join("")) {
        if (inputValue !== "" && index < 3) {
          inputRefs[index + 1].current.focus();
        } else {
          setOtpErr("OTP Invalid!");
        }
      }
    }
  };

  const handleKeyDown = (index, event) => {
    setOtpErr("");
    if (event.key === "Backspace" && index > 0 && otpValues[index] === "") {
      otpValues[index - 1] = "";
      setOtpValues([...otpValues]);
      inputRefs[index - 1].current.focus();
    }
  };

  const handleMobileChange = (event) => {
    // Remove non-numeric characters
    const newValue = event.target.value.replace(/[^0-9]/g, "");

    // Set the new value to the state
    setMobile(newValue);

    // Clear mobileErr when the input changes
    setMobileErr("");
  };

  // const clientId =
  //   "645433162174-3jt137c3nmjbt4n1u41dara5ed1mi4vu.apps.googleusercontent.com";
  // const handleSuccess = async (credentialResponse) => {
  //   const decodedToken = jwtDecode(credentialResponse?.credential);
  //   console.log(decodedToken);

  //   if (decodedToken?.email) {
  //     const data = {
  //       email: decodedToken?.email,
  //       first_name: decodedToken?.given_name,
  //       last_name: decodedToken?.family_name,
  //       type: "Job Seeker",
  //     };
  //     const res = await postData(`/without-login/google-login`, data);
  //     console.log(res);
  //     if (res?.success) {
  //       console.log(res, "SUCCCESS");

  //       Cookies.set("hiclousia_website", res.data.token, { expires: 1 });
  //       Cookies.set("jwtType", res.data.type, { expires: 1 });
  //       setSignModalShow(true);
  //       setModalShow(true);

  //       setTimeout(() => {
  //         setSignModalShow(false);
  //         window.location.href = "/dashboardmain"; // Replace '/' with your desired URL
  //       }, 4000);
  //     } else {
  //       console.log(res, "fails");
  //     }
  //   }
  // };

  const handleSuccess = async (data) => {
    const values = {
      token: data.credential,
      type: "Job Seeker",
    };

    const res = await PostGoogleLogin("/without-login/google-login", values);
    // console.log(res);
    if (res?.success) {
      Cookies.set("hiclousia_website", res.data.token, { expires: 1 });
      Cookies.set("jwtType", res.data.type, { expires: 1 });
      setSignModalShow(true);
      setTimeout(() => {
        setSignModalShow(false);
        if (res.data.steps > 9) {
          window.location.href = "/dashboard-job";
        } else {
          window.location.href = `/signup-job/${res.data.steps}`;
        }
        // window.location.href = '/dashboardmain';
      }, 4000);
    } else {
      // console.log(res, "fails");
      setMobileErr(res?.message);
    }
  };

  const handleError = () => {
    // console.log("Login Failed");
  };

  useEffect(() => {
    const decrementTimer = () => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else {
        setIsTimerExpired(true);
        setOtpSend(null);
      }
    };

    const timerInterval = setInterval(decrementTimer, 1000);

    return () => {
      clearInterval(timerInterval);
    };
  }, [seconds]);

  return (
    <>
      <section className="login">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 ">
              <div className="image-login">
                <img
                  src={
                    process.env.PUBLIC_URL + "assets/Images/banner/login.png"
                  }
                  className="login-img"
                />
                <div className="image-overlay">
                  <Animation />
                </div>
              </div>
            </div>
            <div className="col-lg-8 mt-2">
              <div className="row bg-color">
                <div className="col-md-8 mx-auto">
                  <div className="heading-holder mb-3">
                    <p>Jobseeker Log In</p>
                    <h4>Log In with Mobile Number</h4>
                  </div>
                  <div className="row">
                    <p>Country Code</p>
                    <div className="col-lg-3 col-md-3 col-4">
                      <Form.Select aria-label="Default select example">
                        <option>+91</option>
                      </Form.Select>
                      {/* <PhoneInput
                                                className="phoneInput"
                                                country={"in"} // set the initial country code
                                                enableSearch={true}
                                                value={phone}
                                                readonly=""
                                                onChange={(phone) => {
                                                    // Add a space after the country code
                                                    const formattedPhone = phone.replace(/(\+\d{1,4})(\d{3,14})/, '$1 $2');
                                                    setPhone(formattedPhone);
                                                    setMobileErr('');
                                                }}
                                            /> */}
                    </div>
                    <div className="col-lg-6 col-md-9 col-8">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Control
                          type="text"
                          minlength="10"
                          maxlength="10"
                          disabled={isTimerExpired === true ? false : true}
                          placeholder="Mobile Number"
                          style={{ textAlign: "left" }}
                          onKeyDown={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              if (event.key != "Backspace")
                                event.preventDefault();
                            }
                          }}
                          onChange={(event) => {
                            // Add a space after the country code
                            setPhone(event.target.value);
                            setMobileErr("");
                          }}
                        />
                      </Form.Group>
                    </div>

                    <div className="col-lg-3">
                      <div className="text-lg-start text-md-center text-center">
                        {/* <Link to={"/login-recruiter"}> */}
                        {timerShow == false && (
                          <Button
                            variant="primary changeNobtn"
                            onClick={handleValidation}
                          >
                            Get Otp
                          </Button>
                        )}

                        {timerShow && isTimerExpired == true && (
                          <Button
                            variant="primary changeNobtn"
                            onClick={handleValidation}
                          >
                            Resend OTP
                          </Button>
                        )}

                        {/* </Link> */}
                      </div>
                    </div>
                  </div>
                  <span
                    className="text text-danger"
                    style={{
                      textAlign: "center",
                    }}
                  >
                    {mobileErr && mobileErr}
                  </span>
                  <div className="col-lg-12 col-md-12 col-12">
                    {timerShow && (
                      <div className="row text-center">
                        <div className="otpContainer">
                          {otpValues.map((value, index) => (
                            <input
                              className="otp"
                              type="text"
                              maxLength="1"
                              value={value}
                              onChange={(e) => handleOtpChange(index, e)}
                              onKeyDown={(e) => handleKeyDown(index, e)}
                              ref={inputRefs[index]}
                            />
                          ))}
                        </div>
                        <p className="otp">
                          Enter OTP within{" "}
                          {`${Math.floor(seconds / 60)}:${(seconds % 60)
                            .toString()
                            .padStart(2, "0")}`}{" "}
                          seconds
                        </p>
                        <span
                          className="text text-danger"
                          style={{ textAlign: "center" }}
                        >
                          {otpERR && otpERR}
                        </span>
                      </div>
                    )}
                  </div>
                  <div>
                    <ContactModal
                      show={modalShow}
                      onHide={() => setModalShow(false)}
                    />
                  </div>
                  <div>
                    <SignUpModal
                      show={signModalShow}
                      onHide={() => setSignModalShow(false)}
                    />
                  </div>
                  <div className="row Login_below_info mt-5">
                    <p className="social_txt text-center">
                      Log In with Social Accounts
                    </p>
                    <div className="text-center">
                      {/* <img
                        src={
                          process.env.PUBLIC_URL +
                          "assets/Images/icons/google logo.png"
                        }
                        className="google_img me-2"
                      /> */}

                      <GoogleOAuthProvider clientId="189802957201-b49b89i8t1t0cbcfuedll714lo1d2r0r.apps.googleusercontent.com">
                        <GoogleLogin
                          onSuccess={(credentialResponse) => {
                            handleSuccess(credentialResponse);
                            // console.log(credentialResponse);
                          }}
                          onError={() => {
                            // console.log("Login Failed");
                          }}
                        />
                      </GoogleOAuthProvider>
                      {/* <img
                        src={
                          process.env.PUBLIC_URL +
                          "assets/Images/icons/facebook.png"
                        }
                        className="google_img"
                      /> */}
                    </div>
                  </div>
                  <div className="user-holder text-center mt-4 mb-2">
                    {/* <p>New to Hiclousia?<Link to={"/signup-job"}><span className='ms-2'>Create Account</span></Link></p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default GetotpJobseeker;
