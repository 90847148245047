import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "../ThirdTab/ThirdTab.css";
import FirstTabBanner from "../FirstTabBanner/FirstTabBanner";
import { Link } from "react-router-dom";
import Shortlisted from "./Shortlisted_portfolio_modal/Shortlisted";
import OnHold from "./OnHold_modal/OnHold";
import MyPortfolioModal from "../../../JobseekerPage/my-portfolio/my-portfolio-modal/MyPortfolioModal";
import Reject from "./Reject_modal/Reject";
import { useEffect, useState, useContext } from "react";
import { Context } from "../../../../utils/context.js";
const ThirdTab = (props) => {
  const { getData, postData, NoDataAnimations } = useContext(Context);
  const [modalShow, setModalShow] = useState(false);

  const [modalShow1, setModalShow1] = useState(false);
  const openModal_Shortlist = async (value) => {
    const data = {
      jobseeker_id: value?.id,
      job_id: jobData?.id,
      name: value?.first_name + " " + value?.last_name,
      email: value?.email,
      contact: value?.contact_no,
    };

    const res = await postData(`/recruiters/job/all-matched`, data);
    if (res?.success) {
      setModalShow1(true);

      setTimeout(() => {
        props?.getMatched();
        props?.setDisplay(false);
        setModalShow1(false);
      }, 3000);
    }
  };

  const [modalShow2, setModalShow2] = useState(false);
  const openModal_Hold = () => {
    setModalShow2(true);

    setTimeout(() => {
      setModalShow2(false);
    }, 3000);
  };

  const [modalShow3, setModalShow3] = useState(false);
  const openModal_Reject = () => {
    setModalShow3(true);

    setTimeout(() => {
      setModalShow3(false);
    }, 3000);
  };

  const [jobData, setJobData] = useState();
  const [userData, setUserData] = useState();

  useEffect(() => {
    setJobData(props?.data?.job);
    setUserData(props?.data?.users);
  }, [props]);
  return (
    <>
      <section className="manage-job third-candii-card">
        {/* <div>
                    <FirstTabBanner />
                </div> */}

        <div className="container">
          <div className="row mt-md-3 mt-0">
            <div className="col-md-12">
              {userData?.map((value, index) => (
                <div
                  key={index}
                  className="manage-job-text mt-4 p-md-4 p-2 mb-4 bg-body rounded"
                >
                  <div className="row">
                    <div className="col-lg-2 col-md-3">
                      <div className="text-holder-manage-job">
                        <p className="thirdTabinfo">Job Role:</p>
                        <h5>{jobData?.role?.name}</h5>
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-3">
                      <div className="text-holder-manage-job">
                        <p className="thirdTabinfo">Location of Candidate:</p>
                        <h5>{value?.users_detail?.city?.name}</h5>
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-3">
                      <div className="text-holder-manage-job">
                        <p className="thirdTabinfo">Candidate's Name:</p>
                        <h5>
                          {value?.first_name} {value?.last_name}
                        </h5>
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-3">
                      <div className="text-holder-manage-job">
                        <p className="thirdTabinfo">Portfolio Matched:</p>
                        <Link onClick={() => setModalShow(true)}>
                          <span className="me-2"></span> View Portfolio
                        </Link>
                      </div>
                    </div>
                    <MyPortfolioModal
                      show={modalShow}
                      data={value?.id}
                      onHide={() => setModalShow(false)}
                    />
                    <div className="col-lg-4 col-md-12">
                      <div className="shortlisting text-lg-center flexxx-btn col-md-start">
                        <p>Shortlisting</p>
                        <button
                          className="btn shortlist-btn me-2"
                          onClick={() => openModal_Shortlist(value)}
                        >
                          Shortlist
                        </button>
                        {/* <button className="btn hold-btn me-2" onClick={openModal_Hold}>On Hold</button> */}
                        {/* <button className="btn reject-btn" onClick={openModal_Reject}>Reject</button> */}
                      </div>

                      <Shortlisted
                        show={modalShow1}
                        onHide={() => setModalShow1(false)}
                      />

                      {/* <OnHold
                                                show={modalShow2}
                                                onHide={() => setModalShow2(false)}
                                            />

                                            <Reject
                                                show={modalShow3}
                                                onHide={() => setModalShow3(false)}
                                            /> */}
                    </div>
                  </div>
                </div>
              ))}
              {userData?.length === 0 && <NoDataAnimations />}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ThirdTab;
