import React, { useContext, useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { Link } from "react-router-dom";
import { Context } from "../../../../utils/context";
import HorizantalGraph from "../chart-section/HorizantalGraph";
ChartJS.register(ArcElement, Tooltip, Legend);

const PrimarySkill = () => {
  const {
    postData,
    jobSeekerData,
    setJobSeekerData,
    getJobSeekerData,
    getData,
  } = useContext(Context);

  const getPrimarySkillData = async () => {
    const res = await getData("/jobseeker/profile/primary-skills");

    if (res?.success) {
      setSkills(res.data.skills);
      setCount(res.data.count);
      setLabel(res.data.label);
      setData({
        series: [
          {
            data: res.data.count,
          },
        ],
        options: {
          chart: {
            type: "bar",
            height: 350,
          },
          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: true,
            },
          },
          dataLabels: {
            // enabled: false,
          },
          xaxis: {
            categories: res.data.label,
          },
        },
      });
    }
  };

  useEffect(() => {
    getPrimarySkillData();
  }, []);
  const [label, setLabel] = useState([]);
  const [count, setCount] = useState([]);
  const [data, setData] = useState({
    series: [
      {
        data: count,
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
        },
      },
      dataLabels: {
        // enabled: false,
      },
      xaxis: {
        categories: label,
      },
    },
  });
  const [skills, setSkills] = useState([]);

  return (
    <>
      <div className=" PrimarySkill_main row mt-3">
        <div className="col-md-12 p-0">
          <div className="card">
            <div className="skill-holder1">
              <h4>Primary Skills</h4>
            </div>
            <div className="row mt-4 mb-3">
              <div className="col-lg-6 col-12">
                {/* <Pie data={data} className="skill-chart" /> */}
                <HorizantalGraph data={data} />
              </div>
              <div className="col-lg-6 col-12 ">
                <div className="mt-2">
                  {skills?.map((val, index) => (
                    <div className="primarySkills table-responsive" key={index}>
                      <table className="table table-bordered">
                        <tbody>
                          <tr>
                            <td
                              rowSpan={val?.skills?.length}
                              className="development_first"
                            >
                              {val?.skill_type?.name}
                            </td>
                            <td>
                              {val?.skills?.map((d, index) => (
                                <tr key={index}>
                                  <td>{d?.name}</td>
                                </tr>
                              ))}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrimarySkill;
