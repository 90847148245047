import React from 'react'
import { Link } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "@fortawesome/fontawesome-svg-core/styles.css";
import Select, { components } from "react-select";
import {

    faXmark,

} from "@fortawesome/free-solid-svg-icons";
const EditPrimarySkill = () => {
    const options = [
        { value: "HTML", label: "HTML" },
        { value: "CSS", label: "CSS" },
        { value: "Bootstrap", label: "Bootstrap" },
    ];

    const MultiValueRemove = (props) => (
        <components.MultiValueRemove {...props}>
            <span style={{ cursor: "pointer", marginRight: "5px" }}>&#x2715;</span>
        </components.MultiValueRemove>
    );
    return (
        <>
            <section className='portfolio-experience'>
                <div className='container'>
                    <div className='row'>
                        <div className='heading-holder mb-3 text-center'>
                            <h4>My Portfolio</h4>
                        </div>
                    </div>

                    <div className='black-header'>
                        <div className='row'>
                            <div className='col-md-4 col-4'>
                                <Link to='/dashboard-job'>
                                    <div className='text-holder pt-2 ms-3'>
                                        <p>Back</p>
                                    </div></Link>
                            </div>
                            <div className='col-md-4 col-4'>
                                <div className='text-holder text-center pt-2'>
                                    <h5>Primary Skills</h5>
                                </div>
                            </div>
                            <div className='col-md-4 col-4'>
                                <Link to='/my-portfolio'>
                                    <div className='text-holder text-end pt-2 me-3'>
                                        <p>Save & Close</p>
                                    </div></Link>
                            </div>
                        </div>
                    </div>

                    <div className='form-bg'>
                        <Form>
                            <div className='company-name'>
                                <h4>Tell recruiters what you know or what you are known for e.g. Direct Marketing, Oracle, Java etc.
                                    We will send you job recommendations based on these skills. Each skill is separated by a comma.</h4>
                            </div>
                            <div className='position-holder'>
                                <p>Skills</p>
                            </div>
                            <div className='mb-4'>
                                <button className='language-btn me-2 mb-md-0 mb-2'>Java  <FontAwesomeIcon icon={faXmark} className="cross-icon" /></button>
                                <button className='language-btn me-2 mb-md-0 mb-2'>Python  <FontAwesomeIcon icon={faXmark} className="cross-icon" /></button>
                                <button className='language-btn me-2 mb-md-0 mb-2'>HTML  <FontAwesomeIcon icon={faXmark} className="cross-icon" /></button>
                                <button className='language-btn me-2 mb-md-0 mb-2'>React  <FontAwesomeIcon icon={faXmark} className="cross-icon" /></button>
                                <button className='language-btn me-2 mb-md-0 mb-2'>Node Js  <FontAwesomeIcon icon={faXmark} className="cross-icon" /></button>
                                <button className='language-btn mb-md-0 mb-2'>Js  <FontAwesomeIcon icon={faXmark} className="cross-icon" /></button>
                            </div>

                            <Form.Group as={Row} className="mb-4" controlId="formPlaintextTitle">

                                <Col lg="12">
                                    <Form.Control type="text" placeholder="Enter Your Skills / Specialization" className='select-data' />
                                </Col>
                            </Form.Group>

                            {/* <Select
                                isMulti
                                closeMenuOnSelect={false}
                                options={options}
                                className="multiselector"
                                components={{
                                    MultiValueRemove,

                                }}
                            /> */}


                            <div className='position-holder mt-5'>
                                <p className='mb-2'>Or you can select from the suggested set of skills</p>
                            </div>
                            <div className='mb-md-4 mb-0 '>
                                <button className='language-btn me-2 mb-md-0 mb-2'>Spring  <FontAwesomeIcon icon={faXmark} className="cross-icon" /></button>
                                <button className='language-btn me-2 mb-md-0 mb-2'>Java Spring Boot  <FontAwesomeIcon icon={faXmark} className="cross-icon" /></button>
                                <button className='language-btn me-2 mb-md-0 mb-2'>Swing  <FontAwesomeIcon icon={faXmark} className="cross-icon" /></button>
                                <button className='language-btn me-2 mb-md-0 mb-2'>Java Support  <FontAwesomeIcon icon={faXmark} className="cross-icon" /></button>
                                <button className='language-btn me-2 mb-md-0 mb-2'>Kotlin  <FontAwesomeIcon icon={faXmark} className="cross-icon" /></button>
                            </div>
                            <div className='button-holder text-center mt-md-5 mt-3 mb-md-0 mb-3'>
                                <button className='btn btn-save' >Save</button>
                            </div>


                        </Form>

                    </div>
                </div>
            </section>
        </>
    )
}

export default EditPrimarySkill