import React, { useState, useEffect, useRef } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { InputGroup } from "react-bootstrap";
import CreateJobs from "../../ManageJobs/CreateJobs/CreateJobs";
import Select from "react-select";
import Form from "react-bootstrap/Form";
import { Link, json, useNavigate } from "react-router-dom";
import Row from "react-bootstrap/Row";
import JoditEditor from "jodit-react";
import { PacmanLoader } from "react-spinners";
import SignUpModal from "../SignUpModal/ContactModal";

import { useContext } from "react";
import { Context } from "../../../../../utils/context";
import Autosuggest from "react-autosuggest";
const CreateJobModal = (props) => {
  const navigate = useNavigate();
  const editor = useRef(null);

  const [activePool, setActivePool] = useState(props);

  const [loader, setLoader] = useState(false);

  const {
    getData,
    postData,
    recruiterData,
    setRecruiterData,
    Select2Skills,
    createdStatus,
    setCreatedStatus,
  } = useContext(Context);
  const options = [];

  const [no_of_vacancy, setVacancy] = useState("");
  const [description, setDescription] = useState("");
  const [role_and_permission, setRoleRequired] = useState("");
  const [skills_required, setSkillRequired] = useState("");
  const [contact_no, setContact] = useState("");
  const [email, setEmail] = useState("");
  const [deadline, setDeadline] = useState("");
  const [recruiter_name, setRecruiter] = useState("");
  const [start_salary, setStartSalary] = useState("");
  const [end_salary, setEndSalary] = useState("");
  const [career_level_id, setSelectedCareerOption] = useState("");
  const [experience_id, setSelectedExperienceOption] = useState("");
  const [job_title_id, setSelectedTitleOption] = useState("");
  const [industry_id, setSelectedIndustryOption] = useState("");
  const [sector_id, setSelectedSectorOption] = useState("");
  const [roles_id, setSelectedRoleOption] = useState("");
  const [qualification_id, setSelectedQualificationOption] = useState("");
  const [job_type_id, setSelectedTypeOption] = useState([]);
  const [city_id, setSelectedCityOption] = useState([]);
  const [primary_skill_id, setSelectedPrimaryOption] = useState([]);
  const [secondary_skill_id, setSelectedSecondaryOption] = useState([]);
  const [shift_id, setSelectedShiftOption] = useState([]);
  const [language_id, setSelectedLanguageOption] = useState([]);

  const [vacancyErr, setVacancyErr] = useState("");
  const [startErr, setStartErr] = useState("");
  const [endErr, setEndErr] = useState("");
  const [deadlineErr, setDeadlineErr] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [mobileErr, setMobileErr] = useState("");
  const [recruiterErr, setRecruiterErr] = useState("");
  const [descriptionErr, setDescriptionErr] = useState("");
  const [roleIdErr, setRoleIdErr] = useState("");
  const [rolesErr, setRoleErr] = useState("");
  const [skillErr, setSkillErr] = useState("");

  // Qualifications API
  const [qualificationErr, setQualificationErr] = useState("");

  const [qualifications, setQualification] = useState();

  const getQualifications = async () => {
    const res = await getData("/without-login/master/all-degree");
    if (res?.success) {
      // console.log(res.data, "qualifications");
      const mergedOptions = [
        ...options,
        ...res.data.map((city) => ({ value: city?.id, label: city?.name })),
      ];
      // console.log(mergedOptions, "options");
      setQualification(mergedOptions);
    }
  };

  const handleQualificationChange = (selectedValues) => {
    setQualificationErr("");
    setSelectedQualificationOption(selectedValues);
  };
  //Qualifications API END

  // JobTypes API
  const [typeErr, setTypeErr] = useState("");

  const [jobTypes, setJobType] = useState();

  const getTypes = async () => {
    const res = await getData("/without-login/master/all-job-type");
    if (res?.success) {
      // console.log(res.data, "jobTypes");
      const mergedOptions = [
        ...options,
        ...res.data.map((city) => ({ value: city?.id, label: city?.name })),
      ];
      // console.log(mergedOptions, "options");
      setJobType(mergedOptions);
    }
  };

  const handleTypeChange = (selectedValues) => {
    setTypeErr("");
    setSelectedTypeOption(selectedValues);
  };
  //JobTypes API END

  // Cities API
  const [livingErr, setLivingErr] = useState("");

  const [cities, setCities] = useState();

  const getCities = async () => {
    const res = await getData("/without-login/master/all-cities");
    if (res?.success) {
      // console.log(res.data, "cities");
      const mergedOptions = [
        ...options,
        ...res.data.map((city) => ({ value: city?.id, label: city?.name })),
      ];
      // console.log(mergedOptions, "options");
      setCities(mergedOptions);
    }
  };

  const handleCityChange = (selectedValues) => {
    setLivingErr("");
    setSelectedCityOption(selectedValues);
  };
  //Cities API END

  // Languages API
  const [languageErr, setLanguageErr] = useState("");

  const [languages, setLanguages] = useState();

  const getLanguages = async () => {
    const res = await getData("/without-login/master/all-languages");
    if (res?.success) {
      // console.log(res.data, "languages");
      const mergedOptions = [
        ...options,
        ...res.data.map((city) => ({ value: city?.id, label: city?.name })),
      ];
      // console.log(mergedOptions, "options");
      setLanguages(mergedOptions);
    }
  };

  const handleLanguageChange = (selectedValues) => {
    setLanguageErr("");
    setSelectedLanguageOption(selectedValues);
  };
  //Languages API END

  // Shift API
  const [shiftErr, setShiftErr] = useState("");

  const [shifts, setShift] = useState();

  const getShifts = async () => {
    const res = await getData("/without-login/master/all-shifts");
    if (res?.success) {
      // console.log(res.data, "shifts");
      const mergedOptions = [
        ...options,
        ...res.data.map((city) => ({ value: city?.id, label: city?.name })),
      ];
      // console.log(mergedOptions, "options");
      setShift(mergedOptions);
    }
  };

  const handleShiftChange = (selectedValues) => {
    setShiftErr("");
    setSelectedShiftOption(selectedValues);
  };
  //Shift API END

  // Skills API

  const [primaryErr, setPrimaryErr] = useState("");

  const [secondaryErr, setSecondaryErr] = useState("");

  const [skills, setSkills] = useState();

  const getSkills = async () => {
    const res = await getData("/without-login/master/all-skills");
    if (res?.success) {
      // console.log(res.data, "skills");
      // const mergedOptions = [
      //   ...options,
      //   ...res.data.map((city) => ({ value: city?.id, label: city?.name })),
      // ];
      // console.log(mergedOptions, "options");
      setSkills(await Select2Skills(res.data, "skills"));
    }
  };

  const handlePrimaryChange = (selectedValues) => {
    setPrimaryErr("");
    setSelectedPrimaryOption(selectedValues);
  };
  const handleSecondaryChange = (selectedValues) => {
    setSecondaryErr("");
    setSelectedSecondaryOption(selectedValues);
  };
  //Skills API END

  // career Level API
  const [careerErr, setCareerErr] = useState("");

  const [career, setCareer] = useState();

  const getCareer = async () => {
    const res = await getData("/without-login/master/all-career-level");
    if (res?.success) {
      // console.log(res.data, "career");
      const mergedOptions = [
        ...options,
        ...res.data.map((city) => ({ value: city?.id, label: city?.name })),
      ];
      // console.log(mergedOptions, "options");
      setCareer(mergedOptions);
    }
  };

  const handleCareerChange = (selectedValues) => {
    setCareerErr("");
    setSelectedCareerOption(selectedValues);
  };
  //career Level API END

  // Experience Level API
  const [experienceErr, setExperienceErr] = useState("");

  const [experience, setExperience] = useState();

  const getExperience = async () => {
    const res = await getData("/without-login/master/all-experience-in-year");
    if (res?.success) {
      // console.log(res.data, "career");
      const mergedOptions = [
        ...options,
        ...res.data.map((city) => ({ value: city?.id, label: city?.name })),
      ];
      // console.log(mergedOptions, "options");
      setExperience(mergedOptions);
    }
  };

  const handleExperienceChange = (selectedValues) => {
    setExperienceErr("");
    setSelectedExperienceOption(selectedValues);
  };
  //Experience Level API END

  // JobTitle API
  const [titleErr, setTitleErr] = useState("");
  const [jobTitles, setJobTitle] = useState();
  const [otherTitle, setOtherTitle] = useState(false);
  const [other_job_title, setOtherJobTitle] = useState("");

  const getJobTitles = async () => {
    const res = await getData("/without-login/master/all-job-title");
    if (res?.success) {
      // console.log(res.data, "titles");
      // Options fetched from res.data
      const fetchedOptions = res.data.map((city) => ({
        value: city?.id,
        label: city?.name,
      }));
      // Additional options
      const additionalOptions = [
        { value: "other", label: "Other" },
        // Add more additional options as needed
      ];
      // Merge fetched options with additional options
      const mergedOptions = [
        ...options,
        ...fetchedOptions,
        ...additionalOptions,
      ];
      // console.log(mergedOptions, "options");
      setJobTitle(mergedOptions);
    }
  };

  const handleTitleChange = (selectedValues) => {
    setTitleErr("");
    setSelectedTitleOption(selectedValues);
    if (selectedValues?.value === "other") {
      setOtherTitle(true);
    } else {
      setOtherJobTitle("");
      setOtherTitle(false);
    }
  };
  //JobTitle API END

  // JobIndustry API
  const [industryErr, setIndustryErr] = useState("");
  const [jobIndustrys, setJobIndustry] = useState();
  const [otherIndustry, setOtherIndustry] = useState(false);
  const [other_job_industry, setOtherJobIndustry] = useState("");

  const getJobIndustrys = async () => {
    const res = await getData("/without-login/master/all-industry");
    if (res?.success) {
      // console.log(res.data, "titles");
      // Options fetched from res.data
      const fetchedOptions = res.data.map((city) => ({
        value: city?.id,
        label: city?.name,
      }));
      // Additional options
      const additionalOptions = [
        { value: "other", label: "Other" },
        // Add more additional options as needed
      ];
      // Merge fetched options with additional options
      const mergedOptions = [
        ...options,
        ...fetchedOptions,
        ...additionalOptions,
      ];
      // console.log(mergedOptions, "options");
      setJobIndustry(mergedOptions);
    }
  };

  const handleIndustryChange = (selectedValues) => {
    setIndustryErr("");
    setSelectedIndustryOption(selectedValues);
    if (selectedValues?.value === "other") {
      setOtherIndustry(true);
    } else {
      setOtherJobIndustry("");
      setOtherIndustry(false);
    }
  };
  //JobIndustry API END

  // JobSector API
  const [sectorErr, setSectorErr] = useState("");
  const [jobSectors, setJobSector] = useState();
  const [otherSector, setOtherSector] = useState(false);
  const [other_job_sector, setOtherJobSector] = useState("");

  const getJobSectors = async () => {
    const res = await getData("/without-login/master/all-sector");
    if (res?.success) {
      // console.log(res.data, "sectors");
      // Options fetched from res.data
      const fetchedOptions = res.data.map((city) => ({
        value: city?.id,
        label: city?.name,
      }));
      // Additional options
      const additionalOptions = [
        { value: "other", label: "Other" },
        // Add more additional options as needed
      ];
      // Merge fetched options with additional options
      const mergedOptions = [
        ...options,
        ...fetchedOptions,
        ...additionalOptions,
      ];
      // console.log(mergedOptions, "options");
      setJobSector(mergedOptions);
    }
  };

  const handleSectorChange = (selectedValues) => {
    setSectorErr("");
    setSelectedSectorOption(selectedValues);
    if (selectedValues?.value === "other") {
      setOtherSector(true);
    } else {
      setOtherJobSector("");
      setOtherSector(false);
    }
  };
  //JobSector API END

  const [suggestionsList, setSuggestionsList] = useState([]);

  const getSuggestions = async (inputValue, list) => {
    const inputValueLowerCase = inputValue.trim().toLowerCase();

    if (list === "job_title") {
      const res = await getData(
        `/without-login/master/all-job-title?name=${inputValueLowerCase}`
      );
      if (res?.success) {
        return res.data?.filter((suggestion) =>
          suggestion?.name.toLowerCase().includes(inputValueLowerCase)
        );
      }
    }
    if (list === "industry_id") {
      const res = await getData(
        `/without-login/master/all-industry?name=${inputValueLowerCase}`
      );
      if (res?.success) {
        return res.data?.filter((suggestion) =>
          suggestion?.name.toLowerCase().includes(inputValueLowerCase)
        );
      }
    }
    if (list === "sector_id") {
      const res = await getData(
        `/without-login/master/all-sector?name=${inputValueLowerCase}`
      );
      if (res?.success) {
        return res.data?.filter((suggestion) =>
          suggestion?.name.toLowerCase().includes(inputValueLowerCase)
        );
      }
    }
    if (list === "roles_id") {
      const res = await getData(
        `/without-login/master/all-roles?name=${inputValueLowerCase}`
      );
      if (res?.success) {
        return res.data?.filter((suggestion) =>
          suggestion?.name.toLowerCase().includes(inputValueLowerCase)
        );
      }
    }
  };

  const onSuggestionsFetchRequested = async ({ value }, list) => {
    const suggestions = await getSuggestions(value, list);
    setSuggestionsList(suggestions);
  };

  const onSuggestionsClearRequested = () => {
    setSuggestionsList([]);
  };

  const onChange = (event, { newValue }) => {
    if (event.target.id === "job_title") {
      setSelectedTitleOption(newValue);
    }
    if (event.target.id === "industry_id") {
      setSelectedIndustryOption(newValue);
    }
    if (event.target.id === "sector_id") {
      setSelectedSectorOption(newValue);
    }
    if (event.target.id === "roles_id") {
      setSelectedRoleOption(newValue);
    }
  };

  const getSuggestionValue = (suggestion) => suggestion?.name;

  const renderSuggestion = (suggestion, id) => (
    <div
      id={id}
      style={{
        border: "1px solid #ccc",
        padding: "10px",
        borderRadius: "5px",
        margin: "5px",
        backgroundColor: "#f9f9f9",
      }}
    >
      {suggestion?.name}
    </div>
  );

  const inputProps = {
    onChange,
    style: {
      width: "300px", // Adjust the width as needed
      padding: "8px",
      borderRadius: "5px",
      border: "1px solid #ccc",
      // Add other styles as needed
    },
  };

  //Handle Validation

  const [signModalShow, setSignModalShow] = useState(false);

  const [fileError, setFileError] = useState("");
  const handleValidation = async () => {
    setLoader(true);
    var errors = [];
    //Job Title
    // if (!job_title_id) {
    //   setTitleErr("Job Title is required");
    //   errors.push("Job Title is required");
    // }

    //Job Industry
    if (!industry_id) {
      setIndustryErr("Job Industry is required");
      errors.push("Job Industry is required");
    }

    //Job Sector
    // if (!sector_id) {
    //   setSectorErr("Job Sector is required");
    //   errors.push("Job Sector is required");
    // }

    if (!roles_id) {
      setRoleIdErr("Job Role is required");
      errors.push("Job Role is required");
    }

    //Career level
    if (!career_level_id.value) {
      setCareerErr("Career level is required");
      errors.push("Career level is required");
    }

    //Experience
    if (!experience_id.value) {
      setExperienceErr("Experience is required");
      errors.push("Experience is required");
    }

    //Vacancy
    if (no_of_vacancy.trim() === "") {
      setVacancyErr("No. Of Vacancy is required");
      errors.push("No. Of Vacancy is required");
    }

    //Min Salary
    if (start_salary.trim() === "") {
      setStartErr("Min Salary is required");
      errors.push("Min Salary is required");
    }

    //Max Salary
    if (end_salary.trim() === "") {
      setEndErr("Max Salary is required");
      errors.push("Max Salary is required");
    } else if (end_salary < start_salary) {
      setEndErr("Max Salary should be greater than Start Salary");
      errors.push("Max Salary should be greater than Start Salary");
    }

    //deadline
    if (deadline.trim() === "") {
      setDeadlineErr("Deadline Date is required");
      errors.push("Deadline Date is required");
    } else {
      const currentDate = new Date();
      const enteredDate = new Date(deadline);

      if (enteredDate < currentDate) {
        setDeadlineErr("Deadline Date cannot be in the past or current Date");
        errors.push("Deadline Date cannot be in the past or current Date");
      }
    }

    //Email
    if (email.trim() === "") {
      setEmailErr("Email is required");
      errors.push("Email is required");
    } else if (!/^\S+@\S+\.\S+$/.test(email)) {
      setEmailErr("Email is not valid");
      errors.push("Email is not valid");
    }

    //Contact Number
    if (contact_no.trim() === "") {
      setMobileErr("Contact Number is required");
      errors.push("Contact Number is required");
    } else if (!/^\d{10}$/.test(contact_no)) {
      setMobileErr("Contact Number should be a 10-digit number");
      errors.push("Contact Number should be a 10-digit number");
    }

    //Recruiter
    if (recruiter_name.trim() === "") {
      setRecruiterErr("Recruiter Name is required");
      errors.push("Recruiter Name is required");
    }

    //Recruiter
    if (job_type_id.length === 0) {
      setTypeErr("Job Type is required");
      errors.push("Job Type is required");
    }

    //Location
    if (city_id.length === 0) {
      setLivingErr("Work Location is required");
      errors.push("Work Location is required");
    }

    //Qualification
    if (qualification_id.length === 0) {
      setQualificationErr("Qualification is required");
      errors.push("Qualification is required");
    }

    //Primary
    if (primary_skill_id.length === 0) {
      setPrimaryErr("Primary Skills is required");
      errors.push("Primary Skills is required");
    }

    //Secondary
    // if (secondary_skill_id.length === 0) {
    //   setSecondaryErr("Secondary Skills is required");
    //   errors.push("Secondary Skills is required");
    // }

    //Languages
    if (language_id.length === 0) {
      setLanguageErr("Languages is required");
      errors.push("Languages is required");
    }

    //Shifts
    if (shift_id.length === 0) {
      setShiftErr("Shifts is required");
      errors.push("Shifts is required");
    }

    //Description
    if (description === "") {
      setDescriptionErr("Job Description is required");
      errors.push("Job Description is required");
    }

    //Roles
    // if (role_and_permission === "") {
    //   setRoleErr("Job Role is required");
    //   errors.push("Job Role is required");
    // }

    //Skills Required
    // if (skills_required === "") {
    //   setSkillErr("Job Skills is required");
    //   errors.push("Job Skills is required");
    // }

    //logo

    // Add your file validation logic here
    const allowedFileTypes = ["image/jpeg", "image/png"]; // Example: Allow only JPEG and PNG files
    const maxFileSize = 5242880; // Example: Set a maximum file size of 5 MB

    if (imageDat) {
      if (!allowedFileTypes.includes(imageDat.type)) {
        setFileError("Invalid file type. Please select a JPEG or PNG file.");
        errors.push("Invalid file type. Please select a JPEG or PNG file.");
      } else if (imageDat.size > maxFileSize) {
        setFileError("File size exceeds the maximum limit (5 MB).");
        errors.push("File size exceeds the maximum limit (5 MB).");
      }
    } else {
      setFileError("Logo is required.");
      errors.push("Logo is required.");
    }
    // console.log(imageDat);

    if (errors.length === 0) {
      const data = {
        pool_subscribers_id: props?.activePool?.id,
        plan_id: props?.activePool?.plan_id,
        company_id: props?.activePool?.company_id,
        contact_no: contact_no,
        email: email,
        job_title_id: job_title_id,
        industry_id: industry_id,
        sector_id: sector_id,
        roles_id: roles_id,
        career_level_id: career_level_id?.value,
        description: description,
        role_and_permission: role_and_permission,
        experience_id: experience_id?.value,
        no_of_vacancy: no_of_vacancy,
        start_salary: start_salary,
        end_salary: end_salary,
        skills_required: skills_required,
        recruiter_name: recruiter_name,
        deadline: deadline,
        primary_skill_id: primary_skill_id.map((item) => item.value),
        qualification_id: qualification_id.map((item) => item.value),
        job_type_id: job_type_id.map((item) => item.value),
        language_id: language_id.map((item) => item.value),
        shift_id: shift_id.map((item) => item.value),
        city_id: city_id.map((item) => item.value),
      };

      if (secondary_skill_id.length) {
        data.secondary_skill_id = secondary_skill_id?.map(
          (item) => item?.value
        );
      }

      // console.log(data, "wholeData");

      const res = await postData(`/recruiters/job/job-create`, data);

      if (res?.success) {
        // Create a FormData instance
        const data = new FormData();

        // Append the file to the FormData instance
        //  formData.append('job_id',1);
        data.append("image", imageDat);
        data.append("job_id", res.data.id);

        // Now you have formData with 'job_id' and 'image' as key-value pairs

        const resImage = await postData(
          `/recruiters/job/job-create-logo`,
          data
        );
        if (resImage?.success) {
          setSignModalShow(true);

          setTimeout(() => {
            setSignModalShow(false);
            navigate("/dashboardmain"); // Replace '/' with your desired URL
            setLoader(false);
            props.setModalShow(false);
            props.getTalentPool();
            setCreatedStatus(!createdStatus);
          }, 4000);
        } else {
          setLoader(false);
        }
      }
    } else {
      setLoader(false);
    }
  };

  const [logoPreview, setLogoPreview] = useState(null);
  const [imageDat, setImage] = useState(null);

  const handleFileChange = (event) => {
    setFileError("");
    const file = event.target.files[0];
    setImage(event.target.files[0]);

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setLogoPreview(reader.result);
      };

      reader.readAsDataURL(file);
    } else {
      setLogoPreview(null);
    }
  };

  const getErrors = () => {
    setDeadlineErr("");
    setEmailErr("");
    setMobileErr("");
    setRecruiterErr("");
    setDescriptionErr("");
    setRoleErr("");
    setSkillErr("");
    setVacancyErr("");
    setStartErr("");
    setEndErr("");
    setTitleErr("");
    setIndustryErr("");
    setSectorErr("");
    setCareerErr("");
    setExperienceErr("");
    setTypeErr("");
    setLivingErr("");
    setLanguageErr("");
    setQualificationErr("");
    setPrimaryErr("");
    setSecondaryErr("");
    setShiftErr("");
    setFileError("");

    setDeadline("");
    setEmail("");
    setContact("");
    setRecruiter("");
    setLogoPreview("");
    setDescription("");
    setRoleRequired("");
    setSkillRequired("");
    setVacancy("");
    setStartSalary("");
    setEndSalary("");
    setSelectedTitleOption("");
    setSelectedIndustryOption("");
    setSelectedSectorOption("");
    setSelectedCareerOption("");
    setSelectedExperienceOption("");
    setSelectedTypeOption("");
    setSelectedCityOption("");
    setSelectedLanguageOption("");
    setSelectedQualificationOption("");
    setSelectedPrimaryOption("");
    setSelectedSecondaryOption("");
    setSelectedShiftOption("");
  };

  useEffect(() => {
    getJobTitles();
    getCities();
    getTypes();
    getJobIndustrys();
    getJobSectors();
    getCareer();
    getExperience();
    getSkills();
    getLanguages();
    getShifts();
    getQualifications();
    getErrors();
  }, [props.show]);
  return (
    <>
      <Modal
        {...props}
        fullscreen={true}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <section className="manage-job">
            <div className="container">
              <div className="row mt-md-2 mt-4">
                <div className="col-md-12">
                  <div className="section-title mt-md-3 mt-0">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="row title-creat-job ">
                          <div className="col-lg-4 my-auto">
                            {/* <Link to="/dashboardmain">
                              <a className="title-open-jobs">
                                Go to Dashboard{" "}
                              </a>
                            </Link> */}
                          </div>
                          <div className="col-lg-8">
                            <h5 className="justify-content-center">
                              Create Job (
                              {props?.activePool?.talent_pool_subcategory?.name}
                              )
                            </h5>
                          </div>
                        </div>
                      </div>

                      <div className=" col-xxl-5 col-xl-5 col-lg-5 col-md-7"></div>
                      <div className="col-md-12 p-0">
                        <div className="create-job-form">
                          <div className="row jobTitle_form_sec">
                            <Form>
                              <Row>
                                {/* <div className="col-xxl-6 col-xl-6 col-lg-8 col-md-6 ">
                                  <Form.Group
                                    className="mb-3"
                                    controlId="formGroupEmail"
                                  >
                                    <Form.Label className="d-flex">
                                      Job Title :{" "}
                                       <span className="text-danger asteriskmark">
                                        *
                                      </span> 
                                    </Form.Label>

                                    <Autosuggest
                                      id="job_title"
                                      suggestions={suggestionsList}
                                      onSuggestionsFetchRequested={(event) =>
                                        onSuggestionsFetchRequested(
                                          event,
                                          "job_title"
                                        )
                                      }
                                      onSuggestionsClearRequested={
                                        onSuggestionsClearRequested
                                      }
                                      getSuggestionValue={getSuggestionValue}
                                      renderSuggestion={(suggestion) =>
                                        renderSuggestion(
                                          suggestion,
                                          "job_title"
                                        )
                                      }
                                      inputProps={{
                                        ...inputProps,
                                        id: "job_title",
                                        value: job_title_id,
                                        placeholder: "Enter Job Title",
                                      }}
                                    />
                                  </Form.Group>
                                  <span
                                    className="text text-danger"
                                    style={{
                                      textAlign: "center",
                                    }}
                                  >
                                    {titleErr && titleErr}
                                  </span>
                                </div> */}

                                <div className="col-xxl-6 col-xl-6 col-lg-8 col-md-6 ">
                                  <Form.Group
                                    className="mb-3"
                                    controlId="formGroupEmail"
                                  >
                                    <Form.Label className="d-flex">
                                      Job Role :{" "}
                                      <span className="text-danger asteriskmark">
                                        *
                                      </span>
                                    </Form.Label>
                                    {/* <Select
                                      className="select-line"
                                      value={job_title_id}
                                      onChange={handleTitleChange}
                                      options={jobTitles}
                                    /> */}
                                    <Autosuggest
                                      id="roles_id"
                                      suggestions={suggestionsList}
                                      onSuggestionsFetchRequested={(event) =>
                                        onSuggestionsFetchRequested(
                                          event,
                                          "roles_id"
                                        )
                                      }
                                      onSuggestionsClearRequested={
                                        onSuggestionsClearRequested
                                      }
                                      getSuggestionValue={getSuggestionValue}
                                      renderSuggestion={(suggestion) =>
                                        renderSuggestion(suggestion, "roles_id")
                                      }
                                      inputProps={{
                                        ...inputProps,
                                        id: "roles_id",
                                        value: roles_id,
                                        placeholder: "Enter Job Role",
                                      }}
                                    />
                                  </Form.Group>
                                  <span
                                    className="text text-danger"
                                    style={{
                                      textAlign: "center",
                                    }}
                                  >
                                    {roleIdErr && roleIdErr}
                                  </span>
                                </div>

                                <div className=" col-xxl-6 col-xl-6 col-lg-4 col-md-6 ">
                                  <Form.Group
                                    className="mb-3"
                                    controlId="formGroupPassword"
                                  >
                                    <Form.Label className="d-flex">
                                      Job Type :
                                      <span className="text-danger asteriskmark">
                                        *
                                      </span>
                                    </Form.Label>
                                    <Select
                                      className="select-line"
                                      isMulti
                                      value={job_type_id}
                                      onChange={handleTypeChange}
                                      options={jobTypes}
                                    />
                                  </Form.Group>
                                  <span
                                    className="text text-danger"
                                    style={{
                                      textAlign: "center",
                                    }}
                                  >
                                    {typeErr && typeErr}
                                  </span>
                                </div>
                                {otherTitle && (
                                  <div className=" col-xxl-7 col-xl-7 col-lg-6 col-md-5  ">
                                    <Form.Group
                                      className="mb-3"
                                      controlId="formGroupPassword"
                                    >
                                      <Form.Label className="d-flex">
                                        Other Job Title :
                                        <span className="text-danger asteriskmark">
                                          *
                                        </span>
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        placeholder="Enter Job Title Name"
                                        value={other_job_title}
                                        onChange={(event) => {
                                          setOtherJobTitle(event.target.value);
                                          setTitleErr("");
                                        }}
                                      />
                                    </Form.Group>
                                  </div>
                                )}
                                <div className="col-xxl-6 col-xl-6 col-lg-8 col-md-6 ">
                                  <Form.Group
                                    className="mb-3"
                                    controlId="formGroupPassword"
                                  >
                                    <Form.Label className="d-flex">
                                      Industry :
                                      <span className="text-danger asteriskmark">
                                        *
                                      </span>
                                    </Form.Label>
                                    {/* <Select
                                      className="select-line"
                                      value={industry_id}
                                      onChange={handleIndustryChange}
                                      options={jobIndustrys}
                                    /> */}
                                    <Autosuggest
                                      id="industry_id"
                                      suggestions={suggestionsList}
                                      onSuggestionsFetchRequested={(event) =>
                                        onSuggestionsFetchRequested(
                                          event,
                                          "industry_id"
                                        )
                                      }
                                      onSuggestionsClearRequested={
                                        onSuggestionsClearRequested
                                      }
                                      getSuggestionValue={getSuggestionValue}
                                      renderSuggestion={(suggestion) =>
                                        renderSuggestion(
                                          suggestion,
                                          "industry_id"
                                        )
                                      }
                                      inputProps={{
                                        ...inputProps,
                                        id: "industry_id",
                                        value: industry_id,
                                        placeholder: "Enter Industry",
                                      }}
                                    />
                                  </Form.Group>
                                  <span
                                    className="text text-danger"
                                    style={{
                                      textAlign: "center",
                                    }}
                                  >
                                    {industryErr && industryErr}
                                  </span>
                                </div>

                                <div className=" col-xxl-6 col-xl-6 col-lg-4 col-md-6 ">
                                  <Form.Group
                                    className="mb-3"
                                    controlId="formGroupPassword"
                                  >
                                    <Form.Label className="d-flex">
                                      Work Location :
                                      <span className="text-danger asteriskmark">
                                        *
                                      </span>
                                    </Form.Label>
                                    <Select
                                      className="select-line"
                                      isMulti
                                      value={city_id}
                                      onChange={handleCityChange}
                                      options={cities}
                                    />
                                  </Form.Group>
                                  <span
                                    className="text text-danger"
                                    style={{
                                      textAlign: "center",
                                    }}
                                  >
                                    {livingErr && livingErr}
                                  </span>
                                </div>
                                {otherIndustry && (
                                  <div className=" col-xxl-7 col-xl-7 col-lg-6 col-md-5 ">
                                    <Form.Group
                                      className="mb-3"
                                      controlId="formGroupPassword"
                                    >
                                      <Form.Label className="d-flex">
                                        Other Job Industry :
                                        <span className="text-danger asteriskmark">
                                          *
                                        </span>
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        placeholder="Enter Job Title Name"
                                        value={other_job_industry}
                                        onChange={(event) => {
                                          setOtherJobIndustry(
                                            event.target.value
                                          );
                                          setIndustryErr("");
                                        }}
                                      />
                                    </Form.Group>
                                  </div>
                                )}

                                {/* <div className="col-xxl-6 col-xl-6 col-lg-8 col-md-6 ">
                                  <Form.Group
                                    className="mb-3"
                                    controlId="formGroupPassword"
                                  >
                                    <Form.Label className="d-flex">
                                      Sector :
                                      <span className="text-danger asteriskmark">
                                        *
                                      </span>
                                    </Form.Label>
                                  
                                    <Autosuggest
                                      id="sector_id"
                                      suggestions={suggestionsList}
                                      onSuggestionsFetchRequested={(event) =>
                                        onSuggestionsFetchRequested(
                                          event,
                                          "sector_id"
                                        )
                                      }
                                      onSuggestionsClearRequested={
                                        onSuggestionsClearRequested
                                      }
                                      getSuggestionValue={getSuggestionValue}
                                      renderSuggestion={(suggestion) =>
                                        renderSuggestion(
                                          suggestion,
                                          "sector_id"
                                        )
                                      }
                                      inputProps={{
                                        ...inputProps,
                                        id: "sector_id",
                                        value: sector_id,
                                        placeholder: "Enter Sector",
                                      }}
                                    />
                                  </Form.Group>
                                  <span
                                    className="text text-danger"
                                    style={{
                                      textAlign: "center",
                                    }}
                                  >
                                    {sectorErr && sectorErr}
                                  </span>
                                </div> */}
                                <div className=" col-xxl-6 col-xl-6 col-lg-4 col-md-6 ">
                                  <Form.Group
                                    className="mb-3"
                                    controlId="formGroupPassword"
                                  >
                                    <Form.Label className="d-flex">
                                      Career Level :
                                      <span className="text-danger asteriskmark">
                                        *
                                      </span>
                                    </Form.Label>
                                    <Select
                                      className="select-line"
                                      value={career_level_id}
                                      onChange={handleCareerChange}
                                      options={career}
                                    />
                                  </Form.Group>
                                  <span
                                    className="text text-danger"
                                    style={{
                                      textAlign: "center",
                                    }}
                                  >
                                    {careerErr && careerErr}
                                  </span>
                                </div>
                                {otherSector && (
                                  <div className=" col-xxl-7 col-xl-7 col-lg-6 col-md-5 ">
                                    <Form.Group
                                      className="mb-3"
                                      controlId="formGroupPassword"
                                    >
                                      <Form.Label className="d-flex">
                                        Other Job Sector :
                                        <span className="text-danger asteriskmark">
                                          *
                                        </span>
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        placeholder="Enter Job Title Name"
                                        value={other_job_sector}
                                        onChange={(event) => {
                                          setOtherJobSector(event.target.value);
                                          setSectorErr("");
                                        }}
                                      />
                                    </Form.Group>
                                  </div>
                                )}
                                <div className="col-xxl-6 col-xl-6 col-lg-8 col-md-6 ">
                                  <Form.Group
                                    className="mb-3"
                                    controlId="formGroupPassword"
                                  >
                                    <Form.Label className="d-flex">
                                      Qualification :
                                      <span className="text-danger asteriskmark">
                                        *
                                      </span>
                                    </Form.Label>
                                    <Select
                                      className="select-line"
                                      isMulti
                                      value={qualification_id}
                                      onChange={handleQualificationChange}
                                      options={qualifications}
                                    />
                                  </Form.Group>
                                  <span
                                    className="text text-danger"
                                    style={{
                                      textAlign: "center",
                                    }}
                                  >
                                    {qualificationErr && qualificationErr}
                                  </span>
                                </div>

                                <div className=" col-xxl-6 col-xl-6 col-lg-4 col-md-6 ">
                                  <Form.Group
                                    className="mb-3"
                                    controlId="formGroupPassword"
                                  >
                                    <Form.Label className="d-flex">
                                      Select Prof. Experience: (in Years)
                                      <span className="text-danger asteriskmark">
                                        *
                                      </span>
                                    </Form.Label>
                                    <Select
                                      className="select-line"
                                      value={experience_id}
                                      onChange={handleExperienceChange}
                                      options={experience}
                                    />
                                  </Form.Group>
                                  <span
                                    className="text text-danger"
                                    style={{
                                      textAlign: "center",
                                    }}
                                  >
                                    {experienceErr && experienceErr}
                                  </span>
                                </div>
                                <div className="col-xxl-6 col-xl-6 col-lg-8 col-md-6 ">
                                  <Form.Group
                                    className="mb-3"
                                    controlId="formGroupPassword"
                                  >
                                    <Form.Label className="d-flex">
                                      Primary Skills :
                                      <span className="text-danger asteriskmark">
                                        *
                                      </span>
                                    </Form.Label>
                                    <Select
                                      className="select-line"
                                      isMulti
                                      value={primary_skill_id}
                                      onChange={handlePrimaryChange}
                                      options={skills}
                                    />
                                  </Form.Group>
                                  <span
                                    className="text text-danger"
                                    style={{
                                      textAlign: "center",
                                    }}
                                  >
                                    {primaryErr && primaryErr}
                                  </span>
                                </div>
                                <div className=" col-xxl-6 col-xl-6 col-lg-4 col-md-6 ">
                                  <Form.Group
                                    className="mb-3"
                                    controlId="formGroupPassword"
                                  >
                                    <Form.Label className="d-flex">
                                      Number of Vacancy :
                                      <span className="text-danger asteriskmark">
                                        *
                                      </span>
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      value={no_of_vacancy}
                                      placeholder="Enter Number Of vacancy"
                                      onKeyDown={(event) => {
                                        // Allow numerical digits and the period (.)
                                        if (!/[0-9.]/.test(event.key)) {
                                          if (event.key !== "Backspace") {
                                            event.preventDefault();
                                          }
                                        }
                                      }}
                                      onChange={(event) => {
                                        setVacancy(event.target.value);
                                        setVacancyErr("");
                                      }}
                                    />
                                  </Form.Group>
                                  <span
                                    className="text text-danger"
                                    style={{
                                      textAlign: "center",
                                    }}
                                  >
                                    {vacancyErr && vacancyErr}
                                  </span>
                                </div>
                                <div className="col-xxl-6 col-xl-6 col-lg-8 col-md-6 ">
                                  <Form.Group
                                    className="mb-3"
                                    controlId="formGroupPassword"
                                  >
                                    <Form.Label className="d-flex">
                                      Secondary Skills
                                      {/* <span className="text-danger asteriskmark">
                                        *
                                      </span> */}
                                    </Form.Label>
                                    <Select
                                      className="select-line"
                                      isMulti
                                      value={secondary_skill_id}
                                      onChange={handleSecondaryChange}
                                      options={skills}
                                    />
                                  </Form.Group>
                                  <span
                                    className="text text-danger"
                                    style={{
                                      textAlign: "center",
                                    }}
                                  >
                                    {secondaryErr && secondaryErr}
                                  </span>
                                </div>
                                <div className="col-xxl-6 col-xl-6 col-lg-4 col-md-6 mt-4">
                                  <Form.Group
                                    className="mb-3"
                                    controlId="formGroupSalary"
                                  >
                                    <Form.Label className="d-flex">
                                      Salary Range ( LPA ):
                                      <span className="text-danger asteriskmark">
                                        *
                                      </span>
                                    </Form.Label>
                                    <InputGroup>
                                      ₹{" "}
                                      <Form.Control
                                        type="text"
                                        placeholder="Min Salary"
                                        onKeyDown={(event) => {
                                          // Allow numerical digits and the period (.)
                                          if (!/[0-9]/.test(event.key)) {
                                            if (event.key !== "Backspace") {
                                              event.preventDefault();
                                            }
                                          }
                                        }}
                                        onChange={(event) => {
                                          setStartSalary(event.target.value);
                                          setStartErr("");
                                        }}
                                      />
                                      <InputGroup.Text>-</InputGroup.Text>₹{" "}
                                      <Form.Control
                                        type="text"
                                        placeholder="Max Salary"
                                        onKeyDown={(event) => {
                                          // Allow numerical digits and the period (.)
                                          if (!/[0-9]/.test(event.key)) {
                                            if (event.key !== "Backspace") {
                                              event.preventDefault();
                                            }
                                          }
                                        }}
                                        onChange={(event) => {
                                          setEndSalary(event.target.value);
                                          setEndErr("");
                                        }}
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                  <span
                                    className="text text-danger"
                                    style={{
                                      textAlign: "center",
                                    }}
                                  >
                                    {startErr && startErr} {endErr && endErr}
                                  </span>
                                </div>
                                <div className="col-md-12 col-12">
                                  <Form.Group
                                    className="mb-3"
                                    controlId="formGroupPassword"
                                  >
                                    <Form.Label className="d-flex">
                                      Job description:
                                      <span className="text-danger asteriskmark">
                                        *
                                      </span>
                                    </Form.Label>
                                    <JoditEditor
                                      ref={editor}
                                      value={description} // Set the value from the state
                                      tabIndex={1}
                                      onChange={(newContent) =>
                                        setDescription(newContent)
                                      } // Update the state on change
                                    />
                                  </Form.Group>
                                  <span
                                    className="text text-danger"
                                    style={{
                                      textAlign: "center",
                                    }}
                                  >
                                    {descriptionErr && descriptionErr}
                                  </span>
                                </div>
                                <div className="col-md-6 col-12">
                                  <Form.Group
                                    className="mb-3"
                                    controlId="formGroupPassword"
                                  >
                                    <Form.Label className="d-flex">
                                      Languages:
                                      <span className="text-danger asteriskmark">
                                        *
                                      </span>
                                    </Form.Label>
                                    <Select
                                      className="select-line"
                                      isMulti
                                      value={language_id}
                                      onChange={handleLanguageChange}
                                      options={languages}
                                    />
                                  </Form.Group>
                                  <span
                                    className="text text-danger"
                                    style={{
                                      textAlign: "center",
                                    }}
                                  >
                                    {languageErr && languageErr}
                                  </span>
                                </div>
                                <div className="col-md-6 col-12">
                                  <Form.Group
                                    className="mb-3"
                                    controlId="formGroupPassword"
                                  >
                                    <Form.Label className="d-flex">
                                      Shift and Schedule:
                                      <span className="text-danger asteriskmark">
                                        *
                                      </span>
                                    </Form.Label>
                                    <Select
                                      className="select-line"
                                      isMulti
                                      value={shift_id}
                                      onChange={handleShiftChange}
                                      options={shifts}
                                    />
                                  </Form.Group>
                                  <span
                                    className="text text-danger"
                                    style={{
                                      textAlign: "center",
                                    }}
                                  >
                                    {shiftErr && shiftErr}
                                  </span>
                                </div>
                                <div className="col-md-6 col-12">
                                  <Form.Group
                                    className="mb-3"
                                    controlId="formGroupPassword"
                                  >
                                    <Form.Label className="d-flex">
                                      Roles and responsibilities:
                                      {/* <span className="text-danger asteriskmark">
                                        *
                                      </span> */}
                                    </Form.Label>
                                    <JoditEditor
                                      ref={editor}
                                      value={role_and_permission} // Set the value from the state
                                      tabIndex={1}
                                      onChange={(newContent) =>
                                        setRoleRequired(newContent)
                                      } // Update the state on change
                                    />
                                  </Form.Group>
                                  <span
                                    className="text text-danger"
                                    style={{
                                      textAlign: "center",
                                    }}
                                  >
                                    {rolesErr && rolesErr}
                                  </span>
                                </div>
                                <div className="col-md-6 col-12">
                                  <Form.Group
                                    className="mb-3"
                                    controlId="formGroupPassword"
                                  >
                                    <Form.Label className="d-flex">
                                      Skills required:
                                      {/* <span className="text-danger asteriskmark">
                                        *
                                      </span> */}
                                    </Form.Label>
                                    <JoditEditor
                                      ref={editor}
                                      value={skills_required} // Set the value from the state
                                      tabIndex={1}
                                      onChange={(newContent) =>
                                        setSkillRequired(newContent)
                                      } // Update the state on change
                                    />
                                  </Form.Group>
                                  <span
                                    className="text text-danger"
                                    style={{
                                      textAlign: "center",
                                    }}
                                  >
                                    {skillErr && skillErr}
                                  </span>
                                </div>
                                <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-12">
                                  <Form.Group
                                    className="mb-3"
                                    controlId="formGroupPassword"
                                  >
                                    <p>Contact:</p>
                                    <Form.Label className="d-flex">
                                      H.R. Person / Recruiter
                                      <span className="text-danger asteriskmark">
                                        *
                                      </span>
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Enter Name"
                                      onChange={(event) => {
                                        setRecruiter(event.target.value);
                                        setRecruiterErr("");
                                      }}
                                      onKeyDown={(event) => {
                                        if (!/[A-Z-a-z ]/.test(event.key)) {
                                          event.preventDefault();
                                        }
                                      }}
                                    />
                                  </Form.Group>
                                  <span
                                    className="text text-danger"
                                    style={{
                                      textAlign: "center",
                                    }}
                                  >
                                    {recruiterErr && recruiterErr}
                                  </span>
                                </div>
                                <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-12">
                                  <Form.Group
                                    className="mb-3"
                                    controlId="formGroupPassword"
                                  >
                                    <Form.Label className="mt-5 d-flex">
                                      Upload Company Logo
                                      <span className="text-danger asteriskmark">
                                        *
                                      </span>
                                    </Form.Label>
                                    <Form.Control
                                      type="file"
                                      placeholder="Username"
                                      accept="image/jpeg"
                                      onChange={handleFileChange}
                                    />
                                  </Form.Group>
                                  <span
                                    className="text text-danger"
                                    style={{
                                      textAlign: "center",
                                    }}
                                  >
                                    {fileError && fileError}
                                  </span>
                                </div>
                                <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-12">
                                  {logoPreview && (
                                    <div>
                                      <p>Preview:</p>
                                      <img
                                        src={logoPreview}
                                        alt="Logo Preview"
                                        style={{
                                          maxWidth: "100%",
                                          maxHeight: "200px",
                                        }}
                                      />
                                    </div>
                                  )}
                                </div>
                                <div className="col-md-4 col-12">
                                  <Form.Group
                                    className="mb-3"
                                    controlId="formGroupPassword"
                                  >
                                    <Form.Label className="d-flex">
                                      Recruiter Contact Number:
                                      <span className="text-danger asteriskmark">
                                        *
                                      </span>
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      minlength="10"
                                      maxlength="10"
                                      placeholder="Enter Contact Number"
                                      onKeyDown={(event) => {
                                        // Allow numerical digits and the period (.)
                                        if (!/[0-9]/.test(event.key)) {
                                          if (event.key !== "Backspace") {
                                            event.preventDefault();
                                          }
                                        }
                                      }}
                                      onChange={(event) => {
                                        setContact(event.target.value);
                                        setMobileErr("");
                                      }}
                                    />
                                  </Form.Group>
                                  <span
                                    className="text text-danger"
                                    style={{
                                      textAlign: "center",
                                    }}
                                  >
                                    {mobileErr && mobileErr}
                                  </span>
                                </div>
                                <div className="col-md-4 col-12">
                                  <Form.Group
                                    className="mb-3"
                                    controlId="formGroupPassword"
                                  >
                                    <Form.Label className="d-flex">
                                      Recruiter Email:
                                      <span className="text-danger asteriskmark">
                                        *
                                      </span>
                                    </Form.Label>
                                    <Form.Control
                                      type="email"
                                      placeholder="Enter Email"
                                      onChange={(event) => {
                                        setEmail(event.target.value);
                                        setEmailErr("");
                                      }}
                                    />
                                  </Form.Group>
                                  <span
                                    className="text text-danger"
                                    style={{
                                      textAlign: "center",
                                    }}
                                  >
                                    {emailErr && emailErr}
                                  </span>
                                </div>
                                <div className="col-md-4 col-12">
                                  <Form.Group
                                    className="mb-3"
                                    controlId="formGroupPassword"
                                  >
                                    <Form.Label className="d-flex">
                                      Application Deadline:
                                      <span className="text-danger asteriskmark">
                                        *
                                      </span>
                                    </Form.Label>
                                    <Form.Control
                                      type="date"
                                      placeholder="Select Deadline"
                                      onChange={(event) => {
                                        setDeadline(event.target.value);
                                        setDeadlineErr("");
                                      }}
                                    />
                                  </Form.Group>
                                  <span
                                    className="text text-danger"
                                    style={{
                                      textAlign: "center",
                                    }}
                                  >
                                    {deadlineErr && deadlineErr}
                                  </span>
                                </div>
                                <div className="row createForm_btnCont text-center mt-5 mb-5">
                                  <div className="col-lg-6 col-6 text-end">
                                    <button
                                      type="button"
                                      className="btn createpostBtn show-more"
                                      onClick={handleValidation}
                                      disabled={loader ? true : false}
                                    >
                                      {loader ? (
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            paddingLeft: 30,
                                          }}
                                        >
                                          <PacmanLoader
                                            color="#36d7b7"
                                            size={10}
                                          />
                                        </div>
                                      ) : (
                                        "Post Job"
                                      )}
                                    </button>
                                  </div>
                                  <div className="col-lg-6 col-6 text-start">
                                    <Button
                                      className="CreateForm_closeBtn btn-danger"
                                      onClick={() => {
                                        props.setModalShow(false);
                                      }}
                                    >
                                      Close
                                    </Button>
                                  </div>
                                  {/* <CreateModal show={props.modalShow}
                                                            onHide={() => setModalShow(false)} /> */}
                                </div>
                              </Row>
                            </Form>
                            <div>
                              <SignUpModal
                                show={signModalShow}
                                onHide={() => setSignModalShow(false)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CreateJobModal;
