import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import '../Apply/Apply.css'
import ApplyJobModal from '../ApplyJobModal/ApplyJobModal';

const Apply = () => {
    const [selectedOption, setSelectedOption] = useState('');

    const handleDropdownChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleRadioChange = (event) => {
        setSelectedOption(event.target.value);
    };

    // Apply job modal

    const [isModalOpen, setModalOpen] = useState(false);

    const [modalShow, setModalShow] = React.useState(false);

    const openModal = () => {
        setModalShow(true);

        setTimeout(() => {
            window.location.href = '/dashboard-job';
            setModalShow(false);
        }, 6000);
    };
    const closeModal = () => {
        setModalOpen(false);
    };

    return (
        <>
            <section className="personal-details">
                <div className='container'>
                    <div className='peronal-information'>
                        <div className='row'>
                            <div className='col-md-12 '>
                                <div className='title-personal'>
                                    <p className='mx-auto' >Peronal Details</p>
                                    {/* <p><a className='save-close-text me-5'>Save & Close</a></p> */}
                                </div>
                            </div>
                        </div>
                        <div className='row p-2'>
                            <div className='col-md-7 mt-md-4 mt-0 ms-md-5 ms-0'>
                                <form>  
                                    <div className='row'>
                                        <div className='col-xl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
                                            <label htmlFor="name">Full Name:</label>
                                        </div>
                                        <div className='col-xl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
                                            <input
                                                type="text"
                                                id="name"
                                                name="name"
                                            />
                                        </div>
                                    </div>
                                    <div className='row mt-md-4 mt-2'>
                                        <div className='c'>
                                            <label htmlFor="Number">Contact No:</label>
                                        </div>
                                        <div className='col-lg-1 col-md-1 col-2'>
                                            <select id="dropdown" >
                                                <option value="">+91</option>
                                                <option value="option1">+91</option>
                                                <option value="option2">+91</option>
                                                <option value="option3">+91</option>
                                            </select>
                                        </div>
                                        <div className='col-lg-11 col-md-11 col-10'>
                                            <input
                                                type="tel"
                                                id="Website"
                                                name="Website"
                                            />
                                        </div>
                                    </div>
                                    <div className='row mt-md-4 mt-2 '>
                                        <div className='col-xl-12 col-xl-2 col-lg-12 col-md-12 col-12'>
                                            <label htmlFor="Website">Email ID:</label>
                                        </div>
                                        <div className='col-xl-12 col-xl-2 col-lg-12 col-md-12 col-12'>
                                            <input
                                                type="email"
                                                id="Website"
                                                name="Website"
                                            />
                                        </div>
                                    </div>
                                </form>
                            </div>

                            <div className='col-md-4 '>
                                <div className='desktop-view'>
                                    <div className='user-img-class mt-5'>
                                        <img src={process.env.PUBLIC_URL + "assets/Images/banner/apply-job/user.png"} className='img-class' alt="..." />
                                        <p>Upload Photo</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-12 mt-5'>
                                <div className='text-center'>
                                    <button className='upload-portfolio'>Upload Portfolio</button>
                                </div>
                                <div className='text-center mt-4 mb-md-5 mb-4'>
                                    <button type="button" onClick={openModal} className='save-and-proceed'>Accept</button>
                                    <ApplyJobModal show={modalShow} onHide={() => setModalShow(false)} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Apply;