import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import { Link, useParams, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import "./Blog_Details.css";

import { useContext } from "react";
import { Context } from "../../../../utils/context";
const BlogDetailsModal = (props) => {
    var HtmlToReactParser = require("html-to-react").Parser;
    var htmlToReactParser = new HtmlToReactParser();
    const { getData, imageUrl } = useContext(Context);
    // const { id } = useParams();
    // const location = useLocation();
    // console.log(location);
    // const queryParams = new URLSearchParams(location.search);

    // const imageSource = queryParams.get("imageSource");
    // console.log(imageSource);

    // const [blogPost, setBlogPost] = useState(null);


    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                fullscreen={true}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Modal heading
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <section className="BlogDetails">
                        <div className="container-fluid">
                            <div className="row BlogDetails_main">
                                <div className="col-lg-12 my-auto">
                                    <div className="row">
                                        <div className="col-lg-10 mx-auto">
                                            <Link onClick={props.onHide} className="mb-md-3 mb-0">
                                                <p className="goToBlog_txt">Go to Blog</p>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="row blog_details p-0">
                                        <div className="col-lg-10 mx-auto">
                                            <div className="innerBlog_content">
                                                <div className="row  ">
                                                    <div className="col-xl-7 col-lg-6 col-md-6">
                                                        <div className=" BlogonBanner_content pt-4 pb-md-4 pb-0">
                                                            <p className="Blogdetails_heading">
                                                                {props?.data?.title}
                                                            </p>
                                                            {/* <p className="Blogdetails_info">
                                                            {props?.data?.description}
                                                            </p> */}
                                                            <p className="Blogdetails_date">
                                                                <FontAwesomeIcon
                                                                    icon={faCalendarDays}
                                                                    style={{ color: "white" }}
                                                                    className="me-1"
                                                                />
                                                                {props?.data?.date}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    {/* Mobile viw image */}
                                                    <div className="col-xl-5 col-lg-6 col-md-6">
                                                        <div className="blogdetails_imgsection text-end">

                                                            <img
                                                                src={imageUrl + props?.data?.image}
                                                                className="blogDetails_Backimg"
                                                                alt="Blog Image"
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className="col-xl-4 col-lg-6 ">
                                                        <div className="blogdetails_imgsection-mobile text-end">
                                                            <img
                                                                src={imageUrl + props?.data?.image}
                                                                className="banner-img-mobile"
                                                                alt="..."
                                                            />{" "}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-5 mb-5">
                                <div className="col-lg-8 ">
                                    <div className=" blogDetails_below_info">
                                        <p className="blogDetails_content_section">
                                            {htmlToReactParser.parse(props?.data?.description)}
                                        </p>
                                        {/* <p className="blogDetails_content_section">
                                            Hiring our nation’s heroes can yield positive returns for
                                            employers that invest in veteran hiring. With their
                                            unmatched technical and leadership skills, strong work
                                            ethic, and unwavering commitment to safety and integrity
                                            in the workplace, veterans can generate positive outcomes
                                            that can impact a business’s bottom line. In fact, one
                                            analysis showed that a company of 1,000 employees, 25% of
                                            whom are veterans, can experience an annual cost savings
                                            of $325,000.
                                        </p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default BlogDetailsModal;
