import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Select from "react-select";

import { useContext } from "react";
import { Context } from "../../../../utils/context";
const Step6 = ({ handlePrevious, handleNext }) => {
  const navigate = useNavigate();

  const { getData, postData, recruiterData, jobSeekerData, getJobSeekerData } =
    useContext(Context);

  const [modalShow, setModalShow] = useState(false);

  // Cities API
  const [selectedOption, setSelectedOption] = useState(null);
  const [livingErr, setLivingErr] = useState("");
  const options = [];
  const [cities, setCities] = useState();

  const getCities = async () => {
    const res = await getData("/without-login/master/all-cities");
    if (res?.success) {
      // console.log(res.data, "cities");
      const mergedOptions = [
        ...options,
        ...res.data.map((city) => ({
          value: city?.id,
          label: city?.name,
          name: "living_city_id",
        })),
      ];
      // console.log(mergedOptions, "options");
      setCities(mergedOptions);
    }
  };

  // Nationality API
  const [selectedNationOption, setSelectedNationOption] = useState(null);
  const [nationErr, setNationErr] = useState("");
  const nationOptions = [];
  const [nations, setNations] = useState();

  const getNations = async () => {
    const res = await getData("/without-login/master/all-nationality");
    if (res?.success) {
      // console.log(res.data, "nations");
      const mergedOptions = [
        ...nationOptions,
        ...res.data.map((nation) => ({
          value: nation?.id,
          label: nation?.name,
          name: "nationality_id",
        })),
      ];
      // console.log(mergedOptions, "nationOptions");
      setNations(mergedOptions);
    }
  };

  const [address, setAddress] = useState("");

  const [addressErr, setAddressErr] = useState("");

  const [formData, setFormData] = useState({
    current_address: "",
    living_city_id: "",
    nationality_id: "",
    step: 6,
    status: true,
  });

  const handleChange = async (e) => {
    if (e?.name === "living_city_id" || e?.name === "nationality_id") {
      setFormData({ ...formData, [e?.name]: e });

      if (e?.name === "living_city_id") {
        setLivingErr("");
      }
      if (e?.name === "nationality_id") {
        setNationErr("");
      }
    } else {
      setFormData({ ...formData, [e?.target?.name]: e?.target?.value });
      setAddressErr("");
    }
  };
  const handleValidation = async () => {
    // Initialize an array to store error messages
    var errors = [];

    if (!formData?.living_city_id) {
      setLivingErr("Living City is required");
      errors.push("Living City is required");
    }

    if (!formData?.nationality_id) {
      setNationErr("Nationality is required");
      errors.push("Nationality is required");
    }

    // if (!formData?.current_address) {
    //   setAddressErr("Address is required");
    //   errors.push("Address is required");
    // }

    if (errors.length === 0) {
      // console.log(jobSeekerData?.id);

      const finalData = new FormData();
      finalData.append(`current_address`, formData.current_address);
      finalData.append(`living_city_id`, formData.living_city_id?.value);
      finalData.append(`nationality_id`, formData.nationality_id?.value);
      finalData.append(`step`, formData.step);
      finalData.append(`status`, formData.status);

      const res = await postData(`/jobseeker/signup/address`, finalData);

      if (res?.success) {
        // console.log(res);
        handleNext();
      }
    }
  };

  const seekerData = async () => {
    const res = await getJobSeekerData();
    if (res?.users_detail?.city?.id && res?.users_detail?.nationality?.id) {
      await setFormData({
        living_city_id: {
          value: res?.users_detail?.city?.id,
          label: res?.users_detail?.city?.name,
        },
        nationality_id: {
          value: res?.users_detail?.nationality?.id,
          label: res?.users_detail?.nationality?.name,
        },
        step: 6,
        status: "none",
      });
    }
  };

  useEffect(() => {
    // console.log(recruiterData, "recruiterData");
    getCities();
    getNations();
    seekerData();
  }, []);

  return (
    <>
      <fieldset>
        <div className="form-card StepForm mt-5">
          <div className="d-flex  steps-heading">
            <div className="border-left" />
            <h5>Step 5</h5>
          </div>
          <Form>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              {/* <Form.Label>
                {" "}
                Current Address <span>*</span>
              </Form.Label>
              <Form.Control
                as="textarea"
                name="current_address"
                rows={1}
                onChange={handleChange}
                value={formData?.current_address}
              /> */}
            </Form.Group>
            <span
              className="text text-danger"
              style={{
                textAlign: "center",
              }}
            >
              {addressErr && addressErr}
            </span>
            <Row>
              <div className="col-md-6 col-sm-12 mb-3">
                <Form.Label>
                  Living City <span className="text-danger">*</span>
                </Form.Label>
                <Select
                  value={formData?.living_city_id}
                  onChange={handleChange}
                  options={cities}
                  isSearchable={true}
                />
                <span
                  className="text text-danger"
                  style={{
                    textAlign: "center",
                  }}
                >
                  {livingErr && livingErr}
                </span>
              </div>

              <div className="col-md-6 col-sm-12 mb-3">
                <Form.Label>
                  {" "}
                  Nationality <span className="text-danger">*</span>
                </Form.Label>
                <Select
                  value={formData?.nationality_id}
                  onChange={handleChange}
                  options={nations}
                  isSearchable={true}
                />
                <span
                  className="text text-danger"
                  style={{
                    textAlign: "center",
                  }}
                >
                  {nationErr && nationErr}
                </span>
              </div>
            </Row>
            <div className="mobile-view-button">
              <button
                type="button"
                name="next"
                className="next action-button contin-btn"
                onClick={handleValidation}
              >
                Continue
              </button>
            </div>
          </Form>
        </div>
      </fieldset>

      <div className="row mt-5 pt-4 footer-sec">
        <div className="col-md-10 col-12 desktop-view-button">
          {/* <div className="check-row text-start">
            <span>
              <Form.Check inline name="group1" className="me-2" />
            </span>
            <span className=" by-text ">By signing up, I agree to the</span>
            <span className="px-1 ">
              <Link to="/terms" className="term-btn">
                Term and Conditions
              </Link>
            </span>
            <br />
          </div>
          <div className="login-row text-start">
            <span className="">Already an User?</span>
            <span className="px-1 ">
              <Link to="/other-page" className="login-btn">
                log In
              </Link>
            </span>
            &nbsp; &nbsp; &nbsp;
            <span className="">Not a Jobseeker?</span>
            <span className="px-1 ">
              <Link to="/signup-recruiter" className="signup-btn">
                Signup as Recruiter
              </Link>
            </span>
          </div> */}
        </div>
        {/* <div className="col-md-8 col-12 mb-5  mobile-view-button">
          <div className="check-row text-start">
            <span>
              <Form.Check inline name="group1" className="me-2" />
            </span>
            <span className=" by-text ">By signing up, I agree to the</span>
            <span className="px-1 ">
              <Link to="/terms" className="term-btn">
                Term and Conditions
              </Link>
            </span>
            <br />
          </div>
          <div className="login-row text-start">
            <span className="">Already an User?</span>
            <span className="px-1 ">
              <Link to="/other-page" className="login-btn">
                log In
              </Link>
            </span>
            <br />
            <span className="">Not a Jobseeker?</span>
            <span className="px-1 ">
              <Link to="/signup-recruiter" className="signup-btn">
                Signup as Recruiter
              </Link>
            </span>
          </div>
        </div> */}

        <div className="col-2">
          <div className="desktop-view-button">
            <button
              type="button"
              name="next"
              className="next action-button contin-btn"
              onClick={handleValidation}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Step6;
