import React from 'react'
import { useState } from 'react';
import Lottie from 'react-lottie';
import * as animationData from './Animation - 1698734699183.json';
const DoneModal = () => {

    const [modalShow, setModalShow] = React.useState(false);



    setTimeout(() => {
        setModalShow(false);
        window.location.href = '/my-portfolio';
    }, 2000);

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    return (
        <>
            <div className=' mt-5'>
                <Lottie show={modalShow}
                    onHide={() => setModalShow(false)}
                    options={defaultOptions}
                    height={250}
                    width={250}
                />
            </div>
        </>
    )
}

export default DoneModal