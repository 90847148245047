import { useEffect, useState, useRef } from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";

import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { useNavigate, useLocation } from "react-router-dom";
import ContactModal from "./ContactModal/ContactModal";

import { useContext } from "react";
import { Context } from "../../../../utils/context";
function Step5({ handleNext }) {
  const { postData, recruiterData, setRecruiterData } = useContext(Context);

  const [otp1, setOpt1] = useState("");
  const [otp2, setOpt2] = useState("");
  const [otp3, setOpt3] = useState("");
  const [otp4, setOpt4] = useState("");
  const [error, seterror] = useState(false);
  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);
  const inputRef3 = useRef(null);
  const inputRef4 = useRef(null);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [phone, setPhone] = useState("");
  const [alternate, setAlternate] = useState("");

  const [mobileVerify, setMobileVerify] = useState(false);

  const [modalShow, setModalShow] = useState(false);
  const [timerShow, setTimerShow] = useState(false);

  const [seconds, setSeconds] = useState(60); // Initial value is 60 seconds
  const [isTimerExpired, setIsTimerExpired] = useState(false);
  const [otpERR, setOtpErr] = useState("");

  const [otpsend, setOtpSend] = useState(null);

  const [mobileErr, setMobileErr] = useState("");
  const [mobileDisable, setMobileDis] = useState(false);
  const [alternateMobileErr, setAlternateMobileErr] = useState("");

  const handleValidation = async (type) => {
    // console.log(recruiterData, "recruiterData");
    var mobile = phone;

    // Initialize an array to store error messages
    var errors = [];

    if (mobile.trim() === "") {
      setMobileErr("Mobile Number is required");
      errors.push("Mobile Number is required");
    } else if (!/^\d{10}$/.test(mobile)) {
      setMobileErr("Mobile Number should be a 10-digit number");
      errors.push("Mobile Number should be a 10-digit number");
    }
    if (alternate.trim() !== "") {
      if (!/^\d{10}$/.test(alternate)) {
        setAlternateMobileErr("Mobile Number should be a 10-digit number");
        errors.push("Mobile Number should be a 10-digit number");
      }
    }

    // console.log(mobile, "forms");
    if (errors.length === 0) {
      if (type === "next") {
        if (mobileVerify == false) {
          setMobileErr("Verify Your Mobile Number");
          errors.push("Verify Your Mobile Number");
        } else {
          // console.log("next");
          recruiterData.contact_no = "91" + mobile;
          recruiterData.mobileVerify = true;
          recruiterData.alternate = alternate;
          handleNext();
        }
      }
      if (type === "otp") {
        // console.log("otp");
        setMobileErr("");
        const randOtp = Math.floor(1000 + Math.random() * 9000).toString();
        // console.log(randOtp, "random");
        const res = await postData("/without-login/verify-otp/mobile-verify", {
          mobile: "91" + mobile,
          otp: randOtp,
          type: "Recruiter",
        });

        if (res?.success) {
          // console.log(res, "mobileotp");
          setOtpSend(randOtp);
          setModalShow(true);
          setTimerShow(true);
          setIsTimerExpired(false);
          setOtpErr("");
          setOtpValues(["", "", "", ""]);
          setMobileDis(true);
          setSeconds(60);
          setTimeout(() => {
            setModalShow(false);
          }, 4000);
        } else {
          // console.log(res, "fail");
          setMobileErr(res?.message);
        }
      }
    }
  };

  const [otpValues, setOtpValues] = useState(["", "", "", ""]);
  const inputRefs = [useRef(), useRef(), useRef(), useRef()];

  const handleOtpChange = (index, event) => {
    setOtpErr("");
    const inputValue = event.target.value;

    if (/^[0-9]*$/.test(inputValue) || inputValue === "") {
      otpValues[index] = inputValue;
      setOtpValues([...otpValues]);

      if (otpsend === otpValues.join("")) {
        // Call your OTP verification function here
        setMobileVerify(true);
        setTimerShow(false);
        setOtpValues(["", "", "", ""]);
        setOtpSend(null);
      }

      if (inputValue !== "" && index < 3) {
        inputRefs[index + 1].current.focus();
      } else {
        setOtpErr("OTP Invalid!");
      }
    }
  };

  const handleKeyDown = (index, event) => {
    setOtpErr("");
    if (event.key === "Backspace" && index > 0 && otpValues[index] === "") {
      otpValues[index - 1] = "";
      setOtpValues([...otpValues]);
      inputRefs[index - 1].current.focus();
    }
  };
  const [executeCount, setExecuteCount] = useState(0);

  const handleTerms = (element) => {
    if (element.target.checked) {
      recruiterData.policy = true;
    } else {
      recruiterData.policy = false;
    }
  };

  useEffect(() => {
    if (executeCount == 0) {
      if (recruiterData?.mobileVerify) {
        setMobileVerify(true);
        setPhone(recruiterData?.contact_no?.substr(2, 12));
      }
      setExecuteCount(1);
    }

    const decrementTimer = () => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else {
        setIsTimerExpired(true);
        setOtpSend(null);
        setMobileDis(false);
      }
    };

    const timerInterval = setInterval(decrementTimer, 1000);

    return () => {
      clearInterval(timerInterval);
    };
  }, [seconds]);
  return (
    <>
      <fieldset>
        <div className="form-card StepForm-recruiter">
          <div className="row">
            <div className="col-7">
              <h2>
                <span className="blue-line"></span>{" "}
                <span className="fs-title">Step5</span>
              </h2>
            </div>
          </div>
          <div className="row step1_nameField mt-4">
            <Form>
              <Row className="mb-3">
                <div className=" col-lg-6 col-md-12 col-12">
                  <Form.Group className="mb-3" controlId="formGridCity">
                    <Form.Label>
                      {" "}
                      Contact Number <span className="text-danger">*</span>
                    </Form.Label>
                    <div className="d-flex">
                      <div className="col-lg-3 col-md-3 col-4">
                        <Form.Select aria-label="Default select example">
                          <option>+91</option>
                        </Form.Select>
                      </div>
                      <div className="col-lg-6 col-md-6 col-5">
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Control
                            type="text"
                            minlength="10"
                            maxlength="10"
                            disabled={mobileVerify || mobileDisable}
                            value={recruiterData?.contact_no?.substr(2, 12)}
                            placeholder="Mobile Number"
                            style={{ textAlign: "left" }}
                            onKeyDown={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                if (event.key != "Backspace")
                                  event.preventDefault();
                              }
                            }}
                            onChange={(event) => {
                              // Add a space after the country code
                              setPhone(event.target.value);
                              setMobileErr("");
                            }}
                          />
                        </Form.Group>
                      </div>
                      <div className="col-lg-3 col-md-3 col-3">
                        <div className="text-lg-start text-md-center text-center">
                          {/* <Link to={"/login-recruiter"}> */}

                          {timerShow == false && mobileVerify == false && (
                            <span
                              className="input-group-text"
                              id="basic-addon2"
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                handleValidation("otp");
                              }}
                            >
                              Get Otp
                            </span>
                          )}

                          {timerShow && isTimerExpired == true && (
                            <span
                              className="input-group-text"
                              id="basic-addon2"
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                handleValidation("otp");
                              }}
                            >
                              Resend OTP
                            </span>
                          )}

                          {/* </Link> */}
                        </div>
                      </div>
                      {/* <span>
                        {" "}
                        <PhoneInput
                          country={"in"} // set the initial country code
                          enableSearch={true}
                          value={phone}
                          defaultValue={recruiterData && (recruiterData?.contact_no)}
                          onChange={(phone) => {
                            // Add a space after the country code
                            const formattedPhone = phone.replace(/(\+\d{1,4})(\d{3,14})/, '$1 $2');
                            setPhone(formattedPhone);
                            setMobileErr("");
                            setMobileVerify(false);
                            setTimerShow(false);
                          }}
                        />


                      </span> */}
                      {/* {mobileVerify == false && (
                      <span className="input-group-text" id="basic-addon2"
                      style={{
                        cursor:'pointer'
                      }}
                        onClick={() => {
                          handleValidation("otp");
                        }}
                      >
                        {timerShow == true? '':'Get OTP'} {isTimerExpired == true && timerShow == true ? 'Resend OTP' : ''}
                      </span>
                      )} */}
                    </div>
                    <div>
                      <ContactModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                      />
                    </div>
                    <span
                      className="text text-danger"
                      style={{
                        textAlign: "center",
                      }}
                    >
                      {mobileErr && mobileErr}
                    </span>
                  </Form.Group>
                  {timerShow && (
                    <div className="row text-center">
                      <div className="otpContainer">
                        {otpValues.map((value, index) => (
                          <input
                            className="otp"
                            type="text"
                            maxLength="1"
                            value={value}
                            onChange={(e) => handleOtpChange(index, e)}
                            onKeyDown={(e) => handleKeyDown(index, e)}
                            ref={inputRefs[index]}
                          />
                        ))}
                      </div>

                      <p className="otp">
                        Enter OTP within{" "}
                        {`${Math.floor(seconds / 60)}:${(seconds % 60)
                          .toString()
                          .padStart(2, "0")}`}{" "}
                        seconds
                      </p>
                      <span
                        className="text text-danger"
                        style={{
                          textAlign: "center",
                        }}
                      >
                        {otpERR && otpERR}
                      </span>
                    </div>
                  )}
                  {mobileVerify && (
                    <div className="row text-center">
                      <p className="otp text text-success">
                        Mobile Verified...!
                      </p>
                    </div>
                  )}
                </div>
                <div className=" col-lg-6 col-md-12 col-12">
                  <Form.Group className="mb-3" controlId="formGridState">
                    <Form.Label>
                      {" "}
                      Alternative Contact
                      {/* <span className="text-danger">*</span>{" "} */}
                    </Form.Label>
                    <Form.Control
                      text="text"
                      minlength="10"
                      maxlength="10"
                      placeholder="Enter Your Alternate No"
                      defaultValue={recruiterData && recruiterData?.alternate}
                      onChange={(event) => {
                        setAlternate(event.target.value);
                        setAlternateMobileErr("");
                      }}
                    />
                  </Form.Group>
                  <span
                    className="text text-danger"
                    style={{
                      textAlign: "center",
                    }}
                  >
                    {alternateMobileErr && alternateMobileErr}
                  </span>
                </div>
              </Row>

              <div className="mobile-view-button">
                <button
                  type="button"
                  name="next"
                  className="next action-button contin-btn"
                  onClick={() => handleValidation("next")}
                >
                  Continue
                </button>
              </div>
            </Form>
          </div>
        </div>
      </fieldset>

      <div className="row  mb-4 footer-sec">
        <div className="col-md-10 col-12">
          <div className="check-row text-start">
            <span>
              <input
                type="checkbox"
                name="group1"
                className="me-2"
                onChange={handleTerms}
                checked={recruiterData?.policy}
              />
            </span>
            <span className=" by-text ">By signing up, I agree to the</span>
            <span className="px-1 ">
              <Link to="/terms" className="term-btn">
                Term and Conditions
              </Link>
            </span>
            <br />
          </div>
          <div className="login-row text-start">
            <span className="">Already an User?</span>
            <span className="px-1 ">
              <Link to="/other-page" className="login-btn">
                log In
              </Link>
            </span>
            <br />
            <span className="">Not a Recruiter ?</span>
            <span className="px-1 ">
              <Link to="/getotp-jobseeker" className="signup-btn">
                Signup as Jobseeker
              </Link>
            </span>
          </div>
        </div>

        <div className="col-2">
          <div className="desktop-view-button">
            <button
              type="button"
              name="next"
              className="next action-button contin-btn"
              onClick={() => handleValidation("next")}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Step5;
