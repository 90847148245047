import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./DesiredMobileView.css";

const FilterJobMobile = () => {
  const [rangeValue, setRangeValue] = useState(50);
  const [sidebarClass, setSidebarClass] = useState("sidebar_height");
  const handleRangeChange = (event) => {
    setRangeValue(event.target.value);
  };
  return (
    <>
      <section className="Featured_Category">
        <div className="container">
          <div className="mobile-view-desired">
            <div className="row mt-1">
              <div className="col-lg-3 col-md-4">
                <div className="sidebar_featured_sec">
                  <h3 className="job_filter_txt">Job Filters</h3>
                  <div className={sidebarClass}>
                    <div className="down_content_sidebar ">
                      <div className="">
                        <p className="companyName_txt">Job Availability</p>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="flexCheckIndeterminateDisabled"
                          />
                          <label
                            className="form-check-label"
                            for="flexCheckIndeterminateDisabled"
                          >
                            Open
                          </label>
                        </div>
                        <div className="closedCheckbox_sec form-check mt-2">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="flexCheckIndeterminateDisabled"
                          />
                          <label
                            className="form-check-label"
                            for="flexCheckIndeterminateDisabled"
                          >
                            Closed
                          </label>
                        </div>
                      </div>
                      <div className="mt-4 CompanyName_section">
                        <p className="companyName_txt">Company Name</p>
                        <input
                          className="CompanyName"
                          type="text"
                          placeholder="Desired Company Name"
                        />
                        <div className="line"></div>
                      </div>
                      <div className="mt-4 CompanyName_section">
                        <p className="companyName_txt">Job Sector</p>
                        <input
                          className="CompanyName"
                          type="text"
                          placeholder="Desired Company Name"
                        />
                        <div className="line"></div>
                      </div>
                      <div className="CompanyName_section companyName_txt">
                        <p className="mt-4">Role</p>
                        <input
                          className="CompanyName"
                          type="text"
                          placeholder="Desired Company Name"
                        />
                      </div>
                      <div className="CompanyName_section companyName_txt">
                        <p className="mt-4">Highest Education</p>
                        <input
                          className="CompanyName"
                          type="text"
                          placeholder="Desired Company Name"
                        />
                      </div>
                      <div className="CompanyName_section companyName_txt">
                        <p className="mt-4">Primary Skills</p>
                        <select
                          className="form-select"
                          aria-label="Default select example"
                        >
                          <option selected>Desired Location</option>
                          <option value="1">Pune</option>
                          <option value="2">Mumbai</option>
                          <option value="3">Nashik</option>
                        </select>
                      </div>
                      <div className="CompanyName_section companyName_txt">
                        <p className="mt-4">Secondary Skills</p>
                        <select
                          className="form-select"
                          aria-label="Default select example"
                        >
                          <option selected>Desired Location</option>
                          <option value="1">Pune</option>
                          <option value="2">Mumbai</option>
                          <option value="3">Nashik</option>
                        </select>
                      </div>
                      <div className="CompanyName_section companyName_txt">
                        <p className="mt-4">Highest Education</p>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="flexCheckIndeterminateDisabled"
                          />
                          <label
                            className="form-check-label"
                            for="flexCheckIndeterminateDisabled"
                          >
                            Expert
                          </label>
                        </div>
                        <div className="mt-2 form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="flexCheckIndeterminateDisabled"
                          />
                          <label
                            className="form-check-label"
                            for="flexCheckIndeterminateDisabled"
                          >
                            Advance
                          </label>
                        </div>
                        <div className="mt-2 form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="flexCheckIndeterminateDisabled"
                          />
                          <label
                            className="form-check-label"
                            for="flexCheckIndeterminateDisabled"
                          >
                            Proficient
                          </label>
                        </div>
                      </div>
                      <div className="CompanyName_section companyName_txt">
                        <p className="mt-4">Salary Scale</p>

                        <div className="custom-range-slider">
                          <input
                            type="range"
                            min={0}
                            max={100}
                            value={rangeValue}
                            onChange={handleRangeChange}
                          />
                          <div
                            className="custom-track"
                            style={{ width: `${rangeValue}%` }}
                          ></div>
                          <p>Value: {rangeValue}</p>
                        </div>
                      </div>
                      <div className="CompanyName_section ">
                        <p className="mt-4 companyName_txt">Location</p>
                        <select
                          className="form-select"
                          aria-label="Default select example"
                        >
                          <option selected>Desired Location</option>
                          <option value="1">Pune</option>
                          <option value="2">Mumbai</option>
                          <option value="3">Nashik</option>
                        </select>
                      </div>
                      <div className="CompanyName_section">
                        <p className="mt-4 companyName_txt">Job Type</p>
                        <div className="mt-2 form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="flexCheckIndeterminateDisabled"
                          />
                          <label
                            className="form-check-label"
                            for="flexCheckIndeterminateDisabled"
                          >
                            Full Time
                          </label>
                        </div>
                        <div className="mt-2 form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="flexCheckIndeterminateDisabled"
                          />
                          <label
                            className="form-check-label"
                            for="flexCheckIndeterminateDisabled"
                          >
                            Part Time
                          </label>
                        </div>
                        <div className="mt-2 form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="flexCheckIndeterminateDisabled"
                          />
                          <label
                            className="form-check-label"
                            for="flexCheckIndeterminateDisabled"
                          >
                            Free Lance
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FilterJobMobile;
