import React, { useContext, useEffect, useState } from "react";
import "./UserProfile.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import Success from "../../Model/Animation/Success";
import UpdateModal from "./UpdateModal/UpdateModal";
import { Context } from "../../../../utils/context";
const UserProfile = () => {
  const { jobSeekerData, postData, fetchSeekerData, imageUrl } =
    useContext(Context);
  const [lgShow, setLgShow] = useState(false);
  const handleClose = () => setLgShow(false);
  const handleShow = () => setLgShow(true);

  const [updateshow, setUpdateshow] = React.useState(false);
  const openModal = () => {
    // handleClose();
    // setUpdateshow(true);
    // setTimeout(() => {
    //   // window.location = "/user-profile";
    //   setUpdateshow(false);
    // }, 3000);
  };

  // form
  const [formData, setFormData] = useState({
    first_name: jobSeekerData?.first_name,
    last_name: jobSeekerData?.last_name,
    image: jobSeekerData?.image,
    imageUrl: null,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleImageChange = (e) => {
    const newFormData = {
      ...formData,
      image: e.target.files[0],
    };

    if (e.target.files[0]?.type.startsWith("image/")) {
      newFormData.imageUrl = URL.createObjectURL(e.target.files[0]);
    } else {
      newFormData.imageUrl = null;
    }

    setFormData(newFormData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // openModal();

    const data = new FormData();
    data.append("image", formData.image);
    data.append("first_name", formData.first_name);
    data.append("last_name", formData.last_name);
    const result = await postData("/recruiters/profile", data);
    if (result?.success) {
      handleClose();
      setUpdateshow(true);
      setTimeout(() => {
        setUpdateshow(false);
        fetchSeekerData();
      }, 3000);
    }
  };

  useEffect(() => {
    if (jobSeekerData) {
      setFormData({
        first_name: jobSeekerData?.first_name,
        last_name: jobSeekerData?.last_name,
        image: jobSeekerData?.image,
        imageUrl: null,
      });
    } else {
      fetchSeekerData();
    }
  }, [jobSeekerData]);
  return (
    <>
      <section className="user-profile">
        <div className="container">
          <div className="row">
            <div className="col-md-2 col-4">
              <img
                src={
                  jobSeekerData?.image
                    ? imageUrl + jobSeekerData?.image
                    : process.env.PUBLIC_URL + "assets/Images/banner/manimg.png"
                }
                className="user-profile-img"
              />
            </div>
            <div className="col-md-10 col-8 ">
              <div className="text-end">
                <FontAwesomeIcon
                  icon="fa-solid fa-pen-to-square"
                  className="me-3"
                  style={{ cursor: "pointer" }}
                  onClick={() => setLgShow(true)}
                />
              </div>
              <div className="text-holder-company-profile">
                <h3 className="user-name">
                  {jobSeekerData?.first_name + " " + jobSeekerData?.last_name}{" "}
                </h3>
                {/* <p>HR Manager, at Hiclousia</p> */}
              </div>
              <div className="">
                <p className="text-holder-userprofile">
                  <FontAwesomeIcon
                    icon="fa-solid fa-phone"
                    className="icon-font"
                  />{" "}
                  {jobSeekerData?.contact_no}
                </p>
                <p className="text-holder-userprofile">
                  <FontAwesomeIcon
                    icon="fa-solid fa-envelope"
                    className="icon-font"
                  />{" "}
                  {jobSeekerData?.email}
                </p>
                {/* <p className="text-holder-userprofile">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "assets/Images/icons/linkedin.png"
                    }
                    className="social-user-profile"
                  />{" "}
                  https://linkedin.com/in/Shashikant Kumar
                </p>
                <p className="text-holder-userprofile">
                  {" "}
                  <img
                    src={
                      process.env.PUBLIC_URL + "assets/Images/icons/twitter.png"
                    }
                    className="social-user-profile"
                  />{" "}
                  https://twitter.com/Shashikant Kumar
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*  */}
      <section className="user-profile-talent">
        <div className="container">
          {/* <div className="row  ">
            <div className="col-md-12 text-center mt-md-5 mt-0">
              <h2 className="title-userprofile mt-3">My Talents Pool</h2>
            </div>
            <div className="col-md-12 text-md-end text-start">
              <Link to="/talentPool">
                <button className="btn Draft-btn ">Add Pool</button>
              </Link>
            </div>
          </div>
          <div className="row mt-md-5 mt-2">
            <div className="col-lg-4 col-md-4 col-4 ">
              <div className="grey-card">
                <Link to="/user-talent">
                  <p className="company-name">Silver (S1) Talent Pool</p>
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-4  ">
              <div className="yellow-card">
                <Link to="/user-talent">
                  <p className="company-name">Gold (G3) Talent Pool</p>
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-4  ">
              <div className="cream-card">
                <Link to="/user-talent">
                  <p className="company-name"> Gold (G3) Talent Pool </p>
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-4 ">
              <div className="grey-card">
                <Link to="/user-talent">
                  <p className="company-name">Silver (S1) Talent Pool</p>
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-4 "></div>
            <div className="col-lg-4 col-md-4 col-4 ">
              <div className="cream-card">
                <Link to="/user-talent">
                  <p className="company-name">Gold (G10) Talent Pool</p>
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-4 ">
              <div className="grey-card">
                <Link to="/user-talent">
                  <p className="company-name">Gold (G10) Talent Pool</p>
                </Link>
              </div>
            </div> 
          </div>*/}
        </div>
      </section>
      {/* Edit Modal */}
      <div className="edit-profile-modal-main">
        <Modal
          size="lg"
          show={lgShow}
          onHide={() => setLgShow(false)}
          className="edit-profile-modal"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <h4>Edit Portfolio</h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form role="form">
              <Row>
                <Col md={6}>
                  <Form.Group controlId="firstName" className="mb-3">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="first_name"
                      placeholder="Enter Firstname"
                      value={formData.first_name}
                      onChange={handleInputChange}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="lastName" className="mb-3">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="last_name"
                      placeholder="Enter Lastname"
                      value={formData.last_name}
                      onChange={handleInputChange}
                      required
                    />
                  </Form.Group>
                </Col>
                {/* <Col md={6}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Enter Email</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      placeholder="Enter Email"
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="mobileNumber" className="mb-3">
                    <Form.Label>Mobile Number</Form.Label>
                    <Form.Control
                      type="text"
                      name="mobileNumber"
                      placeholder="Enter Mobile Number"
                      value={formData.mobileNumber}
                      onChange={handleInputChange}
                      required
                    />
                  </Form.Group>
                </Col> */}
                <Col md={6}>
                  <Form.Group controlId="formFileLg" className="mb-3">
                    <Form.Label>User Image</Form.Label>
                    <Form.Control
                      type="file"
                      name="image"
                      onChange={handleImageChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="formFileLg" className="mb-3">
                    <img
                      src={
                        formData.imageUrl
                          ? formData?.imageUrl
                          : imageUrl + formData?.image
                      }
                      alt="Preview"
                      className="image-preview-container"
                      style={{
                        width: "120px",
                        height: "120px",
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <div className="text-center">
                <Button
                  variant="primary"
                  type="button"
                  onClick={() => setLgShow(false)}
                  className="blue-btn-class me-3"
                >
                  Close
                </Button>
                <Button
                  className="blue-btn-class"
                  type="button"
                  variant="primary"
                  onClick={handleSubmit}
                >
                  Update
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
      </div>

      {/* update modal */}
      <UpdateModal show={updateshow} onHide={() => setUpdateshow(false)} />
    </>
  );
};

export default UserProfile;
