import React from "react";
import "../ExploreOpportunity/ExploreOpportunity.css";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

const ExploreOpportunity = () => {
  return (
    <>
      <section className="Job-Opportunity">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-7 col-sm-8 col-8">
              <div className="text-holder-job-alert">
                <h6>
                  Explore New
                  <span className="opportunities_txt">Opportunities</span>
                </h6>
                <h5 className="withUs_txt">with Us!</h5>
                <p>Get Placed Right with the Right Source!</p>
                <div className="button-container">
                  <Link to={"/exploreApportunity"}>
                    <button className="btn btn-primary">Explore More</button>
                  </Link>
                  <div className="arrow-icon">
                    <FontAwesomeIcon icon={faArrowRight} />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5 col-sm-4 col-4">
              <div className="mx-auto text-center">
                <img
                  src={
                    process.env.PUBLIC_URL + "assets/Images/banner/girlimg.png"
                  }
                  className="userimg"
                  alt="..."
                />
              </div>
              <div className="OpportunityverticalLine"></div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ExploreOpportunity;
