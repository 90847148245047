import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";

import { useContext } from "react";
import { Context } from "../../../../utils/context";

function Step4({ handleNext }) {
  const { recruiterData, setRecruiterData } = useContext(Context);

  const [genderErr, setGenderErr] = useState("");
  const [dobErr, setDobErr] = useState("");

  const handleValidation = async () => {
    const gender = document.querySelector(
      'input[name="group1"]:checked'
    )?.value;
    const dob = document.getElementById("dob").value;

    // Initialize an array to store error messages
    const errors = [];

    if (!gender) {
      setGenderErr("Please select a gender");
      errors.push("Please select a gender");
    }

    if (!dob.trim()) {
      setDobErr("Date of birth is required");
      errors.push("Date of birth is required");
    } else {
      const currentDate = new Date();
      const enteredDate = new Date(dob);

      if (enteredDate > currentDate) {
        setDobErr("Date of birth cannot be in the future");
        errors.push("Date of birth cannot be in the future");
      }
    }
    // console.log(gender, 'forms');
    if (errors.length === 0) {
      setRecruiterData({
        ...recruiterData,
        gender: gender,
        dob: dob,
      });
      handleNext();
    }
  };

  const handleGenderChange = (selectedGender) => {
    setRecruiterData({
      ...recruiterData,
      gender: selectedGender,
    });
    setGenderErr(false);
  };

  const handleTerms = (element) => {
    if (element.target.checked) {
      recruiterData.policy = true;
    } else {
      recruiterData.policy = false;
    }
  };

  useEffect(() => {
    // console.log(recruiterData, 'recruiterData');
  }, []);

  return (
    <>
      <fieldset>
        <div className="form-card StepForm-recruiter">
          <div className="row">
            <div className="col-7">
              <h2>
                <span className="blue-line"></span>{" "}
                <span className="fs-title">Step 4</span>
              </h2>
            </div>
          </div>
          <Form>
            <Row className="mb-3">
              <div className="col-md-6 col-12">
                <Form.Group controlId="formGridCity">
                  <Form.Label>Gender*</Form.Label>
                  {["radio"].map((type) => (
                    <div key={`default-${type}`} className="mb-3">
                      <div className="d-flex">
                        <Form.Check
                          className="me-3 gender-name"
                          name="group1"
                          type={type}
                          value="male"
                          label={`Male`}
                          id={`male-${type}`}
                          onChange={() => handleGenderChange("male")}
                          checked={recruiterData?.gender === "male"}
                        />
                        <Form.Check
                          className="gender-name"
                          name="group1"
                          type={type}
                          value="female"
                          label={`Female`}
                          id={`female-${type}`}
                          onChange={() => handleGenderChange("female")}
                          checked={recruiterData?.gender === "female"}
                        />
                      </div>
                    </div>
                  ))}

                  {genderErr && (
                    <div className="error-message text-danger">{genderErr}</div>
                  )}
                </Form.Group>
              </div>
              <div className="col-md-6 col-12">
                <Form.Group controlId="formGridState">
                  <Form.Label>DOB*</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="date"
                      placeholder="saurav"
                      id="dob"
                      onChange={() => setDobErr(false)}
                      defaultValue={recruiterData?.dob}
                    />
                  </InputGroup>
                  {dobErr && (
                    <div className="error-message  text-danger">{dobErr}</div>
                  )}
                </Form.Group>
              </div>
            </Row>
          </Form>
          <div className="mobile-view-button">
            <button
              type="button"
              name="next"
              className="next action-button contin-btn"
              onClick={handleValidation}
            >
              Continue
            </button>
          </div>
        </div>
      </fieldset>

      <div className="row mb-4 footer-sec">
        <div className="col-md-10 col-12">
          <div className="check-row text-start">
            <span>
              <input
                type="checkbox"
                name="group1"
                className="me-2"
                onChange={handleTerms}
                checked={recruiterData?.policy}
              />
            </span>
            <span className=" by-text ">By signing up, I agree to the</span>
            <span className="px-1 ">
              <Link to="/terms" className="term-btn">
                Term and Conditions
              </Link>
            </span>
            <br />
          </div>
          <div className="login-row text-start">
            <span className="">Already an User?</span>
            <span className="px-1 ">
              <Link to="/other-page" className="login-btn">
                log In
              </Link>
            </span>
            <br />
            <span className="">Not a Recruiter ?</span>
            <span className="px-1 ">
              <Link to="/getotp-jobseeker" className="signup-btn">
                Signup as Jobseeker
              </Link>
            </span>
          </div>
        </div>

        <div className="col-2">
          <div className="desktop-view-button">
            <button
              type="button"
              name="next"
              className="next action-button contin-btn"
              onClick={handleValidation}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Step4;
