import React from 'react'
import PostedJob from './PostedJob/PostedJob.js';
import PostedText from './PostedJob/PostedText.js';


const PostedJobMain = () => {
    return (
        <div>
            <PostedJob />
            <PostedText />
        </div>
    )
} 

export default PostedJobMain;