import React from 'react';
import '../PrivacyBanner/PrivacyBanner.css'
import { Link } from 'react-router-dom';

const PrivacyBanner = () => {
    return (
        <>
            <section className="privacy-banner">
                <div className='container-fluid '>
                    <div className="row ">
                        <div className="col-md-12">
                             {/* <div className='banner-img-short'>
                                <img src={banner} className='banner-img' />
                            </div>  */}
                            <div className='text-holder-privacy-banner'>
                                <h2>Privacy Policy</h2>
                            </div>
                            <div className='go-to-text desktop-view-text'>
                                <Link to='/dashboardmain'><a>Go To Dashboard</a></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PrivacyBanner;