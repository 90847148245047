import React from 'react'
import Modal from 'react-bootstrap/Modal';
import "./PopupModal.css"
import Popup_Animation from './Popup_Animation/Popup_Animation';
const PopupModal = (props) => {
  return (
    <>
      <section >
        <Modal
          {...props}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          className='PopupModal'
          centered          
        >          
          <Modal.Body>
            <Popup_Animation/>
            <p className='modal_txt'> Your Need to Log In to Jobseeker <br/> to Applying for Job!</p>
            <p className='text-center' style={{color: "#FFFF"}}> Have a Great Time! </p>
          </Modal.Body>         
        </Modal>
      </section>
    </>
  )
}

export default PopupModal