import React from 'react'
import { Link } from 'react-router-dom';
const ProjectPrimarySkill = () => {
    return (
        <>
            <div className='row mt-3'>
                <div className='col-md-12'>
                    <div className='card'>
                        <Link to='/Portfolio-edit-primaryskill'>
                            <div className='edit-icon text-end'>
                                <img src={process.env.PUBLIC_URL + "assets/Images/my-portfolio/Icon-edit.png"} className='edit-img me-3' />
                            </div></Link>
                        <div className='row mb-3'>
                            <div className='col-lg-2'>
                                <div className='skill-holder1'>
                                    <h4>Primary Skills</h4>
                                </div>
                            </div>

                            <div className='col-lg-9'>
                                <div className="years-and-data-holder">
                                    <div className="years">
                                        <div className="year-holder">
                                            <h1 className="tittle">PYTHON</h1>
                                        </div>
                                        <div className="year-holder">
                                            <h1 className="tittle">JAVA</h1>
                                        </div>

                                        <div className="year-holder">
                                            <h1 className="tittle">JS</h1>
                                        </div>

                                        <div className="year-holder">
                                            <h1 className="tittle">NODE JS</h1>
                                        </div>

                                        <div className="year-holder">
                                            <h1 className="tittle">REACT</h1>
                                        </div>

                                        <div className="year-holder">
                                            <h1 className="tittle">HTML</h1>
                                        </div>
                                    </div>

                                    <div className="data-table">
                                        <div className="data-box">
                                            <div className="box1">
                                                <div className="dashed-box"></div>
                                            </div>
                                            <div className="data-title1">
                                                <p className="tittle">50%</p>
                                            </div>

                                        </div>

                                        <div className="data-box">
                                            <div className="box2">
                                                <div className="dashed-box"></div>
                                            </div>
                                            <div className="data-title2">
                                                <p className="tittle">60%</p>
                                            </div>

                                        </div>

                                        <div className="data-box">
                                            <div className="box3">
                                                <div className="dashed-box"></div>
                                            </div>
                                            <div className="data-title3">
                                                <p className="tittle">80%</p>
                                            </div>

                                        </div>

                                        <div className="data-box">
                                            <div className="box4">
                                                <div className="dashed-box"></div>
                                            </div>
                                            <div className="data-title4">
                                                <p className="tittle">59%</p>
                                            </div>

                                        </div>

                                        <div className="data-box">
                                            <div className="box5">
                                                <div className="dashed-box"></div>
                                            </div>
                                            <div className="data-title5">
                                                <p className="tittle">85%</p>
                                            </div>

                                        </div>

                                        <div className="data-box">
                                            <div className="box6">
                                                <div className="dashed-box"></div>
                                            </div>
                                            <div className="data-title6">
                                                <p className="tittle">95%</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProjectPrimarySkill