import React, { useEffect, useState, useRef } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import EmailChangedModal from "../AccountModel/EmailChangedModal";
import profileImg from "../../AccountManagement1/AccountDelete/Images/accountManagament.png";
import "../AccountText/AccountText.css";
import ContactModal from "../../../RecruiterPage/LoginSignUp/login-page/ContactModal/ContactModal";
import { useNavigate, useLocation } from "react-router-dom";

import { useContext } from "react";
import { Context } from "../../../../utils/context";
const AccountTextModal2 = (props) => {
  // AccountText Modal-2

  const handleClose = () => {
    setTimerShow(false);
    setIsTimerExpired(false);
    setEmailErr("");
    setOtpErr("");
    setOtpValues(["", "", "", ""]);
    setEmail("");
    setEmailVerify(false);
    setEmailDis(false);
    props?.onHide();
  };

  // Verify Otp

  // function handliclick() {
  //     setVerify(!verify)
  // }

  const { postData, recruiterData, setRecruiterData, imageUrl, jobSeekerData } =
    useContext(Context);

  const [emailVerify, setEmailVerify] = useState(false);

  const [changeShow, setChangeShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [timerShow, setTimerShow] = useState(false);

  const [seconds, setSeconds] = useState(0); // Initial value is 60 seconds
  const [isTimerExpired, setIsTimerExpired] = useState(false);
  const [otpERR, setOtpErr] = useState("");

  const [otpsend, setOtpSend] = useState(null);

  const [error, seterror] = useState(false);
  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);
  const inputRef3 = useRef(null);
  const inputRef4 = useRef(null);
  const navigate = useNavigate();
  const { state } = useLocation();

  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [emailDisable, setEmailDis] = useState(false);

  const handleValidation = async (type) => {
    // Initialize an array to store error messages
    var errors = [];

    if (email.trim() === "") {
      setEmailErr("Email is required");
      errors.push("Email is required");
    } else if (!/^\S+@\S+\.\S+$/.test(email)) {
      setEmailErr("Email is not valid");
      errors.push("Email is not valid");
    }

    if (errors.length === 0) {
      if (type === "next") {
        if (emailVerify == false) {
          setEmailErr("Verify Your Email");
          errors.push("Verify Your Email");
        } else {
          const res = await postData(`/common/email-change`, { email: email });
          if (res?.success) {
            setTimerShow(false);
            setIsTimerExpired(false);
            setEmailErr("");
            setOtpErr("");
            setOtpValues(["", "", "", ""]);
            setEmail("");
            setEmailVerify(false);
            setEmailDis(false);
            setChangeShow(true);
            props?.onHide();
            props?.getUserData();
            setTimeout(() => {
              setChangeShow(false);
            }, 4000);
          }
        }
      }
      if (type === "otp") {
        // console.log("otp");
        setEmailErr("");
        const randOtp = Math.floor(1000 + Math.random() * 9000).toString();
        // console.log(randOtp, "random");
        const res = await postData("/without-login/verify-otp/email-verify", {
          email: email,
          otp: randOtp,
        });

        if (res?.success) {
          // console.log(res, "emailotp");
          setOtpSend(randOtp);
          setModalShow(true);
          setTimerShow(true);
          setIsTimerExpired(false);
          setOtpErr("");
          setOtpValues(["", "", "", ""]);
          setSeconds(60);
          setEmailDis(true);
          setTimeout(() => {
            setModalShow(false);
          }, 4000);
        } else {
          // console.log(res, "fail");
          setEmailErr(res?.message);
        }
      }
    }
  };

  const [otpValues, setOtpValues] = useState(["", "", "", ""]);
  const inputRefs = [useRef(), useRef(), useRef(), useRef()];

  const handleOtpChange = (index, event) => {
    setOtpErr("");
    const inputValue = event.target.value;

    if (/^[0-9]*$/.test(inputValue) || inputValue === "") {
      otpValues[index] = inputValue;
      setOtpValues([...otpValues]);

      if (otpsend === otpValues.join("")) {
        // Call your OTP verification function here
        setEmailVerify(true);
        setTimerShow(false);
        setOtpValues(["", "", "", ""]);
        setOtpSend(null);
      }

      if (inputValue !== "" && index < 3) {
        inputRefs[index + 1].current.focus();
      } else {
        setOtpErr("OTP Invalid!");
      }
    }
  };

  const handleKeyDown = (index, event) => {
    setOtpErr("");
    if (event.key === "Backspace" && index > 0 && otpValues[index] === "") {
      otpValues[index - 1] = "";
      setOtpValues([...otpValues]);
      inputRefs[index - 1].current.focus();
    }
  };
  const [executeCount, setExecuteCount] = useState(0);

  const handleTerms = (element) => {
    if (element.target.checked) {
      recruiterData.policy = true;
    } else {
      recruiterData.policy = false;
    }
  };

  useEffect(() => {
    if (executeCount == 0) {
      if (recruiterData?.emailVerify) {
        setEmail(recruiterData?.email);
        setEmailVerify(true);
      }
      setExecuteCount(1);
    }

    const decrementTimer = () => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else {
        setIsTimerExpired(true);
        setOtpSend(null);
        setEmailDis(false);
      }
    };

    const timerInterval = setInterval(decrementTimer, 1000);

    return () => {
      clearInterval(timerInterval);
    };
  }, [seconds]);

  return (
    <>
      <Modal
        className="setting-modal"
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        fullscreen={false}
      >
        <Modal.Header className="title-privacy" closeButton>
          <h5>Email Setting</h5>
        </Modal.Header>
        <Modal.Body className="p-lg-0 p-md-2 p-2">
          <section className="Account-management">
            <div className="container-fluid">
              <div className="row  justify-content-center ">
                {/* <div className="accountManagement_main"></div> */}
                {/* <div className=" col-lg-10 col-md-12"> */}
                {/* <div className="company-profile-title text-center">
                    <div>
                      {jobSeekerData?.image ? (
                        <img
                          src={imageUrl + jobSeekerData?.image}
                          className="accManprofile_img"
                        />
                      ) : (
                        <img src={profileImg} className="accManprofile_img" />
                      )}

                      <img src={profileImg} className="accManprofile_img" />
                    </div>
                    <h2>Account Management</h2>
                  </div> */}
                <div className="subaccount-manage">
                  <div className="row  justify-content-center mt-md-5 mt-3 ">
                    <div className="col-md-12">
                      {/* <div className="text-holder-privacy"> */}
                      {/* <div className="title-privacy">
                            <h5>Email Setting</h5>
                          </div> */}
                      {/* <div className="first-jobseeker-1"> */}
                      <div className="row">
                        <div className="col-md-8 ">
                          <label htmlFor="email">New Email</label>
                        </div>
                        <div className="col-md-4 text-md-end">
                          {emailVerify === false && timerShow !== true && (
                            <button
                              className="verify-btn"
                              onClick={() => {
                                handleValidation("otp");
                              }}
                            >
                              Verify Email
                            </button>
                          )}
                          {emailVerify === false &&
                            timerShow === true &&
                            isTimerExpired === true && (
                              <button
                                className="verify-btn"
                                onClick={() => {
                                  handleValidation("otp");
                                }}
                              >
                                Resend OTP
                              </button>
                            )}
                        </div>
                        <div className="col-md-12">
                          <input
                            className="form-control mt-0 p-2"
                            placeholder="Enter your email id"
                            aria-label="Recipient's username"
                            aria-describedby="basic-addon2"
                            disabled={emailVerify || emailDisable}
                            defaultValue={email}
                            type="email"
                            id="email"
                            onChange={(event) => {
                              setEmail(event.target.value);
                              setEmailErr("");
                              setEmailVerify(false);
                              setTimerShow(false);
                            }}
                          />
                        </div>
                      </div>
                      <span
                        className="text text-danger"
                        style={{
                          textAlign: "center",
                        }}
                      >
                        {emailErr && emailErr}
                      </span>
                      {emailVerify && (
                        <div className="row text-center">
                          <p className="otp text text-success">
                            Email Verified...!
                          </p>
                        </div>
                      )}
                      <div>
                        <ContactModal
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                        />
                      </div>
                      {timerShow && (
                        <div className="row text-center">
                          <div className="otpContainer">
                            {otpValues.map((value, index) => (
                              <input
                                className="otp"
                                type="text"
                                maxLength="1"
                                value={value}
                                onChange={(e) => handleOtpChange(index, e)}
                                onKeyDown={(e) => handleKeyDown(index, e)}
                                ref={inputRefs[index]}
                              />
                            ))}
                          </div>

                          <p className="otp">
                            Enter OTP within{" "}
                            {`${Math.floor(seconds / 60)}:${(seconds % 60)
                              .toString()
                              .padStart(2, "0")}`}{" "}
                            seconds
                          </p>
                          <span
                            className="text text-danger"
                            style={{
                              textAlign: "center",
                            }}
                          >
                            {otpERR && otpERR}
                          </span>
                        </div>
                      )}
                      {/* </div> */}
                      <div className="text-center mt-4">
                        <button className=" decline me-2" onClick={handleClose}>
                          Cancel
                        </button>

                        <button
                          type="button"
                          onClick={() => handleValidation("next")}
                          disabled={emailVerify ? false : true}
                          className=" accept"
                        >
                          Save
                        </button>

                        <EmailChangedModal
                          show={changeShow}
                          onHide={() => setChangeShow(false)}
                        />
                      </div>
                      {/* </div> */}
                    </div>
                  </div>
                </div>
                {/* </div> */}
              </div>
            </div>
          </section>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AccountTextModal2;
