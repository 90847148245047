import React, { useContext, useEffect, useRef, useState } from "react";
import "./PortfolioJobExperience.css";
import { Link } from "react-router-dom";
import { Form, Row, Col, Button, Nav, Tab } from "react-bootstrap";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { Context } from "../../../../utils/context";
import Autosuggest from "react-autosuggest";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "react-bootstrap/Modal";
import {
  faUpload,
  faFile,
  faXmark,
  faPlus,
  faMinus,
} from "@fortawesome/free-solid-svg-icons";
const PortfolioViewDetail = () => {
  const vidRef = useRef();

  useEffect(() => {
    // vidRef.current.play();
  }, []);

  const {
    getData,
    postData,
    recruiterData,
    jobSeekerData,
    editStatusData,
    getJobSeekerData,
    Select2Data,
    fetchSeekerData,
    Select2Skills,
    Select2Roles,
  } = useContext(Context);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const inputRef = React.useRef(null);

  const handleUploadButtonClick = (id) => {
    // Trigger a click on the file input
    // inputRef.current.click();
    document.getElementById(id).click();
  };

  const [currentMediaPreview, setCurrentMediaPreview] = useState("");
  const [currentEvidencePreview, setCurrentEvidencePreview] = useState("");

  const [currentMedia, setCurrentMedia] = useState([]);
  const [currentEvidence, setCurrentEvidence] = useState([]);

  const handleCurrentMediaChange = (event) => {
    // Handle file change here
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      // setCurrentMedia(...currentMedia,selectedFile);
      setCurrentMedia((prevMedia) => [...prevMedia, selectedFile]);
      setCurrentMediaPreview(selectedFile.name);
    }
  };

  const handleJobDelete = async (id) => {
    var data = "";
    if (jobData?.length === 1) {
      var data = {
        step: 9,
        status: "none",
      };
    } else {
      var data = {
        step: 9,
        status: "none",
      };
    }
    const res = await editStatusData(
      `/jobseeker/signup/experiance-job/${id}`,
      data
    );
    setCurrentStatus(true);
    await getJobExperience();
  };

  const [currentEvidenceLoader, setCurrentEvidenceLoader] = useState(0.0);
  const handleCurrentEvidenceChange = (event) => {
    // Handle file change here
    setCurrentEvidenceLoader(0.0);
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      // Update the state with the selected file name
      const interval = setInterval(() => {
        // Increase loader value (random increase for demonstration)
        setCurrentEvidenceLoader((prevLoader) =>
          Math.min(prevLoader + Math.random() * 0.1, 1)
        );
      }, 500);
      // setCurrentEvidence(...currentEvidence,selectedFile);
      setCurrentEvidence((prevMedia) => [...prevMedia, selectedFile]);
      setCurrentEvidencePreview(selectedFile.name);
      // Clear the interval on component unmount
      return () => clearInterval(interval);
    }
  };

  const deleteCurrentEvidence = () => {
    setCurrentEvidenceLoader(0.0);
    setCurrentEvidencePreview("");
  };

  // Generate an array of years, you can customize the range as needed
  const years = Array.from({ length: 100 }, (_, index) => ({
    label: `${new Date().getFullYear() - index}`,
    value: `${new Date().getFullYear() - index}`,
  }));

  const [formCurrentDatas, setFormData] = useState({
    org_type: true,
    step: 9,
    media: undefined,
    job_role_id: "",
    contact_person: "",
    start_date: "",
    end_date: "",
    job_nature_id: [],
    location_id: "",
    skills: [],
    company_id: "",
    contact_person_email: "",
    responsibilities: "",
    achievement_id: "",

    skills_value: [],
  });

  const [ftype, setFType] = useState(true);
  const [lgShow, setLgShow] = useState(false);

  const handleClose = async () => {
    setLgShow(false);
    await getJobExperience();
  };
  const handleShow = async (type = "ADD") => {
    if (type === "ADD") {
      await setFType(true);
      await setSelectedJobNature();
      await setFormData({
        org_type: currentStatus ? true : false,
        step: 9,
        media: undefined,
        job_role_id: "",
        contact_person: "",
        start_date: "",
        end_date: "",
        job_nature_id: [],
        location_id: "",
        skills: [],
        company_id: "",
        contact_person_email: "",
        responsibilities: "",
        achievement_id: "",

        skills_value: [],
      });
      // await setSelectedArea_of_studay([]);
      await setErrors();
    } else {
      await setFType(false);
    }
    await setLgShow(true);
    await setErrors({});
  };

  const getEditData = async (id) => {
    setFType(false);
    const res = await getData(`/jobseeker/signup/experiance-job/${id}`);
    // console.log(res);
    const data = res.data;
    if (data?.org_type === 1) {
      setCurrentStatus(true);
    }
    setSelectedJobNature(
      data?.jobseeker_employement_job_natures?.map((d) => ({
        name: "job_nature_id",
        label: d?.job_nature?.name,
        value: d?.job_nature_id,
      }))
    );
    setFormData({
      ...formCurrentDatas,
      id: data?.id,
      job_role_id: {
        value: data?.role?.id,
        label: data?.role?.name,
        name: "job_role_id",
      },
      org_type: data?.org_type,
      responsibilities: data?.responsibilities,
      media: "edit",
      company_id: data?.company?.name,
      start_date: data?.start_date,
      end_date: data?.end_date,
      job_nature_id: data?.job_nature
        ? {
            value: data?.job_nature?.id,
            label: data?.job_nature?.name,
            name: "job_nature_id",
          }
        : "",

      location_id: data?.city
        ? {
            value: data?.city?.id,
            label: data?.city?.name,
            name: "location_id",
          }
        : "",
      contact_person: data?.contact_person,
      contact_person_email: data?.contact_person_email,
      achievement_id: data?.achievement_id
        ? {
            name: "achievement_id",
            label: data?.achievement?.name,
            value: data?.achievement_id,
          }
        : "",
      skills: (data?.jobseeker_employement_skills || []).map(
        (skill, index) => skill?.id
      ),
      skills_value: await data?.jobseeker_employement_skills?.map((d) => ({
        name: "skills",
        label: d?.skill?.name + " - " + d?.skill?.skill_type?.name,
        value: d?.skill_id,
      })),
      job_nature_id: await data?.jobseeker_employement_job_natures?.map(
        (d) => d?.job_nature_id
      ),
    });
    handleShow("EDIT");
  };

  const handleCurrentSelect2Change = async (selectedOption) => {
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        skills: selectedOption.map((item) => item.value),
        skills_value: selectedOption,
      };
    });
  };

  const [seletedJobNature, setSelectedJobNature] = useState([]);

  useEffect(() => {
    setFormData({
      ...formCurrentDatas,
      ["job_nature_id"]: seletedJobNature?.map((item) => item.value),
    });
  }, [seletedJobNature]);

  const handleChange = (e) => {
    if (e?.name === "achievement_id" || e?.name === "certificate_for_id") {
      setFormData({ ...formCurrentDatas, [e.name]: e });
    } else {
      setFormData({ ...formCurrentDatas, [e.target.name]: e.target.value });
    }
  };
  const handleCurrentSelectChange = async (e) => {
    if (
      e?.name === "job_role_id" ||
      e?.name === "job_nature_id" ||
      e?.name === "location_id" ||
      e?.name === "achievement_id" ||
      e?.name === "state_id" ||
      e?.name === "city_id" ||
      e?.name === "type_id"
    ) {
      if (e?.name === "job_role_id") {
        setFormData({ ...formCurrentDatas, [e?.name]: e });
      }
      if (e?.name === "job_nature_id") {
        setFormData({ ...formCurrentDatas, [e?.name]: e });
      }
      if (e?.name === "achievement_id") {
        setFormData({ ...formCurrentDatas, [e?.name]: e });
      }
      if (e?.name === "location_id") {
        setFormData({ ...formCurrentDatas, [e?.name]: e });
      } else {
        setFormData({ ...formCurrentDatas, [e.name]: e });
      }
    } else {
      setFormData({ ...formCurrentDatas, [e.name]: e.name });
    }
  };

  const handleCurrentInputChange = (e) => {
    if (
      e?.target?.name === "area_of_study" ||
      e?.target?.name === "contact_person" ||
      e?.target?.name === "responsibilities" ||
      e?.target?.name === "contact_person_email"
    ) {
      if (e?.target?.name === "area_of_study") {
        setFormData({
          ...formCurrentDatas,
          [e?.target?.name]: e.target.value,
        });
      }
      if (e?.target?.name === "contact_person") {
        setFormData({
          ...formCurrentDatas,
          [e?.target?.name]: e.target.value,
        });
      }
      if (e?.target?.name === "contact_person_email") {
        setFormData({
          ...formCurrentDatas,
          [e?.target?.name]: e.target.value,
        });
      }
      if (e?.target?.name === "responsibilities") {
        setFormData({
          ...formCurrentDatas,
          [e?.target?.name]: e.target.value,
        });
      }
    }
  };
  const [currentIndex, setcurrentIndex] = useState(0);
  const [prevIndex, setPrevIndex] = useState(0);
  const [roles, setRole] = useState();
  const [roleCurrentErr, setRoleCurrentErr] = useState("");

  const [skills, setSkill] = useState();

  const [jobNature, setJobNature] = useState();
  const [jobLocation, setJobLocation] = useState();
  const [achievements, setAchievement] = useState();
  const getMasters = async () => {
    {
      const res = await getData(`/without-login/master/all-roles`);
      if (res?.success) {
        // console.log(res.data, "roles");

        const data = await Select2Roles(res.data, "job_role_id", false);
        setRole(data);
      }
    }
    {
      const res = await getData(`/without-login/master/all-skills`);
      if (res?.success) {
        // console.log(res.data, "skills");

        const data = await Select2Skills(res.data, "skill_id", false);
        setSkill(data);
      }
    }
    {
      const res = await getData("/without-login/master/all-company");
      if (res?.success) {
        const data = await Select2Data(res.data, "company_id", false);
        // setCompamies(data);
      }
    }
    {
      const res = await getData("/without-login/master/all-job-nature");
      if (res?.success) {
        const data = await Select2Data(res.data, "job_nature_id", false);
        setJobNature(data);
      }
    }
    {
      const res = await getData("/without-login/master/all-cities");
      if (res?.success) {
        const data = await Select2Data(res.data, "location_id", false);
        setJobLocation(data);
      }
    }
    {
      const res = await getData("/without-login/master/all-achievemente");
      if (res?.success) {
        const data = await Select2Data(res.data, "achievement_id", false);
        setAchievement(data);
      }
    }
  };

  const [jobData, setJobData] = useState();

  const [currentStatus, setCurrentStatus] = useState(true);
  const [status, setStatus] = useState("none");
  const getJobExperience = async () => {
    const res = await getData(`/jobseeker/signup/experiance-job`);
    if (res?.success) {
      // console.log(res.data, "job");
      setJobData(res.data);
      if (res?.data.length !== 0) {
        setStatus("none");
      } else {
        setStatus("none");
      }
      res?.data?.forEach((item) => {
        // console.log(item, "ssss");
        if (item?.org_type === 1) {
          setCurrentStatus(false);
        }
      });
    }
  };

  const [errors, setErrors] = useState();
  const errorStyle = {
    color: "red",
    marginLeft: "5px",
    // fontSize: "11px",
  };

  const validateForm = () => {
    let errors = {};
    const regexAlfaNumric = /^[A-Za-z0-9]+(\s[A-Za-z0-9]+)*$/;
    const regexAlfa = /^[A-Za-z]+(\s[A-Za-z]+)*$/;

    if (formCurrentDatas?.media === undefined) {
      errors.media = "Media is required";
    }
    if (!formCurrentDatas.start_date) {
      errors.start_date = "Start Date is required";
    }

    if (!formCurrentDatas.end_date) {
      errors.end_date = "End Date is required";
    } else if (
      new Date(formCurrentDatas.end_date) <
      new Date(formCurrentDatas.start_date)
    ) {
      errors.end_date = "End Date cannot be a past date of Start Date";
    } else if (new Date(formCurrentDatas.end_date) > new Date()) {
      errors.end_date = "End Date cannot be a future date";
    }

    if (!formCurrentDatas.company_id) {
      errors.company_id = "Company Name is required";
    }
    if (!formCurrentDatas.job_role_id) {
      errors.job_role_id = "Job Role is required";
    }

    if (formCurrentDatas?.skills.length === 0) {
      errors.skills = "Skills is required";
    }

    return errors;
  };

  const handleJobSave = async () => {
    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        const finalData = new FormData();
        if (formCurrentDatas.media != "edit") {
          finalData.append(`media`, formCurrentDatas.media);
        }
        finalData.append(`org_type`, formCurrentDatas.org_type ? 1 : 0);
        finalData.append(`responsibilities`, formCurrentDatas.responsibilities);
        finalData.append(`step`, formCurrentDatas.step);
        finalData.append(`status`, "none");
        if (formCurrentDatas.job_role_id) {
          finalData.append(`job_role_id`, formCurrentDatas.job_role_id?.value);
        }
        if (formCurrentDatas.job_nature_id) {
          finalData.append(
            `job_nature_id`,
            JSON.stringify(formCurrentDatas.job_nature_id)
          );
        }
        if (formCurrentDatas.location_id) {
          finalData.append(`location_id`, formCurrentDatas.location_id?.value);
        }

        if (formCurrentDatas.achievement_id) {
          finalData.append(
            `achievement_id`,
            formCurrentDatas.achievement_id?.value
          );
        }
        if (formCurrentDatas.university_type_id) {
          finalData.append(
            `university_type_id`,
            formCurrentDatas.university_type_id?.value
          );
        }
        finalData.append(`skills`, JSON.stringify(formCurrentDatas.skills));

        finalData.append(`contact_person`, formCurrentDatas.contact_person);
        finalData.append(`start_date`, formCurrentDatas.start_date);
        finalData.append(`end_date`, formCurrentDatas.end_date);
        finalData.append(
          `contact_person_email`,
          formCurrentDatas.contact_person_email
        );
        finalData.append(`company_id`, formCurrentDatas.company_id);

        let result;
        if (formCurrentDatas?.id) {
          result = await postData(
            `/jobseeker/signup/experiance-job/${formCurrentDatas?.id}`,
            finalData
          );
        } else {
          result = await postData(
            "/jobseeker/signup/experiance-job",
            finalData
          );
        }

        if (result?.success) {
          await setFormData({
            org_type: currentStatus ? true : false,
            step: 9,
            media: undefined,
            job_role_id: "",
            contact_person: "",
            start_date: "",
            end_date: "",
            job_nature_id: [],
            location_id: "",
            skills: [],
            company_id: "",
            contact_person_email: "",
            responsibilities: "",
            achievement_id: "",

            skills_value: [],
          });

          await setSelectedJobNature([]);
          await setErrors({});
          await getJobExperience();
          handleClose();
        }
      } catch (error) {
        // console.log(error);
      }
    }
  };

  const handleOrganization = async () => {
    setFormData({
      ...formCurrentDatas,
      org_type: !formCurrentDatas?.org_type,
    });
  };

  const [value, setValue] = useState("");
  const [otherCompany, setOtherCompany] = useState(false);
  const [otherDegree, setOtherDegree] = useState(false);
  const [suggestionsList, setSuggestionsList] = useState([]);

  const getSuggestions = async (inputValue, list) => {
    const inputValueLowerCase = inputValue.trim().toLowerCase();

    if (list === "company_id") {
      const res = await getData(
        `/without-login/master/all-company?name=${inputValueLowerCase}`
      );
      if (res?.success) {
        if (res.data.length === 0) {
          setOtherCompany(true);
        } else {
          setOtherCompany(false);
        }

        return res.data?.filter((suggestion) =>
          suggestion?.name.toLowerCase().includes(inputValueLowerCase)
        );
      }
    }
  };

  const onSuggestionsFetchRequested = async ({ value }, list) => {
    const suggestions = await getSuggestions(value, list);
    setSuggestionsList(suggestions);
  };

  const onSuggestionsClearRequested = () => {
    setSuggestionsList([]);
  };

  const onChange = (event, { newValue }) => {
    if (event.target.id === "company_id") {
      setFormData({ ...formCurrentDatas, [event.target.id]: newValue });
    }
  };

  const getSuggestionValue = (suggestion) => suggestion?.name;

  const renderSuggestion = (suggestion, id) => (
    <div
      id={id}
      style={{
        border: "1px solid #ccc",
        padding: "10px",
        borderRadius: "5px",
        margin: "5px",
        backgroundColor: "#f9f9f9",
      }}
    >
      {suggestion?.name}
    </div>
  );

  const inputProps = {
    onChange,
    style: {
      width: "100%", // Adjust the width as needed
      padding: "8px",
      borderRadius: "5px",
      border: "1px solid #ccc",
      // Add other styles as needed
    },
  };

  useEffect(() => {
    fetchSeekerData();
    getMasters();
    // seekerData();
  }, []);

  useEffect(() => {
    setFormData({
      ...formCurrentDatas,
      id: jobSeekerData?.id,
    });
    getJobExperience();
  }, [jobSeekerData?.id]);

  const [activeTab, setActiveTab] = useState("first");

  const handleTabSelect = (selectedTab) => {
    setActiveTab(selectedTab);
  };

  return (
    <>
      <section className="portfolio-experience">
        <div className="container">
          <div className="black-header">
            <div className="row">
              <div className="col-md-4 col-4">
                <Link to="/dashboard-job">
                  <div className="text-holder pt-2 ms-3">
                    <p>Back</p>
                  </div>
                </Link>
              </div>
              <div className="col-md-4 col-4">
                <div className="text-holder text-center pt-2">
                  <h5>Job Experiences</h5>
                </div>
              </div>
              <div className="col-md-4 col-4 text-end ">
                <div className="text-holder pt-2 me-3">
                  <Link onClick={() => handleShow("ADD")}>
                    <p>Add</p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="main-design-tab p-3">
            <Row className="">
              {jobData?.map((value) => (
                <Col className="col-md-5 col-lg-3 col-xl-3 col-xxl-3 col-12 mt-2 mb-3">
                  <div className="msc-box">
                    <div className="tabs-btn p-2">
                      <div className="d-flex justify-content-end">
                        <p
                          className="me-3 ps-2 px-2"
                          style={{
                            backgroundColor:
                              value?.org_type === 1 ? "#00ff68" : "#1967d3",
                            color: value?.org_type === 1 ? "#000" : "#fff",
                            borderRadius: "10px 10px 10px 10px",
                          }}
                        >
                          {value?.org_type === 1
                            ? "Current Organization"
                            : "Previous Organization"}
                        </p>
                        <FontAwesomeIcon
                          icon="fa-solid fa-pen-to-square"
                          className="me-3"
                          style={{ cursor: "pointer" }}
                          onClick={() => getEditData(value?.id)}
                        />
                        <p>
                          <FontAwesomeIcon
                            icon={faXmark}
                            className="close-icon"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleJobDelete(value?.id)}
                          />
                        </p>
                      </div>

                      <div className="info mt-3">
                        <p className="details-field">
                          Company : {value?.company?.name}
                        </p>
                        <p className="details-field">
                          Role: {value?.role?.name}
                        </p>
                        <p className="details-field">
                          <span> Start Date:</span>{" "}
                          {value?.start_date
                            ? new Date(value?.start_date).toLocaleDateString(
                                "en-US",
                                {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                }
                              )
                            : "N/A"}
                        </p>
                        <p>
                          <span>End Date:</span>{" "}
                          {value?.end_date
                            ? new Date(value?.end_date).toLocaleDateString(
                                "en-US",
                                {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                }
                              )
                            : "N/A"}
                        </p>

                        <p>
                          <span>Achievement :</span>
                          {value?.achievement?.name}
                        </p>
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Modal
              size="lg"
              show={lgShow}
              onHide={handleClose}
              className="experience-modal"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Add Job Experience</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Nav variant="pills" className="mb-3">
                  {currentStatus && (
                    <Nav.Item>
                      <Nav.Link
                        style={{
                          backgroundColor: formCurrentDatas?.org_type
                            ? "#0d6efd"
                            : "grey",
                          borderRadius: "20px 20px 20px 20px",
                          color: "white",
                        }}
                        className={formCurrentDatas?.org_type ? "active" : ""}
                        onClick={handleOrganization}
                      >
                        Current Organization
                      </Nav.Link>
                    </Nav.Item>
                  )}
                  <Nav.Item className="ms-3">
                    <Nav.Link
                      style={{
                        backgroundColor: formCurrentDatas?.org_type
                          ? "grey"
                          : "#0d6efd",
                        borderRadius: "20px 20px 20px 20px",
                        color: "white",
                      }}
                      className={formCurrentDatas?.org_type ? "" : "active"}
                      onClick={currentStatus ? handleOrganization : undefined}
                    >
                      Previous Organization
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                <Form>
                  <Row>
                    <div className="col-md-6 col-sm-12 mb-md-3 mb-0">
                      <Form.Label>
                        Job Role <span className="text-danger">*</span>
                      </Form.Label>

                      <Select
                        name="job_role_id"
                        placeholder="Select Job Role"
                        value={formCurrentDatas?.job_role_id}
                        onChange={handleCurrentSelectChange}
                        options={roles}
                        isSearchable={true}
                      />
                      {errors?.job_role_id && (
                        <span style={errorStyle}>{errors?.job_role_id}</span>
                      )}
                    </div>

                    <div className="col-md-6 col-sm-12 mb-md-3 mb-0">
                      <Form.Label>Responsibilities</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Responsibilities"
                        value={formCurrentDatas?.responsibilities}
                        name="responsibilities"
                        onChange={handleCurrentInputChange}
                      />
                    </div>
                  </Row>
                  <Row>
                    <div className="col-md-6 col-sm-12 mb-md-3 mb-0">
                      <Form.Label>
                        Skills Used <span className="text-danger">*</span>
                      </Form.Label>
                      <Select
                        placeholder="Select Skills"
                        className="select-line"
                        isMulti
                        value={formCurrentDatas?.skills_value}
                        onChange={(selectedOption) =>
                          handleCurrentSelect2Change(selectedOption, "skills")
                        }
                        options={skills}
                      />
                      {errors?.skills && (
                        <span style={errorStyle}>{errors?.skills}</span>
                      )}
                    </div>

                    <div className="col-md-6 col-sm-12 mb-md-3 mb-0">
                      <Form.Label>
                        Company Name <span className="text-danger">*</span>
                      </Form.Label>

                      <Autosuggest
                        id="company_id"
                        suggestions={suggestionsList}
                        onSuggestionsFetchRequested={(event) =>
                          onSuggestionsFetchRequested(event, "company_id")
                        }
                        onSuggestionsClearRequested={
                          onSuggestionsClearRequested
                        }
                        getSuggestionValue={getSuggestionValue}
                        renderSuggestion={(suggestion) =>
                          renderSuggestion(suggestion, "company_id")
                        }
                        inputProps={{
                          ...inputProps,
                          id: "company_id",
                          value: formCurrentDatas?.company_id,
                          placeholder: "Enter Company name",
                        }}
                      />
                      {errors?.company_id && (
                        <span style={errorStyle}>{errors?.company_id}</span>
                      )}
                    </div>
                    <div className="col-md-6 col-sm-12 mb-md-3 mb-0">
                      <Form.Label>Contact Person</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Contact Person"
                        value={formCurrentDatas?.contact_person}
                        name="contact_person"
                        onChange={handleCurrentInputChange}
                      />
                    </div>
                    <div className="col-md-6 col-sm-12 mb-md-3 mb-0">
                      <Form.Label>Contact Person Email</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter Contact Person Email"
                        value={formCurrentDatas?.contact_person_email}
                        name="contact_person_email"
                        onChange={handleCurrentInputChange}
                      />
                    </div>
                    <div className="col-md-6 col-sm-6 mb-md-3 mb-0">
                      <Row>
                        <Col>
                          <Form.Label>
                            {" "}
                            Time Period
                            <sup className="bracket-font px-md-1 px-5">
                              (from)
                            </sup>
                            <span className="text-danger">*</span>
                          </Form.Label>

                          <Form.Control
                            type="date"
                            placeholder="Start Date"
                            name="start_date"
                            value={formCurrentDatas?.start_date}
                            onChange={handleChange}
                          />
                          {errors?.start_date && (
                            <span style={errorStyle}>{errors?.start_date}</span>
                          )}
                        </Col>
                        <Col>
                          <Form.Label>
                            {" "}
                            Time Period
                            <sup className="bracket-font px-md-1 px-5">
                              (to)
                            </sup>
                            <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            type="date"
                            placeholder="End Date"
                            name="end_date"
                            value={formCurrentDatas?.end_date}
                            onChange={handleChange}
                          />
                          {errors?.end_date && (
                            <span style={errorStyle}>{errors?.end_date}</span>
                          )}
                        </Col>
                      </Row>
                    </div>
                    <div className="col-md-6 col-sm-12 mb-md-3 mb-0">
                      <Form.Label>Job Nature</Form.Label>
                      {/* <Select
                          name="job_nature_id"
                          placeholder="Select Job Nature"
                          value={formCurrentDatas?.job_nature_id}
                          onChange={handleCurrentSelectChange}
                          options={jobNature}
                          isSearchable={true}
                        /> */}
                      <Select
                        isMulti
                        name="job_nature_id"
                        placeholder="Select Job Nature"
                        value={seletedJobNature}
                        onChange={(selectedOption) =>
                          setSelectedJobNature(selectedOption)
                        }
                        options={jobNature}
                        isSearchable={true}
                      />
                    </div>
                    <div className="col-md-6 col-sm-12 mb-md-3 mb-0">
                      <Form.Label>Job Location</Form.Label>
                      <Select
                        name="location_id"
                        placeholder="Select Job location"
                        value={formCurrentDatas?.location_id}
                        onChange={handleCurrentSelectChange}
                        options={jobLocation}
                        isSearchable={true}
                      />
                    </div>
                    <div className="col-md-6 col-sm-12 mb-md-3 mb-0">
                      <Form.Label>Achievements</Form.Label>
                      <Select
                        name="achievement_id"
                        placeholder="Select Job location"
                        value={formCurrentDatas?.achievement_id}
                        onChange={handleCurrentSelectChange}
                        options={achievements}
                        isSearchable={true}
                      />
                    </div>
                    <div className="col-md-6 col-sm-12 mb-md-3 mb-0">
                      <div>
                        <p className="upload-text m-0">
                          Upload Media <span className="text-danger">*</span>
                        </p>
                        <input
                          className="uplode-file"
                          type="file"
                          name="media"
                          accept="application/pdf"
                          onChange={async (e) => {
                            await setFormData({
                              ...formCurrentDatas,
                              [e.target.name]: e.target.files[0],
                            });
                          }}
                          id="currentMedia"
                        />

                        {/* <button
                            type="button"
                            className=" upload-experience"
                            onClick={() =>
                              handleUploadButtonClick("currentMedia")
                            }
                          >
                            Upload{" "}
                            <FontAwesomeIcon icon={faUpload} className="px-3" />
                          </button> */}
                        <span>{currentMediaPreview}</span>
                        <p className="bracket-font mb-0">
                          ( Max file size 1 MB )
                        </p>
                        {errors?.media && (
                          <span style={errorStyle}>{errors?.media}</span>
                        )}
                      </div>
                    </div>
                  </Row>
                  {/* <div className="text-end">
                    <Button className="education-btn" onClick={handleJobSave}>
                      Save
                    </Button>
                  </div> */}
                </Form>
              </Modal.Body>
              <Modal.Footer className="mx-auto">
                <Button
                  variant="secondary"
                  className="blue-btn-class"
                  onClick={handleClose}
                >
                  Close
                </Button>
                <Button
                  variant="primary"
                  className="blue-btn-class"
                  onClick={handleJobSave}
                >
                  {ftype ? "Add Job" : "Edit Job"}
                </Button>
              </Modal.Footer>
            </Modal>
          </Tab.Container>
        </div>
      </section>
    </>
  );
};

export default PortfolioViewDetail;
