import React, { useEffect, useState, useRef } from "react";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import Select from "react-select";
import Tab from "react-bootstrap/Tab";

import { useContext } from "react";
import { Context } from "../../../../utils/context";
function Freelancing({ getCardData, status }) {
  const educationTabRef = useRef(null);

  const {
    getData,
    postData,
    recruiterData,
    jobSeekerData,
    editStatusData,
    getJobSeekerData,
    Select2Data,
    deleteData,
    Select2Skills,
  } = useContext(Context);

  const [skills, setSkills] = useState([]);
  const [achievemente, setAchievemente] = useState([]);
  const [certificateFor, setCertificateFor] = useState([]);
  const [selectedskills, setSelectedSkills] = useState([]);
  const [errors, setErrors] = useState();

  const [buttonStatus, setButtonStatus] = useState(false);
  const [cards, setCards] = useState([]);

  const [formData, setFormData] = useState({
    id: jobSeekerData?.id,
    step: 9,
    media: undefined,
    client_name: "",
    project_title: "",
    description: "",
    start_date: "",
    end_date: "",
    your_role: "",
    project_outcome: "",
    project_link: "",
  });

  const validateForm = () => {
    let errors = {};
    const regexAlfaNumric = /^[A-Za-z0-9]+(\s[A-Za-z0-9]+)*$/;
    const regexAlfa = /^[A-Za-z]+(\s[A-Za-z]+)*$/;

    if (!formData.project_title) {
      errors.project_title = "project title is required";
    } else if (!regexAlfaNumric.test(formData?.project_title?.trim())) {
      errors.project_title = "Enter a valid data";
    }

    // if (!formData.description) {
    //   errors.description = "description is required";
    // } else if (!regexAlfaNumric.test(formData?.description?.trim())) {
    //   errors.description = "Enter a valid data";
    // }
    if (!formData.your_role) {
      errors.your_role = "your role is required";
    } else if (!regexAlfaNumric.test(formData.your_role?.trim())) {
      errors.your_role = "Enter a valid data";
    }
    // if (!formData.project_outcome) {
    //   errors.project_outcome = "project outcome is required";
    // } else if (!regexAlfaNumric.test(formData.project_outcome?.trim())) {
    //   errors.project_outcome = "Enter a valid data";
    // }
    // if (!formData.project_link) {
    //   errors.project_link = "project link is required";
    // } else if (!regexAlfaNumric.test(formData.project_link?.trim())) {
    //   errors.project_link = "Enter a valid data";
    // }
    if (!formData.client_name) {
      errors.client_name = "client name is required";
    } else if (!regexAlfaNumric.test(formData.client_name?.trim())) {
      errors.client_name = "Enter a valid data";
    }

    // if (formData?.media === undefined) {
    //   errors.media = "media is required";
    // }

    if (!formData.start_date) {
      errors.start_date = "Start Date is required";
    }

    if (!formData.end_date) {
      errors.end_date = "End Date is required";
    } else if (new Date(formData.end_date) < new Date(formData.start_date)) {
      errors.end_date = "End Date cannot be a past date of Start Date";
    } else if (new Date(formData.end_date) > new Date()) {
      errors.end_date = "End Date cannot be a future date";
    }

    return errors;
  };

  const handleChange = (e) => {
    if (e.target.name === "client_name") {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
    if (e.target.name === "project_title") {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
    if (e.target.name === "description") {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
    if (e.target.name === "your_role") {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
    if (e.target.name === "project_outcome") {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
    if (e.target.name === "project_link") {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const getAllData = async () => {
    {
      const res = await getData("/without-login/master/all-achievemente");
      setAchievemente(await Select2Data(res.data, "achievement_id"));
    }
    {
      const res = await getData("/without-login/master/all-skills");
      setSkills(await Select2Skills(res.data, "skills"));
    }
    {
      const res = await getData("/without-login/master/all-certificate-for");
      setCertificateFor(await Select2Data(res.data, "certificate_for"));
    }
  };

  const getAllCards = async () => {
    if (jobSeekerData?.id) {
      const res = await getData(`/jobseeker/signup/experiance-freelancer`);
      await setCards(res?.data);
    }
  };

  useEffect(() => {
    getAllData();
    getAllCards();
  }, []);

  useEffect(() => {
    setFormData({
      ...formData,
      ["skills"]: selectedskills?.map((item) => item.value),
    });
  }, [selectedskills]);

  const handleSubmit = async (e) => {
    await setButtonStatus(true);
    e.preventDefault();

    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      await setButtonStatus(false);
    } else {
      try {
        const finalData = new FormData();
        finalData.append(`media`, formData.media);
        finalData.append(`id`, formData.id);
        finalData.append(`step`, formData.step);
        finalData.append(`client_name`, formData.client_name);
        finalData.append(`project_title`, formData.project_title);
        finalData.append(`description`, formData.description);
        finalData.append(`start_date`, formData.start_date);
        finalData.append(`end_date`, formData.end_date);
        finalData.append(`your_role`, formData.your_role);
        finalData.append(`project_outcome`, formData.project_outcome);
        finalData.append(`status`, status);
        finalData.append(`project_link`, JSON.stringify(formData.project_link));

        await postData("/jobseeker/signup/experiance-freelancer", finalData);

        await setFormData({
          id: jobSeekerData?.id,
          step: 9,
          media: undefined,
          client_name: "",
          project_title: "",
          description: "",
          start_date: "",
          end_date: "",
          your_role: "",
          project_outcome: "",
          project_link: "",
        });

        await getCardData();

        await setErrors();
        await getAllCards();
        await setButtonStatus(false);
      } catch (error) {
        // console.log(error);
        await setButtonStatus(false);
      }
    }
  };

  const handleDelete = async (id) => {
    const data = {
      step: 9,
      jobseeker_id: jobSeekerData?.id,
    };
    if (cards.length === 1) {
      data.step = 9;
    }
    const res = await editStatusData(
      `/jobseeker/signup/experiance-freelancer/${id}`,
      data
    );

    await getAllCards();
  };

  const errorStyle = {
    color: "red",
    marginLeft: "5px",
    // fontSize: "11px",
  };
  return (
    <>
      <section className="eightstep  step-seven text-start StepForm">
        <div className="container">
          <p className="title">Add Freelancing Experience</p>
          <div className="desktop-view-navitem">
            <Tab.Container defaultActiveKey="home">
              <Row>
                <Col md={12}>
                  <Nav variant="pills">
                    {/* {qualifications?.map((value) => (
                      <Nav.Item>
                        <Nav.Link
                          className={
                            education_type_id == value?.id ? "active" : ""
                          }
                          onClick={() => typeChange(value?.id)}
                        >
                          {value?.name}
                        </Nav.Link>
                      </Nav.Item>
                    ))} */}
                  </Nav>
                </Col>

                <Tab.Content>
                  <Tab.Pane eventKey="home"></Tab.Pane>
                  <Tab.Pane eventKey="link-2">Second tab content</Tab.Pane>
                </Tab.Content>
                <Form>
                  <Row>
                    <div className="col-md-6 col-sm-12 mb-3">
                      <Form.Label>
                        Project Title <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Project Title"
                        name="project_title"
                        value={formData?.project_title}
                        onChange={handleChange}
                      />
                      {errors?.project_title && (
                        <span style={errorStyle}>{errors?.project_title}</span>
                      )}
                    </div>

                    <div className="col-md-6 col-sm-12 mb-3">
                      <Form.Label>
                        Client Name <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Client Name"
                        name="client_name"
                        value={formData?.client_name}
                        onChange={handleChange}
                        onKeyDown={(event) => {
                          const allowedCharacters = /[A-Za-z ]/;
                          if (!allowedCharacters.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                      {errors?.client_name && (
                        <span style={errorStyle}>{errors?.client_name}</span>
                      )}
                    </div>

                    <div className="col-md-12 col-sm-12 mb-3">
                      <Row>
                        <Col>
                          <Form.Label>
                            {" "}
                            Time Period
                            <sup className="bracket-font px-md-1 px-5">
                              (from)
                            </sup>{" "}
                            <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            type="date"
                            placeholder="Start Date"
                            name="start_date"
                            value={formData?.start_date}
                            onChange={handleChange}
                          />
                          {errors?.start_date && (
                            <span style={errorStyle}>{errors?.start_date}</span>
                          )}
                        </Col>
                        <Col>
                          <Form.Label>
                            {" "}
                            Time Period
                            <sup className="bracket-font px-md-1 px-5">
                              (to)
                            </sup>{" "}
                            <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            type="date"
                            placeholder="End Date"
                            name="end_date"
                            value={formData?.end_date}
                            onChange={handleChange}
                          />
                          {errors?.end_date && (
                            <span style={errorStyle}>{errors?.end_date}</span>
                          )}
                        </Col>
                      </Row>
                    </div>

                    <div className="col-md-12 col-sm-12 mb-3">
                      <Row>
                        <Form.Label>
                          Your Role <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Your Role"
                          name="your_role"
                          value={formData?.your_role}
                          onChange={handleChange}
                          onKeyDown={(event) => {
                            const allowedCharacters = /[A-Za-z ]/;
                            if (!allowedCharacters.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                        {errors?.your_role && (
                          <span style={errorStyle}>{errors?.your_role}</span>
                        )}
                      </Row>
                    </div>

                    <div className="col-md-6 col-sm-12 mb-3">
                      <Form.Label>
                        Description
                        {/* <span className="text-danger">*</span> */}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Description"
                        name="description"
                        value={formData?.description}
                        onChange={handleChange}
                      />
                      {errors?.description && (
                        <span style={errorStyle}>{errors?.description}</span>
                      )}
                    </div>

                    <div className="col-md-6 col-sm-12 mb-3">
                      <Form.Label>
                        Project Out Come
                        {/* <span className="text-danger">*</span> */}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Project Out Come"
                        name="project_outcome"
                        value={formData?.project_outcome}
                        onChange={handleChange}
                      />
                      {errors?.project_outcome && (
                        <span style={errorStyle}>
                          {errors?.project_outcome}
                        </span>
                      )}
                    </div>

                    <div className="col-md-6 col-sm-12 mb-3">
                      <Form.Label>
                        Project Link
                        {/* <span className="text-danger">*</span> */}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Project Link"
                        name="project_link"
                        value={formData?.project_link}
                        onChange={handleChange}
                      />
                      {errors?.project_link && (
                        <span style={errorStyle}>{errors?.project_link}</span>
                      )}
                    </div>

                    <div className="col-md-6 col-sm-12 mb-3">
                      <Form.Label>
                        Upload Media
                        {/* <span className="text-danger">*</span>{" "} */}
                      </Form.Label>

                      <Form.Control
                        type="file"
                        placeholder="Enter Media"
                        name="media"
                        accept="application/pdf"
                        onChange={async (e) => {
                          await setFormData({
                            ...formData,
                            media: e.target.files[0],
                          });
                        }}
                      />
                      {errors?.media && (
                        <span style={errorStyle}>{errors?.media}</span>
                      )}
                    </div>
                  </Row>

                  <div className="text-end">
                    <Button
                      className="education-btn"
                      disabled={buttonStatus}
                      onClick={handleSubmit}
                    >
                      Add Freelancing
                    </Button>
                  </div>
                </Form>
              </Row>
            </Tab.Container>
          </div>

          {/* Mobile View */}
          <div className="mobile-view-navitem">
            <Tab.Container defaultActiveKey="home" className=" ">
              <Nav variant="pills">
                <Swiper
                  slidesPerView={1}
                  spaceBetween={10}
                  pagination={{
                    clickable: true,
                  }}
                  breakpoints={{
                    0: {
                      slidesPerView: 1,
                    },
                    360: {
                      slidesPerView: 2,
                    },
                    640: {
                      slidesPerView: 2,
                    },
                    767: {
                      slidesPerView: 3,
                    },
                  }}
                  // loop={true}

                  modules={[Pagination]}
                >
                  {/* {qualifications?.map((value) => (
                    <SwiperSlide>
                      <Nav.Item className=" mb-5">
                        <Nav.Link eventKey="home"> {value?.name} </Nav.Link>
                      </Nav.Item>
                    </SwiperSlide>
                  ))} */}
                </Swiper>
              </Nav>
              <Row>
                <Tab.Content>
                  <Tab.Pane eventKey="home">
                    <Form>
                      {cards?.map((value, index) => (
                        <Col
                          className="col-md-5 col-lg-3 col-xl-3 col-xxl-3 col-12 "
                          key={index}
                        >
                          <div className="msc-box">
                            <div className="tabs-btn p-2">
                              <p>
                                {value?.degree?.name}
                                <FontAwesomeIcon
                                  icon={faXmark}
                                  className="close-icon"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => handleDelete(value?.id)}
                                />
                              </p>
                              <div className="info">
                                <p>Title : {value?.title}</p>
                                <p>
                                  Issuing Organization: {value?.issuing_org}
                                </p>
                                <p>Start Date : {value?.start_date}</p>
                                <p>End Date : {value?.end_date}</p>
                                <p>
                                  Certificate For : {value?.certificate_for}
                                </p>
                                <p>Main Topics : {value?.main_topics}</p>
                                <p>Achievemente : {value?.achievement?.name}</p>
                                <p>
                                  Skills :
                                  {value?.jobseeker_certificate_skills?.map(
                                    (skill) => skill?.skill?.name + ", "
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>
                        </Col>
                      ))}
                      <Row>
                        <div className="col-md-6 col-sm-12 mb-3">
                          <Form.Label>
                            Project Title <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Project Title"
                            name="project_title"
                            value={formData?.project_title}
                            onChange={handleChange}
                          />
                          {errors?.project_title && (
                            <span style={errorStyle}>
                              {errors?.project_title}
                            </span>
                          )}
                        </div>

                        <div className="col-md-6 col-sm-12 mb-3">
                          <Form.Label>
                            Client Name <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Client Name"
                            name="client_name"
                            value={formData?.client_name}
                            onChange={handleChange}
                            onKeyDown={(event) => {
                              const allowedCharacters = /[A-Za-z ]/;
                              if (!allowedCharacters.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          />
                          {errors?.client_name && (
                            <span style={errorStyle}>
                              {errors?.client_name}
                            </span>
                          )}
                        </div>

                        <div className="col-md-12 col-sm-12 mb-3">
                          <Row>
                            <Col>
                              <Form.Label>
                                {" "}
                                Time Period
                                <sup className="bracket-font px-md-1 px-5">
                                  (from)
                                </sup>{" "}
                                <span className="text-danger">*</span>
                              </Form.Label>
                              <Form.Control
                                type="date"
                                placeholder="Start Date"
                                name="start_date"
                                value={formData?.start_date}
                                onChange={handleChange}
                              />
                              {errors?.start_date && (
                                <span style={errorStyle}>
                                  {errors?.start_date}
                                </span>
                              )}
                            </Col>
                            <Col>
                              <Form.Label>
                                {" "}
                                Time Period
                                <sup className="bracket-font px-md-1 px-5">
                                  (to)
                                </sup>{" "}
                                <span className="text-danger">*</span>
                              </Form.Label>
                              <Form.Control
                                type="date"
                                placeholder="End Date"
                                name="end_date"
                                value={formData?.end_date}
                                onChange={handleChange}
                              />
                              {errors?.end_date && (
                                <span style={errorStyle}>
                                  {errors?.end_date}
                                </span>
                              )}
                            </Col>
                          </Row>
                        </div>

                        <div className="col-md-12 col-sm-12 mb-3">
                          <Row>
                            <Form.Label>
                              Your Role <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Your Role"
                              name="your_role"
                              value={formData?.your_role}
                              onChange={handleChange}
                              onKeyDown={(event) => {
                                const allowedCharacters = /[A-Za-z ]/;
                                if (!allowedCharacters.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            />
                            {errors?.your_role && (
                              <span style={errorStyle}>
                                {errors?.your_role}
                              </span>
                            )}
                          </Row>
                        </div>

                        <div className="col-md-6 col-sm-12 mb-3">
                          <Form.Label>
                            Description
                            {/* <span className="text-danger">*</span> */}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Description"
                            name="description"
                            value={formData?.description}
                            onChange={handleChange}
                          />
                          {errors?.description && (
                            <span style={errorStyle}>
                              {errors?.description}
                            </span>
                          )}
                        </div>

                        <div className="col-md-6 col-sm-12 mb-3">
                          <Form.Label>
                            Project Out Come
                            {/* <span className="text-danger">*</span> */}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Project Out Come"
                            name="project_outcome"
                            value={formData?.project_outcome}
                            onChange={handleChange}
                          />
                          {errors?.project_outcome && (
                            <span style={errorStyle}>
                              {errors?.project_outcome}
                            </span>
                          )}
                        </div>

                        <div className="col-md-6 col-sm-12 mb-3">
                          <Form.Label>
                            Project Link
                            {/* <span className="text-danger">*</span> */}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Project Link"
                            name="project_link"
                            value={formData?.project_link}
                            onChange={handleChange}
                          />
                          {errors?.project_link && (
                            <span style={errorStyle}>
                              {errors?.project_link}
                            </span>
                          )}
                        </div>

                        <div className="col-md-6 col-sm-12 mb-3">
                          <Form.Label>
                            Upload Media
                            {/* <span className="text-danger">*</span>{" "} */}
                          </Form.Label>

                          <Form.Control
                            type="file"
                            placeholder="Enter Media"
                            name="media"
                            accept="application/pdf"
                            onChange={async (e) => {
                              await setFormData({
                                ...formData,
                                media: e.target.files[0],
                              });
                            }}
                          />
                          {errors?.media && (
                            <span style={errorStyle}>{errors?.media}</span>
                          )}
                        </div>
                      </Row>
                    </Form>
                  </Tab.Pane>
                  <Tab.Pane eventKey="link-2">Second tab content</Tab.Pane>
                </Tab.Content>
              </Row>
              <div className="text-end">
                <Button
                  className="education-btn"
                  disabled={buttonStatus}
                  onClick={handleSubmit}
                >
                  Add Freelancing
                </Button>
              </div>
            </Tab.Container>
          </div>
        </div>
      </section>
    </>
  );
}

export default Freelancing;
