import { useState, useRef, useEffect } from "react";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import InputGroup from "react-bootstrap/InputGroup";
import ContactModal from "../../../RecruiterPage/LoginSignUp/SignUpForm/ContactModal/ContactModal";

import { useContext } from "react";
import { Context } from "../../../../utils/context";
function Step5({ handleNext, handlePrevious }) {
  const { postData, jobSeekerData, setJobSeekerData } = useContext(Context);

  const [otp1, setOpt1] = useState("");
  const [otp2, setOpt2] = useState("");
  const [otp3, setOpt3] = useState("");
  const [otp4, setOpt4] = useState("");
  const [error, seterror] = useState(false);
  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);
  const inputRef3 = useRef(null);
  const inputRef4 = useRef(null);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [phone, setPhone] = useState("");
  const [alternate, setAlternate] = useState("");

  const [mobileVerify, setMobileVerify] = useState(false);

  const [modalShow, setModalShow] = useState(false);
  const [timerShow, setTimerShow] = useState(false);

  const [seconds, setSeconds] = useState(60); // Initial value is 60 seconds
  const [isTimerExpired, setIsTimerExpired] = useState(false);
  const [otpERR, setOtpErr] = useState("");

  const [otpsend, setOtpSend] = useState(null);

  const [mobileErr, setMobileErr] = useState("");
  const [alternateMobileErr, setAlternateMobileErr] = useState("");

  const handleValidation = async (type) => {
    // console.log(jobSeekerData, "jobSeekerData");
    var mobile = phone;

    // Initialize an array to store error messages
    var errors = [];

    if (mobile.trim() === "") {
      setMobileErr("Mobile Number is required");
      errors.push("Mobile Number is required");
    }

    // console.log(mobile + " " + type, "forms");
    if (errors.length === 0) {
      if (type === "next") {
        if (mobileVerify == false) {
          setMobileErr("Verify Your Mobile Number");
          errors.push("Verify Your Mobile Number");
        } else {
          // console.log("next");
          jobSeekerData.contact_no = mobile;
          jobSeekerData.mobileVerify = true;
          jobSeekerData.alternative_no = alternate;
          const res = await postData("/without-login/jobseeker/sign-up", {
            step: 1,
            contact_no: mobile,
            alternative_no: alternate,
          });

          if (res?.success) {
            // console.log(res, "successSignup");
            jobSeekerData.seeker_id = res.data.id;
          } else {
            // console.log(res, "fail");
          }
          handleNext();
        }
      }
      if (type === "otp") {
        // console.log("otp");
        setMobileErr("");
        const randOtp = Math.floor(1000 + Math.random() * 9000).toString();
        // console.log(randOtp, "random");
        const res = await postData("/without-login/verify-otp/mobile-verify", {
          mobile: mobile,
          otp: randOtp,
        });

        if (res?.success) {
          // console.log(res, "mobileotp");
          setOtpSend(randOtp);
          setModalShow(true);
          setTimerShow(true);
          setIsTimerExpired(false);
          setOtpErr("");
          setOtpValues(["", "", "", ""]);

          setSeconds(60);
          setTimeout(() => {
            setModalShow(false);
          }, 4000);
        } else {
          // console.log(res, "fail");
          setMobileErr(res?.message);
        }
      }
    }
  };

  const [otpValues, setOtpValues] = useState(["", "", "", ""]);
  const inputRefs = [useRef(), useRef(), useRef(), useRef()];

  const handleOtpChange = (index, event) => {
    setOtpErr("");
    const inputValue = event.target.value;

    if (/^[0-9]*$/.test(inputValue) || inputValue === "") {
      otpValues[index] = inputValue;
      setOtpValues([...otpValues]);

      if (otpsend === otpValues.join("")) {
        // Call your OTP verification function here
        setMobileVerify(true);
        setTimerShow(false);
        setOtpValues(["", "", "", ""]);
        setOtpSend(null);
      }

      if (inputValue !== "" && index < 3) {
        inputRefs[index + 1].current.focus();
      } else {
        setOtpErr("OTP Invalid!");
      }
    }
  };

  const handleKeyDown = (index, event) => {
    setOtpErr("");
    if (event.key === "Backspace" && index > 0 && otpValues[index] === "") {
      otpValues[index - 1] = "";
      setOtpValues([...otpValues]);
      inputRefs[index - 1].current.focus();
    }
  };
  const [executeCount, setExecuteCount] = useState(0);

  const handleTerms = (element) => {
    if (element.target.checked) {
      jobSeekerData.policy = true;
    } else {
      jobSeekerData.policy = false;
    }
  };

  useEffect(() => {
    if (executeCount == 0) {
      if (jobSeekerData?.mobileVerify) {
        setMobileVerify(true);
      }
      setExecuteCount(1);
    }

    const decrementTimer = () => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else {
        setIsTimerExpired(true);
        setOtpSend(null);
      }
    };

    const timerInterval = setInterval(decrementTimer, 1000);

    return () => {
      clearInterval(timerInterval);
    };
  }, [seconds]);
  return (
    <>
      <fieldset>
        <div className="form-card StepForm mt-5 ">
          <Form>
            <Row>
              <div className="col-md-6 col-sm-12 mb-3">
                <Form.Label>
                  Contact Number <span className="text-danger">*</span>
                </Form.Label>
                <Form.Group className="mb-3" controlId="formGridCity">
                  <div className="d-flex">
                    {/* <DropdownButton
                    variant="outline-secondary"
                    title="+91"
                    id="input-group-dropdown-1"
                    className="contact_dropdown"
                  >

                  </DropdownButton>
                  <Form.Control aria-label="Text input with dropdown button" style={{borderRight: "0px"}} /> */}
                    <span>
                      {" "}
                      <PhoneInput
                        country={"in"} // set the initial country code
                        enableSearch={true}
                        value={phone}
                        defaultValue={
                          jobSeekerData && jobSeekerData?.contact_no
                        }
                        onChange={(phone) => {
                          // Add a space after the country code
                          const formattedPhone = phone.replace(
                            /(\+\d{1,4})(\d{3,14})/,
                            "$1 $2"
                          );
                          setPhone(formattedPhone);
                          setMobileErr("");
                          setMobileVerify(false);
                          setTimerShow(false);
                        }}
                      />
                    </span>
                    {mobileVerify == false && (
                      <span
                        className="input-group-text"
                        id="basic-addon2"
                        onClick={() => {
                          handleValidation("otp");
                        }}
                      >
                        {timerShow == true ? "" : "Get OTP"}{" "}
                        {isTimerExpired == true && timerShow == true
                          ? "Resend OTP"
                          : ""}
                      </span>
                    )}
                  </div>
                  <div>
                    <ContactModal
                      show={modalShow}
                      onHide={() => setModalShow(false)}
                    />
                  </div>
                  <span
                    className="text text-danger"
                    style={{
                      textAlign: "center",
                    }}
                  >
                    {mobileErr && mobileErr}
                  </span>
                </Form.Group>
              </div>

              <div className="col-md-6 col-sm-12 mb-3">
                <Form.Label>
                  {" "}
                  Alternative Contact <span className="text-danger"></span>
                </Form.Label>
                <Form.Control
                  text="text"
                  minlength="10"
                  maxlength="10"
                  placeholder="Enter Your Alternate No"
                  id="alternate"
                  defaultValue={jobSeekerData && jobSeekerData?.alternate}
                  onChange={(event) => {
                    setAlternate(event.target.value);
                    setAlternateMobileErr("");
                  }}
                  onKeyDown={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      if (event.key != "Backspace") event.preventDefault();
                    }
                  }}
                />
              </div>
              <span
                className="text text-danger"
                style={{
                  textAlign: "center",
                }}
              >
                {alternateMobileErr && alternateMobileErr}
              </span>
            </Row>

            {timerShow && (
              <div className="row ">
                <div className="otpContainer">
                  {otpValues.map((value, index) => (
                    <input
                      className="otp"
                      type="text"
                      maxLength="1"
                      value={value}
                      onChange={(e) => handleOtpChange(index, e)}
                      onKeyDown={(e) => handleKeyDown(index, e)}
                      ref={inputRefs[index]}
                    />
                  ))}
                </div>

                <p className="otp">
                  Enter OTP within{" "}
                  {`${Math.floor(seconds / 60)}:${(seconds % 60)
                    .toString()
                    .padStart(2, "0")}`}{" "}
                  seconds
                </p>
                <span
                  className="text text-danger"
                  style={{
                    textAlign: "center",
                  }}
                >
                  {otpERR && otpERR}
                </span>
              </div>
            )}
            {mobileVerify && (
              <div className="row text-center">
                <p className="otp text text-success">Mobile Verified...!</p>
              </div>
            )}

            <div className="mobile-view-button">
              <button
                type="button"
                name="next"
                className="next action-button contin-btn"
                onClick={() => handleValidation("next")}
              >
                Continue
              </button>
            </div>
          </Form>
        </div>
      </fieldset>

      <div className="row mt-5 pt-4 footer-sec">
        <div className="col-md-10 col-12 desktop-view-button">
          {/* <div className="check-row text-start">
            <span>
              <Form.Check inline name="group1" className="me-2" />
            </span>
            <span className=" by-text ">By signing up, I agree to the</span>
            <span className="px-1 ">
              <Link to="/terms" className="term-btn">
                Term and Conditions
              </Link>
            </span>
            <br />
          </div>
          <div className="login-row text-start">
            <span className="">Already an User?</span>
            <span className="px-1 ">
              <Link to="/other-page" className="login-btn">
                log In
              </Link>
            </span>
            &nbsp; &nbsp; &nbsp;
            <span className="">Not a Jobseeker?</span>
            <span className="px-1 ">
              <Link to="/signup-recruiter" className="signup-btn">
                Signup as Recruiter
              </Link>
            </span>
          </div> */}
        </div>
        {/* <div className="col-md-8 col-12 mb-5  mobile-view-button">
          <div className="check-row text-start">
            <span>
              <Form.Check inline name="group1" className="me-2" />
            </span>
            <span className=" by-text ">By signing up, I agree to the</span>
            <span className="px-1 ">
              <Link to="/terms" className="term-btn">
                Term and Conditions
              </Link>
            </span>
            <br />
          </div>
          <div className="login-row text-start">
            <span className="">Already an User?</span>
            <span className="px-1 ">
              <Link to="/other-page" className="login-btn">
                log In
              </Link>
            </span>
            <br />
            <span className="">Not a Jobseeker?</span>
            <span className="px-1 ">
              <Link to="/signup-recruiter" className="signup-btn">
                Signup as Recruiter
              </Link>
            </span>
          </div>
        </div> */}

        <div className="col-2">
          <div className="desktop-view-button">
            <button
              type="button"
              name="next"
              className="next action-button contin-btn"
              onClick={() => handleValidation("next")}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Step5;
