import React from 'react'
import PrivacyBanner from './PrivacyBanner/PrivacyBanner';
import PrivacyPolicy from './PrivacyPolicy/PrivacyPolicy';

const PrivacyPolicyPage = () => {
    return (
        <div>
            <PrivacyBanner />
            <PrivacyPolicy />
        </div>
    )
}

export default PrivacyPolicyPage;