import React from 'react'
import Modal from 'react-bootstrap/Modal';
import Success from '../../../Model/Animation/Success';
// import "./PopupModal.css"

const Reject = (props) => {
  return (
    <>
      <section >
        <Modal
          {...props}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          className='PopupModal'
          centered          
        >          
          <Modal.Body>
            <Success/>
            <p className='modal_txt'> Candidate rejected</p>
            <p className='text-center' style={{color: "#FFFF"}}> Have a Great Time! </p>
          </Modal.Body>         
        </Modal>
      </section>
    </>
  )
}

export default Reject