import React, { useContext, useEffect, useState } from "react";
import "./JobCategory.css";
import Telecommunication from "../JobCategory/Images/telecommunication.jpg";
import Health from "../JobCategory/Images/health.jpg";
import HR from "../JobCategory/Images/hr.jpg";
import Finance1 from "../JobCategory/Images/finanance.jpg";
import Finance2 from "../JobCategory/Images/Teacher.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { Pagination, Autoplay, Navigation } from "swiper/modules";
import PropTypes from "prop-types";
import industryDummy from "./Images/industryDummy.png";

import { Link } from "react-router-dom";
import { Context } from "../../../../utils/context";
const JobCategory = ({ alignment }) => {
  const { getData, imageUrl, NoDataAnimations } = useContext(Context);

  const [data, setData] = useState([]);

  const getAllData = async () => {
    const res = await getData("/without-login/home/popular-job");
    setData(res?.data);
  };

  useEffect(() => {
    getAllData();
  }, []);

  const [swiperInstance, setSwiperInstance] = useState(null);
  const handleMainSliderClick = (direction) => {
    if (swiperInstance) {
      if (direction === "prev") {
        swiperInstance.slidePrev();
      } else if (direction === "next") {
        swiperInstance.slideNext();
      }
    }
  };

  const headingStyle = {
    textAlign: alignment === "left" ? "left" : "center",
  };
  return (
    <>
      <section className="JobCategory">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-lg-12 mx-auto">
              <div className=" jobCategory_heading" style={headingStyle}>
                Popular Job Categories
              </div>
              <div className="row mt-4 mb-5">
                <Swiper
                  onSwiper={(swiper) => setSwiperInstance(swiper)}
                  modules={[Autoplay, Pagination]}
                  breakpoints={{
                    0: {
                      slidesPerView: 1,
                    },
                    360: {
                      slidesPerView: 1,
                    },
                    640: {
                      slidesPerView: 2,
                    },
                    768: {
                      slidesPerView: 3,
                    },
                    1024: {
                      slidesPerView: 4,
                    },
                    1200: {
                      slidesPerView: 4,
                    },
                    1400: {
                      slidesPerView: 4,
                    },
                  }}
                  pagination={{
                    clickable: true,
                  }}
                  // navigation={{
                  //   clickable: true,
                  // }}
                >
                  {data?.map((slide, index) => (
                    <SwiperSlide key={index}>
                      <div className="card_left-sec card  ">
                        {slide?.image ? (
                          <img
                            src={imageUrl + slide?.image}
                            className="card-img-top mx-auto"
                            alt={slide?.name}
                          />
                        ) : (
                          <img
                            src={industryDummy}
                            className="card-img-top mx-auto"
                            alt={slide?.name}
                          />
                        )}
                        <div className="card-body text-center">
                          <p className="jobcategorytxt card-text mb-0 mt-2">
                            {slide?.name}
                          </p>
                          <Link to={`/jobexp/${slide?.name}`}>
                            <button className="btn btn-2 hover-slide-up p-0">
                              <span className="explore_txt">Explore</span>
                            </button>
                          </Link>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
                {data?.length === 0 && <NoDataAnimations />}
                {data?.length !== 0 && (
                  <>
                    <div
                      className="main-slider"
                      onClick={() => handleMainSliderClick("prev")}
                    >
                      <div className="arrow-back" />
                    </div>
                    <div
                      className="main-slider"
                      onClick={() => handleMainSliderClick("next")}
                    >
                      <div className="arrow-next" />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

JobCategory.propTypes = {
  alignment: PropTypes.oneOf(["left", "center"]).isRequired,
};

export default JobCategory;
