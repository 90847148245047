import React, { useEffect, useState } from "react";
import "./AboutHiclousia.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import { useContext } from "react";
import { Context } from "../../../../utils/context";
const AboutHiclousia = () => {
  const { getData, imageUrl } = useContext(Context);

  const [ourPartner, setOurPartner] = useState();

  const getBlogs = async () => {
    const res = await getData("/without-login/master/all-our-about");
    if (res?.success) {
      setOurPartner(res.data);
    }
  };

  var HtmlToReactParser = require("html-to-react").Parser;
  var htmlToReactParser = new HtmlToReactParser();
  // { htmlToReactParser.parse(jobData?.data?.description) }

  useEffect(() => {
    getBlogs();
  }, []);
  return (
    <section className="about-hiclousia-section">
      <div className="container">
        {ourPartner?.map((value, index) => (
          <div className="row  mt-3 mt-md-5" key={index}>
            <div className="col-md-7 col-sm-12 col-12">
              <div className="text-col">
                <h3>{value?.name}</h3>
                <p>{htmlToReactParser.parse(value?.description)}</p>
                <div className="custum-btn">
                  {/* <Button variant="" className="read-btn">
                    Read More
                  </Button> */}
                </div>
              </div>
            </div>
            <div className="col-5">
              <div className="img-col">
                <img
                  src={imageUrl + value?.image}
                  alt="banner"
                  className="descriptn-img"
                />
              </div>
            </div>
          </div>
        ))}

        {/* <div className="row mt-3 mt-md-5">
          <div className="col-md-7 col-sm-12 col-12">
            <div className="text-col">
              <h3>Our Leadership</h3>
              <p>
                Hiclousia’s leadership team is diverse, dedicated and
                committed to empowering our employees to fulfill our mission:
                We help people get jobs. By fostering strong partnerships and
                collaboration, they serve and support job seekers, employers,
                society & our employees.
              </p>
              <div className="custum-btn">
                <Button variant="" className="read-btn">
                  Read More
                </Button>
              </div>
            </div>
          </div>
          <div className="col-5">
            <div className="img-col">
              <img src={process.env.PUBLIC_URL + "assets/Images/banner/aboutus/OurLeadership.png"} alt="banner" className="descriptn-img" />
            </div>
          </div>
        </div> */}
        {/* <div className="row mt-3 mt-md-5">
          <div className="col-md-7 col-sm-12 col-12">
            <div className="text-col">
              <h3>Our Commitments</h3>
              <p>
                Our Environmental, Social & Governance (ESG) commitments aim
                to bring about a future of work that is equitable and
                inclusive. As the world’s number one job site and leading
                matching & hiring platform, we strive to make a positive
                impact on society by connecting people to better work to
                create better lives.
              </p>
              <div className="custum-btn">
                <Button variant="" className="read-btn">
                  Read More
                </Button>
              </div>
            </div>
          </div>
          <div className="col-5">
            <div className="img-col">
              <img src={process.env.PUBLIC_URL + "assets/Images/banner/aboutus/OurCommitment.png"} alt="banner" className="descriptn-img" />
            </div>
          </div>
        </div> */}
      </div>
    </section>
  );
};

export default AboutHiclousia;
