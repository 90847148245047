import React, { useEffect, useState } from "react";
import "./JobExp.css";
import PopupModal from "../Home/Modal/PopupModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useParams } from "react-router-dom";
import "swiper/swiper-bundle.css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import Select from "react-select";
import Autosuggest from "react-autosuggest";
import JobDescriptionModal from "../../JobseekerPage/JobDescriptionModel/JobDescriptionModal";

import { useContext } from "react";
import { Context } from "../../../utils/context";
import { postData } from "../../../utils/api";
const JobExp = () => {
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [rangeValue, setRangeValue] = useState(999999999999999);
  const [sidebarClass, setSidebarClass] = useState("sidebar_height");
  const [show, setShow] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [jdShow, setJDShow] = React.useState(false);
  const [data, setData] = useState();
  const openModal = () => {
    if (signin) {
      if (usertype === "Job Seeker") {
        setJDShow(true);
      } else {
        setModalShow(true);

        setTimeout(() => {
          setModalShow(false);
        }, 3000);
      }
    } else {
      setModalShow(true);

      setTimeout(() => {
        setModalShow(false);
      }, 3000);
    }
  };

  const handleRangeChange = (event) => {
    setRangeValue(event.target.value);
  };

  const [showAll, setShowAll] = useState(false);

  const {
    getData,
    postData,
    imageUrl,
    Select2Data,
    Select2Skills,
    signin,
    usertype,
    citySearch,
    setCitySearch,
    ReactLoader,
    setReactLoader,
    PacmanLoaders,
    NoDataAnimations,
  } = useContext(Context);
  const [jobs, setJobs] = useState();
  const [activeId, setActiveId] = useState();
  const getFeatureJobs = async () => {
    // console.log(formData);
    const finalData = new FormData();
    if (id) {
      finalData.append(`industry_id`, id);
    }
    if (formData.company_name) {
      finalData.append(`company_name`, formData.company_name);
    }
    if (formData.sector_name) {
      finalData.append(`sector_name`, formData.sector_name);
    }
    if (formData.role_name) {
      finalData.append(`role_name`, formData.role_name);
    }
    if (formData.experience_id) {
      finalData.append(`experience_id`, formData.experience_id?.value);
    }
    if (formData.city_id) {
      finalData.append(`city_id`, formData.city_id?.value);
    }
    if (formData.career_level.length != 0) {
      finalData.append(`career_level`, JSON.stringify(formData.career_level));
    }
    if (formData.job_type.length != 0) {
      finalData.append(`job_type`, JSON.stringify(formData.job_type));
    }
    if (formData.job_primary_skills.length != 0) {
      finalData.append(
        "job_primary_skills",
        JSON.stringify(formData.job_primary_skills)
      );
    }

    if (formData.job_secondary_skills.length != 0) {
      finalData.append(
        "job_secondary_skills",
        JSON.stringify(formData.job_secondary_skills)
      );
    }
    finalData.append("salary", rangeValue);

    const res = await postData(`/without-login/featured-jobs`, finalData);

    if (res?.success) {
      setJobs(res.data);
      await setActiveId(res.data[0]?.id);
      setReactLoader(false);
    }
  };

  const handleActive = async (id) => {
    await setActiveId(id);
  };

  const [formData, setFormData] = useState({
    company_name: "",
    sector_name: "",
    role_name: "",
    job_primary_skills: [],
    job_secondary_skills: [],
    job_primary_skills_value: [],
    job_secondary_skills_value: [],
    experience_id: "",
    career_level: "",
    city_id: "",
    job_type: "",
  });
  const [suggestionsList, setSuggestionsList] = useState([]);

  const getSuggestions = async (inputValue, list) => {
    const inputValueLowerCase = inputValue.trim().toLowerCase();

    if (list === "company_name") {
      const res = await getData(
        `/without-login/master/all-company?name=${inputValueLowerCase}`
      );
      if (res?.success) {
        return res.data?.filter((suggestion) =>
          suggestion?.name.toLowerCase().includes(inputValueLowerCase)
        );
      }
    }
    if (list === "sector_name") {
      const res = await getData(
        `/without-login/master/all-sector?name=${inputValueLowerCase}`
      );
      if (res?.success) {
        return res.data?.filter((suggestion) =>
          suggestion?.name.toLowerCase().includes(inputValueLowerCase)
        );
      }
    }
    if (list === "role_name") {
      const res = await getData(
        `/without-login/master/all-roles?name=${inputValueLowerCase}`
      );
      if (res?.success) {
        return res.data?.filter((suggestion) =>
          suggestion?.name.toLowerCase().includes(inputValueLowerCase)
        );
      }
    }
  };

  const onSuggestionsFetchRequested = async ({ value }, list) => {
    const suggestions = await getSuggestions(value, list);
    setSuggestionsList(suggestions);
  };

  const onSuggestionsClearRequested = () => {
    setSuggestionsList([]);
  };

  const onChange = async (event, { newValue }) => {
    if (event.target.id === "company_name") {
      setFormData((prevData) => ({ ...prevData, company_name: newValue }));
    }
    if (event.target.id === "sector_name") {
      setFormData((prevData) => ({ ...prevData, sector_name: newValue }));
    }
    if (event.target.id === "role_name") {
      setFormData((prevData) => ({ ...prevData, role_name: newValue }));
    }
  };

  const getSuggestionValue = (suggestion) => suggestion?.name;

  const renderSuggestion = (suggestion, id) => (
    <div
      id={id}
      style={{
        border: "1px solid #ccc",
        padding: "10px",
        borderRadius: "5px",
        margin: "5px",
        backgroundColor: "#f9f9f9",
      }}
    >
      {suggestion?.name}
    </div>
  );

  const inputProps = {
    onChange,
    style: {
      width: "300px", // Adjust the width as needed
      padding: "8px",
      borderRadius: "5px",
      border: "1px solid #ccc",
      // Add other styles as needed
    },
  };

  const [qualification, setQualification] = useState();
  const [skills, setSkills] = useState();
  const [careerLevels, setCareerLevels] = useState();
  const [experience, setExperience] = useState();
  const [shifts, setShifts] = useState();
  const [cities, setCities] = useState();
  const [maxSalary, setMaxSalary] = useState();
  const getMasters = async () => {
    {
      const res = await getData(`/without-login/master/all-skills`);
      if (res?.success) {
        setSkills(await Select2Skills(res.data, "skill_id"));
      }
    }
    {
      const res = await getData(`/without-login/master/all-career-level`);
      if (res?.success) {
        setCareerLevels(res.data);
      }
    }
    {
      const res = await getData(`/without-login/master/all-experience-in-year`);
      if (res?.success) {
        setExperience(await Select2Data(res.data, "experience_id"));
      }
    }
    {
      const res = await getData(`/without-login/master/all-job-type`);
      if (res?.success) {
        setShifts(res.data);
      }
    }
    {
      const res = await getData(`/without-login/master/all-cities`);
      if (res?.success) {
        setCities(await Select2Data(res.data, "city_id"));
      }
    }
    {
      const res = await getData(`/without-login/master/get-max-salary`);
      if (res?.success) {
        setMaxSalary(res.data);
        setRangeValue(res.data);
      }
    }
  };
  const resetForm = async () => {
    await setFormData({
      company_name: "",
      sector_name: "",
      role_name: "",
      job_primary_skills: [],
      job_secondary_skills: [],
      job_primary_skills_value: [],
      job_secondary_skills_value: [],
      experience_id: "",
      career_level: "",
      city_id: "",
      job_type: "",
    });
    getMasters();
    getFeatureJobs();
  };
  const handleChange = async (e) => {
    // console.log(e?.target?.name);
    if (e?.name === "experience_id" || e?.name === "city_id") {
      setFormData({ ...formData, [e?.name]: e });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };
  const handleCurrentSelect2Change = async (selectedOption, id) => {
    if (id === "job_primary_skills_value") {
      setFormData((prevFormData) => {
        return {
          ...prevFormData,
          [id]: selectedOption,
          job_primary_skills: selectedOption.map((item) => item.value),
        };
      });
    }
    if (id === "job_secondary_skills_value") {
      setFormData((prevFormData) => {
        return {
          ...prevFormData,
          [id]: selectedOption,
          job_secondary_skills: selectedOption.map((item) => item.value),
        };
      });
    }
  };
  const handleCareerLevelChange = (event) => {
    const selectedCareerLevelId = event.target.value;
    const isChecked = event.target.checked;

    setFormData((prevFormData) => {
      if (isChecked) {
        // If the checkbox is checked, add the selected career level to the array
        return {
          ...prevFormData,
          career_level: [...prevFormData.career_level, selectedCareerLevelId],
        };
      } else {
        // If the checkbox is unchecked, remove the selected career level from the array
        return {
          ...prevFormData,
          career_level: prevFormData.career_level.filter(
            (id) => id !== selectedCareerLevelId
          ),
        };
      }
    });
  };

  const handleCheckboxChange = (value) => {
    // Check if the value is already present in job_type
    const updatedJobTypes = formData.job_type.includes(value)
      ? formData.job_type.filter((type) => type !== value) // Remove if already present
      : [...formData.job_type, value]; // Add if not present

    setFormData((prevFormData) => ({
      ...prevFormData,
      job_type: updatedJobTypes,
    }));
  };
  useEffect(() => {
    getFeatureJobs();
    getMasters();
    if (citySearch != "") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        city_id: citySearch,
      }));
    }
  }, []);
  return (
    <>
      <section className="Featured_Category desktop-view-featurecategory">
        <div className="container">
          <div className="heading_featured text-center">Explore Jobs</div>
          <div className="row mt-4">
            <ul
              className="list_tab_featured justify-content-start nav nav-pills mb-3"
              id="pills-tab"
              role="tablist"
            >
              {jobs?.map((value, index) => (
                <li className="nav-item" role="presentation">
                  <button
                    className={
                      activeId === value?.id ? "nav-link active" : "nav-link"
                    }
                    id="pills-Management-tab"
                    type="button"
                    role="tab"
                    aria-selected="true"
                    onClick={() => handleActive(value?.id)}
                  >
                    {value?.name}
                  </button>
                </li>
              ))}
            </ul>
            <div className="row  mt-5 p-0">
              <div className="col-lg-3 col-md-4">
                <div className="sidebar_featured_sec">
                  <h3 className="job_filter_txt">Job Filters</h3>
                  <div className={sidebarClass}>
                    <div className="down_content_sidebar ">
                      <div className="mt-4 CompanyName_section">
                        <p className="companyName_txt">Company Name</p>
                        {/* <input className="CompanyName" type="text" placeholder='Desired Company Name' /> */}
                        <Autosuggest
                          id="company_name"
                          suggestions={suggestionsList}
                          onSuggestionsFetchRequested={(event) =>
                            onSuggestionsFetchRequested(event, "company_name")
                          }
                          onSuggestionsClearRequested={
                            onSuggestionsClearRequested
                          }
                          getSuggestionValue={getSuggestionValue}
                          renderSuggestion={(suggestion) =>
                            renderSuggestion(suggestion, "company_name")
                          }
                          inputProps={{
                            ...inputProps,
                            id: "company_name",
                            value: formData?.company_name,
                            placeholder: "Enter Company Name",
                          }}
                        />
                        <div className="line"></div>
                      </div>

                      {/* <div className="mt-4 CompanyName_section">
                        <p className="companyName_txt">Job Sector</p>
                        <Autosuggest
                          id="sector_name"
                          suggestions={suggestionsList}
                          onSuggestionsFetchRequested={(event) =>
                            onSuggestionsFetchRequested(event, "sector_name")
                          }
                          onSuggestionsClearRequested={
                            onSuggestionsClearRequested
                          }
                          getSuggestionValue={getSuggestionValue}
                          renderSuggestion={(suggestion) =>
                            renderSuggestion(suggestion, "sector_name")
                          }
                          inputProps={{
                            ...inputProps,
                            id: "sector_name",
                            value: formData?.sector_name,
                            placeholder: "Enter Sector Name",
                          }}
                        />
                        <div className="line"></div>
                      </div> */}
                      <div className="CompanyName_section companyName_txt">
                        <p className="mt-4">Role</p>
                        <Autosuggest
                          id="role_name"
                          suggestions={suggestionsList}
                          onSuggestionsFetchRequested={(event) =>
                            onSuggestionsFetchRequested(event, "role_name")
                          }
                          onSuggestionsClearRequested={
                            onSuggestionsClearRequested
                          }
                          getSuggestionValue={getSuggestionValue}
                          renderSuggestion={(suggestion) =>
                            renderSuggestion(suggestion, "role_name")
                          }
                          inputProps={{
                            ...inputProps,
                            id: "role_name",
                            value: formData?.role_name,
                            placeholder: "Enter Role",
                          }}
                        />
                      </div>

                      {/* <div className='mt-4 CompanyName_section'>
                                                <p className='companyName_txt'>Highest Education</p>
                                                <input className="CompanyName" type="text" placeholder='Desired Company Name' />
                                                <div className="line"></div>
                                            </div> */}

                      <div className="mt-4 CompanyName_section">
                        <p className="companyName_txt">Primary Skills</p>
                        <Select
                          name="city_id"
                          placeholder="Select Primary Skills"
                          value={formData?.job_primary_skills_value}
                          onChange={(selectedOption) =>
                            handleCurrentSelect2Change(
                              selectedOption,
                              "job_primary_skills_value"
                            )
                          }
                          isMulti
                          options={skills}
                        />
                        <div className="line"></div>
                      </div>

                      <div className="mt-4 CompanyName_section">
                        <p className="companyName_txt">Secondary Skills</p>
                        <Select
                          name="city_id"
                          placeholder="Select Secondary Skills"
                          value={formData?.job_secondary_skills_value}
                          onChange={(selectedOption) =>
                            handleCurrentSelect2Change(
                              selectedOption,
                              "job_secondary_skills_value"
                            )
                          }
                          isMulti
                          options={skills}
                        />
                        <div className="line"></div>
                      </div>

                      {/* <div className='mt-4 CompanyName_section'>
                                                <p className='companyName_txt'>Professional Projects</p>
                                                <input className="CompanyName" type="text" placeholder='Desired Company Name' />
                                                <div className="line"></div>
                                            </div> */}

                      <div className="mt-4 CompanyName_section">
                        <p className="companyName_txt">
                          Prof. Experience in Years
                        </p>
                        <Select
                          name="experience_id"
                          placeholder="Select Experience"
                          value={formData?.experience_id}
                          onChange={handleChange}
                          options={experience}
                        />
                        <div className="line"></div>
                      </div>
                      <div className="CompanyName_section companyName_txt">
                        <p className="mt-4">Experience</p>
                        {careerLevels?.map((value, index) => (
                          <div key={index} className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value={String(value?.id)} // Ensure value is a string
                              id={`careerLevelCheckbox${value?.id}`}
                              checked={formData.career_level.includes(
                                String(value?.id)
                              )}
                              onChange={handleCareerLevelChange}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`careerLevelCheckbox${value?.id}`}
                            >
                              {value?.name}
                            </label>
                          </div>
                        ))}
                        {/* <div className="mt-2 form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckIndeterminateDisabled" />
                                                    <label className="form-check-label" for="flexCheckIndeterminateDisabled">
                                                        Advance
                                                    </label>
                                                </div>
                                                <div className="mt-2 form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckIndeterminateDisabled" />
                                                    <label className="form-check-label" for="flexCheckIndeterminateDisabled">
                                                        Proficient
                                                    </label>
                                                </div> */}
                      </div>
                      <div className="CompanyName_section companyName_txt">
                        <p className="mt-4">Salary Scale</p>

                        <div className="custom-range-slider">
                          <input
                            type="range"
                            min={0}
                            max={maxSalary}
                            value={rangeValue}
                            onChange={handleRangeChange}
                          />
                          <div
                            className="custom-track"
                            style={{ width: `${rangeValue}%` }}
                          ></div>
                          <p>Value: {rangeValue}</p>
                        </div>
                      </div>
                      <div className="CompanyName_section ">
                        <p className="mt-4 companyName_txt">Location</p>
                        <Select
                          name="city_id"
                          placeholder="Select City"
                          value={formData?.city_id}
                          onChange={handleChange}
                          options={cities}
                        />
                      </div>
                      <div className="CompanyName_section">
                        <p className="mt-4 companyName_txt">Job Type</p>
                        {shifts?.map((value, index) => (
                          <div className="mt-2 form-check" key={index}>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value={value?.id}
                              id={`flexCheckIndeterminateDisabled${index}`}
                              onChange={() => handleCheckboxChange(value?.id)}
                              checked={formData.job_type.includes(value?.id)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`flexCheckIndeterminateDisabled${index}`}
                            >
                              {value?.name}
                            </label>
                          </div>
                        ))}
                      </div>
                      <div className=" text-center mt-4">
                        <button
                          className="btn btn-primary apply-btn-feature"
                          onClick={getFeatureJobs}
                        >
                          Apply
                        </button>
                        <button
                          className="ms-3 btn apply-btn-feature"
                          onClick={resetForm}
                        >
                          Reset
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-9 col-md-8">
                <div
                  className="tab-content featuredJobs_rightSide"
                  id="pills-tabContent"
                >
                  {ReactLoader ? (
                    <div className="container" style={{ paddingLeft: "50%" }}>
                      <PacmanLoaders color="#36d7b7" />
                    </div>
                  ) : (
                    <>
                      {jobs?.map((value, index) => (
                        <div
                          className={
                            activeId === value?.id
                              ? "tab-pane fade show active"
                              : "tab-pane fade"
                          }
                          id={`#pill${value?.id}`}
                          role="tabpanel"
                          aria-labelledby="pills-Management-tab"
                          tabIndex="0"
                        >
                          <div className="row">
                            {value?.jobs
                              ?.slice(0, showAll ? value.jobs.length : 9)
                              .map((job, index) => (
                                <div
                                  className="col-lg-4 col-md-6 mb-md-3 mb-3"
                                  key={index}
                                >
                                  <div className="card card_left-sec">
                                    <div className="card-body pb-0">
                                      <div className="card-title ">
                                        <img
                                          src={
                                            imageUrl + job?.job_contact?.logo
                                          }
                                          className="Tata"
                                        />
                                      </div>
                                      <div className="mt-3">
                                        {job?.job_create_shifts?.map(
                                          (type, i) => (
                                            <span
                                              className="fullTime_txt"
                                              key={i}
                                            >
                                              {type?.shift?.name}
                                            </span>
                                          )
                                        )}
                                      </div>
                                      <p className="card-text card_info">
                                        {job?.role?.name}
                                      </p>
                                      <p className="city_txt mb-0">
                                        <FontAwesomeIcon
                                          icon="fa-solid fa-location-dot"
                                          className="faff-locccc"
                                        />
                                        {job?.job_work_locations
                                          ?.slice(0, 2)
                                          .map((type, i) => type?.city?.name)
                                          .join(", ")}
                                      </p>
                                      <div className="explre_btn_sec">
                                        <button
                                          className="btn btn-2 hover-slide-up"
                                          onClick={() => {
                                            setData(job);
                                            openModal();
                                          }}
                                        >
                                          <span>Explore</span>
                                        </button>
                                        <PopupModal
                                          show={modalShow}
                                          onHide={() => setModalShow(false)}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            {value?.jobs?.length === 0 && <NoDataAnimations />}
                          </div>
                          {value?.jobs.length > 9 && (
                            <div className="showMore_btn_section justify-content-center text-center">
                              <button
                                className="show-more_featured"
                                onClick={() => setShowAll(!showAll)}
                              >
                                {showAll ? "Show Less" : "Show More"}{" "}
                                <FontAwesomeIcon icon="fa-solid fa-chevron-down" />
                              </button>
                            </div>
                          )}
                        </div>
                      ))}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <JobDescriptionModal
        show={jdShow}
        data={data}
        title={"Job Description"}
        onHide={() => setJDShow(false)}
      />

      <section className="Featured_Category mobile-view-featurecategory">
        <div className="container">
          <div className="heading_featured ">Featured Jobs</div>
          <div className="row mt-4">
            <ul
              className="list_tab_featured justify-content-start nav nav-pills mb-3"
              id="pills-tab"
              role="tablist"
            >
              <Swiper
                modules={[Navigation, Autoplay]}
                pagination={false}
                navigation={false}
                // loop={true}
                breakpoints={{
                  0: {
                    slidesPerView: 3,
                    // spaceBetween: 10,
                  },
                  360: {
                    slidesPerView: 3,
                    // spaceBetween: 10,
                  },
                  567: {
                    slidesPerView: 4,
                    // spaceBetween: 10,
                  },
                  640: {
                    slidesPerView: 4,
                    // spaceBetween: 10,
                  },
                  768: {
                    slidesPerView: 4,
                    // spaceBetween: 10,
                  },
                }}
                className="suportres-slider"
              >
                {jobs?.map((value, index) => (
                  <SwiperSlide className="swiper-slide1">
                    <li className="nav-item" role="presentation">
                      <button
                        className={
                          activeId === value?.id
                            ? "nav-link active"
                            : "nav-link"
                        }
                        id="pills-Management-tab-mobile"
                        type="button"
                        role="tab"
                        aria-selected="true"
                        onClick={() => handleActive(value?.id)}
                      >
                        {value?.name}
                      </button>
                    </li>
                  </SwiperSlide>
                ))}

                {/* <SwiperSlide className="swiper-slide1">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="pills-Development-tab-mobile"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-Development-mobile"
                      type="button"
                      role="tab"
                      aria-controls="pills-Development-mobile"
                      aria-selected="false"
                    >
                      Development
                    </button>
                  </li>
                </SwiperSlide>

                <SwiperSlide className="swiper-slide1">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="pills-Health-tab-mobile"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-Health-mobile"
                      type="button"
                      role="tab"
                      aria-controls="pills-Health-mobile"
                      aria-selected="false"
                    >
                      Health Care
                    </button>
                  </li>
                </SwiperSlide>

                <SwiperSlide className="swiper-slide1">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="pills-Education-tab-mobile"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-Education-mobile"
                      type="button"
                      role="tab"
                      aria-controls="pills-Education-mobile"
                      aria-selected="false"
                    >
                      Education
                    </button>
                  </li>
                </SwiperSlide>

                <SwiperSlide className="swiper-slide1">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="pills-Finance-tab-mobile"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-Finance-mobile"
                      type="button"
                      role="tab"
                      aria-controls="pills-Finance-mobile"
                      aria-selected="false"
                    >
                      Finance
                    </button>
                  </li>
                </SwiperSlide>

                <SwiperSlide className="swiper-slide1">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link Telecommunication_tab"
                      id="pills-Telecommunication-tab-mobile"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-Telecommunication-mobile"
                      type="button"
                      role="tab"
                      aria-controls="pills-Telecommunication-mobile"
                      aria-selected="false"
                    >
                      Telecommunication
                    </button>
                  </li>
                </SwiperSlide>

                <SwiperSlide className="swiper-slide1">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="pills-MoreJobs-tab-mobile"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-MoreJobs-mobile"
                      type="button"
                      role="tab"
                      aria-controls="pills-MoreJobs-mobile"
                      aria-selected="false"
                    >
                      More Jobs
                    </button>
                  </li>
                </SwiperSlide> */}
              </Swiper>
            </ul>

            <div className="col-12">
              <div
                className="tab-content featuredJobs_rightSide"
                id="pills-tabContent"
              >
                {jobs?.map((value, index) => (
                  <div
                    className={
                      activeId === value?.id
                        ? "tab-pane fade show active"
                        : "tab-pane fade"
                    }
                    id={`#pill${value?.id}`}
                    role="tabpanel"
                    aria-labelledby="pills-Management-tab-mobile"
                    tabIndex="0"
                  >
                    <div className="row">
                      {value?.jobs
                        ?.slice(0, showAll ? value.jobs.length : 9)
                        .map((job, index) => (
                          <div
                            className="col-lg-4 col-md-6 col-6 mb-md-3 mb-3"
                            key={index}
                          >
                            <div className="card card_left-sec">
                              <div className="card-body pb-0 ">
                                <div className="card-title ">
                                  <img
                                    src={imageUrl + job?.job_contact?.logo}
                                    className="Tata"
                                  />
                                </div>
                                <div className="row justify-content-center mt-3">
                                  {job?.job_create_shifts?.map((type, i) => (
                                    <span
                                      className={`fullTime_txt mb-1`}
                                      key={i}
                                    >
                                      {type?.shift?.name}
                                    </span>
                                  ))}
                                </div>
                                <p className="card-text card_info">
                                  {job?.job_title?.name}
                                </p>
                                <p className="city_txt mb-0">
                                  <FontAwesomeIcon
                                    icon="fa-solid fa-location-dot"
                                    className="faff-locccc"
                                  />
                                  {job?.job_work_locations
                                    ?.slice(0, 2)
                                    .map((type, i) => type?.city?.name)
                                    .join(", ")}
                                </p>
                                <div className="explre_btn_sec">
                                  <button
                                    className="btn btn-2 hover-slide-up"
                                    onClick={() => {
                                      setData(job);
                                      openModal();
                                    }}
                                  >
                                    <span>Explore</span>
                                  </button>
                                  <PopupModal
                                    show={modalShow}
                                    onHide={() => setModalShow(false)}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      {value?.jobs?.length === 0 && <NoDataAnimations />}
                    </div>
                    {value?.jobs.length > 9 && (
                      <div className="showMore_btn_section justify-content-center text-center">
                        <button
                          className="show-more2"
                          onClick={() => setShowAll(!showAll)}
                        >
                          {showAll ? "Show Less" : "Show More"}{" "}
                          <FontAwesomeIcon icon="fa-solid fa-chevron-down" />
                        </button>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default JobExp;
