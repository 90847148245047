import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
// import 'react-tabs/style/react-tabs.css';
import FirstTab from "./FirstTab.js";
import SecondTab from "./SecondTab.js";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import ThirdTab from "./ThirdTab.js";
import FourthTab from "./ForthTab.js";
import "./Maintab.css";
import Autosuggest from "react-autosuggest";
import { Link, useLocation } from "react-router-dom";
import ForthTab from "./ForthTab.js";
import FifthTab from "./FifthTab.js";
import SixthTab from "./SixthTab.js";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import "swiper/swiper-bundle.css";
import { Form, Row, Col, Button, Nav } from "react-bootstrap";
import { useContext, useEffect, useState } from "react";
import { Context } from "../../../../../utils/context.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import PostedJobs from '../PostedJobs/PostedJobs.js';
// import "../../../LoginSignup/UpdatedSingUp/UpdatedForm.css";
import Select from "react-select";
import DatePicker from "react-datepicker";
import Modal from "react-bootstrap/Modal";

const PortfolioEducationForm = () => {
  const location = useLocation();
  const activeTabParam = new URLSearchParams(location.search).get("activeTab");
  const initialActiveTab = activeTabParam ? parseInt(activeTabParam, 10) : 0;

  const [activeTab, setActiveTab] = useState(initialActiveTab);
  const {
    getData,
    postData,
    Select2Data,
    jobSeekerData,
    editStatusData,
    fetchSeekerData,
  } = useContext(Context);

  const [errors, setErrors] = useState();
  const [achievemente, setAchievemente] = useState([]);
  const [degree, setDegree] = useState([]);
  const [university, setUniversity] = useState([]);
  const [universityType, setUniversityType] = useState([]);
  const [degreeType, setDegreeType] = useState([]);
  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [selectedarea_of_studay, setSelectedArea_of_studay] = useState([]);
  const [desciples, setDesciples] = useState([]);
  const [type, setType] = useState([
    {
      value: "Grades",
      label: "Grades",
      name: "type_id",
    },
    {
      value: "GPA",
      label: "GPA",
      name: "type_id",
    },
  ]);

  const [cards, setCards] = useState([]);
  const [status, setStatus] = useState("none");

  const [formData, setFormData] = useState({
    step: 7,
    evidance: undefined,
    achievement_id: "",
    certificate_name: "",
    university_id: "",
    university_type_id: "",
    degree_id: "",
    degree_type_id: "",
    country_id: "",
    state_id: "",
    city_id: "",
    type_id: "",
    grades: "",
    degree_name: "",
    start_date: "",
    end_date: "",
    area_of_study: [],
    college_name: "",
    showCollegeInput: false,
    showDegreeInput: false,
  });

  const validateForm = () => {
    let errors = {};
    const regexAlfaNumric = /^[A-Za-z0-9]+(\s[A-Za-z0-9]+)*$/;
    const regexAlfa = /^[A-Za-z]+(\s[A-Za-z]+)*$/;

    if (!formData.degree_name) {
      errors.degree_name = "Degree Name is required";
    }

    if (!formData.start_date) {
      errors.start_date = "Start Date is required";
    }

    if (!formData.end_date) {
      errors.end_date = "End Date is required";
    } else if (new Date(formData.end_date) < new Date(formData.start_date)) {
      errors.end_date = "End Date cannot be a past date of Start Date";
    } else if (new Date(formData.end_date) > new Date()) {
      errors.end_date = "End Date cannot be a future date";
    }

    if (formData.certificate_name) {
      if (formData?.evidance === undefined) {
        errors.evidance = "Media is required";
      }
    }

    if (!formData.university_id) {
      errors.university_id = "University is required";
    }

    if (!formData.degree_id) {
      errors.degree_id = "Degree is required";
    }

    if (otherDegree) {
      if (!formData?.degree_type_id) {
        errors.degree_type_id = "Degree Type is required";
      }

      if (!formData?.optional_degree) {
        errors.optional_degree = "Degree is required";
      }
    }

    if (otherUniversity) {
      if (!formData.college_name) {
        errors.college_name = "College Name is required";
      }

      if (!formData.country_id) {
        errors.country_id = "Country is required";
      }

      if (!formData.state_id) {
        errors.state_id = "State is required";
      }

      if (!formData.city_id) {
        errors.city_id = "City is required";
      }
    }

    return errors;
  };

  const handleChange = async (e) => {
    // console.log(e?.target?.name);
    if (
      e?.name === "achievement_id" ||
      e?.name === "university_type_id" ||
      e?.name === "degree_type_id" ||
      e?.name === "country_id" ||
      e?.name === "state_id" ||
      e?.name === "city_id" ||
      e?.name === "type_id"
    ) {
      if (e?.name === "country_id") {
        const res = await getData(
          `/without-login/master/all-state/${e?.value}`
        );
        setState(await Select2Data(res.data, "state_id"));
        setFormData({ ...formData, [e.name]: e, state_id: "", city_id: "" });
      } else if (e?.name === "state_id") {
        const res = await getData(`/without-login/master/all-city/${e?.value}`);
        setCity(await Select2Data(res.data, "city_id"));
        setFormData({ ...formData, [e.name]: e, city_id: "" });
      }
      if (e?.name === "university_type_id") {
        setFormData({ ...formData, [e?.name]: e });
      }
      if (e?.name === "degree_type_id") {
        setFormData({ ...formData, [e?.name]: e });
      }
      if (e?.name === "achievement_id") {
        setFormData({ ...formData, [e?.name]: e });
      }
      if (e?.name === "type_id") {
        setFormData({ ...formData, [e?.name]: e });
      } else {
        setFormData({ ...formData, [e.name]: e });
      }
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleInputChange = async (e) => {
    if (
      e?.target?.name === "area_of_study" ||
      e?.target?.name === "degree_name" ||
      e?.target?.name === "college_name" ||
      e?.target?.name === "grades"
    ) {
      if (e?.target?.name === "area_of_study") {
        setFormData({ ...formData, [e?.target?.name]: e.target.value });
      }
      if (e?.target?.name === "degree_name") {
        setFormData({ ...formData, [e?.target?.name]: e.target.value });
      }
      if (e?.target?.name === "college_name") {
        setFormData({ ...formData, [e?.target?.name]: e.target.value });
      }
      if (e?.target?.name === "grades") {
        setFormData({ ...formData, [e?.target?.name]: e.target.value });
      }
    }
  };

  const [lgShow, setLgShow] = useState(false);
  const [ftype, setFType] = useState(true);

  const handleClose = () => setLgShow(false);
  const handleShow = async (type = "ADD") => {
    if (type === "ADD") {
      await setFType(true);
      await setFormData({
        step: 7,
        evidance: undefined,
        achievement_id: "",
        certificate_name: "",
        university_id: "",
        degree_id: "",
        university_type_id: "",
        degree_type_id: "",
        country_id: "",
        state_id: "",
        city_id: "",
        type_id: "",
        grades: "",
        degree_name: "",
        start_date: "",
        end_date: "",
        area_of_study: [],
        college_name: "",
        showCollegeInput: false,
        showDegreeInput: false,
      });
      await setSelectedArea_of_studay([]);
      await setErrors();
    } else {
      await setFType(false);
    }
    await setLgShow(true);
    await setErrors({});
  };

  const getEditData = async (id) => {
    setFType(false);
    const res = await getData(`/jobseeker/signup/education/${id}`);
    // console.log(res);
    const data = res.data;
    setSelectedArea_of_studay(
      await data?.jobseeker_education_areaof_stuadies?.map((d) => ({
        value: d?.descipline_id,
        label: d?.descipline?.name,
        name: "area_of_studay_id",
      }))
    );
    setFormData({
      ...formData,
      id: data?.id,
      evidance: "edit",
      achievement_id: data?.achievement_id
        ? {
            name: "achievement_id",
            label: data?.achievement?.name,
            value: data?.achievement_id,
          }
        : "",
      certificate_name: data?.certificate_name ? data?.certificate_name : "",
      university_id: data?.university?.name,
      degree_id: data?.degree?.name,
      university_type_id: data?.university_type_id
        ? {
            value: data?.university_type_id,
            label: data?.university_type?.name,
            name: "university_type_id",
          }
        : "",
      degree_type_id: data?.degree_type_id
        ? {
            value: data?.degree_type_id,
            label: data?.degree_type?.name,
            name: "degree_type_id",
          }
        : "",
      country_id: data?.country_id
        ? {
            value: data?.country_id,
            label: data?.country?.name,
            name: "country_id",
          }
        : "",
      state_id: data?.state_id
        ? {
            value: data?.state_id,
            label: data?.state?.name,
            name: "state_id",
          }
        : "",
      city_id: data?.city_id
        ? {
            value: data?.city_id,
            label: data?.city?.name,
            name: "city_id",
          }
        : "",
      type_id: data?.type
        ? {
            value: data?.type,
            label: data?.type,
            name: "type_id",
          }
        : "",
      grades: data?.grades ? data?.grades : "",
      degree_name: data?.degree_name,
      start_date: data?.start_date,
      end_date: data?.end_date,
      area_of_study: await data?.jobseeker_education_areaof_stuadies?.map(
        (d) => d?.descipline_id
      ),
      college_name: "",
    });
    handleShow("EDIT");
  };

  const getAllData = async () => {
    {
      const res = await getData("/without-login/master/all-achievemente");
      setAchievemente(await Select2Data(res?.data, "achievement_id"));
    }
    {
      const res = await getData("/without-login/master/all-descipline");
      setDesciples(await Select2Data(res?.data, "area_of_studay_id"));
    }
    {
      const res = await getData("/without-login/master/all-degree");
      setDegree(res?.data);
    }
    {
      const res = await getData("/without-login/master/all-university");
      setUniversity(res?.data);
    }
    {
      const res = await getData("/without-login/master/all-country");
      setCountry(await Select2Data(res?.data, "country_id"));
    }
    {
      const res = await getData("/without-login/master/all-university-type");
      setUniversityType(await Select2Data(res?.data, "university_type_id"));
    }
    {
      const res = await getData("/without-login/master/all-degree-type");
      setDegreeType(await Select2Data(res?.data, "degree_type_id"));
    }
  };

  const [educations, setEducation] = useState();
  const getAllCards = async () => {
    const res = await getData(`/jobseeker/signup/education`);
    if (res?.success) {
      // console.log(res.data.length, "education");
      await setEducation(res?.data);
      if (res?.data.length !== 0) {
        setStatus("none");
      } else {
        setStatus("none");
      }
    }
  };

  useEffect(() => {
    getAllData();
    getAllCards();
  }, [jobSeekerData?.id]);

  const handleSubmit = async (action) => {
    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        const finalData = new FormData();
        if (formData.evidance != "edit") {
          finalData.append(`media`, formData.evidance);
        }

        finalData.append(`step`, formData.step);
        if (formData.achievement_id) {
          finalData.append(`achievement_id`, formData.achievement_id?.value);
        }
        finalData.append(`university_id`, formData.university_id);
        if (formData.university_type_id) {
          finalData.append(
            `university_type_id`,
            formData.university_type_id?.value
          );
        }
        finalData.append(`degree_id`, formData.degree_id);
        if (formData.degree_type_id) {
          finalData.append(`degree_type_id`, formData.degree_type_id?.value);
          finalData.append(`optional_degree`, formData.optional_degree);
        }
        if (formData.country_id) {
          finalData.append(`country_id`, formData.country_id?.value);
        }
        if (formData.state_id) {
          finalData.append(`state_id`, formData.state_id?.value);
        }
        if (formData.city_id) {
          finalData.append(`city_id`, formData.city_id?.value);
        }

        if (formData.type_id) {
          finalData.append(`type_id`, formData.type_id?.value);
        }
        finalData.append(`degree_name`, formData.degree_name);
        finalData.append(`college_name`, formData.college_name);

        finalData.append(`certificate_name`, formData.certificate_name);
        finalData.append(`start_date`, formData.start_date);
        finalData.append(`end_date`, formData.end_date);
        finalData.append(
          `area_of_study`,
          JSON.stringify(formData.area_of_study)
        );
        finalData.append(`grades`, formData.grades);
        finalData.append(`status`, status);
        let result;
        if (formData?.id) {
          result = await postData(
            `/jobseeker/signup/education/${formData?.id}`,
            finalData
          );
        } else {
          result = await postData("/jobseeker/signup/education", finalData);
        }
        // console.log(result);
        if (result?.success) {
          await setFormData({
            step: 7,
            evidance: undefined,
            achievement_id: "",
            certificate_name: "",
            university_id: "",
            degree_id: "",
            university_type_id: "",
            degree_type_id: "",
            country_id: "",
            state_id: "",
            city_id: "",
            type_id: "",
            grades: "",
            degree_name: "",
            start_date: "",
            end_date: "",
            area_of_study: [],
            college_name: "",
            showCollegeInput: false,
            showDegreeInput: false,
          });
          await setSelectedArea_of_studay([]);
          await setErrors();
          await getAllCards();
          await fetchSeekerData();
        }
        handleClose();
        // setLgShow(false);
      } catch (error) {
        // console.log(error);
      }
    }
  };

  useEffect(() => {
    setFormData({
      ...formData,
      ["area_of_study"]: selectedarea_of_studay?.map((item) => item.value),
    });
  }, [selectedarea_of_studay]);

  const handleDelete = async (id) => {
    var data = "";
    if (educations.length === 1) {
      var data = {
        step: 7,
        status: "none",
      };
    } else {
      var data = {
        step: 7,
        status: "none",
      };
    }
    const res = await editStatusData(`/jobseeker/signup/education/${id}`, data);

    await getAllCards();
    await fetchSeekerData();
  };

  const errorStyle = {
    color: "red",
    marginLeft: "5px",
    // fontSize: "11px",
  };

  const [value, setValue] = useState("");
  const [otherUniversity, setOtherUniversity] = useState(false);
  const [otherDegree, setOtherDegree] = useState(false);
  const [suggestionsList, setSuggestionsList] = useState([]);

  const getSuggestions = async (inputValue, list) => {
    const inputValueLowerCase = inputValue.trim().toLowerCase();

    if (list === "university") {
      const res = await getData(
        `/without-login/master/all-university?name=${inputValueLowerCase}`
      );
      if (res?.success) {
        if (res.data.length === 0) {
          setOtherUniversity(true);
        } else {
          setOtherUniversity(false);
        }

        return res.data?.filter((suggestion) =>
          suggestion?.name.toLowerCase().includes(inputValueLowerCase)
        );
      }
    }
    if (list === "degree") {
      const res = await getData(
        `/without-login/master/all-degree?name=${inputValueLowerCase}`
      );
      if (res?.success) {
        if (res.data.length === 0) {
          setOtherDegree(true);
        } else {
          setOtherDegree(false);
        }
        return res.data?.filter((suggestion) =>
          suggestion?.name.toLowerCase().includes(inputValueLowerCase)
        );
      }
    }
  };

  const onSuggestionsFetchRequested = async ({ value }, list) => {
    const suggestions = await getSuggestions(value, list);
    setSuggestionsList(suggestions);
  };

  const onSuggestionsClearRequested = () => {
    setSuggestionsList([]);
  };

  const onChange = (event, { newValue }) => {
    if (event.target.id === "university_id") {
      const isExistingUniversity = suggestionsList.some(
        (suggestion) => suggestion.name === newValue
      );
      if (!isExistingUniversity) {
        setFormData((prevData) => ({
          ...prevData,
          university_id: newValue,
          showCollegeInput: true,
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          university_id: newValue,
          showCollegeInput: false,
        }));
      }
      // setFormData((prevData) => ({ ...prevData, university_id: newValue }));
    }

    if (event.target.id === "degree_id") {
      const isExistingUniversity = suggestionsList.some(
        (suggestion) => suggestion.name === newValue
      );
      if (!isExistingUniversity) {
        setFormData((prevData) => ({
          ...prevData,
          degree_id: newValue,
          showDegreeInput: true,
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          degree_id: newValue,
          showDegreeInput: false,
        }));
      }
      // setFormData((prevData) => ({ ...prevData, degree_id: newValue }));
    }
  };

  const getSuggestionValue = (suggestion) => suggestion?.name;

  const renderSuggestion = (suggestion, id) => (
    <div
      id={id}
      style={{
        border: "1px solid #ccc",
        padding: "10px",
        borderRadius: "5px",
        margin: "5px",
        backgroundColor: "#f9f9f9",
      }}
    >
      {suggestion?.name}
      <br />
      {id === "university_id" && (
        <sup>
          <b id={id}>
            {suggestion?.university_type?.name} : {suggestion?.city?.name},
            {suggestion?.state?.name},{suggestion?.country?.name}
          </b>
        </sup>
      )}
    </div>
  );

  const inputProps = {
    onChange,
    style: {
      width: "100%", // Adjust the width as needed
      padding: "8px",
      borderRadius: "5px",
      border: "1px solid #ccc",
      // Add other styles as needed
    },
  };

  return (
    <>
      <section className="portfolio-experience">
        <div className="container">
          <div className="heading text-center">
            <p>My Portfolio</p>
          </div>
          <div className="tab-header">
            <Row>
              <Col className="">
                <Link to="/dashboard-job">Back</Link>
              </Col>
              <Col className="text-center">
                <p className="edu-text">Add Education</p>
              </Col>
              <Col className="text-end">
                <Link onClick={() => handleShow()}>
                  <div className="text-holder text-end pt-2 me-3">
                    <p>Add</p>
                  </div>
                </Link>
              </Col>
            </Row>
          </div>

          <div className="row ms-0 me-0">
            <div className="col-md-12 p-0">
              <div className="main-design-tab p-3">
                <Form>
                  <Row className="">
                    {educations?.map((value) => (
                      <Col className="col-md-5 col-lg-3 col-xl-3 col-xxl-3 col-12 ">
                        <div className="msc-box">
                          <div className="d-flex ">
                            <div className="tabs-btn p-2 ">
                              <div className="text-end">
                                <FontAwesomeIcon
                                  icon="fa-solid fa-pen-to-square"
                                  className="me-3 "
                                  style={{ cursor: "pointer" }}
                                  onClick={() => getEditData(value?.id)}
                                />
                                <FontAwesomeIcon
                                  icon={faXmark}
                                  className="close-icon"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => handleDelete(value?.id)}
                                />
                              </div>

                              <p>
                                <span>Title:</span>
                                {value?.degree?.name}
                              </p>
                              <div
                                className="info"
                                // onClick={() => getEditData(value?.id)}
                              >
                                <p>
                                  <span>Degree :</span> {value?.degree?.name}
                                </p>
                                <p>
                                  <span>University:</span>{" "}
                                  {value?.university?.name}
                                </p>
                                <p>
                                  <span>Start Date :</span>{" "}
                                  {new Date(
                                    value?.start_date
                                  ).toLocaleDateString("en-CA", {
                                    year: "numeric",
                                    month: "2-digit",
                                    day: "2-digit",
                                  })}
                                </p>
                                <p>
                                  <span>End Date :</span>{" "}
                                  {new Date(value?.end_date).toLocaleDateString(
                                    "en-CA",
                                    {
                                      year: "numeric",
                                      month: "2-digit",
                                      day: "2-digit",
                                    }
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    ))}
                  </Row>
                  <Modal
                    size="lg"
                    show={lgShow}
                    onHide={handleClose}
                    className="experience-modal"
                    centered
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>
                        {ftype ? "Add Education" : "Edit Education"}
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Row>
                        <div className="col-md-6 col-sm-12 mb-3">
                          <Form.Label>
                            University<span className="text-danger">*</span>
                          </Form.Label>
                          <div
                            className="autosuggest-container"
                            style={{ overflowY: "auto", maxHeight: "200px" }}
                          >
                            <Autosuggest
                              className="autooooo"
                              id="university_id"
                              suggestions={suggestionsList}
                              onSuggestionsFetchRequested={(event) =>
                                onSuggestionsFetchRequested(event, "university")
                              }
                              onSuggestionsClearRequested={
                                onSuggestionsClearRequested
                              }
                              getSuggestionValue={getSuggestionValue}
                              renderSuggestion={(suggestion) =>
                                renderSuggestion(suggestion, "university_id")
                              }
                              inputProps={{
                                ...inputProps,
                                id: "university_id",
                                value: formData?.university_id,
                                placeholder: "Enter University",
                              }}
                            />
                          </div>

                          {/* <Select
                        name="university_id"
                        placeholder="Select University"
                        value={formData?.university_id}
                        onChange={handleChange}
                        options={university}
                      /> */}
                          {errors?.university_id && (
                            <span style={errorStyle}>
                              {errors?.university_id}
                            </span>
                          )}
                        </div>

                        {/* {formData?.showCollegeInput ? (
                      <div className="col-md-6 col-sm-12 mb-3">
                        <Form.Label>
                          College Name <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter College Name"
                          name="college_name"
                          value={formData?.college_name}
                          onChange={handleInputChange}
                        />
                        {errors?.college_name && (
                          <span style={errorStyle}>{errors?.college_name}</span>
                        )}
                      </div>
                    ) : (
                      <></>
                    )} */}

                        {otherUniversity && (
                          <>
                            <div className="col-md-6 col-sm-12 mb-3">
                              <Form.Label>
                                College Name{" "}
                                <span className="text-danger">*</span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter College Name"
                                name="college_name"
                                value={formData?.college_name}
                                onChange={handleInputChange}
                              />
                              {errors?.college_name && (
                                <span style={errorStyle}>
                                  {errors?.college_name}
                                </span>
                              )}
                            </div>
                          </>
                        )}

                        <div className="col-md-6 col-sm-12 mb-3">
                          <Form.Label>
                            Degree Earned <span className="text-danger">*</span>{" "}
                          </Form.Label>

                          <Autosuggest
                            suggestions={suggestionsList}
                            onSuggestionsFetchRequested={(event) =>
                              onSuggestionsFetchRequested(event, "degree")
                            }
                            onSuggestionsClearRequested={
                              onSuggestionsClearRequested
                            }
                            getSuggestionValue={getSuggestionValue}
                            renderSuggestion={(suggestion) =>
                              renderSuggestion(suggestion, "degree_id")
                            }
                            inputProps={{
                              ...inputProps,
                              id: "degree_id",
                              value: formData?.degree_id,
                              placeholder: "Enter Degree",
                            }}
                          />
                          {/* <Select
                        name="degree_id"
                        placeholder="Select Degree Earned "
                        value={formData?.degree_id}
                        onChange={handleChange}
                        options={degree}
                      /> */}
                          {errors?.degree_id && (
                            <span style={errorStyle}>{errors?.degree_id}</span>
                          )}
                        </div>

                        {/* {formData?.showDegreeInput ? (
                      <div className="col-md-6 col-sm-12 mb-3">
                        <Form.Label>
                          Degree <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Degree"
                          name="optional_degree"
                          value={formData?.optional_degree}
                          onChange={handleInputChange}
                        />
                        {errors?.optional_degree && (
                          <span style={errorStyle}>
                            {errors?.optional_degree}
                          </span>
                        )}
                      </div>
                    ) : (
                      <></>
                    )} */}

                        <div className="col-md-6 col-sm-12 mb-3">
                          <Form.Label>
                            GPA or Grades <span className="text-danger"></span>{" "}
                          </Form.Label>

                          <Select
                            name="type_id"
                            placeholder="Select GPA or Grades"
                            value={formData?.type_id}
                            onChange={handleChange}
                            options={type}
                          />
                          {errors?.type_id && (
                            <span style={errorStyle}>{errors?.type_id}</span>
                          )}
                        </div>

                        <div className="col-md-6 col-sm-12 mb-3">
                          <Form.Label>
                            GPA or Grades <span className="text-danger"></span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter GPA or Grades"
                            name="grades"
                            value={formData?.grades}
                            onChange={handleInputChange}
                          />
                          {errors?.grades && (
                            <span style={errorStyle}>{errors?.grades}</span>
                          )}
                        </div>
                        {otherDegree && (
                          <>
                            <div className="col-md-4 col-sm-12 mb-3">
                              <Form.Label>
                                Degree Type{" "}
                                <span className="text-danger">*</span>{" "}
                              </Form.Label>

                              <Select
                                name="degree_type_id"
                                placeholder="Select Degree Type"
                                value={formData?.degree_type_id}
                                onChange={handleChange}
                                options={degreeType}
                              />
                              {errors?.degree_type_id && (
                                <span style={errorStyle}>
                                  {errors?.degree_type_id}
                                </span>
                              )}
                            </div>

                            <div className="col-md-6 col-sm-12 mb-3">
                              <Form.Label>
                                Degree <span className="text-danger">*</span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter Degree"
                                name="optional_degree"
                                value={formData?.optional_degree}
                                onChange={handleChange}
                              />
                              {errors?.optional_degree && (
                                <span style={errorStyle}>
                                  {errors?.optional_degree}
                                </span>
                              )}
                            </div>
                          </>
                        )}
                        {otherUniversity && (
                          <>
                            <div className="col-md-4 col-sm-12 mb-3">
                              <Form.Label>
                                University Type{" "}
                                <span className="text-danger">*</span>{" "}
                              </Form.Label>

                              <Select
                                name="university_type_id"
                                placeholder="Select University Type"
                                value={formData?.university_type_id}
                                onChange={handleChange}
                                options={universityType}
                              />
                              {errors?.country_id && (
                                <span style={errorStyle}>
                                  {errors?.country_id}
                                </span>
                              )}
                            </div>
                            <div className="col-md-4 col-sm-12 mb-3">
                              <Form.Label>
                                Country <span className="text-danger">*</span>{" "}
                              </Form.Label>

                              <Select
                                name="country_id"
                                placeholder="Select Country"
                                value={formData?.country_id}
                                onChange={handleChange}
                                options={country}
                              />
                              {errors?.country_id && (
                                <span style={errorStyle}>
                                  {errors?.country_id}
                                </span>
                              )}
                            </div>

                            <div className="col-md-4 col-sm-12 mb-3">
                              <Form.Label>
                                State <span className="text-danger">*</span>{" "}
                              </Form.Label>

                              <Select
                                name="state_id"
                                placeholder="Select State"
                                value={formData?.state_id}
                                onChange={handleChange}
                                options={state}
                              />
                              {errors?.state_id && (
                                <span style={errorStyle}>
                                  {errors?.state_id}
                                </span>
                              )}
                            </div>

                            <div className="col-md-4 col-sm-12 mb-3">
                              <Form.Label>
                                City <span className="text-danger">*</span>{" "}
                              </Form.Label>

                              <Select
                                name="city_id"
                                placeholder="Select City"
                                value={formData?.city_id}
                                onChange={handleChange}
                                options={city}
                              />
                              {errors?.city_id && (
                                <span style={errorStyle}>
                                  {errors?.city_id}
                                </span>
                              )}
                            </div>
                          </>
                        )}

                        <div className="col-md-6 col-sm-12 mb-3">
                          <Form.Label>
                            Degree Name <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Degree Name"
                            name="degree_name"
                            value={formData?.degree_name}
                            onChange={handleInputChange}
                          />
                          {errors?.degree_name && (
                            <span style={errorStyle}>
                              {errors?.degree_name}
                            </span>
                          )}
                        </div>

                        {/* <div className="col-md-6 col-sm-12 mb-3">
                      <Form.Label>
                        Area of Study <span className="text-danger"></span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Area of Study"
                        name="area_of_study"
                        value={formData?.area_of_study}
                        onChange={handleInputChange}
                      />
                      {errors?.area_of_study && (
                        <span style={errorStyle}>{errors?.area_of_study}</span>
                      )}
                    </div> */}

                        <div className="col-md-6 col-sm-12 mb-3">
                          <Form.Label>Area Of Study</Form.Label>

                          <Select
                            isMulti={true}
                            name="area_of_studay"
                            placeholder="Selecte Area Of Study"
                            value={selectedarea_of_studay}
                            onChange={(e) => {
                              setSelectedArea_of_studay(e);
                            }}
                            options={desciples}
                          />
                          {errors?.area_of_studay && (
                            <span style={errorStyle}>
                              {errors?.area_of_studay}
                            </span>
                          )}
                        </div>

                        <div className="col-md-6 col-sm-6 mb-3">
                          <Form.Label>
                            Start Date <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            type="date"
                            placeholder="Start Date"
                            name="start_date"
                            value={formData?.start_date}
                            onChange={handleChange}
                          />
                          {errors?.start_date && (
                            <span style={errorStyle}>{errors?.start_date}</span>
                          )}
                        </div>

                        <div className="col-md-6 col-sm-6 mb-3">
                          <Form.Label>
                            End Date <span className="text-danger">*</span>
                          </Form.Label>

                          <Form.Control
                            type="date"
                            placeholder="End Date"
                            name="end_date"
                            value={formData?.end_date}
                            onChange={handleChange}
                          />

                          {errors?.end_date && (
                            <span style={errorStyle}>{errors?.end_date}</span>
                          )}
                        </div>

                        {/* <div className="col-md-6 col-sm-12 mb-3">
                      <Form.Label>
                        Achievemente<span className="text-danger"></span>{" "}
                      </Form.Label>

                      <Select
                        name="achievement_id"
                        placeholder="Select Achievement"
                        value={formData?.achievement_id}
                        onChange={handleChange}
                        options={achievemente}
                      />
                      {errors?.achievement_id && (
                        <span style={errorStyle}>{errors?.achievement_id}</span>
                      )}
                    </div> */}

                        <div className="col-md-6 col-sm-12 mb-3">
                          <Form.Label>
                            Certificate Name{" "}
                            <span className="text-danger"></span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Certificate Name"
                            name="certificate_name"
                            value={formData?.certificate_name}
                            onChange={handleChange}
                          />
                          {errors?.certificate_name && (
                            <span style={errorStyle}>
                              {errors?.certificate_name}
                            </span>
                          )}
                        </div>

                        <div className="col-md-6 col-sm-12 mb-3">
                          <Form.Label>
                            Upload Media
                            <span className="text-danger"></span>{" "}
                          </Form.Label>

                          <Form.Control
                            type="file"
                            placeholder="Upload Media"
                            name="evidance"
                            accept="application/pdf"
                            onChange={async (e) => {
                              await setFormData({
                                ...formData,
                                [e.target.name]: e.target.files[0],
                              });
                            }}
                          />
                          {errors?.evidance && (
                            <span style={errorStyle}>{errors?.evidance}</span>
                          )}
                        </div>
                      </Row>
                    </Modal.Body>
                    <Modal.Footer className="mx-auto">
                      <Button
                        className="blue-btn-class me-2"
                        variant="secondary"
                        onClick={handleClose}
                      >
                        Close
                      </Button>
                      <Button className="blue-btn-class" onClick={handleSubmit}>
                        {ftype ? "Add Education" : "Edit Education"}
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </Form>
                {/* <Tabs
                  className="desktop-view-tabs"
                  selectedIndex={activeTab}
                  onSelect={(index) => setActiveTab(index)}
                >
                  <TabList className="nav-flex mx-auto justify-content-center px-4 mb-2 g-0">
                    <Tab className="text-center">Higher Education</Tab>
                    <Tab className="text-center">Post Graduation</Tab>
                    <Tab className="text-center">Graduation</Tab>
                    <Tab className="text-center">HSC</Tab>
                    <Tab className="text-center">SSC</Tab>
                    <Tab className="text-center">Certifications</Tab>
                  </TabList>

                  <TabPanel>
                    <FirstTab />
                  </TabPanel>

                  <TabPanel>
                    <SecondTab />
                  </TabPanel>

                  <TabPanel>
                    <ThirdTab />
                  </TabPanel>

                  <TabPanel>
                    <ForthTab />
                  </TabPanel>

                  <TabPanel>
                    <FifthTab />
                  </TabPanel>

                  <TabPanel>
                    <SixthTab />
                  </TabPanel>
                </Tabs> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PortfolioEducationForm;
