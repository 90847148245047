import React, { useContext, useState, useEffect } from "react";
import "../../Myportfolio.css";
import PortfolioChart from "./Portfolio-Chart/PortfolioChart";
import PortfolioPrimarySkill from "./Port-Primary-skill/PortfolioPrimarySkill";
import PortExperienceSection from "./Port-Experience-section/PortExperienceSection";

import { Context } from "../../../../../utils/context";

const PortfolioModalComponent = (props) => {
  const { getData, postData } = useContext(Context);

  const [portfolio, setPortfolio] = useState();
  const [primaryData, setPrimaryData] = useState();
  const getPortfolio = async () => {
    const res = await getData(
      `/recruiters/job/view-portfolios/${props?.userId}`
    );
    if (res?.success) {
      setPortfolio(res.data);
    }
  };

  const getPrimary = async () => {
    setPrimaryData(props?.userId);
  };

  useEffect(() => {
    getPortfolio();
    getPrimary();
  }, [props]);
  return (
    <>
      <section className="my-portfolio">
        <div className="container">
          <PortfolioChart portfolio={portfolio} onHide={props.onHide} />
          <PortExperienceSection portfolio={portfolio} />
          <PortfolioPrimarySkill userId={primaryData} />
        </div>
      </section>
    </>
  );
};

export default PortfolioModalComponent;
