import React from "react";
import '../OpenJobs/OpenJobs.css'
import '../HiringFunnel2/HiringFunnel2.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import PortFolio from "../../../../CommonPage/PortFolio/PortFolio";

const HiringFunnel2 = () => {
    return (
        <>
            <section className="manage-job">
                <div className='container'>
                    <div className="row mt-md-5 mt-3">
                        <div className="col-md-12">
                            <div className="section-title mt-md-3 mt-0">
                                <div className="row">
                                   
                                        <div className="heading-holder1 text-center">
                                            <h5 className="hiring-name">Hiring Funnel</h5>
                                        </div>
                                    
                                    <div className=" col-xxl-4 col-xl-4 col-lg-4 col-md-6 mt-md-3 mt-0">
                                        <a className="title-open-jobs"><Link to='/postedjob'>Go to List of Till Date Posted Jobs</Link> <FontAwesomeIcon icon="fa-solid fa-angle-right" className="mx-2" /><Link to='/hiring'>Hiring Funnel</Link> <FontAwesomeIcon icon="fa-solid fa-angle-right" className="mx-2" /> <span>Portfolio</span></a>
                                    </div>
                                    <div className=" col-xxl-8 col-xl-8 col-lg-8 col-md-6">
                                        <div className="left-blue-line"></div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="mt-md-5 mt-2">
                                            <div className="row">
                                                <div className="col-lg-8 col-md-6">
                                                    <div className="text-holder-manage-job">
                                                        <p>Name: Shweta Sharma <span> (Graphic & UI Designer) </span></p>
                                                        <span>Mumbai Central, Mumbai Maharashtra 411004 <br /> shwetasharma@dummymail.com <br />+91 9876543210</span>
                                                    </div>
                                                    <div className="d-flex mobile-view-icon">
                                                    <FontAwesomeIcon icon="fa-solid fa-file-pdf" className="link-icon me-2"/>  
                                                    <FontAwesomeIcon icon="fa-solid fa-link" className="link-icon me-2"/> 
                                                    <FontAwesomeIcon icon="fa-solid fa-file-arrow-down" className="link-icon me-2"/>
                                                    </div>
                                                </div>
                                                {/* <div className="col-md-4"></div> */}
                                                <div className="col-lg-4 col-md-6">
                                                    <p className="text-hiring-funnel">Applied on : 2 / 10 / 2023</p>
                                                
                                                    <div className="shortlisting text-start">
                                                        <p className="text-start text-hiring-funnel">Status:</p>
                                                        <button className="btn shortlist-btn me-2">Shortlist</button>
                                                        <button className="btn hold-btn me-2">On Hold</button>
                                                        <button className="btn reject-btn me-2">Reject</button>
                                                        <button className="btn send-email">Send Email To Candidate</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row mb-5'>

                        <div className='col-md-8'>
                            <div className="responsive-container">
                                <iframe id="slideshareframe" src="//www.slideshare.net/slideshow/embed_code/key/inwur8JgqF1NyL" frameborder="0" marginwidth="0" marginheight="600" scrolling="no" allowfullscreen> </iframe>
                            </div>
                        </div>
                        <div className='col-md-4 mt-5'>
                            <div className="portfolio-btn-main ">
                                <div>
                                    <button className='btn portfolio-btn'><FontAwesomeIcon icon="fa-solid fa-file-pdf" /> View Portfolio PDF</button>
                                </div>
                                <div className='view-portfolio'>
                                    <button className='btn portfolio-btn'><FontAwesomeIcon icon="fa-solid fa-link" /> View Portfolio Link</button>
                                    <div className='no-link-text-main'>
                                        <p className='no-link-text'>No Link Availale</p>
                                    </div>
                                </div>
                                <div>
                                    <button className='btn download-portfolio-btn'><FontAwesomeIcon icon="fa-solid fa-file-arrow-down" /> Download Portfolio</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



        </>
    )
}

export default HiringFunnel2;