import React from "react";

const AccountBanner = () => {
  return (
    <>
      {/* <section className="privacy-banner">
                <div className='container-fluid '>
                    <div className="row ">
                        <div className="col-md-12">
                            <div className='banner-img-short'>
                                <img src={banner} className='banner-img' />
                            </div>
                            <div className='text-holder-privacy-account'>
                            <img src={user} className='banner-img' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>  */}
    </>
  );
};

export default AccountBanner;
