import React from 'react';
import './PostedJob.css';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PostedJob = () => {
    return (
        <>
            <section className="Posted-job">
                <div className='container'>
                    <div className="row">
                        <div className="section-title text-center mt-md-5 mt-3">
                            <div className="row mb-xl-5">
                                <div className=" col-md-12 text-center">
                                    <h5 className="title-talent">My Posted Jobs</h5>
                                </div>
                                <div className=" col-xxl-3 col-xl-3 col-lg-4 col-md-6 ">
                                    <a className="title-open-jobs"><Link to='/dashboardmain'>Go to Dashboard </Link><FontAwesomeIcon icon="fa-solid fa-angle-right" className='mx-2' /> <span> My Posted Jobs</span></a>
                                </div>
                                <div className=" col-xxl-9 col-xl-9 col-lg-8 col-md-6">
                                    <div className="left-black-line"></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-12 ">
                            <div className="first-company">
                                <div className="row ms-lg-5 ms-md-0">
                                    <div className="col-md-10 col-8">
                                        <div className="company-name"> 
                                            <h5 className="position mt-md-3 mt-1">Graphic & UI Designer</h5>
                                        </div>
                                    </div>
                                    <div className='col-md-2 col-4 mt-3 pool-silver'></div>
                                    <div className="col-md-12">
                                        <div className="text-holder-employer">
                                            <p className="job-post mt-md-3 mt-0"><p>Profcyma Solution Pvt. Ltd</p></p>
                                        </div>
                                    </div>
                                    <div className='col-md-10 col-8 d-flex'>
                                        <p className="pool-silver me-2"> <FontAwesomeIcon icon="fa-solid fa-star" /><FontAwesomeIcon icon="fa-solid fa-star" /><FontAwesomeIcon icon="fa-solid fa-star" /><FontAwesomeIcon icon="fa-solid fa-star" /><FontAwesomeIcon icon="fa-solid fa-star" className='me-2' />9,408 reviews  </p>
                                        <p className="pool-silver me-2" ><FontAwesomeIcon icon="fa-solid fa-location-dot" /> Bavdhan, Pune</p>
                                    </div>
                                    <div className='col-md-2 col-4'>
                                        <p className="pool-silver text-md-start text-end">Silver Pool (S1) </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PostedJob;