import React from 'react'
import HelpDeskBanner from './HelpDeskBanner/HelpDeskBanner';
import HelpDesk from './HelpDesk/HelpDesk';


const HelpDeskPage = () => {
    return (
        <div>
            <HelpDeskBanner />
            <HelpDesk />
        </div>
    )
} 

export default HelpDeskPage;