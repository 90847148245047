import React from 'react';
import '../HelpDesk/HelpDesk.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const HelpDesk = () => {
    return (
        <>
            <section className="helpdesk">
                <div className='container-fluid me-0 ms-0'>
                    <div className="row">
                        <div className='col-md-12 p-0'>
                            <div className='bgimg-main'>
                                <img src={process.env.PUBLIC_URL + "assets/Images/banner/helpdesk/helpdeskbg.png"}  className='bgimg' />
                            </div>
                        </div>
                        <div className='col-lg-5 col-md-6'>
                            <div className='main-contact'>
                                <div className='contact-deatils mb-5'>
                                    <div className='row '>
                                        <div className='col-lg-1 col-md-1 col-sm-1 col-1'>
                                            <FontAwesomeIcon icon="fa-solid fa-phone" className='social-icon' />
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-sm-6 col-6'>
                                            <p>Help Desk Number :</p>
                                        </div>
                                        <div className='col-lg-5 col-md-5 col-sm-5 col-5'>
                                            <p>0123-456-7890</p>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-1 col-md-1 col-1'>
                                            <FontAwesomeIcon icon="fa-solid fa-envelope" className='social-icon' />
                                        </div>
                                        <div className='col-lg-5 col-md-5 col-5'>
                                            <p>E-mail :</p>
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-5'>
                                            <p>inquiry@hiclousia.com</p>
                                        </div>
                                    </div>
                                    {/* email */}
                                    {/* For Support */}
                                    <div className='for-support'>
                                        <button className='btn support-btn'><FontAwesomeIcon icon="fa-solid fa-headphones" className='social-icon' /> for support</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HelpDesk;