import React, { useContext, useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { Link } from "react-router-dom";
import { Context } from "../../../../../../utils/context";
import Lottie from "react-lottie";
import HorizantalGraph from "../../../../my-portfolio/chart-section/HorizantalGraph";
import alertAnimation from "../Portfolio-Chart/alert-lottie.json"; // Replace with the path to your Lottie JSON file
ChartJS.register(ArcElement, Tooltip, Legend);

const PortfolioPrimarySkill = (props) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: alertAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const {
    postData,
    jobSeekerData,
    setJobSeekerData,
    getJobSeekerData,
    getData,
  } = useContext(Context);
  const [primaryPopup, setPrimaryPopup] = useState(true);
  const getPrimarySkillData = async () => {
    const res = await getData(
      `/recruiters/job/view-portfolios-primary-skills/${props?.userId}`
    );

    if (res?.success) {
      // console.log(res);
      if (res.data.count.length > 0) {
        setPrimaryPopup(false);
      }
      if (res.data.label.length > 0) {
        setPrimaryPopup(false);
      }
      setSkills(res.data.skills);
      setCount(res.data.count);
      setLabel(res.data.label);
      setData({
        series: [
          {
            data: res.data.count,
          },
        ],
        options: {
          chart: {
            type: "bar",
            height: 350,
          },
          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: true,
            },
          },
          dataLabels: {
            // enabled: false,
          },
          xaxis: {
            categories: res.data.label,
          },
        },
      });
    }
  };

  useEffect(() => {
    getPrimarySkillData();
  }, [props]);
  const [label, setLabel] = useState([]);
  const [count, setCount] = useState([]);
  const [data, setData] = useState({
    series: [
      {
        data: count,
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
        },
      },
      dataLabels: {
        // enabled: false,
      },
      xaxis: {
        categories: label,
      },
    },
  });
  const [skills, setSkills] = useState([]);

  return (
    <>
      <div className=" PrimarySkill_main row mt-3">
        <div className="col-md-12 p-0">
          <div className="card">
            <div className="skill-holder1">
              <h4>Primary Skills</h4>
            </div>
            <div className="row mt-4 mb-3">
              <div className="col-lg-6 col-12">
                <HorizantalGraph data={data} />
              </div>
              <div className="col-lg-6 col-12 ">
                <div className="mt-2">
                  {skills?.map((val, index) => (
                    <div className="primarySkills table-responsive" key={index}>
                      <table className="table table-bordered">
                        <tbody>
                          {val?.skills?.map((d, index) => (
                            <tr>
                              <td className="development_first">
                                {val?.skill_type?.name}
                              </td>
                              <td>{d?.name}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ))}

                  {primaryPopup && (
                    <div className="middlePopup">
                      <div className="popupContent">
                        <div
                          className="alert-popup"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <Lottie
                            options={defaultOptions}
                            height={150}
                            width={150}
                          />
                          <p className="message">No Primary Skills Yet...!</p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PortfolioPrimarySkill;
