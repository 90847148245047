import React from 'react'
import Apply from './Apply/Apply.js';
import ApplyJobsTitle from './ApplyJobsTitle/ApplyJobsTitle.js';


const ApplyJobs = () => {
    return (
        <div>
             <ApplyJobsTitle />    
            <Apply />        
        </div>
    )
} 

export default ApplyJobs;