import React from 'react'
import CompanyProfileText from './CompanyProfileText/CompanyProfileText';
import CompanyBanner from './CompanyBanner/CompanyBanner';




const CompanyProfile = () => {
    return (
        <div>
             {/* <CompanyBanner /> */}
            <CompanyProfileText />
           
        </div>
    )
} 

export default CompanyProfile;