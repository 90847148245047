import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import '../AppliedJobs/AppliedJobs.css'

const AppliedJobs = () => {
    return (
        <>
            <section className="Sixth-job-tab">
                <div className='sec-title mt-md-5 mt-3'>
                    <h5 className="title-talent">Applied Jobs</h5>
                </div>
                <div className='container'>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="tittle-sixth-job-tab">
                                {/* <h5 className="text-center mt-3"></h5> */}
                                <div className="desktop-view">
                                    <Link to='/dashboard-job'><a>Go to Dashboard</a></Link>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="sixthjob-card mt-3">
                                    <div className="row">
                                        <div className="col-lg-10 col-md-8 col-8">
                                            <h5>Graphic % UI Designer </h5>
                                        </div>
                                        <div className="col-lg-2 col-md-4 col-4">
                                            <div>
                                                <button className="btn show-more2">Applied</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-10 col-md-10 col-sm-8 col-8">
                                            <div>
                                                <a className="company-name">Profcyma Solutions PVT. Ltd.</a>
                                            </div>
                                            <div className="d-md-flex">
                                                <p className="text-holder-sixthjob"><FontAwesomeIcon icon="fa-solid fa-star" /><FontAwesomeIcon icon="fa-solid fa-star" /><FontAwesomeIcon icon="fa-solid fa-star" /><FontAwesomeIcon icon="fa-solid fa-star" /><FontAwesomeIcon icon="fa-solid fa-star" />9,408 reviews</p>
                                                <p className="text-holder-sixthjob"><FontAwesomeIcon icon="fa-solid fa-location-dot" className="me-2 ms-md-2" /> Bavdan,Pune</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-2 col-2">
                                            <div className="mt-2">
                                                <p className="text-holder-sixthjob">Status:Shortlisted</p>
                                                <p className="text-holder-sixthjob">Job:Active</p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="applied-job">
                                <div className="col-md-12">
                                    <div className="applied-card mt-3">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="row">
                                                    <div className="col-md-1 col-1">
                                                        <div className="text-holder-applied">
                                                            <FontAwesomeIcon icon="fa-solid fa-briefcase" className="me-4" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-11 col-11">
                                                        <div className="text-holder-applied">
                                                            <h5>Job description: </h5>
                                                            <p>Urgently hiring full time in-house Graphic Designer & UI Designer.
                                                                We are looking for a Graphic Designer who will be responsible for
                                                                the User interface (UI) design of various websites and design of
                                                                banners & logos. The candidate will ensure the online user experience
                                                                is optimized for improved usability, usefulness, and exceptional
                                                                visual design.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="text-holder-applied">
                                                    <div className="row">
                                                        <div className="col-md-1 col-1">
                                                            <div className="text-holder-applied">
                                                                <FontAwesomeIcon icon="fa-solid fa-briefcase" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-11 col-11">
                                                            <div className="text-holder-applied">
                                                                <h6>Skills </h6>
                                                                <p>Photoshop, Illlustrator, Corel Draw, Adobe XD, Figma</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="text-holder-applied">
                                                    <div className="row">
                                                        <div className="col-md-1 col-1">
                                                            <div className="text-holder-applied">
                                                                <FontAwesomeIcon icon="fa-solid fa-graduation-cap" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-11 col-11">
                                                            <div className="text-holder-applied">
                                                                <h6> Qualification </h6>
                                                                <p>Any Graduate with Degree or Diploma Certification in Graphic
                                                                    and UI Designing </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="text-holder-applied">
                                                    <div className="row">
                                                        <div className="col-md-1 col-1">
                                                            <div className="text-holder-applied">
                                                                <FontAwesomeIcon icon="fa-solid fa-comments" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-11 col-11">
                                                            <div className="text-holder-applied">
                                                                <h6> Languages </h6>
                                                                <p>English, Hindi, Marathi</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="applied-card">
                                        <h5>Job details:</h5>
                                        <div className="row mt-3">
                                            <div className="col-md-6">
                                                <div>
                                                    <div className="text-holder-applied">
                                                        <div className="row ">
                                                            <div className="col-md-1 col-1">
                                                                <div className="text-holder-applied">
                                                                    <FontAwesomeIcon icon="fa-solid fa-money-bill-1" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-11 col-11">
                                                                <div className="text-holder-applied">
                                                                    <h6> Pay </h6>
                                                                    <p>₹ 18,000 - ₹ 36,000 a month</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="row">
                                                            <div className="col-md-1 col-1">
                                                                <div className="text-holder-applied">
                                                                    <FontAwesomeIcon icon="fa-solid fa-clock" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-11 col-11">
                                                                <div className="text-holder-applied">
                                                                    <h6>Shift and Schedule</h6>
                                                                    <p>Day shift</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-1 col-1">
                                                                <div className="text-holder-applied">
                                                                    <FontAwesomeIcon icon="fa-solid fa-briefcase" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-11 col-11">
                                                                <div className="text-holder-applied">
                                                                    <h5>Experience required: 1 year</h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* <h6>Shift and Schedule</h6> */}
                                                        <div className="row mt-4">
                                                            <div className="col-md-1 col-1">
                                                                <div className="text-holder-applied">
                                                                    <FontAwesomeIcon icon="fa-solid fa-location-dot" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-11 col-11">
                                                                <div className="text-holder-applied">
                                                                    <h6>Job Location: Bavdan, Pune</h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="row">
                                                    <div className="col-md-1 col-1">
                                                        <div className="text-holder-applied">
                                                            <FontAwesomeIcon icon="fa-solid fa-briefcase" className="me-4" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-11 col-11">
                                                        <div className="text-holder-applied">
                                                            <h6> Job type </h6>
                                                            <p>Full Time</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-1 col-1">
                                                        <div className="text-holder-applied">
                                                            <FontAwesomeIcon icon="fa-solid fa-briefcase" className="me-4" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-11 col-11">
                                                        <div className="text-holder-applied">
                                                            <h6>Number of Vacancy</h6>
                                                            <p>1 </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-1">
                                                        <div className="text-holder-applied">
                                                            <FontAwesomeIcon icon="fa-solid fa-briefcase" className="me-4" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-11 col-11">
                                                        <div className="text-holder-applied">
                                                            <h6>Secondary Skills: Drawing & Visualization</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row application_row_txt">
                                                    <div className="col-md-1 col-1">
                                                        <div className="text-holder-applied">
                                                            <FontAwesomeIcon icon="fa-solid fa-briefcase" className="me-4" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-11 col-11">
                                                        <div className="text-holder-applied">
                                                            <h6> Application Deadline Date : 20/10/2023</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="applied-card">
                                        <div>
                                            <h6> <FontAwesomeIcon icon="fa-solid fa-briefcase" className="me-4" /> Roles and responsibilities: </h6>
                                            <ul className="mt-3">
                                                <li><FontAwesomeIcon icon="fa-solid fa-circle-check" className="watch-icon me-2" />Expertise in designing websites using Photoshop and Illustrator.</li>
                                                <li><FontAwesomeIcon icon="fa-solid fa-circle-check" className="watch-icon me-2" />Visualize and design user interface, wireframes, mock-ups, task flows for web applications.</li>
                                                <li><FontAwesomeIcon icon="fa-solid fa-circle-check" className="watch-icon me-2" />Create graphic assets like icons, buttons, widgets, landing pages for use in user interfaces.</li>
                                                <li><FontAwesomeIcon icon="fa-solid fa-circle-check" className="watch-icon me-2" />Designing Web Layouts, Logos, Banners, Infographics.</li>
                                                <li><FontAwesomeIcon icon="fa-solid fa-circle-check" className="watch-icon me-2" />Conceptualize ideas that bring simplicity and delight to the customer.</li>
                                                <li><FontAwesomeIcon icon="fa-solid fa-circle-check" className="watch-icon me-2" />Maintain standards and processes for producing deliverables.</li>
                                                <li><FontAwesomeIcon icon="fa-solid fa-circle-check" className="watch-icon me-2" />Gather feedback from teams and follow an iterative process.</li>
                                            </ul>
                                        </div>
                                        <div>
                                            <h6> <FontAwesomeIcon icon="fa-solid fa-briefcase" className="me-4" /> Skill Required </h6>
                                            <ul className="mt-3">
                                                <li><FontAwesomeIcon icon="fa-solid fa-circle-check" className="watch-icon me-2" />Expertise in designing websites using Photoshop and Illustrator.</li>
                                                <li><FontAwesomeIcon icon="fa-solid fa-circle-check" className="watch-icon me-2" /> Visualize and design user interface, wireframes, mock-ups, task flows for web applications.</li>
                                                <li><FontAwesomeIcon icon="fa-solid fa-circle-check" className="watch-icon me-2" /> Create graphic assets like icons, buttons, widgets, landing pages for use in user interfaces.</li>
                                                <li><FontAwesomeIcon icon="fa-solid fa-circle-check" className="watch-icon me-2" /> Designing Web Layouts, Logos, Banners, Infographics.</li>
                                                <li><FontAwesomeIcon icon="fa-solid fa-circle-check" className="watch-icon me-2" /> Conceptualize ideas that bring simplicity and delight to the customer.</li>
                                                <li><FontAwesomeIcon icon="fa-solid fa-circle-check" className="watch-icon me-2" /> Maintain standards and processes for producing deliverables.</li>
                                                <li><FontAwesomeIcon icon="fa-solid fa-circle-check" className="watch-icon me-2" /> Gather feedback from teams and follow an iterative process.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="applied-card">
                                        <div className="contact">
                                            <h5>Contact:</h5>
                                            <div className="ps-4">
                                                <p className="name-holder">Deepti Deshmukh (HR Manager)<br />Profycyma Solutions Pvt. Ltd.</p>
                                                <p>Recruiter Contact Number: +91 9876543210</p>
                                                <p>Recruiter Email: hiclousia@gmail.com</p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AppliedJobs;