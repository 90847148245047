import React from "react";
import { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import Collapse from "react-bootstrap/Collapse";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import PopupModal from "../../Modal/PopupModal";
import Offcanvas from "react-bootstrap/Offcanvas";
import "./InnerPage.css";
import "../../FeaturedJobs/FeaturedJobs.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const InnerPage = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [open, setOpen] = useState(false);
  const [rangeValue, setRangeValue] = useState(50);
  const [sidebarClass, setSidebarClass] = useState("sidebar_height");
  const [modalShow, setModalShow] = React.useState(false);

  const openModal = () => {
    setModalShow(true);

    setTimeout(() => {
      setModalShow(false);
      window.location.href = "/";
    }, 2000);
  };
  const toggleOpen = () => {
    setOpen(!open);

    if (open) {
      setSidebarClass("sidebar_height");
    } else {
      setSidebarClass("sidebar_height show-more-active");
    }
  };
  const jobData = [
    {
      title: "Full Stack Developer",
      location: "Pune",
      imageSource: process.env.PUBLIC_URL + "assets/Images/logo/Tata.png",
      companyname: "Tata consultancy Services",
      jobType: ["Full Time", "Part Time", "Freelance"],
    },
    {
      title: "Front End Developer",
      location: "Bangalore",
      imageSource: process.env.PUBLIC_URL + "assets/Images/logo/Infosys.png",
      companyname: "Infosys",
      jobType: ["Full Time", "Part Time", "Freelance"],
    },
    {
      title: "BackEnd Developer",
      location: "Bangalore",
      imageSource:
        process.env.PUBLIC_URL + "assets/Images/logo/Aditya_birla.png",
      companyname: "Aditya Birla Group",
      jobType: ["Full Time", "Part Time", "Freelance"],
    },
    {
      title: "Graphic Designing",
      location: "Bangalore",
      imageSource: process.env.PUBLIC_URL + "assets/Images/logo/Reliance.png",
      companyname: "Reliance",
      jobType: ["Full Time", "Part Time", "Freelance"],
    },
    {
      title: "UI/UX Developer",
      location: "Bangalore",
      imageSource: process.env.PUBLIC_URL + "assets/Images/logo/Fujitsu.png",
      companyname: "Fujitsu",
      jobType: ["Full Time", "Part Time", "Freelance"],
    },
    {
      title: "Digital Marketing",
      location: "Bangalore",
      imageSource: process.env.PUBLIC_URL + "assets/Images/logo/Cognizant.png",
      companyname: "Cognizant",
      jobType: ["Full Time", "Part Time", "Freelance"],
    },
    {
      title: "BackEnd  Developer",
      location: "Bangalore",
      imageSource: process.env.PUBLIC_URL + "assets/Images/logo/Capjemini.png",
      companyname: "Capzemini",
      jobType: ["Full Time", "Part Time", "Freelance"],
    },
    {
      title: "Front End Developer",
      location: "Bangalore",
      imageSource: process.env.PUBLIC_URL + "assets/Images/logo/Symantec.png",
      companyname: "Tech_Mahindra",
      jobType: ["Full Time", "Part Time", "Freelance"],
    },
    {
      title: "Full Stack Developer",
      location: "Bangalore",
      imageSource: process.env.PUBLIC_URL + "assets/Images/logo/Symantec.png",
      companyname: "Symantec",
      jobType: ["Full Time", "Part Time", "Freelance"],
    },
    {
      title: "Full Stack Developer",
      location: "Bangalore",
      imageSource: process.env.PUBLIC_URL + "assets/Images/logo/Symantec.png",
      companyname: "Symantec",
      jobType: ["Full Time", "Part Time", "Freelance"],
    },
    {
      title: "Full Stack Developer",
      location: "Bangalore",
      imageSource: process.env.PUBLIC_URL + "assets/Images/logo/Symantec.png",
      companyname: "Symantec",
      jobType: ["Full Time", "Part Time", "Freelance"],
    },
    {
      title: "Full Stack Developer",
      location: "Bangalore",
      imageSource: process.env.PUBLIC_URL + "assets/Images/logo/Symantec.png",
      companyname: "Symantec",
      jobType: ["Full Time", "Part Time", "Freelance"],
    },
  ];
  const [showAll, setShowAll] = useState(false);
  const visibleJobs = showAll ? jobData : jobData.slice(0, 9);

  const handleRangeChange = (event) => {
    setRangeValue(event.target.value);
  };
  return (
    <>
      <section className="Featured_Category desktop-view-innerpage mb-5   ">
        <div className="container">
          <div className="row mt-5">
            <div className="text_horizantal_line mb-4">
              <Breadcrumb className="talent_txt">
                <Breadcrumb.Item href="/">Go to Home</Breadcrumb.Item>
                <Breadcrumb.Item href="/innerPage" className="Popular_txt">
                  Popular Job Categories
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-lg-3 col-md-12">
              <div className="sidebar_featured_sec">
                <h3 className="job_filter_txt">Job Filters</h3>
                <div className={sidebarClass}>
                  <div className="down_content_sidebar ">
                    <div className="">
                      <p className="JobAvailability_txt">Job Availability</p>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckIndeterminateDisabled"
                        />
                        <label
                          className="form-check-label"
                          for="flexCheckIndeterminateDisabled"
                        >
                          Open
                        </label>
                      </div>
                      <div className="closedCheckbox_sec form-check mt-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckIndeterminateDisabled"
                        />
                        <label
                          className="form-check-label"
                          for="flexCheckIndeterminateDisabled"
                        >
                          Closed
                        </label>
                      </div>
                    </div>
                    <div className="mt-4 CompanyName_section">
                      <h5 className="">Company Name</h5>
                      <input
                        className="CompanyName"
                        type="text"
                        placeholder="Desired Company Name"
                      />
                      <div className="line"></div>
                    </div>

                    <div className="CompanyName_section">
                      <h5 className="mt-4">Role</h5>
                      <input
                        className="CompanyName"
                        type="text"
                        placeholder="Desired Company Name"
                      />
                    </div>

                    <div className="CompanyName_section">
                      <h5 className="mt-4">Experience</h5>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckIndeterminateDisabled"
                        />
                        <label
                          className="form-check-label"
                          for="flexCheckIndeterminateDisabled"
                        >
                          Expert
                        </label>
                      </div>
                      <div className="mt-2 form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckIndeterminateDisabled"
                        />
                        <label
                          className="form-check-label"
                          for="flexCheckIndeterminateDisabled"
                        >
                          Advance
                        </label>
                      </div>
                      <div className="mt-2 form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckIndeterminateDisabled"
                        />
                        <label
                          className="form-check-label"
                          for="flexCheckIndeterminateDisabled"
                        >
                          Proficient
                        </label>
                      </div>
                    </div>

                    <div className="CompanyName_section">
                      <h5 className="mt-4">Salary Scale</h5>

                      <div className="custom-range-slider">
                        <input
                          type="range"
                          min={0}
                          max={100}
                          value={rangeValue}
                          onChange={handleRangeChange}
                        />
                        <div
                          className="custom-track"
                          style={{ width: `${rangeValue}%` }}
                        ></div>
                        <p>Value: {rangeValue}</p>
                      </div>
                    </div>

                    <div className="CompanyName_section">
                      <h5 className="mt-4">Location</h5>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                      >
                        <option selected>Desired Location</option>
                        <option value="1">Pune</option>
                        <option value="2">Mumbai</option>
                        <option value="3">Nashik</option>
                      </select>
                    </div>

                    <div className="CompanyName_section">
                      <h5 className="mt-4">Job Type</h5>
                      <div className="mt-2 form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckIndeterminateDisabled"
                        />
                        <label
                          className="form-check-label"
                          for="flexCheckIndeterminateDisabled"
                        >
                          Full Time
                        </label>
                      </div>
                      <div className="mt-2 form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckIndeterminateDisabled"
                        />
                        <label
                          className="form-check-label"
                          for="flexCheckIndeterminateDisabled"
                        >
                          Part Time
                        </label>
                      </div>
                      <div className="mt-2 form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckIndeterminateDisabled"
                        />
                        <label
                          className="form-check-label"
                          for="flexCheckIndeterminateDisabled"
                        >
                          Free Lance
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-9 col-md-12">
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-Management"
                  role="tabpanel"
                  aria-labelledby="pills-Management-tab"
                  tabIndex="0"
                >
                  <div className="row">
                    {jobData.map((job, index) => (
                      <div className="col-lg-3 col-md-6 " key={index}>
                        <div className="card card_left-sec">
                          <div className="card-body p-0">
                            <div className="card-title mt-2">
                              <img src={job.imageSource} className="Tata" />
                            </div>
                            <div className="companyName">{job.companyname}</div>
                            <div className="innnerJobCategory_below">
                              <p className="card-text card_info">{job.title}</p>
                              <p className="city_txt">{job.location}</p>
                              <div className="explre_btn_sec">
                                <button
                                  className="btn btn-2 hover-slide-up"
                                  onClick={openModal}
                                  style={{ backGround: "transparent" }}
                                >
                                  <span>Apply</span>
                                </button>
                                <PopupModal
                                  show={modalShow}
                                  onHide={() => setModalShow(false)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* mobile-view-start */}

      <section className="Featured_Category mobile-view-innerpage">
        <div className="container">
          <div className="row ">
            <div className="text_horizantal1 mb-4">
              <div className=" d-flex">
                <h5>Popular Job Categories</h5>

                <div className="image-holder1 ms-auto ">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "assets/Images/icons/filter-icon.png"
                    }
                    className="filter-icon"
                    alt="..."
                    onClick={handleShow}
                  />
                </div>
              </div>
            </div>
            <div className=" col-md-12">
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-Management"
                  role="tabpanel"
                  aria-labelledby="pills-Management-tab"
                  tabIndex="0"
                >
                  <div className="row">
                    {jobData.map((job, index) => (
                      <div className="col-lg-3 col-md-6 mt-md-3" key={index}>
                        <div className="card card_left-sec1 mb-3">
                          <div className="card-body p-0">
                            <div className="d-flex">
                              <div className="card-title">
                                <img src={job.imageSource} className="Tata" />
                              </div>
                              <div className="companyName ms-2 mt-1">
                                {job.companyname}
                              </div>
                            </div>

                            <div className="innnerJobCategory_below">
                              <p className="card-text">{job.title}</p>
                              <p className="city_txt">
                                {" "}
                                <FontAwesomeIcon
                                  icon="fa-solid fa-location-dot"
                                  className="mx-2"
                                />
                                {job.location}
                              </p>
                              <div className="explre_btn_sec text-end">
                                <button
                                  className="btn btn-2 "
                                  onClick={openModal}
                                >
                                  Apply
                                </button>
                                <PopupModal
                                  show={modalShow}
                                  onHide={() => setModalShow(false)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="showMore_btn_section justify-content-center text-center">
                    <Dropdown>
                      {!open && (
                        <Dropdown.Toggle
                          variant="primary"
                          id="dropdown-basic"
                          className="showMore_btn mb-5"
                          onClick={() => setOpen(!open)}
                          aria-controls="example-collapse-text"
                          aria-expanded={open}
                        >
                          Show More
                        </Dropdown.Toggle>
                      )}

                      <Collapse in={open}>
                        <div id="example-collapse-text">
                          <div className="row">
                            {jobData.map((job, index) => (
                              <div
                                className="col-lg-3 col-md-6 mt-md-3"
                                key={index}
                              >
                                <div className="card card_left-sec1 mb-3">
                                  <div className="card-body p-0">
                                    <div className="d-flex">
                                      <div className="card-title">
                                        <img
                                          src={job.imageSource}
                                          className="Tata"
                                        />
                                      </div>
                                      <div className="companyName ms-2 mt-1">
                                        {job.companyname}
                                      </div>
                                    </div>

                                    <div className="innnerJobCategory_below">
                                      <p className="card-text">{job.title}</p>
                                      <p className="city_txt">
                                        {" "}
                                        <FontAwesomeIcon
                                          icon="fa-solid fa-location-dot"
                                          className="mx-2"
                                        />
                                        {job.location}
                                      </p>
                                      <div className="explre_btn_sec">
                                        <button
                                          className="btn btn-2 "
                                          onClick={openModal}
                                          style={{ backGround: "transparent" }}
                                        >
                                          <span>Apply</span>
                                        </button>
                                        <PopupModal
                                          show={modalShow}
                                          onHide={() => setModalShow(false)}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </Collapse>
                      {open && (
                        <button
                          className="btn btn-2 hover-slide-up showMore_btn mb-5"
                          onClick={toggleOpen}
                        >
                          <span>Show Less</span>
                        </button>
                      )}
                    </Dropdown>
                  </div>
                </div>
              </div>
            </div>
            <Offcanvas
              show={show}
              onHide={handleClose}
              className="Featured_Category mobile-view-innerpage"
            >
              <Offcanvas.Header closeButton>
                <h3 className="job_filter_txt">Job Filters</h3>
              </Offcanvas.Header>
              <Offcanvas.Body className="p-0">
                <div className="sidebar_featured_sec">
                  <div className={sidebarClass}>
                    <div className="down_content_sidebar ">
                      <div className="">
                        <h5 className="">Job Availability</h5>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="flexCheckIndeterminateDisabled"
                          />
                          <label
                            className="form-check-label"
                            for="flexCheckIndeterminateDisabled"
                          >
                            Open
                          </label>
                        </div>
                        <div className="closedCheckbox_sec form-check mt-2">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="flexCheckIndeterminateDisabled"
                          />
                          <label
                            className="form-check-label"
                            for="flexCheckIndeterminateDisabled"
                          >
                            Closed
                          </label>
                        </div>
                      </div>
                      <div className="mt-4 CompanyName_section">
                        <h5 className="">Company Name</h5>
                        <input
                          className="CompanyName"
                          type="text"
                          placeholder="Desired Company Name"
                        />
                        <div className="line"></div>
                      </div>

                      <div className="mt-4 CompanyName_section">
                        <h5 className="">Job Sector</h5>
                        <input
                          className="CompanyName"
                          type="text"
                          placeholder="Desired Company Name"
                        />
                        <div className="line"></div>
                      </div>

                      <div className="CompanyName_section">
                        <h5 className="mt-4">Role</h5>
                        <input
                          className="CompanyName"
                          type="text"
                          placeholder="Desired Company Name"
                        />
                      </div>

                      <div className="mt-4 CompanyName_section">
                        <h5 className="">Highest Education</h5>
                        <input
                          className="CompanyName"
                          type="text"
                          placeholder="Desired Company Name"
                        />
                        <div className="line"></div>
                      </div>

                      <div className="mt-4 CompanyName_section">
                        <h5 className="">Primary Skills</h5>
                        <input
                          className="CompanyName"
                          type="text"
                          placeholder="Desired Company Name"
                        />
                        <div className="line"></div>
                      </div>

                      <div className="CompanyName_section">
                        <h5 className="mt-4">Secondary Skills</h5>
                        <select
                          className="form-select"
                          aria-label="Default select example"
                        >
                          <option selected></option>
                          <option value="1"></option>
                          <option value="2"></option>
                          <option value="3"></option>
                        </select>
                      </div>
                      <div className="CompanyName_section">
                        <h5 className="mt-4">Professional Projects</h5>
                        <select
                          className="form-select"
                          aria-label="Default select example"
                        >
                          <option selected></option>
                          <option value="1"></option>
                          <option value="2"></option>
                          <option value="3"></option>
                        </select>
                      </div>
                      <div className="CompanyName_section">
                        <h5 className="mt-4">Prof. Experience in Years</h5>
                        <select
                          className="form-select"
                          aria-label="Default select example"
                        >
                          <option selected></option>
                          <option value="1"></option>
                          <option value="2"></option>
                          <option value="3"></option>
                        </select>
                      </div>
                      <div className="CompanyName_section">
                        <h5 className="mt-4">Experience</h5>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="flexCheckIndeterminateDisabled"
                          />
                          <label
                            className="form-check-label"
                            for="flexCheckIndeterminateDisabled"
                          >
                            Expert
                          </label>
                        </div>
                        <div className="mt-2 form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="flexCheckIndeterminateDisabled"
                          />
                          <label
                            className="form-check-label"
                            for="flexCheckIndeterminateDisabled"
                          >
                            Advance
                          </label>
                        </div>
                        <div className="mt-2 form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="flexCheckIndeterminateDisabled"
                          />
                          <label
                            className="form-check-label"
                            for="flexCheckIndeterminateDisabled"
                          >
                            Proficient
                          </label>
                        </div>
                      </div>

                      <div className="CompanyName_section">
                        <h5 className="mt-4">Salary Scale</h5>

                        <div className="custom-range-slider">
                          <input
                            type="range"
                            min={0}
                            max={100}
                            value={rangeValue}
                            onChange={handleRangeChange}
                          />
                          <div
                            className="custom-track"
                            style={{ width: `${rangeValue}%` }}
                          ></div>
                          <p className="range-value">Value: {rangeValue}</p>
                        </div>
                      </div>

                      <div className="CompanyName_section">
                        <h5 className="mt-4">Location</h5>
                        <select
                          className="form-select"
                          aria-label="Default select example"
                        >
                          <option selected>Desired Location</option>
                          <option value="1">Pune</option>
                          <option value="2">Mumbai</option>
                          <option value="3">Nashik</option>
                        </select>
                      </div>

                      <div className="CompanyName_section">
                        <h5 className="mt-4">Job Type</h5>
                        <div className="mt-2 form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="flexCheckIndeterminateDisabled"
                          />
                          <label
                            className="form-check-label"
                            for="flexCheckIndeterminateDisabled"
                          >
                            Full Time
                          </label>
                        </div>
                        <div className="mt-2 form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="flexCheckIndeterminateDisabled"
                          />
                          <label
                            className="form-check-label"
                            for="flexCheckIndeterminateDisabled"
                          >
                            Part Time
                          </label>
                        </div>
                        <div className="mt-2 form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="flexCheckIndeterminateDisabled"
                          />
                          <label
                            className="form-check-label"
                            for="flexCheckIndeterminateDisabled"
                          >
                            Free Lance
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Offcanvas.Body>
            </Offcanvas>
          </div>
        </div>
      </section>
    </>
  );
};

export default InnerPage;
