import React from 'react';
import './PostedJob.css';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PostedText = () => {
    return (
        <>
            <section className="Posted-job-text">
                <div className='container'>

                    <div className="applied-job">
                        <div className="col-md-12">
                            <div className="applied-card p-md-4  p-2 mt-3">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-md-1 col-1">
                                                <div className="text-holder-applied">
                                                    <FontAwesomeIcon icon="fa-solid fa-briefcase" className="me-4" />
                                                </div>
                                            </div>
                                            <div className="col-md-11 col-11">
                                                <div className="text-holder-applied">
                                                    <h5>Job description: </h5>
                                                    <p>Urgently hiring full time in-house Graphic Designer & UI Designer.
                                                        We are looking for a Graphic Designer who will be responsible for
                                                        the User interface (UI) design of various websites and design of
                                                        banners & logos. The candidate will ensure the online user experience
                                                        is optimized for improved usability, usefulness, and exceptional
                                                        visual design.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="text-holder-applied">
                                            <div className="row">
                                                <div className="col-md-1 col-1">
                                                    <div className="text-holder-applied">
                                                        <FontAwesomeIcon icon="fa-solid fa-briefcase" />
                                                    </div>
                                                </div>
                                                <div className="col-md-11 col-11">
                                                    <div className="text-holder-applied">
                                                        <h6>Skills </h6>
                                                        <p>Photoshop, Illlustrator, Corel Draw, Adobe XD, Figma</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-holder-applied">
                                            <div className="row">
                                                <div className="col-md-1 col-1">
                                                    <div className="text-holder-applied">
                                                        <FontAwesomeIcon icon="fa-solid fa-graduation-cap" />
                                                    </div>
                                                </div>
                                                <div className="col-md-11 col-11">
                                                    <div className="text-holder-applied">
                                                        <h6> Qualification </h6>
                                                        <p>Any Graduate with Degree or Diploma Certification in Graphic
                                                            and UI Designing </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-holder-applied">
                                            <div className="row">
                                                <div className="col-md-1 col-1">
                                                    <div className="text-holder-applied">
                                                        <FontAwesomeIcon icon="fa-solid fa-comments" />
                                                    </div>
                                                </div>
                                                <div className="col-md-11 col-11">
                                                    <div className="text-holder-applied">
                                                        <h6> Languages </h6>
                                                        <p>English, Hindi, Marathi</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="applied-card p-md-4  p-2 mt-2">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="text-holder-applied">
                                            <h5>Job details:</h5>
                                            <div className="row">
                                                <div className="col-md-1 col-1">
                                                    <div className="text-holder-applied">
                                                        <FontAwesomeIcon icon="fa-solid fa-money-bill-1" />
                                                    </div>
                                                </div>
                                                <div className="col-md-11 col-11">
                                                    <div className="text-holder-applied">
                                                        <h6> Pay </h6>
                                                        <p>₹ 18,000 - ₹ 36,000 a month</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="row">
                                                <div className="col-md-1 col-1">
                                                    <div className="text-holder-applied">
                                                        <FontAwesomeIcon icon="fa-solid fa-money-bill-1" />
                                                    </div>
                                                </div>
                                                <div className="col-md-11 col-11">
                                                    <div className="text-holder-applied">
                                                        <h6>Experience required:<span>1 year</span> </h6>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-1 col-1">
                                                    <div className="text-holder-applied">
                                                        <FontAwesomeIcon icon="fa-solid fa-location-dot" />
                                                    </div>
                                                </div>
                                                <div className="col-md-11 col-11">
                                                    <h6>Shift and Schedule</h6>
                                                    <div className="text-holder-applied">
                                                        <p> Location:Bavdan, Pune</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-1 col-1">
                                                    <div className="text-holder-applied">

                                                    </div>
                                                </div>
                                                <div className="col-md-11 col-11">
                                                    <div className="text-holder-applied">
                                                        <p>English, Hindi, Marathi</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-1 col-1">
                                                    <div className="text-holder-applied">
                                                        <FontAwesomeIcon icon="fa-solid fa-briefcase" />
                                                    </div>
                                                </div>
                                                <div className="col-md-11 col-11">
                                                    <div className="text-holder-applied">
                                                        <h6>Number of Vacancy</h6>
                                                        <p>1 </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-md-1 col-1">
                                                <div className="text-holder-applied">
                                                    <FontAwesomeIcon icon="fa-solid fa-briefcase" />
                                                </div>
                                            </div>
                                            <div className="col-md-11 col-11">
                                                <div className="text-holder-applied">
                                                    <h6> Job type </h6>
                                                    <p>Full Time</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-1 col-1">
                                                <div className="text-holder-applied">
                                                    <FontAwesomeIcon icon="fa-solid fa-briefcase" />
                                                </div>
                                            </div>
                                            <div className="col-md-11 col-11">
                                                <div className="text-holder-applied">
                                                    <h6>Number of Vacancy</h6>
                                                    <p>1 </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-1 col-1">
                                                <div className="text-holder-applied">
                                                    <FontAwesomeIcon icon="fa-solid fa-briefcase" />
                                                </div>
                                            </div>
                                            <div className="col-md-11 col-11">
                                                <div className="text-holder-applied">
                                                    <h6>Secondary Skills: Drawing & Visualization</h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-1 col-1">
                                                <div className="text-holder-applied">
                                                    <FontAwesomeIcon icon="fa-solid fa-briefcase" />
                                                </div>
                                            </div>
                                            <div className="col-md-11 col-11">
                                                <div className="text-holder-applied">
                                                    <h6> Application Deadline Date :<span>20/10/2023</span> </h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="applied-card    mt-2">
                                <div>
                                    <h6>Roles and responsibilities: </h6>
                                    <ul className='responsibility-text'>
                                        <li><FontAwesomeIcon icon="fa-solid fa-circle-check" className="watch-icon me-2" /> Expertise in designing websites using Photoshop and Illustrator.</li>
                                        <li><FontAwesomeIcon icon="fa-solid fa-circle-check" className="watch-icon me-2" />Visualize and design user interface, wireframes, mock-ups, task flows for web applications.</li>
                                        <li><FontAwesomeIcon icon="fa-solid fa-circle-check" className="watch-icon me-2" />Create graphic assets like icons, buttons, widgets, landing pages for use in user interfaces.</li>
                                        <li><FontAwesomeIcon icon="fa-solid fa-circle-check" className="watch-icon me-2" />Designing Web Layouts, Logos, Banners, Infographics.</li>
                                        <li><FontAwesomeIcon icon="fa-solid fa-circle-check" className="watch-icon me-2" />Conceptualize ideas that bring simplicity and delight to the customer.</li>
                                        <li><FontAwesomeIcon icon="fa-solid fa-circle-check" className="watch-icon me-2" />Maintain standards and processes for producing deliverables.</li>
                                        <li><FontAwesomeIcon icon="fa-solid fa-circle-check" className="watch-icon me-2" />Gather feedback from teams and follow an iterative process.</li>
                                    </ul>
                                </div>
                                <div>
                                    <p>Skill Required </p>
                                    <ul className='responsibility-text'>
                                        <li><FontAwesomeIcon icon="fa-solid fa-circle-check" className="watch-icon me-2" />Expertise in designing websites using Photoshop and Illustrator.</li>
                                        <li><FontAwesomeIcon icon="fa-solid fa-circle-check" className="watch-icon me-2" /> Visualize and design user interface, wireframes, mock-ups, task flows for web applications.</li>
                                        <li><FontAwesomeIcon icon="fa-solid fa-circle-check" className="watch-icon me-2" /> Create graphic assets like icons, buttons, widgets, landing pages for use in user interfaces.</li>
                                        <li><FontAwesomeIcon icon="fa-solid fa-circle-check" className="watch-icon me-2" /> Designing Web Layouts, Logos, Banners, Infographics.</li>
                                        <li><FontAwesomeIcon icon="fa-solid fa-circle-check" className="watch-icon me-2" /> Conceptualize ideas that bring simplicity and delight to the customer.</li>
                                        <li><FontAwesomeIcon icon="fa-solid fa-circle-check" className="watch-icon me-2" /> Maintain standards and processes for producing deliverables.</li>
                                        <li><FontAwesomeIcon icon="fa-solid fa-circle-check" className="watch-icon me-2" /> Gather feedback from teams and follow an iterative process.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="applied-card p-md-4 p-2 mt-2 mb-5 ">
                                <div>
                                    <h5>Contact:</h5>
                                    <h6>Deepti Deshmukh (HR Manager)<br />Profycyma Solutions Pvt. Ltd.</h6>
                                    <p>Recruiter Contact Number: +91 9876543210</p>
                                    <p>Recruiter Email: hiclousia@gmail.com</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='text-center mt-5 mb-5'>
                        <Link to='/dashboardmain'><button className='show-more'>OK </button></Link>
                    </div>

                </div>
            </section>
        </>
    )
}

export default PostedText;