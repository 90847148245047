import React, { useEffect, useState } from "react";
import "./Banner.css";
import { Link, useNavigate } from "react-router-dom";
import Women from "./Animation/WomenLottie/Women";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faMapMarker,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { useContext } from "react";
import { Context } from "../../../../utils/context";
import Select from "react-select";
import Autosuggest from "react-autosuggest";
const Banner = () => {
  const navigate = useNavigate();
  const [selectedItems, setSelectedItems] = useState([]);
  const handleClick = () => {};
  const {
    getData,
    postData,
    recruiterData,
    setRecruiterData,
    Select2Skills,
    Select2Data,
    citySearch,
    setCitySearch,
  } = useContext(Context);
  const [selectCity, setSelectCity] = useState();
  const handleChange = async (e) => {
    if (e?.name === "city_id") {
      setSelectCity(e);
    }
  };
  const [industry_id, setSelectedIndustryOption] = useState("");
  const [suggestionsList, setSuggestionsList] = useState([]);

  const getSuggestions = async (inputValue, list) => {
    const inputValueLowerCase = inputValue.trim().toLowerCase();

    if (list === "industry_id") {
      const res = await getData(
        `/without-login/master/all-industry?name=${inputValueLowerCase}`
      );
      if (res?.success) {
        return res.data?.filter((suggestion) =>
          suggestion?.name.toLowerCase().includes(inputValueLowerCase)
        );
      }
    }
  };

  const onSuggestionsFetchRequested = async ({ value }, list) => {
    const suggestions = await getSuggestions(value, list);
    setSuggestionsList(suggestions);
  };

  const onSuggestionsClearRequested = () => {
    setSuggestionsList([]);
  };

  const onChange = (event, { newValue }) => {
    if (event.target.id === "industry_id") {
      setSelectedIndustryOption(newValue);
    }
  };

  const getSuggestionValue = (suggestion) => suggestion?.name;

  const renderSuggestion = (suggestion, id) => (
    <div
      id={id}
      for={suggestion?.id}
      style={{
        border: "1px solid #ccc",
        padding: "10px",
        borderRadius: "5px",
        margin: "5px",
        backgroundColor: "#f9f9f9",
      }}
    >
      {suggestion?.name}
    </div>
  );

  const inputProps = {
    onChange,
    style: {
      borderRadius: "5px",
      paddingLeft: "26px",
      backgroundColor: "#FBFBFB",
      border: "0px",
    },
  };

  const [cities, setCities] = useState();
  const getMasters = async () => {
    {
      const res = await getData(`/without-login/master/all-cities`);
      if (res?.success) {
        setCities(await Select2Data(res.data, "city_id"));
      }
    }
  };

  const handleSearch = async () => {
    setCitySearch(selectCity);
    navigate(`/jobexp/${industry_id}`);
  };

  useEffect(() => {
    getMasters();
  }, []);
  return (
    <>
      <section className="Banner">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-md-7 col-8 ">
              <div className="left-sec_banner">
                <h5 className="mt-lg-0 mt-md-0 mt-3 heading-one">
                  Find Jobs, Employment & Career Opportunities
                </h5>
                <div className="">
                  <span className="banner_heading_2">Drop a Portfolio &</span>
                  <br />
                  <span className="banner-heading-3">
                    Get Your Desired Job!
                  </span>
                </div>
                <div className="reach_text mb-lg-0 mb-md-0 mb-3">
                  <b>Reach 350 Million+</b> job seekers with a job posting on
                  Hiclousia
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-5 col-4 pt-3">
              <Women />
            </div>
            <div className="col-xxl-8 col-xl-8 col-lg-10 col-md-10 col-12">
              <div className="row search_section mb-4">
                <div className="col-lg-12 ">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5">
                          <div className="btn-group searchJobs_section pe-0">
                            <FontAwesomeIcon
                              icon={faSearch}
                              className="search_icon_banner"
                            />
                            <Autosuggest
                              id="industry_id"
                              suggestions={suggestionsList}
                              onSuggestionsFetchRequested={(event) =>
                                onSuggestionsFetchRequested(
                                  event,
                                  "industry_id"
                                )
                              }
                              onSuggestionsClearRequested={
                                onSuggestionsClearRequested
                              }
                              getSuggestionValue={getSuggestionValue}
                              renderSuggestion={(suggestion) =>
                                renderSuggestion(suggestion, "industry_id")
                              }
                              inputProps={{
                                ...inputProps,
                                id: "industry_id",
                                value: industry_id,
                                placeholder: "Enter Industry",
                                className: "form-control search_icon_menu",
                              }}
                            />
                            {/* <input type="text" className="form-control search_icon_menu" data-bs-toggle="dropdown" aria-expanded="false" id="actionButton" placeholder="Search Jobs"></input> */}
                            {/* <ul className="dropdown-menu dropdown-menu-start" aria-labelledby="actionButton">
                              <div className="mysearch_section  p-2">
                                <div className="text_horizantal_line">
                                  <p className="talent_txt mb-0">My Search</p>
                                </div>
                                <div>
                                  <span className="content_dropdown_menu me-2">Development   <FontAwesomeIcon icon={faTimes} className="ms-3" /></span>
                                  <span className="content_dropdown_menu me-2">Banking <FontAwesomeIcon icon={faTimes} className="ms-3" /> </span>
                                  <span className="content_dropdown_menu me-2">Deign/Architecture <FontAwesomeIcon icon={faTimes} className="ms-3" /> </span>
                                </div>
                              </div>
                              <div className="mysearch_section p-2">
                                <div className="text_horizantal_line">
                                  <p className="talent_txt mb-0">Sectors</p>

                                </div>
                                <div className="mt-2">
                                  <div className="d-flex">
                                    <FontAwesomeIcon icon={["fas", "user-gear"]} className="sector_icons" />
                                    <p className="ms-2 sector_dropdown">Admin/Back Office</p>
                                  </div>

                                  <div className="d-flex">
                                    <FontAwesomeIcon icon={["fas", "bullhorn"]} className="sector_icons" />
                                    <p className="ms-2 sector_dropdown">Advertising/Communication</p>
                                  </div>

                                  <div className="d-flex">
                                    <FontAwesomeIcon icon={["fas", "building-columns"]} className="sector_icons" />
                                    <p className="ms-2 sector_dropdown">Banking</p>
                                  </div>

                                  <div className="d-flex">
                                    <FontAwesomeIcon icon={["fas", "laptop-code"]} className="sector_icons" />
                                    <p className="ms-2 sector_dropdown">Development</p>
                                  </div>

                                  <div className="d-flex">
                                    <FontAwesomeIcon icon={["fas", "laptop-code"]} className="sector_icons" />
                                    <p className="ms-2 sector_dropdown">Development</p>
                                  </div>
                                </div>
                              </div>
                            </ul> */}
                          </div>
                        </div>
                        <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-6 col-5 ps-0 ">
                          <div className="citiesDropdownBanner ">
                            <FontAwesomeIcon
                              icon="fa-solid fa-location-dot"
                              className="location_icon_banner"
                            />
                            <Select
                              classNamePrefix="custom-select"
                              name="city_id"
                              placeholder="Select City"
                              value={selectCity}
                              onChange={handleChange}
                              options={cities}
                            />
                            {/* <select className="form-select form-control" name="city_id" id="inputGroupSelect01" onChange={handleChange}>
                              <option selected>All Cities</option>
                              {cities?.map((value, index) => (
                              
                                <option value={value?.id}>{value?.label}</option>
                              ))}
                            </select> */}
                          </div>
                        </div>
                        <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-1 col-2 ps-0">
                          <div className="text-center ">
                            <button
                              className="btn  search_btn"
                              onClick={handleSearch}
                            >
                              <span>Search</span>
                              <FontAwesomeIcon
                                icon="fa-solid fa-magnifying-glass"
                                className="seerachh-iccc"
                              />{" "}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner;
