import React, { useState } from "react";
import "./Header.css";
import { Link, NavLink, useLocation } from "react-router-dom";
import MainHeader from "./MainHeader";
import JobHeader from "./JobHeader";
import RecruiterHeader from "./RecruiterHeader";
import { useEffect } from "react";
import SabKaBaap from "./SabKaBaap";

const Header = () => {
  const location = useLocation();
  const [headerText, setHeaderText] = useState(location.pathname);

  useEffect(() => {
    setHeaderText(location.pathname);
  }, []);

  // console.log(location?.pathname);

  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
  };

  const logoStyle = {
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
  };

  return (
    <>
      <SabKaBaap />
      {/* {
        location?.pathname == "/" || location?.pathname == "/talentPool" || location?.pathname == "/aboutUs" || location?.pathname == "/blogs" || location?.pathname == "/blogs/:id" || location?.pathname == "/contact" || location?.pathname == "/login-recruiter"
          || location?.pathname == "/login-job" || location?.pathname == "/signup-job" || location?.pathname == "/signup-recruiter" || location?.pathname == "/terms"
          || location?.pathname == "/getotp" || location?.pathname == "/getotp-jobseeker" ? <MainHeader /> : <></>
      }

      {
        location?.pathname == "/profile" || location?.pathname == "/user-profile" || location?.pathname == "/user-profile" || location?.pathname == "/privacy" || location?.pathname.startsWith("/blogs/") || location?.pathname == "/help" ||
          location?.pathname == "/notification-job" || location?.pathname == "/notification" || location?.pathname == "/account" || location?.pathname == "/account-2" || location?.pathname == "/account-3" || location?.pathname == "/account-4" ||
          location?.pathname == "/account-6" || location?.pathname == "/reset" || location?.pathname == "/createjobs" || location?.pathname == "/editjobs-2" || location?.pathname == "/draft-3"
          || location?.pathname == "/dashboardmain" || location?.pathname == "/searchpool" || location?.pathname == "/schedule" || location?.pathname == "/hiring" || location?.pathname == "/hiring-2" || location?.pathname == "/draft"
          || location?.pathname == "/innerPage" || location?.pathname == "/portfolio" || location?.pathname.startsWith("/buynow/") || location?.pathname == "/purchased" || location?.pathname == "/user-talent" || location?.pathname == "/viewjobs"
          || location?.pathname == "/editjobs" || location?.pathname == "/postedjobmain" || location?.pathname == "/postedjob" || location?.pathname == "/manangejobs" || location?.pathname == "/openjobs" || location?.pathname == "/draft-2"
          || location?.pathname == "/draft-3" || location?.pathname == "/editjobs" || location?.pathname == "/shortlisted-interview-portfolio" ? <RecruiterHeader /> : <></>
      }

      {
        location?.pathname == "/dashboard-job" || location?.pathname == "/appliedJob" || location?.pathname == "/apply-job" || location?.pathname == "/matched-job" || location?.pathname == "/job-description"
          || location?.pathname == "/exploreApportunity" || location?.pathname == "/desired" || location?.pathname == "/thirdtab" || location?.pathname == "/job-search" || location?.pathname == "/apply-job-2"
          || location?.pathname == "/apply-job-3" || location?.pathname == "/Portfolio-project-tab" || location?.pathname == "/my-portfolio" || location?.pathname == "/view-portfolio" || location?.pathname == "/portfolio-job-experience" || location?.pathname == "/portfolio-project-form" || location?.pathname == "/portfolio-internship-form"
          || location?.pathname == "/portfolio-freelancing-form" || location?.pathname == "/personal-detail-form" || location?.pathname == "/portfolio-education-form" || location?.pathname == "/portfolio-viewdetails-experience" || location?.pathname == "/portfolio-viewdetails-project" || location?.pathname == "/portfolio-viewdetails-internship"
          || location?.pathname == "/portfolio-skillquestion" || location?.pathname == "/Portfolio-edit-primaryskill" || location?.pathname == "/done-skill" ? <JobHeader /> : <></>
      } */}
    </>
  );
};

export default Header;
