import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "../ThirdTab/ThirdTab.css";
import FirstTabBanner from "../FirstTabBanner/FirstTabBanner";
import { Link } from "react-router-dom";
import { useContext, useState } from "react";
import Shortlisted from "../ThirdTab/Shortlisted_portfolio_modal/Shortlisted";
import OnHold from "../ThirdTab/OnHold_modal/OnHold";
import Reject from "../ThirdTab/Reject_modal/Reject";
import { Button } from "react-bootstrap";
import ViewPortfolioModal from "../ViewPortfolioModal/ViewPortfolios";
import { Context } from "../../../../utils/context";
import MyPortfolioModal from "../../../JobseekerPage/my-portfolio/my-portfolio-modal/MyPortfolioModal";
const AppliedThirdTab = ({ job, getAllData, setDisplay }) => {
  const { getData, postData } = useContext(Context);

  const [modalShow1, setModalShow1] = useState(false);
  const openModal_Shortlist = async (id) => {
    const res = await postData(`/recruiters/job/all-appyled/${id}`, {
      job_apply_status_id: 1,
    });
    if (res?.success) {
      setModalShow1(true);
      // getAllData();
      setTimeout(() => {
        setModalShow1(false);
        setDisplay();
      }, 3000);
    }
  };

  const [modalShow2, setModalShow2] = useState(false);
  const openModal_Hold = async (id) => {
    const res = await postData(`/recruiters/job/all-appyled/${id}`, {
      job_apply_status_id: 2,
    });
    if (res?.success) {
      setModalShow2(true);
      // getAllData();
      setTimeout(() => {
        setModalShow2(false);
        setDisplay();
      }, 3000);
    }
  };

  const [modalShow3, setModalShow3] = useState(false);
  const openModal_Reject = async (id) => {
    const res = await postData(`/recruiters/job/all-appyled/${id}`, {
      job_apply_status_id: 3,
    });
    if (res?.success) {
      setModalShow3(true);
      setTimeout(() => {
        setModalShow3(false);
        getAllData();
        // setDisplay();
      }, 3000);
    }
  };
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <section className="manage-job third-candii-card">
        {/* <div>
                    <FirstTabBanner />
                </div> */}

        <div className="container">
          <div className="row mt-md-3 mt-0">
            <div className="col-md-12">
              {job?.job_applyeds?.map((data, index) => (
                <div
                  key={index}
                  className="manage-job-text mt-4 p-md-4 p-2 mb-4 bg-body rounded"
                >
                  <div className="row">
                    <div className="col-lg-2 col-md-3">
                      <div className="text-holder-manage-job">
                        <p className="thirdTabinfo">Job Role:</p>
                        <h5>{job.role?.name}</h5>
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-3">
                      <div className="text-holder-manage-job">
                        <p className="thirdTabinfo">Location of Candidate:</p>
                        <h5>{data?.user?.users_detail?.city?.name}</h5>
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-3">
                      <div className="text-holder-manage-job">
                        <p className="thirdTabinfo">Candidate's Name:</p>
                        <h5>{data?.name}</h5>
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-3">
                      <div className="text-holder-manage-job">
                        <p className="thirdTabinfo">Portfolio Matched:</p>
                        <Button
                          onClick={() => setModalShow(true)}
                          className="viewPortfoliobtn"
                        >
                          <span className="me-2">{job?.portfolioMatched}</span>
                          View Portfolio
                        </Button>

                        <MyPortfolioModal
                          show={modalShow}
                          data={data?.user?.id}
                          onHide={() => setModalShow(false)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <div className="shortlisting text-lg-center flexxx-btn col-md-start">
                        <p>Shortlisting</p>
                        <button
                          className="btn shortlist-btn me-2"
                          onClick={() => openModal_Shortlist(data?.id)}
                        >
                          Shortlist
                        </button>
                        <button
                          className="btn hold-btn me-2"
                          onClick={() => openModal_Hold(data?.id)}
                        >
                          On Hold
                        </button>
                        <button
                          className="btn reject-btn"
                          onClick={() => openModal_Reject(data?.id)}
                        >
                          Reject
                        </button>
                      </div>

                      <Shortlisted
                        show={modalShow1}
                        onHide={() => setModalShow1(false)}
                      />

                      <OnHold
                        show={modalShow2}
                        onHide={() => setModalShow2(false)}
                      />

                      <Reject
                        show={modalShow3}
                        onHide={() => setModalShow3(false)}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AppliedThirdTab;
