import React, { useContext, useEffect, useRef, useState } from "react";
import "./PortfolioJobExperience.css";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import * as animationData from "./Completedlotty.json";
import * as animationDataTwo from "./Hundredper.json";

import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "@fortawesome/fontawesome-svg-core/styles.css";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Select, { components } from "react-select";
import Modal from "react-bootstrap/Modal";
import Lottie from "react-lottie";

import { Link } from "react-router-dom";
import { Context } from "../../../../utils/context";

import {
  faUpload,
  faFile,
  faXmark,
  faPlus,
  faMinus,
} from "@fortawesome/free-solid-svg-icons";

const PortFolioProjectform = () => {
  const [lgShow, setLgShow] = useState(false);

  const {
    getData,
    postData,
    recruiterData,
    jobSeekerData,
    editStatusData,
    getJobSeekerData,
    Select2Data,
    fetchSeekerData,
    Select2Skills,
    Select2Roles,
  } = useContext(Context);

  const [ftype, setFType] = useState(true);
  const handleClose = () => setLgShow(false);
  const handleShow = async (type = "ADD") => {
    if (type === "ADD") {
      await setFType(true);
      await setFormNumber({
        type_id: "",
        organization: "",
        title: "",
        project_nature: "",
        role: "",
        url: "",
        skills: [],
        publication: "",
        description: "",
        start_date: "",
        end_date: "",
        start_date_value: "",
        end_date_value: "",
        category_id: "",
        achievement_id: "",

        mentor_email: "",
        mentor: "",
        type_id_value: "",
        category_id_value: "",
        achievement_id_value: "",
        skills_value: [],
        media: undefined,
      });
      // await setSelectedArea_of_studay([]);
      await setErrors();
    } else {
      await setFType(false);
    }
    await setLgShow(true);
    await setErrors({});
  };

  const [selectedskills, setSelectedSkills] = useState([]);
  useEffect(() => {
    setFormNumber({
      ...formNumber,
      ["skills"]: selectedskills?.map((item) => item.value),
    });
  }, [selectedskills]);
  const [projectTypes, setProjectTypes] = useState();
  const [roles, setRole] = useState();
  const [roleCurrentErr, setRoleCurrentErr] = useState("");

  const [skills, setSkill] = useState();
  const [skillCurrentErr, setSkillCurrentErr] = useState("");

  const [achievements, setAchievement] = useState();
  const [companyCurrentErr, setCompanyCurrentErr] = useState("");

  const [companySectors, setCompanySectors] = useState();

  const getMasters = async () => {
    {
      const res = await getData(`/without-login/master/all-type`);
      if (res?.success) {
        const data = await Select2Data(res.data, "type_id", false);
        setProjectTypes(data);
      }
    }
    {
      const res = await getData(`/without-login/master/all-roles`);
      if (res?.success) {
        const data = await Select2Roles(res.data, "role_id", false);
        setRole(data);
      }
    }
    {
      const res = await getData(`/without-login/master/all-skills`);
      if (res?.success) {
        const data = await Select2Skills(res.data, "skill_id", false);
        setSkill(data);
      }
    }
    {
      const res = await getData("/without-login/master/all-achievemente");
      if (res?.success) {
        const data = await Select2Data(res.data, "achievement_id", false);
        setAchievement(data);
      }
    }
    {
      const res = await getData("/without-login/master/all-category");
      if (res?.success) {
        const data = await Select2Data(res.data, "category_id", false);
        setCompanySectors(data);
      }
    }
  };

  const [formNumber, setFormNumber] = useState({
    type_id: "",
    organization: "",
    title: "",
    project_nature: "",
    role: "",
    url: "",
    skills: [],
    publication: "",
    description: "",
    start_date: "",
    end_date: "",
    start_date_value: "",
    end_date_value: "",
    category_id: "",
    achievement_id: "",

    mentor_email: "",
    mentor: "",
    type_id_value: "",
    category_id_value: "",
    achievement_id_value: "",
    skills_value: [],
    media: undefined,
  });

  const handleSelectChange = (e) => {
    setFormNumber({ ...formNumber, [e.name]: e });
  };

  const handleInputChange = (e) => {
    setFormNumber({ ...formNumber, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    let errors = {};
    const regexAlfa = /^[A-Za-z]+(\s[A-Za-z]+)*$/;
    const regexEmail =
      /^([A-Za-z0-9_\-\.])+\@(?!(?:[A-Za-z0-9_\-\.]+\.)?([A-Za-z]{2,4})\.\2)([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

    if (!formNumber.type_id) {
      errors[`type_id`] = "Project Type is required";
    }

    if (!formNumber.organization) {
      errors.organization = "Project Organization is required";
    } else if (!regexAlfa.test(formNumber.organization?.trim())) {
      errors.organization = "Enter a valid data";
    }

    if (!formNumber.title) {
      errors.title = "Project Title is required";
    } else if (!regexAlfa.test(formNumber.title?.trim())) {
      errors.title = "Enter a valid data";
    }
    if (!formNumber.role) {
      errors.role = "Project Role is required";
    } else if (!regexAlfa.test(formNumber.role?.trim())) {
      errors.role = "Enter a valid data";
    }

    if (!formNumber.mentor) {
      errors.mentor = "Mentor is required";
    } else if (!regexAlfa.test(formNumber.mentor?.trim())) {
      errors.mentor = "Enter a valid data";
    }
    if (formNumber.mentor_email) {
      if (!regexEmail.test(formNumber.mentor_email)) {
        errors.mentor_email = "Enter a valid Email";
      }
    }

    if (formNumber?.skills.length === 0) {
      errors.skills = "Skills is required";
    }

    if (!formNumber.role) {
      errors.role = "Role is required";
    }

    if (!formNumber.start_date) {
      errors.start_date = "Start Date is required";
    }

    if (!formNumber.end_date) {
      errors.end_date = "End Date is required";
    } else if (
      new Date(formNumber.end_date) < new Date(formNumber.start_date)
    ) {
      errors.end_date = "End Date cannot be a past date of Start Date";
    } else if (new Date(formNumber.end_date) > new Date()) {
      errors.end_date = "End Date cannot be a future date";
    }

    return errors;
  };

  const [errors, setErrors] = useState({});

  const handleProjectSave = async () => {
    const validationErrors = validateForm();
    // console.log(validationErrors);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        const finalData = new FormData();
        if (formNumber.media != "edit") {
          finalData.append(`media`, formNumber.media);
        }
        finalData.append(`type_id`, formNumber.type_id?.value);
        finalData.append(`organization`, formNumber.organization);
        finalData.append(`step`, formNumber.step);
        finalData.append(`status`, "none");

        if (formNumber.category_id) {
          finalData.append(`category_id`, formNumber.category_id?.value);
        }

        if (formNumber.achievement_id) {
          finalData.append(`achievement_id`, formNumber.achievement_id?.value);
        }
        if (formNumber.university_type_id) {
          finalData.append(
            `university_type_id`,
            formNumber.university_type_id?.value
          );
        }
        finalData.append(`skills`, JSON.stringify(formNumber.skills));

        finalData.append(`title`, formNumber.title);
        finalData.append(`role`, formNumber.role);
        finalData.append(`url`, formNumber.url);
        finalData.append(`publication`, formNumber.publication);
        finalData.append(`description`, formNumber.description);
        finalData.append(`mentor_email`, formNumber.mentor_email);
        finalData.append(`mentor`, formNumber.mentor);
        finalData.append(`start_date`, formNumber.start_date);
        finalData.append(`end_date`, formNumber.end_date);

        // finalData.append(`company_id`, formNumber.company_id);

        let result;
        if (formNumber?.id) {
          result = await postData(
            `/jobseeker/signup/project/${formNumber?.id}`,
            finalData
          );
        } else {
          result = await postData("/jobseeker/signup/project", finalData);
        }

        if (result?.success) {
          await setFormNumber({
            type_id: "",
            organization: "",
            title: "",
            project_nature: "",
            role: "",
            url: "",
            skills: [],
            publication: "",
            description: "",
            start_date: "",
            end_date: "",
            start_date_value: "",
            end_date_value: "",
            category_id: "",
            achievement_id: "",

            mentor_email: "",
            mentor: "",
            type_id_value: "",
            category_id_value: "",
            achievement_id_value: "",
            skills_value: [],
            media: undefined,
          });
          handleClose();
          setErrors("");
          getProjects();
        }
      } catch (error) {}
    }
  };

  const handleDelete = async (id) => {
    var data = "";
    if (projectData?.length === 1) {
      var data = {
        step: 8,
        status: "none",
      };
    } else {
      var data = {
        step: 8,
        status: "none",
      };
    }
    const res = await editStatusData(`/jobseeker/signup/project/${id}`, data);
    await getProjects();
  };

  const getEditData = async (id) => {
    setFType(false);
    const res = await getData(`/jobseeker/signup/project/${id}`);
    // console.log(res);
    const data = res.data;
    setSelectedSkills(
      data?.jobseeker_projects_skills?.map((d) => ({
        name: "skills",
        label: d?.skill?.name + " - " + d?.skill?.skill_type?.name,
        value: d?.skill_id,
      }))
    );
    setFormNumber({
      ...formNumber,
      id: data?.id,
      type_id: {
        value: data?.type?.id,
        label: data?.type?.name,
        name: "type_id",
      },
      url: data?.url,
      role: data?.role,
      title: data?.title,
      publication: data?.publication,
      media: "edit",
      organization: data?.organization,
      description: data?.description,
      start_date: data?.start_date,
      end_date: data?.end_date,
      job_nature_id: {
        value: data?.job_nature?.id,
        label: data?.job_nature?.name,
        name: "job_nature_id",
      },
      location_id: {
        value: data?.city?.id,
        label: data?.city?.name,
        name: "location_id",
      },
      mentor: data?.mentor,
      mentor_email: data?.mentor_email,
      achievement_id: data?.achievement_id
        ? {
            name: "achievement_id",
            label: data?.achievement?.name,
            value: data?.achievement_id,
          }
        : "",
      category_id: data?.category_id
        ? {
            name: "category_id",
            label: data?.category?.name,
            value: data?.category_id,
          }
        : "",
      skills: (data?.jobseeker_projects_skills || []).map(
        (skill, index) => skill?.id
      ),
    });
    handleShow("EDIT");
  };

  const [projectData, setProjectData] = useState();

  const [status, setStatus] = useState("none");
  const getProjects = async () => {
    const res = await getData(`/jobseeker/signup/project`);
    if (res?.success) {
      // console.log(res.data, "project");
      setProjectData(res.data);
      if (res?.data.length !== 0) {
        setStatus("none");
      } else {
        setStatus("none");
      }
    }
  };
  useEffect(() => {
    getMasters();
    fetchSeekerData();
    getProjects();
  }, []);

  const errorStyle = {
    color: "red",
    marginLeft: "5px",
    fontSize: "11px",
  };

  return (
    <>
      <section className="portfolio-experience">
        <div className="container">
          <div className="row">
            <div className="heading-holder mb-3 text-center">
              <h4>My Portfolio</h4>
            </div>
          </div>

          <div className="black-header">
            <div className="row">
              <div className="col-md-4 col-4">
                <Link to="/dashboard-job">
                  <div className="text-holder pt-2 ms-3">
                    <p>Back</p>
                  </div>
                </Link>
              </div>
              <div className="col-md-4 col-4">
                <div className="text-holder text-center pt-2">
                  <h5>Projects</h5>
                </div>
              </div>
              <div className="col-md-4 col-4">
                <Link onClick={() => setLgShow(true)}>
                  <div className="text-holder text-end pt-2 me-3">
                    <p>Add</p>
                  </div>
                </Link>
              </div>
            </div>
          </div>
          {/* static card */}
          <div className="main-design-tab p-3">
            <Row className="mt-4">
              {projectData?.map((value, index) => (
                <Col className="col-md-5 col-lg-3 col-xl-3 col-xxl-3 col-12 mt-2">
                  <div className="msc-box">
                    <div className="tabs-btn p-2">
                      <div className="d-flex justify-content-end">
                        <FontAwesomeIcon
                          icon="fa-solid fa-pen-to-square"
                          className="me-3"
                          style={{ cursor: "pointer" }}
                          onClick={() => getEditData(value?.id)}
                        />
                        <p>
                          <FontAwesomeIcon
                            icon={faXmark}
                            className="close-icon"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleDelete(value?.id)}
                          />
                        </p>
                      </div>
                      <div className="info">
                        <p>Title : {value?.title}</p>
                        <p>Organization: {value?.organization}</p>
                        <p>Mentor: {value?.mentor}</p>
                        <p>Start Date: {value?.start_date}</p>
                        <p>End Date: {value?.end_date}</p>
                        <p>Type : {value?.type?.name}</p>
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
          <Modal
            size="lg"
            show={lgShow}
            onHide={() => setLgShow(false)}
            className="experience-modal"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Add Project</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ maxHeight: "60vh", overflowY: "auto" }}>
              <div className="form-bg">
                <div className="container">
                  <p className="title">Add Project</p>

                  <Form>
                    {/* {formNumber.r_id} */}
                    <Row>
                      <div className="col-md-6 col-sm-12 mb-md-3 mb-0">
                        <Form.Label>
                          Project Type <span className="text-danger">*</span>
                        </Form.Label>
                        <Select
                          placeholder="Enter Project Type"
                          value={formNumber.type_id}
                          options={projectTypes}
                          name="type_id"
                          onChange={handleSelectChange}
                          isSearchable={true}
                        />
                        {errors[`type_id`] && (
                          <div style={errorStyle}>{errors[`type_id`]}</div>
                        )}
                      </div>
                    </Row>

                    <Row>
                      <div className="col-md-6 col-sm-12 mb-md-3 mb-0">
                        <Form.Label>
                          Project Organization{" "}
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="organization"
                          placeholder="Enter Institute / Company Name"
                          value={formNumber.organization}
                          onChange={handleInputChange}
                        />{" "}
                        {errors[`organization`] && (
                          <div style={errorStyle}>{errors[`organization`]}</div>
                        )}
                      </div>
                      <div className="col-md-6 col-sm-12 mb-md-3 mb-0">
                        <Form.Label>
                          Project Title <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="title"
                          placeholder="Enter Project Title"
                          value={formNumber.title}
                          onChange={handleInputChange}
                        />
                        {errors[`title`] && (
                          <div style={errorStyle}>{errors[`title`]}</div>
                        )}
                      </div>
                      {/* <div className="col-md-6 col-sm-12 mb-md-3 mb-0">
                  <Form.Label>My Position</Form.Label>
                  <Form.Control type="text" placeholder="Enter Position"  value={formNumber.my} onChange={(event) => handleInputChange(event,  'my')} />
                </div> */}
                    </Row>
                    <Row>
                      <div className="col-md-6 col-sm-12 mb-md-3 mb-0">
                        <Form.Label>Role</Form.Label>{" "}
                        <span className="text-danger">*</span>
                        <Form.Control
                          type="text"
                          name="role"
                          placeholder="Your Role in Project"
                          value={formNumber.role}
                          onChange={handleInputChange}
                        />
                        {errors[`role`] && (
                          <div style={errorStyle}>{errors[`role`]}</div>
                        )}
                      </div>
                    </Row>
                    <Row>
                      <div className="col-md-6 col-sm-12 mb-md-3 mb-0">
                        <Form.Label>
                          Skill Used: <span className="text-danger">*</span>
                        </Form.Label>
                        <Select
                          placeholder="Select Skills"
                          className="select-line"
                          isMulti
                          name="skills"
                          value={selectedskills}
                          onChange={(e) => {
                            setSelectedSkills(e);
                          }}
                          options={skills}
                        />{" "}
                        {errors[`skills`] && (
                          <div style={errorStyle}>{errors[`skills`]}</div>
                        )}
                      </div>

                      <div className="col-md-6 col-sm-12 mb-md-3 mb-0">
                        <Form.Label>Project Url</Form.Label>
                        <Form.Control
                          type="text"
                          name="url"
                          placeholder="Project Url"
                          value={formNumber.url}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="col-md-6 col-sm-12 mb-md-3 mb-0">
                        <Form.Label>Publications</Form.Label>
                        <Form.Control
                          type="text"
                          name="publication"
                          placeholder="Publications"
                          value={formNumber.publication}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="col-md-6 col-sm-12 mb-md-3 mb-0">
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                          type="text"
                          name="description"
                          placeholder="Description"
                          value={formNumber.description}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="col-md-6 col-sm-6 mb-md-3 mb-0">
                        <Row>
                          <Col>
                            <Form.Label>
                              Time Period
                              <sup className="bracket-font px-md-1 px-5">
                                (from)
                              </sup>{" "}
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              type="date"
                              placeholder="End Date"
                              name="start_date"
                              value={formNumber?.start_date}
                              onChange={handleInputChange}
                            />{" "}
                            {errors[`start_date`] && (
                              <div style={errorStyle}>
                                {errors[`start_date`]}
                              </div>
                            )}
                          </Col>
                          <Col>
                            <Form.Label>
                              Time Period
                              <sup className="bracket-font px-md-1 px-5">
                                (to)
                              </sup>{" "}
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              type="date"
                              placeholder="End Date"
                              name="end_date"
                              value={formNumber?.end_date}
                              onChange={handleInputChange}
                            />{" "}
                            {errors[`end_date`] && (
                              <div style={errorStyle}>{errors[`end_date`]}</div>
                            )}
                          </Col>
                        </Row>
                      </div>
                      <div className="col-md-6 col-sm-12 mb-md-3 mb-0">
                        <Form.Label>Mentor Email</Form.Label>
                        <Form.Control
                          type="text"
                          name="mentor_email"
                          placeholder="Mentor Email"
                          value={formNumber.mentor_email}
                          onChange={handleInputChange}
                        />
                        {errors[`mentor_email`] && (
                          <div style={errorStyle}>{errors[`mentor_email`]}</div>
                        )}
                      </div>
                      <div className="col-md-6 col-sm-12 mb-md-3 mb-0">
                        <Form.Label>
                          Mentor/Guide Name{" "}
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="mentor"
                          placeholder="Mentor/Guide Name"
                          value={formNumber.mentor}
                          onChange={handleInputChange}
                        />{" "}
                        {errors[`mentor`] && (
                          <div style={errorStyle}>{errors[`mentor`]}</div>
                        )}
                      </div>
                      {/* <div className="col-md-6 col-sm-12 mb-md-3 mb-0">
                  <Form.Label>Company Name</Form.Label>
                  <Form.Control type="text" placeholder="Company Name"  value={formNumber.com} onChange={(event) => handleInputChange(event,  'com')}/>
                </div> */}
                      <div className="col-md-6 col-sm-12 mb-md-3 mb-0">
                        <Form.Label>Category</Form.Label>
                        <Select
                          placeholder="Enter Category"
                          name="category_id"
                          value={formNumber.category_id}
                          onChange={handleSelectChange}
                          options={companySectors}
                          isSearchable={true}
                        />
                      </div>

                      <div className="col-md-6 col-sm-12 mb-md-3 mb-0">
                        <Form.Label>Achievements</Form.Label>
                        <Select
                          placeholder="Enter Achievements"
                          name="achievement_id"
                          value={formNumber.achievement_id}
                          onChange={handleSelectChange}
                          options={achievements}
                          isSearchable={true}
                        />
                      </div>

                      <div className="col-md-6 col-sm-12 mb-md-3 mb-0">
                        <div>
                          <p className="upload-text m-0">Upload Media</p>
                          <input
                            className="uplode-file"
                            type="file"
                            accept="application/pdf"
                            name="media"
                            id={"currentMedia"}
                            onChange={async (e) => {
                              await setFormNumber({
                                ...formNumber,
                                [e.target.name]: e.target.files[0],
                              });
                            }}
                          />
                          {/* <button
                          type="button"
                          className=" upload-experience"
                          onClick={() =>
                            handleUploadButtonClick("currentMedia" + index)
                          }
                        >
                          Upload{" "}
                          <FontAwesomeIcon icon={faUpload} className="px-3" />
                        </button> */}
                          {/* <span>{formNumber?.name}</span> */}
                          {/* <p className="bracket-font mb-0">
                            ( Max file size 1 MB )
                          </p> */}
                        </div>
                      </div>
                    </Row>
                    <hr />
                  </Form>

                  <div className="lower-buttons mt-4 d-flex">
                    {/* <Button
                      className="remove-btn me-2                  "
                      onClick={handleProjectSave}
                    >
                      Save <FontAwesomeIcon className="px-2" />{" "}
                    </Button> */}
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer className="mx-auto">
              <Button
                variant="secondary"
                className="blue-btn-class"
                onClick={handleClose}
              >
                Close
              </Button>
              <Button
                variant="primary"
                className="blue-btn-class "
                onClick={handleProjectSave}
              >
                {ftype ? "Add Project" : "Edit Project"}
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </section>
    </>
  );
};

export default PortFolioProjectform;
