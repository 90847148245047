import React, { useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Link } from "react-router-dom";
import "react-tabs/style/react-tabs.css";
import "../FirstTab/FirstTab.css";

import { useContext } from "react";
import { Context } from "../../../../utils/context";
import CreateJobModal from "./CreateJobModal/CreateJobModal";

const FirstTab = () => {
  const [selectedOption, setSelectedOption] = useState("");
  const [modalShow, setModalShow] = React.useState(false);
  const handleDropdownChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const remainingDays = (endDateString) => {
    // Calculate days remaining
    const endDate = new Date(endDateString);
    const today = new Date();

    // Calculate the difference in milliseconds
    const differenceInMilliseconds = endDate - today;

    // Convert milliseconds to days
    const days = Math.ceil(differenceInMilliseconds / (1000 * 60 * 60 * 24));

    // Update state
    return days;
  };

  const { getData, imageUrl } = useContext(Context);

  const [talentPools, setTalentPool] = useState();

  const getTalentPool = async () => {
    const res = await getData("/recruiters/talent-pool/active-talent-pool");
    if (res?.success) {
      // console.log(res.data, "talentPools");
      setTalentPool(res.data);
    }
  };

  const [activePool, setActivePool] = useState();
  const modalHandle = async (index, subIndex) => {
    // console.log(talentPools[index]?.pool_subscribers[subIndex]);
    setActivePool(talentPools[index]?.pool_subscribers[subIndex]);
  };

  useEffect(() => {
    getTalentPool();
  }, []);

  return (
    <>
      <section className="first-tab">
        <div className="sec-title mt-md-5 ">
          <h5 className="title-talent">All Active Talent Pools</h5>
        </div>
        <div className="container">
          <div className="row">
            <div className="sub-tab-dashboard">
              <div className="row mt-md-5 mt-2">
                <div className="col-lg-10 col-md-12 mx-auto">
                  <Tabs>
                    <TabList className="row mx-auto justify-content-center g-0 talpoltab">
                      {talentPools?.map((value, index) => (
                        <Tab className="col-lg-3 col-md-4  col-4 text-center">
                          {value?.name}
                        </Tab>
                      ))}
                    </TabList>
                    {talentPools?.map((value, index) => (
                      <TabPanel>
                        <div className="row">
                          {value?.pool_subscribers == 0 ? (
                            <h6
                              className="mt-5 text-danger"
                              style={{
                                textAlign: "center",
                              }}
                            >
                              No Active Plans For this Category
                            </h6>
                          ) : (
                            value?.pool_subscribers?.map((value1, index1) => (
                              <div className="col-md-4">
                                <div>
                                  <p className="silver-text">
                                    {value1?.talent_pool_subcategory?.name}
                                  </p>
                                </div>
                                <div
                                  className={
                                    value1?.deletedAt === null
                                      ? "card-text-holder"
                                      : "card-text-holder-exp"
                                  }
                                >
                                  <b className="silver-text-mob">
                                    {value1?.talent_pool_subcategory?.name}
                                  </b>
                                  <div className="talent-poolflex mt-3">
                                    <p>
                                      <b>Job Posted </b> :{" "}
                                      {value1?.total_jobs - value1?.no_of_jobs}/
                                      {value1?.total_jobs}
                                    </p>
                                    {/* <p>
                                      Days Remaining :
                                      {remainingDays(value1?.end_date)}
                                    </p> */}
                                    {/* <p>Timer : 00:00</p> */}
                                    <p>
                                      <b>Company Name </b>:
                                      {value1?.company?.name}
                                    </p>
                                    {/* <p>
                                      <b>Sector </b>:{value1?.sector?.name}
                                    </p> */}
                                    <p>
                                      <b>City </b>:{value1?.city?.name}
                                    </p>
                                    <p>
                                      <b>Duration </b>:{value1?.duration}{" "}
                                      {value1?.duration == 1 ? "Day" : "Days"}
                                    </p>
                                  </div>
                                  {value1?.deletedAt === null ? (
                                    <>
                                      {value1?.no_of_jobs == 0 ? (
                                        <button className="btn create-job-btn ">
                                          Exceeded Job Post Limit
                                        </button>
                                      ) : (
                                        <button
                                          className="btn create-job-btn "
                                          onClick={() => {
                                            setModalShow(true);
                                            modalHandle(index, index1);
                                          }}
                                        >
                                          Create Job
                                        </button>
                                      )}
                                    </>
                                  ) : (
                                    <button className="btn create-job-btn-exp ">
                                      Pool Has Been Expired
                                    </button>
                                  )}
                                </div>
                              </div>
                            ))
                          )}

                          <CreateJobModal
                            show={modalShow}
                            setModalShow={setModalShow}
                            activePool={activePool}
                            getTalentPool={getTalentPool}
                          />
                          {/* <div className="col-md-4">
                                                    <div>
                                                        <p className="silver-text">Silver S3</p>
                                                    </div>
                                                    <div className="card-text-holder">
                                                        <p  className="silver-text-mob">Silver S3</p>
                                                        <div className="talent-poolflex">
                                                            <p>Job Posted : 1/1</p>
                                                            <p>Days Remaining : 25</p>
                                                            <p>Timer : 00:00</p>
                                                        </div>
                                                        <Link to='/createjobs'><button className="btn create-job-btn ">Create Job</button></Link>
                                                    </div>
                                                </div>
                                                 */}
                        </div>
                      </TabPanel>
                    ))}
                    {/* <TabPanel>
                                            <div className="row" >
                                                <div className="col-md-4">
                                                    <div>
                                                        <p className="silver-text">Gold G1</p>
                                                    </div>
                                                    <div className="card-text-holder">
                                                        <p  className="silver-text-mob">Gold G1</p>
                                                        <div className="talent-poolflex">
                                                            <p>Job Posted : 1/1</p>
                                                            <p>Days Remaining : 25</p>
                                                            <p>Timer : 00:00</p>
                                                        </div>
                                                        <Link to='/createjobs'><button className="btn create-job-btn ">Create Job</button></Link>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div>
                                                        <p className="silver-text">Gold G2</p>
                                                    </div>
                                                    <div className="card-text-holder">
                                                        <p  className="silver-text-mob">Gold G2</p>
                                                        <div className="talent-poolflex">
                                                            <p>Job Posted : 1/1</p>
                                                            <p>Days Remaining : 25</p>
                                                            <p>Timer : 00:00</p>
                                                        </div>
                                                        <Link to='/createjobs'><button className="btn create-job-btn ">Create Job</button></Link>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div>
                                                        <p className="silver-text">Gold G10</p>
                                                    </div>
                                                    <div className="card-text-holder">
                                                        <p  className="silver-text-mob">Gold G10</p>
                                                        <div className="talent-poolflex">
                                                            <p>Job Posted : 1/1</p>
                                                            <p>Days Remaining : 25</p>
                                                            <p>Timer : 00:00</p>
                                                        </div>
                                                        <Link to='/createjobs'><button className="btn create-job-btn ">Create Job</button></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPanel>
                                        <TabPanel>
                                            <div className="row" >
                                                <div className="col-md-4">
                                                    <div>
                                                        <p className="silver-text">Platinum P1</p>
                                                    </div>
                                                    <div className="card-text-holder">
                                                        <p  className="silver-text-mob">Platinum P1</p>
                                                        <div className="talent-poolflex">
                                                            <p>Job Posted : 1/1</p>
                                                            <p>Days Remaining : 25</p>
                                                            <p>Timer : 00:00</p>
                                                        </div>
                                                        <Link to='/createjobs'><button className="btn create-job-btn ">Create Job</button></Link>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div>
                                                        <p className="silver-text">Platinum P3</p>
                                                    </div>
                                                    <div className="card-text-holder">
                                                        <p  className="silver-text-mob">Platinum P3</p>
                                                        <div className="talent-poolflex">
                                                            <p>Job Posted : 1/1</p>
                                                            <p>Days Remaining : 25</p>
                                                            <p>Timer : 00:00</p>
                                                        </div>
                                                        <Link to='/createjobs'><button className="btn create-job-btn ">Create Job</button></Link>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div>
                                                        <p className="silver-text">Platinum P10</p>
                                                    </div>
                                                    <div className="card-text-holder">
                                                        <p  className="silver-text-mob">Platinum P10</p>
                                                        <div className="talent-poolflex">
                                                            <p>Job Posted : 1/1</p>
                                                            <p>Days Remaining : 25</p>
                                                            <p>Timer : 00:00</p>
                                                        </div>
                                                        <Link to='/createjobs'><button className="btn create-job-btn ">Create Job</button></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPanel> */}
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FirstTab;
