import React, { useState } from "react";
import "./UpdatedForm.css";

const ProgressBar = ({ currentStep, totalSteps, handlePrevious }) => {
  return (
    <div className="progress-container ">
      {[...Array(totalSteps)].map((_, index) => (
        <div
          key={index}
          className={`step ${index < currentStep ? "completed" : ""}`}
        >
          {index > 0 && (
            <div className={`line ${index < currentStep ? "completed" : ""}`} />
          )}
        </div>
      ))}
    </div>
    
  );
};

export default ProgressBar;
