// RoundedProgressBar.js
import React from "react";
import "./UpdatedForm.css";

const RoundedBar = ({ percentage, color, borderColor }) => {
  return (
    <div className="circular-progress-bar-container">
      <div className="circular-progress-bar" style={{ borderColor: borderColor }}>
        <div className="progress-bar" style={{ backgroundColor: color, transform: `rotate(${percentage * 3.6}deg)` }}></div>
        <span className="percentage-label">{percentage}%</span>
      </div>
    </div>
  );
};

export default RoundedBar;
