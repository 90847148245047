import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import Col from "react-bootstrap/Col";

import Row from "react-bootstrap/Row";

import { useContext } from "react";
import { Context } from "../../../../utils/context";

function Step1({ handleNext }) {
  const { recruiterData, setRecruiterData } = useContext(Context);

  const [nameErr, setNameErr] = useState("");
  const [lastNameErr, setlastNameErr] = useState("");

  const handleValidation = async () => {
    // console.log(recruiterData, "recruiterData");
    var firstname = document.getElementById("firstname").value;
    var lastname = document.getElementById("lastname").value;

    // Initialize an array to store error messages
    var errors = [];

    if (firstname.trim() === "") {
      setNameErr("First Name is required");
      errors.push("First Name is required");
    } else if (!/^[a-zA-Z ]+$/.test(firstname)) {
      setNameErr("First Name should contain only letters and spaces");
      errors.push("First Name should contain only letters and spaces");
    }

    if (lastname.trim() === "") {
      setlastNameErr("Last Name is required");
      errors.push("Last Name is required");
    } else if (!/^[a-zA-Z ]+$/.test(lastname)) {
      setlastNameErr("Last Name should contain only letters and spaces");
      errors.push("Last Name should contain only letters and spaces");
    }

    if (errors.length === 0) {
      recruiterData.first_name = firstname;
      recruiterData.last_name = lastname;
      handleNext();
    }
  };

  const handleTerms = (element) => {
    if (element.target.checked) {
      recruiterData.policy = true;
    } else {
      recruiterData.policy = false;
    }
  };
  return (
    <>
      <fieldset>
        <div className="form-card StepForm-recruiter">
          <div className="row">
            <div className="col-7">
              <h2>
                <span className="blue-line"></span>{" "}
                <span className="fs-title">Step1</span>
              </h2>
            </div>
          </div>
          <Form>
            <Row className="mb-3">
              <div className="col-md-6">
                <Form.Group as={Col} controlId="formGridCity">
                  <Form.Label>
                    First Name <span className="text-danger">*</span>{" "}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter First Name"
                    id="firstname"
                    defaultValue={recruiterData && recruiterData?.first_name}
                    onKeyDown={(event) => {
                      if (!/[A-Z-a-z ]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={() => setNameErr("")}
                  />
                  <span
                    className="text text-danger "
                    style={{
                      textAlign: "center",
                    }}
                  >
                    {nameErr && nameErr}
                  </span>
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group as={Col} controlId="formGridState">
                  <Form.Label>
                    Last Name <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Last Name"
                    id="lastname"
                    defaultValue={recruiterData && recruiterData?.last_name}
                    onKeyDown={(event) => {
                      if (!/[A-Z-a-z ]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={() => setlastNameErr("")}
                  />
                  <span
                    className="text text-danger "
                    style={{
                      textAlign: "center",
                    }}
                  >
                    {lastNameErr && lastNameErr}
                  </span>
                </Form.Group>
              </div>
            </Row>
          </Form>
          <div className="row step1_nameField mt-4">
            <div className="mobile-view-button">
              <button
                type="button"
                name="next"
                className="next action-button contin-btn"
                onClick={handleValidation}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </fieldset>
      <div className="row  mb-4 footer-sec">
        <div className="col-md-10 col-12">
          <div className="check-row text-start">
            <span>
              <input
                type="checkbox"
                name="group1"
                className="me-2"
                onChange={handleTerms}
                checked={recruiterData?.policy}
              />
            </span>
            <span className=" by-text ">By signing up, I agree to the</span>
            <span className="px-1 ">
              <Link to="/terms" className="term-btn">
                Term and Conditions
              </Link>
            </span>
            <br />
          </div>
          <div className="login-row text-start">
            <span className="">Already an User?</span>
            <span className="px-1 ">
              <Link to="/other-page" className="login-btn">
                log In
              </Link>
            </span>
            <br />
            <span className="">Not a Recruiter ?</span>
            <span className="px-1 ">
              <Link to="/getotp-jobseeker" className="signup-btn">
                Signup as Jobseeker
              </Link>
            </span>
          </div>
        </div>

        <div className="col-2 ">
          <button
            type="button"
            name="next"
            className="next action-button desktop-view-button"
            onClick={handleValidation}
          >
            Continue
          </button>
        </div>
      </div>
    </>
  );
}

export default Step1;
