import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";

import { useContext } from "react";
import { Context } from "../../../../utils/context";
function Step4({ handleNext }) {
  const {
    postData,
    fetchSeekerData,
    jobSeekerData,
    setJobSeekerData,
    getJobSeekerData,
  } = useContext(Context);

  const [dobErr, setDobErr] = useState("");
  const [dob, setDOB] = useState("");

  const [formData, setFormData] = useState({
    step: 4,
    dob: "",
    status: true,
  });

  const handleValidation = async () => {
    // Initialize an array to store error messages
    const errors = [];

    if (!formData?.dob) {
      setDobErr("Date of birth is required");
      errors.push("Date of birth is required");
    } else {
      const currentDate = new Date();
      const enteredDate = new Date(formData?.dob);

      if (enteredDate > currentDate) {
        setDobErr("Date of birth cannot be in the future");
        errors.push("Date of birth cannot be in the future");
      }
    }
    // console.log(formData?.dob, "forms");
    if (errors.length === 0) {
      const res = await postData(`/jobseeker/signup/dob`, formData);
      if (res?.success) {
        handleNext();
      } else {
        // console.log(res, "fails");
      }
    }
  };

  const seekerData = async () => {
    const res = await getJobSeekerData();
    if (res?.users_detail?.dob != null) {
      await setFormData({
        dob: res?.users_detail?.dob,
        step: 4,
        status: "none",
      });
    }
  };
  const handleDateChange = (date) => {
    const formattedDate = date.target.value;

    // Update formData state with the formatted date
    setFormData({ ...formData, dob: formattedDate });
    setDobErr(false);
  };

  useEffect(() => {
    seekerData();
  }, []);
  return (
    <>
      <fieldset>
        <div className="form-card StepForm mb-0 mt-5">
          <div className="d-flex  steps-heading">
            <div className="border-left" />
            <h5>Step 3</h5>
          </div>
          <Row>
            <Col className="col-xxl-6 col-xl-6 col-lg-5 col-md-6 col-12">
              <p className="birthdate-text">
                Enter Your Birthdate <span>*</span>
              </p>
            </Col>
            <Col className="col-xxl-6 col-xl-6 col-lg-7 col-md-6 col-12">
              <div className="input-group mb-3">
                <Form.Control
                  type="date"
                  placeholderText="yyyy-mm-dd"
                  name="date"
                  value={formData?.dob}
                  onChange={handleDateChange}
                />
              </div>
            </Col>
          </Row>
          {dobErr && <div className="error-message  text-danger">{dobErr}</div>}
          <div className="mobile-view-button">
            <button
              type="button"
              name="next"
              className="next action-button contin-btn"
              onClick={handleValidation}
            >
              Continue
            </button>
          </div>
        </div>
      </fieldset>

      <div className="row mt-5 pt-4 footer-sec">
        <div className="col-md-10 col-12 desktop-view-button">
          {/* <div className="check-row text-start">
            <span>
              <Form.Check inline name="group1" className="me-2" />
            </span>
            <span className=" by-text ">By signing up, I agree to the</span>
            <span className="px-1 ">
              <Link to="/terms" className="term-btn">
                Term and Conditions
              </Link>
            </span>
            <br />
          </div>
          <div className="login-row text-start">
            <span className="">Already an User?</span>
            <span className="px-1 ">
              <Link to="/other-page" className="login-btn">
                log In
              </Link>
            </span>
            &nbsp; &nbsp; &nbsp;
            <span className="">Not a Jobseeker?</span>
            <span className="px-1 ">
              <Link to="/signup-recruiter" className="signup-btn">
                Signup as Recruiter
              </Link>
            </span>
          </div> */}
        </div>
        {/* <div className="col-md-8 col-12 mb-5  mobile-view-button">
          <div className="check-row text-start">
            <span>
              <Form.Check inline name="group1" className="me-2" />
            </span>
            <span className=" by-text ">By signing up, I agree to the</span>
            <span className="px-1 ">
              <Link to="/terms" className="term-btn">
                Term and Conditions
              </Link>
            </span>
            <br />
          </div>
          <div className="login-row text-start">
            <span className="">Already an User?</span>
            <span className="px-1 ">
              <Link to="/other-page" className="login-btn">
                log In
              </Link>
            </span>
            <br />
            <span className="">Not a Jobseeker?</span>
            <span className="px-1 ">
              <Link to="/signup-recruiter" className="signup-btn">
                Signup as Recruiter
              </Link>
            </span>
          </div>
        </div> */}

        <div className="col-2">
          <div className="desktop-view-button">
            <button
              type="button"
              name="next"
              className="next action-button contin-btn"
              onClick={handleValidation}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Step4;
