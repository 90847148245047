import React, { useContext, useEffect, useState } from "react";
import "../JobAlert/JobAlert.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import ExploreOpportunity from "../ExploreOpportunity/ExploreOpportunity";
import { Context } from "../../../../utils/context";
import JobDescriptionModal from "../../JobDescriptionModel/JobDescriptionModal";

const JobAlert = () => {
  const { getData, imageUrl, postData, NoDataAnimations } = useContext(Context);
  const [modalShow, setModalShow] = useState(false);
  const [jobAlerts, setJobAlerts] = useState([]);

  const getAllData = async () => {
    const res = await getData("/jobseeker/dashboard/job-alerts");
    if (res?.success) {
      setJobAlerts(res.data);
    }
  };

  const [data, setData] = useState({});

  useEffect(() => {
    getAllData();
  }, [modalShow]);

  const [showAll, setShowAll] = useState(false);
  const visibleJobs = showAll ? jobAlerts : jobAlerts?.slice(0, 6);
  return (
    <>
      <section className="Job-alert Job-alert-desktopview">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="title-job-alert">
                <h5>Job Alert</h5>
                <p>Jobs You Applied For</p>
              </div>
            </div>
            {visibleJobs?.map((job, index) => (
              <div key={index} className="col-xl-2 col-lg-3 col-md-4 mt-2">
                <div className="jobcard">
                  <div className="text-center">
                    <img
                      src={imageUrl + job?.job_contact?.logo}
                      className="companyimg"
                      alt="..."
                    />
                    <p className="mt-3">{job?.role?.name}</p>
                  </div>
                  <div className="blue-background mt-4">
                    <div className="text-holder-job mt-2">
                      <p>
                        Job Posted On{" "}
                        {job?.createdAt?.replace("T", " ")?.split(" ")[0]}
                      </p>
                      <p>
                        <FontAwesomeIcon icon="fa-solid fa-location-dot" />{" "}
                        Location {job?.city?.name}
                      </p>
                    </div>

                    <div className="explore-btn">
                      {/* <Link to="/job-description"> */}
                      <button
                        className="btn"
                        onClick={() => {
                          setData(job);
                          setModalShow(true);
                        }}
                      >
                        Explore
                      </button>
                      {/* </Link> */}
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {jobAlerts?.length === 0 && <NoDataAnimations />}

            <JobDescriptionModal
              show={modalShow}
              data={data}
              title={"Job Description"}
              onHide={() => setModalShow(false)}
            />
            {jobAlerts?.length > 6 && (
              <div className="text-center mt-5 mb-5">
                <button
                  className="show-more2"
                  onClick={() => setShowAll(!showAll)}
                >
                  {showAll ? "Show Less" : "Show More"}{" "}
                  <FontAwesomeIcon icon="fa-solid fa-chevron-down" />
                </button>
              </div>
            )}
          </div>
        </div>
      </section>

      <section className="Job-alert Job-alert-mobileview">
        <div className="row">
          <div className="col-md-12">
            <div className="title-job-alert">
              <h5>Job Alert</h5>
              <p>Jobs You Applied For</p>
            </div>
          </div>

          {visibleJobs?.map((job, index) => (
            <div key={index} className="col-xl-2 col-lg-3 col-md-4 mt-2">
              <div className="jobcard">
                <div className="row">
                  <div className="col-3">
                    <div className="logoCard">
                      <img
                        src={imageUrl + job?.job_contact?.logo}
                        className="companyimg"
                        alt="..."
                      />
                    </div>
                  </div>
                  <div className="col-9">
                    <div className="mt-3 jobalert_title">
                      {job?.job_title?.name}
                    </div>
                  </div>
                </div>

                <div className="blue-background mt-4">
                  <div className="row">
                    <div className="col-8 col-md-12">
                      <div className="text-holder-job mt-2">
                        <p>
                          <FontAwesomeIcon icon="fa-solid fa-calendar-days pe-2" />
                          Job Posted On{" "}
                          {job?.createdAt?.replace("T", " ")?.split(" ")[0]}
                        </p>
                        <p>
                          <FontAwesomeIcon icon="fa-solid fa-location-dot" />{" "}
                          Location {job?.city?.name}
                        </p>
                      </div>
                    </div>
                    <div className="col-4 col-md-12 my-auto">
                      <div className="explore-btn">
                        {/* <Link to="/job-description"> */}
                        <button
                          className=" btn btn-primary"
                          onClick={() => {
                            setData(job);
                            setModalShow(true);
                          }}
                        >
                          Explore
                        </button>
                        {/* </Link> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {jobAlerts?.length === 0 && <NoDataAnimations />}

          {jobAlerts?.length > 6 && (
            <div className="text-center">
              <button
                className=" show-more2"
                onClick={() => setShowAll(!showAll)}
              >
                {showAll ? "Show Less" : "Show More"}{" "}
                <FontAwesomeIcon icon="fa-solid fa-chevron-down" />
              </button>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default JobAlert;
