import React, { useState, useEffect } from "react";
import "./Footer.css";
import { Link, useNavigate } from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLinkedin,
  faInstagram,
  faFacebook,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";

import Cookies from "js-cookie";
import { useContext } from "react";
import { Context } from "../../utils/context";
library.add(fas);

const Footer = () => {
  const [showIcons, setShowIcons] = useState(false);
  const [icon, setIcon] = useState("+");
  const toggleIcons = () => {
    setShowIcons(!showIcons);
    setIcon(showIcons ? "+" : "x");
  };

  const {
    ReactLoader,
    setReactLoader,
    getData,
    setSignin,
    signin,
    setUsertype,
    userType,
  } = useContext(Context);

  const [authToken, setAuthToken] = useState(null);
  const [authType, setAuthType] = useState(null);
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const browseJob = () => {
    const featuredCategoryElement =
      document.querySelector(".Featured_Category");
    if (featuredCategoryElement) {
      featuredCategoryElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    // console.log(signin, "UserAccess");
    if (signin) {
      setAuthToken(Cookies.get("hiclousia_website"));
      setAuthType(Cookies.get("jwtType"));
    } else {
      setAuthToken(null);
      setAuthType(null);
    }
    setReactLoader(false);
  }, [signin, userType]);
  return (
    <>
      <section className="footer desktop-view-footer">
        <div className="container">
          <div className="row footer_row_main  justify-content-center pt-4 pb-2">
            <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 links">
              <div className="footer_heading_section">
                <a className="navbar-brand">
                  <FontAwesomeIcon
                    icon={["custom", "search-plus"]}
                    className="logo-icon"
                  />
                  <span className="Hiclousia_footer_logo">Hiclousia</span>
                </a>
              </div>
            </div>
            <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12 links">
              <div className="text-holder"></div>
              <div className="row footer_upper_content">
                <div className=" col-xl-3 col-lg-3 col-md-6 col-12">
                  <div className="text-holder">
                    <h6>Quick Links</h6>
                    <Link to="/">
                      <p>Home</p>
                    </Link>
                    <Link to="/blogs">
                      <p>Blogs</p>
                    </Link>
                    <Link to="/contact">
                      <p>Contact Us</p>
                    </Link>
                  </div>
                </div>
                <div className=" col-xl-3 col-lg-3 col-md-6 col-12">
                  <div className="text-holder">
                    <h6>For Job seekers</h6>
                    {authToken === null && (
                      <Link to="/getotp-jobseeker">
                        <p>Sign In / Sign Up</p>
                      </Link>
                    )}
                    <Link to="" onClick={browseJob}>
                      <p>Browse Job</p>
                    </Link>
                  </div>
                </div>
                <div className=" col-xl-3 col-lg-3 col-md-6 col-12">
                  <div className="text-holder mt-xl-0 mt-lg-0 mt-md-3">
                    <h6>For Recruiter</h6>
                    {authToken === null && (
                      <Link to="/getotp">
                        <p>Sign In / Sign Up</p>
                      </Link>
                    )}
                  </div>
                </div>
                <div className=" col-xl-3 col-lg-3 col-md-6 col-12">
                  <div className="text-holder mt-xl-0 mt-lg-0 mt-md-3">
                    <h6>Contact</h6>
                  </div>
                  <div className="text-holder">
                    <div className="text ">
                      <FontAwesomeIcon
                        icon="fa-solid fa-location-dot"
                        className="icon me-1"
                      />
                      <span>502, Milestone, 12, Ramdaspeth, Nagpur-440010</span>
                    </div>
                    <div className="text ">
                      <FontAwesomeIcon
                        icon="fa-solid fa-phone"
                        className="icon me-1"
                      />{" "}
                      <span>000 - 2222222 / 3333333</span>
                    </div>
                    <div className="text">
                      <FontAwesomeIcon
                        icon="fa-solid fa-envelope"
                        className="icon me-1"
                      />
                      <span>jobs@hiclousia.com </span>
                    </div>
                    <FontAwesomeIcon
                      icon={faLinkedin}
                      className="linkedin_icon"
                    />
                    <FontAwesomeIcon
                      icon={faInstagram}
                      className="linkedin_icon ms-3"
                    />
                    <FontAwesomeIcon
                      icon={faFacebook}
                      className="linkedin_icon ms-3"
                    />
                    <FontAwesomeIcon
                      icon={faTwitter}
                      className="linkedin_icon ms-3"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="horizantal_line"></div>
          </div>
          <div className="Footer_bottom_info text-center">
            <span>
              © 2023 All Rights Reserved Terms Of Use | Privacy Policy{" "}
              <Link to="https://profcymaglobal.com/" target="_blank">
                {" "}
                Powered by Profcyma
              </Link>
            </span>
          </div>
        </div>
      </section>

      {/* mobile-view-start */}
      <section className=" mobile-view-footer">
        <div className="container">
          <div className="row ">
            <div className="col-md-12 ms-0 me-0">
              <div
                className={`circle_rect_sec ${showIcons ? "show-icons" : ""}`}
              >
                <div className="circle" onClick={toggleIcons}>
                  <div className="plus-icon mt-1">{icon}</div>
                </div>

                {showIcons && (
                  <div className="icons mb-5">
                    <Link to="/talentPool">
                      <div className="image-footer ">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/Images/icons/footer1.png"
                          }
                          className="footer_icon_img "
                        />
                        <p>Talent Pool</p>
                      </div>
                    </Link>
                    <Link to="/aboutUs">
                      <div className="image-footer ">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/Images/icons/footer2.png"
                          }
                          className="footer_icon_img "
                        />
                        <p>About Us</p>
                      </div>
                    </Link>
                    <Link to="/blogs">
                      <div className="image-footer">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/Images/icons/footer3.png"
                          }
                          className="footer_icon_img "
                        />
                        <p>Blogs</p>
                      </div>
                    </Link>
                    <Link to="/contact">
                      <div className="image-footer ">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/Images/icons/footer4.png"
                          }
                          className="footer_icon_img"
                        />
                        <p>Contact Us</p>
                      </div>
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
