import React from 'react'
import Modal from 'react-bootstrap/Modal';
// import "./PopupModal.css"

import Success from '../../../Model/Animation/Success';
const Shortlisted = (props) => {
  return (
    <>
      <section >
        <Modal
          {...props}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          className='PopupModal'
          centered          
        >          
          <Modal.Body>
            <Success/>
            <p className='modal_txt'> Shortlisted succesfully</p>
            <p className='text-center' style={{color: "#FFFF"}}> Have a Great Time! </p>
          </Modal.Body>         
        </Modal>
      </section>
    </>
  )
}

export default Shortlisted