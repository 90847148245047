import React from "react";
import Success from "../../../RecruiterPage/Model/Animation/Success.js";
import Modal from "react-bootstrap/Modal";

function DeleteAccount(props) {
  return (
    <>
      <section className="PopupModal">
        <Modal
          {...props}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          className="PopupModal"
          centered
        >
          <Modal.Body>
            <Success />
            <p className="modal_txt">
              {" "}
              Your Account has been De-Activate Successfully!
            </p>
            <p className="text-center" style={{ color: "#FFFF" }}>
              {" "}
              Have a Great Time!{" "}
            </p>
          </Modal.Body>
        </Modal>
      </section>
    </>
  );
}

export default DeleteAccount;
