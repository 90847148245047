import React from 'react';
import Modal from 'react-modal';
import '../CreateJobs/CreateJobs.css'

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  content: {
    margin: 'auto',
    backgroundColor: '#0054B3',
  },
};

Modal.setAppElement('#root'); 

function MyModal(props) {
    
  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.onClose}
      style={customStyles}
    >
      {/* <button onClick={props.onClose}>Close Modal</button> */}
      <p className='text-center first-line'>Your Job has been Posted Successfully!</p>
      <p className='text-center second-line'>Have a Great Time!</p>
    </Modal>
    
  );
}

export default MyModal;
