import React, { useState } from "react";
import ReactDOM from "react-dom";
import ReactApexChart from "react-apexcharts";

const HorizantalGraph = (props) => {
  // const [chartData, setChartData] = useState({
  //   series: [
  //     {
  //       data: [400, 430, 448, 470, 540, 580, 690, 1100, 1200, 1380],
  //     },
  //   ],
  //   options: {
  //     chart: {
  //       type: "bar",
  //       height: 350,
  //     },
  //     plotOptions: {
  //       bar: {
  //         borderRadius: 4,
  //         horizontal: true,
  //       },
  //     },
  //     dataLabels: {
  //       enabled: false,
  //     },
  //     xaxis: {
  //       categories: [
  //         "South Korea",
  //         "Canada",
  //         "United Kingdom",
  //         "Netherlands",
  //         "Italy",
  //         "France",
  //         "Japan",
  //         "United States",
  //         "China",
  //         "Germany",
  //       ],
  //     },
  //   },
  // });

  return (
    <div>
      <div id="chart">
        <ReactApexChart
          options={props?.data?.options}
          series={props?.data?.series}
          type="bar"
          height={350}
        />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default HorizantalGraph;
