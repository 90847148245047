import React, { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import "./ApplyModal.css";
import { Context } from "../../../utils/context";

const ApplyModal = (props) => {
  const { jobSeekerData, fetchSeekerData, postData } = useContext(Context);

  const [validated, setValidated] = useState(false);

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    let errors = {};

    const regexEmail =
      /^([A-Za-z0-9_\-\.])+\@(?!(?:[A-Za-z0-9_\-\.]+\.)?([A-Za-z]{2,4})\.\2)([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    const regexAlfa = /^[A-Za-z]+(\s[A-Za-z]+)*$/;

    if (!props.formData.name) {
      errors.name = "Name is required";
    } else if (!regexAlfa.test(props.formData.name?.trim())) {
      errors.name = "Enter a valid data";
    }

    if (!props.formData.email) {
      errors.email = "Email is required";
    } else if (!regexEmail.test(props.formData.email?.trim())) {
      errors.email = "Enter a valid data";
    }

    // const regexContact = /^(\+\d{1,4}[-\s]?)?\d{10}$/;
    // if (!props.formData.contact.length == 12) {
    //   errors.contact = "Contact No is required";
    // }

    return errors;
  };

  const handleChange = (e) => {
    props.setFormData({ ...props.formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        const res = await postData(`/jobseeker/jobs/job-apply`, props.formData);
        // console.log(res);
        props.onHide();
        props.onJDHide();
        setErrors({});
      } catch (error) {
        // console.log(error);
      }
    }
  };

  const handleKeyPressContact = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);

    if (!/^\d$/.test(keyValue)) {
      event.preventDefault();
    }
  };

  const errorStyle = {
    color: "red",
    marginLeft: "5px",
    // fontSize: "11px",
  };

  return (
    <>
      <section className="applyModal">
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="applyModal"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter ">
              Apply here
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Form.Group as={Col} md="4" controlId="validationCustom01">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    name="name"
                    value={props.formData?.name}
                    onChange={handleChange}
                    placeholder="Enter Name"
                  />
                  {errors?.name && (
                    <span style={errorStyle}>{errors?.name}</span>
                  )}
                  {/* <Form.Control.Feedback type="invalid">
                    Enter your Name
                  </Form.Control.Feedback> */}
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="validationCustom02">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    required
                    type="email"
                    name="email"
                    value={props.formData?.email}
                    onChange={handleChange}
                    placeholder="Enter Email"
                  />

                  {errors?.email && (
                    <span style={errorStyle}>{errors?.email}</span>
                  )}

                  {/* <Form.Control.Feedback type="invalid">
                    Enter your Email
                  </Form.Control.Feedback> */}
                </Form.Group>
                <Form.Group
                  as={Col}
                  md="4"
                  controlId="validationCustomUsername"
                >
                  <Form.Label>Contact </Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      type="text"
                      name="contact"
                      value={props.formData?.contact}
                      onChange={handleChange}
                      onKeyPress={handleKeyPressContact}
                      placeholder="Enter Contact"
                      aria-describedby="inputGroupPrepend"
                      required
                    />

                    {/* <Form.Control.Feedback type="invalid">
                      Enter your Contact No
                    </Form.Control.Feedback> */}
                  </InputGroup>
                  {errors?.contact && (
                    <span style={errorStyle}>{errors?.contact}</span>
                  )}
                </Form.Group>
              </Row>

              <Form.Group className="mb-3"></Form.Group>
              <div className="row ">
                <div className="col-md-6 col-6 text-end">
                  <Button type="submit" onClick={handleSubmit}>
                    Apply
                  </Button>
                </div>
                <div className="col-md-6 col-6 text-start">
                  <Button type="button" onClick={props.onHide}>
                    Cancel
                  </Button>
                </div>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
      </section>
    </>
  );
};

export default ApplyModal;
