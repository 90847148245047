import React, { useContext, useEffect } from "react";
import JobBanner from "./JobBanner/JobBanner";
import JobTabs from "./JobTab/JobTab";
import { Context } from "../../../utils/context";

const DashBoardJob = () => {
  return (
    <div>
      <JobBanner />
      <JobTabs />
    </div>
  );
};

export default DashBoardJob;
