import React, { useContext, useEffect, useState } from "react";
import "../ThirdTab/ThirdTab.js";
import "../FourthTab/FourthTab.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import ShortlistedInterview from "./ShortlistedInterview.js";
import OnHoldInterview from "./OnHoldInterview.js";
import Rejected from "./Rejected.js";
import Schedule from "./Schedule.js";
import { Breadcrumb } from "react-bootstrap";
import { Context } from "../../../../utils/context.js";

const FourthTab = () => {
  const { getData, NoDataAnimations } = useContext(Context);

  const numberOfJobs = 4;

  const [apply, setApply] = useState([]);
  const [onHold, setOnHold] = useState([]);
  const [reject, setReject] = useState([]);
  const [schedule, setSchedule] = useState([]);

  const [shortlistdData, setShortListedData] = useState([]);
  const [shortlistddisplay, setShortListedDisplay] = useState(false);
  const handleClickShortListed = async (data) => {
    await setApply(data);
    await setShortListedDisplay(!shortlistddisplay);
  };

  const [onholdData, setOnHoldData] = useState([]);
  const [onholdDatadisplay, setOnHoldDisplay] = useState(false);
  const handleClickOnHold = async (data) => {
    await setOnHold(data);
    await setOnHoldDisplay(!onholdDatadisplay);
  };

  const [rejectData, setRejectData] = useState([]);
  const [rejectDataDisplay, setRejectDataDisplay] = useState(false);
  const handleClickReject = async (data) => {
    await setReject(data);
    await setRejectDataDisplay(!rejectDataDisplay);
  };

  const [scheduleData, setScheduleData] = useState([]);
  const [scheduleDataDisplay, setScheduleDataDisplay] = useState(false);
  const handleClickSchedule = async (data) => {
    await setSchedule(data);
    await setScheduleDataDisplay(!scheduleDataDisplay);
  };

  const getAllData = async () => {
    {
      const res = await getData(`/recruiters/job/all-portfolios/1`);
      setShortListedData(res.data);
    }
    {
      const res = await getData(`/recruiters/job/all-portfolios/2`);
      setOnHoldData(res.data);
    }
    {
      const res = await getData(`/recruiters/job/all-portfolios/3`);
      setRejectData(res.data);
    }
    {
      const res = await getData(`/recruiters/job/all-portfolios/4`);
      setScheduleData(res.data);
    }
  };
  useEffect(() => {
    getAllData();
  }, []);
  return (
    <>
      <section className="manage-job">
        <div className="container">
          <div className="row">
            <div className="col-md-9"></div>
          </div>

          <Tabs
            defaultActiveKey="shortlisted"
            id="justify-tab-example"
            className="mx-auto justify-content-center shortlisted-tabss "
            center
          >
            <Tab eventKey="shortlisted" title="Shortlisted" className="short ">
              <div style={{ display: shortlistddisplay ? "none" : "" }}>
                <div className="row mt-4 p-2">
                  {shortlistdData?.map((job, index) => (
                    <div key={index} className="col-lg-3 col-md-6">
                      <div className="manage-job-text1 shortlistd mt-md-4  mb-md-4 mt-1 mb-0">
                        <div className="row portfolio_maincard text-md-center">
                          <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-2 col-1 mark-icon">
                            <div className="mark_iconsec_dash">
                              <FontAwesomeIcon
                                icon="fa-solid fa-circle-check "
                                className="circle-check-class short-fafafff my-auto mx-auto"
                              />
                            </div>
                          </div>
                          <div className="col-xxl-10 col-xl-10 col-lg-9 col-md-10 col-11">
                            <div className="white-back p-xxl-4 p-xl-4 p-lg-2 p-md-4 pb-4">
                              <div className="text-holder-manage-job">
                                <p className="mb-0">Company</p>
                                <h5>{job?.company?.name}</h5>
                                <p className="mb-0">Role</p>
                                <h5>{job?.role?.name}</h5>
                                <div>
                                  <button
                                    className="btn view-all "
                                    onClick={() =>
                                      handleClickShortListed(job?.job_applyeds)
                                    }
                                  >
                                    View All Candidate's
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  {shortlistdData?.length === 0 && <NoDataAnimations />}
                </div>
              </div>

              {shortlistddisplay && (
                <>
                  <ShortlistedInterview
                    setShortListedDisplay={setShortListedDisplay}
                    apply={apply}
                  />
                </>
              )}
            </Tab>

            <Tab eventKey="hold" title="On Hold" className="hold">
              <div
                className="row mt-4 p-2"
                style={{ display: onholdDatadisplay ? "none" : "" }}
              >
                {onholdData?.map((job, index) => (
                  <div key={index} className="col-lg-3 col-md-6">
                    <div className="manage-job-text2 shortlistd mt-md-4  mb-md-4 mt-1 mb-0">
                      <div className="row portfolio_maincard text-md-center">
                        <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-2 col-1 mark-icon">
                          <div className=" ">
                            <img
                              className="circle-check-class mx-auto hold-iccc"
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/Images/icons/onhold.png"
                              }
                            />
                          </div>
                        </div>
                        <div className="col-xxl-10 col-xl-10 col-lg-9 col-md-10 col-11">
                          <div className="white-back p-xxl-4 p-xl-4 p-lg-2 p-md-4">
                            <div className="row">
                              <div className="text-holder-manage-job">
                                <p className="mb-0">Company</p>
                                <h5>{job?.company?.name}</h5>
                                <p className="mb-0">Role</p>
                                <h5>{job?.role?.name}</h5>
                                <button
                                  className="btn view-all "
                                  onClick={() =>
                                    handleClickOnHold(job?.job_applyeds)
                                  }
                                >
                                  View All Candidate's
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                {onholdData?.length === 0 && <NoDataAnimations />}
              </div>

              {onholdDatadisplay && (
                <>
                  <OnHoldInterview
                    setOnHoldDisplay={setOnHoldDisplay}
                    apply={onHold}
                  />
                </>
              )}
            </Tab>

            <Tab eventKey="rejected" title="Rejected" className="reject">
              <div
                className="row mt-4 p-2"
                style={{ display: rejectDataDisplay ? "none" : "" }}
              >
                {rejectData?.map((job, index) => (
                  <div key={index} className="col-lg-3 col-md-6">
                    <div className="manage-job-text3 shortlistd mt-md-4  mb-md-4 mt-1 mb-0">
                      <div className="row portfolio_maincard text-md-center">
                        <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-2 col-1 mark-icon">
                          <div className=" ">
                            <img
                              className="circle-check-class  mx-auto hold-iccc"
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/Images/icons/rejected.png"
                              }
                            />
                          </div>
                        </div>
                        <div className="col-xxl-10 col-xl-10 col-lg-9 col-md-10 col-11">
                          <div className="white-back p-xxl-4 p-xl-4 p-lg-2 p-md-4">
                            <div className="row">
                              <div className="text-holder-manage-job">
                                <p className="mb-0">Company</p>
                                <h5>{job?.company?.name}</h5>
                                <p className="mb-0">Role</p>
                                <h5>{job?.role?.name}</h5>
                                <button
                                  className="btn view-all "
                                  onClick={() =>
                                    handleClickReject(job?.job_applyeds)
                                  }
                                >
                                  View All Candidate's
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                {rejectData?.length === 0 && <NoDataAnimations />}
              </div>
              {rejectDataDisplay && (
                <>
                  <Rejected
                    setRejectDataDisplay={setRejectDataDisplay}
                    apply={reject}
                  />
                </>
              )}
            </Tab>
            <Tab eventKey="schedule" title="Scheduled" className="schedule">
              <div
                className="row mt-4 p-2"
                style={{ display: scheduleDataDisplay ? "none" : "" }}
              >
                {scheduleData?.map((job, index) => (
                  <div key={index} className="col-lg-3 col-md-6">
                    <div className="manage-job-text3 shortlistd mt-md-4  mb-md-4 mt-1 mb-0">
                      <div className="row portfolio_maincard text-md-center">
                        <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-2 col-1 mark-icon">
                          <div className=" ">
                            <img
                              className="circle-check-class mx-auto hold-iccc"
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/Images/icons/schedule.png"
                              }
                            />
                          </div>
                        </div>
                        <div className="col-xxl-10 col-xl-10 col-lg-9 col-md-10 col-11">
                          <div className="white-back p-xxl-4 p-xl-4 p-lg-2 p-md-4">
                            <div className="row">
                              <div className="text-holder-manage-job">
                                <p className="mb-0">Company</p>
                                <h5>{job?.company?.name}</h5>
                                <p className="mb-0">Role</p>
                                <h5>{job?.role?.name}</h5>
                                <button
                                  className="btn view-all "
                                  onClick={() =>
                                    handleClickSchedule(job?.job_applyeds)
                                  }
                                >
                                  View All Candidate's
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                {scheduleData?.length === 0 && <NoDataAnimations />}
              </div>
              {scheduleDataDisplay && (
                <>
                  <Schedule
                    setScheduleDataDisplay={setScheduleDataDisplay}
                    apply={schedule}
                  />
                </>
              )}
            </Tab>
          </Tabs>
        </div>
      </section>
    </>
  );
};

export default FourthTab;
