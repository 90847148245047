import React, { useState } from 'react';
import '../OpenJobs/OpenJobs.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import ShortBanner from "../../../../RecruiterPage/NavBar/ShortListedPortfolio/ShortBanner/ShortBanner";
import { Link } from 'react-router-dom'
import FirstTabBanner from "../../FirstTabBanner/FirstTabBanner";

const OpenJobs = () => {
    const jobData = [
        {
            title: 'Graphic & UI Designer',
            postedDate: '15/09/23',
            location: 'Mumbai',
            pool: 'Silver (S1)',
        },
        {
            title: 'Back End Developer',
            postedDate: '15/09/23',
            location: 'Pune',
            pool: 'Silver (S1)',
        },
        {
            title: 'Graphic & UI Designer',
            postedDate: '15/09/23',
            location: 'Mumbai',
            pool: 'Silver (S1)',
        },
        {
            title: 'FrontEnd Developer',
            postedDate: '15/09/23',
            location: 'Mumbai',
            pool: 'Silver (S1)',
        },
        {
            title: 'Graphic & UI Designer',
            postedDate: '15/09/23',
            location: 'Mumbai',
            pool: 'Silver (S1)',
        },
        {
            title: 'Tester',
            postedDate: '15/09/23',
            location: 'Bangalore',
            pool: 'Silver (S1)',
        },
    ];

    const [showAll, setShowAll] = useState(false);
    const visibleJobs = showAll ? jobData : jobData.slice(0, 3); // Show 3
    return (
        <>
            <section className="manage-job">
                <div className='col-md-12'>
                    <div>
                        {/* <ShortBanner /> */}
                        <FirstTabBanner />
                    </div>
                </div>
                <div className='container'>
                    <div className="row mt-md-5 mt-4">
                        <div className="heading-holder1 text-center">
                            <h5 className="hiring-name">Open Jobs</h5>
                        </div>

                        <div className="col-md-12 text-end">

                            <Link to='/purchased'><button className="btn search-pool-btn me-2 ">Search by Pool<img src={process.env.PUBLIC_URL + "assets/Images/icons/filter.png"} className="ms-2" alt="..." /></button></Link>
                            <Link to='/draft-2'><button className="btn Draft-btn me-4">Draft Jobs (3)</button></Link>
                        </div>
                        <div className="col-md-12">
                            <div className="section-title mt-3">
                                <div className="row">
                                    <div className=" col-xxl-3 col-xl-3 col-lg-4 col-md-6 ">
                                        <Link to='/dashboardmain' className="title-open-jobs">Go to List of Till Date Posted Jobs <FontAwesomeIcon icon="fa-solid fa-angle-right" className="mx-2" /><Link to='/openjobs'><span>Open Jobs</span></Link></Link>
                                    </div>
                                    <div className=" col-xxl-9 col-xl-9 col-lg-8 col-md-6">
                                        <div className="left-black-line"></div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="grid-view">
                                            <div className="row mb-5">
                                                {visibleJobs.map((job, index) => (
                                                    <div key={index} className="col-lg-4 col-md-6 ">
                                                        <div className="first-company">
                                                            <div className='row'>
                                                                <div className='col-lg-8 col-md-8 col-8'>
                                                                    <div className="text-holder-employer">
                                                                        <h5 className="position">{job.title}</h5>
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-4 col-md-4 col-4 text-end'>
                                                                    <div className="btn-group secondTab_dropdown">
                                                                        <Link className="nav-link" data-bs-toggle="dropdown" aria-expanded="false">
                                                                            <FontAwesomeIcon icon="ellipsis-vertical" />
                                                                        </Link>
                                                                        <ul className="dropdown-menu dropdown-menu-end p-0" aria-labelledby="actionButton">
                                                                            <li>
                                                                                <Link className="dropdown-item" to="/editjobs">
                                                                                    Edit Job details
                                                                                </Link>
                                                                            </li>
                                                                            <li>
                                                                                <Link className="dropdown-item" to="/viewjobs">
                                                                                    View Job details
                                                                                </Link>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="text-holder-employer">
                                                                <p className="job-post">
                                                                    <FontAwesomeIcon icon="calendar-days" className="social-icon me-2" />
                                                                    Job Posted on {job.postedDate}
                                                                </p>
                                                                <p className="job-post">
                                                                    <FontAwesomeIcon icon="location-dot" className="social-icon me-2" />
                                                                    Location: {job.location}
                                                                </p>
                                                                <p className="job-post">
                                                                    <FontAwesomeIcon icon="star" className="social-icon me-2" />
                                                                    Pool: {job.pool}
                                                                </p>
                                                            </div>
                                                            <div className="shortlisting text-center">
                                                                <Link to="/openjobs">
                                                                    <a className="btn open-btn me-2">Open</a>
                                                                </Link>
                                                                <button className="btn paused-btn me-2">Paused</button>
                                                                <button className="btn closed-btn me-2">Closed</button>
                                                                <Link to="/hiring">
                                                                    <a className="btn Hiring">Hiring Funnel</a>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="text-center mt-5">
                                                <button className="show-more2" onClick={() => setShowAll(!showAll)}>
                                                    {showAll ? 'Show Less' : 'Show More'} <FontAwesomeIcon icon="chevron-down" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className='text-center '>
                                        <button className='show-more'>Show More <FontAwesomeIcon icon="fa-solid fa-chevron-down" /> </button>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default OpenJobs;