import React from 'react';
import '../PortFolio/PortFolio.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

const PortFolio = () => {
    return (
        <>
            <section className="portfolio">
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12 text-center'>
                            <h5 className='title-portfolio'>View Portfolio</h5>
                        </div>
                        <div className=" col-xxl-3 col-xl-3 col-lg-4 col-md-6  ">
                            <a className="title-open-jobs"><Link to='/schedule'>Go to Shortlisted Portfolios</Link> <FontAwesomeIcon icon="fa-solid fa-angle-right" /> <span> Portfolio</span></a>
                        </div>
                        <div className=" col-xxl-9 col-xl-9 col-lg-8 col-md-6 ">
                            <div className="left-blue-line"></div>
                        </div>
                        <div className='col-md-8 mt-5'>
                            <div className="responsive-container">
                                <iframe id="slideshareframe" src="//www.slideshare.net/slideshow/embed_code/key/inwur8JgqF1NyL" frameborder="0" marginwidth="0" marginheight="600" scrolling="no" allowfullscreen> </iframe>
                            </div>
                        </div>
                        <div className='col-md-4 mt-5'>
                            <div className="portfolio-btn-main ">
                                <div>
                                    <button className='btn portfolio-btn'><FontAwesomeIcon icon="fa-solid fa-file-pdf" /> View Portfolio PDF</button>
                                </div>
                                <div className='view-portfolio'>
                                    <button className='btn portfolio-btn'><FontAwesomeIcon icon="fa-solid fa-link" /> View Portfolio Link</button>
                                    <div className='no-link-text-main'>
                                        <p className='no-link-text'>No Link Availale</p>
                                    </div>
                                </div>
                                <div>
                                    <button className='btn download-portfolio-btn'><FontAwesomeIcon icon="fa-solid fa-file-arrow-down" /> Download Portfolio</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PortFolio;