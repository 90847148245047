import React, { useContext, useEffect, useState } from "react";
import "../ThirdTab/ThirdTab.css";
import { Link } from "react-router-dom";
import ThirdTab from "./ThirdTab";
import Breadcrumb from "react-bootstrap/Breadcrumb";

import { Context } from "../../../../utils/context.js";
const ThirdTabMain = () => {
  const { getData, NoDataAnimations } = useContext(Context);
  const [display, setDisplay] = useState(false);
  const numberOfJobs = 5;
  const jobs = Array.from({ length: numberOfJobs }, (_, index) => ({
    role: "Data Analyst",
  }));

  const [matched, setMatched] = useState([]);
  const handleClick = async (data) => {
    await setMatched(data);
    await setDisplay(!display);
  };

  const [matchedData, setMatchecData] = useState([]);
  const getMatched = async () => {
    const res = await getData(`/recruiters/job/all-matched`);
    if (res?.success) {
      setMatchecData(res.data);
    }
  };
  useEffect(() => {
    getMatched();
  }, []);
  useEffect(() => {
    getMatched();
  }, [display]);
  return (
    <>
      <section
        className="manage-job"
        style={{ display: display ? "none" : "block" }}
      >
        <div className="container">
          <div
            className="row justify-content-center my-5 "
            style={{ display: display ? "none" : "" }}
          >
            {matchedData?.map((value, index) => (
              <div key={index} className="col-xl-2 col-lg-4 col-md-4 col-md-3">
                <div className="text-holder-manage-job text-md-center ">
                  <div className="matched-portfolio">
                    <p className="mb-0">Company</p>
                    <h5>{value?.job?.company?.name}</h5>
                    <p className="mb-0">Role</p>
                    <h5>{value?.job?.role?.name}</h5>

                    <button
                      className="btn view-all"
                      onClick={() => handleClick(value)}
                    >
                      View Candidate's Match
                    </button>
                  </div>
                </div>
              </div>
            ))}
            {matchedData?.length === 0 && <NoDataAnimations />}
          </div>
        </div>
      </section>

      {display && (
        <>
          <div className="row ms-0 me-0">
            <div className="col-xl-8 col-lg-10 col-md-10  col-10 mx-auto">
              <Breadcrumb className="matchedPortfolio_breadcrum_main">
                <Breadcrumb.Item
                  className="matchedPortfolio_breadcrum"
                  onClick={() => setDisplay(false)}
                >
                  Back
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>

          <ThirdTab
            data={matched}
            setDisplay={setDisplay}
            getMatched={getMatched}
          />
        </>
      )}
    </>
  );
};

export default ThirdTabMain;
