import React, { useEffect, useState, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import PhoneNumberModal from "../AccountModel/PhoneNumbermodal.js";
import AccountBanner from "../../AccountManagement/AccountBanner/AccountBanner.js";
import { Link } from "react-router-dom";
import profileImg from "../../AccountManagement1/AccountDelete/Images/accountManagament.png";
import ContactModal from "../../../RecruiterPage/LoginSignUp/login-page/ContactModal/ContactModal";
import { useNavigate, useLocation } from "react-router-dom";

import { useContext } from "react";
import { Context } from "../../../../utils/context";
const AccountTextModal4 = (props) => {
  const [changeShow, setChangeShow] = useState(false);

  const [verify, setVerify] = useState(false);

  const openModal = () => {
    setModalShow(true);

    setTimeout(() => {
      window.location.href = "/account";
      setModalShow(false);
    }, 3000);
  };

  // AccountText Modal-4

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setTimerShow(false);
    setIsTimerExpired(false);
    setMobileErr("");
    setOtpErr("");
    setOtpValues(["", "", "", ""]);
    setPhone("");
    setMobileVerify(false);
    setMobileDis(false);
    props?.onHide();
  };

  // Verify Otp

  function handliclick() {
    setVerify(!verify);
  }
  const { postData, recruiterData, setRecruiterData } = useContext(Context);

  const [otp1, setOpt1] = useState("");
  const [otp2, setOpt2] = useState("");
  const [otp3, setOpt3] = useState("");
  const [otp4, setOpt4] = useState("");
  const [error, seterror] = useState(false);
  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);
  const inputRef3 = useRef(null);
  const inputRef4 = useRef(null);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [phone, setPhone] = useState("");
  const [alternate, setAlternate] = useState("");

  const [mobileVerify, setMobileVerify] = useState(false);

  const [modalShow, setModalShow] = useState(false);
  const [timerShow, setTimerShow] = useState(false);

  const [seconds, setSeconds] = useState(60); // Initial value is 60 seconds
  const [isTimerExpired, setIsTimerExpired] = useState(false);
  const [otpERR, setOtpErr] = useState("");

  const [otpsend, setOtpSend] = useState(null);

  const [mobileErr, setMobileErr] = useState("");
  const [mobileDisable, setMobileDis] = useState(false);
  const [alternateMobileErr, setAlternateMobileErr] = useState("");

  // console.log(props?.userData);
  const handleValidation = async (type) => {
    // console.log(recruiterData, "recruiterData");
    var mobile = phone;

    // Initialize an array to store error messages
    var errors = [];

    if (mobile.trim() === "") {
      setMobileErr("Mobile Number is required");
      errors.push("Mobile Number is required");
    } else if (!/^\d{10}$/.test(mobile)) {
      setMobileErr("Mobile Number should be a 10-digit number");
      errors.push("Mobile Number should be a 10-digit number");
    }
    if (alternate.trim() !== "") {
      if (!/^\d{10}$/.test(alternate)) {
        setAlternateMobileErr("Mobile Number should be a 10-digit number");
        errors.push("Mobile Number should be a 10-digit number");
      }
    }

    // console.log(mobile, "forms");
    if (errors.length === 0) {
      if (type === "next") {
        if (mobileVerify == false) {
          setMobileErr("Verify Your Mobile Number");
          errors.push("Verify Your Mobile Number");
        } else {
          const res = await postData(`/common/contact-change`, {
            contact_no: "91" + phone,
            type: props?.userData?.type,
          });
          if (res?.success) {
            setTimerShow(false);
            setIsTimerExpired(false);
            setMobileErr("");
            setOtpErr("");
            setOtpValues(["", "", "", ""]);
            setPhone("");
            setMobileVerify(false);
            setMobileDis(false);
            setChangeShow(true);
            props?.onHide();
            props?.getUserData();
            setTimeout(() => {
              setChangeShow(false);
            }, 4000);
          }
        }
      }
      if (type === "otp") {
        // console.log("otp");
        setMobileErr("");
        const randOtp = Math.floor(1000 + Math.random() * 9000).toString();
        // console.log(randOtp, "random");
        const res = await postData("/without-login/verify-otp/mobile-verify", {
          mobile: "91" + mobile,
          otp: randOtp,
          type: props?.userData?.type,
        });

        if (res?.success) {
          // console.log(res, "mobileotp");
          setOtpSend(randOtp);
          setModalShow(true);
          setTimerShow(true);
          setIsTimerExpired(false);
          setOtpErr("");
          setOtpValues(["", "", "", ""]);
          setMobileDis(true);
          setSeconds(60);
          setTimeout(() => {
            setModalShow(false);
          }, 4000);
        } else {
          // console.log(res, "fail");
          setMobileErr(res?.message);
        }
      }
    }
  };

  const [otpValues, setOtpValues] = useState(["", "", "", ""]);
  const inputRefs = [useRef(), useRef(), useRef(), useRef()];

  const handleOtpChange = (index, event) => {
    setOtpErr("");
    const inputValue = event.target.value;

    if (/^[0-9]*$/.test(inputValue) || inputValue === "") {
      otpValues[index] = inputValue;
      setOtpValues([...otpValues]);

      if (otpsend === otpValues.join("")) {
        // Call your OTP verification function here
        setMobileVerify(true);
        setTimerShow(false);
        setOtpValues(["", "", "", ""]);
        setOtpSend(null);
      }

      if (inputValue !== "" && index < 3) {
        inputRefs[index + 1].current.focus();
      } else {
        setOtpErr("OTP Invalid!");
      }
    }
  };

  const handleKeyDown = (index, event) => {
    setOtpErr("");
    if (event.key === "Backspace" && index > 0 && otpValues[index] === "") {
      otpValues[index - 1] = "";
      setOtpValues([...otpValues]);
      inputRefs[index - 1].current.focus();
    }
  };
  const [executeCount, setExecuteCount] = useState(0);

  const handleTerms = (element) => {
    if (element.target.checked) {
      recruiterData.policy = true;
    } else {
      recruiterData.policy = false;
    }
  };

  useEffect(() => {
    if (executeCount == 0) {
      if (recruiterData?.mobileVerify) {
        setMobileVerify(true);
        setPhone(recruiterData?.contact_no?.substr(2, 12));
      }
      setExecuteCount(1);
    }

    const decrementTimer = () => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else {
        setIsTimerExpired(true);
        setOtpSend(null);
        setMobileDis(false);
      }
    };

    const timerInterval = setInterval(decrementTimer, 1000);

    return () => {
      clearInterval(timerInterval);
    };
  }, [seconds]);

  return (
    <>
      <Modal
        className="setting-modal"
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        fullscreen={false}
      >
        <Modal.Header className="title-privacy" closeButton>
          <h5>Change Phone Number</h5>
        </Modal.Header>
        <Modal.Body className="p-0">
          <section className="Account-management">
            <div>
              <AccountBanner />
            </div>
            <div className="container-fluid">
              <div className="row  justify-content-center ">
                {/* <div className='accountManagement_main'></div> */}
                {/* <div className='  col-lg-10 col-md-12'> */}

                {/* <div className='company-profile-title text-center'>
                                        <div>
                                            <img src={profileImg} className='accManprofile_img' />

                                        </div>
                                        <h2>Account Management</h2>
                                    </div> */}
                <div className="row justify-content-center mt-md-5 mt-3">
                  {/* <div className="title-privacy">
                                            <h5>Change Phone Number</h5>
                                        </div> */}
                  {/* <div className="text-holder-privacy"> */}
                  {/* <div className='first-jobseeker-1'> */}
                  <div className="row">
                    <div className="col-md-8">
                      <label>Change Phone Number</label>
                    </div>
                    <div className="col-md-4 text-md-end">
                      {timerShow == false && mobileVerify == false && (
                        <button
                          className="verify-btn"
                          onClick={() => {
                            handleValidation("otp");
                          }}
                        >
                          Verify Number
                        </button>
                      )}
                      {timerShow && isTimerExpired == true && (
                        <button
                          className="verify-btn"
                          onClick={() => {
                            handleValidation("otp");
                          }}
                        >
                          Resend OTP
                        </button>
                      )}
                    </div>
                    <div className="col-md-3 col-3">
                      <Form.Select aria-label="Default select example">
                        <option>+91</option>
                      </Form.Select>
                    </div>
                    <div className="col-md-9 col-9">
                      <Form.Control
                        type="text"
                        minlength="10"
                        maxlength="10"
                        disabled={mobileVerify || mobileDisable}
                        value={phone}
                        placeholder="Mobile Number"
                        style={{ textAlign: "left" }}
                        onKeyDown={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            if (event.key != "Backspace")
                              event.preventDefault();
                          }
                        }}
                        onChange={(event) => {
                          // Add a space after the country code
                          setPhone(event.target.value);
                          setMobileErr("");
                        }}
                      />
                    </div>
                  </div>
                  <div>
                    <ContactModal
                      show={modalShow}
                      onHide={() => setModalShow(false)}
                    />
                  </div>
                  <span
                    className="text text-danger"
                    style={{
                      textAlign: "center",
                    }}
                  >
                    {mobileErr && mobileErr}
                  </span>
                  {timerShow && (
                    <div className="row text-center">
                      <div className="otpContainer">
                        {otpValues.map((value, index) => (
                          <input
                            className="otp"
                            type="text"
                            maxLength="1"
                            value={value}
                            onChange={(e) => handleOtpChange(index, e)}
                            onKeyDown={(e) => handleKeyDown(index, e)}
                            ref={inputRefs[index]}
                          />
                        ))}
                      </div>

                      <p className="otp">
                        Enter OTP within{" "}
                        {`${Math.floor(seconds / 60)}:${(seconds % 60)
                          .toString()
                          .padStart(2, "0")}`}{" "}
                        seconds
                      </p>
                      <span
                        className="text text-danger"
                        style={{
                          textAlign: "center",
                        }}
                      >
                        {otpERR && otpERR}
                      </span>
                    </div>
                  )}
                  {mobileVerify && (
                    <div className="row text-center">
                      <p className="otp text text-success">
                        Mobile Verified...!
                      </p>
                    </div>
                  )}
                  {/* </div> */}
                  <div className="text-center mt-4">
                    <Link to={"/account"}>
                      <button className=" decline me-2" onClick={handleClose}>
                        Cancel
                      </button>
                    </Link>
                    <button
                      onClick={() => handleValidation("next")}
                      disabled={mobileVerify ? false : true}
                      className=" accept"
                    >
                      Save
                    </button>
                    <PhoneNumberModal
                      show={changeShow}
                      onHide={() => setChangeShow(false)}
                    />
                  </div>
                  {/* </div> */}
                </div>
                {/* </div> */}
              </div>
            </div>
          </section>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AccountTextModal4;
