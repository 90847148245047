import React from "react";
import "./App.css";
import All_Routes from "./Components/All_Routes/All_Routes";
import Footer from "./Components/Footer/Footer";
import Header from "./Components/Header/Header";
import ScrollToTop from "./Components/CommonPage/ScrollToTop/ScrollToTop";
import AppContext from "./utils/context";

function App() {
  return (
    <div className="App">
      <AppContext>
        <Header />
        <ScrollToTop />
        <All_Routes />
        <Footer />
      </AppContext>
    </div>
  );
}

export default App;
