import React from 'react'
import Lottie from 'react-lottie';
import * as animationData from './NodataAnimation.json';
import "./NodataAnimation.css"
const NodataAnimation = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };
  return (
    <>
          <div className='mb-3 NodataAnimation mx-auto mt-5'>
            <Lottie
              options={defaultOptions}
              height={124}
              width={137}
            />

          </div>
    </>
  )
}

export default NodataAnimation