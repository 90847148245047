
import React, { useState } from 'react';
import '../OpenJobs/OpenJobs.css'
import '../SearchPool/SearchPool.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ShortBanner from '../../../NavBar/ShortListedPortfolio/ShortBanner/ShortBanner.js';
import { Link } from 'react-router-dom';
import CreateModal from '../CreateJobs/CreateModal.js';


const SearchPool = () => {
    const [isModalOpen, setModalOpen] = useState(false);

    const openModal = () => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    return (
        <>
            <section className="manage-job">
                <div className='col-md-12'>
                    <div>
                        <ShortBanner />
                    </div>
                </div>
                <div className='container'>
                    <div className="row mt-5">
                        <div className="col-md-12 text-end">
                            <button className="btn search-pool-btn me-2 ">Search by Pool</button>
                            <button className="btn btn-primary Draft-btn">Draft Jobs (3)</button>
                        </div>
                        <div className="col-md-12">
                            <div className="section-title mt-3">
                                <div className="row">
                                    <div className=" col-xxl-4 col-xl-4 col-lg-5 col-md-7">
                                        <a className="title-open-jobs">Go to List of Till Date Posted Jobs <FontAwesomeIcon icon="fa-solid fa-angle-right" />Open Jobs<FontAwesomeIcon icon="fa-solid fa-angle-right" /><span>Search By Pool   </span></a>
                                    </div>
                                    <div className=" col-xxl-8 col-xl-8 col-lg-7 col-md-5 mt-2">
                                        <div className="left-black-line"></div>
                                    </div>
                                    <div className="col-md-12 ">
                                        <div className="post-new-job mt-4">
                                        </div>
                                    </div>
                                    <div className="col-md-1 col-1">
                                        <p className="silver-text">Silver S1</p>
                                    </div>
                                    <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-8 col-7">
                                    </div>
                                    <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-3 col-4 text-end mt-1">
                                        <Link to='/create'><a className="btn btn-primary Draft-btn">Post New Job</a></Link>
                                    </div>
                                    <div className='text-center mt-5 mb-5'>
                                        <button onClick={openModal} className='btn Draft-btn'>Post a Job</button>
                                        <CreateModal isOpen={isModalOpen} onClose={closeModal} />
                                    </div>
                                    <div className="col-md-12">
                                        <div className="grid-view">
                                            <div className="row mb-5">
                                                <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-6 mt-4">
                                                    <div className="first-company">
                                                        <div className="text-end">
                                                            <FontAwesomeIcon icon="fa-solid fa-ellipsis-vertical" />
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <div className="text-holder-employer">
                                                                    <h5 className="position mt-3">Graphic & UI Designer</h5>
                                                                    <p className="job-post mt-3"><FontAwesomeIcon icon="fa-solid fa-calendar-days" className="social-icon me-2" />Job Posted on 15/09/23</p>
                                                                    <p className="job-post mt-3"><FontAwesomeIcon icon="fa-solid fa-location-dot" className="social-icon me-2" />Location: Mumbai</p>
                                                                    <p className="job-post mt-3"><FontAwesomeIcon icon="fa-solid fa-star" className="social-icon me-2" />Pool: Silver (S1)</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <div className="shortlisting">
                                                                    <button className="btn open-btn me-2">Open</button>
                                                                    <button className="btn paused-btn me-2">Paused</button>
                                                                    <button className="btn closed-btn me-2">Closed</button>
                                                                    <Link to='/hiring'><a className="btn Hiring">Hiring Funnel</a></Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default SearchPool;