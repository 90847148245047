import React, { useEffect, useState } from "react";
import "./OurPartner.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { Navigation, Autoplay } from "swiper/modules";

import { useContext } from "react";
import { Context } from "../../../../utils/context";
const OurPartner = () => {
  const { getData, imageUrl } = useContext(Context);

  const [ourPartner, setOurPartner] = useState();

  const getBlogs = async () => {
    const res = await getData("/without-login/master/all-our-partners");
    if (res?.success) {
      setOurPartner(res.data);
    }
  };

  useEffect(() => {
    getBlogs();
  }, []);
  return (
    <section className="oue-partner-section">
      <div className="container">
        <div className="heading">
          <h4>Our Partners</h4>
        </div>
        <div className="cards">
          <Swiper
            spaceBetween={50}
            // slidesPerView={7}
            modules={[Navigation, Autoplay]}
            breakpoints={{
              0: {
                slidesPerView: 3,
                // spaceBetween: 10,
              },
              360: {
                slidesPerView: 3,
                // spaceBetween: 10,
              },
              567: {
                slidesPerView: 4,
                // spaceBetween: 10,
              },
              767: {
                slidesPerView: 4,
                // spaceBetween: 10,
              },
              1024: {
                slidesPerView: 7,
                // spaceBetween: 10,
              },
            }}
          >
            {ourPartner?.map((value, index) => (
              <SwiperSlide key={index}>
                <img
                  src={imageUrl + value?.image}
                  alt="banner"
                  className="company-logo"
                />
              </SwiperSlide>
            ))}
            {/*             
            <SwiperSlide>
              <img src={process.env.PUBLIC_URL + "assets/Images/logo/cognizant-1.png"} alt="banner" className="company-logo" />
            </SwiperSlide> */}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default OurPartner;
