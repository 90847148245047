import React from "react";
import "../FourthTab/FourthTab.css";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import FirstTabBanner from "../FirstTabBanner/FirstTabBanner";
// import Tab from 'react-bootstrap/Tab';
import Tabs from "react-bootstrap/Tabs";
import { Breadcrumb } from "react-bootstrap";
import Tab from "../Tab/Tab";
import ScheduleInterViewModal from "../../ScheduleInterviewModal/ScheduleIntervViewModal.js";
import MyPortfolioModal from "../../../JobseekerPage/my-portfolio/my-portfolio-modal/MyPortfolioModal";
const ShortlistedInterview = (props) => {
  const [modalShow, setModalShow] = React.useState(false);
  const [portfolioShow, setportfolioShow] = React.useState(false);
  const [applyID, setApplyID] = React.useState("");

  const handleScheduleShow = async (data) => {
    await setModalShow(true);
    await setApplyID(data);
  };
  return (
    <>
      <div className="row">
        <div className="col-xl-12 col-lg-11 col-md-10  col-10 mx-auto">
          <Breadcrumb className="matchedPortfolio_breadcrum_main mt-md-4 mt-4">
            {/* <Breadcrumb.Item className="matchedPortfolio_breadcrum">
              Home
            </Breadcrumb.Item>
            <Breadcrumb.Item
              className="matchedPortfolio_breadcrum"
              href="/dashboardmain"
            >
              DashBoard
            </Breadcrumb.Item> */}
            <Breadcrumb.Item
              className="matchedPortfolio_breadcrum"
              onClick={() => props.setShortListedDisplay(false)}
            >
              Back
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <section className="manage-job forth-candiii-card">
        {/* <FirstTabBanner /> */}

        {/* <Tab /> */}

        <div className="container">
          <div className="row mt-5">
            {props?.apply?.map((data, index) => (
              <div className="col-lg-12" key={index}>
                <div className="manage-job-text shortlistd my-md-3 mb-3">
                  <div className="row text-center">
                    <div className="col-md-1 col-1 mark-icon">
                      <div className=" ">
                        <FontAwesomeIcon
                          icon="fa-solid fa-circle-check "
                          className="circle-check-class  mx-auto"
                        />
                      </div>
                    </div>
                    <div className="col-md-11 col-11  ps-0">
                      <div className="white-back p-3">
                        <div className="row justify-content-lg-center ">
                          <div className="col-lg-2 col-md-4">
                            <div className="text-holder-manage-job">
                              <p className="candidate_infoHeading">Job Role:</p>
                              <h5>{data?.job?.role?.name}</h5>
                            </div>
                          </div>
                          <div className="col-lg-2 col-md-4">
                            <div className="text-holder-manage-job">
                              <p className="candidate_infoHeading">
                                Location of Candidate:
                              </p>
                              <h5>{data?.user?.users_detail?.city?.name}</h5>
                            </div>
                          </div>
                          <div className="col-lg-2 col-md-4">
                            <div className="text-holder-manage-job">
                              <p className="candidate_infoHeading">
                                Candidate's Name:
                              </p>
                              <h5>
                                {data?.user?.first_name +
                                  " " +
                                  data?.user?.last_name}
                              </h5>
                            </div>
                          </div>
                          <div className="col-lg-2 col-md-4">
                            <div className="text-holder-manage-job">
                              <p className="candidate_infoHeading">
                                Interview Status:
                              </p>
                              {/* <Link to="/schedule"> Schedule Interview</Link> */}
                              <Button
                                variant="primary"
                                className="btn scheduleIntBtn"
                                onClick={() => handleScheduleShow(data)}
                              >
                                Schedule Interview
                              </Button>
                            </div>
                          </div>
                          <div className="col-lg-2 col-md-4">
                            <div className="text-holder-manage-job">
                              <p className="candidate_infoHeading">Portfolio</p>
                              <Link onClick={() => setportfolioShow(true)}>
                                {" "}
                                View Portfolio
                              </Link>
                            </div>
                          </div>
                          <MyPortfolioModal
                            show={portfolioShow}
                            data={data?.user?.id}
                            onHide={() => setportfolioShow(false)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}

            <ScheduleInterViewModal
              show={modalShow}
              data={applyID}
              setShortListedDisplay={() => props.setShortListedDisplay(false)}
              onHide={() => setModalShow(false)}
            />
            {/* <div className="col-lg-12">
              <div className="manage-job-text my-md-3  mb-3">
                <div className="row text-center">
                  <div className="col-md-1 col-1 mark-icon">
                    <div className=" ">
                      <FontAwesomeIcon
                        icon="fa-solid fa-circle-check "
                        className="circle-check-class mt-lg-4 mt-md-5 mx-auto"
                      />
                    </div>
                  </div>
                  <div className="col-md-11 col-11  ps-0">
                    <div className="white-back p-3">
                      <div className="row justify-content-lg-center">
                        <div className="col-lg-2 col-md-4">
                          <div className="text-holder-manage-job">
                            <p className="candidate_infoHeading">Job Role:</p>
                            <h5>Data Analyst</h5>
                          </div>
                        </div>
                        <div className="col-lg-2 col-md-4">
                          <div className="text-holder-manage-job">
                            <p className="candidate_infoHeading">
                              Location of Candidate:
                            </p>
                            <h5>Andheri (W)</h5>
                          </div>
                        </div>
                        <div className="col-lg-2 col-md-4">
                          <div className="text-holder-manage-job">
                            <p className="candidate_infoHeading">
                              Candidate's Name:
                            </p>
                            <h5>Sharad Kumar</h5>
                          </div>
                        </div>
                        <div className="col-lg-2 col-md-4">
                          <div className="text-holder-manage-job">
                            <p className="candidate_infoHeading">
                              Interview Status:
                            </p>
                            <Link to=""> Schedule Interview</Link>
                          </div>
                        </div>
                        <div className="col-lg-2 col-md-4">
                          <div className="text-holder-manage-job">
                            <p className="candidate_infoHeading">Portfolio:</p>
                            <Link to=""> View Portfolio</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="manage-job-text my-md-3  mb-3">
                <div className="row text-center">
                  <div className="col-md-1 col-1 mark-icon">
                    <div className=" ">
                      <FontAwesomeIcon
                        icon="fa-solid fa-circle-check "
                        className="circle-check-class mt-lg-4 mt-md-5 mx-auto"
                      />
                    </div>
                  </div>
                  <div className="col-md-11 col-11  ps-0">
                    <div className="white-back p-3">
                      <div className="row justify-content-lg-center">
                        <div className="col-lg-2 col-md-4">
                          <div className="text-holder-manage-job">
                            <p className="candidate_infoHeading">Job Role:</p>
                            <h5>Data Analyst</h5>
                          </div>
                        </div>
                        <div className="col-lg-2 col-md-4">
                          <div className="text-holder-manage-job">
                            <p className="candidate_infoHeading">
                              Location of Candidate:
                            </p>
                            <h5>Andheri (W)</h5>
                          </div>
                        </div>
                        <div className="col-lg-2 col-md-4">
                          <div className="text-holder-manage-job">
                            <p className="candidate_infoHeading">
                              Candidate's Name:
                            </p>
                            <h5>Sharad Kumar</h5>
                          </div>
                        </div>
                        <div className="col-lg-2 col-md-4">
                          <div className="text-holder-manage-job">
                            <p className="candidate_infoHeading">
                              Interview Status:
                            </p>
                            <Link to=""> Schedule Interview</Link>
                          </div>
                        </div>
                        <div className="col-lg-2 col-md-4">
                          <div className="text-holder-manage-job">
                            <p className="candidate_infoHeading">Portfolio:</p>
                            <Link to=""> View Portfolio</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="manage-job-text  my-md-3 mb-3">
                <div className="row text-center">
                  <div className="col-md-1 col-1 mark-icon">
                    <div className=" ">
                      <FontAwesomeIcon
                        icon="fa-solid fa-circle-check "
                        className="circle-check-class mt-lg-4 mt-md-5 mx-auto"
                      />
                    </div>
                  </div>
                  <div className="col-md-11 col-11  ps-0">
                    <div className="white-back p-3">
                      <div className="row justify-content-lg-center">
                        <div className="col-lg-2 col-md-4">
                          <div className="text-holder-manage-job">
                            <p className="candidate_infoHeading">Job Role:</p>
                            <h5>Data Analyst</h5>
                          </div>
                        </div>
                        <div className="col-lg-2 col-md-4">
                          <div className="text-holder-manage-job">
                            <p className="candidate_infoHeading">
                              Location of Candidate:
                            </p>
                            <h5>Andheri (W)</h5>
                          </div>
                        </div>
                        <div className="col-lg-2 col-md-4">
                          <div className="text-holder-manage-job">
                            <p className="candidate_infoHeading">
                              Candidate's Name:
                            </p>
                            <h5>Sharad Kumar</h5>
                          </div>
                        </div>
                        <div className="col-lg-2 col-md-4">
                          <div className="text-holder-manage-job">
                            <p className="candidate_infoHeading">
                              Interview Status:
                            </p>
                            <Link to=""> Schedule Interview</Link>
                          </div>
                        </div>
                        <div className="col-lg-2 col-md-4">
                          <div className="text-holder-manage-job">
                            <p className="candidate_infoHeading">Portfolio:</p>
                            <Link to=""> View Portfolio</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default ShortlistedInterview;
