import React, { useState } from 'react';
import FirstTab1 from '../DashBoardJob/JobTab/FirstTab1/FirstTab1';
import SecondJobTab from '../DashBoardJob/SecondJobTab/SecondJobTab';
import ThirdJobTab from '../DashBoardJob/ThirdJobTab/ThirdJobTab'
import MyPortfolio from '../my-portfolio/MyPortfolio';
import FifthJobTab from '../DashBoardJob/FifithJobTab/FifthJobTab';
import SixthJobTab from '../DashBoardJob/SixthJobTab/SixthJobTab';
import './ScrollableTab.css';

const ScrollableTab = () => {
    const [selectedTab, setSelectedTab] = useState(0);

    const tabs = [
        { tab: 'My Portfolio' },
        { tab: 'Dashboard' },
        { tab: 'Shortlisted' },
        { tab: 'About Interview' },

        { tab: 'Saved Job' },
        { tab: 'Applied' },
    ];

    const handleTabClick = (index) => {
        setSelectedTab(index);
    };
    return (
        <div className="scrollable-tabs-container">
            <div className="tabs">
                {tabs.map((tab, index) => (
                    <div
                        key={index}
                        className={`tab ${index === selectedTab ? 'active' : ''}`}
                        onClick={() => handleTabClick(index)}
                    >
                        {tab.tab}
                    </div>
                ))}
            </div>
            <div className="tab-content">
                {selectedTab == 0 && (
                    <MyPortfolio />
                )}
                {selectedTab == 1 && (
                    <FirstTab1 />
                )}
                {selectedTab == 2 && (
                    <SecondJobTab />
                )}
                {selectedTab == 3 && (
                    <ThirdJobTab />
                )}

                {selectedTab == 4 && (
                    <FifthJobTab />
                )}
                {selectedTab == 5 && (
                    <SixthJobTab />
                )}
            </div>


        </div>

    );
};

export default ScrollableTab;
