import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link } from "react-router-dom";
import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement } from "chart.js";
import { Line } from "react-chartjs-2";
import GaugeChart from "react-gauge-chart";
import Radar from "react-d3-radar";
import Lottie from "react-lottie";
import alertAnimation from "./alert-lottie"; // Replace with the path to your Lottie JSON file

import {
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Legend,
} from "chart.js";

import { useContext } from "react";
import { Context } from "../../../../../../utils/context";
const PortfolioChart = (props) => {
  // console.log(props);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: alertAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  Chart.register(ArcElement);
  Chart.register(CategoryScale);
  Chart.register(LinearScale);
  Chart.register(PointElement);
  Chart.register(LineElement);
  const chartData = [80, 20];
  const showData = chartData[0] + "%";

  const data = {
    // labels: ["Red", "Green"],
    datasets: [
      {
        data: chartData,
        backgroundColor: ["#1967D3", "#EEEEEE"],
      },
    ],
  };

  // Gauge Chart

  // Register the plugin
  Chart.register({
    id: "centerText",
    beforeDraw: (chart) => {
      if (chart && chart.chart && chart.chart.width && chart.chart.height) {
        const width = chart.chart.width;
        const height = chart.chart.height;
        const ctx = chart.chart.ctx;

        const fontSize = (height / 114).toFixed(2);
        ctx.font = fontSize + "em sans-serif";
        ctx.fillStyle = chart.config.options.plugins.centerText.color;
        ctx.textBaseline = "middle";

        const text = chart.config.options.plugins.centerText.text;
        const textX = Math.round((width - ctx.measureText(text).width) / 2);
        const textY = height / 2;

        ctx.fillText(text, textX, textY);
      }
    },
  });

  const data1 = {
    labels: ["1st", "2nd", "3rd", "4th"],
    datasets: [
      {
        label: "My First Dataset",
        data: [20, 40, 50, 21, 60, 100, 0],
        fill: false,
        borderColor: "rgb(75, 192, 192)",
        tension: 0.1,
      },
    ],
  };

  const [educationPopup, setEducationPopup] = useState(true);
  const [chartPopup, setchartPopup] = useState(true);
  const [portfolio, setPortfolio] = useState();

  const {
    postData,
    jobSeekerData,
    setJobSeekerData,
    getData,
    getJobSeekerData,
    imageUrl,
  } = useContext(Context);
  const [experiancedata, setExperianceData] = useState({
    variables: [],
    values: {},
  });

  console.log(props);
  const seekerData = async () => {
    const res2 = await getData(
      `/recruiters/job/view-portfolios-expertis/${props?.portfolio?.id}`
    );
    // console.log(res2.data?.variables);
    setExperianceData(res2.data);
  };

  useEffect(() => {
    // console.log(educationPopup);
    if (props?.portfolio?.jobseeker_educations.length > 0) {
      setEducationPopup(false);
    }
    if (props?.portfolio?.steps === "10") {
      setchartPopup(false);
    }
    setPortfolio(props?.portfolio);
    seekerData();
  }, [props]);
  return (
    <>
      <div className="row desktop-view-chart">
        <div className="col-md-12">
          <div className="heading-holder text-center">
            <h4>My Portfolio</h4>
          </div>
        </div>
        <div className="text-left">
          <span onClick={props.onHide} style={{ cursor: "pointer" }}>
            Back
          </span>
        </div>
        <div className="col-lg-12 col-md-12">
          <div className="hr-line mt-3 mb-5"></div>
        </div>

        <div className="col-lg-4 ">
          <div className="card portfolio_card mb-3">
            <div
              className="row"
              style={{ filter: educationPopup && "blur(2px)" }}
            >
              <div className="col-lg-3 col-md-3">
                <div className="profile-img">
                  <img
                    src={
                      portfolio?.image
                        ? imageUrl + portfolio?.image
                        : process.env.PUBLIC_URL +
                          "assets/Images/my-portfolio/profile-img.png"
                    }
                  />
                  {/* <h6 className="mt-1">{portfolio?.first_name}</h6> */}
                </div>
              </div>
              <div className="col-lg-9 col-md-9">
                <div className="name-holder mt-3">
                  <h5 className="ms-4">
                    I'm {portfolio?.first_name} {portfolio?.last_name}
                  </h5>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "assets/Images/my-portfolio/export-img.png"
                    }
                    className="export-img1 ms-4 me-2"
                  />
                  <span>{portfolio?.tag}</span>
                  <p className="jobtype">
                    <b>Job type</b>({" "}
                    {portfolio?.jobseeker_job_types?.map(
                      (d) => d?.job_type?.name + ", "
                    )}
                    )
                  </p>
                </div>
              </div>
            </div>
            <div
              className="table-responsive chartSec_table"
              style={{ filter: educationPopup && "blur(2px)" }}
            >
              <table className="table">
                <tbody>
                  <tr className="main-table">
                    <td className="talent">Talent</td>
                    <td>{portfolio?.talent?.name}</td>
                  </tr>
                  <tr className="main-table">
                    <td  className="talent">Talent Pool</td>
                    <td>{portfolio?.league?.name}</td>
                  </tr>
                  <tr className="main-table">
                    <td  className="talent"> Pool</td>
                    <td>{portfolio?.pool_score}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div
              className="table-responsive chartSec_table"
              style={{ filter: educationPopup && "blur(2px)" }}
            >
              <table className="table">
                <tbody>
                  <tr className="professional-table">
                    <td className="main-table">Professional <br/>Experience</td>
                    <td className="talent1" colSpan={3}>
                      {portfolio?.experiance}
                      Years
                    </td>
                  </tr>

                  {portfolio?.jobseeker_educations?.map(
                    (value, index) =>
                      portfolio?.jobseeker_educations.length == index + 1 && (
                        <tr className="professional-table">
                          <td className="main-table-2">
                            Higher <br/>Education
                            {/* /University/Year */}
                          </td>
                         
                          <td className="main-two">
                            <tr>
                              <td className="talent2">{value?.degree?.name}</td>
                            </tr>
                            <tr>
                              <td className="talent2">
                              {value?.university?.name}
                              </td>
                            </tr>
                            <tr>
                              <td className="talent-2">
                              {value?.end_date
                              ? new Date(value?.end_date).getFullYear()
                              : "N/A"}
                              </td>
                            </tr>
                          </td>
                        </tr>
                      )
                  )}
                </tbody>
              </table>
            </div>

            <div
              className="table-responsive chartSec_table"
              style={{ filter: educationPopup && "blur(2px)" }}
            >
              <table className="table  living-one">
                <tbody>
                 

                  <tr>
                    <td  className="living">Living City</td>
                    <td colSpan={3}>{portfolio?.users_detail?.city?.name}</td>
                  </tr>
                  <tr>
                    <td className="living2">Nationality</td>
                    <td colSpan={3}>
                      {portfolio?.users_detail?.nationality?.name}
                    </td>
                  </tr>



                </tbody>
              </table>
            </div>
            <div
              className="table-responsive chartSec_table"
              style={{ filter: educationPopup && "blur(2px)" }}
            >
              <table className="table  living-one">
                <tbody>
                       {portfolio?.jobseeker_certificates?.map(
                    (value, index) =>
                      portfolio?.jobseeker_certificates.length == index + 1 && (
                        <tr key={index} className="professional-table">
                          <td className="main-table-2" colSpan="2">
                            {index === 0
                              ? " Achievments"
                              : ""}
                          </td>
                          
                       
                        <td className="main-two">
                              <tr>
                                <td className="talent3">{value?.title}</td>
                              </tr>
                              <tr>
                                <td className="talent3">{value?.achievement?.name}</td>
                              </tr>
                              <tr>
                                <td className="talent-2">
                                {value?.start_date
                              ? new Date(value.start_date).getFullYear()
                              : "N/A"}
                                </td>
                              </tr>
                              </td>
                              </tr>
                        
                      )
                  )}
                 
                </tbody>
                </table>
                </div>
            {educationPopup && (
              <div className="middlePopup">
                <div className="popupContent">
                  <div
                    className="alert-popup"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Lottie options={defaultOptions} height={150} width={150} />
                    <p className="message">Education Data Not filled Yet...!</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="col-lg-8">
          <div className="card radar_chart mb-3">
            <h5 className="text-center mb-0">Expertise</h5>

            {experiancedata?.variables?.length ? (
              <Radar
                width={500}
                height={500}
                padding={70}
                domainMax={experiancedata?.max_experiance + 2}
                highlighted={null}
                fill="red"
                onHover={(point) => {
                  if (point) {
                    // console.log("hovered over a data point");
                  } else {
                    // console.log("not over anything");
                  }
                }}
                data={{
                  variables: experiancedata?.variables,
                  sets: [
                    {
                      key: "me",
                      label: "My Scores",
                      values: experiancedata?.values,
                    },
                  ],
                }}
                className="custom-radar"
              />
            ) : (
              <div className="middlePopup">
                <div className="popupContent">
                  <div
                    className="alert-popup"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Lottie options={defaultOptions} height={150} width={150} />
                    <p className="message">Insufficient Data...!</p>
                  </div>
                </div>
              </div>
            )}

            {/* <div className='col-lg-4'>
                            <div className='card mb-3'>

                                <CustomGaugeChart
                                    id="gauge-chart5"
                                    nrOfLevels={420}
                                    arcsLength={[0.25, 0.25, 0.25, 0.25]}
                                    colors={['#5BE12C', '#F5CD19', '#EA4228']}
                                    percent={0.37}
                                    arcPadding={0.02}
                                />
                                <div className='score-button text-center mt-4 mb-2'>
                                    <Link to="/innerPage" >   <div className='btn btn-score'>Search a Suitable Job</div></Link>
                                </div>
                            </div>
                        </div> */}
          </div>
        </div>
      </div>

      {/* mobile-view-start */}
      <section className="mobile-view-chart">
        <div className="row ">
          <div className="row mx-auto">
            <div className="col-4"></div>
            <div className="col-8">
              <div className="heading-holder text-center">
                <h4>My Portfolio</h4>
              </div>
            </div>
          </div>
          <div className="col-12 p-0">
            <div className="card mb-3">
              <div
                className="row"
                style={{ filter: educationPopup && "blur(2px)" }}
              >
                <div className="col-4">
                  <div className="profile-img">
                    <img
                      src={
                        portfolio?.image
                          ? imageUrl + portfolio?.image
                          : process.env.PUBLIC_URL +
                            "assets/Images/my-portfolio/profile-img.png"
                      }
                    />
                    <h6 className="mt-1 ChartuserName">
                      {portfolio?.first_name}
                    </h6>
                  </div>
                </div>
                <div className="col-8">
                  <div className="name-holder mt-3">
                    <h5 className="">
                      I'm {portfolio?.first_name} {portfolio?.last_name}
                    </h5>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "assets/Images/my-portfolio/export-img.png"
                      }
                      className="export-img1 me-2"
                    />
                    <span>{portfolio?.tag && portfolio?.tag}</span>
                    <p className="jobtype">
                      <b>Job type</b>(
                      {portfolio?.jobseeker_job_types
                        ?.map((d) => d?.job_type?.name)
                        .join(", ")}
                      )
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="table-responsive chartSec_table"
                style={{ filter: educationPopup && "blur(2px)" }}
              >
                <table className="table">
                  <tbody>
                    <tr className="main-table">
                      <td className="talent">Talent</td>
                      <td>{portfolio?.talent?.name}</td>
                    </tr>
                    <tr className="main-table">
                      <td className="talent">Talent Pool</td>
                      <td>{portfolio?.league?.name}</td>
                    </tr>
                    <tr className="main-table">
                      <td className="talent"> Pool</td>
                      <td>{portfolio?.pool_score}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div
                className="table-responsive chartSec_table"
                style={{ filter: educationPopup && "blur(2px)" }}
              >
                <table className="table">
                  <tbody>
                    <tr className="professional-table">
                      <td className="main-table-edu">Professional Experience</td>
                      <td className="talent-university" colSpan={3}>
                        
                        {portfolio?.experiance}
                        Years
                      </td>
                    </tr>
                    {portfolio?.jobseeker_educations?.map(
                      (value, index) =>
                        portfolio?.jobseeker_educations.length == index + 1 && (
                          <tr className="professional-table">
                            <td className="main-table-edu">Highest <br/> Education</td>
                            <td className="talent-university">{value?.university?.name}</td>
                          </tr>
                        )
                    )}
                  </tbody>
                </table>
              </div>

              <div
                className="table-responsive chartSec_table"
                style={{ filter: educationPopup && "blur(2px)" }}
              >
                <table className="table living-one ">
                  <tbody>
                   

                    <tr>
                      <td className="living">Living City</td>
                      <td>{portfolio?.users_detail?.city?.name}</td>
                    </tr>
                    <tr>
                      <td className="living2">Nationality</td>
                      <td>{portfolio?.users_detail?.nationality?.name}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              



              <div
                className="table-responsive chartSec_table"
                style={{ filter: educationPopup && "blur(2px)" }}
              >
                <table className="table living-one ">
                  <tbody>
                  {portfolio?.jobseeker_certificates?.map((value, index) => (
                      <tr key={index}>
                        <td className="achevie">
                          {index === 0 ? "Achievments" : ""}
                        </td>
                        <td>{value?.title}</td>
                      </tr>
                    ))}
                    </tbody>
                </table>
              </div>
            
                     
 
              {educationPopup && (
                <div className="middlePopup">
                  <div className="popupContent">
                    <div
                      className="alert-popup"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Lottie
                        options={defaultOptions}
                        height={150}
                        width={150}
                      />
                      <p className="message">
                        Complete Your Education Form...!
                      </p>
                      <button
                        className="btn btn-danger"
                        type="button"
                        onClick={() => {
                          window.location.href = `/signup-job/${jobSeekerData?.steps}`;
                        }}
                      >
                        Go to form
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="col-12 p-0">
            <div className="card radar_chart mb-3">
              <p className="text-center">Expertise</p>
              {experiancedata?.variables?.length && (
                <Radar
                  width={500}
                  height={500}
                  padding={70}
                  domainMax={experiancedata?.max_experiance + 2}
                  highlighted={null}
                  fill="red"
                  onHover={(point) => {
                    if (point) {
                      // console.log("hovered over a data point");
                    } else {
                      // console.log("not over anything");
                    }
                  }}
                  data={{
                    variables: experiancedata?.variables,
                    sets: [
                      {
                        key: "me",
                        label: "My Scores",
                        values: experiancedata?.values,
                      },
                    ],
                  }}
                  className="custom-radar" // Add a class to the Radar component
                />
              )}

              {!experiancedata?.variables?.length && (
                <div className="middlePopup">
                  <div className="popupContent">
                    <div
                      className="alert-popup"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Lottie
                        options={defaultOptions}
                        height={150}
                        width={150}
                      />
                      <p className="message">Complete Your Portfolio...!</p>
                      <button
                        className="btn btn-danger"
                        type="button"
                        onClick={() => {
                          window.location.href = `/signup-job/${jobSeekerData?.steps}`;
                        }}
                      >
                        Go to form
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default PortfolioChart;
