import React from "react";
import '../TermsCondition/TermsCondition.css'

const TermsCondition = () => {
     return (
          <>
               <section className="terms-condition">
                    <div className='container'>
                         <div className="row mt-5">
                              <div className="col-md-12">
                                   <div className="title-terms text-center">
                                        <h5>Terms and Conditions</h5>
                                   </div>
                                   <div className="subtitle">
                                        <h2>Introduction</h2>
                                        <p>These Website Standard Terms and Conditions written on this webpage shall manage your use of our website, Webiste Name accessible at Hiclousia.com.</p>
                                        <p>These Terms will be applied fully and affect to your use of this Website. By using this Website, you agreed to accept all terms and conditions written in here. You must not use this Website if you disagree with any of these Website Standard Terms and Conditions.</p>
                                   </div>
                                   <div className="subtitle">
                                        <h2>Intellectual Property Rights</h2>
                                        <p>Other than the content you own, under these Terms, Company Name and/or its licensors own all the intellectual property rights and materials contained in this Website.</p>
                                        <p>You are granted limited license only for purposes of viewing the material contained on this Website.</p>
                                   </div>
                                   <div className="subtitle">
                                        <h2>Your Content</h2>
                                        <p>In these Website Standard Terms and Conditions, “Your Content” shall mean any audio, video text, images or other material you choose to display on this Website. By displaying Your Content, you grant Company Name a non-exclusive, worldwide irrevocable, sub licensable license to use, reproduce, adapt, publish, translate and distribute it in any and all media..</p>
                                        <p>Your Content must be your own and must not be invading any third-party's rights. Company Name reserves the right to remove any of Your Content from this Website at any time without notice.</p>
                                   </div>
                                   <div className="subtitle">
                                        <h2>Limitation of liability</h2>
                                        <p>In no event shall Company Name, nor any of its officers, directors and employees, shall be held liable for anything arising out of or in any way connected with your use of this Website whether such liability is under contract.  Company Name, including its officers, directors and employees shall not be held liable for any indirect, consequential or special liability arising out of or in any way related to your use of this Website.</p>
                                   </div>
                                   <div className="subtitle">
                                        <h2>Severability</h2>
                                        <p>If any provision of these Terms is found to be invalid under any applicable law, such provisions shall be deleted without affecting the remaining provisions herein.</p>
                                   </div>
                                   <div className="subtitle">
                                        <h2>Variation of Terms</h2>
                                        <p>Company Name is permitted to revise these Terms at any time as it sees fit, and by using this Website you are expected to review these Terms on a regular basis.</p>
                                   </div>
                                   <div className="subtitle">
                                        <h2>Assignment</h2>
                                        <p>The Company Name is allowed to assign, transfer, and subcontract its rights and/or obligations under these Terms without any notification. However, you are not allowed to assign, transfer, or subcontract any of your rights and/or obligations under these Terms.</p>
                                   </div>
                                   <div className="subtitle">
                                        <h2>Entire Agreement</h2>
                                        <p>These Terms constitute the entire agreement between Company Name and you in relation to your use of this Website, and supersede all prior agreements and understandings.</p>
                                   </div>
                                   <div className="subtitle">
                                        <h2>Governing Law & Jurisdiction</h2>
                                        <p>These Terms will be governed by and interpreted in accordance with the laws of the State of Country, and you submit to the non-exclusive jurisdiction of the state and federal courts located in Country for the resolution of any disputes.</p>
                                   </div>
                              </div>
                              <div className="text-center mb-3">
                                   <button className="btn decline me-2">Decline</button>
                                   <button className="btn accept">Accept</button>
                              </div>
                         </div>
                    </div>
               </section>
          </>
     )
}

export default TermsCondition;