import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import Collapse from "react-bootstrap/Collapse";
import DesiredMobileView from "./DesiredMobileView";
import "./DesiredMobileView.css";

const DesiredJobs = () => {
  const [open, setOpen] = useState(false);
  const [rangeValue, setRangeValue] = useState(50);
  const [sidebarClass, setSidebarClass] = useState("sidebar_height");
  const [show, setShow] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);

  const openModal = () => {
    setModalShow(true);
    setTimeout(() => {
      setModalShow(false);
    }, 2000);
  };

  const toggleOpen = () => {
    setOpen(!open);

    if (open) {
      setSidebarClass("sidebar_height");
    } else {
      setSidebarClass("sidebar_height show-more-active");
    }
  };
  const jobData = [
    {
      title: "Full Stack Developer",
      location: "Pune",
      imageSource: process.env.PUBLIC_URL + "assets/Images/logo/Tata.png",
      jobType: ["Full Time", "Part Time", "Freelance"],
    },
    {
      title: "Front End Developer",
      location: "Bangalore",
      imageSource: process.env.PUBLIC_URL + "assets/Images/logo/Tata.png",
      jobType: ["Full Time", "Part Time", "Freelance"],
    },
    {
      title: "BackEnd Developer",
      location: "Bangalore",
      imageSource: process.env.PUBLIC_URL + "assets/Images/logo/Tata.png",
      jobType: ["Full Time", "Part Time", "Freelance"],
    },
    {
      title: "Graphic Designing",
      location: "Bangalore",
      imageSource: process.env.PUBLIC_URL + "assets/Images/logo/Tata.png",
      jobType: ["Full Time", "Part Time", "Freelance"],
    },
    {
      title: "UI/UX Developer",
      location: "Bangalore",
      imageSource: process.env.PUBLIC_URL + "assets/Images/logo/Tata.png",
      jobType: ["Full Time", "Part Time", "Freelance"],
    },
    {
      title: "Digital Marketing",
      location: "Bangalore",
      imageSource: process.env.PUBLIC_URL + "assets/Images/logo/Tata.png",
      jobType: ["Full Time", "Part Time", "Freelance"],
    },
    {
      title: "BackEnd  Developer",
      location: "Bangalore",
      imageSource: process.env.PUBLIC_URL + "assets/Images/logo/Tata.png",
      jobType: ["Full Time", "Part Time", "Freelance"],
    },
    {
      title: "Front End Developer",
      location: "Bangalore",
      imageSource: process.env.PUBLIC_URL + "assets/Images/logo/Tata.png",
      jobType: ["Full Time", "Part Time", "Freelance"],
    },
    {
      title: "Full Stack Developer",
      location: "Bangalore",
      imageSource: process.env.PUBLIC_URL + "assets/Images/logo/Tata.png",
      jobType: ["Full Time", "Part Time", "Freelance"],
    },
  ];
  const handleRangeChange = (event) => {
    setRangeValue(event.target.value);
  };

  return (
    <>
      <section className="Featured_Category">
        <div className="container">
          <div className="desktop-view-desired">
            <div className="row  mt-5 p-0">
              <div className="col-lg-3 col-md-4">
                <div className="sidebar_featured_sec">
                  <h3 className="job_filter_txt">Job Filters</h3>
                  <div className={sidebarClass}>
                    <div className="down_content_sidebar ">
                      <div className="">
                        <p className="companyName_txt">Job Availability</p>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="flexCheckIndeterminateDisabled"
                          />
                          <label
                            className="form-check-label"
                            for="flexCheckIndeterminateDisabled"
                          >
                            Open
                          </label>
                        </div>
                        <div className="closedCheckbox_sec form-check mt-2">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="flexCheckIndeterminateDisabled"
                          />
                          <label
                            className="form-check-label"
                            for="flexCheckIndeterminateDisabled"
                          >
                            Closed
                          </label>
                        </div>
                      </div>
                      <div className="mt-4 CompanyName_section">
                        <p className="companyName_txt">Company Name</p>
                        <input
                          className="CompanyName"
                          type="text"
                          placeholder="Desired Company Name"
                        />
                        <div className="line"></div>
                      </div>
                      <div className="mt-4 CompanyName_section">
                        <p className="companyName_txt">Job Sector</p>
                        <input
                          className="CompanyName"
                          type="text"
                          placeholder="Desired Company Name"
                        />
                        <div className="line"></div>
                      </div>
                      <div className="CompanyName_section companyName_txt">
                        <p className="mt-4">Role</p>
                        <input
                          className="CompanyName"
                          type="text"
                          placeholder="Desired Company Name"
                        />
                      </div>
                      <div className="CompanyName_section companyName_txt">
                        <p className="mt-4">Highest Education</p>
                        <input
                          className="CompanyName"
                          type="text"
                          placeholder="Desired Company Name"
                        />
                      </div>
                      <div className="CompanyName_section companyName_txt">
                        <p className="mt-4">Primary Skills</p>
                        <select
                          className="form-select"
                          aria-label="Default select example"
                        >
                          <option selected>Desired Location</option>
                          <option value="1">Pune</option>
                          <option value="2">Mumbai</option>
                          <option value="3">Nashik</option>
                        </select>
                      </div>
                      <div className="CompanyName_section companyName_txt">
                        <p className="mt-4">Secondary Skills</p>
                        <select
                          className="form-select"
                          aria-label="Default select example"
                        >
                          <option selected>Desired Location</option>
                          <option value="1">Pune</option>
                          <option value="2">Mumbai</option>
                          <option value="3">Nashik</option>
                        </select>
                      </div>
                      <div className="CompanyName_section companyName_txt">
                        <p className="mt-4">Highest Education</p>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="flexCheckIndeterminateDisabled"
                          />
                          <label
                            className="form-check-label"
                            for="flexCheckIndeterminateDisabled"
                          >
                            Expert
                          </label>
                        </div>
                        <div className="mt-2 form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="flexCheckIndeterminateDisabled"
                          />
                          <label
                            className="form-check-label"
                            for="flexCheckIndeterminateDisabled"
                          >
                            Advance
                          </label>
                        </div>
                        <div className="mt-2 form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="flexCheckIndeterminateDisabled"
                          />
                          <label
                            className="form-check-label"
                            for="flexCheckIndeterminateDisabled"
                          >
                            Proficient
                          </label>
                        </div>
                      </div>
                      <div className="CompanyName_section companyName_txt">
                        <p className="mt-4">Salary Scale</p>

                        <div className="custom-range-slider">
                          <input
                            type="range"
                            min={0}
                            max={100}
                            value={rangeValue}
                            onChange={handleRangeChange}
                          />
                          <div
                            className="custom-track"
                            style={{ width: `${rangeValue}%` }}
                          ></div>
                          <p>Value: {rangeValue}</p>
                        </div>
                      </div>
                      <div className="CompanyName_section ">
                        <p className="mt-4 companyName_txt">Location</p>
                        <select
                          className="form-select"
                          aria-label="Default select example"
                        >
                          <option selected>Desired Location</option>
                          <option value="1">Pune</option>
                          <option value="2">Mumbai</option>
                          <option value="3">Nashik</option>
                        </select>
                      </div>
                      <div className="CompanyName_section">
                        <p className="mt-4 companyName_txt">Job Type</p>
                        <div className="mt-2 form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="flexCheckIndeterminateDisabled"
                          />
                          <label
                            className="form-check-label"
                            for="flexCheckIndeterminateDisabled"
                          >
                            Full Time
                          </label>
                        </div>
                        <div className="mt-2 form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="flexCheckIndeterminateDisabled"
                          />
                          <label
                            className="form-check-label"
                            for="flexCheckIndeterminateDisabled"
                          >
                            Part Time
                          </label>
                        </div>
                        <div className="mt-2 form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="flexCheckIndeterminateDisabled"
                          />
                          <label
                            className="form-check-label"
                            for="flexCheckIndeterminateDisabled"
                          >
                            Free Lance
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-9 col-md-8">
                <div
                  className="tab-content featuredJobs_rightSide"
                  id="pills-tabContent"
                >
                  <div
                    className="tab-pane fade show active"
                    id="pills-Management"
                    role="tabpanel"
                    aria-labelledby="pills-Management-tab"
                    tabIndex="0"
                  >
                    <div className="row">
                      {jobData.map((job, index) => (
                        <div className="col-lg-4 col-md-6 mb-md-3" key={index}>
                          <div className="card card_left-sec">
                            <div className="card-body pb-0">
                              <div className="card-title ">
                                <img src={job.imageSource} className="Tata" />
                              </div>
                              <div className="mt-3">
                                {job.jobType.map((type, i) => (
                                  <span className="fullTime_txt" key={i}>
                                    {type}
                                  </span>
                                ))}
                              </div>
                              <p className="card-text card_info">{job.title}</p>
                              <p className="city_txt">{job.location}</p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="showMore_btn_section justify-content-center text-center">
                      <Dropdown>
                        {!open && (
                          <Dropdown.Toggle
                            variant="primary"
                            id="dropdown-basic"
                            className="showMore_btn"
                            onClick={() => setOpen(!open)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open}
                          >
                            Show More
                          </Dropdown.Toggle>
                        )}
                        <Collapse in={open}>
                          <div id="example-collapse-text">
                            <div className="row">
                              <div className="col-lg-4 col-md-6 mb-md-3">
                                <div className="card card_left-sec pb-0">
                                  <div className="card-body pb-0">
                                    <div className="card-title ">
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "assets/Images/logo/bajaj.png"
                                        }
                                        className="Tata"
                                      />
                                    </div>
                                    <div className="mt-3">
                                      <span className="fullTime_txt">
                                        Full Time
                                      </span>
                                      <span className="fullTime_txt">
                                        Part Time
                                      </span>
                                      <span className="fullTime_txt">
                                        Freelance
                                      </span>
                                    </div>
                                    <p className="card-text card_info">
                                      Graphic Designer
                                    </p>
                                    <p className="city_txt">Pune</p>
                                    <button className=" btn btn-2 hover-slide-up">
                                      <span>Explore</span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-6 mb-md-3">
                                <div className="card_left-sec card">
                                  <div className="card-body pb-0">
                                    <div className="card-title ">
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "assets/Images/logo/Wipro.png"
                                        }
                                        className="Tata"
                                      />
                                    </div>
                                    <div className="mt-3">
                                      <span className="fullTime_txt">
                                        Full Time
                                      </span>
                                      <span className="fullTime_txt">
                                        Part Time
                                      </span>
                                      <span className="fullTime_txt">
                                        Freelance
                                      </span>
                                    </div>
                                    <p className="card-text card_info">
                                      Back End Developer
                                    </p>
                                    <p className="city_txt">Nagpur</p>
                                    <button className=" btn btn-2 hover-slide-up">
                                      <span>Explore</span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-6 mb-md-3">
                                <div className="card_left-sec mb-4 card">
                                  <div className="pb-0 card-body">
                                    <div className="card-title ">
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "assets/Images/logo/airtel.png"
                                        }
                                        className="Tata"
                                      />
                                    </div>
                                    <div className="mt-3">
                                      <span className="fullTime_txt">
                                        Full Time
                                      </span>
                                      <span className="fullTime_txt">
                                        Part Time
                                      </span>
                                      <span className="fullTime_txt">
                                        Freelance
                                      </span>
                                    </div>
                                    <p className="card-text card_info">
                                      UI Designer
                                    </p>
                                    <p className="city_txt">Pune</p>
                                    <button className=" btn btn-2 hover-slide-up">
                                      <span>Explore</span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Collapse>
                        {open && (
                          <button
                            className="btn btn-2 hover-slide-up showMore_btn"
                            onClick={toggleOpen}
                          >
                            <span>Show Less</span>
                          </button>
                        )}
                      </Dropdown>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-Development"
                    role="tabpanel"
                    aria-labelledby="pills-Development-tab"
                    tabIndex="0"
                  >
                    <div className="row">
                      {jobData.map((job, index) => (
                        <div className="col-lg-4" key={index}>
                          <div className="card mb-3 card_left-sec">
                            <div className="card-body pb-0">
                              <div className="card-title ">
                                <img src={job.imageSource} className="Tata" />
                              </div>
                              <div className="mt-3">
                                {job.jobType.map((type, i) => (
                                  <span className="fullTime_txt" key={i}>
                                    {type}
                                  </span>
                                ))}
                              </div>
                              <p className="card-text card_info">{job.title}</p>
                              <p className="city_txt">{job.location}</p>
                              <div className="explre_btn_sec">
                                <button className="btn btn-2 hover-slide-up">
                                  <span>Explore</span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-Health"
                    role="tabpanel"
                    aria-labelledby="pills-Health-tab"
                    tabIndex="0"
                  >
                    <div className="row">
                      {jobData.map((job, index) => (
                        <div className="col-lg-4" key={index}>
                          <div className="card mb-3 card_left-sec">
                            <div className="card-body pb-0">
                              <div className="card-title ">
                                <img src={job.imageSource} className="Tata" />
                              </div>
                              <div className="mt-3">
                                {job.jobType.map((type, i) => (
                                  <span className="fullTime_txt" key={i}>
                                    {type}
                                  </span>
                                ))}
                              </div>
                              <p className="card-text card_info">{job.title}</p>
                              <p className="city_txt">{job.location}</p>
                              <div className="explre_btn_sec">
                                <button className="btn btn-2 hover-slide-up">
                                  <span>Explore</span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-Education"
                    role="tabpanel"
                    aria-labelledby="pills-Education-tab"
                    tabIndex="0"
                  >
                    ...
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-Finance"
                    role="tabpanel"
                    aria-labelledby="pills-Finance-tab"
                    tabIndex="0"
                  >
                    ...
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-Telecommunication"
                    role="tabpanel"
                    aria-labelledby="pills-Telecommunication-tab"
                    tabIndex="0"
                  >
                    ...
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-MoreJobs"
                    role="tabpanel"
                    aria-labelledby="pills-MoreJobs-tab"
                    tabIndex="0"
                  >
                    ...
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Moblie view */}
          <div className="mobile-view-desired">
            <DesiredMobileView />
          </div>
        </div>
      </section>
    </>
  );
};

export default DesiredJobs;
