import React, { useState } from 'react';
import '../MatchedJob/MatchedJob.css'
import ThirdJobTabModal from '../DashBoardJob/ThirdJobTab/ThirdJobTabModal';

const MatchedJob = () => {
    const [isModalOpen, setModalOpen] = useState(false);

    const [modalShow, setModalShow] = React.useState(false);

    const openModal = () => {
        setModalShow(true);

        setTimeout(() => {
            window.location.href = '/dashboard-job';
            setModalShow(false);
        }, 6000);
    };
    const closeModal = () => {
        setModalOpen(false);
    };
    return (
        <>
            <section className="manage-job">
                <div className="row me-0 ms-0">
                    <div className="col-lg-4 col-md-4 col-sm-3 col-3  mt-lg-5 mt-md-5 mt-3">
                        <div>
                            <div className="black-title-line mt-3"></div>
                        </div>

                    </div>
                    <div className='col-lg-4  col-md-4 col-sm-6 col-6 text-center mt-lg-5 mt-md-5 mt-3'>
                        <h5 className="title-talent ">Matched Job</h5>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-3 col-3  mt-lg-5 mt-md-5 mt-3">
                        <div>
                            <div className="black-title-line mt-3"></div>
                        </div>
                    </div>
                </div>
                <div className='container'>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="Third-job mt-md-4 mt-3">
                                <div className="row" >
                                    <div className="col-xxl-1 col-xl-1 col-lg-2  col-md-2 col-sm-12 col-12 ">
                                        <div className=" mt-md-3 mt-0 mx-auto d-flex">
                                            <div className='companyimg-desktop' >
                                                <img src={process.env.PUBLIC_URL + "assets/Images/logo/infosys.png"} className="companyimg1" alt="..." />
                                            </div>
                                            <div className='companyimg-mobile'>
                                                <img src={process.env.PUBLIC_URL + "assets/Images/logo/infosys.png"} className="companyimg1" alt="..." />
                                            </div>
                                            <div className="company-name ms-2 mt-2">
                                                <p>Profcyma Solution Pvt. Ltd.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="text-holder-manage-job">
                                            <div className='desktop-view-text'>
                                                <p>Company Name:</p>
                                                <h5>Profcyma Solution Pvt. Ltd.</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="text-holder-manage-job">
                                            <p>Position:</p>
                                            <h5>Data Analyst</h5>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="text-holder-manage-job">
                                            <p>Status of applied Job:</p>
                                            <h5>You are in hiring pipeline</h5>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="text-holder-manage-job">
                                            <p>Response from recruiter:</p>
                                            <h5>Portfolio Matched</h5>

                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4">
                                        <div className='desktop-matched-job'>
                                            <div className="text-holder-manage-job text-lg-center text-md-center text-start">
                                                <p>My Response:</p>
                                                <div className='d-flex'>
                                                    <button type="button" onClick={openModal} className="btn accpet-blue-btn me-2">Accept</button>
                                                    <ThirdJobTabModal show={modalShow} onHide={() => setModalShow(false)} />
                                                    <button type="button" onClick={openModal} className="btn btn-decline me-2">Decline</button>
                                                    <ThirdJobTabModal show={modalShow} onHide={() => setModalShow(false)} />
                                                    <button type="button" onClick={openModal} className="btn btn-exit">Exit</button>
                                                    <ThirdJobTabModal show={modalShow} onHide={() => setModalShow(false)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Mobile view btn */}
                                    <div className="col-lg-3 col-md-4">
                                        <div className='mobile-matched-job mb-3'>
                                            <div className="text-holder-manage-job ">
                                                <p>My Response:</p>
                                                <div className='d-flex'>
                                                    <button type="button" onClick={openModal} className="btn accpet-blue-btn-mobile me-2">Accept</button>
                                                    <ThirdJobTabModal show={modalShow} onHide={() => setModalShow(false)} />
                                                    <button type="button" onClick={openModal} className="btn decline-blue-btn me-2">Decline</button>
                                                    <ThirdJobTabModal show={modalShow} onHide={() => setModalShow(false)} />
                                                    <button type="button" onClick={openModal} className="btn exit-blue-btn">Exit</button>
                                                    <ThirdJobTabModal show={modalShow} onHide={() => setModalShow(false)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="Third-job mt-4">
                                <div className="row" >
                                    <div className="col-xxl-1 col-xl-1 col-lg-2  col-md-2 col-sm-12 col-12 ">
                                        <div className=" mt-md-3 mt-0 mx-auto d-flex">

                                            <div className='companyimg-desktop' >
                                                <img src={process.env.PUBLIC_URL + "assets/Images/logo/profcyma.png"} className="companyimg1" alt="..." />
                                            </div>
                                            <div className='companyimg-mobile'>
                                                <img src={process.env.PUBLIC_URL + "assets/Images/logo/profcyma.png"} className="companyimg1" alt="..." />
                                            </div>
                                            <div className="company-name ms-2 mt-2">
                                                <p>Profcyma Solution Pvt. Ltd.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="text-holder-manage-job">
                                            <div className='desktop-view-text'>
                                                <p>Company Name:</p>
                                                <h5>Profcyma Solution Pvt. Ltd.</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="text-holder-manage-job">
                                            <p>Position:</p>
                                            <h5>Data Analyst</h5>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="text-holder-manage-job">
                                            <p>Status of applied Job:</p>
                                            <h5>You are in hiring pipeline</h5>
                                        </div>

                                    </div>
                                    <div className="col-md-2">
                                        <div className="text-holder-manage-job">
                                            <p>Response from recruiter:</p>
                                            <h5>Portfolio Matched</h5>

                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4">
                                        <div className='desktop-matched-job'>
                                            <div className="text-holder-manage-job text-lg-center text-md-center text-start">
                                                <p>My Response:</p>
                                                <div className='d-flex'>
                                                    <button type="button" onClick={openModal} className="btn accpet-blue-btn me-2">Accept</button>
                                                    <ThirdJobTabModal show={modalShow} onHide={() => setModalShow(false)} />
                                                    <button type="button" onClick={openModal} className="btn  btn-decline me-2">Decline</button>
                                                    <ThirdJobTabModal show={modalShow} onHide={() => setModalShow(false)} />
                                                    <button type="button" onClick={openModal} className="btn btn-exit">Exit</button>
                                                    <ThirdJobTabModal show={modalShow} onHide={() => setModalShow(false)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Mobile view btn */}
                                    <div className="col-lg-3 col-md-4">
                                        <div className='mobile-matched-job mb-3'>
                                            <div className="text-holder-manage-job">
                                                <p>My Response:</p>
                                                <div className='d-flex'>
                                                    <button type="button" onClick={openModal} className="btn accpet-blue-btn-mobile me-2">Accept</button>
                                                    <ThirdJobTabModal show={modalShow} onHide={() => setModalShow(false)} />
                                                    <button type="button" onClick={openModal} className="btn decline-blue-btn me-2">Decline</button>
                                                    <ThirdJobTabModal show={modalShow} onHide={() => setModalShow(false)} />
                                                    <button type="button" onClick={openModal} className="btn exit-blue-btn">Exit</button>
                                                    <ThirdJobTabModal show={modalShow} onHide={() => setModalShow(false)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="Third-job mt-4">
                                <div className="row" >
                                    <div className="col-xxl-1 col-xl-1 col-lg-2  col-md-2 col-sm-12 col-12 ">
                                        <div className=" mt-md-3 mt-0 mx-auto d-flex">

                                            <div className='companyimg-desktop' >
                                                <img src={process.env.PUBLIC_URL + "assets/Images/logo/cognizant.png"} className="companyimg1" alt="..." />
                                            </div>
                                            <div className='companyimg-mobile'>
                                                <img src={process.env.PUBLIC_URL + "assets/Images/logo/cognizant.png"} className="companyimg1" alt="..." />
                                            </div>
                                            <div className="company-name ms-2 mt-2">
                                                <p>Conizant</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="text-holder-manage-job">
                                            <div className='desktop-view-text'>
                                                <p>Company Name:</p>
                                                <h5>Profcyma Solution Pvt. Ltd.</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="text-holder-manage-job">
                                            <p>Position:</p>
                                            <h5>Data Analyst</h5>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="text-holder-manage-job">
                                            <p>Status of applied Job:</p>
                                            <h5>You are in hiring pipeline</h5>
                                        </div>

                                    </div>
                                    <div className="col-md-2">
                                        <div className="text-holder-manage-job">
                                            <p>Response from recruiter:</p>
                                            <h5>Portfolio Matched</h5>

                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4">
                                        <div className='desktop-matched-job'>
                                            <div className="text-holder-manage-job text-lg-center text-md-center text-start">
                                                <p>My Response:</p>
                                                <div className='d-flex'>
                                                    <button type="button" onClick={openModal} className="btn accpet-blue-btn me-2">Accept</button>
                                                    <ThirdJobTabModal show={modalShow} onHide={() => setModalShow(false)} />
                                                    <button type="button" onClick={openModal} className="btn btn-decline me-2">Decline</button>
                                                    <ThirdJobTabModal show={modalShow} onHide={() => setModalShow(false)} />
                                                    <button type="button" onClick={openModal} className="btn btn-exit">Exit</button>
                                                    <ThirdJobTabModal show={modalShow} onHide={() => setModalShow(false)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Mobile view btn */}
                                    <div className="col-lg-3 col-md-4">
                                        <div className='mobile-matched-job mb-3'>
                                            <div className="text-holder-manage-job">
                                                <p>My Response:</p>
                                                <div className='d-flex'>
                                                    <button type="button" onClick={openModal} className="btn accpet-blue-btn-mobile me-2">Accept</button>
                                                    <ThirdJobTabModal show={modalShow} onHide={() => setModalShow(false)} />
                                                    <button type="button" onClick={openModal} className="btn decline-blue-btn me-2">Decline</button>
                                                    <ThirdJobTabModal show={modalShow} onHide={() => setModalShow(false)} />
                                                    <button type="button" onClick={openModal} className="btn exit-blue-btn">Exit</button>
                                                    <ThirdJobTabModal show={modalShow} onHide={() => setModalShow(false)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-12 mb-5'>
                            <div>
                                {/* <ChatBotJob /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default MatchedJob;