import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import JobCategory from "../../../CommonPage/Home/JobCategory/JobCategory";
import "./JobTab.css";
import FirstTab1 from "./FirstTab1/FirstTab1";
import SecondJobTab from "../SecondJobTab/SecondJobTab";
import ThirdJobTab from "../ThirdJobTab/ThirdJobTab";
import JobAlert from "../JobAlert/JobAlert";
import SixthJobTab from "../SixthJobTab/SixthJobTab";
import FifthJobTab from "../FifithJobTab/FifthJobTab";
import ExploreOpportunity from "../ExploreOpportunity/ExploreOpportunity";
import MyPortfolio from "../../my-portfolio/MyPortfolio";
import "swiper/swiper-bundle.css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import ScrollableTab from "../../ScrollableTab/ScrollableTab";
const JobTabs = () => {
  return (
    <>
      <section className="main-tabs">
        <div className="container-fluid">
          <div className="main-design-tab msindddd">
            <div className="main-design-desktop-tab">
              <Tabs>
                <div className="container">
                  <TabList className="row  justify-content-center p-0">
                    <Tab className="col-xxl-2 col-xl-2 col-lg-2 col-md-3 text-center">
                      My Portfolio
                    </Tab>
                    <Tab className="col-xxl-2 col-xl-2 col-lg-2 col-md-3 text-center">
                      Dashboard
                    </Tab>
                    <Tab className="col-xxl-2 col-xl-2 col-lg-2 col-md-3 text-center">
                      Shortlisted
                    </Tab>
                    <Tab className="col-xxl-2 col-xl-2 col-lg-2 col-md-3 text-center">
                      About Interview
                    </Tab>

                    <Tab className="col-xxl-2 col-xl-2 col-lg-2 col-md-3 text-center">
                      Saved Jobs
                    </Tab>
                    <Tab className="col-xxl-2 col-xl-2 col-lg-2 col-md-3 text-center">
                      Applied
                    </Tab>
                  </TabList>
                </div>

                <TabPanel>
                  <MyPortfolio />
                </TabPanel>
                
                <TabPanel>
                  <FirstTab1 />
                  <JobCategory alignment="left" />
                  <JobAlert />
                  <ExploreOpportunity />
                </TabPanel>

                {/* Secondtab */}
                <TabPanel>
                  <SecondJobTab />
                </TabPanel>

                {/* Thirdtab */}
                <TabPanel>
                  <ThirdJobTab />
                </TabPanel>

                {/* Fourthtab */}

                {/* Fifth tab */}
                <TabPanel>
                  <FifthJobTab />
                </TabPanel>

                {/* Sixth tab */}
                <TabPanel>
                  <SixthJobTab />
                </TabPanel>
              </Tabs>
            </div>
          </div>
          {/* Mobile view */}
          <div className="scrollable-tab-mobile ">
            <ScrollableTab />
          </div>
        </div>
      </section>
    </>
  );
};

export default JobTabs;
