import React from 'react'
import Lottie from "react-lottie";
import circle_json from "../../Animation/woman 1.json"
import "./Women.css"
const Women = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: circle_json,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <>
      <section className='lottie-animation-div'>
        <div className='circle-img text-center mt-2'>
          <img src={process.env.PUBLIC_URL + "assets/Images/banner/circle-round.png"} className='man_img_slider1' alt="..." />
          <div className='Women-lottie'>
            <Lottie
              options={defaultOptions}
            />
          </div>
        </div>

      </section>
    </>
  )
}

export default Women