import React from "react";
import "./Help.css";
import { Link } from "react-router-dom";

const Help = () => {
  return (
    <section className="help-section ">
      <div className="container-fluid p-0">
        <div className="complete-banner">
          <div className="help-banner">
            <img
              src={
                process.env.PUBLIC_URL +
                "assets/Images/banner/helpdesk/helpdeskbg.png"
              }
              className="help-banner-img"
            />
          </div>
          <div className="container">
            <div className="overlay">
              <div className="heading">
                <h1>We're Here to help</h1>
              </div>
              <div className="buttons">
                <div className="row">
                  <div className="col-6 custum-btn">
                    <Link to="/help">
                      {" "}
                      <button
                        className="btn createAcc_btn"
                        fdprocessedid="s74iip"
                      >
                        Help Desk
                      </button>
                    </Link>
                  </div>
                  <div className="col-6 custum-btn">
                    <Link to="/contact">
                      {" "}
                      <button
                        className="btn createAcc_btn"
                        fdprocessedid="s74iip"
                      >
                        Contact Us
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Help;
