import React from 'react'
import Modal from 'react-bootstrap/Modal';
import BuyNowAnimation from "./BuyNowAnimation/BuyNowAnimation"

const BuyNowModal = (props) => {
  return (
    <>
     <section className='PopupModal'>
        <Modal
          {...props}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          className='PopupModal'
          centered
          
        >
          
          <Modal.Body>
            <BuyNowAnimation/>
            <p className='modal_txt'> Your Pool has been Purchased Successfully! </p>
            <p className='text-center' style={{color: "#FFFF"}}> Have a Great Time! </p>
          </Modal.Body>
         
        </Modal>

      </section>
    </>
  )
}

export default BuyNowModal