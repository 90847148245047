import axiosClient from "./ApiInstance";
import Cookies from "js-cookie";

export const getData = async (url, data) => {
  try {
    const response = await axiosClient.get(
      process.env.REACT_APP_BASE_URL + url,
      data
    );
    return response.data;
  } catch (error) {
    // Cookies.remove("hiclousia_website", { path: "/" });
    // window.location.href = "/";
    // console.log(error);
  }
};

export const postData = async (url, data) => {
  try {
    const response = await axiosClient.post(
      process.env.REACT_APP_BASE_URL + url,
      data
    );

    return response.data;
  } catch (error) {
    // Cookies.remove("hiclousia_website", { path: "/" });
    // window.location.href = "/";
    // console.log(error);
  }
};

export const editStatusData = async (url, data) => {
  try {
    const response = await axiosClient.put(
      process.env.REACT_APP_BASE_URL + url,
      data
    );

    return response.data;
  } catch (error) {
    // Cookies.remove("hiclousia_website", { path: "/" });
    // window.location.href = "/";
    // console.log(error);
  }
};

export const deleteData = async (url, data = {}) => {
  try {
    const response = await axiosClient.delete(
      process.env.REACT_APP_BASE_URL + url,
      data
    );

    return response.data;
  } catch (error) {
    // Cookies.remove("hiclousia_website", { path: "/" });
    // window.location.href = "/";
    // console.log(error);
  }
};

// Download Apis
export const getDownloadDataExcel = async (aurl, data, name) => {
  try {
    const response = await axiosClient.post(
      process.env.REACT_APP_BASE_URL + aurl,
      data,
      {
        responseType: "blob", // Set responseType to "blob"
      }
    );

    const url = window.URL.createObjectURL(new Blob([response.data]));
    // Create a temporary anchor element to trigger the download
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `${name + " " + new Date().toLocaleDateString()}.xlsx`
    );
    document.body.appendChild(link);
    link.click();

    // Clean up after the download
    window.URL.revokeObjectURL(url);
  } catch (error) {
    // Cookies.remove("hiclousia_website", { path: "/" });
    // window.location.href = "/";
    // console.log(error);
  }
};

export const Select2Data = async (data, name, other = false) => {
  const result = data.map((data) => ({
    value: data?.id,
    label: data?.name,
    name: name,
  }));

  if (other) {
    result.push({ value: "0", label: "Other", name: name });
  }
  return result;
};

export const getJobSeekerData = async () => {
  try {
    const response = await getData(`/jobseeker/profile`);
    return response.data;
  } catch (error) {
    Cookies.remove("hiclousia_website", { path: "/" });
    window.location.href = "/";
    // console.log(error);
  }
};

export const PostGoogleLogin = async (url, values) => {
  try {
    const formData = new URLSearchParams();
    formData.append("token", values?.token);
    formData.append("type", values?.type);
    // console.log(url);
    // console.log(process.env.REACT_APP_BASE_URL + url);

    const response = await axiosClient.post(
      // "http://127.0.0.1:1212/api/without-login/google-login",
      process.env.REACT_APP_BASE_URL + url,
      formData.toString()
    );

    // console.log("Response data:", response.data);
    return response.data;
  } catch (error) {
    // Cookies.remove("hiclousia_website", { path: "/" });
    // window.location.href = "/";
    // console.log(error);
  }
};

export const Select2Skills = async (data, name, other = false) => {
  const result = data.map((data) => ({
    value: data?.id,
    label: data?.name + " - " + data?.skill_type?.name,
    name: name,
  }));

  if (other) {
    result.push({ value: "0", label: "Other", name: name });
  }
  return result;
};

export const Select2Roles = async (data, name, other = false) => {
  const result = data.map((data) => ({
    value: data?.id,
    label: data?.name + " - " + data?.role_subtype?.name,
    name: name,
  }));

  if (other) {
    result.push({ value: "0", label: "Other", name: name });
  }
  return result;
};
