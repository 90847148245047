import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link } from "react-router-dom";
import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement } from "chart.js";
import { Line } from "react-chartjs-2";
import GaugeChart from "react-gauge-chart";
import Radar from "react-d3-radar";
import Lottie from "react-lottie";
import alertAnimation from "./alert-lottie"; // Replace with the path to your Lottie JSON file
// import Button from 'react-bootstrap/Button';
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { MultiSelect } from "primereact/multiselect";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import Success from "../../../RecruiterPage/Model/Animation/Success.js";
import "./chartttt.css";
import {
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Legend,
} from "chart.js";

import { useContext } from "react";
import { Context } from "../../../../utils/context";
import Select from "react-select";
import { Label } from "semantic-ui-react";
const ChartSection = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: alertAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const [show, setShow] = useState(false);
  const [showone, setShowone] = useState(false);
  const [showtwo, setShowtwo] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCloseone = () => setShowone(false); // Use setShowone here
  const handleShowone = () => setShowone(true); // Use setShowone here

  const handleClosetwo = () => setShowtwo(false);

  const handleShowtwo = async () => {
    try {
      const data = new FormData();
      data.append("image", formData.image);
      data.append("first_name", formData.first_name);
      data.append("last_name", formData.last_name);
      data.append("tag", formData.tag);
      data.append("living_city_id", formData.living_city_id?.value);
      data.append("nationality_id", formData.nationality_id?.value);
      data.append("job_type_id", JSON.stringify(formData.job_type_id));
      const result = await postData("/jobseeker/signup/profile", data);
      if (result?.success) {
        handleCloseone();
        setShowtwo(true);
        await setTimeout(() => {
          seekerData();
          setShowtwo(false);
        }, 3000);
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const [selectedJobtype, setSelectedJob_type] = useState([]);

  useEffect(() => {
    setFormData({
      ...formData,
      ["job_type_id"]: selectedJobtype?.map((item) => item.value),
    });
  }, [selectedJobtype]);

  const handleChange = (e) => {
    if (e?.target?.name === "image") {
      const newFormData = {
        ...formData,
        image: e.target.files[0],
      };

      if (e.target.files[0]?.type.startsWith("image/")) {
        newFormData.imageUrl = URL.createObjectURL(e.target.files[0]);
      } else {
        newFormData.imageUrl = null;
      }

      setFormData(newFormData);
    } else if (e?.name == "nationality_id" || e?.name == "living_city_id") {
      setFormData({ ...formData, [e.name]: e });
    } else if (e?.target) {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const [educationPopup, setEducationPopup] = useState(true);
  const [chartPopup, setchartPopup] = useState(false);
  const [portfolio, setPortfolio] = useState();

  const {
    postData,
    jobSeekerData,
    setJobSeekerData,
    getData,
    getJobSeekerData,
    Select2Data,
    imageUrl,
  } = useContext(Context);
  const [experiancedata, setExperianceData] = useState({
    variables: [],
    values: {},
  });
  // console.log(experiancedata);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    image: "",
    tag: "",
    living_city_id: "",
    nationality_id: "",
    job_type_id: [],
  });
  const [Jobtypes, setJobTypes] = useState([]);
  const [Nationality, setNationality] = useState([]);
  const [Living, setLiving] = useState([]);
  const seekerData = async () => {
    const res = await getJobSeekerData();
    if (res?.id) {
      if (res.jobseeker_educations.length > 0) {
        setEducationPopup(false);
      }
      // if (res.steps === "10") {
      //   setchartPopup(false);
      // }
      await setPortfolio(res);

      await setFormData({
        first_name: res?.first_name,
        last_name: res?.last_name,
        tag: res?.tag,
        image: res?.image,
        living_city_id: {
          name: "living_city_id",
          value: res?.users_detail?.living_city_id,
          label: res?.users_detail?.city?.name,
        },
        nationality_id: {
          name: "nationality_id",
          value: res?.users_detail?.nationality_id,
          label: res?.users_detail?.nationality?.name,
        },
        job_type_id: res?.jobseeker_job_types?.map((d) => d?.job_type_id),
      });
      await setSelectedJob_type(
        res.jobseeker_job_types?.map((data) => ({
          value: data?.job_type_id,
          label: data?.job_type?.name,
        }))
      );
    } else {
      window.location.href = "/getotp-jobseeker";
    }

    const res2 = await getData("/jobseeker/profile/expertise");
    setExperianceData(res2.data);

    const res3 = await getData("/without-login/master/all-job-type");
    setJobTypes(await Select2Data(res3.data));

    const res4 = await getData("/without-login/master/all-nationality");
    setNationality(await Select2Data(res4.data, "nationality_id"));

    const res5 = await getData("/without-login/master/all-cities");
    setLiving(await Select2Data(res5.data, "living_city_id"));
  };

  useEffect(() => {
    seekerData();
  }, []);
  return (
    <>
      <div className="row desktop-view-chart">
        <div className="col-md-12">
          <div className="heading-holder text-center">
            <h4>My Portfolio</h4>
          </div>
        </div>
        <div className="col-lg-12 col-md-12">
          <div className="hr-line mt-3 mb-5"></div>
        </div>

        <div className="col-lg-4 ">
          <div className="card portfolio_card mb-3">
            <div
              className="row"
              style={{ filter: educationPopup && "blur(2px)" }}
            >
              <div className="col-lg-3 col-md-3">
                <div className="profile-img">
                  <img
                    src={
                      portfolio?.image
                        ? imageUrl + portfolio?.image
                        : process.env.PUBLIC_URL +
                          "assets/Images/my-portfolio/profile-img.png"
                    }
                  />
                  {/* <h6 className="mt-1">{portfolio?.first_name}</h6> */}
                </div>
              </div>
              <div className="col-lg-9 col-md-9">
                <div className="d-flex">
                  <div className="name-holder mt-3">
                    <h5 className="ms-4">
                      I'm {portfolio?.first_name} {portfolio?.last_name}
                    </h5>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "assets/Images/my-portfolio/export-img.png"
                      }
                      className="export-img1 ms-4 me-2"
                    />
                    <span>{portfolio?.tag && portfolio?.tag}</span>
                    <p className="jobtype">
                      <b>Job type</b>(
                      {portfolio?.jobseeker_job_types
                        ?.map((d) => d?.job_type?.name)
                        .join(", ")}
                      )
                    </p>
                  </div>
                  <div className="edit-icon">
                    <Button variant="edit" onClick={handleShowone}>
                      <img
                        className="edit"
                        src={
                          process.env.PUBLIC_URL +
                          "assets/Images/my-portfolio/edit-img.png"
                        }
                        alt="Edit"
                      />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="table-responsive chartSec_table"
              style={{ filter: educationPopup && "blur(2px)" }}
            >
              <table className="table">
                <tbody>
                  <tr className="main-table">
                    <td className="talent">Talent</td>
                    <td>{portfolio?.talent?.name}</td>
                  </tr>
                  <tr className="main-table">
                    <td className="talent">Talent Pool</td>
                    <td>{portfolio?.league?.name}</td>
                  </tr>
                  <tr className="main-table">
                    <td className="talent"> Pool</td>
                    <td>{portfolio?.pool_score}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div
              className="table-responsive chartSec_table"
              style={{ filter: educationPopup && "blur(2px)" }}
            >
              <table className="table">
                <tbody>
                  <tr className="professional-table">
                    <td className="main-table">
                      Professional <br /> Experience
                    </td>
                    <td className="talent1" colSpan={3}>
                      {portfolio?.experiance}
                      Years
                    </td>
                  </tr>
                  {portfolio?.jobseeker_educations?.map(
                    (value, index) =>
                      portfolio?.jobseeker_educations.length == index + 1 && (
                        <tr key={index} className="professional-table">
                          <td className="main-table-2">
                            Highest <br /> Education
                           
                          </td>

                          <td className="main-two">
                            <tr>
                              <td className="talent2">{value?.degree?.name}</td>
                            </tr>
                            <tr>
                              <td className="talent2">
                                {value?.university?.name}
                              </td>
                            </tr>
                            <tr>
                              <td className="talent-2">
                                {value?.end_date
                                  ? new Date(value?.end_date).getFullYear()
                                  : "N/A"}
                              </td>
                            </tr>
                          </td>
                        </tr>
                      )
                  )}
                </tbody>
              </table>
            </div>

            <div
              className="table-responsive chartSec_table"
              style={{ filter: educationPopup && "blur(2px)" }}
            >
              <table className="table living-one ">
                <tbody className="">
                  <tr>
                    <td className="living">Living City</td>
                    <td colSpan={3}>{portfolio?.users_detail?.city?.name}</td>
                  </tr>
                  <tr>
                    <td className="living2">Nationality</td>
                    <td colSpan={3}>
                      {portfolio?.users_detail?.nationality?.name}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>



            <div
              className="table-responsive chartSec_table"
              style={{ filter: educationPopup && "blur(2px)" }}
            >
              <table className="table">
                <tbody>
                {portfolio?.jobseeker_certificates?.map(
                    (value, index) =>
                      portfolio?.jobseeker_certificates.length == index + 1 && (
                        


                        <tr key={index} className="professional-table">
                            <td className="main-table-2" colSpan="2">
                              {index === 0 ? "Achievements" : ""}
                             
                            </td>

                            <td className="main-two">
                              <tr>
                                <td className="talent3">{value?.title}</td>
                              </tr>
                              <tr>
                                <td className="talent3">{value?.achievement?.name}</td>
                              </tr>
                              <tr>
                                <td className="talent-2">
                                  {value?.start_date ? new Date(value.start_date).getFullYear() : "N/A"}
                                </td>
                              </tr>
                            </td>
                          </tr>

                      )
                  )}  
                </tbody>
              </table>
              </div>
           
            {educationPopup && (
              <div className="middlePopup">
                <div className="popupContent">
                  <div
                    className="alert-popup"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Lottie options={defaultOptions} height={150} width={150} />
                    <p className="message">Complete Your Education Form...!</p>
                    <button
                      className="btn btn-danger"
                      onClick={() => {
                        window.location.href = `/signup-job/6`;
                      }}
                    >
                      Go to form
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="col-lg-8">
          <div className="card portfolio_card  radar_chart mb-3">
            <h5 className="text-center mb-0">Expertise</h5>
            {experiancedata?.variables?.length ? (
              <Radar
                width={500}
                height={500}
                padding={70}
                domainMax={experiancedata?.max_experiance + 2}
                highlighted={null}
                fill="red"
                onHover={(point) => {
                  if (point) {
                    // console.log("hovered over a data point");
                  } else {
                    // console.log("not over anything");
                  }
                }}
                data={{
                  variables: experiancedata?.variables,
                  sets: [
                    {
                      key: "me",
                      label: "My Scores",
                      values: experiancedata?.values,
                    },
                  ],
                }}
                className="custom-radar"
              />
            ) : (
              <div className="middlePopup">
                <div className="popupContent">
                  <div
                    className="alert-popup"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Lottie options={defaultOptions} height={150} width={150} />
                    <p className="message">Complete Your Portfolio...!</p>
                    <Link to="/portfolio-viewdetails-experience">
                      <button className="btn btn-danger" type="button">
                        Go to form
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            )}

            {/* <div className='col-lg-4'>
                            <div className='card mb-3'>

                                <CustomGaugeChart
                                    id="gauge-chart5"
                                    nrOfLevels={420}
                                    arcsLength={[0.25, 0.25, 0.25, 0.25]}
                                    colors={['#5BE12C', '#F5CD19', '#EA4228']}
                                    percent={0.37}
                                    arcPadding={0.02}
                                />
                                <div className='score-button text-center mt-4 mb-2'>
                                    <Link to="/innerPage" >   <div className='btn btn-score'>Search a Suitable Job</div></Link>
                                </div>
                            </div>
                        </div> */}
          </div>
        </div>
      </div>

      {/* mobile-view-start */}
      <section className="mobile-view-chart">
        <div className="row ">
          <div className="row mx-auto">
            <div className="col-4">
              {!educationPopup && !chartPopup && (
                <div className="details-holder" onClick={handleShow}>
                  <h4>Edit Details</h4>
                </div>
              )}
            </div>
            <div className="col-8">
              <div className="heading-holder text-center">
                <h4>My Portfolio</h4>
              </div>
            </div>
          </div>
          <div className="col-12 p-0">
            <div className="card mb-3">
              <div
                className="row"
                style={{ filter: educationPopup && "blur(2px)" }}
              >
                <div className="col-4">
                  <div className="profile-img">
                    <img
                      src={
                        portfolio?.image
                          ? imageUrl + portfolio?.image
                          : process.env.PUBLIC_URL +
                            "assets/Images/my-portfolio/profile-img.png"
                      }
                    />
                    <h6 className="mt-1 ChartuserName">
                      {portfolio?.first_name}
                    </h6>
                  </div>
                </div>
                <div className="col-8">
                  <div className="name-holder mt-3">
                    <h5 className="">
                      I'm {portfolio?.first_name} {portfolio?.last_name}
                    </h5>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "assets/Images/my-portfolio/export-img.png"
                      }
                      className="export-img1 me-2"
                    />
                    <span>{portfolio?.tag && portfolio?.tag}</span>
                    <p className="jobtype">
                      <b>Job type</b>(
                      {portfolio?.jobseeker_job_types
                        ?.map((d) => d?.job_type?.name)
                        .join(", ")}
                      )
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="table-responsive chartSec_table"
                style={{ filter: educationPopup && "blur(2px)" }}
              >
                <table className="table">
                  <tbody>
                    <tr className="main-table">
                      <td className="talent">Talent</td>
                      <td>{portfolio?.talent?.name}</td>
                    </tr>
                    <tr className="main-table">
                      <td className="talent">Talent Pool</td>
                      <td>{portfolio?.league?.name}</td>
                    </tr>
                    <tr className="main-table">
                      <td className="talent"> Pool</td>
                      <td>{portfolio?.pool_score}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div
                className="table-responsive chartSec_table"
                style={{ filter: educationPopup && "blur(2px)" }}
              >
                <table className="table">
                  <tbody>
                    <tr className="professional-table">
                      <td className="main-table-edu">
                        Professional <br/>Experience
                      </td>
                      <td className="talent-university" colSpan={3}>
                        {portfolio?.experiance}
                        Years
                      </td>
                    </tr>
                    {portfolio?.jobseeker_educations?.map(
                      (value, index) =>
                        portfolio?.jobseeker_educations.length == index + 1 && (
                          <tr key={index} className="professional-table">
                            <td className="main-table-edu">
                              Highest Education
                            </td>
                            <td className="talent-university">
                              {value?.university?.name}
                            </td>
                          </tr>
                        )
                    )}
                  </tbody>
                </table>
              </div>

              <div
                className="table-responsive chartSec_table"
                style={{ filter: educationPopup && "blur(2px)" }}
              >
                <table className="table living-one ">
                  <tbody>
                    <tr>
                      <td className="living">Living City</td>
                      <td>{portfolio?.users_detail?.city?.name}</td>
                    </tr>
                    <tr>
                      <td className="living2">Nationality</td>
                      <td>{portfolio?.users_detail?.nationality?.name}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div
                className="table-responsive chartSec_table"
                style={{ filter: educationPopup && "blur(2px)" }}
              >
                <table className="table living-one ">
                  <tbody>
                    {portfolio?.jobseeker_certificates?.map((value, index) => (
                      <tr key={index}>
                        <td className="achevie">
                          {index === 0 ? "Achievments" : ""}
                        </td>
                        <td>{value?.title}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              {educationPopup && (
                <div className="middlePopup">
                  <div className="popupContent">
                    <div
                      className="alert-popup"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Lottie
                        options={defaultOptions}
                        height={150}
                        width={150}
                      />
                      <p className="message">
                        Complete Your Education Form...!
                      </p>
                      <button
                        className="btn btn-danger"
                        type="button"
                        onClick={() => {
                          window.location.href = `/signup-job/${jobSeekerData?.steps}`;
                        }}
                      >
                        Go to form
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="col-12 p-0">
            <div className="card radar_chart mb-3">
              <p className="text-center">Expertise</p>
              {experiancedata?.variables?.length && (
                <Radar
                  width={500}
                  height={500}
                  padding={70}
                  domainMax={experiancedata?.max_experiance + 2}
                  highlighted={null}
                  fill="red"
                  onHover={(point) => {
                    if (point) {
                      // console.log("hovered over a data point");
                    } else {
                      // console.log("not over anything");
                    }
                  }}
                  data={{
                    variables: experiancedata?.variables,
                    sets: [
                      {
                        key: "me",
                        label: "My Scores",
                        values: experiancedata?.values,
                      },
                    ],
                  }}
                  className="custom-radar" // Add a class to the Radar component
                />
              )}

              {chartPopup && (
                <div className="middlePopup">
                  <div className="popupContent">
                    <div
                      className="alert-popup"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Lottie
                        options={defaultOptions}
                        height={150}
                        width={150}
                      />
                      <p className="message">Complete Your Portfolio...!</p>
                      <button
                        className="btn btn-danger"
                        type="button"
                        onClick={() => {
                          window.location.href = `/signup-job/${jobSeekerData?.steps}`;
                        }}
                      >
                        Go to form
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div>
          <Offcanvas
            show={show}
            onHide={handleClose}
            className="edit-details-offcanvas"
          >
            <Offcanvas.Header closeButton>
              {/* <Offcanvas.Title>Offcanvas</Offcanvas.Title> */}
            </Offcanvas.Header>
            <Offcanvas.Body className="ps-0">
              <div className="card mt-3">
                <div className="card-body">
                  <Link to="/portfolio-education-form?activeTab=0">
                    <div className="d-flex  mb-3">
                      <div className="text-holder">
                        <p>Education</p>
                      </div>
                      <div className="image-holder ms-auto">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "assets/Images/my-portfolio/edit-img.png"
                          }
                          className="edit-tool"
                        />
                      </div>
                    </div>
                  </Link>

                  <Link to="/portfolio-certificate-form?activeTab=0">
                    <div className="d-flex  mb-3">
                      <div className="text-holder">
                        <p>Certificate</p>
                      </div>
                      <div className="image-holder ms-auto">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "assets/Images/my-portfolio/edit-img.png"
                          }
                          className="edit-tool"
                        />
                      </div>
                    </div>
                  </Link>

                  <Link to="/portfolio-viewdetails-experience">
                    <div className="d-flex  mb-3">
                      <div className="text-holder ">
                        <p>Experience</p>
                      </div>
                      <div className="image-holder ms-auto">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "assets/Images/my-portfolio/edit-img.png"
                          }
                          className="edit-tool"
                        />
                      </div>
                    </div>
                  </Link>

                  <Link to="/portfolio-freelancing-form">
                    <div className="d-flex  mb-3">
                      <div className="text-holder">
                        <p>Freelancing Projects</p>
                      </div>
                      <div className="image-holder ms-auto">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "assets/Images/my-portfolio/edit-img.png"
                          }
                          className="edit-tool"
                        />
                      </div>
                    </div>
                  </Link>

                  <Link to="/portfolio-project-form">
                    <div className="d-flex  mb-3">
                      <div className="text-holder">
                        <p>Projects</p>
                      </div>
                      <div className="image-holder ms-auto">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "assets/Images/my-portfolio/edit-img.png"
                          }
                          className="edit-tool"
                        />
                      </div>
                    </div>
                  </Link>

                  <Link
                    onClick={() => {
                      handleShowone();
                      handleClose();
                    }}
                  >
                    <div className="d-flex  mb-3">
                      <div className="text-holder">
                        <p>Personal Details</p>
                      </div>
                      <div className="image-holder ms-auto">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "assets/Images/my-portfolio/edit-img.png"
                          }
                          className="edit-tool"
                        />
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </Offcanvas.Body>
          </Offcanvas>
        </div>
      </section>

      <div className="edit-modal" id="edit-modal-one">
        <Modal
          show={showone}
          onHide={handleCloseone}
          centered
          className="edit-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit User Profile</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-6">
                <Form>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      type="text"
                      value={formData?.first_name}
                      onChange={handleChange}
                      name="first_name"
                    />
                  </Form.Group>
                </Form>
              </div>
              <div className="col-md-6">
                <Form>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      type="text"
                      value={formData?.last_name}
                      onChange={handleChange}
                      name="last_name"
                    />
                  </Form.Group>
                </Form>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <Form>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Tag</Form.Label>
                    <Form.Control
                      type="text"
                      value={formData?.tag}
                      onChange={handleChange}
                      name="tag"
                    />
                  </Form.Group>
                </Form>
              </div>
              <div className="col-md-12">
                <Label>Select Job Type</Label>
                <Select
                  className="select-line"
                  isMulti
                  value={selectedJobtype}
                  onChange={(e) => setSelectedJob_type(e)}
                  options={Jobtypes}
                />
              </div>

              <div className="col-md-6 mt-3">
                <Select
                  value={formData?.living_city_id}
                  name="living_city_id"
                  onChange={handleChange}
                  options={Living}
                  placeholder="Living City"
                />
              </div>
              <div className="col-md-6 mt-3">
                <Select
                  options={Nationality}
                  value={formData?.nationality_id}
                  name="nationality_id"
                  onChange={handleChange}
                  placeholder="Nationality"
                />
              </div>
            </div>
            <div className="col-md-12 mt-3">
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>Edit photo</Form.Label>
                <Form.Control
                  type="file"
                  name="image"
                  onChange={handleChange}
                />
              </Form.Group>
            </div>
            {formData?.image && (
              <div className="col-md-12 mt-3">
                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Label>Edit photo</Form.Label>
                  <img
                    src={
                      formData?.imageUrl
                        ? formData?.imageUrl
                        : imageUrl + formData?.image
                    }
                    alt="Preview"
                    className="image-preview-container"
                    style={{
                      width: "120px",
                      height: "120px",
                    }}
                  />
                </Form.Group>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button className="blue-btn-class" onClick={handleCloseone}>
              Close
            </Button>
            <Button className="blue-btn-class" onClick={handleShowtwo}>
              {" "}
              {/* Open Update Modal */}
              Update
            </Button>
          </Modal.Footer>
        </Modal>
      </div>

      <div className="update-modal" id="update-modal-one">
        <Modal
          show={showtwo}
          onHide={handleClosetwo}
          centered
          className="custom-modal"
        >
          {/* <Modal.Header closeButton></Modal.Header> */}
          <Modal.Body>
            <Success />
            <p className="update text-center"> Updated In Successfully!</p>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};
export default ChartSection;
