import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { Await, Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "@fortawesome/fontawesome-svg-core/styles.css";
import Tab from "react-bootstrap/Tab";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select, { components } from "react-select";
import Autosuggest from "react-autosuggest";

import { useContext } from "react";
import { Context } from "../../../../utils/context";
import {
  faUpload,
  faFile,
  faXmark,
  faPlus,
  faMinus,
} from "@fortawesome/free-solid-svg-icons";
import { getData } from "../../../../utils/api";

const options = [
  { value: "HTML", label: "HTML" },
  { value: "CSS", label: "CSS" },
  { value: "Bootstrap", label: "Bootstrap" },
];

const MultiValueRemove = (props) => (
  <components.MultiValueRemove
    {...props}
    className="custom-multi-value-remove"
  />
);
function Job({ getCardData, status, currentStatus }) {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const inputRef = React.useRef(null);

  const handleUploadButtonClick = (id) => {
    // Trigger a click on the file input
    // inputRef.current.click();
    document.getElementById(id).click();
  };

  const [currentMediaPreview, setCurrentMediaPreview] = useState("");
  const [currentEvidencePreview, setCurrentEvidencePreview] = useState("");

  const [currentMedia, setCurrentMedia] = useState([]);
  const [currentEvidence, setCurrentEvidence] = useState([]);

  const handleCurrentMediaChange = (event) => {
    // Handle file change here
    const selectedFile = event.target.files[0];
    // console.log(selectedFile);
    if (selectedFile) {
      // setCurrentMedia(...currentMedia,selectedFile);
      setCurrentMedia((prevMedia) => [...prevMedia, selectedFile]);
      setCurrentMediaPreview(selectedFile.name);
    }
  };

  const [currentEvidenceLoader, setCurrentEvidenceLoader] = useState(0.0);
  const handleCurrentEvidenceChange = (event) => {
    // Handle file change here
    setCurrentEvidenceLoader(0.0);
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      // Update the state with the selected file name
      const interval = setInterval(() => {
        // Increase loader value (random increase for demonstration)
        setCurrentEvidenceLoader((prevLoader) =>
          Math.min(prevLoader + Math.random() * 0.1, 1)
        );
      }, 500);
      // setCurrentEvidence(...currentEvidence,selectedFile);
      setCurrentEvidence((prevMedia) => [...prevMedia, selectedFile]);
      setCurrentEvidencePreview(selectedFile.name);
      // Clear the interval on component unmount
      return () => clearInterval(interval);
    }
  };

  const deleteCurrentEvidence = () => {
    setCurrentEvidenceLoader(0.0);
    setCurrentEvidencePreview("");
  };

  const {
    getData,
    postData,
    recruiterData,
    jobSeekerData,
    editStatusData,
    getJobSeekerData,
    Select2Data,
    Select2Skills,
    Select2Roles,
  } = useContext(Context);

  const [buttonStatus, setButtonStatus] = useState(false);
  // Generate an array of years, you can customize the range as needed
  const years = Array.from({ length: 100 }, (_, index) => ({
    label: `${new Date().getFullYear() - index}`,
    value: `${new Date().getFullYear() - index}`,
  }));

  const [formCurrentDatas, setFormData] = useState({
    org_type: true,
    id: jobSeekerData?.id,
    step: 9,
    media: undefined,
    job_role_id: "",
    contact_person: "",
    start_date: "",
    end_date: "",
    job_nature_id: [],
    location_id: "",
    skills: [],
    company_id: "",
    contact_person_email: "",
    responsibilities: "",
    achievement_id: "",

    skills_value: [],
  });

  const handleCurrentSelect2Change = async (selectedOption) => {
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        skills: selectedOption.map((item) => item.value),
        skills_value: selectedOption,
      };
    });
  };
  const [seletedJobNature, setSelectedJobNature] = useState([]);

  useEffect(() => {
    setFormData({
      ...formCurrentDatas,
      ["job_nature_id"]: seletedJobNature?.map((item) => item.value),
    });
  }, [seletedJobNature]);
  const handleCurrentSelectChange = async (e) => {
    if (
      e?.name === "job_role_id" ||
      e?.name === "job_nature_id" ||
      e?.name === "location_id" ||
      e?.name === "achievement_id" ||
      e?.name === "state_id" ||
      e?.name === "city_id" ||
      e?.name === "type_id"
    ) {
      if (e?.name === "job_role_id") {
        setFormData({ ...formCurrentDatas, [e?.name]: e });
      }
      if (e?.name === "job_nature_id") {
        setFormData({ ...formCurrentDatas, [e?.name]: e });
      }
      if (e?.name === "achievement_id") {
        setFormData({ ...formCurrentDatas, [e?.name]: e });
      }
      if (e?.name === "location_id") {
        setFormData({ ...formCurrentDatas, [e?.name]: e });
      } else {
        setFormData({ ...formCurrentDatas, [e.name]: e });
      }
    } else {
      setFormData({ ...formCurrentDatas, [e.name]: e.name });
    }
  };

  const handleCurrentInputChange = (e) => {
    if (
      e?.target?.name === "area_of_study" ||
      e?.target?.name === "contact_person" ||
      e?.target?.name === "responsibilities" ||
      e?.target?.name === "contact_person_email"
    ) {
      if (e?.target?.name === "area_of_study") {
        setFormData({ ...formCurrentDatas, [e?.target?.name]: e.target.value });
      }
      if (e?.target?.name === "contact_person") {
        setFormData({ ...formCurrentDatas, [e?.target?.name]: e.target.value });
      }
      if (e?.target?.name === "contact_person_email") {
        setFormData({ ...formCurrentDatas, [e?.target?.name]: e.target.value });
      }
      if (e?.target?.name === "responsibilities") {
        setFormData({ ...formCurrentDatas, [e?.target?.name]: e.target.value });
      }
    } else {
      setFormData({ ...formCurrentDatas, [e?.target?.name]: e.target.value });
    }
  };
  const [roles, setRole] = useState();

  const [skills, setSkill] = useState();

  const [companies, setCompamies] = useState();

  const [jobNature, setJobNature] = useState();
  const [jobLocation, setJobLocation] = useState();
  const [achievements, setAchievement] = useState();
  const getMasters = async () => {
    {
      const res = await getData(`/without-login/master/all-roles`);
      if (res?.success) {
        // console.log(res.data, "roles");

        const data = await Select2Roles(res.data, "job_role_id", false);
        setRole(data);
      }
    }
    {
      const res = await getData(`/without-login/master/all-skills`);
      if (res?.success) {
        const data = await Select2Skills(res.data, "skill_id", false);
        setSkill(data);
      }
    }
    {
      const res = await getData("/without-login/master/all-company");
      if (res?.success) {
        const data = await Select2Data(res.data, "company_id", false);
        setCompamies(data);
      }
    }
    {
      const res = await getData("/without-login/master/all-job-nature");
      if (res?.success) {
        const data = await Select2Data(res.data, "job_nature_id", false);
        setJobNature(data);
      }
    }
    {
      const res = await getData("/without-login/master/all-cities");
      if (res?.success) {
        const data = await Select2Data(res.data, "location_id", false);
        setJobLocation(data);
      }
    }
    {
      const res = await getData("/without-login/master/all-achievemente");
      if (res?.success) {
        const data = await Select2Data(res.data, "achievement_id", false);
        setAchievement(data);
      }
    }
  };

  const [errors, setErrors] = useState();
  const errorStyle = {
    color: "red",
    marginLeft: "5px",
    // fontSize: "11px",
  };
  const validateForm = () => {
    let errors = {};
    const regexAlfaNumric = /^[A-Za-z0-9]+(\s[A-Za-z0-9]+)*$/;
    const regexAlfa = /^[A-Za-z]+(\s[A-Za-z]+)*$/;

    // if (formCurrentDatas?.media === undefined) {
    //   errors.media = "Evidance is required";
    // }

    // if (!formCurrentDatas.issuing_org) {
    //   errors.issuing_org = "Issuing Organization is required";
    // } else if (!regexAlfaNumric.test(formCurrentDatas.issuing_org?.trim())) {
    //   errors.issuing_org = "Enter a valid data";
    // }

    if (!formCurrentDatas.start_date) {
      errors.start_date = "Start Date is required";
    }

    if (!formCurrentDatas.end_date) {
      errors.end_date = "End Date is required";
    } else if (
      new Date(formCurrentDatas.end_date) <
      new Date(formCurrentDatas.start_date)
    ) {
      errors.end_date = "End Date cannot be a past date of Start Date";
    } else if (new Date(formCurrentDatas.end_date) > new Date()) {
      errors.end_date = "End Date cannot be a future date";
    }

    // if (!formCurrentDatas.certificate_for_id) {
    //   errors.certificate_for_id = "Certificate For is required";
    // }

    // if (!formCurrentDatas.main_topics) {
    //   errors.main_topics = "Main Topics is required";
    // } else if (!regexAlfaNumric.test(formCurrentDatas.main_topics?.trim())) {
    //   errors.main_topics = "Enter a valid data";
    // }
    if (!formCurrentDatas.company_id) {
      errors.company_id = "Company Name is required";
    }
    if (!formCurrentDatas.job_role_id) {
      errors.job_role_id = "Job Role is required";
    }
    // if (!formCurrentDatas.achievement_id) {
    //   errors.achievement_id = "Achievement is required";
    // }
    if (formCurrentDatas?.skills.length === 0) {
      errors.skills = "Skills is required";
    }

    return errors;
  };
  const handleJobSave = async () => {
    await setButtonStatus(true);
    // console.log(formCurrentDatas);
    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      await setButtonStatus(false);
    } else {
      try {
        const finalData = new FormData();
        finalData.append(`media`, formCurrentDatas.media);
        finalData.append(`id`, formCurrentDatas.id);
        finalData.append(`org_type`, formCurrentDatas.org_type ? 1 : 0);
        finalData.append(`responsibilities`, formCurrentDatas.responsibilities);
        finalData.append(`step`, formCurrentDatas.step);
        finalData.append(`job_role_id`, formCurrentDatas.job_role_id?.value);
        if (formCurrentDatas.job_nature_id) {
          finalData.append(
            `job_nature_id`,
            JSON.stringify(formCurrentDatas.job_nature_id)
          );
        }
        if (formCurrentDatas.location_id) {
          finalData.append(`location_id`, formCurrentDatas.location_id?.value);
        }
        if (formCurrentDatas.achievement_id) {
          finalData.append(
            `achievement_id`,
            formCurrentDatas.achievement_id?.value
          );
        }

        finalData.append(
          `university_type_id`,
          formCurrentDatas.university_type_id?.value
        );
        finalData.append(`skills`, JSON.stringify(formCurrentDatas.skills));

        finalData.append(`contact_person`, formCurrentDatas.contact_person);
        finalData.append(`start_date`, formCurrentDatas.start_date);
        finalData.append(`end_date`, formCurrentDatas.end_date);
        finalData.append(`status`, status);
        finalData.append(
          `contact_person_email`,
          formCurrentDatas.contact_person_email
        );
        finalData.append(`company_id`, formCurrentDatas.company_id);

        const result = await postData(
          "/jobseeker/signup/experiance-job",
          finalData
        );

        if (result?.success) {
          await setFormData({
            org_type: true,
            id: jobSeekerData?.id,
            step: 9,
            media: undefined,
            job_role_id: [],
            contact_person: "",
            start_date: "",
            end_date: "",
            job_nature_id: "",
            location_id: "",
            skills: [],
            company_id: "",
            contact_person_email: "",
            responsibilities: "",
            achievement_id: "",
            skills_value: [],
          });
          await setSelectedJobNature([]);
          await getCardData();
          await setErrors("");
          await setButtonStatus(false);
        }
      } catch (error) {
        // console.log(error);
        await setButtonStatus(false);
      }
    }
  };

  const handleOrganization = async () => {
    setFormData({ ...formCurrentDatas, org_type: !formCurrentDatas?.org_type });
  };

  const [value, setValue] = useState("");
  const [otherCompany, setOtherCompany] = useState(false);
  const [otherDegree, setOtherDegree] = useState(false);
  const [suggestionsList, setSuggestionsList] = useState([]);

  const getSuggestions = async (inputValue, list) => {
    const inputValueLowerCase = inputValue.trim().toLowerCase();

    if (list === "company_id") {
      const res = await getData(
        `/without-login/master/all-company?name=${inputValueLowerCase}`
      );
      if (res?.success) {
        if (res.data.length === 0) {
          setOtherCompany(true);
        } else {
          setOtherCompany(false);
        }

        return res.data?.filter((suggestion) =>
          suggestion?.name.toLowerCase().includes(inputValueLowerCase)
        );
      }
    }
  };

  const onSuggestionsFetchRequested = async ({ value }, list) => {
    const suggestions = await getSuggestions(value, list);
    setSuggestionsList(suggestions);
  };

  const onSuggestionsClearRequested = () => {
    setSuggestionsList([]);
  };

  const onChange = (event, { newValue }) => {
    if (event.target.id === "company_id") {
      setFormData({ ...formCurrentDatas, [event.target.id]: newValue });
    }
  };

  const getSuggestionValue = (suggestion) => suggestion?.name;

  const renderSuggestion = (suggestion, id) => (
    <div
      id={id}
      style={{
        border: "1px solid #ccc",
        padding: "10px",
        borderRadius: "5px",
        margin: "5px",
        backgroundColor: "#f9f9f9",
      }}
    >
      {suggestion?.name}
    </div>
  );

  const inputProps = {
    onChange,
    style: {
      width: "300px", // Adjust the width as needed
      padding: "8px",
      borderRadius: "5px",
      border: "1px solid #ccc",
      // Add other styles as needed
    },
  };

  useEffect(() => {
    getMasters();
  }, []);
  useEffect(() => {
    if (currentStatus) {
      setFormData({ ...formCurrentDatas, org_type: false });
    }
  }, [currentStatus]);
  return (
    <>
      <Tab.Container id="left-tabs-example" defaultActiveKey="first">
        <Row>
          <Nav variant="pills">
            {currentStatus && (
              <Nav.Item>
                <Nav.Link
                  className={formCurrentDatas?.org_type ? "active" : ""}
                  onClick={handleOrganization}
                >
                  Current Organization
                </Nav.Link>
              </Nav.Item>
            )}
            <Nav.Item>
              <Nav.Link
                className={formCurrentDatas?.org_type ? "" : "active"}
                onClick={currentStatus ? handleOrganization : undefined}
              >
                Previous Organization
              </Nav.Link>
            </Nav.Item>
          </Nav>

          <Tab.Content>
            <Tab.Pane eventKey="first">
              <Form>
                <Row>
                  <div className="col-md-6 col-sm-12 mb-md-3 mb-0">
                    <Form.Label>
                      Job Role <span className="text-danger">*</span>
                    </Form.Label>

                    <Select
                      name="job_role_id"
                      classNamePrefix="jobRolee"
                      placeholder="Select Job Role"
                      value={formCurrentDatas?.job_role_id}
                      onChange={handleCurrentSelectChange}
                      options={roles}
                      isSearchable={true}
                    />
                    {errors?.job_role_id && (
                      <span style={errorStyle}>{errors?.job_role_id}</span>
                    )}
                  </div>

                  <div className="col-md-6 col-sm-12 mb-md-3 mb-0">
                    <Form.Label>Responsibilities</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Responsibilities"
                      value={formCurrentDatas?.responsibilities}
                      name="responsibilities"
                      onChange={handleCurrentInputChange}
                    />
                  </div>
                </Row>
                <Row>
                  <div className="col-md-6 col-sm-12 mb-md-3 mb-0">
                    <Form.Label>
                      Skills Used <span className="text-danger">*</span>
                    </Form.Label>
                    <Select
                      placeholder="Select Skills"
                      className="select-line"
                      classNamePrefix="skillsexp"
                      isMulti
                      value={formCurrentDatas?.skills_value}
                      onChange={(selectedOption) =>
                        handleCurrentSelect2Change(selectedOption, "skills")
                      }
                      options={skills}
                    />
                    {errors?.skills && (
                      <span style={errorStyle}>{errors?.skills}</span>
                    )}
                  </div>

                  <div className="col-md-6 col-sm-12 mb-md-3 mb-0">
                    <Form.Label>
                      Company Name <span className="text-danger">*</span>
                    </Form.Label>

                    <Autosuggest
                      id="company_id"
                      suggestions={suggestionsList}
                      onSuggestionsFetchRequested={(event) =>
                        onSuggestionsFetchRequested(event, "company_id")
                      }
                      onSuggestionsClearRequested={onSuggestionsClearRequested}
                      getSuggestionValue={getSuggestionValue}
                      renderSuggestion={(suggestion) =>
                        renderSuggestion(suggestion, "company_id")
                      }
                      inputProps={{
                        ...inputProps,
                        id: "company_id",
                        value: formCurrentDatas?.company_id,
                        placeholder: "Enter Company name",
                      }}
                    />
                    {errors?.company_id && (
                      <span style={errorStyle}>{errors?.company_id}</span>
                    )}
                  </div>
                  <div className="col-md-6 col-sm-12 mb-md-3 mb-0">
                    <Form.Label>Contact Person</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Contact Person"
                      value={formCurrentDatas?.contact_person}
                      name="contact_person"
                      onChange={handleCurrentInputChange}
                    />
                  </div>
                  <div className="col-md-6 col-sm-12 mb-md-3 mb-0">
                    <Form.Label>Contact Person Email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter Contact Person Email"
                      value={formCurrentDatas?.contact_person_email}
                      name="contact_person_email"
                      onChange={handleCurrentInputChange}
                    />
                  </div>
                  <div className="col-md-6 col-sm-6 mb-md-3 mb-0">
                    <Row>
                      <Col>
                        <Form.Label>
                          {" "}
                          Time Period
                          <sup className="bracket-font px-md-1 px-5">
                            (from)
                          </sup>{" "}
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="date"
                          placeholder="Start Date"
                          name="start_date"
                          value={formCurrentDatas?.start_date}
                          onChange={handleCurrentInputChange}
                        />
                        {errors?.start_date && (
                          <span style={errorStyle}>{errors?.start_date}</span>
                        )}
                      </Col>
                      <Col>
                        <Form.Label>
                          {" "}
                          Time Period
                          <sup className="bracket-font px-md-1 px-5">
                            (to)
                          </sup>{" "}
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="date"
                          placeholder="End Date"
                          name="end_date"
                          value={formCurrentDatas?.end_date}
                          onChange={handleCurrentInputChange}
                        />
                        {errors?.end_date && (
                          <span style={errorStyle}>{errors?.end_date}</span>
                        )}
                      </Col>
                    </Row>
                  </div>
                  <div className="col-md-6 col-sm-12 mb-md-3 mb-0">
                    <Form.Label>Job Nature</Form.Label>
                    <Select
                      isMulti
                      name="job_nature_id"
                      classNamePrefix="jobNature"
                      placeholder="Select Job Nature"
                      value={seletedJobNature}
                      onChange={(selectedOption) =>
                        setSelectedJobNature(selectedOption)
                      }
                      options={jobNature}
                      isSearchable={true}
                    />
                  </div>
                  <div className="col-md-6 col-sm-12 mb-md-3 mb-0">
                    <Form.Label>Job Location</Form.Label>
                    <Select
                      name="location_id"
                      classNamePrefix="jobLoc"
                      placeholder="Select Job location"
                      value={formCurrentDatas?.location_id}
                      onChange={handleCurrentSelectChange}
                      options={jobLocation}
                      isSearchable={true}
                    />
                  </div>
                  <div className="col-md-6 col-sm-12 mb-md-3 mb-2">
                    <Form.Label>Achievements</Form.Label>
                    <Select
                      name="achievement_id"
                      classNamePrefix="jobAchieve"
                      placeholder="Select Job location"
                      value={formCurrentDatas?.achievement_id}
                      onChange={handleCurrentSelectChange}
                      options={achievements}
                      isSearchable={true}
                    />
                  </div>
                  <div className="col-md-6 col-sm-12 mb-md-3 mb-0">
                    <div>
                      <p className="upload-text m-0">Upload Media</p>
                      <input
                        className="uplode-file"
                        type="file"
                        name="media"
                        onChange={async (e) => {
                          await setFormData({
                            ...formCurrentDatas,
                            [e.target.name]: e.target.files[0],
                          });
                        }}
                        id="currentMedia"
                        accept="application/pdf"
                      />

                      <button
                        type="button"
                        className=" upload-experience"
                        onClick={() => handleUploadButtonClick("currentMedia")}
                      >
                        Upload{" "}
                        <FontAwesomeIcon icon={faUpload} className="px-3" />
                      </button>
                      <span>{currentMediaPreview}</span>
                      <p className="bracket-font mb-0">
                        ( Max file size 1 MB )
                      </p>
                      {errors?.media && (
                        <span style={errorStyle}>{errors?.media}</span>
                      )}
                    </div>
                  </div>
                </Row>
                <div className="text-end">
                  <Button
                    className="education-btn"
                    disabled={buttonStatus}
                    onClick={handleJobSave}
                  >
                    Save
                  </Button>
                </div>
              </Form>
            </Tab.Pane>
            <Tab.Pane eventKey="second"></Tab.Pane>
          </Tab.Content>
        </Row>
      </Tab.Container>
    </>
  );
}

export default Job;
