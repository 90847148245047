import React from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import Animation from "../../../RecruiterPage/LoginSignUp/login-page/animation/Animation";

const LoginJob = () => {
  return (
    <>
      <section className="login desktopview-job-login">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-12">
              <div className="image-login">
                <img
                  src={
                    process.env.PUBLIC_URL + "assets/Images/banner/login.png"
                  }
                  className="login-img"
                />
                <div className="image-overlay">
                  <Animation />
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-md-12 ">
              <div className="row bg-color">
                <div className="col-md-8 mx-auto">
                  <div className="heading-holder mb-3">
                    <p>Recruiter's Log In</p>
                    <h4>Log In with Mobile Number</h4>
                  </div>
                  <div className="row">
                    <p>Country Code</p>
                    <div className="col-lg-3 col-md-3">
                      <Form.Select aria-label="Default select example">
                        <option>+91</option>
                        <option value="1">+90</option>
                        <option value="2">+74</option>
                        <option value="3">+65</option>
                      </Form.Select>
                    </div>
                    <div className="col-lg-6 col-md-9">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Control
                          type="text"
                          placeholder="Enter Mobile No."
                        />
                      </Form.Group>
                    </div>
                    <div className="col-lg-3">
                      <Link to="/dashboard-job">
                        {" "}
                        <Button variant="primary changeNobtn">
                          Change Number
                        </Button>
                      </Link>
                    </div>
                  </div>
                  <div className="row Login_below_info">
                    <div className="col-lg-6 mx-auto">
                      <div className="row Login_otpSection">
                        <div className="col-lg-3 col-md-3">
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Control type="text" />
                          </Form.Group>
                        </div>
                        <div className="col-lg-3 col-md-3">
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Control type="text" />
                          </Form.Group>
                        </div>
                        <div className="col-lg-3 col-md-3">
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Control type="text" />
                          </Form.Group>
                        </div>
                        <div className="col-lg-3 col-md-3">
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Control type="text" />
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                    <p className="text-center codeTxt">
                      If you don't receive code,{" "}
                      <span className="resendCode_txt">Resend Code</span>
                    </p>
                    <p className="social_txt text-center">
                      Log In with Social Accounts
                    </p>
                    <div className="text-center">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "assets/Images/icons/google logo.png"
                        }
                        className="google_img me-2"
                      />
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "assets/Images/icons/facebook.png"
                        }
                        className="google_img"
                      />
                    </div>
                  </div>
                  <div className="user-holder text-center mt-4 mb-2">
                    <p>
                      New to Hiclousia?
                      <Link to="/signup-job">
                        <span className="ms-2">Create Account</span>
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* mobile-view-start */}
      <section className="login mobileview-job-login ">
        <div className="container-fluid p-0">
          <div className="row  me-0">
            <div className="image-login ">
              <img
                src={process.env.PUBLIC_URL + "assets/Images/banner/login.png"}
                className="login-img"
              />
              <div className="image-overlay">
                <Animation />
              </div>
            </div>

            <div className="col-10 mx-auto mt-2">
              <div className="row ">
                <div className="col-md-8 mx-auto">
                  <div className="heading-holder  mb-3">
                    <p>Jobseeker's Log In</p>
                    <h4>Log In with Mobile Number</h4>
                  </div>
                  <div className="row">
                    <p className="code-country">Country Code</p>
                    <div className="col-4">
                      <Form.Select aria-label="Default select example">
                        <option>+91</option>
                        <option value="1">+90</option>
                        <option value="2">+74</option>
                        <option value="3">+65</option>
                      </Form.Select>
                    </div>
                    <div className=" col-8">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Control
                          type="text"
                          placeholder="9852145698"
                          style={{ textAlign: "left" }}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-lg-3">
                      <div className="text-center mt-2">
                        <Link to="/dashboard-job">
                          {" "}
                          <Button variant="primary changeNobtn">
                            Change Number
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="row Login_below_info">
                    <div className="col-lg-6 mx-auto">
                      <div className="row Login_otpSection">
                        <div className="col-3 ">
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Control type="Number" />
                          </Form.Group>
                        </div>
                        <div className="col-3">
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Control type="Number" />
                          </Form.Group>
                        </div>
                        <div className="col-3">
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Control type="Number" />
                          </Form.Group>
                        </div>
                        <div className="col-3">
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Control type="Number" />
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                    <p className="text-center codeTxt">
                      If you don't receive code,{" "}
                      <Link to="/getotp-jobseeker">
                        <span className="resendCode_txt">Resend Code</span>
                      </Link>{" "}
                    </p>
                    <p className="social_txt text-center">
                      Log In with Social Accounts
                    </p>
                    <div className="text-center">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "assets/Images/icons/google logo.png"
                        }
                        className="google_img me-2"
                      />
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "assets/Images/icons/facebook.png"
                        }
                        className="google_img"
                      />
                    </div>
                  </div>
                  <div className="user-holder text-center my-4 ">
                    <p>
                      New to Hiclousia?
                      <Link to="/signup-job">
                        <span className="ms-2">Create Account</span>
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LoginJob;
