import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
// import 'react-tabs/style/react-tabs.css';
import FirstTab from "./FirstTab.js";
import SecondTab from "./SecondTab.js";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import ThirdTab from "./ThirdTab.js";
import FourthTab from "./ForthTab.js";
import "./Maintab.css";
import Autosuggest from "react-autosuggest";
import { Link, useLocation } from "react-router-dom";
import ForthTab from "./ForthTab.js";
import FifthTab from "./FifthTab.js";
import SixthTab from "./SixthTab.js";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import "swiper/swiper-bundle.css";
import { Form, Row, Col, Button, Nav } from "react-bootstrap";
import { useContext, useEffect, useState } from "react";
import { Context } from "../../../../../utils/context.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import PostedJobs from '../PostedJobs/PostedJobs.js';
// import "../../../LoginSignup/UpdatedSingUp/UpdatedForm.css";
import Select from "react-select";
import DatePicker from "react-datepicker";
// import Button from 'react-bootstrap/Button';
import Modal from "react-bootstrap/Modal";

const PortfolioEducationForm = () => {
  // const [lgShow, setLgShow] = useState(false);

  // const handleClose = () => setLgShow(false);
  // const handleShow = () => setLgShow(true);

  const location = useLocation();
  const activeTabParam = new URLSearchParams(location.search).get("activeTab");
  const initialActiveTab = activeTabParam ? parseInt(activeTabParam, 10) : 0;

  const [activeTab, setActiveTab] = useState(initialActiveTab);

  const {
    getData,
    postData,
    recruiterData,
    jobSeekerData,
    editStatusData,
    getJobSeekerData,
    Select2Data,
    deleteData,
    Select2Skills,
    fetchSeekerData,
  } = useContext(Context);

  const [skills, setSkills] = useState([]);
  const [achievemente, setAchievemente] = useState([]);
  const [certificateFor, setCertificateFor] = useState([]);
  const [selectedskills, setSelectedSkills] = useState([]);
  const [errors, setErrors] = useState();

  const [cards, setCards] = useState([]);
  const [status, setStatus] = useState("none");

  const [formData, setFormData] = useState({
    step: 8,
    evidance: undefined,
    achievement_id: "",
    title: "",
    issuing_org: "",
    start_date: "",
    end_date: "",
    certificate_for_id: "",
    main_topics: "",
    skills: [],
  });

  const validateForm = () => {
    let errors = {};
    const regexAlfaNumric = /^[A-Za-z0-9]+(\s[A-Za-z0-9]+)*$/;
    const regexAlfa = /^[A-Za-z]+(\s[A-Za-z]+)*$/;

    if (!formData.title) {
      errors.title = "Title is required";
    } else if (!regexAlfaNumric.test(formData.title?.trim())) {
      errors.title = "Enter a valid data";
    }

    if (formData?.evidance === undefined) {
      errors.evidance = "Evidance is required";
    }

    // if (!formData.issuing_org) {
    //   errors.issuing_org = "Issuing Organization is required";
    // } else if (!regexAlfaNumric.test(formData.issuing_org?.trim())) {
    //   errors.issuing_org = "Enter a valid data";
    // }

    if (!formData.start_date) {
      errors.start_date = "Start Date is required";
    }

    if (!formData.end_date) {
      errors.end_date = "End Date is required";
    } else if (new Date(formData.end_date) < new Date(formData.start_date)) {
      errors.end_date = "End Date cannot be a past date of Start Date";
    } else if (new Date(formData.end_date) > new Date()) {
      errors.end_date = "End Date cannot be a future date";
    }

    // if (!formData.certificate_for_id) {
    //   errors.certificate_for_id = "Certificate For is required";
    // }

    if (!formData.main_topics) {
      errors.main_topics = "Main Topics is required";
    }

    if (!formData.achievement_id) {
      errors.achievement_id = "Achievement is required";
    }
    if (selectedskills.length === 0) {
      errors.skills = "Skills is required";
    }

    return errors;
  };

  const handleChange = (e) => {
    if (e?.name === "achievement_id" || e?.name === "certificate_for_id") {
      setFormData({ ...formData, [e.name]: e });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const getAllData = async () => {
    {
      const res = await getData("/without-login/master/all-achievemente");
      setAchievemente(await Select2Data(res.data, "achievement_id"));
    }
    {
      const res = await getData("/without-login/master/all-skills");
      setSkills(await Select2Skills(res.data, "skills"));
    }
    {
      const res = await getData("/without-login/master/all-certificate-for");
      setCertificateFor(await Select2Data(res.data, "certificate_for_id"));
    }
  };

  const getAllCards = async () => {
    // if (jobSeekerData?.id) {
    const res = await getData(`/jobseeker/signup/certificate`);
    if (res?.success) {
      await setCards(res?.data);
      if (res?.data.length !== 0) {
        setStatus("none");
      } else {
        setStatus("none");
      }
      // }
    }
  };

  useEffect(() => {
    getAllData();
    getAllCards();
  }, [jobSeekerData?.id]);

  useEffect(() => {
    setFormData({
      ...formData,
      ["skills"]: selectedskills?.map((item) => item.value),
    });
  }, [selectedskills]);

  const [lgShow, setLgShow] = useState(false);
  const [ftype, setFType] = useState(true);

  const handleClose = () => setLgShow(false);
  const handleShow = async (type = "ADD") => {
    if (type === "ADD") {
      await setFType(true);
      await setFormData({
        step: 8,
        evidance: undefined,
        achievement_id: "",
        title: "",
        issuing_org: "",
        start_date: "",
        end_date: "",
        certificate_for_id: "",
        main_topics: "",
        skills: [],
      });
      // await setSelectedArea_of_studay([]);
      await setErrors();
      await setSelectedSkills([]);
    } else {
      await setFType(false);
    }
    await setLgShow(true);
    await setErrors({});
  };

  const getEditData = async (id) => {
    setFType(false);
    const res = await getData(`/jobseeker/signup/certificate/${id}`);
    // console.log(res);
    const data = res.data;
    setSelectedSkills(
      data?.jobseeker_certificate_skills?.map((d) => ({
        name: "skills",
        label: d?.skill?.name + " - " + d?.skill?.skill_type?.name,
        value: d?.skill_id,
      }))
    );
    setFormData({
      ...formData,
      id: data?.id,
      achievement_id: "",
      title: data?.title,
      evidance: "edit",
      issuing_org: data?.issuing_org,
      start_date: data?.start_date,
      end_date: data?.end_date,
      certificate_for_id: data?.certificate_for
        ? {
            value: data?.certificate_for?.id,
            label: data?.certificate_for?.name,
            name: "certificate_for_id",
          }
        : "",
      main_topics: data?.main_topics,
      achievement_id: data?.achievement_id
        ? {
            name: "achievement_id",
            label: data?.achievement?.name,
            value: data?.achievement_id,
          }
        : "",
      skills: await data?.jobseeker_certificate_skills?.map((d) => ({
        name: "skills",
        label: d?.skill?.name,
        value: d?.skill_id,
      })),
    });
    handleShow("EDIT");
  };
  const handleSubmit = async (action) => {
    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      if (action === "continue") {
        if (cards.length > 0) {
          // handleNext();
        }
      }
    } else {
      try {
        const finalData = new FormData();
        if (formData.evidance != "edit") {
          finalData.append(`evidance`, formData.evidance);
        }
        finalData.append(`step`, formData.step);
        finalData.append(`achievement_id`, formData.achievement_id?.value);
        finalData.append(`title`, formData.title);
        finalData.append(`issuing_org`, formData.issuing_org);
        finalData.append(`start_date`, formData.start_date);
        finalData.append(`end_date`, formData.end_date);
        if (formData.certificate_for_id) {
          finalData.append(
            `certificate_for_id`,
            formData.certificate_for_id?.value
          );
        }
        finalData.append(`main_topics`, formData.main_topics);
        finalData.append(`skills`, JSON.stringify(formData.skills));
        finalData.append(`status`, status);
        let result;
        if (formData?.id) {
          result = await postData(
            `/jobseeker/signup/certificate/${formData?.id}`,
            finalData
          );
        } else {
          result = await postData("/jobseeker/signup/certificate", finalData);
        }

        if (result?.success) {
          await setFormData({
            step: 8,
            evidance: undefined,
            achievement_id: "",
            title: "",
            issuing_org: "",
            start_date: "",
            end_date: "",
            certificate_for_id: "",
            main_topics: "",
            skills: [],
          });

          await setSelectedSkills([]);

          await setErrors();
          await getAllCards();
          await fetchSeekerData();
          setSelectedSkills([]);
          if (action === "continue") {
            // handleNext();
          }
          handleClose();
        }
      } catch (error) {
        // console.log(error);
      }
    }
  };

  const handleDelete = async (id) => {
    var data = "";
    if (cards.length === 1) {
      var data = {
        step: 8,
        status: "none",
      };
    } else {
      var data = {
        step: 8,
        status: "none",
      };
    }
    const res = await editStatusData(
      `/jobseeker/signup/certificate/${id}`,
      data
    );

    await getAllCards();
    await fetchSeekerData();
  };
  const errorStyle = {
    color: "red",
    marginLeft: "5px",
    // fontSize: "11px",
  };

  return (
    <>
      <section className="portfolio-experience">
        <div className="container">
          <div className="heading text-center">
            <p>My Portfolio</p>
          </div>
          <div className="tab-header">
            <Row>
              <Col className="">
                <Link to="/dashboard-job">Back</Link>
              </Col>
              <Col className="text-center">
                <p className="edu-text">Add Certificate</p>
              </Col>
              <Col className="text-end">
                {/* <Link><div onClick={() => setAddform(!addform)}>Add</div></Link> */}
                <Link onClick={() => handleShow()}>Add</Link>
              </Col>
            </Row>
          </div>

          <div className="row ms-0 me-0">
            <div className="col-md-12 p-0">
              <div className="main-design-tab p-3">
                <Form>
                  <Row className="">
                    {cards?.map((value, index) => (
                      <Col
                        className="col-md-5 col-lg-3 col-xl-3 col-xxl-3 col-12 mt-2"
                        key={index}
                      >
                        <div className="msc-box">
                          <div className="tabs-btn p-2">
                            <div className="d-flex justify-content-end">
                              <FontAwesomeIcon
                                icon="fa-solid fa-pen-to-square"
                                className="me-3"
                                style={{ cursor: "pointer" }}
                                onClick={() => getEditData(value?.id)}
                              />
                              <p>
                                {value?.degree?.name}
                                <FontAwesomeIcon
                                  icon={faXmark}
                                  className="close-icon"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => handleDelete(value?.id)}
                                />
                              </p>
                            </div>
                            <div className="info">
                              <p>
                                <span>Title :</span> {value?.title}
                              </p>
                              <p>
                                <span>Issuing Organization:</span>{" "}
                                {value?.issuing_org}
                              </p>
                              <p>
                                <span>Start Date :</span> {value?.start_date}
                              </p>
                              <p>
                                <span>End Date :</span> {value?.end_date}
                              </p>
                              <p>
                                <span>Certificate For :</span>
                                {value?.certificate_for?.name}
                              </p>
                              <p>
                                <span>Main Topics :</span> {value?.main_topics}
                              </p>
                              <p>
                                <span>Achievemente :</span>{" "}
                                {value?.achievement?.name}
                              </p>
                              <p>
                                <span> Skills :</span>

                                {value?.jobseeker_certificate_skills?.map(
                                  (skill) => skill?.skill?.name + ", "
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </Form>

                <Modal
                  size="lg"
                  show={lgShow}
                  onHide={() => setLgShow(false)}
                  className="experience-modal"
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title>
                      {ftype ? "Add Certificate" : "Edit Certificate"}
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    {" "}
                    <Row>
                      <div className="col-md-6 col-sm-12 mb-3">
                        <Form.Label>
                          Title <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Title"
                          name="title"
                          value={formData?.title}
                          onChange={handleChange}
                        />
                        {errors?.title && (
                          <span style={errorStyle}>{errors?.title}</span>
                        )}
                      </div>

                      <div className="col-md-6 col-sm-12 mb-3">
                        <Form.Label>
                          Issuing Organization{" "}
                          <span className="text-danger"></span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Issuing Organization"
                          name="issuing_org"
                          value={formData?.issuing_org}
                          onChange={handleChange}
                        />
                        {errors?.issuing_org && (
                          <span style={errorStyle}>{errors?.issuing_org}</span>
                        )}
                      </div>

                      <div className="col-md-3 col-sm-6 mb-3">
                        <Form.Label>
                          Start Date <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="date"
                          placeholder="Start Date"
                          name="start_date"
                          value={formData?.start_date}
                          onChange={handleChange}
                        />
                        {errors?.start_date && (
                          <span style={errorStyle}>{errors?.start_date}</span>
                        )}
                      </div>

                      <div className="col-md-3 col-sm-6 mb-3">
                        <Form.Label>
                          End Date <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="date"
                          placeholder="End Date"
                          name="end_date"
                          value={formData?.end_date}
                          onChange={handleChange}
                        />
                        {errors?.end_date && (
                          <span style={errorStyle}>{errors?.end_date}</span>
                        )}
                      </div>

                      <div className="col-md-6 col-sm-12 mb-3">
                        <Form.Label>
                          Certificate For <span className="text-danger"></span>
                        </Form.Label>
                        <Select
                          name="certificate_for_id"
                          placeholder="Enter Certificate For"
                          value={formData?.certificate_for_id}
                          onChange={handleChange}
                          options={certificateFor}
                        />
                        {/* <Form.Control
                              type="text"
                              placeholder="Enter Certificate For"
                              name="certificate_for"
                              value={formData?.certificate_for}
                              onChange={handleChange}
                            /> */}
                        {errors?.certificate_for_id && (
                          <span style={errorStyle}>
                            {errors?.certificate_for_id}
                          </span>
                        )}
                      </div>

                      <div className="col-md-6 col-sm-12 mb-3">
                        <Form.Label>
                          Main Topics <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Main Topics"
                          name="main_topics"
                          value={formData?.main_topics}
                          onChange={handleChange}
                        />
                        {errors?.main_topics && (
                          <span style={errorStyle}>{errors?.main_topics}</span>
                        )}
                      </div>

                      <div className="col-md-6 col-sm-12 mb-3">
                        <Form.Label>
                          Achievemente<span className="text-danger">*</span>{" "}
                        </Form.Label>

                        <Select
                          name="achievement_id"
                          placeholder="Enter Degree"
                          value={formData?.achievement_id}
                          onChange={handleChange}
                          options={achievemente}
                        />
                        {errors?.achievement_id && (
                          <span style={errorStyle}>
                            {errors?.achievement_id}
                          </span>
                        )}
                      </div>

                      <div className="col-md-6 col-sm-12 mb-3">
                        <Form.Label>
                          Skills<span className="text-danger">*</span>{" "}
                        </Form.Label>

                        <Select
                          isMulti={true}
                          name="skills"
                          placeholder="Selecte Skills"
                          value={selectedskills}
                          onChange={(e) => {
                            setSelectedSkills(e);
                          }}
                          options={skills}
                        />
                        {errors?.skills && (
                          <span style={errorStyle}>{errors?.skills}</span>
                        )}
                      </div>

                      <div className="col-md-6 col-sm-12 mb-3">
                        <Form.Label>
                          Upload Evidence<span className="text-danger">*</span>{" "}
                        </Form.Label>

                        <Form.Control
                          type="file"
                          placeholder="Enter Evidance"
                          name="evidance"
                          accept="application/pdf"
                          onChange={async (e) => {
                            await setFormData({
                              ...formData,
                              [e.target.name]: e.target.files[0],
                            });
                          }}
                        />
                        {errors?.evidance && (
                          <span style={errorStyle}>{errors?.evidance}</span>
                        )}
                      </div>
                    </Row>
                    {/* <div className="text-end">
                      <Button
                        className="education-btn"
                        onClick={() => handleSubmit("add")}
                      >
                        Add Certificate
                      </Button>
                    </div> */}
                  </Modal.Body>
                  <Modal.Footer className="mx-auto">
                    <Button
                      variant="secondary"
                      className="blue-btn-class "
                      onClick={handleClose}
                    >
                      Close
                    </Button>
                    <Button
                      variant="primary"
                      className="blue-btn-class "
                      onClick={() => handleSubmit("add")}
                      // onClick={handleClose}
                    >
                      {ftype ? "Add Certificate" : "Edit Certificate"}
                    </Button>
                  </Modal.Footer>
                </Modal>
                {/* <Tabs
                  className="desktop-view-tabs"
                  selectedIndex={activeTab}
                  onSelect={(index) => setActiveTab(index)}
                >
                  <TabList className="nav-flex mx-auto justify-content-center px-4 mb-2 g-0">
                    <Tab className="text-center">Higher Education</Tab>
                    <Tab className="text-center">Post Graduation</Tab>
                    <Tab className="text-center">Graduation</Tab>
                    <Tab className="text-center">HSC</Tab>
                    <Tab className="text-center">SSC</Tab>
                    <Tab className="text-center">Certifications</Tab>
                  </TabList>

                  <TabPanel>
                    <FirstTab />
                  </TabPanel>

                  <TabPanel>
                    <SecondTab />
                  </TabPanel>

                  <TabPanel>
                    <ThirdTab />
                  </TabPanel>

                  <TabPanel>
                    <ForthTab />
                  </TabPanel>

                  <TabPanel>
                    <FifthTab />
                  </TabPanel>

                  <TabPanel>
                    <SixthTab />
                  </TabPanel>
                </Tabs> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PortfolioEducationForm;
