import React, { useState } from "react";
import Question1 from "./Question1";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import "../SkillQuestions/AllQuestion.css";
import Question2 from "./Question2";
import Question3 from "./Question3";
import Question4 from "./Question4";
import Question5 from "./Question5";

const SkillQuestions = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const totalSteps = 5;

  const handleNext = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return <Question1 />;
      case 2:
        return <Question2 />;
      case 3:
        return <Question3 />;
      case 4:
        return <Question4 />;
      case 5:
        return <Question5 />;
      default:
        return null;
    }
  };

  const renderNavigationButtons = () => {
    if (currentStep === 1) {
      return (
        <div className="navigation-buttons">
          {currentStep < totalSteps && <button className="nextbtns" onClick={handleNext}>Next</button>}
        </div>
      );
    } else if (currentStep > 1 && currentStep < totalSteps) {
      return (
        <div className="navigation-buttons">
          <button className="backbtns" onClick={handleBack}>Back</button>
          <button className="nextbtns" onClick={handleNext}>Next</button>
        </div>
      );
    } else if (currentStep === totalSteps) {
      return (
        <div className="navigation-buttons">

          <Link to='/done-skill'>  <button className="nextbtns" >Done</button></Link>
        </div>
      );
    }

  };

  return (

    <div className="container">
      <div className="skill-container">
        {/* Common Header */}
        <div className="portfolio-experience main-tabs mb-0">
          <div className="container">
            <div className="heading text-center">
              <p>My Portfolio</p>
            </div>
            <div className="tab-header">
              <Row>
                <Col className="">
                  <Link to="/">Back</Link>
                </Col>
                <Col className="text-center">
                  <p className="edu-text">General Skills - Communication</p>
                </Col>
                <Col className="text-end">
                  <Link to="/">Save & close</Link>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <div className="shadow-container">
          <div className="progress-indicator">
            {/* Progress Indicator */}
            <div
              className="progress-style"
              style={{
                width: `${(currentStep) * 20}%`,
                height: "7px",
                background: "#1967D3",
                transition: "width 0.3s ease",
              }}
            />
          </div>
          {/* Render current step */}
          {renderStep()}
          {renderNavigationButtons()}
        </div>
      </div>
    </div>
  );
};

export default SkillQuestions;
