import React from 'react'
import SchedulInterview from './Schedulinterview/SchedulInterview.js';
import ShortBanner from './ShortBanner/ShortBanner.js';



const ShortListedPortFolio = () => {
    return (
        <div>
            <ShortBanner />
            <SchedulInterview />
        </div>
    )
}

export default ShortListedPortFolio;