import React from 'react';
import Lottie from 'react-lottie';
import './Sms.css';
import * as animationData from './chatbot.json';

function Sms(){
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };
    
    return (
      <section className='main'>
            {/* <div className='sms-icon'>
                <Lottie className="me-auto"
                options={defaultOptions}    
                />       
        </div> */}
      </section>
    );
}
export default Sms;