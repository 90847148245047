import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import JobDescription from "../DashBoardJob/JobDescription/JobDescription";
import "./JobDescriptionModel.css";
const JobDescriptionModal = (props) => {
  return (
    <>
      <Modal
        {...props}
        fullscreen={true}
        dialogClassName="modal-90w"
        className="jdModal"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Modal heading
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <JobDescription
            data={props.data}
            onJDHide={props.onHide}
            title={props.title}
            getSavedJobs={props?.getSavedJobs}
          />
        </Modal.Body>
        <Modal.Footer>
          <div className="jdFootermodal">
            <Button onClick={props.onHide}>Close</Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default JobDescriptionModal;
