import { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate, useLocation } from "react-router-dom";

import ContactModal from "../ContactModal/ContactModal";

import { useContext } from "react";
import { Context } from "../../../../utils/context";
function Step2({ handleNext }) {
  const { postData, recruiterData, getJobSeekerData, jobSeekerData } =
    useContext(Context);

  const [emailVerify, setEmailVerify] = useState(false);

  const [modalShow, setModalShow] = useState(false);
  const [timerShow, setTimerShow] = useState(false);

  const [seconds, setSeconds] = useState(0); // Initial value is 60 seconds
  const [isTimerExpired, setIsTimerExpired] = useState(false);
  const [otpERR, setOtpErr] = useState("");

  const [otpsend, setOtpSend] = useState(null);
  const [emailDisable, setEmailDis] = useState(false);

  const [error, seterror] = useState(false);
  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);
  const inputRef3 = useRef(null);
  const inputRef4 = useRef(null);
  const navigate = useNavigate();
  const { state } = useLocation();

  const [emailErr, setEmailErr] = useState("");
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState(true);

  const handleValidation = async (type) => {
    // console.log(recruiterData, "recruiterData");

    // Initialize an array to store error messages
    var errors = [];

    if (email.trim() === "") {
      setEmailErr("Email is required");
      errors.push("Email is required");
    } else if (
      !/^([A-Za-z0-9_\-\.])+\@(?!(?:[A-Za-z0-9_\-\.]+\.)?([A-Za-z]{2,4})\.\2)([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(
        email
      )
    ) {
      setEmailErr("Email is not valid");
      errors.push("Email is not valid");
    }

    if (errors.length === 0) {
      if (type === "next") {
        if (emailVerify == false) {
          setEmailErr("Verify Your Email");
          errors.push("Verify Your Email");
        } else {
          // console.log("next");
          const data = {
            email: email,
            step: 5,
            status: status,
          };
          const res = await postData(`/jobseeker/signup/email`, data);
          if (res?.success) {
            handleNext();
          } else {
            // console.log(res, "fails");
            setEmailErr(res?.message);
          }
        }
      }
      if (type === "otp") {
        // console.log("otp");
        setEmailErr("");
        const randOtp = Math.floor(1000 + Math.random() * 9000).toString();
        // console.log(randOtp, "random");
        const res = await postData("/without-login/verify-otp/email-verify", {
          email: email,
          otp: randOtp,
        });

        if (res?.success) {
          // console.log(res, "emailotp");
          setOtpSend(randOtp);
          setModalShow(true);
          setTimerShow(true);
          setIsTimerExpired(false);
          setOtpErr("");
          setOtpValues(["", "", "", ""]);
          setSeconds(60);
          setEmailDis(true);
          setTimeout(() => {
            setModalShow(false);
          }, 4000);
        } else {
          // console.log(res, "fail");
          setEmailErr(res?.message);
        }
      }
    }
  };

  const [otpValues, setOtpValues] = useState(["", "", "", ""]);
  const inputRefs = [useRef(), useRef(), useRef(), useRef()];

  const handleOtpChange = (index, event) => {
    setOtpErr("");
    const inputValue = event.target.value;

    if (/^[0-9]*$/.test(inputValue) || inputValue === "") {
      otpValues[index] = inputValue;
      setOtpValues([...otpValues]);

      if (otpsend === otpValues.join("")) {
        // Call your OTP verification function here
        setEmailVerify(true);
        setTimerShow(false);
        setOtpValues(["", "", "", ""]);
        setOtpSend(null);
      }

      if (inputValue !== "" && index < 3) {
        inputRefs[index + 1].current.focus();
      } else {
        setOtpErr("OTP Invalid!");
      }
    }
  };

  const handleKeyDown = (index, event) => {
    setOtpErr("");
    if (event.key === "Backspace" && index > 0 && otpValues[index] === "") {
      otpValues[index - 1] = "";
      setOtpValues([...otpValues]);
      inputRefs[index - 1].current.focus();
    }
  };
  const [executeCount, setExecuteCount] = useState(0);

  const seekerData = async () => {
    const res = await getJobSeekerData();
    if (res?.email !== null) {
      setEmail(res?.email);
      setEmailVerify(true);
      await setStatus("none");
    }
  };

  useEffect(() => {
    if (executeCount == 0) {
      seekerData();
      setExecuteCount(1);
    }

    const decrementTimer = () => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else {
        setIsTimerExpired(true);
        setOtpSend(null);
        setEmailDis(false);
      }
    };

    const timerInterval = setInterval(decrementTimer, 1000);

    return () => {
      clearInterval(timerInterval);
    };
  }, [seconds]);
  return (
    <>
      <fieldset>
        <div className="form-card StepForm">
          <div className="background-signup mt-5">
            <div className="d-flex  steps-heading ">
              <div className="border-left" />
              <h5>Step 4</h5>
            </div>
            <Form>
              <div className="enter-email mt-0">
                <Form.Group as={Row} controlId="formPlaintextEmail">
                  <Form.Label className="email" column lg="2" md="3" sm="12">
                    Email<span className="star">*</span>
                  </Form.Label>
                  <Col lg="10" md="9" sm="12">
                    <div className="input-group mb-3">
                      <input
                        type="email"
                        className="form-control mt-0"
                        placeholder="Enter your email id"
                        aria-label="Recipient's username"
                        disabled={emailVerify || emailDisable}
                        defaultValue={email}
                        aria-describedby="basic-addon2"
                        onChange={(event) => {
                          setEmail(event.target.value);
                          setEmailErr("");
                          setEmailVerify(false);
                          setTimerShow(false);
                        }}
                      />

                      {emailVerify === false && timerShow !== true && (
                        <span
                          className="input-group-text"
                          style={{ cursor: "pointer" }}
                          id="basic-addon2"
                          onClick={() => {
                            handleValidation("otp");
                          }}
                        >
                          Get OTP
                        </span>
                      )}
                      {emailVerify === false &&
                        timerShow === true &&
                        isTimerExpired === true && (
                          <span
                            className="input-group-text"
                            style={{ cursor: "pointer" }}
                            id="basic-addon2"
                            onClick={() => {
                              handleValidation("otp");
                            }}
                          >
                            Resend OTP
                          </span>
                        )}
                    </div>
                    <div>
                      <ContactModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                      />
                    </div>
                    <span
                      className="text text-danger"
                      style={{
                        textAlign: "center",
                      }}
                    >
                      {emailErr && emailErr}
                    </span>

                    {timerShow && (
                      <div className="row text-center">
                        <div className="otpContainer">
                          {otpValues.map((value, index) => (
                            <input
                              className="otp"
                              type="text"
                              maxLength="1"
                              value={value}
                              onChange={(e) => handleOtpChange(index, e)}
                              onKeyDown={(e) => handleKeyDown(index, e)}
                              ref={inputRefs[index]}
                            />
                          ))}
                        </div>

                        <p className="otp">
                          Enter OTP within{" "}
                          {`${Math.floor(seconds / 60)}:${(seconds % 60)
                            .toString()
                            .padStart(2, "0")}`}{" "}
                          seconds
                        </p>
                        <span
                          className="text text-danger"
                          style={{
                            textAlign: "center",
                          }}
                        >
                          {otpERR && otpERR}
                        </span>
                      </div>
                    )}
                  </Col>
                </Form.Group>
                {emailVerify && (
                  <div className="row text-center">
                    <p className="otp text text-success">Email Verified...!</p>
                  </div>
                )}
              </div>

              <div className="mobile-view-button">
                <button
                  type="button"
                  name="next"
                  className="next action-button contin-btn"
                  onClick={() => handleValidation("next")}
                >
                  Continue
                </button>
              </div>
            </Form>
          </div>
        </div>
      </fieldset>

      <div className="row mt-5 pt-4 footer-sec">
        <div className="col-md-10 col-12 desktop-view-button">
          {/* <div className="check-row text-start">
            <span>
              <Form.Check inline name="group1" className="me-2" />
            </span>
            <span className=" by-text ">By signing up, I agree to the</span>
            <span className="px-1 ">
              <Link to="/terms" className="term-btn">
                Term and Conditions
              </Link>
            </span><br />
          </div>
          <div className="login-row text-start">
            <span className="">Already an User?</span>
            <span className="px-1 ">
              <Link to="/other-page" className="login-btn">
                log In
              </Link>
            </span>&nbsp; &nbsp; &nbsp;
            <span className="">Not a Jobseeker?</span>
            <span className="px-1 ">
              <Link to="/signup-recruiter" className="signup-btn">
                Signup as Recruiter
              </Link>
            </span>
          </div> */}
        </div>
        {/* <div className="col-md-8 col-12 mb-5  mobile-view-button">
          <div className="check-row text-start">
            <span>
              <Form.Check inline name="group1" className="me-2" />
            </span>
            <span className=" by-text ">By signing up, I agree to the</span>
            <span className="px-1 ">
              <Link to="/terms" className="term-btn">
                Term and Conditions
              </Link>
            </span><br />
          </div>
          <div className="login-row text-start">
            <span className="">Already an User?</span>
            <span className="px-1 ">
              <Link to="/other-page" className="login-btn">
                log In
              </Link>
            </span><br />
            <span className="">Not a Jobseeker?</span>
            <span className="px-1 ">
              <Link to="/signup-recruiter" className="signup-btn">
                Signup as Recruiter
              </Link>
            </span>
          </div>
        </div> */}

        <div className="col-2">
          <div className="desktop-view-button">
            <button
              type="button"
              name="next"
              className="next action-button contin-btn"
              onClick={() => handleValidation("next")}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Step2;
