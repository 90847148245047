import React from "react";
import Banner from "../Banner/Banner";
import JobCategory from "../JobCategory/JobCategory";
import OnlinePortfolio from "../OnlinePortfolio/OnlinePortfolio";
import FeaturedJobs from "../FeaturedJobs/FeaturedJobs";
import Reviews from "../Reviews/Reviews";
import ReviewSlider from "../ReviewSlider/ReviewSlider";
const MainHome = () => {
  return (
    <>
      <Banner />
      <JobCategory />
      <OnlinePortfolio />
      <FeaturedJobs />
      {/* <Reviews /> */}
      <ReviewSlider />
    </>
  );
};

export default MainHome;
