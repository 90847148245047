import React, { useState } from 'react';
import '../OpenJobs/OpenJobs.css';
import '../SearchPool/SearchPool.css';
import '../CreateJobs/CreateJobs.css'
import { Link } from 'react-router-dom';
import CreateModal from '../CreateJobs/CreateModal';
import Select from 'react-select';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';


const ViewJobs = () => {
    const [selectedOption, setSelectedOption] = useState('');
    const options = [
        { value: 'developer', label: 'developer' },
        { value: 'accountent', label: 'accountent' },
        { value: 'web developer', label: 'web developer' },
    ];






    return (
        <>
            <section className="manage-job">
                <div className='container'>
                    <div className="row ">
                        <div className="col-md-12">
                            <div className="section-title mt-3">
                                <div className="row">
                                    <div className="col-md-12 ">
                                        <div className="title-view-job text-center">
                                            <h5>View Job Details (Silver S1)</h5>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <Form className='create-job-form'>
                                            <Row>
                                                <div className="col-xxl-9 col-xl-9 col-lg-8 col-md-6 ">
                                                    <Form.Group className="mb-3" controlId="formGroupEmail">
                                                        <Form.Label>Job Title :</Form.Label>
                                                        <Form.Control type="text" placeholder="Recipient's username" />
                                                    </Form.Group>
                                                </div>
                                                <div className=" col-xxl-3 col-xl-3 col-lg-4 col-md-6 ">
                                                    <Form.Group className="mb-3" controlId="formGroupPassword">
                                                        <Form.Label>Job Type :</Form.Label>
                                                        <Select className='select-line'
                                                            defaultValue={selectedOption}
                                                            onChange={setSelectedOption}
                                                            options={options}
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-xxl-9 col-xl-9 col-lg-8 col-md-6 col-12">
                                                    <Form.Group className="mb-3" controlId="formGroupPassword">
                                                        <Form.Label>Industry :</Form.Label>
                                                        <Form.Control type="text" placeholder="Username" />
                                                    </Form.Group>
                                                </div>

                                                <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-12">
                                                    <Form.Group className="mb-3" controlId="formGroupPassword">
                                                        <Form.Label>Work Location :</Form.Label>
                                                        <Select className='select-line'
                                                            defaultValue={selectedOption}
                                                            onChange={setSelectedOption}
                                                            options={options}
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-xxl-9 col-xl-9 col-lg-8 col-md-6 col-12">
                                                    <Form.Group className="mb-3" controlId="formGroupPassword">
                                                        <Form.Label>Sector :</Form.Label>
                                                        <Form.Control type="text" placeholder="Username" />
                                                    </Form.Group>

                                                </div>
                                                <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-12 ">
                                                    <Form.Group className="mb-3" controlId="formGroupPassword">
                                                        <Form.Label>Career Level :</Form.Label>
                                                        <Select className='select-line'
                                                            defaultValue={selectedOption}
                                                            onChange={setSelectedOption}
                                                            options={options}
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-xxl-9 col-xl-9 col-lg-8 col-md-6 col-12">
                                                    <Form.Group className="mb-3" controlId="formGroupPassword">
                                                        <Form.Label>Qualification :</Form.Label>
                                                        <Form.Control type="text" placeholder="Username" />
                                                    </Form.Group>

                                                </div>

                                                <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-12">
                                                    <Form.Group className="mb-3" controlId="formGroupPassword">
                                                        <Form.Label>Select Prof. Experience in Years :</Form.Label>
                                                        <Select className='select-line'
                                                            defaultValue={selectedOption}
                                                            onChange={setSelectedOption}
                                                            options={options}
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-xxl-9 col-xl-9 col-lg-8 col-md-6">
                                                    <Form.Group className="mb-3" controlId="formGroupPassword">
                                                        <Form.Label>Primary Skills :</Form.Label>
                                                        <Form.Control as="textarea" rows={1} />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6">
                                                    <Form.Group className="mb-3" controlId="formGroupPassword">
                                                        <Form.Label>Number of Vacancy :</Form.Label>
                                                        <Select className='select-line'
                                                            defaultValue={selectedOption}
                                                            onChange={setSelectedOption}
                                                            options={options}
                                                        />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-xxl-9 col-xl-9 col-lg-8 col-md-6">
                                                    <Form.Group className="mb-3" controlId="formGroupPassword">
                                                        <Form.Label>Secondary Skills</Form.Label>
                                                        <Form.Control as="textarea" rows={1} />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 mt-4">
                                                    <Form.Group className="mb-3" controlId="formGroupPassword">
                                                        <Form.Control type="text" placeholder="Username" />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-12 col-12">
                                                    <Form.Group className="mb-3" controlId="formGroupPassword">
                                                        <Form.Label>Job description:</Form.Label>
                                                        <Form.Control as="textarea" rows={1} />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <Form.Group className="mb-3" controlId="formGroupPassword">
                                                        <Form.Label>Languages:</Form.Label>
                                                        <Form.Control type="text" placeholder="Username" />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <Form.Group className="mb-3" controlId="formGroupPassword">
                                                        <Form.Label>Shift and Schedule:</Form.Label>
                                                        <Form.Control type="text" placeholder="Username" />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <Form.Group className="mb-3" controlId="formGroupPassword">
                                                        <Form.Label>Roles and responsibilities:</Form.Label>
                                                        <Form.Control as="textarea" rows={1} />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <Form.Group className="mb-3" controlId="formGroupPassword">
                                                        <Form.Label>Skills required:</Form.Label>
                                                        <Form.Control as="textarea" rows={1} />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-xxl-10 col-xl-10 col-lg-8 col-md-8 col-12">
                                                    <Form.Group className="mb-3" controlId="formGroupPassword">
                                                        <p>Contact:</p>
                                                        <Form.Label>H.R. Person / Recruiter</Form.Label>
                                                        <Form.Control type="text" placeholder="Username" />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-xxl-2 col-xl-2 col-lg-4 col-md-4 col-12">
                                                    <Form.Group className="mb-3" controlId="formGroupPassword">
                                                        <Form.Label className='mt-5'>Upload Company Logo</Form.Label>
                                                        <Form.Control type="text" placeholder="Username" />
                                                    </Form.Group>

                                                </div>
                                                <div className="col-md-4 col-12">
                                                    <Form.Group className="mb-3" controlId="formGroupPassword">
                                                        <Form.Label>Recruiter Contact Number:</Form.Label>
                                                        <Form.Control type="text" placeholder="Username" />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-4 col-12">
                                                    <Form.Group className="mb-3" controlId="formGroupPassword">
                                                        <Form.Label>Recruiter Email:</Form.Label>
                                                        <Form.Control type="text" placeholder="Username" />
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-4 col-12">
                                                    <Form.Group className="mb-3" controlId="formGroupPassword">
                                                        <Form.Label>Application Deadline:</Form.Label>
                                                        <Form.Control type="text" placeholder="Username" />
                                                    </Form.Group>
                                                </div>
                                                <div className='text-center mt-5 mb-5'>
                                                    <Link to={'/dashboardmain'}><button type='button' className='btn show-more'>Ok</button></Link>

                                                </div>
                                            </Row>
                                        </Form>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ViewJobs;