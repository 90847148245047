
import React, { useEffect, useRef } from "react";
import './PortfolioJobExperience.css';
import { Link } from 'react-router-dom';
const ViewDetailsinternship = () => {
    const vidRef = useRef();

    useEffect(() => { vidRef.current.play(); }, []);
    return (
        <>
            <section className='portfolio-experience'>
                <div className='container'>
                    <div className='black-header'>
                        <div className='row'>
                            <div className='col-md-4 col-6'>
                                <Link to='/my-portfolio'>
                                    <div className='text-holder pt-2 ms-3'>
                                        <p>Back</p>
                                    </div>
                                </Link>
                            </div>
                            <div className='col-md-4 col-6'>
                                <div className='text-holder text-center pt-2'>
                                    <h5>Internship Experiences</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='form-bg'>
                        <div className='company-name'>
                            <h4>Profcyma Solutions Pvt. Ltd.</h4>
                        </div>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <div className='row'>
                                    <div className='col-5'>
                                        <div className='position-holder'>
                                            <p>Role :</p>
                                        </div>
                                    </div>
                                    <div className='col-7'>
                                        <div className='role-holder '>
                                            <p>Frontend Intern</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-5'>
                                        <div className='position-holder'>
                                            <p>My Position :</p>
                                        </div>
                                    </div>
                                    <div className='col-7'>
                                        <div className='role-holder '>
                                            <p>Junior Frontend Intern</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-5'>
                                        <div className='position-holder'>
                                            <p>Skills Used :</p>
                                        </div>
                                    </div>
                                    <div className='col-7'>
                                        <div className='role-holder '>
                                            <p>JAVA, Python, HTML, React</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-5'>
                                        <div className='position-holder'>
                                            <p>Job Nature :</p>
                                        </div>
                                    </div>
                                    <div className='col-7'>
                                        <div className='role-holder '>
                                            <p>Developer</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-5'>
                                        <div className='position-holder'>
                                            <p>Time Period :</p>
                                        </div>
                                    </div>
                                    <div className='col-7'>
                                        <div className='role-holder '>
                                            <p>2022-2023</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-5'>
                                        <div className='position-holder'>
                                            <p>Reporting Person :</p>
                                        </div>
                                    </div>
                                    <div className='col-7'>
                                        <div className='role-holder '>
                                            <p>Prasad Kulkarni</p>
                                        </div>
                                    </div>
                                </div>
                               <div className='row'>
                                    <div className='col-5'>
                                        <div className='position-holder'>
                                            <p>Scale:</p>
                                        </div>
                                    </div>
                                    <div className='col-7'>
                                        <div className='role-holder '>
                                            <p>7.5</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-5'>
                                        <div className='position-holder'>
                                            <p>Company Sector:</p>
                                        </div>
                                    </div>
                                    <div className='col-7'>
                                        <div className='role-holder '>
                                            <p>I.T.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='video-img text-lg-end text-md-center mb-3'>
                                <video src={process.env.PUBLIC_URL+ "assets/Images/my-portfolio/video.mp4"} ref={vidRef} muted loop className=" video-class-grid" />
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-6">
                                <div className='position-holder mt-3'>
                                    <p>Files Attached</p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <button className='btn btn-status1 me-1 mt-3'><img src={process.env.PUBLIC_URL+ "assets/Images/my-portfolio/document.png"} className="document-img me-2" />Experience Letter</button>
                            </div>
                            <div className=" col-lg-3 col-md-6">
                                <button className='btn btn-status1 me-1 mt-3'> <img src={process.env.PUBLIC_URL+ "assets/Images/my-portfolio/document.png"} className="document-img me-2" /> Document 1</button>
                            </div>
                            <div className=" col-lg-3 col-md-6">
                                <button className='btn btn-status1 me-1 mt-3'> <img src={process.env.PUBLIC_URL+ "assets/Images/my-portfolio/document.png"} className="document-img me-2" /> Document 2</button>
                            </div>
                            <div className='button-holder text-center mt-5'>
                                <button className='btn btn-save' >OK</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ViewDetailsinternship